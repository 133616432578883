import { createSvgIcon } from '@mui/material';

const ArrowBack = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.175 19.475L4.225 12.525C4.14167 12.4417 4.08333 12.3584 4.05 12.275C4.01667 12.1917 4 12.1 4 12C4 11.9 4.01667 11.8084 4.05 11.725C4.08333 11.6417 4.14167 11.5584 4.225 11.475L11.2 4.50005C11.3333 4.36672 11.5 4.30005 11.7 4.30005C11.9 4.30005 12.075 4.37505 12.225 4.52505C12.375 4.67505 12.45 4.85005 12.45 5.05005C12.45 5.25005 12.375 5.42505 12.225 5.57505L6.55 11.25H18.95C19.1667 11.25 19.3458 11.3209 19.4875 11.4625C19.6292 11.6042 19.7 11.7834 19.7 12C19.7 12.2167 19.6292 12.3959 19.4875 12.5375C19.3458 12.6792 19.1667 12.75 18.95 12.75H6.55L12.25 18.45C12.3833 18.5834 12.45 18.75 12.45 18.95C12.45 19.15 12.375 19.325 12.225 19.475C12.075 19.625 11.9 19.7 11.7 19.7C11.5 19.7 11.325 19.625 11.175 19.475Z" />
  </svg>,
  'ArrowBack',
);

export default ArrowBack;
