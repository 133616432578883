import React, { useMemo, useState } from 'react';
import { enqueueSnackbar, VariantType } from 'notistack';
import { DateTimePickerTabs } from '@mui/x-date-pickers';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import dayjs, { Dayjs } from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Banner, fetchEditBanner, savingBannerStatus } from 'store/bannerDetailsSlice';
import ArrowBack from 'assets/img/ArrowBack';
import CalendarIcon from 'assets/img/CalendarIcon';
import ChevronRightIcon from 'assets/img/ChevronRightIcon';
import EditIcon from 'assets/img/EditIcon';
import RefreshIcon from 'assets/img/RefreshIcon';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import ContextMenu from 'components/WorldManagement/ContextMenu/ContextMenu';
import DateTimePicker from 'components/shared/DateTimePicker/DateTimePicker';
import IconButton from 'components/shared/IconButton/IconButton';
import TextEditorField from 'components/WorldManagement/Banners/BannerDetails/TextEditorField/TextEditorField';
import TextField from 'components/shared/TextField/TextField';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import getValidationSchema from 'components/WorldManagement/Banners/BannersModal/utils/getValidationSchema';
import { CoverAction } from 'components/WorldManagement/Banners/BannerDetails/BannerDetailsModal/SetCover';
import './index.scss';

interface IProps {
  bannerDetails: Banner;
  setArchiveBanners(banner: Banner): void;
  setExpiredBanners(banner: Banner): void;
  setOpenLinkedCollections(banner: Banner): void;
  setIsOpenClosingModal(): void;
  setShowSetAvatar(action: CoverAction): void;
  refreshData(): void;
}

// todo добавить сохранение деталей.
const BannerDetailsForm = ({
  bannerDetails,
  setExpiredBanners,
  setArchiveBanners,
  setOpenLinkedCollections,
  setIsOpenClosingModal,
  setShowSetAvatar,
  refreshData,
}: IProps) => {
  const [dateWithInitialValue, setDateWithInitialValue] = useState<Dayjs | null>(
    bannerDetails.expiredDate ? dayjs(bannerDetails.expiredDate) : null,
  );
  const disableEditing = useMemo(() => bannerDetails.status === 'LIVE', [bannerDetails]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const savingStatus = useAppSelector(savingBannerStatus);

  const schema = useMemo(() => getValidationSchema('edit'), []);
  const initialDetails = useMemo(() => {
    return {
      name: bannerDetails.name,
      expiredDate: bannerDetails.expiredDate,
      title: bannerDetails.title,
      subtitle: bannerDetails.subtitle,
      article: bannerDetails.article,
    };
  }, []);

  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    setValue,
    formState: { errors, isValid, isDirty },
  } = useForm<Banner>({
    resolver: yupResolver(schema),
    defaultValues: initialDetails,
  });

  // todo картинка баннера должна загружаться отдельно и сохраняться сразу. она не будет влиять на сохранение остального
  // замена картинки уже работает. с изменением текущей косямба :(
  // (смотри src/components/WorldManagement/Banners/BannerDetails/BannerDetailsModal/SetCover.tsx)
  const onSubmit = async (data: any) => {
    if (disableEditing) {
      return;
    }
    console.log(data);

    void trigger();
    try {
      // enqueueSnackbar('Changes have been saved', { variant: 'success' as VariantType });
      const result = await dispatch(fetchEditBanner({ bannerId: bannerDetails.id, data: data }));
      if (result.meta.requestStatus === 'fulfilled') {
        enqueueSnackbar(`Changes have been saved`, { variant: 'success' as VariantType });
        refreshData();
      }
      if (result.meta.requestStatus === 'rejected') {
        enqueueSnackbar('Saving error, try again later', { variant: 'error' as VariantType });
        // setErrorMessage('Saving error, try again later');
      }
    } catch (e) {
      enqueueSnackbar('Saving error, try again later', { variant: 'error' as VariantType });
    }
  };

  return (
    <form className="bannerDetails-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="bannerDetails-breadcrumbs">
        <Breadcrumbs separator={<ChevronRightIcon fontSize="small" />}>
          <div className="bannerDetails-header-text">
            <IconButton
              onClick={() => {
                if (!disableEditing && isDirty) {
                  setIsOpenClosingModal();
                  return;
                }
                navigate('/ui/world-management/banners');
              }}
            >
              <ArrowBack />
            </IconButton>
            Tim World Management
          </div>
          <div className="bannerDetails-header-text">Banners</div>
          <div className="bannerDetails-header-text bannerDetails-header-text_main">
            {bannerDetails.name}
            {isDirty && <span className="bannerDetails-header-text"> (not saved)</span>}
          </div>
        </Breadcrumbs>
        <div className="bannerDetails-header-actions">
          <LoadingButton
            disabled={disableEditing || !isDirty}
            loading={savingStatus === 'loading'}
            variant="contained"
            type="submit"
            color="primary"
          >
            Save
          </LoadingButton>
          <ContextMenu
            item={bannerDetails}
            setArchiveBanners={(banner: Banner) => setArchiveBanners(banner)}
            setExpiredBanners={(banner: Banner) => setExpiredBanners(banner)}
            setLinkedCollections={(banner: Banner) => setOpenLinkedCollections(banner)}
          />
        </div>
      </div>
      <div className={disableEditing ? 'bannerDetails-header bannerDetails-header_disabled' : 'bannerDetails-header'}>
        <div className="bannerDetails-header-title">Settings</div>
        <div className="bannerDetails-header-lastModified">
          Last modified: {new Date(bannerDetails.updatedAt).toLocaleDateString()}
        </div>
        <TextField
          disabled={disableEditing}
          {...register('name')}
          error={!!errors.name}
          name="name"
          label="Name*"
          defaultValue={initialDetails.name}
          size="small"
          onBlur={() => trigger('name')}
        />
        <DateTimePicker
          disabled={disableEditing}
          minDateTime={dayjs(new Date()).add(5, 'minutes')}
          onChange={(newValue) => {
            // console.log('changing expiredDate')
            setDateWithInitialValue(newValue);
            setValue('expiredDate', newValue ? new Date(newValue.toString()) : null, { shouldDirty: true });
          }}
          label="Validity date & time"
          value={dateWithInitialValue}
          slots={{
            openPickerIcon: CalendarIcon,
            tabs: DateTimePickerTabs,
          }}
          slotProps={{
            textField: {
              ...register('expiredDate'),
              defaultValue: initialDetails.expiredDate,
              size: 'small',
            },
          }}
        />
      </div>
      <div className="bannerDetails-content">
        <div className="bannerDetails-content-title">Banner</div>
        <div className="bannerDetails-content-info">
          <div>
            <div className="avatar-imageLabel">Cover</div>
            <div className="avatar-imageContainer">
              <div className="avatar-imageContainer-buttons">
                <Tooltip title="Edit photo" placement="top-start">
                  <span className={disableEditing ? 'avatar-imageContainer-buttons_disabled' : ''}>
                    <IconButton disabled={disableEditing} onClick={() => setShowSetAvatar('edit')}>
                      <EditIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Replace photo" placement="top-start">
                  <span className={disableEditing ? 'avatar-imageContainer-buttons_disabled' : ''}>
                    <IconButton disabled={disableEditing} onClick={() => setShowSetAvatar('replace')}>
                      <RefreshIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
              <img src={bannerDetails.cover.originalLink} alt="avatar" />
            </div>
          </div>
          <div
            className={
              disableEditing
                ? 'bannerDetails-content-info-fields bannerDetails-content-info-fields_disabled'
                : 'bannerDetails-content-info-fields'
            }
          >
            <TextField
              disabled={disableEditing}
              {...register('title')}
              name="title"
              label="Title (optional)"
              defaultValue={initialDetails.title}
              size="small"
              fullWidth={true}
            />
            <TextField
              disabled={disableEditing}
              {...register('subtitle')}
              name="subtitle"
              label="Subtitle (optional)"
              defaultValue={initialDetails.subtitle}
              size="small"
              fullWidth={true}
            />
            <TextEditorField
              readOnly={disableEditing}
              maxLength={2000}
              label="Article"
              placeholder="Write an article"
              defaultValue={initialDetails.article?.editorString}
              onChange={(textString) => {
                if (!disableEditing && textString !== initialDetails.article?.textString) {
                  // console.log('changing textString', initialDetails.article?.editorString, initialDetails.article?.textString, textString)
                  setValue('article.textString', textString);
                }
              }}
              onSave={(editorString) => {
                if (!disableEditing && editorString !== initialDetails.article?.editorString) {
                  // console.log('changing editorString')
                  setValue('article.editorString', editorString, { shouldDirty: true });
                }
              }}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default BannerDetailsForm;
