const ChangeIcon = () => {
  return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path d="M9.03311 17.1505C7.55564 18.6583 4.00062 19.7256 3.64175 19.308C3.28287 18.8904 4.36094 15.448 5.82691 13.944C7.29288 12.44 11.7083 8.0546 14.5488 5.2217C18.5983 1.17194 21.8045 4.37842 17.755 8.42817C14.9145 11.2611 10.5106 15.6427 9.03311 17.1505Z" stroke="currentColor" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.8047 6.25195L16.8259 9.27312" stroke="currentColor" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.6563 19.3992H18.9997" stroke="currentColor" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  )
}

export default ChangeIcon;