import { Tooltip as TooltipMUI } from '@mui/material';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';
import './index.scss';

const Tooltip = (props: TooltipProps) => {
  return (
    <TooltipMUI
      {...props}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: props.followCursor ? [20, 0] : [0, 0],
              },
            },
          ],
        },
      }}
    >
      <div>{props.children}</div>
    </TooltipMUI>
  );
};

export default Tooltip;
