import { DialogContentText as DialogContentTextMUI } from '@mui/material';
import { DialogContentTextProps } from '@mui/material/DialogContentText/DialogContentText';
import './index.scss';

const DialogContentText = (props: DialogContentTextProps) => {
  return (
    <DialogContentTextMUI {...props} />
  );
};

export default DialogContentText;
