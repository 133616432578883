import { createSvgIcon } from '@mui/material';

const InfoFilledIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12.0004 2.15039C8.44398 2.15039 5.88693 2.77567 4.26115 4.47535C2.64394 6.16606 2.15039 8.71327 2.15039 12.0004C2.15039 15.5182 2.63682 18.0889 4.27435 19.7264C5.91188 21.364 8.48261 21.8504 12.0004 21.8504C15.5583 21.8504 18.1028 21.2244 19.7256 19.6059C21.3517 17.9842 21.8504 15.567 21.8504 12.5298C21.8504 9.02841 21.4965 6.33816 19.9533 4.57011C18.3793 2.76689 15.7853 2.15039 12.0004 2.15039ZM12.0004 11.1504C12.4698 11.1504 12.8504 11.5309 12.8504 12.0004V17.0004C12.8504 17.4698 12.4698 17.8504 12.0004 17.8504C11.5309 17.8504 11.1504 17.4698 11.1504 17.0004V12.0004C11.1504 11.5309 11.5309 11.1504 12.0004 11.1504ZM11.0005 8.65039C10.9965 8.09839 11.4445 7.65039 11.9965 7.65039C12.5525 7.65039 13.0005 8.09839 13.0005 8.65039C13.0005 9.20239 12.5525 9.65039 12.0005 9.65039C11.4485 9.65039 11.0005 9.20239 11.0005 8.65039Z" fill="currentColor" />
  </svg>,
  'InfoFilled',
);

export default InfoFilledIcon;
