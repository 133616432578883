import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from "store/store";
import { Rules } from "components/Moderation/types/ModerationTabs";
import axios from "axios";
import { IStatus } from "types/commonTypes";

export type ProfileImplications = 'BLOCK_PUBLICATION' | 'BAN_PROFILE' | 'NO_IMPLICATIONS';
export type Decision = 'DELETE' | 'LIMIT';
export type Term = 'FOR_PERIOD' | 'PERMANENTLY';

export interface Restriction {
  age?: string;
  location?: string;
}

export interface HiveRule {
  id: string;
  metric: string;
  decision: Decision;
  type: string;
  reviewMinScore: number;
  decisionMinScore: number;
  restrictions?: Restriction[];
  profileImplicationDto: {
    countForPeriod: number;
    periodUnit: string;
    periodCount: number;
    profileDecision: ProfileImplications;
    term: Term;
    decisionPeriodUnit: string;
    decisionPeriodCount: number;
  }
}

export interface ManualRule {
  id: string;
  metric: string;
  decision: Decision;
  type: string;
  restrictions?: Restriction[];
  profileImplicationDto:  {
    countForPeriod: number;
    periodUnit: string;
    periodCount: number;
    profileDecision: ProfileImplications;
    term: Term;
    decisionPeriodUnit: string;
    decisionPeriodCount: number;
  }
}

export interface ProfileRule {
  id: string;
  metric: string;
  countForPeriod: number;
  periodUnit: string;
  periodCount: number;
  profileDecision: ProfileImplications;
  term: Term;
  decisionPeriodUnit: string;
  decisionPeriodCount: number;
}

export interface IRules {
  hive: HiveRule[];
  manual: ManualRule[];
  profile: ProfileRule[];
}

export interface IColumnsRules {
  hive: any[];
  manual: any[];
  profile: any[];
}

const initialColumnsState: IColumnsRules = {
  hive: [
    {
      columnId: 'metric',
      columnName: "Category",
      flex: 1,
      sortable: true,
    },
    {
      columnId: 'threshold',
      columnName: "Threshold",
      width: 176,
      sortable: false,
    },
    {
      columnId: 'decision',
      columnName: "Decision",
      width: 160,
      sortable: false,
    },
    {
      columnId: 'profileImplication',
      columnName: "Profile implication",
      width: 256,
      sortable: false,
    },
    {
      columnId: 'action',
      columnName: "",
      width: 96,
      sortable: false,
    },
  ],
  manual: [
    {
      columnId: 'metric',
      columnName: "Category",
      flex: 1,
      sortable: true,
    },
    {
      columnId: 'decision',
      columnName: "Decision",
      width: 160,
      sortable: false,
    },
    {
      columnId: 'profileImplication',
      columnName: "Profile implication",
      width: 256,
      sortable: false,
    },
    {
      columnId: 'action',
      columnName: "",
      width: 96,
      sortable: false,
    },
  ],
  profile: [
    {
      columnId: 'metric',
      columnName: "Category",
      flex: 1,
      sortable: true,
    },
    {
      columnId: 'profileImplication',
      columnName: "Profile implication",
      width: 256,
      sortable: false,
    },
    {
      columnId: 'action',
      columnName: "",
      width: 96,
      sortable: false,
    },
  ],
}

export interface RulesState {
  columns: IColumnsRules;
  rulesData: IRules;
  status: IStatus;
}

const initialState: RulesState = {
  columns: initialColumnsState,
  rulesData: {
    hive: [],
    manual: [],
    profile: [],
  },
  status: 'idle',
};

export const fetchRules = createAsyncThunk(
  'rules/fetchRules',
  async (params: Rules) => {
    let dataUrl;

    switch (params) {
      case Rules.HIVE: {
        dataUrl = 'moderation/hive-rules/full';
        break;
      }
      case Rules.MANUAL: {
        dataUrl = 'moderation/manual-content-rules/full';
        break;
      }
      case Rules.PROFILE: {
        dataUrl = 'moderation/profile-only-rules';
        break;
      }
    }

    const response = await axios.get((process.env.REACT_APP_MODERATION_RULES_ENDPOINT as string) + dataUrl);
    return {...response, typeRule: params};
  }
);

export const fetchAddRule = createAsyncThunk(
  'rules/addRule',
  async ({ type, data }: { type: Rules, data: any }) => {
    let dataUrl;

    switch (type) {
      case Rules.HIVE: {
        dataUrl = 'moderation/hive-rules';
        break;
      }
      case Rules.MANUAL: {
        dataUrl = 'moderation/manual-content-rules';
        break;
      }
      case Rules.PROFILE: {
        dataUrl = 'moderation/manual-profile-rules';
        break;
      }
    }

    const response = await axios.post((process.env.REACT_APP_MODERATION_RULES_ENDPOINT as string) + dataUrl, {
      ...data
    });
    return {...response, typeRule: type };
  }
);

export const fetchUpdateRule = createAsyncThunk(
  'rules/updateRule',
  async ({ type, data }: { type: Rules, data: any }) => {
    let dataUrl;

    switch (type) {
      case Rules.HIVE: {
        dataUrl = `moderation/hive-rules/${data.id}`;
        break;
      }
      case Rules.MANUAL: {
        dataUrl = `moderation/manual-content-rules/${data.id}`;
        break;
      }
      case Rules.PROFILE: {
        dataUrl = `moderation/manual-profile-rules/${data.id}`;
        break;
      }
    }

    const response = await axios.post((process.env.REACT_APP_MODERATION_RULES_ENDPOINT as string) + dataUrl, {
      ...data
    });
    return {...response, typeRule: type };
  }
);

export const fetchDeleteRule = createAsyncThunk(
  'rules/deleteRule',
  async ({ type, data }: { type: Rules, data: any }) => {
    let dataUrl;

    switch (type) {
      case Rules.HIVE: {
        dataUrl = `moderation/hive-rules/${data.id}`;
        break;
      }
      case Rules.MANUAL: {
        dataUrl = `moderation/manual-content-rules/${data.id}`;
        break;
      }
      case Rules.PROFILE: {
        dataUrl = `moderation/manual-profile-rules/${data.id}`;
        break;
      }
    }

    const response = await axios.delete((process.env.REACT_APP_MODERATION_RULES_ENDPOINT as string) + dataUrl);
    return {...response, ...data, typeRule: type };
  }
);

export const rulesSlice = createSlice({
  name: 'rules',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRules.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRules.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        if (payload.typeRule === Rules.HIVE) {
          state.rulesData.hive = payload.data;
        }
        if (payload.typeRule === Rules.MANUAL) {
          state.rulesData.manual = payload.data;
        }
        if (payload.typeRule === Rules.PROFILE) {
          state.rulesData.profile = payload.data;
        }
      })
      .addCase(fetchRules.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchAddRule.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAddRule.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        if (payload.typeRule === Rules.HIVE) {
          state.rulesData.hive = [...state.rulesData.hive, payload.data];
        }
        if (payload.typeRule === Rules.MANUAL) {
          state.rulesData.manual = [...state.rulesData.manual, payload.data];
        }
        if (payload.typeRule === Rules.PROFILE) {
          state.rulesData.profile = [...state.rulesData.profile, payload.data];
        }
      })
      .addCase(fetchAddRule.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchUpdateRule.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUpdateRule.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        if (payload.typeRule === Rules.HIVE) {
          state.rulesData.hive = [...state.rulesData.hive.filter((value) => value.id !== payload.data.id), payload.data];
        }
        if (payload.typeRule === Rules.MANUAL) {
          state.rulesData.manual = [...state.rulesData.manual.filter((value) => value.id !== payload.data.id), payload.data];
        }
        if (payload.typeRule === Rules.PROFILE) {
          state.rulesData.profile = [...state.rulesData.profile.filter((value) => value.id !== payload.data.id), payload.data];
        }
      })
      .addCase(fetchUpdateRule.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchDeleteRule.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDeleteRule.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        if (payload.typeRule === Rules.HIVE) {
          state.rulesData.hive = state.rulesData.hive.filter((value) => value.id !== payload.id);
        }
        if (payload.typeRule === Rules.MANUAL) {
          state.rulesData.manual = state.rulesData.manual.filter((value) => value.id !== payload.id);
        }
        if (payload.typeRule === Rules.PROFILE) {
          state.rulesData.profile = state.rulesData.profile.filter((value) => value.id !== payload.id);
        }
      })
      .addCase(fetchDeleteRule.rejected, (state) => {
        state.status = 'failed';
      })
  },
});

export const selectRulesStatus = (state: RootState): IStatus => state.rules.status;
export const selectRules = (state: RootState): IRules => state.rules.rulesData;
export const selectColumn = (state: RootState): IColumnsRules => state.rules.columns;

export default rulesSlice.reducer;