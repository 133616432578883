const TaxModerationIcon = () => (
  <svg className="tax-moderation-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.8974 22C2.9886 22 2 18.6667 2 12C2 5.33333 3.11217 2 10.8974 2C15.3921 2 19 3.90476 19 8.19048" stroke="#BFC6E2" strokeWidth="1.7" strokeLinecap="round"/>
    <path d="M9.26215 2C9.26215 2 10.1331 4.87559 8.54188 6.20157C6.95071 7.52755 3.5 6.80179 3.5 6.80179" stroke="#BFC6E2" strokeWidth="1.7" strokeLinecap="round"/>
    <path className="tax-moderation-icon__sign" d="M11.677 7.63591C11.677 7.28471 11.3739 7 11 7C10.6261 7 10.323 7.28471 10.323 7.63591V7.92623C9.04176 8.15693 8 9.11508 8 10.4131L8 10.4148L8.00001 10.4168L8.00005 10.4211L8.00024 10.4317C8.00042 10.4396 8.00074 10.449 8.00128 10.46C8.00235 10.4819 8.00429 10.51 8.00778 10.5434C8.01474 10.61 8.028 10.699 8.0534 10.8031C8.10386 11.0099 8.20493 11.2873 8.4095 11.5668C8.84453 12.1612 9.65006 12.6359 11 12.6359C11.9731 12.6359 12.329 12.9546 12.4748 13.1537C12.5606 13.271 12.6047 13.3903 12.6268 13.481C12.6377 13.5257 12.6426 13.5607 12.6447 13.5809L12.646 13.5953C12.6407 14.2545 12.0122 14.8554 11.0774 14.8554C10.4242 14.8554 9.88145 14.5609 9.63525 14.1976C9.43447 13.9014 9.01602 13.8141 8.70061 14.0027C8.3852 14.1913 8.29228 14.5843 8.49306 14.8806C8.89236 15.4698 9.56478 15.8806 10.323 16.046V16.3641C10.323 16.7153 10.6261 17 11 17C11.3739 17 11.677 16.7153 11.677 16.3641V16.0738C12.9582 15.8431 14 14.8849 14 13.5869L14 13.5852L14 13.5832L14 13.5789L13.9998 13.5683L13.9995 13.5601L13.9987 13.54C13.9977 13.5181 13.9957 13.49 13.9922 13.4566C13.9853 13.39 13.972 13.301 13.9466 13.1969C13.8961 12.9901 13.7951 12.7127 13.5905 12.4332C13.1555 11.8388 12.3499 11.3641 11 11.3641C10.0269 11.3641 9.67096 11.0454 9.52524 10.8463C9.43943 10.729 9.39531 10.6097 9.37317 10.519C9.36228 10.4743 9.35739 10.4393 9.35528 10.4191L9.35402 10.4047C9.35927 9.74551 9.98776 9.14464 10.9226 9.14464C11.5758 9.14464 12.1185 9.43908 12.3648 9.80238C12.5655 10.0986 12.984 10.1859 13.2994 9.99733C13.6148 9.80874 13.7077 9.41568 13.5069 9.11941C13.1076 8.5302 12.4352 8.11936 11.677 7.95398V7.63591Z" fill="#BFC6E2"/>
    <path d="M16.3634 18.9274C15.4534 19.856 13.2638 20.5134 13.0428 20.2562C12.8217 19.999 13.4857 17.8788 14.3887 16.9524C15.2916 16.0261 18.0111 13.325 19.7607 11.5801C22.2549 9.08579 24.2297 11.0607 21.7355 13.5551C19.9859 15.2999 17.2734 17.9987 16.3634 18.9274Z" stroke="#BFC6E2" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M19.302 12.2142L21.1628 14.0751" stroke="#BFC6E2" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

);

export default TaxModerationIcon;
