import { createSvgIcon } from '@mui/material';

const StarFilledIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.4907 3.1306C13.9543 2.05247 13.1004 1.22742 11.9988 1.22742C10.8985 1.22742 10.042 2.05099 9.50061 3.1282L7.73981 6.64979L7.73672 6.65603C7.69285 6.7456 7.57906 6.88756 7.39756 7.02213C7.2168 7.15616 7.04726 7.22474 6.94883 7.24173L6.94777 7.24191L3.76076 7.77141C2.59331 7.96599 1.58231 8.53965 1.25286 9.57135C0.923663 10.6022 1.41363 11.6556 2.24903 12.491L4.72903 14.971C4.81165 15.0536 4.91392 15.221 4.97915 15.4488C5.04388 15.675 5.04712 15.8734 5.02112 15.9919L5.02065 15.994L4.31196 19.0583C4.0143 20.3446 4.10582 21.6693 5.06655 22.3703C6.02639 23.0707 7.31627 22.7558 8.45307 22.0814L11.4375 20.3146L11.4397 20.3133C11.5554 20.2468 11.7579 20.1899 12.0038 20.1899C12.2521 20.1899 12.4499 20.2478 12.5567 20.3112L15.5471 22.0814C16.6849 22.7535 17.9769 23.0742 18.9371 22.3743L18.9594 22.3577C19.0529 22.2876 19.1379 22.2115 19.2149 22.13C19.9127 21.3911 19.9533 20.2088 19.6884 19.059L18.9795 15.994L18.979 15.9919C18.953 15.8734 18.9563 15.675 19.021 15.4488C19.0862 15.221 19.1885 15.0536 19.2711 14.971L21.7491 12.493L21.7501 12.492C22.5908 11.6566 23.0824 10.6023 22.7505 9.56981C22.4192 8.53881 21.4064 7.96591 20.2398 7.77148L17.0498 7.24148C16.9457 7.22405 16.7732 7.15448 16.5922 7.02095C16.4106 6.88693 16.2972 6.74534 16.2534 6.65603L14.4907 3.1306Z" />
  </svg>,
  'StarFilled',
);

export default StarFilledIcon;
