import colors from 'theme/constants/colors';
import { ITaxStatus, TaxesStatuses, TaxRuleCertainty } from 'store/slices/Settings/interface';

export const possibleTaxStatuses: ITaxStatus[] = [
  {
    status: TaxesStatuses.ACTIVE,
    title: 'Active',
    color: colors.success,
  },
  {
    status: TaxesStatuses.INACTIVE,
    title: 'Inactive',
    color: colors.error,
  },
  {
    status: TaxesStatuses.DEFAULT,
    title: 'Default',
    color: colors.success,
  },
];

export const selectableTaxStatuses: ITaxStatus[] = [
  {
    status: TaxesStatuses.ACTIVE,
    title: 'Active',
    color: colors.success,
  },
  {
    status: TaxesStatuses.INACTIVE,
    title: 'Inactive',
    color: colors.error,
  },
];

/**
 * Represents sales tax groups.
 *
 * @enum {string}
 * @readonly
 */
export enum SalesTaxGroup {
  RULES = 'Rules',
  CODES = 'Codes',
}

export const possibleTaxRuleCertainty = [
  {
    certainty: TaxRuleCertainty.FULL,
    color: colors.success,
  },
  {
    certainty: TaxRuleCertainty.UNCERTAIN,
    color: colors.warning,
  },
];

/**
 * Enum representing error codes in taxes.
 *
 * @enum {string}
 * @readonly
 * @property {string} TAX_CODE_USED_IN_RULES - Error code for when a tax code is used in the rules.
 * @property {string} INCORRECT_TAX_CODE_STATUS - Error code for incorrect tax code status.
 */
export enum ErrorCodes {
  TAX_CODE_USED_IN_RULES = 'ASTR-001',
  INCORRECT_TAX_CODE_STATUS = 'ASTR-002',
}
