import { useEffect, useState } from 'react';
import { enqueueSnackbar, VariantType } from 'notistack';
import { DateTimePickerTabs } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { IStatus } from 'types/commonTypes';
import { Collection } from 'store/collectionDetailsSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  CohortsLinkedCollection,
  fetchCohortsLinkedCollections,
  fetchPublishCollections,
  selectCohortsLinkedCollections,
} from 'store/worldManagementSlice';
import CalendarIcon from 'assets/img/CalendarIcon';
import Alert from 'components/shared/Alert/Alert';
import Button from 'components/shared/Button/Button';
import DateTimePicker from 'components/shared/DateTimePicker/DateTimePicker';
import LoadingButton from 'components/shared/LoadingButton/LoadingButton';
import './index.scss';

dayjs.extend(utc);

interface IProps {
  items: Collection | Collection[];
  onClose(): void;
  status: IStatus;
}

// todo проверить, когда будут коллекции в статусе Live
const PublishCollectionsModal = ({ items, onClose, status }: IProps) => {
  let content;
  let title;
  const isArray = Array.isArray(items);
  const compilationIds = isArray ? items.map(({ id }) => id) : [items.id];

  const [dateWithoutInitialValue, setDateWithoutInitialValue] = useState<Dayjs | null>(null);
  const [error, setError] = useState(false);
  const [willUnpublishCollections, setWillUnpublishCollections] = useState<CohortsLinkedCollection[]>([]);

  const allLinkedCollections: CohortsLinkedCollection[] | [] = useAppSelector(selectCohortsLinkedCollections);

  const dispatch = useAppDispatch();

  if (Array.isArray(items)) {
    title = `Publish ${items.length} collections`;
    content = (
      <div
        className={`publishCollectionsModal-content ${items.length > 3 && 'publishCollectionsModal-content_scroll'}`}
      >
        {items.map((item) => (
          <div className="item-content" key={item.id}>
            <div className="item-primary">{item.title}</div>
            <div className="item-secondary">
              {item.creatorName} {item.creatorLastName}
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    title = `Publish ${items.title} collection`;
    content = null;
  }

  const unpublishList = !!willUnpublishCollections.length ? (
    <Alert severity="warning">
      {!isArray ? (
        `Collection "${willUnpublishCollections[0].compilation.title}" for "${willUnpublishCollections[0].cohort.title}" cohort will be unpublished`
      ) : (
        <ul className="publishCollectionsModal-footer-list">
          {willUnpublishCollections.map((unpublish) => (
            <li key={unpublish.compilation.id} className="publishCollectionsModal-footer-listItem">
              {`Collection "${unpublish.compilation.title}" for "${unpublish.cohort.title}" cohort will be unpublished`}
            </li>
          ))}
        </ul>
      )}
    </Alert>
  ) : null;

  const handlePublish = async () => {
    try {
      const result = await dispatch(
        fetchPublishCollections({
          compilationIds: compilationIds,
          publishDateTime: dayjs().utc(true).format('YYYY-MM-DDTHH:mm:ss[Z]'),
          deactivatedDateTime: dateWithoutInitialValue
            ? dateWithoutInitialValue.utc(true).format('YYYY-MM-DDTHH:mm:ss[Z]')
            : undefined,
        }),
      );
      if (result.meta.requestStatus === 'fulfilled') {
        onClose();
        enqueueSnackbar(
          `${isArray ? items.length + 'collections have' : `"${items.title}" collection has`} been published`,
          { variant: 'success' as VariantType },
        );
      }
      result.meta.requestStatus === 'rejected' &&
        enqueueSnackbar('Publishing error, try again later', { variant: 'error' as VariantType });
      result.meta.requestStatus === 'rejected' && setError(true);
    } catch (e) {
      setError(true);
    }
  };

  useEffect(() => {
    dispatch(fetchCohortsLinkedCollections());
  }, []);

  useEffect(() => {
    setWillUnpublishCollections(
      allLinkedCollections.filter((linked) => compilationIds.includes(linked.compilation.id)),
    );
  }, [allLinkedCollections, compilationIds]);

  return (
    <div className="publishCollectionsModal">
      <div className="publishCollectionsModal-header">{title}</div>
      {content}
      <div className="publishCollectionsModal-footer">
        <DateTimePicker
          minDateTime={dayjs()}
          onChange={(newValue) => {
            setDateWithoutInitialValue(newValue);
          }}
          label="Deactivation date & time (optional)"
          value={dateWithoutInitialValue}
          slots={{
            openPickerIcon: CalendarIcon,
            tabs: DateTimePickerTabs,
          }}
          slotProps={{
            textField: {
              size: 'small',
              fullWidth: true,
            },
          }}
        />
        {error ? <Alert severity="error">Publishing error. Try later</Alert> : unpublishList}
        <div className="publishCollectionsModal-footer-buttons">
          <Button onClick={onClose} variant="text" color="primary">
            Cancel
          </Button>
          <LoadingButton variant="contained" color="primary" loading={status === 'loading'} onClick={handlePublish}>
            Publish collection{Array.isArray(items) ? 's' : ''}
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};

export default PublishCollectionsModal;
