import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useErrorBoundary } from 'react-error-boundary';
import withAuth from "components/Authorization/withAuth";
import { ErrorBoundaryErrors } from "containers/ErrorPage/ErrorPage";
import { fetchArchiveBanner, fetchExpireBanner, selectLinkedCollections, selectWorldColumns } from "store/worldManagementSlice";
import { useParams, useNavigate } from "react-router-dom";
import Modal from "components/shared/Modal/Modal";
import ArchiveBannersModal from "components/WorldManagement/Banners/BannersModal/ArchiveBanners/ArchiveBannersModal";
import ExpiredBannerModal from "components/WorldManagement/Banners/BannersModal/ExpiredBanner/ExpiredBannerModal";
import ManageLinkedCollectionsModal
  from "components/ManageLinkedCollections/ManageLinkedCollectionsModal";
import { Banner, fetchBanner, selectBanner, selectBannerStatus } from "store/bannerDetailsSlice";
import BannerDetailsForm from "components/WorldManagement/Banners/BannerDetails/BannerDetailsForm";
import BannerDetailsCloseModal
  from "components/WorldManagement/Banners/BannerDetails/BannerDetailsModal/BannerDetailsCloseModal";
import SetCover, { CoverAction } from "components/WorldManagement/Banners/BannerDetails/BannerDetailsModal/SetCover";

import './index.scss';
import { VariantType, enqueueSnackbar } from 'notistack';

function BannerDetails() {
  const [archiveBanners, setArchiveBanners] = useState<Banner | null>(null);
  const [expiredBanners, setExpiredBanners] = useState<Banner | null>(null);
  const [openLinkedCollections, setOpenLinkedCollections] = useState<Banner | null>(null);
  const [showSetAvatar, setShowSetAvatar] = useState<{ show: boolean, action: CoverAction }>({ show: false, action: 'edit' });
  const [isOpenClosingModal, setIsOpenClosingModal] = useState(false);

  let { bannerId } = useParams();
  const { showBoundary } = useErrorBoundary();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const bannerDetails = useAppSelector(selectBanner);
  const bannerStatus = useAppSelector(selectBannerStatus);

  const linkedCollections = useAppSelector(selectLinkedCollections);
  const columns = useAppSelector(selectWorldColumns);

  const initialFetchData = useCallback(async () => {
    Promise.all([
      bannerId && dispatch(fetchBanner(bannerId)),
    ]).then(result => {
      result.forEach((value: any) => {
        if (value.meta && value.meta.requestStatus === 'rejected' && ErrorBoundaryErrors.includes(value.error.message)) {
          showBoundary( new Error(value.error.message));
        }
      })
    });
  }, []);

  useEffect(() => {
    initialFetchData();
  }, []);

  const [errorMessage, setErrorMessage] = useState('');

  const archiveBannerFunc = useCallback(async (items: Banner | Banner[]) => {
    try {
    setErrorMessage('');
    const isArray = Array.isArray(items);
    const data = isArray ? items.map(item => item.id) : [items.id];
    const result = await dispatch(fetchArchiveBanner(data));
    if(result.meta.requestStatus === 'fulfilled') {
      if(isArray) {
        setArchiveBanners(null);
        enqueueSnackbar(`${items.length} banners have been archived`, { variant: 'success' as VariantType });
      } else {
        setArchiveBanners(null)
        enqueueSnackbar(`${items.name} banner has been archived`, { variant: 'success' as VariantType });
      }
    } else if (result.meta.requestStatus === 'rejected') {
      if(isArray)
        setErrorMessage('Archiving error, try again later')
      } else {
        setArchiveBanners(null)
        enqueueSnackbar(`Archiving error, try again later`, { variant: 'error' as VariantType });
      }
    } catch {
      if(Array.isArray(items)) {
        setErrorMessage('Archiving error. Try later')
      } else {
        setArchiveBanners(null)
        enqueueSnackbar(`Archiving error, try again later`, { variant: 'error' as VariantType });
      }
    }
  }, [dispatch]);

  const expireBannerFunc = useCallback(async (item: Banner) => {
    const result = await dispatch(fetchExpireBanner([item.id]));
    if(result.meta.requestStatus === 'fulfilled') {
      setExpiredBanners(null);
      enqueueSnackbar(`${item.name} banner has been set as expired`, { variant: 'success' as VariantType });
    } else if (result.meta.requestStatus === 'rejected') {
      setExpiredBanners(null);
      enqueueSnackbar(`Saving error, try again later`, { variant: 'error' as VariantType });
    }
  }, [dispatch])

  return (
    <div className="bannerDetails-container">
      {bannerStatus === 'idle' && bannerDetails &&
        <BannerDetailsForm
          bannerDetails={bannerDetails}
          setArchiveBanners={setArchiveBanners}
          setExpiredBanners={setExpiredBanners}
          setOpenLinkedCollections={setOpenLinkedCollections}
          setIsOpenClosingModal={() => setIsOpenClosingModal(true)}
          refreshData={initialFetchData}
          setShowSetAvatar={(action: CoverAction) => setShowSetAvatar({ show: true, action })}
        />
      }
      <Modal customstyle={{ minHeight: 208 }} open={!!archiveBanners} onClose={() => {setArchiveBanners(null); setErrorMessage('')}}>
        <ArchiveBannersModal onClose={() => {setArchiveBanners(null); setErrorMessage('')}} errorMessage={errorMessage} items={archiveBanners!} archive={(items) => {archiveBannerFunc(items)}} />
      </Modal>
      <Modal open={!!expiredBanners} onClose={() => setExpiredBanners(null)}>
        <ExpiredBannerModal onClose={() => setExpiredBanners(null)} item={expiredBanners!} expire={(item) => expireBannerFunc(item)} />
      </Modal>
      {bannerDetails && !!openLinkedCollections &&
        <ManageLinkedCollectionsModal
          open={!!openLinkedCollections}
          onClose={() => setOpenLinkedCollections(null)}
          entityType={'BANNER'}
          entityId={bannerDetails.id}
        />
      }
      {bannerDetails && <Modal open={showSetAvatar.show} onClose={() => setShowSetAvatar({ show: false, action: 'edit' })}>
        <SetCover
          coverAction={showSetAvatar.action}
          avatar={showSetAvatar.action === 'edit' ? bannerDetails.cover : null }
          bannerId={bannerDetails.id}
          onClose={() => setShowSetAvatar({ show: false, action: 'edit' })}
        />
      </Modal>
      }
      <Modal customstyle={{ minHeight: 188 }} open={isOpenClosingModal}>
        <BannerDetailsCloseModal
          closeModal={() => setIsOpenClosingModal(false)}
          onClose={() => {
            navigate("/ui/world-management/banners");
          }
        }
        />
      </Modal>
    </div>
  );
}

export default withAuth(BannerDetails);
