import { createSvgIcon } from '@mui/material';

const ArrowDownIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.8499 3.4999C12.8499 3.03046 12.4693 2.6499 11.9999 2.6499C11.5305 2.6499 11.1499 3.03046 11.1499 3.4999V18.4478L6.53112 13.829C6.19917 13.4971 5.66098 13.4971 5.32904 13.829C4.99709 14.161 4.99709 14.6992 5.32904 15.0311L11.399 21.1011C11.5584 21.2605 11.7746 21.3501 12.0001 21.3501C12.2255 21.3501 12.4417 21.2605 12.6011 21.1011L18.6711 15.0311C19.0031 14.6992 19.0031 14.161 18.6711 13.829C18.3392 13.4971 17.801 13.4971 17.469 13.829L12.8499 18.4482V3.4999Z" />
  </svg>,
  'ArrowDown',
);

export default ArrowDownIcon;
