import React from "react";
import Button from "components/shared/Button/Button";

import 'components/WorldManagement/Banners/BannersModal/AddBanner/index.scss';

interface IProps {
  onClose(): void;
  closeModal(): void;
}

const AddBannerModalCancel = ({ onClose, closeModal }: IProps) => {

  return (
    <div className='addBannerModal'>
      <div className="addBannerModal-header">
        Cancel adding new banner?
      </div>
      <div className="addBannerModal-content">
        Banner won’t be added
      </div>
      <div className="addBannerModal-footer">
        <div className="addBannerModal-footer-buttons">
          <Button onClick={closeModal}>Don’t cancel</Button>
          <Button
            onClick={() => {
              closeModal();
              onClose();
            }}
            variant="contained"
            color= "primary"
          >
            Cancel adding
          </Button>
        </div>
      </div>
    </div>
  )
};

export default AddBannerModalCancel;