import { MenuItem as MenuItemMUI } from '@mui/material';
import { MenuItemProps } from '@mui/material/MenuItem/MenuItem';
import './index.scss';

const MenuItem = (props: MenuItemProps) => {
  return (
    <MenuItemMUI {...props} />
  );
};

export default MenuItem;
