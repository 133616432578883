import { GridColumnHeaderParams } from '@mui/x-data-grid-pro';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import Typography from 'components/shared/Typography/Typography';

/**
 * Props for the RenderHeaderWithSecondaryText component.
 *
 * @interface
 * @property {string} secondaryText - Cell's secondary text.
 * @property {GridColumnHeaderParams} props - Parameters for rendering the cell.
 */
interface IRenderHeaderWithTooltipProps {
  secondaryText: string;
  tooltipTitle?: string;
  props: GridColumnHeaderParams;
}

/**
 * Renders a header cell with secondary text in a data grid.
 */
export const RenderHeaderWithSecondaryText = ({
  secondaryText,
  tooltipTitle,
  props,
}: IRenderHeaderWithTooltipProps) => {
  const { colDef } = props;

  return (
    <Tooltip placement="top" followCursor title={tooltipTitle || ''}>
      <Typography variant="tableHeader">
        {colDef.headerName}
        {secondaryText && (
          <Typography variant="tableHeader" color="text.secondary">
            {secondaryText}
          </Typography>
        )}
      </Typography>
    </Tooltip>
  );
};
