import React, { ChangeEvent, FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import uniqueId from 'lodash/uniqueId';
import { useErrorBoundary } from 'react-error-boundary';
import { enqueueSnackbar, VariantType } from 'notistack';
import isEqual from 'lodash/isEqual';
import startCase from 'lodash/startCase';
import { possiblePaymentFeesStatuses } from './constants';
import { currencyFormatter } from 'utils/currencyFormatter';
import { Permissions } from 'types/commonTypes';
import { IPaymentFee, IPaymentFeeStatus } from 'store/slices/Settings/interface';
import { checkPermissions } from 'utils/checkPermissions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  fetchPaymentFees,
  selectPaymentFees,
  selectPaymentFeesStatus,
  selectTotalCount,
  setPaymentFeesStatus,
} from 'store/slices/Settings/paymentFeesSlice';
import {
  clearFilters,
  fetchFilters,
  reverseFilters,
  selectFilters,
  selectFiltersCurrentData,
  selectFiltersData,
} from 'store/filtersSlice';
import { selectUserPermissions } from 'store/authSlice';
import DeleteIcon from 'assets/img/DeleteIcon';
import EditIcon from 'assets/img/EditIcon';
import FilterIcon from 'assets/img/FilterIcon';
import ActiveFilterList from 'components/ActiveFilterList/ActiveFilterList';
import Button from 'components/shared/Button/Button';
import CheckIfEmptyCell from 'components/shared/DataGrid/CheckIfEmptyCell/CheckIfEmptyCell';
import CustomFooter from 'components/shared/DataGrid/CustomFooter/CustomFooter';
import CustomNoRowsOverlay from 'components/shared/DataGrid/CustomNoResultsOverlay/CustomNoRowsOverlay';
import Drawer from 'components/shared/Drawer/Drawer';
import FilterDrawer from 'components/FilterDrawer/FilterDrawer';
import Grid from 'components/shared/Grid/Grid';
import ListItemIcon from 'components/shared/List/ListItem/ListItemComponents/ListItemIcon/ListItemIcon';
import ListItemText from 'components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText';
import Menu from 'components/shared/Menu/Menu';
import MenuItem from 'components/shared/Menu/MenuItem/MenuItem';
import RenderCellWithCopy from 'components/shared/DataGrid/RenderCellWithCopy/RenderCellWithCopy';
import SearchField from 'components/shared/SearchField/SearchField';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';
import { CloseFilterDrawerConfirmModal } from 'components/FilterDrawer/CloseFilterDrawerConfirmModal';
import { RenderActionsCell, RenderStatusSelect } from './components';
import { AddFeeModal } from './modals';
import './index.scss';

export const PaymentFees: FC = () => {
  const dispatch = useAppDispatch();

  const userPermissions = useAppSelector(selectUserPermissions);
  const data = useAppSelector(selectPaymentFees);
  const totalCount = useAppSelector(selectTotalCount);
  const status = useAppSelector(selectPaymentFeesStatus);
  const filters = useAppSelector(selectFilters);
  const filtersCurrentData = useAppSelector(selectFiltersCurrentData);
  const filtersData = useAppSelector(selectFiltersData);
  let isLoading = status === 'loading';

  const filtersLength = useMemo(() => {
    return Object.values(filtersData).filter((item: any) => item !== null).length;
  }, [filtersData]);

  const { showBoundary } = useErrorBoundary();
  const [isAddingFee, setIsAddingFee] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [highlightedProviderTariffId, setHighlightedProviderTariffId] = useState<string | null>(null);

  const [filterDrawer, setFilterDrawer] = useState<boolean>(false);
  const [filterDrawerCloseConfirmModal, setFilterDrawerCloseConfirmModal] = useState<boolean>(false);
  const [fetchingError, setFetchingError] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [tableMenuAnchorEl, setTableMenuAnchorEl] = useState<null | HTMLElement>(null);
  const tableMenu = Boolean(tableMenuAnchorEl);

  const [statusMenuAnchorEl, setStatusMenuAnchorEl] = useState<null | HTMLElement>(null);
  const statusMenuOpen = Boolean(statusMenuAnchorEl);

  const [currentSelectedStatus, setCurrentSelectedStatus] = useState<IPaymentFeeStatus | null>(null);
  const [currentSelectedFee, setCurrentSelectedFee] = useState<IPaymentFee | null>(null);

  const onFilterSidebarClose = useCallback(
    (apply?: boolean) => {
      if (isEqual(filtersData, filtersCurrentData) || apply === true) {
        setFilterDrawerCloseConfirmModal(false);
        setFilterDrawer(false);
        dispatch(reverseFilters());
      } else {
        setFilterDrawerCloseConfirmModal(true);
      }
    },
    [filtersData, filtersCurrentData],
  );

  const cancelCloseFilterSidebar = useCallback((event?: any, reason?: any) => {
    if (reason && reason === 'backdropClick') return;
    setFilterDrawerCloseConfirmModal(false);
  }, []);

  const handleChangePageSize = (pageSize: number) => {
    setPage(0);
    setPageSize(pageSize);
    void fetchPaymentFeesGrid(0, pageSize);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
    void fetchPaymentFeesGrid(page, pageSize);
  };

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleStatusMenuClick = (event: MouseEvent<HTMLDivElement>, data: IPaymentFee) => {
    event.stopPropagation();
    setStatusMenuAnchorEl(event.currentTarget);
    setCurrentSelectedFee(data);
  };

  const handleTableMenuClick = (event: MouseEvent<HTMLButtonElement>, data: IPaymentFee) => {
    event.stopPropagation();
    setTableMenuAnchorEl(event.currentTarget);
    setCurrentSelectedFee(data);
  };

  const handleTableMenuClose = () => {
    setTableMenuAnchorEl(null);
  };

  const handleStatusMenuClose = () => {
    setStatusMenuAnchorEl(null);
  };

  const handleChangeStatusClick = (elem: IPaymentFeeStatus) => {
    setCurrentSelectedStatus(elem);
    handleStatusMenuClose();
  };

  const handleAddFeeModalClose = (isAdded?: boolean) => {
    setIsAddingFee(false);
    if (isAdded) {
      dispatch(fetchPaymentFees({ page: page, pageSize: pageSize })).then((result) => {
        if (!isLoading) {
          setHighlightedProviderTariffId(result.payload?.content[0]?.providerTariffId);
          setTimeout(() => {
            setHighlightedProviderTariffId(null);
          }, 2000);
        }
      });
    }
  };

  const fetchPaymentFeesInitial = useCallback(
    async (page: number, pageSize: number) => {
      try {
        await dispatch(fetchPaymentFees({ page, pageSize })).unwrap();
      } catch (error) {
        showBoundary(error);
      }
    },
    [dispatch],
  );

  const fetchPaymentFeesGrid = useCallback(
    async (page: number, pageSize: number) => {
      dispatch(fetchPaymentFees({ page, pageSize })).then((result: any) => {
        if (result.meta && result.meta.requestStatus === 'rejected') {
          enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType });
          setFetchingError(true);
        } else if (result.meta && result.meta.requestStatus === 'fulfilled') {
          setFetchingError(false);
        }
      });
    },
    [dispatch],
  );

  const repeatFetchingRequest = useCallback(async () => {
    await fetchPaymentFeesGrid(page, pageSize);
  }, [dispatch, page, pageSize]);

  const resetFilters = useCallback(async () => {
    dispatch(clearFilters());
    await dispatch(fetchPaymentFees({ page, pageSize }));
  }, []);

  const initialFetchData = useCallback(async () => {
    dispatch(setPaymentFeesStatus('loading'));
    await dispatch(fetchFilters('fees'));
    if (filters) {
      await fetchPaymentFeesInitial(page, pageSize);
    }
  }, [dispatch, filters, page, pageSize]);

  useEffect(() => {
    dispatch(clearFilters());
    initialFetchData();
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'providerTariffId',
      headerName: 'ID',
      flex: 0.6,
      minWidth: 160,
      sortable: false,
      renderCell: (params) => <RenderCellWithCopy isWrappedText props={params} />,
    },
    {
      field: 'providerName',
      headerName: 'Provider',
      flex: 0.5,
      minWidth: 80,
      sortable: false,
      renderCell: ({ value }) => <span>{value ? startCase(value.toLowerCase()) : 'No provider'}</span>,
    },
    {
      field: 'transactionType',
      headerName: 'Transaction type',
      flex: 0.5,
      minWidth: 120,
      sortable: false,
    },
    {
      field: 'paymentMethodName',
      headerName: 'Payment method',
      flex: 0.5,
      minWidth: 120,
      sortable: false,
      renderCell: ({ value }) => <span>{value ? value : '—'}</span>,
    },
    {
      field: 'paymentFeePercentage',
      headerName: 'Payment fee percentage',
      flex: 0.5,
      minWidth: 190,
      sortable: false,
      type: 'number',
      renderCell: (params) => CheckIfEmptyCell(params),
      valueFormatter: ({ value }) => `${Number(value).toString()}%`,
    },
    {
      field: 'paymentFeeFixed',
      headerName: 'Payment fee fixed',
      flex: 0.3,
      minWidth: 150,
      sortable: false,
      type: 'number',
      renderCell: ({ value }) => <span>{Number(value) ? currencyFormatter(value, 'en-US', 'USD', true) : '—'}</span>,
    },
    {
      field: 'feeStatus',
      headerName: 'Status',
      flex: 0.3,
      minWidth: 80,
      sortable: false,
      renderCell: (params) => (
        <RenderStatusSelect
          props={params}
          selectedCellData={currentSelectedFee}
          isMenuOpen={statusMenuOpen}
          onClick={(e, data) => handleStatusMenuClick(e, data)}
        />
      ),
    },
    {
      field: 'hidden',
      headerName: '',
      sortable: false,
      flex: 0.2,
      minWidth: 60,
      renderCell: (params) => <RenderActionsCell props={params} onClick={(e, data) => handleTableMenuClick(e, data)} />,
    },
  ];

  const rows: IPaymentFee[] | [] = useMemo(() => {
    if (data) {
      return data;
    } else return [];
  }, [data]);

  const isEmptyData = !rows.length;
  const isDisabledFilterButton = !((!isEmptyData && !fetchingError) || !!filtersLength);

  const isActionMenuVisible =
    checkPermissions(userPermissions, [Permissions.SETTINGS_EDIT_PAYMENT_FEE]) ||
    checkPermissions(userPermissions, [Permissions.SETTINGS_DELETE_PAYMENT_FEE]);

  return (
    <div className="fees-table-container">
      <div className="fees-table-container__actions">
        <Stack direction="row" gap={2}>
          <Button
            disabled={isDisabledFilterButton}
            size="small"
            startIcon={<FilterIcon />}
            variant="outlined"
            onClick={() => setFilterDrawer(true)}
          >
            Filter {!!filtersLength && `(${filtersLength})`}
          </Button>
        </Stack>
        <Stack direction="row" gap={2}>
          {checkPermissions(userPermissions, [Permissions.SETTINGS_ADD_PAYMENT_FEE]) && (
            <Button variant="contained" onClick={() => setIsAddingFee(true)}>
              <Typography variant="buttonMedium">Add fee</Typography>
            </Button>
          )}
          {!isEmptyData && (
            <SearchField
              searchValue={searchValue}
              onChange={handleChangeSearch}
              clearEvent={() => setSearchValue('')}
              label="Search by ID"
              maxlength={50}
              disabled
            />
          )}
        </Stack>
      </div>
      {filtersLength !== 0 && <ActiveFilterList refreshData={() => fetchPaymentFeesGrid(page, pageSize)} />}
      <Grid
        className="fees-table"
        columns={columns}
        rows={rows}
        getRowId={(row) => row.providerTariffId}
        loading={isLoading}
        getRowHeight={() => 'auto'}
        getRowSpacing={() => ({
          bottom: 16,
        })}
        getRowClassName={(params) =>
          `${params.row.providerTariffId === highlightedProviderTariffId ? 'fees-table__highlighted-row' : ''}`
        }
        page={page}
        pageSize={pageSize}
        pagination
        paginationMode="server"
        filterMode="server"
        sortingMode="server"
        rowCount={totalCount}
        rowsPerPageOptions={[10, 25, 50, 100]}
        disableColumnMenu
        disableColumnReorder
        disableColumnResize
        disableSelectionOnClick
        columnVisibilityModel={{
          hidden: isActionMenuVisible,
        }}
        onPageChange={(newPage) => handleChangePage(newPage)}
        onPageSizeChange={(newPageSize) => handleChangePageSize(newPageSize)}
        components={{
          NoRowsOverlay: () =>
            CustomNoRowsOverlay({
              isFiltersApplied: !!filtersLength,
              fetchingError: fetchingError,
              clearFilters: () => resetFilters(),
              refreshData: () => repeatFetchingRequest(),
              renderNoDataComponent: () => (
                <>
                  <Typography color="text.secondary" variant="body2">
                    No payment fee has been created yet
                  </Typography>
                  {checkPermissions(userPermissions, [Permissions.SETTINGS_ADD_PAYMENT_FEE]) && (
                    <Button onClick={() => setIsAddingFee(true)} variant="text" color="primary">
                      <Typography variant="buttonMedium">Create fee</Typography>
                    </Button>
                  )}
                </>
              ),
            }),
          Footer: CustomFooter,
        }}
        hideFooter={isEmptyData && !fetchingError}
      />
      <Menu
        id="table-menu"
        anchorEl={tableMenuAnchorEl}
        open={tableMenu}
        onClose={handleTableMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'table-button',
        }}
      >
        {checkPermissions(userPermissions, [Permissions.SETTINGS_EDIT_PAYMENT_FEE]) && (
          <MenuItem disabled>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        )}
        {checkPermissions(userPermissions, [Permissions.SETTINGS_DELETE_PAYMENT_FEE]) && (
          <MenuItem disabled>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        )}
      </Menu>
      <Menu
        id="status-menu"
        anchorEl={statusMenuAnchorEl}
        open={statusMenuOpen}
        onClose={handleStatusMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {possiblePaymentFeesStatuses.map((status: IPaymentFeeStatus) => (
          <MenuItem
            disabled
            key={uniqueId()}
            selected={status.status === currentSelectedFee?.feeStatus}
            onClick={() => handleChangeStatusClick(status)}
          >
            {status.title}
          </MenuItem>
        ))}
      </Menu>
      <AddFeeModal open={isAddingFee} onClose={(isAdded) => handleAddFeeModalClose(isAdded)} />
      <CloseFilterDrawerConfirmModal
        open={filterDrawerCloseConfirmModal}
        onClose={() => onFilterSidebarClose(true)}
        closeModal={() => cancelCloseFilterSidebar()}
      />
      <Drawer anchor="right" open={filterDrawer} onClose={() => onFilterSidebarClose()}>
        <FilterDrawer
          type="fees"
          isHidePresets
          onClose={() => onFilterSidebarClose()}
          onApply={() => {
            onFilterSidebarClose(true);
            setPage(0);
            void fetchPaymentFeesGrid(0, pageSize);
          }}
        />
      </Drawer>
    </div>
  );
};
