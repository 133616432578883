import LocationIcon from 'assets/img/LocationIcon';
import AttentionIcon from 'assets/img/AttentionIcon';
import WiFiIcon from 'assets/img/WiFiIcon';
import './index.scss';

const StatusBlock = ({ status, irl }: any) => {
  const statusTitle = status?.toLowerCase();

  return (
    <div className="statusBlock--outerContainer">
      <div className={`statusBlock--container ${statusTitle?.toLowerCase()}`}>
        {statusTitle === 'online' ? <WiFiIcon /> : <></>}
        {statusTitle === 'offline' ? <LocationIcon /> : <></>}
        {statusTitle === 'canceled' ? <AttentionIcon /> : <></>}
        {statusTitle === 'offline' && irl ? 'irl' : statusTitle}
      </div>
    </div>
  );
};

export default StatusBlock;
