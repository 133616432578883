import { ChangeEvent, forwardRef, useState } from 'react';
import { Fade, Paper, Popper, useAutocomplete } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import uniqueId from 'lodash/uniqueId';
// import startCase from 'lodash/startCase';
// import { useDebounce } from '@uidotdev/usehooks';
import { useAppDispatch } from 'store/hooks';
import List from 'components/shared/List/List';
// import ListItem from 'components/shared/List/ListItem/ListItem';
// import ListItemButton from 'components/shared/List/ListItem/ListItemComponents/ListItemButton/ListItemButton';
// import ListItemText from 'components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText';
import LoadingButton from 'components/shared/LoadingButton/LoadingButton';
import SearchField from 'components/shared/SearchField/SearchField';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';

export const RequestsSearch = () => {
  // const dispatch = useAppDispatch();
  // const navigate = useNavigate();

  const searchResults: readonly any[] = [];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchValue, setSearchValue] = useState('');
  // const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [isSearchError, setIsSearchError] = useState(false);
  // const debouncedSearchValue = useDebounce(searchValue.trim(), 500);

  const { inputValue, getRootProps, getInputProps, getListboxProps, getOptionProps } = useAutocomplete({
    id: 'search-autocomplete',
    options: searchResults ? searchResults : [],
    inputValue: searchValue,
    // getOptionLabel: (option) => option,
    disableCloseOnSelect: true,
  });

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleClearEvent = () => {
    setSearchValue('');
  };

  // const handleSearchEvent = async () => {
  //   setIsLoadingSearch(true);
  //   try {
  //     const result = await dispatch(fetchTaxModerationSearch({ searchValue: debouncedSearchValue, listSize: 6 }));
  //
  //     if (result.meta.requestStatus === 'fulfilled') {
  //       setIsSearchError(false);
  //       setIsLoadingSearch(false);
  //     } else if (result.meta.requestStatus === 'rejected') {
  //       setIsSearchError(true);
  //       setIsLoadingSearch(false);
  //     }
  //   } catch (error) {
  //     setIsSearchError(true);
  //     setIsLoadingSearch(false);
  //   }
  // };

  const handleListItemButtonClick = (id: string) => {
    // navigate(`/ui/tax-moderation/details/${id}`);
  };

  // const renderOptions = (option, index: number): ReactNode => {
  //   const highlightParts = option.entityName?.split(new RegExp(`(${inputValue})`, 'gi'));
  //
  //   return (
  //     <ListItem {...getOptionProps({ option, index })} key={option.id || uniqueId()} disablePadding>
  //       <ListItemButton onClick={() => handleListItemButtonClick(option.id)}>
  //         <ListItemText
  //           disableTypography
  //           primary={
  //             <Typography variant="body1">
  //               {highlightParts.map((part: string, index: Key) => (
  //                 <span style={{ fontWeight: part.toLowerCase() === inputValue.toLowerCase() ? 500 : 400 }} key={index}>
  //                   {part}
  //                 </span>
  //               ))}
  //             </Typography>
  //           }
  //           secondary={
  //             <Typography color="text.secondary" variant="body2">
  //               {startCase(option.entityType.toLowerCase())}
  //             </Typography>
  //           }
  //         />
  //       </ListItemButton>
  //     </ListItem>
  //   );
  // };

  const renderContent = () => {
    if (isSearchError) {
      return (
        <Stack pt={1} alignItems="center" spacing={1}>
          <Typography color="error" variant="body2">
            Loading results error
          </Typography>
          <LoadingButton
            loadingPosition="start"
            startIcon={<></>}
            loading={true}
            // onClick={handleSearchEvent}
            variant="text"
            color="primary"
            sx={{ minWidth: 105, p: 0 }}
          >
            Retry
          </LoadingButton>
        </Stack>
      );
    }

    // if (inputValue && searchResults && searchResults.length > 0) {
    //   return searchResults.map((result, index) => renderOptions(result, index));
    // }

    return (
      <Stack p="8px 0" alignItems="center" gap={1}>
        <Typography color="text.primary" variant="body2">
          There are no matches.
        </Typography>
        <Typography color="text.secondary" variant="body2">
          Try a different query.
        </Typography>
      </Stack>
    );
  };

  const RenderList = forwardRef<any, {}>((_, ref) => {
    return (
      <List {...getListboxProps()} ref={ref}>
        {renderContent()}
      </List>
    );
  });

  // useEffect(() => {
  //   handleSearchEvent();
  // }, [debouncedSearchValue]);

  return (
    <div id="consumtion-requests-search" {...getRootProps()}>
      <SearchField
        disabled
        searchValue={inputValue}
        onChange={handleChangeSearch}
        // searchEvent={handleSearchEvent}
        clearEvent={handleClearEvent}
        onBlur={() => setAnchorEl(null)}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        label="Search by case ID, operation ID"
        maxlength={50}
        inputProps={{ ...getInputProps() }}
      />

      {inputValue.length > 2 && (
        <Popper
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          placement="bottom-start"
          sx={{ minWidth: '300px', borderRadius: '4px', zIndex: 1200 }}
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={300}>
              <Paper elevation={4} sx={{ backgroundColor: 'background.paper', overflow: 'auto', maxHeight: '393px' }}>
                <RenderList />
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
    </div>
  );
};
