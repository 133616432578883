export enum MainRoutes {
  USERS = '/ui/users',
  CONTENT_DISPLAY = '/ui/content-display',
  MEMBERS = '/ui/members',
  PROFILE = '/ui/profile',
  PAYMENTS = '/ui/payments',
  PAYMENTS_SETTINGS = '/ui/payments-settings',
}

export enum PaymentsSettingsRoutes {
  TARIFFS = '/ui/payments-settings/tariffs',
  TARIFFS_DETAILS = '/ui/payments-settings/tariffs/details',
  PAYMENT_FEES = '/ui/payments-settings/payment-fees',
  IN_APP_TIERS = '/ui/payments-settings/in-app-tiers',
  TAXES = '/ui/payments-settings/taxes',
}

export enum PaymentsRoutes {
  OPERATIONS = '/ui/payments/operations',
  BULK_PAYOUTS = '/ui/payments/bulk-payouts',
  FINANCE_DATA = '/ui/payments/finance-data',
  USER_FINANCE_DATA = '/ui/payments/user-finance-data',
}

export enum TaxModerationRoutes {
  TAX_MODERATION = '/ui/tax-moderation',
  TAX_MODERATION_DETAILS = '/ui/tax-moderation/:listType?/details/:id',
  TAX_MODERATION_ALL_RESOLVED = '/ui/tax-moderation/all-resolved',
}

export enum ConsumptionRequestsRoutes {
  CONSUMPTION_REQUESTS = '/ui/consumption-requests',
  CONSUMPTION_REQUESTS_DETAILS = '/ui/consumption-requests/details/:id',
}
