import React, { ForwardedRef, forwardRef } from 'react';
import { Select as SelectBase, InputLabel } from '@mui/material';
import { SelectProps } from '@mui/material/Select/Select';
import colors from 'theme/constants/colors';
import FormControl from 'components/shared/FormControl/FormControl';
import MenuItem from 'components/shared/Menu/MenuItem/MenuItem';

export interface ISelectOptions {
  value: string | number | readonly string[] | undefined;
  text?: string;
  color?: string;
}

interface ISelectProps<T extends ISelectOptions> extends SelectProps {
  options?: T[];
  primary?: boolean;
  label?: string;
  optionsName?: string;
}

const Select = forwardRef(<T extends ISelectOptions>(props: ISelectProps<T>, ref: ForwardedRef<unknown>) => {
  return (
    <FormControl fullWidth={props.fullWidth} variant={props.variant} size={props.size}>
      {props.placeholder ? <InputLabel error={props.error}>{props.placeholder}</InputLabel> : <></>}
      <SelectBase
        ref={ref}
        {...props}
        value={props.value}
        onChange={props.onChange}
        label={props.placeholder}
        sx={{
          '& .MuiSvgIcon-root': {
            color: colors.actionDisabled,
          },
          ...props.sx,
        }}
        MenuProps={{
          sx: { width: 'fit-content' },
        }}
      >
        {(props.options || []).map((item: T, index: number) => (
          <MenuItem
            key={index}
            value={item.value}
            sx={item.color ? { color: item.color, whiteSpace: 'normal' } : { whiteSpace: 'normal' }}
          >
            {props.optionsName ? (item[props.optionsName as keyof T] as string) : item.text}
          </MenuItem>
        ))}
      </SelectBase>
    </FormControl>
  );
});

export default Select;
