import { isValidElement } from 'react';
import { GridRenderCellParams, useGridSelector } from '@mui/x-data-grid';
import { gridDetailPanelExpandedRowsContentCacheSelector, useGridApiContext } from '@mui/x-data-grid-pro';
import ChevronDownIcon from 'assets/img/ChevronDownIcon';
import IconButton from 'components/shared/IconButton/IconButton';

/**
 * Renders a toggle button for expanding/collapsing the detail panel in a DataGrid cell.
 *
 * @param {object} props - The properties for the RenderDetailPanelToggle component.
 * @param {string} props.id - The unique identifier of the row.
 * @param {boolean} props.value - The current expansion state of the detail panel.
 * @returns {JSX.Element} - The JSX element representing the detail panel toggle button.
 */
const RenderDetailPanelToggle = (props: Pick<GridRenderCellParams, 'id' | 'value'>): JSX.Element => {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();

  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);

  // Check if the detail panel has content for the specified row
  const hasDetail = isValidElement(contentCache[id]);

  return (
    <IconButton
      size="small"
      sx={{ position: 'absolute', left: 16, p: 0 }}
      tabIndex={-1}
      disabled={!hasDetail}
      aria-label={isExpanded ? 'Close' : 'Open'}
    >
      <ChevronDownIcon
        sx={{
          fontSize: 32,
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
        }}
      />
    </IconButton>
  );
};

export default RenderDetailPanelToggle;
