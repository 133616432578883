import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import isEqual from 'lodash/isEqual';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useNavigate, useParams } from 'react-router-dom';
import { Link, Tab } from '@mui/material';
import { useErrorBoundary } from 'react-error-boundary';
import { VariantType, enqueueSnackbar } from 'notistack';
import WorldTabs from 'components/WorldManagement/types/WorldTabs';
import { Collection } from 'store/collectionDetailsSlice';
import { Banner } from 'store/bannerDetailsSlice';
import { ErrorBoundaryErrors } from 'containers/ErrorPage/ErrorPage';
import { stringCapitalize } from 'utils/stringCapitalize';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { clearDetails, Cohort, fetchCohort, selectCohortDetails } from 'store/cohortDetailsSlice';
import { fetchGenders, selectGenders } from 'store/commonSlice';
import { clearFilters, reverseFilters, selectFiltersCurrentData, selectFiltersData } from 'store/filtersSlice';
import {
  clearErrorMessage,
  CohortsTableData,
  fetchAllCohorts,
  fetchArchiveBanner,
  fetchDefaultCollection,
  fetchExpireBanner,
  fetchWorld,
  selectAllCohorts,
  selectTotalCount,
  selectWorld,
  selectWorldColumns,
  selectWorldStatus,
} from 'store/worldManagementSlice';
import AvatarItem from 'components/shared/AvatarItem/AvatarItem';
import Button from 'components/shared/Button/Button';
import CustomFooter from 'components/shared/DataGrid/CustomFooter/CustomFooter';
import Divider from 'components/shared/Divider/Divider';
import Drawer from 'components/shared/Drawer/Drawer';
import FilterDrawer from 'components/FilterDrawer/FilterDrawer';
import Grid from 'components/shared/Grid/Grid';
import ManageLinkedCollectionsModal from 'components/ManageLinkedCollections/ManageLinkedCollectionsModal';
import Modal from 'components/shared/Modal/Modal';
import withAuth from 'components/Authorization/withAuth';
import AddBannerModal from 'components/WorldManagement/Banners/BannersModal/AddBanner/AddBannerModal';
import AddCollectionModal from 'components/WorldManagement/Collections/CollectionsModal/AddCollection/AddCollectionModal';
import ArchiveBannersModal from 'components/WorldManagement/Banners/BannersModal/ArchiveBanners/ArchiveBannersModal';
import ArchiveCollectionsModal from 'components/WorldManagement/Collections/CollectionsModal/ArchiveCollections/ArchiveCollectionsModal';
import CohortModal from 'components/WorldManagement/Cohorts/CohortsModal/CohortModal';
import ContextMenu from 'components/WorldManagement/ContextMenu/ContextMenu';
import CustomToolbar from 'components/WorldManagement/CustomToolbar/CustomToolbar';
import DeleteCohortsModal from 'components/WorldManagement/Cohorts/CohortsModal/DeleteCohorts/DeleteCohortsModal';
import ExpiredBannerModal from 'components/WorldManagement/Banners/BannersModal/ExpiredBanner/ExpiredBannerModal';
import PublishCollectionsModal from 'components/WorldManagement/Collections/CollectionsModal/PublishCollections/PublishCollectionsModal';
import UnpublishCollectionModal from 'components/WorldManagement/Collections/CollectionsModal/UnpublishCollection/UnpublishCollectionModal';
import { CloseFilterDrawerConfirmModal } from 'components/FilterDrawer/CloseFilterDrawerConfirmModal';
import './index.scss';

type TCohortModal =
  | {
      itemId: null;
      action: 'add';
    }
  | {
      itemId: string;
      action: 'edit';
    };

const initialRowCount = 10;

function WorldManagement() {
  let { tabId } = useParams();
  const isCorrectPage = useMemo(() => tabId && Object.values(WorldTabs).includes(tabId as WorldTabs), [tabId]);
  const [tabValue, setTabValue] = useState<WorldTabs>(tabId ? (tabId as WorldTabs) : WorldTabs.COLLECTIONS);
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [filterDrawerCloseConfirmModal, setFilterDrawerCloseConfirmModal] = useState<boolean>(false);
  const [addNewBanner, setAddNewBanner] = useState(false);
  const [cohortModal, setCohortModal] = useState<TCohortModal | null>(null);
  const [deleteCohorts, setDeleteCohorts] = useState<CohortsTableData | CohortsTableData[] | null>(null);
  const [archiveBanners, setArchiveBanners] = useState<Banner | Banner[] | null>(null);
  const [expiredBanner, setExpiredBanner] = useState<Banner | null>(null);
  const [openLinkedCollections, setOpenLinkedCollections] = useState<Banner | null>(null);
  const [addNewCollection, setAddNewCollection] = useState(false);
  const [archiveCollections, setArchiveCollections] = useState<Collection | Collection[] | null>(null);
  const [publishCollections, setPublishCollections] = useState<Collection | Collection[] | null>(null);
  const [unpublishCollections, setUnpublishCollections] = useState<Collection | null>(null);
  const [pageSize, setPageSize] = useState(initialRowCount);
  const [page, setPage] = useState(0);
  const apiRef = useGridApiRef();

  // const [sortingDrawer, setSortingDrawer] = useState(false);
  const mounted = useRef(true);
  // const [searchValue, setSearchValue] = useState<string>('');
  const { showBoundary } = useErrorBoundary();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const filtersData = useAppSelector(selectFiltersData);
  const filtersCurrentData = useAppSelector(selectFiltersCurrentData);
  const columns = useAppSelector(selectWorldColumns);
  const worldData = useAppSelector(selectWorld);
  const status = useAppSelector(selectWorldStatus);
  const totalCount = useAppSelector(selectTotalCount);
  const genders = useAppSelector(selectGenders);
  const cohortDetails = useAppSelector(selectCohortDetails);
  const allCohorts = useAppSelector(selectAllCohorts);

  const filtersLength = useMemo(() => {
    return Object.values(filtersData).filter((value) => value !== null).length;
  }, [filtersData]);

  const onFilterSidebarClose = useCallback(
    (apply?: boolean) => {
      if (isEqual(filtersData, filtersCurrentData) || apply === true) {
        setFilterDrawerCloseConfirmModal(false);
        setFilterDrawer(false);
        dispatch(reverseFilters());
      } else {
        setFilterDrawerCloseConfirmModal(true);
      }
    },
    [filtersData, filtersCurrentData],
  );

  const cancelCloseFilterSidebar = useCallback((event?: any, reason?: any) => {
    if (reason && reason === 'backdropClick') return;
    setFilterDrawerCloseConfirmModal(false);
  }, []);

  const handleTabChange = useCallback((event: ChangeEvent<{}>, value: WorldTabs) => {
    setTabValue(value);
  }, []);

  const customCellRenderCollections: any = {
    name: (props: GridRenderCellParams<any>) => (
      <div className="worldCell worldCell-content">
        <div className="worldCell-primary">{props.row?.title}</div>
        {props.row?.id !== 'DEFAULT' && (
          <div className="worldCell-secondary">
            {props.row?.creatorName} {props.row?.creatorLastName}
          </div>
        )}
      </div>
    ),
    status: (props: GridRenderCellParams<string>) => (
      <div className={`worldCell worldCell-status worldCell-status_${props.row?.status.toLowerCase()}`}>
        {stringCapitalize(props.row?.status)}
      </div>
    ),
    publicationDate: (props: GridRenderCellParams<Date>) => {
      if (props.row?.id === 'DEFAULT') {
        return <div className="worldCell"></div>;
      }
      return (
        <div
          className={`worldCell worldCell-wrap
          ${!props.row?.publishDateTime && !props.row?.expiredDateTime && 'worldCell-status_expired'}`}
        >
          {props.row?.publishDateTime && props.row?.expiredDateTime
            ? `${new Date(props.row?.publishDateTime).toLocaleString('en-AU').toUpperCase()}-
          ${new Date(props.row?.expiredDateTime).toLocaleString('en-AU').toUpperCase()}`
            : 'Not specified'}
        </div>
      );
    },
    cohort: (props: GridRenderCellParams<string>) => <div className="worldCell">{props.row?.cohortTitle}</div>,
    updatedAt: (props: GridRenderCellParams<Date | null>) => (
      <div className="worldCell">
        {props.row?.updatedAt ? new Date(props.row?.updatedAt).toLocaleDateString('en-AU') : ''}
      </div>
    ),
    action: (props: GridRenderCellParams<any>) => {
      if (props.row?.title === 'ALL') {
        return <div className="worldCell"></div>;
      }
      return (
        <div className="worldCell worldCell-action">
          <ContextMenu
            item={props.row}
            setPublishCollections={(collection: Collection) => setPublishCollections(collection)}
            setUnpublishCollections={(collection: Collection) => setUnpublishCollections(collection)}
            setArchiveCollections={(collection: Collection) => setArchiveCollections(collection)}
          />
        </div>
      );
    },
  };

  const customCellRenderBanners: any = {
    name: (props: GridRenderCellParams<any>) => (
      <Link
        className="worldCell"
        underline="none"
        href={`/ui/world-management/banners/${props.row?.id}`}
        onClick={(e: any) => e.stopPropagation()}
      >
        <AvatarItem
          src={props.row?.cover.originalLink}
          primaryText={props.row?.name}
          subText={`by ${props.row?.creatorName} ${props.row?.creatorLastName}`}
        />
      </Link>
    ),
    status: (props: GridRenderCellParams<string>) => (
      <div className={`worldCell worldCell-status worldCell-status_${props.row?.status.toLowerCase()}`}>
        {stringCapitalize(props.row?.status)}
      </div>
    ),
    expiredDate: (props: GridRenderCellParams<Date | null>) => (
      <div className={`worldCell ${!props.row?.expiredDate && 'worldCell-status_expired'}`}>
        {props.row?.expiredDate
          ? new Date(props.row?.expiredDate).toLocaleString('en-AU').toUpperCase()
          : 'Not specified'}
      </div>
    ),
    updated: (props: GridRenderCellParams<Date>) => (
      <div className="worldCell">{new Date(props.row?.updatedAt).toLocaleDateString('en-AU')}</div>
    ),
    action: (props: GridRenderCellParams<any>) => (
      <div className="worldCell worldCell-action">
        <ContextMenu
          item={props.row}
          setArchiveBanners={(banner: Banner) => setArchiveBanners(banner)}
          setExpiredBanners={(banner: Banner) => setExpiredBanner(banner)}
          setLinkedCollections={(banner: Banner) => setOpenLinkedCollections(banner)}
        />
      </div>
    ),
  };

  const customCellRenderCohorts: any = {
    name: (props: GridRenderCellParams<any>) => (
      <div className="worldCell worldCell-content">
        <div className="worldCell-primary">{props.row?.title}</div>
        {props.row?.creatorName && props.row?.creatorLastName && (
          <div className="worldCell-secondary">
            {props.row?.creatorName} {props.row?.creatorLastName}
          </div>
        )}
      </div>
    ),
    status: (props: GridRenderCellParams<string>) => (
      <div className={`worldCell worldCell-status worldCell-status_${stringCapitalize(props.row?.status)}`}>
        {props.row?.status.toLowerCase()}
      </div>
    ),
    linkedCollection: (props: GridRenderCellParams<string>) => (
      <div className={`worldCell worldCell-status ${!props.row?.hasCompilation ? 'worldCell-gray' : ''}`}>
        {props.row?.hasCompilation ? 'Yes' : 'No'}
      </div>
    ),
    creationDate: (props: GridRenderCellParams<Date>) => (
      <div className={`worldCell`}>{new Date(props.row?.createdAt).toLocaleDateString('en-AU')}</div>
    ),
    action: (props: GridRenderCellParams<any>) => (
      <div className="worldCell worldCell-action">
        <ContextMenu
          item={props.row}
          setEditCohorts={(itemId: string) => setCohortModal({ itemId, action: 'edit' })}
          setDeleteCohorts={(cohort: Cohort) => setDeleteCohorts(cohort)}
        />
      </div>
    ),
  };

  const rows: any = useMemo(() => {
    switch (tabValue) {
      case WorldTabs.COLLECTIONS: {
        return worldData.collections?.map((item: any) => ({ ...item, id: item.id })) || [];
      }
      case WorldTabs.BANNERS: {
        return worldData.banners.map((item: any) => ({ ...item, id: item.id }));
      }
      case WorldTabs.COHORTS: {
        return worldData.cohorts?.map((item: any) => ({ ...item, id: item.id })) || [];
      }
      case WorldTabs.ARCHIVE: {
        return worldData.archive.map((item: any, index: number) => ({
          ...item,
          id: index,
        }));
      }
    }
  }, [worldData, tabValue]);

  const cols: GridColDef[] = useMemo(() => {
    switch (tabValue) {
      case WorldTabs.COLLECTIONS: {
        return columns.collections.map((item: any) => ({
          field: item.columnId,
          headerName: item.columnName,
          width: item.width,
          flex: item.flex,
          sortable: item.sortable,
          renderCell: customCellRenderCollections[item.columnId] ?? null,
        }));
      }
      case WorldTabs.BANNERS: {
        return columns.banners.map((item: any) => ({
          field: item.columnId,
          headerName: item.columnName,
          width: item.width,
          headerClassName: item.headerClassName,
          flex: item.flex,
          renderCell: customCellRenderBanners[item.columnId] ?? null,
        }));
      }
      case WorldTabs.COHORTS: {
        return columns.cohorts.map((item: any) => ({
          field: item.columnId,
          headerName: item.columnName,
          width: item.width,
          flex: item.flex,
          renderCell: customCellRenderCohorts[item.columnId] ?? null,
        }));
      }
      case WorldTabs.ARCHIVE: {
        return columns.archive.map((item: any) => ({
          field: item.columnId,
          headerName: item.columnName,
          width: item.width,
          headerClassName: item.headerClassName,
          flex: item.flex,
          renderCell: customCellRenderBanners[item.columnId] ?? null,
        }));
      }
    }
  }, [columns, tabValue]);

  useEffect(() => {
    if (!tabId) {
      navigate(`/ui/world-management/collections`, { replace: false });
      return;
    }

    if (!isCorrectPage) {
      showBoundary(new Error('Request failed with status code 404'));
    }
  }, []);

  const initialFetchData = useCallback(async () => {
    Promise.all([dispatch(fetchGenders()), dispatch(fetchDefaultCollection()), dispatch(fetchAllCohorts())]).then(
      (result) => {
        result.forEach((value: any) => {
          if (
            value.meta &&
            value.meta.requestStatus === 'rejected' &&
            ErrorBoundaryErrors.includes(value.error.message)
          ) {
            // showBoundary( new Error(value.error.message));
          }
        });
      },
    );
  }, []);

  useEffect(() => {
    initialFetchData();
  }, []);

  const resetFilters = useCallback(async () => {
    dispatch(clearFilters());
    await dispatch(fetchWorld({ page: 0, pageSize: initialRowCount, dataType: tabValue }));
  }, [tabValue]);

  const fetchData = useCallback(async (page: number, pageSize: number, tabValue: WorldTabs) => {
    await dispatch(fetchWorld({ page, pageSize, dataType: tabValue }));
  }, []);

  const changePage = (page: number) => {
    setPage(page);
    fetchData(page, pageSize, tabValue);
  };

  const handleClickCollectionRow = (params: GridRowParams<any>) => {
    navigate(`/ui/world-management/collections/${params.row?.id}`);
  };

  useEffect(() => {
    dispatch(clearFilters());
    setPage(0);
    navigate(`/ui/world-management/${tabValue}`, { replace: false });
    // Promise.all([
    fetchData(0, pageSize, tabValue);
    // ]).then(result => {
    //   result.forEach((value: any) => {
    //     if (value.meta && value.meta.requestStatus === 'rejected' && ErrorBoundaryErrors.includes(value.error.message)) {
    //       showBoundary( new Error(value.error.message));
    //     }
    //   });
    // })

    return () => {
      mounted.current = true;
    };
  }, [tabValue]);

  useEffect(() => {
    if (cohortModal?.itemId) {
      dispatch(fetchCohort(cohortModal.itemId));
    }
  }, [cohortModal]);

  const [errorMessage, setErrorMessage] = useState('');

  const archiveBannerFunc = useCallback(
    async (items: Banner | Banner[]) => {
      try {
        setErrorMessage('');
        const isArray = Array.isArray(items);
        const data = isArray ? items.map((item) => item.id) : [items.id];
        const result = await dispatch(fetchArchiveBanner(data));
        if (result.meta.requestStatus === 'fulfilled') {
          if (isArray) {
            setArchiveBanners(null);
            enqueueSnackbar(`${items.length} banners have been archived`, { variant: 'success' as VariantType });
          } else {
            setArchiveBanners(null);
            enqueueSnackbar(`${items.name} banner has been archived`, { variant: 'success' as VariantType });
          }
        } else if (result.meta.requestStatus === 'rejected') {
          if (isArray) setErrorMessage('Archiving error. Try later');
        } else {
          setArchiveBanners(null);
          enqueueSnackbar(`Archiving error, try again later`, { variant: 'error' as VariantType });
        }
      } catch {
        if (Array.isArray(items)) {
          setErrorMessage('Archiving error. Try later');
        } else {
          setArchiveBanners(null);
          enqueueSnackbar(`Archiving error, try again later`, { variant: 'error' as VariantType });
        }
      }
    },
    [dispatch],
  );

  const expireBannerFunc = useCallback(
    async (item: Banner) => {
      const result = await dispatch(fetchExpireBanner([item.id]));
      if (result.meta.requestStatus === 'fulfilled') {
        setExpiredBanner(null);
        enqueueSnackbar(`${item.name} banner has been set as expired`, { variant: 'success' as VariantType });
      } else if (result.meta.requestStatus === 'rejected') {
        setExpiredBanner(null);
        enqueueSnackbar(`Changing error, try again later`, { variant: 'error' as VariantType });
      }
    },
    [dispatch],
  );

  return (
    <div className="worldManagement-container">
      <div className="worldManagement-header">Tim World Management</div>
      <TabContext value={tabValue}>
        <TabList onChange={handleTabChange} className="worldManagement-tabs">
          <Tab label="Collections" value={WorldTabs.COLLECTIONS} className="worldManagement-tab" />
          <Tab label="Banners" value={WorldTabs.BANNERS} className="worldManagement-tab" />
          <Tab label="Cohorts" value={WorldTabs.COHORTS} className="worldManagement-tab" />
          <Divider orientation="vertical" flexItem />
          <Tab label="Archive" value={WorldTabs.ARCHIVE} className="worldManagement-tab" />
        </TabList>
        <TabPanel value={WorldTabs.COLLECTIONS}>
          <div className="worldManagement-table-container">
            <div className="worldManagement-table-buttons">
              {/*todo second release*/}
              {/*<Button*/}
              {/*  startIcon={<FilterIcon />}*/}
              {/*  className="worldManagement-table-button"*/}
              {/*  variant="outlined"*/}
              {/*  onClick={() => setFilterDrawer(true)}*/}
              {/*>*/}
              {/*  Filter ({filtersLength})*/}
              {/*</Button>*/}
              <div></div>
              <Button
                className="worldManagement-table-button"
                variant="contained"
                onClick={() => setAddNewCollection(true)}
              >
                Add collection
              </Button>
            </div>

            <Grid
              pagination={true}
              checkboxSelection={true}
              disableSelectionOnClick={true}
              onRowClick={(params) => handleClickCollectionRow(params)}
              columns={cols}
              rowHeight={72}
              apiRef={apiRef}
              disableColumnResize={true}
              disableColumnMenu={true}
              rows={rows}
              experimentalFeatures={{ rowPinning: true }}
              pinnedRows={{
                top: worldData.defaultCollection ? [worldData.defaultCollection] : [],
              }}
              paginationMode="server"
              headerHeight={64}
              hideFooterSelectedRowCount={true}
              loading={status === 'loading'}
              page={page}
              pageSize={pageSize}
              onPageChange={(page) => changePage(page)}
              onPageSizeChange={(pageSize) => setPageSize(pageSize)}
              rowCount={totalCount.collectionElements}
              components={{
                Toolbar: () => {
                  const selectedRows = apiRef.current.getSelectedRows();
                  return CustomToolbar({
                    selectedRows,
                    setArchiveCollections: (items: Collection[]) => setArchiveCollections(items),
                    setPublishCollections: (items: Collection[]) => setPublishCollections(items),
                  });
                },
                Footer: CustomFooter,
              }}
            />
          </div>
        </TabPanel>
        <TabPanel value={WorldTabs.BANNERS}>
          <div className="worldManagement-table-container">
            <div className="worldManagement-table-buttons">
              {/*todo second release*/}
              {/*<Button*/}
              {/*  startIcon={<FilterIcon />}*/}
              {/*  className="worldManagement-table-button"*/}
              {/*  variant="outlined"*/}
              {/*  onClick={() => setFilterDrawer(true)}*/}
              {/*>*/}
              {/*  Filter ({filtersLength})*/}
              {/*</Button>*/}
              <div></div>
              <Button
                className="worldManagement-table-button"
                variant="contained"
                onClick={() => setAddNewBanner(true)}
              >
                Add banner
              </Button>
            </div>

            <Grid
              pagination={true}
              checkboxSelection={true}
              columns={cols}
              rowHeight={68}
              apiRef={apiRef}
              disableColumnResize={true}
              disableColumnMenu={true}
              rows={rows}
              headerHeight={64}
              hideFooterSelectedRowCount={true}
              loading={false}
              pageSize={pageSize}
              paginationMode="server"
              onPageChange={(page) => changePage(page)}
              onPageSizeChange={(pageSize) => setPageSize(pageSize)}
              rowCount={totalCount.bannerElements}
              components={{
                Toolbar: () => {
                  const selectedRows = apiRef.current.getSelectedRows();
                  return CustomToolbar({
                    selectedRows,
                    setArchiveBanners: (items: Banner[]) => setArchiveBanners(items),
                  });
                },
                Footer: CustomFooter,
              }}
            />
          </div>
        </TabPanel>
        <TabPanel value={WorldTabs.COHORTS}>
          <div className="worldManagement-table-container">
            <div className="worldManagement-table-buttons">
              {/*todo second release*/}
              {/*<Button*/}
              {/*  startIcon={<FilterIcon />}*/}
              {/*  className="worldManagement-table-button"*/}
              {/*  variant="outlined"*/}
              {/*  onClick={() => setFilterDrawer(true)}*/}
              {/*>*/}
              {/*  Filter ({filtersLength})*/}
              {/*</Button>*/}
              <div></div>
              <Button
                className="worldManagement-table-button"
                variant="contained"
                onClick={() => setCohortModal({ itemId: null, action: 'add' })}
              >
                Add cohort
              </Button>
            </div>

            <Grid
              pagination={true}
              checkboxSelection={true}
              columns={cols}
              rowHeight={68}
              apiRef={apiRef}
              disableColumnResize={true}
              disableColumnMenu={true}
              rows={rows}
              headerHeight={64}
              hideFooterSelectedRowCount={true}
              loading={status === 'loading'}
              pageSize={pageSize}
              paginationMode="server"
              onPageChange={(page) => changePage(page)}
              onPageSizeChange={(pageSize) => setPageSize(pageSize)}
              rowCount={totalCount.cohortElements}
              components={{
                Toolbar: () => {
                  const selectedRows = apiRef.current.getSelectedRows();
                  return CustomToolbar({
                    selectedRows,
                    setDeleteCohorts: (items: Cohort[]) => setDeleteCohorts(items),
                  });
                },
                Footer: CustomFooter,
              }}
            />
          </div>
        </TabPanel>
        <TabPanel value={WorldTabs.ARCHIVE}>
          {/* todo добавить таблицу архива */}
          <></>
        </TabPanel>
      </TabContext>
      <CloseFilterDrawerConfirmModal
        open={filterDrawerCloseConfirmModal}
        onClose={() => onFilterSidebarClose(true)}
        closeModal={() => cancelCloseFilterSidebar()}
      />
      <Drawer anchor="right" open={filterDrawer} onClose={() => onFilterSidebarClose()}>
        <FilterDrawer
          type={tabValue}
          isHidePresets
          onClose={onFilterSidebarClose}
          onApply={() => {
            onFilterSidebarClose(true);
            // dispatch(fetchData({ pageSize: rowCount, type: tabValue }));
          }}
        />
      </Drawer>
      {/*<Drawer anchor='right' open={sortingDrawer} onClose={() => setSortingDrawer(false)}>*/}
      {/*  <SortingDrawer*/}
      {/*    type={tabValue}*/}
      {/*    isHidePresets*/}
      {/*    close={() => setSortingDrawer(false)}*/}
      {/*    apply={() => {*/}
      {/*      setSortingDrawer(false);*/}
      {/*      dispatch(fetchData({ pageSize: rowCount, type: tabValue }));*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</Drawer>*/}

      <Modal open={addNewBanner}>
        <AddBannerModal onClose={() => setAddNewBanner(false)} />
      </Modal>
      <Modal customstyle={{ minHeight: 208 }} open={!!archiveBanners}>
        <ArchiveBannersModal
          onClose={() => {
            setArchiveBanners(null);
            setErrorMessage('');
          }}
          errorMessage={errorMessage}
          items={archiveBanners!}
          archive={(items) => {
            archiveBannerFunc(items);
          }}
        />
      </Modal>
      <Modal open={!!expiredBanner}>
        <ExpiredBannerModal
          onClose={() => setExpiredBanner(null)}
          item={expiredBanner!}
          expire={(item) => expireBannerFunc(item)}
        />
      </Modal>
      {openLinkedCollections && (
        <ManageLinkedCollectionsModal
          open={!!openLinkedCollections}
          onClose={() => setOpenLinkedCollections(null)}
          entityId={openLinkedCollections.id}
          entityType="BANNER"
        />
      )}
      <Modal customstyle={{ minHeight: 180 }} open={!!deleteCohorts}>
        <DeleteCohortsModal onClose={() => setDeleteCohorts(null)} items={deleteCohorts!} status={status} />
      </Modal>
      {cohortModal && (cohortDetails || cohortModal.action === 'add') && (
        <Modal customstyle={{ minHeight: 180 }} open={!!cohortModal}>
          <CohortModal
            onClose={() => {
              setCohortModal(null);
              dispatch(clearErrorMessage());
              dispatch(clearDetails());
            }}
            item={cohortModal.action === 'edit' ? cohortDetails : null}
            action={cohortModal.action}
            genders={genders}
          />
        </Modal>
      )}
      <Modal open={addNewCollection}>
        <AddCollectionModal
          onClose={() => {
            setAddNewCollection(false);
            dispatch(clearErrorMessage());
          }}
          cohorts={allCohorts}
        />
      </Modal>
      <Modal customstyle={{ minHeight: 208 }} open={!!archiveCollections}>
        <ArchiveCollectionsModal
          status={status}
          onClose={() => setArchiveCollections(null)}
          items={archiveCollections!}
        />
      </Modal>
      <Modal customstyle={{ minHeight: 212 }} open={!!publishCollections}>
        <PublishCollectionsModal
          status={status}
          onClose={() => setPublishCollections(null)}
          items={publishCollections!}
        />
      </Modal>
      <Modal customstyle={{ minHeight: 180 }} open={!!unpublishCollections}>
        <UnpublishCollectionModal onClose={() => setUnpublishCollections(null)} item={unpublishCollections!} />
      </Modal>
    </div>
  );
}

export default withAuth(WorldManagement);
