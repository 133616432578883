import { useCallback, useState } from 'react';
import { GridOverlay } from '@mui/x-data-grid';
import Button from 'components/shared/Button/Button';
import Dialog from 'components/shared/Dialog/Dialog';
import DialogActions from 'components/shared/Dialog/DialogActions/DialogActions';
import DialogContent from 'components/shared/Dialog/DialogContent/DialogContent';
import DialogTitle from 'components/shared/Dialog/DialogTitle/DialogTitle';
import Typography from 'components/shared/Typography/Typography';

interface ICustomNoResultsOverlayProps {
  isFiltersApplied?: boolean;
  fetchingError?: boolean;
  clearFilters?: () => void;
  refreshData?: () => void;
  renderNoDataComponent?: () => void;
}

const CustomNoRowsOverlay = ({
  isFiltersApplied,
  fetchingError,
  clearFilters,
  refreshData,
  renderNoDataComponent,
}: ICustomNoResultsOverlayProps) => {
  const [clearFiltersConfirmModal, setClearFiltersConfirmModal] = useState<boolean>(false);

  const cancelClearFilters = useCallback((event?: any, reason?: any) => {
    if (reason && reason === 'backdropClick') return;
    setClearFiltersConfirmModal(false);
  }, []);

  const handleClearFilters = useCallback(async () => {
    setClearFiltersConfirmModal(false);
    clearFilters && clearFilters();
  }, [clearFilters]);

  return (
    <GridOverlay className="operations-table__overlay">
      {fetchingError ? (
        <>
          <Typography color="text.secondary" variant="body2">
            There are some troubles with data receiving. Repeat request in a while.
          </Typography>
          <Typography fontSize="small" color="text.secondary" variant="body2">
            WARNING! In case of page reload you could lose your requested data
          </Typography>
          <Button variant="text" onClick={() => refreshData && refreshData()}>
            Repeat request
          </Button>
        </>
      ) : isFiltersApplied ? (
        <>
          <Typography color="text.secondary" variant="body2">
            No data to display. Try to remove any of filters or change their value
          </Typography>
          <Button variant="text" onClick={() => setClearFiltersConfirmModal(true)}>
            Clear filters
          </Button>
        </>
      ) : (
        <>{renderNoDataComponent && renderNoDataComponent()}</>
      )}
      {clearFiltersConfirmModal && (
        <Dialog fullWidth maxWidth="xs" open={clearFiltersConfirmModal}>
          <DialogTitle variant="h6">Clear filters?</DialogTitle>
          <DialogContent>
            <Typography variant="body1">All data will be shown</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => cancelClearFilters()} variant="text" color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleClearFilters()} variant="contained" color="primary">
              Clear
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </GridOverlay>
  );
};

export default CustomNoRowsOverlay;
