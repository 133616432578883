import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from './store';
import { IFilterItem, IStatus } from 'types/commonTypes';
import { IActiveUser, IBannedUser, IDeletedUser } from 'types/User';
import { UsersTabs } from '../containers/Users/Users';

export interface UsersState {
  columns: any[];
  filters: IFilterItem[];
  activeUsers: IActiveUser[];
  bannedUsers: IBannedUser[];
  deletedUsers: IDeletedUser[];
  value: number;
  totalActiveCount: number | undefined;
  totalBannedCount: number | undefined;
  totalDeletedCount: number | undefined;
  status: IStatus;
  actualUsersStatus: IStatus;
  bannedUsersStatus: IStatus;
  deletedUserStatus: IStatus;
  refreshStatus: IStatus;
}

const initialState: UsersState = {
  columns: [],
  filters: [],
  activeUsers: [],
  bannedUsers: [],
  deletedUsers: [],
  value: 0,
  totalActiveCount: 0,
  totalBannedCount: 0,
  totalDeletedCount: 0,
  status: 'idle',
  actualUsersStatus: 'idle',
  bannedUsersStatus: 'idle',
  deletedUserStatus: 'idle',
  refreshStatus: 'idle',
};

export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async ({ page, pageSize, type }: { page: number; pageSize: number; type?: string }, thunkAPI) => {
    const rootState: RootState = (await thunkAPI.getState()) as RootState;
    const filters = Object.keys(rootState.filters.data)
      ? Object.values(rootState.filters.data).filter((item: any) => item !== null)
      : [];
    const sortingList = Object.keys(rootState.sorting.data)
      ? Object.values(rootState.sorting.data).filter(
          (item: any) => item !== null && item.columnId !== null && item.sortingType !== null,
        )
      : [];
    const columns =
      type !== 'actual' && Object.keys(rootState.columns.data)
        ? Object.values(rootState.columns.data).filter((item: any) => item !== null)
        : [];

    try {
      const response = await axios.post(
        process.env.REACT_APP_USERS_ENDPOINT + `timusers/users${type ? `/${type}` : ''}`,
        {
          pageInfo: {
            page: page + 1,
            size: pageSize,
          },
          columns: columns,
          sortingList: sortingList,
          filters: filters,
        },
      );
      return response.data;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue({
          message: error.message || 'An unexpected error occurred',
          status: error.response?.status,
        });
      } else {
        return thunkAPI.rejectWithValue({
          message: 'An unexpected error occurred',
        });
      }
    }
  },
);

export const fetchChangeStatus = createAsyncThunk('users/changeStatus', async (data: any) => {
  return data;
});

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsersStatus: (state, action: PayloadAction<IStatus>) => {
      state.status = action.payload;
    },
    setActualUsersStatus: (state, action: PayloadAction<IStatus>) => {
      state.actualUsersStatus = action.payload;
    },
    setUsersRefreshStatus: (state, action: PayloadAction<IStatus>) => {
      state.refreshStatus = action.payload;
    },
    clearData: (state) => {
      state.activeUsers = [];
      state.bannedUsers = [];
      state.deletedUsers = [];
      state.status = 'loading';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state, action) => {
        state.status = 'loading';
        if (action.meta.arg.type === UsersTabs.ACTIVE) {
          state.totalActiveCount = 0;
          state.actualUsersStatus = 'loading';
        }
        if (action.meta.arg.type === UsersTabs.BANNED) {
          state.totalBannedCount = 0;
          state.bannedUsersStatus = 'loading';
        }
        if (action.meta.arg.type === UsersTabs.DELETED) {
          state.totalDeletedCount = 0;
          state.deletedUserStatus = 'loading';
        }
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'idle';
        state.refreshStatus = 'idle';
        if (action.meta.arg.type === UsersTabs.ACTIVE) {
          state.activeUsers = action.payload.users;
          state.totalActiveCount = action.payload.allResultsCount;
          state.actualUsersStatus = 'idle';
        }
        if (action.meta.arg.type === UsersTabs.BANNED) {
          state.bannedUsers = action.payload.users;
          state.totalBannedCount = action.payload.allResultsCount;
          state.bannedUsersStatus = 'idle';
        }
        if (action.meta.arg.type === UsersTabs.DELETED) {
          state.deletedUsers = action.payload.users;
          state.totalDeletedCount = action.payload.allResultsCount;
          state.deletedUserStatus = 'idle';
        }
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        if (action.meta.arg.type === UsersTabs.ACTIVE) {
          state.activeUsers = [];
          state.actualUsersStatus = 'failed';
        }
        if (action.meta.arg.type === UsersTabs.BANNED) {
          state.bannedUsers = [];
          state.bannedUsersStatus = 'failed';
        }
        if (action.meta.arg.type === UsersTabs.DELETED) {
          state.deletedUsers = [];
          state.deletedUserStatus = 'failed';
        }
        state.status = 'failed';
        state.refreshStatus = 'idle';
      })
      .addCase(fetchChangeStatus.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchChangeStatus.fulfilled, (state, action) => {
        action.payload.data.forEach((iter: any) => {
          const index = state.activeUsers.findIndex((item) => item.baseInfo.userId === iter.baseInfo.userId);
          state.activeUsers[index].status = action.payload.value;
        });
        state.status = 'idle';
      })
      .addCase(fetchChangeStatus.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectActiveUsers = (state: RootState): IActiveUser[] => state.users.activeUsers;
export const selectBannedUsers = (state: RootState): IBannedUser[] => state.users.bannedUsers;
export const selectDeletedUsers = (state: RootState): IDeletedUser[] => state.users.deletedUsers;
export const selectUsersStatus = (state: RootState): IStatus => state.users.status;
export const selectActualUsersStatus = (state: RootState): IStatus => state.users.actualUsersStatus;
export const selectBannedUsersStatus = (state: RootState): IStatus => state.users.bannedUsersStatus;
export const selectDeletedUsersStatus = (state: RootState): IStatus => state.users.deletedUserStatus;
export const selectUsersRefreshStatus = (state: RootState): IStatus => state.users.refreshStatus;
export const selectActiveUsersCount = (state: RootState): number | undefined => state.users.totalActiveCount;
export const selectBannedUsersCount = (state: RootState): number | undefined => state.users.totalBannedCount;
export const selectDeletedUsersCount = (state: RootState): number | undefined => state.users.totalDeletedCount;

export const { clearData, setUsersStatus, setActualUsersStatus, setUsersRefreshStatus } = usersSlice.actions;

export default usersSlice.reducer;
