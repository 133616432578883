import { Switch as SwitchBase } from '@mui/material';
import { SwitchProps } from '@mui/material/Switch/Switch';
import colors from 'theme/constants/colors';

interface ISwitchProps extends SwitchProps {
  primary?: boolean;
  label?: string;
}

const Switch = (props: ISwitchProps) => {
  return (
    <SwitchBase
      sx={{
        '& .MuiSwitch-track': {
          backgroundColor: colors.textSecondary,
        },
      }}
      {...props}
    />
  );
};

export default Switch;
