import { OrderedBanner } from "store/collectionDetailsSlice";
import Card from "components/shared/Card/Card";
import CardActionArea from "components/shared/Card/CardActionArea";
import { CardMedia } from "@mui/material";
import CardContent from "components/shared/Card/CardContent";

interface IProps {
  entity: OrderedBanner;
  height: number;
  width: number;
}

const BannerCard = ({ entity, width, height }: IProps) => {
  return (
    <Card className="bannerCard">
      <CardActionArea>
        <CardMedia
          component="img"
          image={entity.cover?.originalLink || ''}
          height={height}
          width={width}
          alt="Banner cover"
        />
        <CardContent>
          {entity.subtitle && <span>{entity.subtitle}</span>}
          {entity.title && <span className="banner-name">{entity.title}</span>}
        </CardContent>
      </CardActionArea>
    </Card>
  )
};

export default BannerCard;
