import React, { ChangeEvent, useMemo, useState } from 'react';
import { TabList, TabPanel } from '@mui/lab';
import { Avatar, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext/TabContext';
import { intervalToDuration } from 'date-fns';
import { Link } from 'react-router-dom';
import startCase from 'lodash/startCase';
import colors from 'theme/constants/colors';
import { getAuraColor } from 'utils/getAuraColor';
import { useAppSelector } from 'store/hooks';
import { selectUserProfile } from 'store/userProfileSlice';
import CalendarIcon from 'assets/img/CalendarIcon';
import EarnPendingWalletIcon from 'assets/img/EarnPendingWalletIcon';
import ErrorIcon from 'assets/img/ErrorIcon';
import FollowersIcon from 'assets/img/FollowersIcon';
import GenderIcon from 'assets/img/GenderIcon';
import InfoIcon from 'assets/img/InfoIcon';
import LikeIcon from 'assets/img/LikeIcon';
import LinkIcon from 'assets/img/LinkIcon';
import LocationIcon from 'assets/img/LocationIcon';
import MessageIcon from 'assets/img/MessageIcon';
import PhoneIcon from 'assets/img/PhoneIcon';
import RestrictIcon from 'assets/img/RestrictIcon';
import RestrictInProgressIcon from 'assets/img/RestrictInProgressIcon';
import StarIcon from 'assets/img/StarIcon';
import SubscriptionsIcon from 'assets/img/SubscriptionsIcon';
import UserIcon from 'assets/img/UserIcon';
import UserNoAvatarPreview from 'assets/img/UserNoAvatarPreview.svg';
import UsernameIcon from 'assets/img/UsernameIcon';
import VerifiedIcon from 'assets/img/VerifiedIcon';
import VerifiedMonochromeIcon from 'assets/img/VerifiedMonochromeIcon';
import VerifyBlockedIcon from 'assets/img/VerifyBlockedIcon';
import VerifyLockedIcon from 'assets/img/VerifyLockedIcon';
import VerifyPendingIcon from 'assets/img/VerifyPendingIcon';
import VerifyReviewIcon from 'assets/img/VerifyReviewIcon';
import WalletIcon from 'assets/img/WalletIcon';
import WithdrawPendingWalletIcon from 'assets/img/WithdrawPendingWalletIcon';
import WithdrawWalletIcon from 'assets/img/WithdrawWalletIcon';
import Chip from 'components/shared/Chip/Chip';
import IconButton from 'components/shared/IconButton/IconButton';
import LinkMUI from 'components/shared/Link/Link';
import ListItem from 'components/shared/List/ListItem/ListItem';
import ListItemAvatar from 'components/shared/List/ListItem/ListItemComponents/ListItemAvatar/ListItemAvatar';
import ListItemText from 'components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText';
import SimpleGrid from 'components/shared/SimpleGrid/SimpleGrid';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';
import UserProfileDataBlock from 'components/UserProfile/UserProfileDataBlock';
import './index.scss';
import Tooltip from '../shared/Tooltip/Tooltip';

const UserProfile = () => {
  const data = useAppSelector(selectUserProfile);

  const [tabValue, setTabValue] = useState('0');

  const handleTabChange = (event: ChangeEvent<{}>, value: string) => {
    setTabValue(value);
  };

  const localeOptions: any = { year: 'numeric', month: 'long', day: 'numeric' };

  const walletStatus = useMemo(() => {
    switch (data.paymentInfo.walletStatusCode) {
      case 'TPO_001':
        return { statusText: 'Blocked', icon: <VerifyLockedIcon /> };
      case 'TPO_002':
        return { statusText: 'Verified', icon: <VerifiedIcon /> };
      case 'TPO_003':
      case 'TPO_011':
        return { statusText: 'Restricted', icon: <RestrictIcon /> };
      case 'TPO_004':
        return { statusText: 'Restricted soon', icon: <RestrictInProgressIcon /> };
      case 'TPO_005':
        return { statusText: 'Under review', icon: <VerifyReviewIcon /> };
      case 'TPO_006':
        return { statusText: 'Not verified', icon: <VerifiedMonochromeIcon /> };
      case 'TPO_007':
      case 'TPO_008':
      case 'TPO_009':
      case 'TPO_010':
        return { statusText: 'Not verified', icon: <VerifyBlockedIcon /> };
      case 'TPO_014':
        return { statusText: 'Pending', icon: <VerifyPendingIcon /> };
      case 'TPO_000':
        return { statusText: 'No wallet', icon: <ErrorIcon /> };
      default:
        return { statusText: 'Pending', icon: <VerifyPendingIcon /> };
    }
  }, [data.paymentInfo.walletStatusCode]);

  return (
    <>
      <div className="user-profile">
        <div className="user-profile__avatar">
          <Avatar
            src={data.main.avatarUrl || UserNoAvatarPreview}
            sx={{ width: 222, height: 222, borderRadius: 4, position: 'relative' }}
          />
          <div className="user-profile__avatar-name">
            <Typography color="text.primary" variant="body1" >
              {data.personal.firstName + ' ' + data.personal.lastName}
            </Typography>
            {/*<Link href="/users" sx={data.main.status !== 'ACTIVE' ? { pointerEvents: 'none' } : {}}>*/}
            <Tooltip title="View profile" placement="top-end" followCursor>
              <IconButton disabled size="small">
                <LinkIcon color="disabled" sx={{ opacity: 0.4 }} />
              </IconButton>
            </Tooltip>
            {/*</Link>*/}
          </div>
          {data.main.levelAura && (
            <div
              className="user-profile__avatar-aura"
              style={{ background: getAuraColor(data.main.levelAura).background }}
            >
              <Tooltip title="Aura level" placement="top-end" followCursor>
                <Typography variant="subtitle2" color={colors.white}>
                  {data.main.levelAura && startCase(data.main.levelAura.toLowerCase())}
                </Typography>
              </Tooltip>
            </div>
          )}
        </div>
        <div className="main">
          <div className="main-group">
            <Typography variant="h6" mb={2}>
              System info
            </Typography>
            <UserProfileDataBlock withCopy primary="Username" secondary={data.main.username} />
            <UserProfileDataBlock withCopy primary="ID" secondary={data.main.userId} />
            {/*<UserProfileInfoBlock withCopy primary="Registration date" secondary={(data.personal.registrationDate || new Date()).toLocaleDateString('en-GB', localeOptions)} />*/}
          </div>
          <div className="main-group">
            <Typography variant="h6" mb={2}>
              Violations
            </Typography>
            <UserProfileDataBlock primary="Profile" secondary={data.main.profileViolationsCount} />
            <UserProfileDataBlock primary="Content" secondary={data.main.contentViolationsCount} />
            <LinkMUI
              component={Link}
              color={colors.actionDisabled}
              underline="none"
              sx={{ cursor: 'pointer' }}
              to={
                {
                  // pathname: `/ui/moderation/confirmed-violations`,
                  // search: `?${createSearchParams({
                  //   userName: data.main.username,
                  //   userId: data.main.userId,
                  // })}`,
                }
              }
              // target='_blank'
            >
              Show details
            </LinkMUI>
          </div>
          <div className="main-group">
            <Typography variant="h6" mb={2}>
              Active paid content
            </Typography>
            <Stack direction="row" alignItems="center">
              <UserProfileDataBlock primary="Offers" secondary={data.main.activePaidContent?.offersCount} />
              <UserProfileDataBlock primary="Wishes" secondary={data.main.activePaidContent?.wishesCount} />
            </Stack>
            <Stack direction="row" alignItems="center">
              <UserProfileDataBlock primary="Lifestyle" secondary={data.main.activePaidContent?.lifestylesCount} />
              <UserProfileDataBlock primary="Events" secondary={data.main.activePaidContent?.eventsCount} />
            </Stack>
          </div>
          {/* <div className="main-group">
            <Typography variant="h6" mb={2}>
              Latest activity
            </Typography>
            <UserProfileDataBlock
              primary="Date"
              secondary={(data.personal?.latestActivityDate || new Date()).toLocaleDateString('en-GB', localeOptions)}
            />
            <UserProfileDataBlock
              primary="Location"
              secondary={data.personal?.latestActivityLocation || `Lisbon, Spain`}
            />
            <LinkMUI
              component={Link}
              underline="hover"
              sx={{ cursor: 'pointer' }}
              to={{
                pathname: `/users`,
              }}
            >
              Show history
            </LinkMUI>
          </div> */}
        </div>
      </div>
      <TabContext value={tabValue}>
        <TabList onChange={handleTabChange} className="users-tabs">
          <Tab label="Personal" value="0" />
          <Tab disabled label="Financial data" value="1" />
        </TabList>
        <TabPanel value="0" sx={{ padding: 0 }}>
          <Stack gap={4} className="personal">
            <Stack>
              <Typography variant="h6" pb={1}>
                Personal data
              </Typography>
              <Stack direction="row" gap="24px">
                <Stack flex="1 1" width={0}>
                  <UserProfileDataBlock
                    primary="Name"
                    secondary={data.personal.firstName + ' ' + data.personal.lastName}
                    icon={<UserIcon />}
                  />
                  <UserProfileDataBlock primary="Username" secondary={data.personal.username} icon={<UsernameIcon />} />
                  <UserProfileDataBlock
                    primary="Date of birth"
                    secondary={
                      <>
                        {new Date(data.personal.birthDate).toLocaleDateString('en-GB', localeOptions)} (
                        {
                          intervalToDuration({
                            start: data.personal.birthDate === '' ? new Date() : new Date(data.personal.birthDate),
                            end: new Date(),
                          }).years
                        }{' '}
                        y.o.)
                      </>
                    }
                    icon={<CalendarIcon />}
                  />
                </Stack>
                <Stack flex="1 1" width={0}>
                  <UserProfileDataBlock primary="Pronoun" secondary={data.personal.gender} icon={<GenderIcon />} />
                  <UserProfileDataBlock
                    primary="Hustle"
                    secondary={data.personal.occupation || 'Not specified'}
                    icon={<LikeIcon />}
                  />
                  <UserProfileDataBlock
                    primary="Location"
                    secondary={data.personal.location || 'Not specified'}
                    icon={<LocationIcon />}
                  />
                </Stack>
                <Stack flex="1 1" width={0}>
                  <UserProfileDataBlock
                    hasEllipsis={false}
                    primary="What im about"
                    secondary={data.personal?.aboutMe || <br />}
                    icon={<InfoIcon />}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack>
              <Stack direction="row" gap="24px">
                <Stack flex="1 1" width={0}>
                  <Typography pb={1} variant="h6">
                    Connections
                  </Typography>
                </Stack>
                <Stack flex="1 1" width={0}>
                  <Typography pb={1} variant="h6">
                    Contacts
                  </Typography>
                </Stack>
                <Stack flex="1 1" width={0}></Stack>
              </Stack>
              <Stack direction="row" gap="24px">
                <Stack flex="1 1" width={0}>
                  <UserProfileDataBlock
                    primary="Followers"
                    secondary={data.personal.followersCount || 0}
                    icon={<FollowersIcon />}
                  />
                  <UserProfileDataBlock
                    primary="Subscriptions"
                    secondary={data.personal.subscriptionsCount || 0}
                    icon={<SubscriptionsIcon />}
                  />
                </Stack>
                <Stack flex="1 1" width={0}>
                  <UserProfileDataBlock
                    withCopy
                    primary="Phone"
                    secondary={data.personal.phoneNumber}
                    icon={<PhoneIcon />}
                  />
                  <UserProfileDataBlock
                    withCopy
                    primary="Email"
                    secondary={data.personal.email}
                    icon={<MessageIcon />}
                  />
                </Stack>
                <Stack flex="1 1" width={0}>
                  {/*<UserProfileDataBlock*/}
                  {/*  primary="Facebook"*/}
                  {/*  secondary={data.personal?.facebookId || 'Not linked'}*/}
                  {/*  icon={<FacebookIcon />}*/}
                  {/*/>*/}
                  {/*<UserProfileDataBlock*/}
                  {/*  primary="Instagram"*/}
                  {/*  secondary={data.personal.instagramId}*/}
                  {/*  icon={<InstagramIcon />}*/}
                  {/*/>*/}
                </Stack>
              </Stack>
            </Stack>
            <Stack gap={1} className="passions">
              <Typography mb={1} variant="h6">
                Passions
              </Typography>
              <SimpleGrid container wrap="wrap" flexDirection="row" minHeight="100%" alignContent="stretch" spacing={2}>
                {data.personal?.passions?.map((item: string, index: number) => (
                  <SimpleGrid item xs={3} key={index}>
                    <ListItem disablePadding>
                      <ListItemAvatar sx={{ minWidth: 'auto', mr: 2 }}>
                        <Avatar sx={{ bgcolor: 'transparent', width: 24, height: 24 }}>
                          <StarIcon color="disabled" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography color="text.secondary" variant="body2">
                            {item}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </SimpleGrid>
                ))}
              </SimpleGrid>
            </Stack>
          </Stack>
        </TabPanel>
        <TabPanel value="1" sx={{ padding: 0 }}>
          <div className="personal">
            <div className="personalData--headerBlock gapped">
              <Typography variant="h6" color="text.primary">
                Payout wallet
              </Typography>
              <Chip
                className={`userProfile--walletStatus${walletStatus.statusText === 'No wallet' ? ' no-wallet' : ''}`}
                icon={walletStatus.icon}
                label={walletStatus.statusText?.toUpperCase()}
              />
            </div>
            <div className="personalData--infoContainer">
              <div className="personalData--infoBlock">
                <UserProfileDataBlock
                  icon={<WalletIcon />}
                  secondary="Balance"
                  primary={`$${data.paymentInfo.balance}`}
                />
              </div>
              <div className="personalData--infoBlock">
                <UserProfileDataBlock
                  icon={<WithdrawWalletIcon />}
                  secondary="Available to withdrawal"
                  primary={`$${data.paymentInfo.balanceAvailableWithdrawal}`}
                />
              </div>
              <div className="personalData--infoBlock">
                <UserProfileDataBlock
                  icon={<WithdrawPendingWalletIcon />}
                  secondary="Pending (withdrawn)"
                  primary={`$${data.paymentInfo.balancePendingWithdrawal}`}
                />
              </div>
              <div className="personalData--infoBlock">
                <UserProfileDataBlock
                  icon={<EarnPendingWalletIcon />}
                  secondary="Pending (earned)"
                  primary={`$${data.paymentInfo.balancePendingEarned}`}
                />
              </div>
            </div>
          </div>
        </TabPanel>
      </TabContext>
    </>
  );
};

export default UserProfile;
