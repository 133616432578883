import { Decision, ProfileImplications, Term } from "store/rulesSlice";

export const implications: Record<ProfileImplications, string> = {
  'BLOCK_PUBLICATION': 'Prohibit content creation',
  'BAN_PROFILE': 'Ban profile',
  'NO_IMPLICATIONS': 'No implications',
}

export const terms: Record<Term, string> = {
  'FOR_PERIOD': 'for period',
  'PERMANENTLY': 'permanently',
}

export const decisions: Record<Decision, string> = {
  'DELETE': 'Delete',
  'LIMIT': 'Limit',
}
