const WithdrawWalletIcon = (props: any) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9992 11.1848C11.5926 10.95 11.4533 10.4302 11.6881 10.0236L13.4749 6.9287C12.6794 6.98157 11.8036 7.2235 11.1071 7.78941C10.3413 8.4116 9.6711 9.52351 9.6711 11.5672V14.9633C9.6711 15.4328 9.29054 15.8133 8.8211 15.8133C8.35165 15.8133 7.9711 15.4323 7.9711 14.9628L7.9711 11.5672C7.9711 9.1109 8.80087 7.47282 10.0351 6.47001C10.976 5.7055 12.079 5.36985 13.0516 5.26041L9.92864 3.45736C9.52209 3.22264 9.3828 2.70278 9.61752 2.29624C9.85224 1.88969 10.3721 1.75039 10.7786 1.98511L16.4137 5.23854L13.1603 10.8736C12.9256 11.2802 12.4057 11.4195 11.9992 11.1848Z" fill="currentColor"/>
      <path d="M18.3269 12.4058C17.6315 12.4058 17.0677 12.9695 17.0677 13.665C17.0677 14.3604 17.6315 14.9242 18.3269 14.9242C19.0224 14.9242 19.5861 14.3604 19.5861 13.665C19.5861 12.9695 19.0224 12.4058 18.3269 12.4058Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.15454 5.85341C7.3689 6.27106 7.2041 6.7834 6.78645 6.99775C4.79786 8.01839 3.44023 10.0879 3.44023 12.4731V14.8562C3.44023 18.2528 6.19368 21.0062 9.59024 21.0062H15.497C17.2772 21.0062 18.7645 19.7519 19.1233 18.0788H18.4935C16.0555 18.0788 14.0791 16.1024 14.0791 13.6644C14.0791 11.2264 16.0555 9.25 18.4935 9.25H19.092C18.9473 8.66781 18.6704 8.13776 18.2942 7.69294C17.9911 7.33449 18.0359 6.79817 18.3944 6.49503C18.7528 6.1919 19.2891 6.23674 19.5923 6.59518C20.2202 7.33767 20.6576 8.24831 20.8273 9.25H21.4469C22.8162 9.25 23.9263 10.3601 23.9263 11.7294V15.5994C23.9263 16.9687 22.8162 18.0788 21.4469 18.0788H20.8497C20.4711 20.6957 18.2188 22.7062 15.497 22.7062H9.59024C5.2548 22.7062 1.74023 19.1916 1.74023 14.8562V12.4731C1.74023 9.42584 3.47673 6.78562 6.0102 5.48532C6.42785 5.27097 6.94019 5.43577 7.15454 5.85341ZM18.4935 10.95C16.9944 10.95 15.7791 12.1653 15.7791 13.6644C15.7791 15.1635 16.9944 16.3788 18.4935 16.3788H21.4469C21.8773 16.3788 22.2263 16.0298 22.2263 15.5994V11.7294C22.2263 11.299 21.8773 10.95 21.4469 10.95H18.4935Z" fill="currentColor"/>
    </svg>
  )
}

export default WithdrawWalletIcon;