import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { enqueueSnackbar, VariantType } from 'notistack';
import { SalesTaxGroup } from '../constants';
import { ModalAction } from 'types/commonTypes';
import { ITaxCode } from 'store/slices/Settings/interface';
import getValidationSchema from './utils/getValidationSchema';
import { useAppDispatch } from 'store/hooks';
import { fetchAddTaxCode, fetchTaxCodes } from 'store/slices/Settings/taxesSlice';
import { fetchSalesTaxCodes } from 'store/slices/Settings/dictionariesSlice';
import Button from 'components/shared/Button/Button';
import Dialog from 'components/shared/Dialog/Dialog';
import DialogActions from 'components/shared/Dialog/DialogActions/DialogActions';
import DialogContent from 'components/shared/Dialog/DialogContent/DialogContent';
import DialogTitle from 'components/shared/Dialog/DialogTitle/DialogTitle';
import LoadingButton from 'components/shared/LoadingButton/LoadingButton';
import SimpleGrid from 'components/shared/SimpleGrid/SimpleGrid';
import TextField from 'components/shared/TextField/TextField';
import { ConfirmCancelModal } from './ConfirmCancelModal';

interface ITaxCodeModalProps {
  open: boolean;
  onClose: () => void;
  page: number;
  pageSize: number;
}

export const TaxCodeModal = ({ open, onClose, page, pageSize }: ITaxCodeModalProps) => {
  const dispatch = useAppDispatch();
  const schema = useMemo(() => getValidationSchema(ModalAction.ADD, SalesTaxGroup.CODES), []);

  const [isOpenConfirmCancelModal, setIsOpenConfirmCancelModal] = useState(false);

  const {
    reset,
    setError,
    handleSubmit,
    register,
    trigger,
    formState: { errors, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      code: '',
      category: '',
      description: '',
    },
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = async (data: Pick<ITaxCode, 'code' | 'category' | 'description'>) => {
    void trigger();

    const formattedData = {
      code: data.code.trim(),
      category: data.category.trim(),
      description: data.description.trim(),
    };

    try {
      const result = await dispatch(fetchAddTaxCode(formattedData));

      if (result.meta.requestStatus === 'fulfilled') {
        handleClose();
        enqueueSnackbar('Tax code has been added', {
          variant: 'success' as VariantType,
        });
        void dispatch(fetchTaxCodes({ page, pageSize }));
        dispatch(fetchSalesTaxCodes());
      } else if (result.payload.meta) {
        const { title, meta } = result.payload;
        const metaKeys = Object.keys(meta) as Array<keyof Omit<ITaxCode, 'id' | 'salesTaxCodeId' | 'status'>>;

        if (metaKeys.length) {
          metaKeys.forEach((key) => setError(key, { message: meta[key] }));
        } else {
          enqueueSnackbar(`An error occurred while adding the tax code: ${title}`, {
            variant: 'error' as VariantType,
          });
        }
      } else {
        enqueueSnackbar('An error occurred while adding the tax code', {
          variant: 'error' as VariantType,
        });
      }
    } catch (error) {
      enqueueSnackbar('An error occurred while adding the tax code', {
        variant: 'error' as VariantType,
      });
    }
  };

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={open}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle variant="h6">Add new sales tax code</DialogTitle>
          <DialogContent>
            <SimpleGrid
              sx={{ paddingTop: 2 }}
              container
              flexDirection="column"
              minHeight="100%"
              alignContent="stretch"
              spacing={2}
            >
              <SimpleGrid pt={2} item xs={12}>
                <TextField
                  {...register('category')}
                  size="small"
                  name="category"
                  label="Category"
                  type="text"
                  fullWidth
                  error={!!errors.category}
                  helperText={errors.category && (errors.category?.message as string)}
                  onBlur={() => trigger('category')}
                />
              </SimpleGrid>
              <SimpleGrid item xs={12}>
                <TextField
                  {...register('code')}
                  size="small"
                  name="code"
                  label="Tax code"
                  type="text"
                  fullWidth
                  error={!!errors.code}
                  helperText={errors.code && (errors.code?.message as string)}
                  onBlur={() => trigger('code')}
                />
              </SimpleGrid>
              <SimpleGrid item xs={12}>
                <TextField
                  {...register('description')}
                  size="small"
                  name="description"
                  label="Description"
                  type="text"
                  fullWidth
                  multiline
                  maxRows={4}
                  error={!!errors.description}
                  helperText={errors.description && (errors.description?.message as string)}
                  onBlur={() => trigger('description')}
                />
              </SimpleGrid>
            </SimpleGrid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                isDirty ? setIsOpenConfirmCancelModal(true) : handleClose();
              }}
              variant="text"
              color="primary"
            >
              Cancel
            </Button>
            <LoadingButton
              // disabled={!isValid || !isDirty}
              variant="contained"
              color="primary"
              type="submit"
            >
              Add tax code
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
      <ConfirmCancelModal
        action={ModalAction.ADD}
        actionName="tax code"
        closeModal={() => setIsOpenConfirmCancelModal(false)}
        open={isOpenConfirmCancelModal}
        onClose={handleClose}
      />
    </>
  );
};
