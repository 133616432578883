import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useErrorBoundary } from 'react-error-boundary';
import { enqueueSnackbar, VariantType } from 'notistack';
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { ITariffGroupDetails } from 'store/slices/Settings/interface';
import { PaymentsSettingsRoutes } from 'routes/enum';
import { Permissions } from 'types/commonTypes';
import { ErrorBoundaryErrors } from 'containers/ErrorPage/ErrorPage';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  fetchTariffGroup,
  selectTariffGroupDetails,
  selectTariffGroupDetailsStatus,
} from 'store/slices/Settings/tariffsSlice';
import { currencyFormatter } from 'utils/currencyFormatter';
import { checkPermissions } from 'utils/checkPermissions';
import { selectUserPermissions } from 'store/authSlice';
import ChevronRightIcon from 'assets/img/ChevronRightIcon';
import ArrowBack from 'assets/img/ArrowBack';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import Button from 'components/shared/Button/Button';
import CheckIfEmptyCell from 'components/shared/DataGrid/CheckIfEmptyCell/CheckIfEmptyCell';
import CustomNoRowsOverlay from 'components/shared/DataGrid/CustomNoResultsOverlay/CustomNoRowsOverlay';
import Grid from 'components/shared/Grid/Grid';
import IconButton from 'components/shared/IconButton/IconButton';
import LinkMUI from 'components/shared/Link/Link';
import RenderCellWithCopy from 'components/shared/DataGrid/RenderCellWithCopy/RenderCellWithCopy';
import SearchField from 'components/shared/SearchField/SearchField';
import Typography from 'components/shared/Typography/Typography';
import { RenderWithTooltipHeader } from './components';
import './index.scss';

export const TariffDetails: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  const tariffGroupId = params.get('tariffGroupId');
  const tariffGroupTitle = params.get('tariffGroupTitle');

  const userPermissions = useAppSelector(selectUserPermissions);
  const data = useAppSelector(selectTariffGroupDetails);
  const status = useAppSelector(selectTariffGroupDetailsStatus);
  let isLoading = status === 'loading';

  const { showBoundary } = useErrorBoundary();
  const [searchValue, setSearchValue] = useState<string>('');
  const [fetchingError, setFetchingError] = useState<boolean>(false);

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const fetchTariffGroupInitial = useCallback(async () => {
    try {
      tariffGroupId && (await dispatch(fetchTariffGroup(tariffGroupId)).unwrap());
    } catch (error) {
      showBoundary(error);
    }
  }, [dispatch]);

  const fetchTariffGroupGrid = useCallback(async () => {
    tariffGroupId &&
      dispatch(fetchTariffGroup(tariffGroupId)).then((result: any) => {
        if (result.meta && result.meta.requestStatus === 'rejected') {
          enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType });
          setFetchingError(true);
        } else if (result.meta && result.meta.requestStatus === 'fulfilled') {
          setFetchingError(false);
        }
      });
  }, [dispatch]);

  const repeatFetchingRequest = useCallback(async () => {
    fetchTariffGroupGrid();
  }, [dispatch]);

  const initialFetchData = useCallback(async () => {
    await fetchTariffGroupInitial();
  }, []);

  useEffect(() => {
    initialFetchData();
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'tariffId',
      headerName: 'Tariff ID',
      flex: 0.5,
      minWidth: 140,
      sortable: false,
      renderCell: (params) => <RenderCellWithCopy props={params} />,
    },
    {
      field: 'operationType',
      headerName: 'Operation Type',
      flex: 0.3,
      minWidth: 140,
      sortable: false,
      renderCell: ({ value }) => <span>{value ? value : '—'}</span>,
    },
    {
      field: 'providerName',
      headerName: 'Provider',
      flex: 0.3,
      minWidth: 100,
      sortable: false,
      valueFormatter: ({ value }) => (value ? value : 'No provider'),
    },
    {
      field: 'priceMin',
      headerName: 'Price min',
      flex: 0.3,
      minWidth: 100,
      sortable: false,
      type: 'number',
      renderCell: ({ value }) => <span>{Number(value) ? currencyFormatter(value, 'en-US', 'USD', true) : '—'}</span>,
    },
    {
      field: 'priceMax',
      headerName: 'Price max',
      flex: 0.3,
      minWidth: 100,
      sortable: false,
      type: 'number',
      renderCell: ({ value }) => <span>{Number(value) ? currencyFormatter(value, 'en-US', 'USD', true) : '—'}</span>,
    },
    {
      field: 'platformFeePercentageClient',
      headerName: 'PFP (Cl)',
      flex: 0.3,
      minWidth: 100,
      sortable: false,
      type: 'number',
      renderHeader: (params) => <RenderWithTooltipHeader props={params} title="Platform fee percentage (client)" />,
      renderCell: (params) => CheckIfEmptyCell(params),
      valueFormatter: ({ value }) => `${value}%`,
    },
    {
      field: 'platformFeeFixedClient',
      headerName: 'PFF (Cl)',
      flex: 0.3,
      minWidth: 100,
      sortable: false,
      type: 'number',
      renderHeader: (params) => <RenderWithTooltipHeader props={params} title="Platform fee fixed (client)" />,
      renderCell: (params) => CheckIfEmptyCell(params),
      valueFormatter: ({ value }) => {
        return value ? currencyFormatter(value, 'en-US', 'USD', true) : '—';
      },
    },
    {
      field: 'platformFeePercentageCreator',
      headerName: 'PFP (Cr)',
      flex: 0.3,
      minWidth: 100,
      sortable: false,
      type: 'number',
      renderHeader: (params) => <RenderWithTooltipHeader props={params} title="Platform fee percentage (creator)" />,
      renderCell: ({ value }) => <span>{`${value}%`}</span>,
    },
    {
      field: 'platformFeeFixedCreator',
      headerName: 'PFF (Cr)',
      flex: 0.3,
      minWidth: 100,
      sortable: false,
      type: 'number',
      renderHeader: (params) => <RenderWithTooltipHeader props={params} title="Platform fee fixed (creator)" />,
      renderCell: ({ value }) => <span>{Number(value) ? currencyFormatter(value, 'en-US', 'USD', true) : '—'}</span>,
    },
  ];

  const rows: ITariffGroupDetails[] | [] = useMemo(() => {
    if (data) {
      return data;
    } else return [];
  }, [data]);

  const isEmptyData = !rows.length;

  return (
    <div className="tariff-details-table-container">
      <div className="tariff-details-table-container__actions">
        {checkPermissions(userPermissions, [Permissions.SETTINGS_ADD_TARIFF_IN_GROUP]) && (
          <Button disabled variant="contained">
            Add tariff
          </Button>
        )}

        {!isEmptyData && (
          <SearchField
            searchValue={searchValue}
            onChange={handleChangeSearch}
            clearEvent={() => setSearchValue('')}
            label="Search by tariff ID"
            maxlength={50}
            disabled
          />
        )}
      </div>
      <div className="tariff-details-table-container__breadcrumbs">
        <Link className="unstyled-link" color="default" to={PaymentsSettingsRoutes.TARIFFS}>
          <IconButton size="small" color="default">
            <ArrowBack />
          </IconButton>
        </Link>
        <Breadcrumbs separator={<ChevronRightIcon fontSize="small" />}>
          <LinkMUI
            component={Link}
            to={PaymentsSettingsRoutes.TARIFFS}
            underline="hover"
            variant="h6"
            color="text.secondary"
          >
            Tariff groups
          </LinkMUI>
          <Typography color="text.primary" variant="h6">
            {`${tariffGroupTitle} `}
            <span>
              <Typography display="inline" color="text.secondary" variant="h6">
                ({tariffGroupId})
              </Typography>
            </span>
          </Typography>
        </Breadcrumbs>
      </div>
      <Grid
        className="tariff-details-table"
        columns={columns}
        rows={rows}
        getRowId={(row) => row.tariffId}
        loading={isLoading}
        getRowHeight={() => 'auto'}
        getRowSpacing={() => ({
          bottom: 16,
        })}
        hideFooter
        disableColumnMenu
        disableColumnReorder
        disableColumnResize
        disableSelectionOnClick
        columnVisibilityModel={{
          hidden: false,
        }}
        components={{
          NoRowsOverlay: () =>
            CustomNoRowsOverlay({
              isFiltersApplied: false,
              fetchingError: fetchingError,
              refreshData: () => repeatFetchingRequest(),
              renderNoDataComponent: () => (
                <>
                  <Typography color="text.secondary" variant="body2">
                    No tariffs fee has been added yet
                  </Typography>
                  {checkPermissions(userPermissions, [Permissions.SETTINGS_ADD_TARIFF_IN_GROUP]) && (
                    <Button variant="text" color="primary">
                      <Typography variant="buttonMedium">Create tariff</Typography>
                    </Button>
                  )}
                </>
              ),
            }),
        }}
      />
    </div>
  );
};
