import { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { useSearchParams } from 'react-router-dom';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import startCase from 'lodash/startCase';
import uniqueId from 'lodash/uniqueId';
import { useErrorBoundary } from 'react-error-boundary';
import { enqueueSnackbar, VariantType } from 'notistack';
import { ErrorCodes, SalesTaxGroup, selectableTaxStatuses } from './constants';
import { ErrorBoundaryErrors } from 'containers/ErrorPage/ErrorPage';
import { ModalAction, Permissions } from 'types/commonTypes';
import { ITaxCode, ITaxRule, ITaxStatus, TDataGridRowItem } from 'store/slices/Settings/interface';
import { checkPermissions } from 'utils/checkPermissions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectUserPermissions } from 'store/authSlice';
import { fetchSalesTaxCodes } from 'store/slices/Settings/dictionariesSlice';
import {
  fetchChangeTaxStatus,
  fetchTaxCodes,
  fetchTaxRules,
  selectTaxCodes,
  selectTaxesStatus,
  selectTaxRules,
  selectTotalCount,
} from 'store/slices/Settings/taxesSlice';
import Button from 'components/shared/Button/Button';
import ButtonGroup from 'components/shared/ButtonGroup/ButtonGroup';
import CustomFooter from 'components/shared/DataGrid/CustomFooter/CustomFooter';
import CustomNoRowsOverlay from 'components/shared/DataGrid/CustomNoResultsOverlay/CustomNoRowsOverlay';
import Grid from 'components/shared/Grid/Grid';
import Menu from 'components/shared/Menu/Menu';
import MenuItem from 'components/shared/Menu/MenuItem/MenuItem';
import RenderExpandableCell from 'components/shared/DataGrid/RenderExpandableCell/RenderExpandableCell';
import Typography from 'components/shared/Typography/Typography';
import {
  RenderActionsCell,
  RenderCategoryCell,
  RenderCertaintyCell,
  RenderPassionsCell,
  RenderStatusSelect,
} from './components';
import { ChangeStatusConfirmModal, ChangeStatusWarningModal, TaxCodeModal, TaxRuleModal } from './modals';
import './index.scss';

type TTaxRuleModal =
  | {
      item: null;
      action: ModalAction.ADD;
    }
  | {
      item: ITaxRule;
      action: ModalAction.EDIT;
    };

export const Taxes: FC = () => {
  const dispatch = useAppDispatch();
  const apiRef = useGridApiRef();

  const [searchParams, setSearchParams] = useSearchParams();
  const ruleId = searchParams.get('ruleId');

  const userPermissions = useAppSelector(selectUserPermissions);
  const taxCodes = useAppSelector(selectTaxCodes);
  const taxRules = useAppSelector(selectTaxRules);
  const totalCount = useAppSelector(selectTotalCount);
  const status = useAppSelector(selectTaxesStatus);
  let isLoading = status === 'loading';

  const { showBoundary } = useErrorBoundary();
  const [isTaxCodeModalOpen, setIsTaxCodeModalOpen] = useState(false);
  const [isTaxRuleModalOpen, setIsTaxRuleModalOpen] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [highlightedRuleId, setHighlightedRuleId] = useState<string | null>(null);
  const [fetchingError, setFetchingError] = useState<boolean>(false);

  const [salesTaxGroup, setSalesTaxGroup] = useState<string>(SalesTaxGroup.RULES);
  const [taxRuleModalState, setTaxRuleModalState] = useState<TTaxRuleModal | null>(null);

  const [pageCodes, setPageCodes] = useState(0);
  const [pageSizeCodes, setPageSizeCodes] = useState(25);

  const [pageRules, setPageRules] = useState(0);
  const [pageSizeRules, setPageSizeRules] = useState(25);

  const [statusMenuAnchorEl, setStatusMenuAnchorEl] = useState<null | HTMLElement>(null);
  const statusMenuOpen = Boolean(statusMenuAnchorEl);

  const [currentSelectedStatus, setCurrentSelectedStatus] = useState<ITaxStatus | null>(null);
  const [currentSelectedRow, setCurrentSelectedRow] = useState<(ITaxCode & ITaxRule) | null>(null);

  const columns: GridColDef[] | [] = useMemo(() => {
    if (salesTaxGroup === SalesTaxGroup.CODES) {
      return [
        {
          field: 'category',
          headerName: 'Category',
          flex: 1,
          minWidth: 140,
          sortable: false,
          renderCell: ({ value }) => <span>{value ? value : '—'}</span>,
        },
        {
          field: 'code',
          headerName: 'Tax code',
          flex: 1,
          minWidth: 140,
          sortable: false,
          renderCell: ({ value }) => <span>{value ? value : '—'}</span>,
        },
        {
          field: 'description',
          headerName: 'Description',
          flex: 3,
          minWidth: 280,
          sortable: false,
          renderCell: (params) => <RenderExpandableCell {...params} />,
        },
        {
          field: 'status',
          headerName: 'Status',
          flex: 0.4,
          minWidth: 100,
          sortable: false,
          renderCell: (params) => (
            <RenderStatusSelect
              props={params}
              disablePermission={[Permissions.SETTINGS_CHANGE_TAX_CODE_STATUS]}
              selectedCellData={currentSelectedRow}
              isMenuOpen={statusMenuOpen}
              onClick={(e, data) => handleStatusMenuClick(e, data)}
            />
          ),
        },
      ];
    } else
      return [
        {
          field: 'entityType',
          headerName: 'Entity',
          flex: 0.6,
          minWidth: 140,
          sortable: false,
          renderCell: ({ value }) => <span>{value ? startCase(value?.toLowerCase()) : '—'}</span>,
        },
        {
          field: 'format',
          headerName: 'Format',
          flex: 0.6,
          minWidth: 140,
          sortable: false,
          renderCell: ({ value }) => <span>{value ? startCase(value?.toLowerCase()) : '—'}</span>,
        },
        {
          field: 'eventOnlineFormatType',
          headerName: 'Type',
          flex: 0.6,
          minWidth: 140,
          sortable: false,
          renderCell: ({ value }) => <span>{value ? startCase(value?.toLowerCase()) : '—'}</span>,
        },
        {
          field: 'eventCategories',
          headerName: 'YZZY category',
          flex: 0.6,
          minWidth: 140,
          sortable: false,
          renderCell: (params) => <RenderCategoryCell props={params} />,
        },
        {
          field: 'passions',
          headerName: 'Passions',
          flex: 1,
          minWidth: 160,
          sortable: false,
          renderCell: (params) => <RenderPassionsCell props={params} />,
        },
        {
          field: 'salesTaxCode.category',
          headerName: 'Sales tax category',
          flex: 1,
          minWidth: 160,
          sortable: false,
          renderCell: ({ value }) => <span>{value ? value : '—'}</span>,
          valueGetter: (params) => params.row.salesTaxCode?.category,
        },
        {
          field: 'salesTaxCode.code',
          headerName: 'Sales tax code',
          flex: 1,
          minWidth: 140,
          sortable: false,
          renderCell: ({ value }) => <span>{value ? value : '—'}</span>,
          valueGetter: (params) => params.row.salesTaxCode?.code,
        },
        {
          field: 'certainty',
          headerName: 'Certainty',
          flex: 0.6,
          minWidth: 120,
          sortable: false,
          renderCell: (params) => <RenderCertaintyCell props={params} />,
        },
        {
          field: 'status',
          headerName: 'Status',
          flex: 0.4,
          minWidth: 100,
          sortable: false,
          renderCell: (params) => (
            <RenderStatusSelect
              props={params}
              disablePermission={[Permissions.SETTINGS_CHANGE_TAXES_RULE_STATUS]}
              selectedCellData={currentSelectedRow}
              isMenuOpen={statusMenuOpen}
              onClick={(e, data) => handleStatusMenuClick(e, data)}
            />
          ),
        },
        {
          field: 'hidden',
          headerName: '',
          flex: 0.4,
          minWidth: 70,
          sortable: false,
          renderCell: (params) => (
            <RenderActionsCell props={params} onClick={(e, data) => handleActionMenuClick(e, data)} />
          ),
        },
      ];
  }, [salesTaxGroup, statusMenuOpen]);

  const rows: ITaxCode[] | ITaxRule[] | [] = useMemo(() => {
    if (salesTaxGroup === SalesTaxGroup.CODES && taxCodes) {
      return taxCodes;
    } else if (taxRules) {
      return taxRules;
    } else return [];
  }, [salesTaxGroup, taxRules, taxCodes]);

  const isEmptyData = !rows.length;

  const rowCount: number = useMemo(() => {
    if (salesTaxGroup === SalesTaxGroup.CODES) {
      return totalCount.taxCodesCount;
    } else return totalCount.taxRulesCount;
  }, [salesTaxGroup, totalCount]);

  const page: number = useMemo(() => {
    return salesTaxGroup === SalesTaxGroup.CODES ? pageCodes : pageRules;
  }, [pageCodes, pageRules, salesTaxGroup]);

  const pageSize: number = useMemo(() => {
    return salesTaxGroup === SalesTaxGroup.CODES ? pageSizeCodes : pageSizeRules;
  }, [pageSizeCodes, pageSizeRules, salesTaxGroup]);

  const handleStatusMenuClick = (event: MouseEvent<HTMLDivElement>, data: ITaxCode & ITaxRule) => {
    event.stopPropagation();
    setStatusMenuAnchorEl(event.currentTarget);
    setCurrentSelectedStatus(null);
    setCurrentSelectedRow(data);
  };

  const handleStatusMenuClose = () => {
    setStatusMenuAnchorEl(null);
  };

  const handleChangeStatusClick = (data: ITaxStatus) => {
    setCurrentSelectedStatus(data);
    handleStatusMenuClose();
  };

  const handleChangePage = (page: number) => {
    if (salesTaxGroup === SalesTaxGroup.CODES) {
      setPageCodes(page);
    } else {
      setPageRules(page);
    }
    void fetchTaxesGrid(page, salesTaxGroup === SalesTaxGroup.CODES ? pageSizeCodes : pageSizeRules, salesTaxGroup);
  };

  const handleChangePageSize = (pageSize: number) => {
    if (salesTaxGroup === SalesTaxGroup.CODES) {
      setPageSizeCodes(pageSize);
    } else {
      setPageSizeRules(pageSize);
    }
    void fetchTaxesGrid(salesTaxGroup === SalesTaxGroup.CODES ? pageCodes : pageRules, pageSize, salesTaxGroup);
  };

  const fetchTaxesInitial = useCallback(
    async (page: number, pageSize: number, selectedTaxGroup?: string, salesTaxRuleId?: string) => {
      try {
        if (selectedTaxGroup === SalesTaxGroup.CODES) {
          await dispatch(fetchTaxCodes({ page, pageSize })).unwrap();
        } else {
          await dispatch(fetchTaxRules({ page, pageSize, salesTaxRuleId })).unwrap();
        }
      } catch (error) {
        showBoundary(error);
      }
    },
    [dispatch],
  );

  const fetchTaxesGrid = useCallback(
    async (page: number, pageSize: number, selectedTaxGroup?: string, salesTaxRuleId?: string) => {
      if (selectedTaxGroup === SalesTaxGroup.CODES) {
        dispatch(fetchTaxCodes({ page, pageSize })).then((result: any) => {
          if (result.meta && result.meta.requestStatus === 'rejected') {
            enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType });
            setFetchingError(true);
          } else if (result.meta && result.meta.requestStatus === 'fulfilled') {
            setFetchingError(false);
          }
        });
      } else {
        dispatch(fetchTaxRules({ page, pageSize, salesTaxRuleId })).then((result: any) => {
          if (result.meta && result.meta.requestStatus === 'rejected') {
            enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType });
            setFetchingError(true);
          } else if (result.meta && result.meta.requestStatus === 'fulfilled') {
            setPageRules(result.payload.currentPage - 1);
            setFetchingError(false);
          }
        });
      }
    },
    [dispatch],
  );

  const handleChangeSalesTaxGroup = useCallback(
    (value: string) => {
      if (searchParams.has('ruleId')) {
        searchParams.delete('ruleId');
        setSearchParams(searchParams);
      }
      setPageCodes(0);
      setPageRules(0);
      setSalesTaxGroup(value);

      void fetchTaxesGrid(page, pageSize, value);
    },
    [fetchTaxesGrid, searchParams, pageSize],
  );

  const handleOverlayButtonClick = () => {
    if (salesTaxGroup === SalesTaxGroup.RULES) {
      setIsTaxCodeModalOpen(false);
      setIsTaxRuleModalOpen(true);
      setTaxRuleModalState({ action: ModalAction.ADD, item: null });
    } else {
      setIsTaxRuleModalOpen(false);
      setIsTaxCodeModalOpen(true);
    }
  };

  const handleTaxRuleModalClose = (data: ITaxRule | undefined) => {
    setIsTaxRuleModalOpen(false);
    if (data) {
      setHighlightedRuleId(data.salesTaxRuleId);
      setTimeout(() => {
        setHighlightedRuleId(null);
      }, 2000);
    }
  };

  const handleWarningModalClose = () => {
    setShowWarningModal(false);
  };

  const handleConfirmModalClose = () => {
    setShowConfirmModal(false);
  };

  const repeatFetchingRequest = useCallback(async () => {
    fetchTaxesGrid(page, pageSize, salesTaxGroup);
  }, [dispatch, page, pageSize]);

  const initialFetchData = useCallback(async () => {
    await fetchTaxesInitial(pageRules, pageSizeRules, SalesTaxGroup.RULES, ruleId ? ruleId : undefined);
  }, [pageRules, pageSizeRules, ruleId]);

  const handleActionMenuClick = (event: MouseEvent<HTMLButtonElement>, data: ITaxCode & ITaxRule) => {
    setCurrentSelectedRow(data);
    setCurrentSelectedStatus(null);
    setTaxRuleModalState({ action: ModalAction.EDIT, item: data as ITaxRule });
    data && setIsTaxRuleModalOpen(true);
  };

  const handleChangeStatus = useCallback(async () => {
    const result = await dispatch(
      fetchChangeTaxStatus({
        currentSelectedRow: currentSelectedRow,
        currentSelectedStatus: currentSelectedStatus,
      }),
    );

    if (result.meta.requestStatus === 'fulfilled') {
      void fetchTaxesGrid(0, pageSize, salesTaxGroup);
      dispatch(fetchSalesTaxCodes());
    } else if (result.meta.requestStatus === 'rejected' && result.payload.code === ErrorCodes.TAX_CODE_USED_IN_RULES) {
      setShowWarningModal(true);
      dispatch(fetchSalesTaxCodes());
    } else if (
      result.meta.requestStatus === 'rejected' &&
      result.payload.code === ErrorCodes.INCORRECT_TAX_CODE_STATUS
    ) {
      setShowConfirmModal(true);
      dispatch(fetchSalesTaxCodes());
    } else {
      enqueueSnackbar(result.payload.title ?? `An error occurred, try again later`, {
        variant: 'error' as VariantType,
      });
    }
  }, [currentSelectedStatus, currentSelectedRow]);

  useEffect(() => {
    initialFetchData();
  }, []);

  useEffect(() => {
    currentSelectedStatus?.status !== currentSelectedRow?.status && currentSelectedStatus && handleChangeStatus();
  }, [currentSelectedStatus, currentSelectedRow, handleChangeStatus]);

  useEffect(() => {
    if (apiRef && ruleId && taxRules && !isLoading) {
      apiRef.current.selectRow(ruleId, true);
    }
  }, [apiRef, ruleId, taxRules, isLoading]);

  useEffect(() => {
    const handleSelectRow = () => {
      if (apiRef.current && ruleId) {
        apiRef.current.selectRow(ruleId, false);
      }
    };

    document.addEventListener('mousedown', handleSelectRow);

    return () => {
      document.removeEventListener('mousedown', handleSelectRow);
    };
  }, [apiRef, ruleId]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (apiRef.current && ruleId && taxRules && !isLoading) {
        let rowIndex = apiRef.current.getRowIndex(ruleId);
        apiRef.current.scrollToIndexes({ rowIndex: page * pageSize + rowIndex });
        searchParams.delete('ruleId');
        setSearchParams(searchParams);
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [apiRef, ruleId, taxRules, isLoading, page, pageSize]);

  return (
    <div className="taxes-table-container">
      <div className="taxes-table-container__actions">
        <ButtonGroup className="taxes-button-group" size="medium" color="primary">
          <Button
            disabled={!checkPermissions(userPermissions, [Permissions.SETTINGS_VIEW_TAXES_RULES])}
            className={salesTaxGroup === SalesTaxGroup.RULES ? 'Mui-selected' : ''}
            onClick={() => handleChangeSalesTaxGroup(SalesTaxGroup.RULES)}
          >
            Rules For Sales Tax
          </Button>
          <Button
            disabled={!checkPermissions(userPermissions, [Permissions.SETTINGS_VIEW_TAXES_CODES])}
            className={salesTaxGroup === SalesTaxGroup.CODES ? 'Mui-selected' : ''}
            onClick={() => handleChangeSalesTaxGroup(SalesTaxGroup.CODES)}
          >
            Sales tax codes
          </Button>
        </ButtonGroup>
        {checkPermissions(userPermissions, [
          salesTaxGroup === SalesTaxGroup.CODES
            ? Permissions.SETTINGS_ADD_TAX_CODE
            : Permissions.SETTINGS_ADD_TAXES_RULE,
        ]) && (
          <Button variant="contained" onClick={handleOverlayButtonClick}>
            <Typography variant="buttonMedium">
              {salesTaxGroup === SalesTaxGroup.CODES ? 'Add tax code' : 'Add rule'}
            </Typography>
          </Button>
        )}
      </div>
      <Grid
        apiRef={apiRef}
        className="taxes-table"
        columns={columns}
        rows={rows}
        getRowId={(row) => (salesTaxGroup === SalesTaxGroup.RULES ? row.salesTaxRuleId : row.id)}
        getRowClassName={(params) =>
          `${params.row.salesTaxRuleId === highlightedRuleId ? 'taxes-table__highlighted-row' : ''}`
        }
        loading={isLoading}
        getRowHeight={() => 'auto'}
        page={page}
        pageSize={pageSize}
        pagination
        paginationMode="server"
        rowCount={rowCount}
        rowsPerPageOptions={[10, 25, 50, 100]}
        hideFooterSelectedRowCount
        disableVirtualization
        disableColumnMenu
        disableColumnReorder
        disableColumnResize
        disableSelectionOnClick
        columnVisibilityModel={{
          hidden: checkPermissions(userPermissions, [Permissions.SETTINGS_EDIT_SALES_TAX]),
        }}
        onPageChange={(newPage) => handleChangePage(newPage)}
        onPageSizeChange={(newPageSize) => handleChangePageSize(newPageSize)}
        components={{
          NoRowsOverlay: () =>
            CustomNoRowsOverlay({
              isFiltersApplied: false,
              fetchingError: fetchingError,
              refreshData: () => repeatFetchingRequest(),
              renderNoDataComponent: () => (
                <>
                  <Typography color="text.secondary" variant="body2">
                    No {salesTaxGroup === SalesTaxGroup.RULES ? 'rules for sales tax have' : 'sales tax code has'} been
                    added yet
                  </Typography>
                  {checkPermissions(userPermissions, [
                    salesTaxGroup === SalesTaxGroup.CODES
                      ? Permissions.SETTINGS_ADD_TAX_CODE
                      : Permissions.SETTINGS_ADD_TAXES_RULE,
                  ]) && (
                    <Button onClick={handleOverlayButtonClick} variant="text" color="primary">
                      <Typography variant="buttonMedium">
                        {salesTaxGroup === SalesTaxGroup.CODES ? 'Add tax code' : 'Add rule'}
                      </Typography>
                    </Button>
                  )}
                </>
              ),
            }),
          Footer: CustomFooter,
        }}
        hideFooter={isEmptyData && !fetchingError}
      />
      <Menu
        id="status-menu"
        anchorEl={statusMenuAnchorEl}
        open={statusMenuOpen}
        onClose={handleStatusMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {selectableTaxStatuses.map((status: ITaxStatus) => (
          <MenuItem
            key={uniqueId()}
            selected={status.status === currentSelectedRow?.status}
            onClick={() => handleChangeStatusClick(status)}
          >
            {status.title}
          </MenuItem>
        ))}
      </Menu>
      <TaxCodeModal
        open={isTaxCodeModalOpen}
        onClose={() => setIsTaxCodeModalOpen(false)}
        page={page}
        pageSize={pageSize}
      />
      {taxRuleModalState && (
        <TaxRuleModal
          action={taxRuleModalState.action}
          item={taxRuleModalState.action === ModalAction.EDIT ? (taxRuleModalState.item as TDataGridRowItem) : null}
          open={isTaxRuleModalOpen}
          onClose={(data) => handleTaxRuleModalClose(data)}
          page={page}
          pageSize={pageSize}
        />
      )}

      <ChangeStatusWarningModal open={showWarningModal} onClose={handleWarningModalClose} />
      <ChangeStatusConfirmModal
        page={page}
        pageSize={pageSize}
        data={currentSelectedRow}
        open={showConfirmModal}
        onClose={handleConfirmModalClose}
      />
    </div>
  );
};
