import { createSvgIcon } from '@mui/material';

const ChevronDownIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.73193 10.2989C6.39998 9.96697 6.39998 9.42877 6.73193 9.09683C7.06388 8.76488 7.60207 8.76488 7.93401 9.09683L11.9999 13.1627L16.0656 9.09695C16.3976 8.76501 16.9358 8.76501 17.2677 9.09695C17.5996 9.4289 17.5996 9.96709 17.2677 10.299L12.707 14.8598C12.3165 15.2503 11.6833 15.2503 11.2928 14.8597L6.73193 10.2989Z" />
  </svg>,
  'ChevronDown',
);

export default ChevronDownIcon;
