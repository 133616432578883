import axios, { isAxiosError } from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOperation, IOperationDetails, IOperationSearchItem, IOperationsState, OperationActions } from './interface';
import { IStatus } from 'types/commonTypes';
import { RootState } from 'store/store';

const initialState: IOperationsState = {
  operations: [],
  operationDetails: null,
  operationsCount: 0,
  status: 'idle',
  actionStatus: 'idle',
  currentAction: null,
  operationDetailsStatus: 'idle',
  operationsSearch: [],
  operationsSearchAll: [],
  operationsSearchStatus: 'idle',
};

export const fetchOperations = createAsyncThunk(
  'payments/operations',
  async ({ page, pageSize }: { page: number; pageSize: number }, { rejectWithValue, getState }) => {
    try {
      const rootState: RootState = getState() as RootState;
      const filters = Object.keys(rootState.filters.data)
        ? Object.values(rootState.filters.data).filter((item: any) => item !== null)
        : [];
      const sortingList = Object.keys(rootState.sorting.data)
        ? Object.values(rootState.sorting.data).filter(
            (item: any) => item !== null && item.columnId !== null && item.sortingType !== null,
          )
        : [];
      const response = await axios.post((process.env.REACT_APP_OPERATIONS_ENDPOINT as string) + 'operations/find', {
        pageInfo: {
          page: page + 1,
          size: pageSize,
        },
        sortingList: sortingList,
        filters: filters,
      });

      return response.data;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue({
          message: error.message || 'An unexpected error occurred',
          status: error.response?.status,
        });
      } else {
        return rejectWithValue({
          message: 'An unexpected error occurred',
        });
      }
    }
  },
);

export const fetchOperationDetails = createAsyncThunk(
  'payments/operationDetails',
  async (operationId: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        (process.env.REACT_APP_OPERATIONS_ENDPOINT as string) + `operations/${operationId}/details`,
      );

      return response.data;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue({
          message: error.message || 'An unexpected error occurred',
          status: error.response?.status,
        });
      } else {
        return rejectWithValue({
          message: 'An unexpected error occurred',
        });
      }
    }
  },
);

export const fetchOperationAction = createAsyncThunk(
  'payments/operationAction',
  async (
    {
      operationId,
      action,
      comment,
      reason,
      inApp,
    }: {
      operationId: string;
      action: OperationActions;
      comment?: string;
      reason?: string;
      inApp?: boolean | null;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_OPERATIONS_ENDPOINT as string}operations/${operationId}/status`,
        {
          action: action,
          comment: comment,
          reason: reason,
          inApp: inApp,
        },
      );

      return response.data;
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data);
    }
  },
);

export const fetchOperationsSearch = createAsyncThunk(
  'payments/operationsSearch',
  async ({ searchValue, listSize }: { searchValue: string; listSize?: number }, { rejectWithValue }) => {
    try {
      const response = await axios.post((process.env.REACT_APP_OPERATIONS_ENDPOINT as string) + `operations/search`, {
        value: searchValue,
        size: listSize,
      });

      return response.data;
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data);
    }
  },
);

export const OperationsSlice = createSlice({
  name: 'operations',
  initialState,
  reducers: {
    setOperationsStatus: (state, action: PayloadAction<IStatus>) => {
      state.status = action.payload;
    },
    clearOperationsSearch: (state) => {
      state.operationsSearch = [];
      state.operationsSearchAll = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOperations.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOperations.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.operations = payload.content;
        state.operationsCount = payload.totalElements;
      })
      .addCase(fetchOperations.rejected, (state) => {
        state.status = 'failed';
        state.operations = [];
        state.operationsCount = 0;
      })
      .addCase(fetchOperationDetails.pending, (state) => {
        state.operationDetailsStatus = 'loading';
      })
      .addCase(fetchOperationDetails.fulfilled, (state, { payload }) => {
        state.operationDetailsStatus = 'idle';
        state.operationDetails = payload;
      })
      .addCase(fetchOperationDetails.rejected, (state) => {
        state.operationDetailsStatus = 'failed';
      })
      .addCase(fetchOperationAction.pending, (state, action) => {
        state.currentAction = action.meta.arg.action;
        state.actionStatus = 'loading';
      })
      .addCase(fetchOperationAction.fulfilled, (state) => {
        state.currentAction = null;
        state.actionStatus = 'idle';
      })
      .addCase(fetchOperationAction.rejected, (state) => {
        state.currentAction = null;
        state.actionStatus = 'failed';
      })
      .addCase(fetchOperationsSearch.pending, (state) => {
        state.operationsSearchStatus = 'loading';
      })
      .addCase(fetchOperationsSearch.fulfilled, (state, { payload }) => {
        state.operationsSearch = payload.operations;
        state.operationsSearchAll = payload.allIds;
        state.operationsSearchStatus = 'idle';
      })
      .addCase(fetchOperationsSearch.rejected, (state) => {
        state.operationsSearchStatus = 'failed';
      });
  },
});

export const selectOperations = (state: RootState): IOperation[] => state.operations.operations;
export const selectOperationsSearchResults = (state: RootState): IOperationSearchItem[] =>
  state.operations.operationsSearch;
export const selectOperationsSearchAllResults = (state: RootState): string[] => state.operations.operationsSearchAll;
export const selectOperationsSearchStatus = (state: RootState): IStatus => state.operations.operationsSearchStatus;
export const selectOperationDetails = (state: RootState): IOperationDetails | null => state.operations.operationDetails;
export const selectTotalCount = (state: RootState): number => state.operations.operationsCount;
export const selectOperationsStatus = (state: RootState): IStatus => state.operations.status;
export const selectOperationDetailsStatus = (state: RootState): IStatus => state.operations.operationDetailsStatus;
export const selectOperationActionStatus = (state: RootState): IStatus => state.operations.actionStatus;
export const selectCurrentOperationAction = (state: RootState): OperationActions | null =>
  state.operations.currentAction;

export const { setOperationsStatus, clearOperationsSearch } = OperationsSlice.actions;

export default OperationsSlice.reducer;
