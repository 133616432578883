import { Breadcrumbs as BreadcrumbsBase } from "@mui/material";
import { BreadcrumbsProps } from "@mui/material/Breadcrumbs/Breadcrumbs";

interface IBreadcrumbsProps extends BreadcrumbsProps {
  primary?: boolean;
  label?: string;
}

const Breadcrumbs = (props: IBreadcrumbsProps) => {
  return (
    <BreadcrumbsBase aria-label="breadcrumb" separator={props.separator || "/"}>
      {props.children}
    </BreadcrumbsBase>
  )
};

export default Breadcrumbs;
