import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ITariffGroup, ITariffGroupDetails, ITariffGroupsState } from './interface';
import { RootState } from 'store/store';
import { IStatus } from 'types/commonTypes';

const initialState: ITariffGroupsState = {
  tariffGroups: [],
  tariffGroupDetails: [],
  tariffGroupsStatus: 'idle',
  tariffGroupDetailsStatus: 'idle',
};

export const fetchTariffGroups = createAsyncThunk('payments/tariffGroups', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(
      (process.env.REACT_APP_PAYMENTS_ENDPOINT as string) + 'payment-adapter/tariffs/groups',
    );
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      return rejectWithValue({
        message: error.message || 'An unexpected error occurred',
        status: error.response?.status,
      });
    } else {
      return rejectWithValue({
        message: 'An unexpected error occurred',
      });
    }
  }
});

export const fetchTariffGroup = createAsyncThunk(
  'payments/tariffGroupDetails',
  async (tariffGroupId: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        (process.env.REACT_APP_PAYMENTS_ENDPOINT as string) + `payment-adapter/tariffs/groups/${tariffGroupId}`,
      );
      return response.data;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue({
          message: error.message || 'An unexpected error occurred',
          status: error.response?.status,
        });
      } else {
        return rejectWithValue({
          message: 'An unexpected error occurred',
        });
      }
    }
  },
);

export const tariffsSlice = createSlice({
  name: 'tariffs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTariffGroups.pending, (state) => {
        state.tariffGroupsStatus = 'loading';
      })
      .addCase(fetchTariffGroups.fulfilled, (state, { payload }) => {
        state.tariffGroupsStatus = 'idle';
        state.tariffGroups = payload;
      })
      .addCase(fetchTariffGroups.rejected, (state) => {
        state.tariffGroupsStatus = 'failed';
        state.tariffGroups = [];
      })
      .addCase(fetchTariffGroup.pending, (state) => {
        state.tariffGroupDetailsStatus = 'loading';
      })
      .addCase(fetchTariffGroup.fulfilled, (state, { payload }) => {
        state.tariffGroupDetailsStatus = 'idle';
        state.tariffGroupDetails = payload;
      })
      .addCase(fetchTariffGroup.rejected, (state) => {
        state.tariffGroupDetailsStatus = 'failed';
      });
  },
});

export const selectTariffGroups = (state: RootState): ITariffGroup[] => state.tariffs.tariffGroups;
export const selectTariffGroupDetails = (state: RootState): ITariffGroupDetails[] => state.tariffs.tariffGroupDetails;
export const selectTariffGroupsStatus = (state: RootState): IStatus => state.tariffs.tariffGroupsStatus;
export const selectTariffGroupDetailsStatus = (state: RootState): IStatus => state.tariffs.tariffGroupDetailsStatus;

export default tariffsSlice.reducer;
