import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from 'store/store';
import { Rules } from 'components/Moderation/types/ModerationTabs';
import { IStatus } from 'types/commonTypes';
import { fetchAddRule, fetchDeleteRule } from 'store/rulesSlice';

export interface ModerationMetricsState {
  status: IStatus;
  allMetrics: {
    content: string[];
    profile: string[];
  };
  availableMetrics: {
    hive: string[];
    content: string[];
    profile: string[];
  };
  dangerousMetrics: {
    hive: string[];
    content: string[];
    profile: string[];
  };
}

const initialState: ModerationMetricsState = {
  status: 'idle',
  allMetrics: {
    content: [],
    profile: [],
  },
  availableMetrics: {
    hive: [],
    content: [],
    profile: [],
  },
  dangerousMetrics: {
    hive: [],
    content: [],
    profile: [],
  },
};

export const fetchAllMetrics = createAsyncThunk('metrics/all', async () => {
  const url = process.env.REACT_APP_MODERATION_RULES_ENDPOINT as string;

  const responseContent = await axios.get(url + 'moderation/metrics/manual-content');
  const responseProfile = await axios.get(url + 'moderation/metrics/manual-profile');
  return {
    content: responseContent.data,
    profile: responseProfile.data,
  };
});

export const fetchDangerousMetrics = createAsyncThunk('metrics/dangerous', async () => {
  const url = process.env.REACT_APP_MODERATION_RULES_ENDPOINT as string;

  const responseHiveText = await axios.get(url + 'moderation/metrics/hive/dangerous/TEXT');
  const responseHiveVISUAL = await axios.get(url + 'moderation/metrics/hive/dangerous/VISUAL');
  const responseContent = await axios.get(url + 'moderation/metrics/manual-content/dangerous');
  const responseProfile = await axios.get(url + 'moderation/metrics/manual-profile/dangerous');
  return {
    hive: responseHiveText.data.concat(responseHiveVISUAL.data),
    content: responseContent.data,
    profile: responseProfile.data,
  };
});

export const fetchAvailableMetrics = createAsyncThunk('metrics/available', async () => {
  const url = process.env.REACT_APP_MODERATION_RULES_ENDPOINT as string;

  const responseHive = await axios.get(url + 'moderation/metrics/hive/available');
  const responseContent = await axios.get(url + 'moderation/metrics/manual-content/available');
  const responseProfile = await axios.get(url + 'moderation/metrics/manual-profile/available');
  return {
    hive: responseHive.data,
    content: responseContent.data,
    profile: responseProfile.data,
  };
});

export const moderationMetricsSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllMetrics.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllMetrics.fulfilled, (state, action) => {
        state.status = 'idle';
        state.allMetrics = action.payload;
      })
      .addCase(fetchAllMetrics.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchDangerousMetrics.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDangerousMetrics.fulfilled, (state, action) => {
        state.status = 'idle';
        state.dangerousMetrics = action.payload;
      })
      .addCase(fetchDangerousMetrics.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchAvailableMetrics.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAvailableMetrics.fulfilled, (state, action) => {
        state.status = 'idle';
        state.availableMetrics = action.payload;
      })
      .addCase(fetchAvailableMetrics.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchDeleteRule.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        if (payload.typeRule === Rules.HIVE) {
          state.availableMetrics.hive = [...state.availableMetrics.hive, payload.metric];
        }
        if (payload.typeRule === Rules.MANUAL) {
          state.availableMetrics.content = [...state.availableMetrics.content, payload.metric];
        }
        if (payload.typeRule === Rules.PROFILE) {
          state.availableMetrics.profile = [...state.availableMetrics.profile, payload.metric];
        }
      })
      .addCase(fetchAddRule.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        if (payload.typeRule === Rules.HIVE) {
          state.availableMetrics.hive = [
            ...state.availableMetrics.hive.filter((value) => value !== payload.data.metric),
          ];
        }
        if (payload.typeRule === Rules.MANUAL) {
          state.availableMetrics.content = [
            ...state.availableMetrics.content.filter((value) => value !== payload.data.metric),
          ];
        }
        if (payload.typeRule === Rules.PROFILE) {
          state.availableMetrics.profile = [
            ...state.availableMetrics.profile.filter((value) => value !== payload.data.metric),
          ];
        }
      });
  },
});

export default moderationMetricsSlice.reducer;
export const selectAllMetrics = (state: RootState): any => state.metrics.allMetrics;
export const selectAvailableMetrics = (state: RootState): any => state.metrics.availableMetrics;
export const selectDangerousMetrics = (state: RootState): any => state.metrics.dangerousMetrics;
