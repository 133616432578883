import { Fragment } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import colors from 'theme/constants/colors';
import Box from 'components/shared/Box/Box';
import Divider from 'components/shared/Divider/Divider';
import Typography from 'components/shared/Typography/Typography';

/**
 * Props for the RenderPassionsCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 */
interface IRenderPassionsCellProps {
  props: GridRenderCellParams;
}

/**
 * Renders a list of passions in a data grid cell.
 */
export const RenderPassionsCell = ({ props }: IRenderPassionsCellProps): JSX.Element => {
  const { row } = props;

  if (!row.passions?.flat()?.length) {
    return <span>—</span>;
  }

  const renderPassion = (
    passionGroup: {
      id: string;
      title: string;
    }[],
    idx: number,
  ): JSX.Element => (
    <Box key={idx} width={1} sx={{ whiteSpace: 'pre-line' }}>
      {passionGroup.map((passion, subIdx) => (
        <Fragment key={subIdx}>
          {passion.title}
          {subIdx < passionGroup?.length - 1 && (
            <Typography variant="body2" display="inline" color={colors.outlinedBorder}>
              {'\n + '}
            </Typography>
          )}
        </Fragment>
      ))}

      {row.passions?.length > 1 && idx < row.passions?.length - 1 && (
        <Divider flexItem orientation="horizontal" key={`divider-${idx}`}>
          <Typography variant="tooltip" color={colors.outlinedBorder} key={`tooltip-${idx}`}>
            OR
          </Typography>
        </Divider>
      )}
    </Box>
  );

  return (
    <Box display="flex" alignItems="flex-start" flexDirection="column" width={1}>
      {row.passions.map(renderPassion)}
    </Box>
  );
};
