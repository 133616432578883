import { createSvgIcon } from '@mui/material';

const ChevronRightIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <g id="Chevron-Right">
      <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M10.2988 17.2681C9.96681 17.6 9.42862 17.6 9.09668 17.2681C8.76473 16.9361 8.76473 16.3979 9.09668 16.066L13.1625 12.0001L9.0968 7.93438C8.76485 7.60243 8.76485 7.06424 9.0968 6.7323C9.42875 6.40035 9.96694 6.40035 10.2989 6.7323L14.8596 11.293C15.2501 11.6835 15.2501 12.3167 14.8596 12.7072L10.2988 17.2681Z" />
    </g>
  </svg>,
  'ChevronRight',
);

export default ChevronRightIcon;
