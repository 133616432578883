import React from "react";
import Button from "components/shared/Button/Button";
import { Banner } from "store/bannerDetailsSlice";
import Alert from "components/shared/Alert/Alert";

import './index.scss';
import { useAppSelector } from "store/hooks";
import LoadingButton from "components/shared/LoadingButton/LoadingButton";

interface IProps {
  item: Banner;
  onClose(): void;
  expire(item: Banner): void;
}

const ExpiredBannerModal = ({ item, onClose, expire }: IProps) => {
  const expireBannerStatus = useAppSelector(state => state.worldManagement.expireBannerStatus);

  return (
    <div className='expiredBannerModal'>
      <div className="expiredBannerModal-header">
        You are going to set banner as expired:
      </div>
      <div className="expiredBannerModal-content">
        <div className='item-content'>
          <div className='item-primary'>{item.name}</div>
          <div className='item-secondary'>{item.creatorName} {item.creatorLastName}</div>
        </div>
      </div>
      <Alert severity="warning">After this action banner will be invisible for app users</Alert>
      <div className="expiredBannerModal-footer">
        <div className="expiredBannerModal-footer-buttons">
          <Button
            onClick={onClose}
            variant="text"
            color="primary"
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="warning"
            loading={expireBannerStatus === 'loading'}
            onClick={() => expire(item)}>
            Set banner as expired
          </LoadingButton>
        </div>
      </div>
    </div>
  )
};

export default ExpiredBannerModal;
