import * as yup from "yup";
import { Rules } from "components/Moderation/types/ModerationTabs";

const getValidationSchema = (contentType: Rules, action: string) => {
  if (action === 'delete') {
    return yup.object().shape({
      id: yup.string(),
    });
  }
  return yup.object().shape({
    metric: yup.string()
      .required('Category field must be filled'),
    type: yup.string(),
    reviewMinScore: yup.number()
      .typeError('Review score must be in a range from 0.6 to 1.0')
      .when('reviewMinScore', (_, schema) => {
        return contentType === Rules.HIVE ? schema.required('Review score must be in a range from 0.6 to 1.0') : schema;
      })
      .min(0.6, 'Review score must be in a range from 0.6 to 1.0')
      .max(1, 'Review score must be in a range from 0.6 to 1.0')
      .lessThan(yup.ref('decisionMinScore'), 'Review score can’t be more than confirmation score'),
    decisionMinScore: yup.number()
      .typeError('Confirmation score must be in a range from 0.6 to 1.0')
      .when('decisionMinScore', (_, schema) => {
        return contentType === Rules.HIVE ? schema.required('Confirmation score must be in a range from 0.6 to 1.0') : schema;
      })
      .min(0.6, 'Confirmation score must be in a range from 0.6 to 1.0')
      .max(1.0, 'Confirmation score must be in a range from 0.6 to 1.0'),
    decision: yup.string()
      .when('decision', (_, schema) => {
       return contentType !== Rules.PROFILE ? schema.required('Decision field must be filled') : schema;
      }),
    restrictions: yup.array()
      .nullable()
      .when('decision', {
        is: 'LIMIT',
        then: (schema) => schema.default([{ age: '18' }])
          .required('Restrictions field must be filled'),
        otherwise: (schema) => schema.default(null).notRequired()
      }),
    profileDecision: yup.string()
      .when('decision', {
        is: 'LIMIT',
        then: (schema) => schema.default('NO_IMPLICATIONS'),
        otherwise: (schema) => schema.default('BLOCK_PUBLICATION')
          .required('Implication field must be filled')
      }),
    term: yup.string().required().nullable()
      .when('decision', {
        is: 'LIMIT',
        then: (schema) => schema.default(null),
        otherwise: (schema) => schema.default('FOR_PERIOD')
      }),
    decisionPeriodUnit: yup.string().required().nullable()
      .when('decision', {
        is: 'LIMIT',
        then: (schema) => schema.default(null),
        otherwise: (schema) => schema.default('DAYS')
      }),
    decisionPeriodCount: yup.number()
      .typeError('Period must be in a range from 1 to 30')
      .nullable()
      .when('decision', {
        is: 'LIMIT',
        then: (schema) => schema.default(null),
      })
      .when('term', {
        is: 'PERMANENTLY',
        then: (schema) => schema.default(null),
        otherwise: schema => schema.integer('Period must be in a range from 1 to 30')
          .min(1, 'Period must be in a range from 1 to 30')
          .max(30, 'Period must be in a range from 1 to 30')
          .required('Period must be in a range from 1 to 30')
          .default(14)
      }),
    countForPeriod: yup.number()
      .typeError('Number of violations must be in a range from 1 to 20')
      .nullable()
      .when('decision', {
        is: 'LIMIT',
        then: (schema) => schema.default(null),
        otherwise: (schema) => schema.default(2).integer('Number of violations must be in a range from 1 to 20')
          .min(2, 'Number of violations must be in a range from 1 to 20')
          .max(20, 'Number of violations must be in a range from 1 to 20'),
      }),
    periodCount: yup.number()
      .typeError('Period must be in a range from 1 to 365 days or 12 month')
      .nullable()
      .integer('Period must be more than 0')
      .min(1, 'Period must be more than 0')
      .when('periodUnit', {
        is: 'DAYS',
        then: (schema) => schema.max(365, 'Period must be less than 365 days'),
        otherwise: (schema) => schema.max(12, 'Period must be less than 12 month'),
      })
      .when('decision', {
        is: 'LIMIT',
        then: (schema) => schema.default(null),
      }),
    periodUnit: yup.string().nullable()
      .when('decision', {
        is: 'LIMIT',
        then: (schema) => schema.default(null),
        otherwise: (schema) => schema.default('DAYS')
      }),
  },
    [
      ['reviewMinScore', 'reviewMinScore'],
      ['decisionMinScore', 'decisionMinScore'],
      ['decision', 'decision'],
    ],
    );
}

export default getValidationSchema;