import React from 'react';
import { createTheme, Shadows, Theme } from '@mui/material/styles';
import colors from 'theme/constants/colors';

export const theme: Theme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '4px',
          color: colors.actionDisabled,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: colors.actionSelected,
        },
      },
    },
  },
  shadows: [
    ...createTheme({}).shadows.map((shadow, i) =>
      i === 24
        ? '0px 9px 46px 8px rgba(16, 24, 40, 0.12), 0px 24px 38px 3px rgba(16, 24, 40, 0.14), 0px 11px 15px -7px rgba(16, 24, 40, 0.20)'
        : shadow,
    ),
  ] as Shadows,
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    success: {
      main: colors.success,
    },
    warning: {
      main: colors.warning,
    },
    info: {
      main: colors.info,
    },
    error: {
      main: colors.error,
    },
    action: {
      disabled: colors.actionDisabled,
      disabledBackground: colors.actionDisabledBackground,
    },
    divider: colors.divider,
    common: {
      black: colors.text,
    },
    text: {
      primary: colors.text,
      secondary: colors.textSecondary,
    },
  },
  typography: {
    h1: {
      fontSize: '96px',
      fontStyle: 'normal',
      fontWeight: '300',
      lineHeight: '116.7%' /* 112.032px */,
      letterSpacing: '-1.5px',
    },
    h2: {
      fontSize: '60px',
      fontStyle: 'normal',
      fontWeight: '300',
      lineHeight: '120%' /* 72px */,
      letterSpacing: '-0.5px',
    },
    h3: {
      fontSize: '48px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '116.7%' /* 56.016px */,
    },
    h4: {
      fontSize: '34px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '123.5%' /* 41.99px */,
      letterSpacing: '0.25px',
    },
    h5: {
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '133.4%' /* 32.016px */,
      letterSpacing: '0.15px',
    },
    h6: {
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '160%' /* 32px */,
      letterSpacing: '0.15px',
    },
    errorTitle: {
      fontFamily: 'Roboto Mono, monospace',
      fontSize: '48px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
      letterSpacing: '-1.5px',
    },
    errorSubtitle: {
      fontFamily: 'Roboto Mono, monospace',
      fontSize: '32px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
      letterSpacing: '-1.5px',
    },
    subtitle1: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '175%' /* 28px */,
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '157%' /* 21.98px */,
      letterSpacing: '0.1px',
    },
    body1: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '150%' /* 24px */,
      letterSpacing: '0.15px',
    },
    body2Bold: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '143%' /* 20.02px */,
      letterSpacing: '0.5px',
    },
    body2: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '143%' /* 20.02px */,
      letterSpacing: '0.17px',
    },
    bodyLarge: {
      fontFamily: 'Noto Sans Display, monospace',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '100%' /* 20px */,
      letterSpacing: '0.3px',
    },
    caption: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '166%' /* 19.92px */,
      letterSpacing: '0.4px',
    },
    overline: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '266%' /* 31.92px */,
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
    avatarInitials: {
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '100%' /* 20px */,
      letterSpacing: '0.14px',
    },
    alertTitle: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '150%' /* 24px */,
      letterSpacing: '0.15px',
    },
    menuItem: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '150%' /* 24px */,
      letterSpacing: '0.15px',
    },
    inputText: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '150%' /* 24px */,
      letterSpacing: '0.15px',
    },
    buttonLarge: {
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '173.333%' /* 26px */,
      letterSpacing: '0.46px',
      textTransform: 'uppercase',
    },
    buttonMedium: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '171.429%' /* 24px */,
      letterSpacing: '0.4px',
      textTransform: 'uppercase',
    },
    listSubheader: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '342.857%' /* 48px */,
      letterSpacing: '0.1px',
    },
    tableHeader: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '171.429%' /* 24px */,
      letterSpacing: '0.17px',
    },
    menuItemDense: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '171.429%' /* 24px */,
      letterSpacing: '0.17px',
    },
    bottomNavigationActiveLabel: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '23.24px',
      letterSpacing: '0.4px',
    },
    buttonSmall: {
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '169.231%' /* 22px */,
      letterSpacing: '0.46px',
      textTransform: 'uppercase',
    },
    chip: {
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '138.462%' /* 18px */,
      letterSpacing: '0.16px',
    },
    badgeLabel: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '166.667%' /* 20px */,
      letterSpacing: '0.14px',
    },
    helperText: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '166.667%' /* 20px */,
      letterSpacing: '0.4px',
    },
    inputLabel: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '100%' /* 12px */,
      letterSpacing: '0.15px',
    },
    tooltip: {
      fontSize: '10px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '140%' /* 14px */,
    },
  },
});

declare module '@mui/material/styles' {
  interface TypographyVariants {
    tableHeader: React.CSSProperties;
    body2Bold: React.CSSProperties;
    bodyLarge?: React.CSSProperties;
    avatarInitials: React.CSSProperties;
    alertTitle: React.CSSProperties;
    menuItem: React.CSSProperties;
    inputText: React.CSSProperties;
    buttonLarge: React.CSSProperties;
    buttonMedium: React.CSSProperties;
    listSubheader: React.CSSProperties;
    menuItemDense: React.CSSProperties;
    bottomNavigationActiveLabel: React.CSSProperties;
    buttonSmall: React.CSSProperties;
    chip: React.CSSProperties;
    badgeLabel: React.CSSProperties;
    helperText: React.CSSProperties;
    inputLabel: React.CSSProperties;
    tooltip: React.CSSProperties;
    errorTitle: React.CSSProperties;
    errorSubtitle: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    tableHeader?: React.CSSProperties;
    body2Bold?: React.CSSProperties;
    bodyLarge?: React.CSSProperties;
    avatarInitials?: React.CSSProperties;
    alertTitle?: React.CSSProperties;
    menuItem?: React.CSSProperties;
    inputText?: React.CSSProperties;
    buttonLarge?: React.CSSProperties;
    buttonMedium?: React.CSSProperties;
    listSubheader?: React.CSSProperties;
    menuItemDense?: React.CSSProperties;
    bottomNavigationActiveLabel?: React.CSSProperties;
    buttonSmall?: React.CSSProperties;
    chip?: React.CSSProperties;
    badgeLabel?: React.CSSProperties;
    helperText?: React.CSSProperties;
    inputLabel?: React.CSSProperties;
    tooltip?: React.CSSProperties;
    errorTitle?: React.CSSProperties;
    errorSubtitle?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    tableHeader: true;
    body2Bold: true;
    bodyLarge: true;
    avatarInitials: true;
    alertTitle: true;
    menuItem: true;
    inputText: true;
    buttonLarge: true;
    buttonMedium: true;
    listSubheader: true;
    menuItemDense: true;
    bottomNavigationActiveLabel: true;
    buttonSmall: true;
    chip: true;
    badgeLabel: true;
    helperText: true;
    inputLabel: true;
    tooltip: true;
    errorTitle: true;
    errorSubtitle: true;
  }
}
