import { createSvgIcon } from '@mui/material';

const ArrowDropDownIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.20712 10.5C7.76167 10.5 7.53858 11.0386 7.85356 11.3536L11.6465 15.1464C11.8417 15.3417 12.1583 15.3417 12.3536 15.1464L16.1465 11.3536C16.4614 11.0386 16.2384 10.5 15.7929 10.5H8.20712Z" />
  </svg>,
  'ArrowDropDown',
);

export default ArrowDropDownIcon;
