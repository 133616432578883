const VolumeIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.01 4.23003C11.47 3.32003 12.9 3.16003 14.02 3.78003C15.13 4.40003 15.75 5.69003 15.75 7.41003V16.59C15.75 18.31 15.13 19.6 14.02 20.22C13.57 20.47 13.07 20.59 12.55 20.59C11.75 20.59 10.89 20.32 10.01 19.77L7.09 17.94C6.89 17.82 6.66 17.75 6.43 17.75H5C2.58 17.75 1.25 16.42 1.25 14V10C1.25 7.58003 2.58 6.25003 5 6.25003H6.43C6.66 6.25003 6.89 6.18003 7.09 6.06003L10.01 4.23003Z" />
      <path d="M19.83 19.25C19.67 19.25 19.52 19.2 19.38 19.1C19.05 18.85 18.98 18.38 19.23 18.05C21.9 14.49 21.9 9.51003 19.23 5.95003C18.98 5.62003 19.05 5.15003 19.38 4.90003C19.71 4.65003 20.18 4.72003 20.43 5.05003C23.5 9.14003 23.5 14.86 20.43 18.95C20.29 19.15 20.06 19.25 19.83 19.25Z" />
      <path d="M18 16.75C17.84 16.75 17.69 16.7 17.55 16.6C17.22 16.35 17.15 15.88 17.4 15.55C18.97 13.46 18.97 10.54 17.4 8.45003C17.15 8.12003 17.22 7.65003 17.55 7.40003C17.88 7.15003 18.35 7.22003 18.6 7.55003C20.56 10.17 20.56 13.83 18.6 16.45C18.45 16.65 18.23 16.75 18 16.75Z" />
    </svg>
  )
}

export default VolumeIcon;