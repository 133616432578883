import { FC, MouseEvent } from 'react';
import { intervalToDuration } from 'date-fns';
import colors from 'theme/constants/colors';
import { UserProfileMainData, UserProfilePaymentInfo, UserProfilePersonalData } from 'store/userProfileSlice';
import CopyIcon from 'assets/img/CopyIcon';
import Avatar from 'components/shared/Avatar/Avatar';
import Button from 'components/shared/Button/Button';
import DialogActions from 'components/shared/Dialog/DialogActions/DialogActions';
import Divider from 'components/shared/Divider/Divider';
import IconButton from 'components/shared/IconButton/IconButton';
import ListItem from 'components/shared/List/ListItem/ListItem';
import ListItemText from 'components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText';
import Popover from 'components/shared/Popover/Popover';
import Stack from 'components/shared/Stack/Stack';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import Typography from 'components/shared/Typography/Typography';

interface ProfilePopoverProps {
  anchorEl: HTMLButtonElement | null;
  profileInfo: {
    main: UserProfileMainData;
    personal: UserProfilePersonalData;
    paymentInfo: UserProfilePaymentInfo;
  };
  onClose: () => void;
  coordinates: { x: number; y: number };
  copyId: (e: MouseEvent<HTMLButtonElement>, value: string) => void;
  profileId: string;
}

export const ProfilePopover: FC<ProfilePopoverProps> = ({
  anchorEl,
  profileInfo,
  onClose,
  coordinates,
  profileId,
  copyId,
}) => {
  const mainInfo = profileInfo?.main;
  const personalInfo = profileInfo?.personal;

  if (!mainInfo || mainInfo.userId !== profileId) {
    return null;
  }

  const birthDate = personalInfo?.birthDate ? new Date(personalInfo.birthDate) : null;
  const age = birthDate ? intervalToDuration({ start: birthDate, end: new Date() }).years : null;

  return (
    <Popover
      open={!!anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: coordinates.y + 20,
        left: coordinates.x - 35,
      }}
    >
      <Stack gap={2} direction="row" sx={{ p: '24px 24px 0' }}>
        <div className="avatar-wrapper-payments" data-level={mainInfo.levelAura}>
          <Avatar
            alt={`${mainInfo.firstName} ${mainInfo.lastName}`}
            src={mainInfo.avatarUrl}
            sx={
              mainInfo.levelAura
                ? {
                    border: `2px solid ${colors.white}`,
                  }
                : null
            }
          />
        </div>

        <Stack gap={1} flex="auto">
          <Typography variant="body2">{`${mainInfo.firstName} ${mainInfo.lastName}`}</Typography>
          <Typography variant="body2">{`@${mainInfo.username}`}</Typography>
          <Stack gap={1} direction="row">
            {age && <Typography variant="body2">{`${age} y.o.`}</Typography>}
            <Typography variant="body2">{personalInfo?.location}</Typography>
          </Stack>
          <Divider flexItem orientation="horizontal" />
          <ListItem sx={{ p: 0 }}>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body2" color="text.secondary">
                  Profile ID
                </Typography>
              }
              secondary={
                <Stack direction="row" gap={1}>
                  <Typography variant="subtitle1" color="text.primary">
                    {mainInfo.userId || '—'}
                  </Typography>
                  {mainInfo.userId && (
                    <Tooltip placement="top" title="Copy">
                      <IconButton color="default" size="small" onClick={(e) => copyId(e, mainInfo.userId)}>
                        <CopyIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              }
            ></ListItemText>
          </ListItem>
        </Stack>
      </Stack>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Popover>
  );
};
