import * as React from 'react';
import { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { DesktopDateTimePickerProps } from '@mui/x-date-pickers/DesktopDateTimePicker/DesktopDateTimePicker.types';
import './index.scss';

const DateTimePicker = (props: DesktopDateTimePickerProps<Dayjs, false>) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDateTimePicker className="desktopDateTimePicker" {...props} />
    </LocalizationProvider>
  );
};

export default DateTimePicker;
