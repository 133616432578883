import { DialogContent as DialogContentMUI } from '@mui/material';
import { DialogContentProps } from '@mui/material/DialogContent/DialogContent';
import './index.scss';

const DialogContent = (props: DialogContentProps) => {
  return (
    <DialogContentMUI {...props} />
  );
};

export default DialogContent;
