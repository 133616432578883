import { createSvgIcon } from '@mui/material';

const TextIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.5413 4.72283C5.03158 4.72283 4.54976 5.16478 4.54976 5.80402V6.50198C4.54976 6.97142 4.1692 7.35198 3.69976 7.35198C3.23032 7.35198 2.84976 6.97142 2.84976 6.50198V5.80402C2.84976 4.29749 4.02266 3.02283 5.5413 3.02283H18.4587C19.9915 3.02283 21.1503 4.31229 21.1503 5.80402V6.50198C21.1503 6.97142 20.7697 7.35198 20.3003 7.35198C19.8308 7.35198 19.4503 6.97142 19.4503 6.50198V5.80402C19.4503 5.16864 18.9721 4.72283 18.4587 4.72283H12.85V19.2934H14.5052C14.9746 19.2934 15.3552 19.674 15.3552 20.1434C15.3552 20.6128 14.9746 20.9934 14.5052 20.9934H9.49487C9.02542 20.9934 8.64487 20.6128 8.64487 20.1434C8.64487 19.674 9.02542 19.2934 9.49487 19.2934H11.15V4.72283H5.5413Z" />
  </svg>,
  'Text',
);

export default TextIcon;
