import { GridRenderCellParams } from '@mui/x-data-grid';
import { possibleOperationStatuses } from '../constants';
import LockIcon from 'assets/img/LockIcon';
import Stack from 'components/shared/Stack/Stack';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import Typography from 'components/shared/Typography/Typography';

/**
 * Props for the RenderStatusCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 */
interface IRenderStatusCellProps {
  props: GridRenderCellParams;
}

/**
 * Renders a status cell in a data grid.
 */
export const RenderStatusCell = ({ props }: IRenderStatusCellProps) => {
  const { row } = props;

  if (!row.id) {
    return <></>;
  }

  const isOperationSuspended = row.isTransferSuspended;
  const isSuspendedTransaction = row.suspended && !row.userFriendlyOperationId;
  const currentStatus = possibleOperationStatuses.find((el) => row.status?.toLowerCase() === el.status?.toLowerCase());
  const displayedStatus = currentStatus?.title ?? 'Unknown';

  const getTransactionTitle = () => {
    return isSuspendedTransaction ? 'Transaction suspended' : 'Operation suspended';
  };

  return (
    <Stack direction="row" alignItems="center" flexWrap="nowrap" maxWidth={1}>
      <Tooltip
        style={{ display: 'inline-grid' }}
        disableInteractive
        placement="top"
        title={row.status.replace(row.status, displayedStatus)}
      >
        <Typography
          variant="chip"
          color={{ color: currentStatus?.color }}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {row.status.replace(row.status, displayedStatus)}
        </Typography>
      </Tooltip>
      {(isOperationSuspended || row.suspended) && (
        <Tooltip disableInteractive placement="top" title={getTransactionTitle()}>
          <LockIcon color="warning" sx={{ fontSize: 16, verticalAlign: 'sub', ml: 0.5 }} />
        </Tooltip>
      )}
    </Stack>
  );
};
