import { TextField as TextFieldMUI } from '@mui/material';
import { ForwardedRef, forwardRef } from 'react';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import './index.scss';

const TextField = forwardRef((props: TextFieldProps, ref: ForwardedRef<HTMLDivElement | null>) => {
  return <TextFieldMUI ref={ref} {...props} />;
});

export default TextField;
