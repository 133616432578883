const StopIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <g id="Stop">
        <g id="Vector">
          <path d="M7 12C7 11.2929 7 10.9393 7.73223 10.7197C8.46447 10.5 9.64298 10.5 12 10.5C14.357 10.5 15.5355 10.5 16.2678 10.7197C17 10.9393 17 11.2929 17 12C17 12.7071 17 13.0607 16.2678 13.2803C15.5355 13.5 14.357 13.5 12 13.5C9.64298 13.5 8.46447 13.5 7.73223 13.2803C7 13.0607 7 12.7071 7 12Z" />
          <path fillRule="evenodd" clipRule="evenodd" d="M12 1.15002C6.03058 1.15002 1.15002 6.03058 1.15002 12C1.15002 17.9695 6.03058 22.85 12 22.85C17.9695 22.85 22.85 17.9695 22.85 12C22.85 6.03058 17.9695 1.15002 12 1.15002ZM2.85002 12C2.85002 6.96947 6.96947 2.85002 12 2.85002C17.0306 2.85002 21.15 6.96947 21.15 12C21.15 17.0306 17.0306 21.15 12 21.15C6.96947 21.15 2.85002 17.0306 2.85002 12Z" />
        </g>
      </g>
    </svg>
  )
}

export default StopIcon;