import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { IMemberInfo } from 'types/Member';
import { RootState } from './store';
import { IStatus } from 'types/commonTypes';

export interface UsersState {
  columns: any[];
  filters: any[];
  data: any[];
  oldMembers: any;
  value: number;
  totalCount: number | undefined;
  status: IStatus;
  refreshStatus: IStatus;
  passwordResetStatus: IStatus;
  newMemberStatus: IStatus;
  changeRoleStatus: IStatus;
  changeStatusStatus: IStatus;
  resendActivationLinkStatus: IStatus;
  roles: any[];
  statuses: any[];
}

const initialState: UsersState = {
  columns: [],
  filters: [],
  data: [],
  oldMembers: {},
  value: 0,
  totalCount: 0,
  status: 'loading',
  refreshStatus: 'idle',
  passwordResetStatus: 'idle',
  newMemberStatus: 'idle',
  changeRoleStatus: 'idle',
  changeStatusStatus: 'idle',
  resendActivationLinkStatus: 'idle',
  roles: [],
  statuses: [],
};

export const fetchMembers = createAsyncThunk('members/fetchMembers', async (searchValue: string, thunkAPI) => {
  try {
    const rootState: RootState = (await thunkAPI.getState()) as RootState;
    // console.log('getting columns for members', searchValue);
    const filters = Object.keys(rootState.filters.data)
      ? Object.values(rootState.filters.data).filter((item: any) => item !== null)
      : [];
    const sortingList = Object.keys(rootState.sorting.data)
      ? Object.values(rootState.sorting.data).filter(
          (item: any) => item !== null && item.columnId !== null && item.sortingType !== null,
        )
      : [];
    const response = await axios.post(process.env.REACT_APP_MEMBERS_ENDPOINT + 'members/all', {
      pageInfo: {
        size: 1000,
        page: 1,
      },
      sortingList: sortingList,
      filters: filters,
      name: searchValue,
    });
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      return thunkAPI.rejectWithValue({
        message: error.message || 'An unexpected error occurred',
        status: error.response?.status,
      });
    } else {
      return thunkAPI.rejectWithValue({
        message: 'An unexpected error occurred',
      });
    }
  }
});

export const fetchRoles = createAsyncThunk('members/fetchRoles', async () => {
  const response = await axios.get(process.env.REACT_APP_MEMBERS_ENDPOINT + 'roles');
  return response.data;
});

export const fetchSaveMember = createAsyncThunk('members/saveMember', async (data: any, thunkAPI) => {
  try {
    const response = await axios.post(process.env.REACT_APP_MEMBERS_ENDPOINT + 'members', data);
    return response.data;
  } catch (e: any) {
    const err = e as AxiosError;
    const isMemberExist = err.response ? (err.response?.data as any).title.indexOf('Member with email') !== -1 : false;
    return thunkAPI.rejectWithValue({ isMemberExist: isMemberExist });
  }
});

export const fetchEditMember = createAsyncThunk('members/editMember', async (data: any, thunkAPI) => {
  try {
    // console.log(data);
    const response = await axios.patch(process.env.REACT_APP_MEMBERS_ENDPOINT + 'members', data);
    return response.data;
  } catch (e: any) {
    const err = e as AxiosError;
    return thunkAPI.rejectWithValue(err.response?.status);
  }
});

export const fetchResetPassword = createAsyncThunk(
  'members/resetPassword',
  async (member: IMemberInfo | null, thunkAPI) => {
    try {
      const response = await axios.post(process.env.REACT_APP_MEMBERS_ENDPOINT + 'members/send-password-reset-otp', {
        email: member?.email,
      });
      return response.data;
    } catch (e: any) {
      const err = e as AxiosError;
      return thunkAPI.rejectWithValue(err.response?.status);
    }
  },
);

export const fetchResendActivationLink = createAsyncThunk(
  'members/resendActivationLink',
  async (member: IMemberInfo | null) => {
    const response = await axios.post(process.env.REACT_APP_MEMBERS_ENDPOINT + 'members/send-password-reset-otp', {
      email: member?.email,
    });
    return response.data;
  },
);

export const fetchStatuses = createAsyncThunk('members/fetchStatuses', async () => {
  const response = await axios.get(process.env.REACT_APP_MEMBERS_ENDPOINT + 'statuses');
  return response.data;
});

export const fetchChangeRole = createAsyncThunk(
  'members/fetchChangeRole',
  async ({ selectionModel, currentSelectedRole }: { selectionModel: any; currentSelectedRole: any }) => {
    // console.log(selectionModel, currentSelectedRole);
    const response = await axios.patch(process.env.REACT_APP_MEMBERS_ENDPOINT + 'members/role', {
      roleId: currentSelectedRole.id,
      members: selectionModel,
    });
    return response.data;
  },
);

export const fetchChangeStatus = createAsyncThunk(
  'members/fetchChangeStatus',
  async ({ members, currentSelectedStatus }: { members: any; currentSelectedStatus: any }) => {
    // console.log(members, currentSelectedStatus);
    const response = await axios.patch(process.env.REACT_APP_MEMBERS_ENDPOINT + 'members/status', {
      status: currentSelectedStatus.status,
      members: members,
    });
    return response.data;
  },
);

export const membersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    conserveMembers: (state) => {
      const result: any = {};
      state.data.forEach((el) => (result[el.id] = el));
      state.oldMembers = result;
    },
    cleanOldMembers: (state) => {
      state.oldMembers = {};
    },
    clearContentMembersData: (state) => {
      state.status = 'loading';
      state.data = [];
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload;
    },
    setUsersStatus: (state, action: PayloadAction<IStatus>) => {
      state.status = action.payload;
    },
    setNewMemberStatus: (state, action: PayloadAction<IStatus>) => {
      state.newMemberStatus = action.payload;
    },
    setChangeStatusStatus: (state, action: PayloadAction<IStatus>) => {
      state.changeStatusStatus = action.payload;
    },
    setRefreshStatus: (state, action) => {
      state.refreshStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMembers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMembers.fulfilled, (state, action) => {
        // console.log(state.data);
        state.status = 'idle';
        state.refreshStatus = 'idle';
        state.data = action.payload.members;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchMembers.rejected, (state) => {
        state.status = 'failed';
        state.refreshStatus = 'idle';
        state.data = [];
        state.totalCount = 0;
      })
      .addCase(fetchChangeStatus.pending, (state) => {
        state.changeStatusStatus = 'loading';
      })
      .addCase(fetchChangeStatus.fulfilled, (state) => {
        state.changeStatusStatus = 'idle';
      })
      .addCase(fetchChangeStatus.rejected, (state) => {
        state.changeStatusStatus = 'failed';
      })
      .addCase(fetchRoles.pending, () => {})
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.roles = action.payload.roles;
      })
      .addCase(fetchRoles.rejected, () => {})
      .addCase(fetchResendActivationLink.pending, (state) => {
        state.resendActivationLinkStatus = 'loading';
      })
      .addCase(fetchResendActivationLink.fulfilled, (state) => {
        state.resendActivationLinkStatus = 'idle';
      })
      .addCase(fetchResendActivationLink.rejected, (state) => {
        state.resendActivationLinkStatus = 'idle';
      })
      .addCase(fetchResetPassword.pending, (state) => {
        state.passwordResetStatus = 'loading';
      })
      .addCase(fetchResetPassword.fulfilled, (state) => {
        state.passwordResetStatus = 'idle';
      })
      .addCase(fetchResetPassword.rejected, (state) => {
        state.passwordResetStatus = 'failed';
      })
      .addCase(fetchChangeRole.pending, (state) => {
        state.changeRoleStatus = 'loading';
      })
      .addCase(fetchChangeRole.fulfilled, (state) => {
        state.changeRoleStatus = 'idle';
      })
      .addCase(fetchChangeRole.rejected, (state) => {
        state.changeRoleStatus = 'failed';
      })
      .addCase(fetchSaveMember.pending, (state) => {
        state.newMemberStatus = 'loading';
      })
      .addCase(fetchSaveMember.fulfilled, (state) => {
        state.newMemberStatus = 'idle';
      })
      .addCase(fetchSaveMember.rejected, (state, action) => {
        if ((action.payload as any).isMemberExist) {
          state.newMemberStatus = 'idle';
        } else {
          state.newMemberStatus = 'failed';
        }
      })
      .addCase(fetchStatuses.pending, () => {})
      .addCase(fetchStatuses.fulfilled, (state, action) => {
        state.statuses = action.payload;
      })
      .addCase(fetchStatuses.rejected, () => {});
  },
});

export const {
  incrementByAmount,
  setUsersStatus,
  setNewMemberStatus,
  conserveMembers,
  cleanOldMembers,
  clearContentMembersData,
  setChangeStatusStatus,
  setRefreshStatus,
} = membersSlice.actions;

export const selectMembersCount = (state: RootState): number => state.members.data.length;
export const selectMembers = (state: RootState): any[] => state.members.data;
export const selectOldMembers = (state: RootState): any => state.members.oldMembers;
export const selectRoles = (state: RootState): any[] => state.members.roles;
export const selectMembersStatus = (state: RootState): IStatus => state.members.status;
export const selectMembersRefreshStatus = (state: RootState): IStatus => state.members.refreshStatus;
export const selectMembersStatuses = (state: RootState): any[] => state.members.statuses;
export const selectNewMemberCreationStatus = (state: RootState): IStatus => state.members.newMemberStatus;
export const selectPasswordResetStatus = (state: RootState): IStatus => state.members.passwordResetStatus;
export const selectChangeRoleStatus = (state: RootState): IStatus => state.members.changeRoleStatus;
export const selectChangeStatusStatus = (state: RootState): IStatus => state.members.changeStatusStatus;
export const selectResendActivationLinkStatus = (state: RootState): IStatus => state.members.resendActivationLinkStatus;


export default membersSlice.reducer;
