import { useCallback, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from 'components/Navbar/Navbar';
import withAuth from 'components/Authorization/withAuth';
import './index.scss';

const Layout = () => {
  const [isMenuOpen, setOpen] = useState<boolean>(false);
  const handleOpen = useCallback((value: boolean) => setOpen(value), []);

  return (
    <div className={`layout-container${isMenuOpen ? ' layout-container--blurred' : ''}`}>
      <Navbar isMenuOpen={isMenuOpen} handleOpen={handleOpen} />
      <div style={{ width: '64px' }} />
      <Outlet />
    </div>
  );
};
export default withAuth(Layout);
