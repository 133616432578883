import { EntityType } from 'types/commonTypes';
import { stringCapitalize } from 'utils/stringCapitalize';
import CloseIcon from 'assets/img/CloseIcon';
import IconButton from 'components/shared/IconButton/IconButton';
import Typography from 'components/shared/Typography/Typography';
import './index.scss';

interface MediaHeaderProps {
  entityType: string;
  contentType: string;
  entityId: string;
  entityOwnerId?: string;
  mediaId?: string;
  onClose: () => void;
  showDetailedHeader: boolean;
}

const MediaHeader: React.FC<MediaHeaderProps> = ({
  entityType,
  contentType,
  entityId,
  entityOwnerId,
  mediaId,
  onClose,
  showDetailedHeader,
}) => {
  const getMediaHeader = () => {
    if (contentType?.toUpperCase() === EntityType.AVATAR && mediaId !== entityId) return 'User’s profile pic';
    if (mediaId === entityId) return 'Image of user’s profile pic';
    if (showDetailedHeader) return `${stringCapitalize(entityType)} ${contentType.toLowerCase()}`;

    return `${stringCapitalize(entityType)} media`;
  };

  return (
    <div className="media-header">
      <div className="media-header-title">
        <Typography variant="h5">{getMediaHeader()}</Typography>
        <Typography variant="body1" color="text.secondary">
          {entityType?.toUpperCase() === EntityType.AVATAR && entityOwnerId ? `#${entityOwnerId}` : `#${entityId}`}
        </Typography>
      </div>
      <IconButton size="small" onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export default MediaHeader;
