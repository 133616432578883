import { MouseEvent } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { enqueueSnackbar, VariantType } from 'notistack';
import { useHover } from '@uidotdev/usehooks';
import CopyIcon from 'assets/img/CopyIcon';
import IconButton from 'components/shared/IconButton/IconButton';
import Stack from 'components/shared/Stack/Stack';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import Typography from 'components/shared/Typography/Typography';

/**
 * Props for the RenderCellWithCopy component.
 *
 * @interface
 * @property {GridRenderCellParams} props - The properties of the RenderCellWithCopy component.
 * @property {[boolean]} isWrappedText - Flag for controlling wrapped text.
 */
interface IRenderCellWithCopyProps {
  props: GridRenderCellParams;
  isWrappedText?: boolean;
}

/**
 * Renders a cell with copyToClipboard functionality.
 */
const RenderCellWithCopy = ({ props, isWrappedText }: IRenderCellWithCopyProps) => {
  const { value } = props;
  const [ref, hovering] = useHover<HTMLDivElement>();

  const copyValue = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (navigator.clipboard) {
      enqueueSnackbar('Copied to clipboard', {
        variant: 'success' as VariantType,
      });

      return navigator.clipboard.writeText(value);
    }
  };

  return (
    <div className="MuiDataGrid-cellContent" ref={ref}>
      <Stack direction="row" alignItems="flex-start" gap={1}>
        <Tooltip placement="top" title={value && value}>
          <div style={{ display: 'inline-grid' }}>
            <Typography
              component="span"
              variant="body2"
              sx={{
                display: isWrappedText ? '-webkit-box' : '',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: isWrappedText ? 'normal' : 'nowrap',
                wordBreak: 'break-all',
                lineClamp: '2',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
                minHeight: '28px',
              }}
            >
              {value || '—'}
            </Typography>
          </div>
        </Tooltip>
        {hovering && value && (
          <Tooltip placement="top" title="Copy">
            <IconButton color="default" size="small" onClick={(e) => copyValue(e)}>
              <CopyIcon sx={{ fontSize: 18 }} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </div>
  );
};

export default RenderCellWithCopy;
