import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { enqueueSnackbar, VariantType, closeSnackbar } from 'notistack';
import { AuthMessages, CommonErrorMessages, OtherMessages, ProfileMessages } from 'store/types/ErrorMessages';
import getValidationSchema from 'components/Authorization/utils/getValidationShema';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  checkoutErrorMessage,
  fetchSignIn,
  fetchVerifyOtp,
  selectAuthCooldownTimer,
  selectAuthStatus,
  selectAuthStatusText,
  selectAuthTriesResendLeft,
  selectIsAuth,
  selectSnackbarKeys,
  updateSnackbarKeys,
} from 'store/authSlice';
import { fetchProfile } from 'store/profileSlice';
import CloseIcon from 'assets/img/CloseIcon';
import CopyIcon from 'assets/img/CopyIcon';
import ErrorIcon from 'assets/img/ErrorIcon';
import EyeClosedIcon from 'assets/img/EyeClosedIcon';
import EyeIcon from 'assets/img/EyeIcon';
import InfoIcon from 'assets/img/InfoIcon';
import Logo from 'assets/img/Logo';
import WarningIcon from 'assets/img/WarningIcon';
import AuthLayout from 'components/AuthLayout/AuthLayout';
import FormHelperText from 'components/shared/FormHelperText/FormHelperText';
import IconButton from 'components/shared/IconButton/IconButton';
import InputAdornment from 'components/shared/InputAdornment/InputAdornment';
import LinkMUI from 'components/shared/Link/Link';
import LoadingButton from 'components/shared/LoadingButton/LoadingButton';
import OTPInput from 'components/shared/OTPInput';
import Popover from 'components/shared/Popover/Popover';
import TextField from 'components/shared/TextField/TextField';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import { useErrorBoundary } from 'react-error-boundary';
import './index.scss';

dayjs.extend(utc);

type InfoType = 'message' | 'warning' | 'error';

interface Info {
  isShow: boolean;
  infoType: undefined | InfoType;
  message: string;
}

const adminEmail = process.env.REACT_APP_ADMIN_EMAIL as string;

function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [showInfo, setShowInfo] = useState<Info>({ isShow: false, infoType: undefined, message: '' });
  const ref = useRef<any>();
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });

  const statusText = useAppSelector(selectAuthStatusText);
  const status = useAppSelector(selectAuthStatus);
  const triesResendLeft = useAppSelector(selectAuthTriesResendLeft);
  const cooldownTimer = useAppSelector(selectAuthCooldownTimer);
  const snackbarKeys = useAppSelector(selectSnackbarKeys);
  const isAuth = useAppSelector(selectIsAuth);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp: new Date(dayjs(cooldownTimer).local().format()),
    autoStart: false,
  });

  const schema = useMemo(() => getValidationSchema(showOtp ? 'otp' : 'login'), [showOtp]);
  const {
    control,
    register,
    getValues,
    handleSubmit,
    trigger,
    reset,
    resetField,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
      otp: '',
    },
    resolver: yupResolver(schema),
  });

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const copyEmail = async () => {
    if (navigator.clipboard) {
      enqueueSnackbar('Email address has been copied to clipboard', { variant: 'success' as VariantType });
      return navigator.clipboard.writeText(adminEmail);
    }
  };
  const [bannedUsers, setBannedUsers] = useState<string[]>([]);
  const [blockedUsers, setBlockedUsers] = useState<string[]>([]);
  const [isDisableSignInButton, setIsDisableSignInButton] = useState(false);
  const isSignInButtonDisabled = (!isValid && !errors.otp) || isDisableSignInButton;
  const email = useWatch({ control, name: 'email' });

  const { showBoundary } = useErrorBoundary();

  const onSubmit = async (data: any) => {
    if (!showOtp) {
      closeSnackbar();
      dispatch(updateSnackbarKeys([]));
    }

    void trigger();

    try {
      if (showOtp) {
        const result = await dispatch(fetchVerifyOtp({ otp: data.otp, email: data.email }));
        if (result.meta.requestStatus === 'fulfilled') {
          navigate('/ui/users'); // todo поменять на первый доступный раздел после добавления ролей
          await dispatch(fetchProfile());
        }
      } else {
        const result = await dispatch(fetchSignIn({ password: data.password, email: data.email }));
        if (result.meta.requestStatus === 'fulfilled') {
          setShowOtp(true);
          dispatch(checkoutErrorMessage({ showInfoLoginMessage: false }));
          setShowInfo({ isShow: false, infoType: undefined, message: '' });
        } else if (result.meta.requestStatus === 'rejected') {
          const { title } = result.payload;
          const userEmail = result.meta.arg.email;
          if (title === AuthMessages.NO_TRIES_LOGIN || title === ProfileMessages.NO_TRIES) {
            setBannedUsers((prevBannedUsers) => [...prevBannedUsers, userEmail]);
          } else if (title === AuthMessages.TRY_SIGN_IN_LATER) {
            setBlockedUsers((prevBlockedUsers) => [...prevBlockedUsers, userEmail]);
          }
        }
      }
    } catch (e) {
      enqueueSnackbar('Signing in error, try again later', { variant: 'error' as VariantType });
    }
  };

  useEffect(() => {
    if (cooldownTimer) {
      restart(new Date(dayjs(cooldownTimer).local().format()));
    }
  }, [cooldownTimer]);

  useEffect(() => {
    if (isAuth) {
      navigate('/ui/users'); // todo поменять на первый доступный раздел после добавления ролей
    }
  }, [isAuth]);

  const isEmailBlocked = blockedUsers.includes(email);

  useEffect(() => {
    const isEmailBanned = bannedUsers.includes(email);
    if (statusText === AuthMessages.NO_TRIES_LOGIN && isEmailBanned) {
      setShowInfo({
        isShow: true,
        infoType: 'error',
        message: `Your account has been blocked due to multiple unsuccessful login attempts.\n\nTo recover access, please contact your manager.`,
      });
      setIsDisableSignInButton(true);
    } else if (statusText === ProfileMessages.NO_TRIES && isEmailBanned) {
      setShowInfo({
        isShow: true,
        infoType: 'error',
        message: `Your account has been blocked due to multiple unsuccessful password changes.\n\nTo recover access, please contact your manager.`,
      });
      setIsDisableSignInButton(true);
    } else if (statusText === AuthMessages.TRY_SIGN_IN_LATER && isEmailBlocked && minutes + seconds > 0) {
      setIsDisableSignInButton(true);
    } else {
      setShowInfo({ isShow: false, infoType: undefined, message: '' });
      setIsDisableSignInButton(false);
    }
    if (statusText === ProfileMessages.NO_TRIES) {
      setShowInfo({
        isShow: true,
        infoType: 'error',
        message: `Your account has been blocked due to multiple unsuccessful password changes.\n\nTo recover access, please contact your manager `,
      });
    }
  }, [bannedUsers, isEmailBlocked, email, statusText]);

  useEffect(() => {
    if (!ref.current) {
      ref.current = true;
      return;
    }

    switch (statusText) {
      case AuthMessages.INCORRECT_CREDENTIALS:
        setShowInfo({ isShow: true, infoType: 'error', message: 'Incorrect email or password' });
        break;
      case AuthMessages.TRY_SIGN_IN_LATER:
        setShowInfo({
          isShow: true,
          infoType: 'error',
          message: `You have unsuccessfully tried to enter 5 times.\nTry again 10 minutes later.`,
        });
        break;
      case AuthMessages.NO_TRIES_LOGIN:
        setShowInfo({
          isShow: true,
          infoType: 'error',
          message: `Your account has been blocked due to multiple unsuccessful login attempts.\n\nTo recover access, please contact your manager.`,
        });
        break;
      case AuthMessages.NO_TRIES_GET_OTP:
        setShowInfo({
          isShow: true,
          infoType: 'error',
          message: `You have made too many attempts to resend the one time password.\n\nTo recover access, please contact your manager.`,
        });
        break;
      case AuthMessages.DEACTIVATED_OTP_CODE:
      case AuthMessages.EXPIRED_OTP_CODE:
        setShowInfo({ isShow: true, infoType: 'error', message: `Code was deactivated.\nRequest another one.` });
        break;
      case OtherMessages.SHOULD_ENTER_CREDENTIALS_AGAIN:
        setShowInfo({
          isShow: true,
          infoType: 'message',
          message: 'You have to enter your credentials again to request new code.',
        });
        break;
      case CommonErrorMessages.UNEXPECTED_ERROR:
        enqueueSnackbar('Signing in error, try again later', { variant: 'error' as VariantType });
        showOtp && setShowOtp(false);
        resetField('otp');
        break;
      case AuthMessages.INCORRECT_OTP:
      default:
        break;
    }
  }, [statusText, ref]);

  useEffect(() => {
    if (showOtp) {
      void trigger('otp');
      if (triesResendLeft && triesResendLeft === 1) {
        setShowInfo({
          isShow: true,
          infoType: 'warning',
          message: `If you don’t enter one time password now, you will be able to request new one 1 more time.\nIf you don’t enter one time password then, your account will be blocked.`,
        });

        return;
      }
      !triesResendLeft &&
        setShowInfo({
          isShow: true,
          infoType: 'warning',
          message: 'If you don’t enter one time password now, your account will be blocked.',
        });
    }
  }, [triesResendLeft, showOtp]);

  const info = showInfo.isShow ? (
    <div
      className={`authForm-info authForm-info--${showInfo.infoType === 'message' ? 'info' : showInfo.infoType} fd-r`}
    >
      <div>
        {showInfo.infoType === 'message' ? (
          <InfoIcon />
        ) : showInfo.infoType === 'warning' ? (
          <WarningIcon />
        ) : (
          <ErrorIcon />
        )}
      </div>
      <div className="authForm-info-text">
        {showInfo.message}
        {statusText === AuthMessages.NO_TRIES_LOGIN && (
          <IconButton className="authForm-button--minWidth" onClick={() => void copyEmail()}>
            <CopyIcon />
          </IconButton>
        )}
      </div>
    </div>
  ) : null;

  return (
    <AuthLayout>
      <div className="authContainer">
        <div className="authForm-container">
          <Logo isStatic={true} />
          <p className="authForm-text">
            {showOtp ? 'Enter the code we have sent to your email' : 'Enter your credentials'}
          </p>
          {info}
          <form className="authForm" onSubmit={handleSubmit(onSubmit)}>
            {showOtp ? (
              <>
                <Controller
                  name="otp"
                  control={control}
                  rules={{ validate: (value) => value.length === 6 }}
                  render={({ field: { onChange, ...field } }) => (
                    <div>
                      {/*<MuiOtpInput*/}
                      {/*  sx={{ gap: 1 }}*/}
                      {/*  onChange={(value) => {*/}
                      {/*    onChange(value);*/}
                      {/*    trigger('otp');*/}
                      {/*  }}*/}
                      {/*  {...field}*/}
                      {/*  length={6}*/}
                      {/*  TextFieldsProps={{ error: statusText === AuthMessages.INCORRECT_OTP || statusText === AuthMessages.DEACTIVATED_OTP_CODE }}*/}
                      {/*/>*/}

                      <OTPInput
                        {...field}
                        onChangeOTP={onChange}
                        autoFocus
                        length={6}
                        inputClassName={
                          (showInfo.isShow && showInfo.infoType) === 'error' ||
                          statusText === AuthMessages.INCORRECT_OTP ||
                          statusText === AuthMessages.DEACTIVATED_OTP_CODE
                            ? 'otpInput otpInput_error'
                            : 'otpInput'
                        }
                      />
                      {(showInfo.isShow && showInfo.infoType) === 'error' ||
                      statusText === AuthMessages.INCORRECT_OTP ||
                      statusText === AuthMessages.DEACTIVATED_OTP_CODE ? (
                        <FormHelperText error>Incorrect code</FormHelperText>
                      ) : null}
                    </div>
                  )}
                />
                <LinkMUI
                  sx={{ display: '-webkit-box', m: '8px 0 0 auto', cursor: 'pointer' }}
                  underline="hover"
                  component="span"
                  color="primary"
                  variant="body2"
                  onClick={(event) => {
                    if (triesResendLeft && triesResendLeft > 0) {
                      setShowOtp(false);
                      dispatch(checkoutErrorMessage({ showInfoLoginMessage: true }));
                      reset();
                      return;
                    }
                    setAnchorEl(event.currentTarget);
                    setCoordinates({ x: event.clientX, y: event.clientY });
                  }}
                >
                  <Tooltip
                    followCursor
                    placement="top-end"
                    title={
                      triesResendLeft && triesResendLeft > 1
                        ? `You can request new code ${triesResendLeft} more times`
                        : (triesResendLeft === 1 && `You can request new code ${triesResendLeft} more time`) ||
                          'You can’t request new code'
                    }
                  >
                    <span> Request new code</span>
                  </Tooltip>
                </LinkMUI>
                <Popover
                  open={!!anchorEl}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  anchorReference="anchorPosition"
                  anchorPosition={{
                    top: coordinates.y,
                    left: coordinates.x,
                  }}
                >
                  <div className="infoContent">
                    <div className="infoContent-textInfo">
                      You have used maximum attempts of code request. To recover password, please contact your manager.
                      {/*<br />*/}
                      {/*<span className="infoContent-textInfo--main"> {adminEmail} </span>*/}
                      {/*<IconButton className="infoContent-copyButton" onClick={() => void copyEmail()}>*/}
                      {/*  <CopyIcon />*/}
                      {/*</IconButton>*/}
                    </div>

                    <IconButton className="infoContent-closeButton" onClick={() => setAnchorEl(null)}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </Popover>
                <LoadingButton
                  disabled={!isValid || !!errors.otp}
                  className="authForm-field"
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  loadingPosition="start"
                  startIcon={<></>}
                  loading={status === 'loading'}
                >
                  Confirm
                </LoadingButton>
              </>
            ) : (
              <>
                <TextField
                  {...register('email')}
                  error={!!errors.email}
                  className="authForm-field"
                  label="Email"
                  name="email"
                  autoComplete="off"
                  defaultValue=""
                  fullWidth
                  onBlur={() => trigger('email')}
                />
                {errors.email && <FormHelperText error>{errors.email?.message as string}</FormHelperText>}
                <TextField
                  {...register('password')}
                  error={!!errors.password}
                  className="authForm-field"
                  type={showPassword ? 'text' : 'password'}
                  label="Password"
                  name="password"
                  defaultValue=""
                  fullWidth
                  onBlur={() => trigger('password')}
                  InputProps={{
                    autoComplete: 'new-password',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          className="authForm-button--minWidth"
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword((show) => !show)}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <EyeClosedIcon /> : <EyeIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.password && <FormHelperText error>{errors.password?.message as string}</FormHelperText>}
                <LinkMUI
                  sx={{ m: '8px 0 0 auto', cursor: 'pointer' }}
                  component="span"
                  underline="hover"
                  variant="body2"
                  color="primary"
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setCoordinates({ x: event.clientX, y: event.clientY });
                  }}
                >
                  Forgot password or have any issues?
                </LinkMUI>
                <Popover
                  open={!!anchorEl}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  anchorReference="anchorPosition"
                  anchorPosition={{
                    top: coordinates.y,
                    left: coordinates.x,
                  }}
                >
                  <div className="infoContent">
                    <div className="infoContent-textInfo">
                      If you forgot your password or have other problems, please contact your manager.
                      {/*<span className="infoContent-textInfo--main"> {adminEmail} </span>*/}
                      {/*<IconButton className="authForm-button--minWidth" onClick={() => void copyEmail()}>*/}
                      {/*  <CopyIcon />*/}
                      {/*</IconButton>*/}
                    </div>

                    <IconButton className="infoContent-closeButton" onClick={() => setAnchorEl(null)}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </Popover>

                <LoadingButton
                  className="authForm-field"
                  disabled={isSignInButtonDisabled}
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  loadingPosition="start"
                  startIcon={<></>}
                  loading={status === 'loading'}
                >
                  Sign in{' '}
                  {cooldownTimer && isEmailBlocked && minutes + seconds > 0
                    ? `(${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds})`
                    : ''}
                </LoadingButton>
              </>
            )}
          </form>
        </div>
      </div>
    </AuthLayout>
  );
}

export default Login;
