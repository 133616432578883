import Button from 'components/shared/Button/Button';
import Dialog from 'components/shared/Dialog/Dialog';
import DialogActions from 'components/shared/Dialog/DialogActions/DialogActions';
import DialogContent from 'components/shared/Dialog/DialogContent/DialogContent';
import DialogTitle from 'components/shared/Dialog/DialogTitle/DialogTitle';
import Typography from 'components/shared/Typography/Typography';

interface ICloseFilterDrawerConfirmModalProps {
  open: boolean;
  onClose: () => void;
  closeModal: () => void;
}

export const CloseFilterDrawerConfirmModal = ({ open, onClose, closeModal }: ICloseFilterDrawerConfirmModalProps) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle variant="h6">Close filter bar?</DialogTitle>
      <DialogContent>
        <Typography variant="body1">Changes won’t be applied</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} variant="text" color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            closeModal();
            onClose();
          }}
          variant="contained"
          color="primary"
        >
          Close bar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
