import { DropzoneArea, DropzoneAreaProps } from "mui-file-dropzone";

import './index.scss';

interface IDropZoneProps extends DropzoneAreaProps {
  primary?: boolean;
  label?: string;
}

const Dropzone = (props: IDropZoneProps) => {
  return (
    <DropzoneArea
      fileObjects={[]}
      disableRejectionFeedback={false}
      classes={{
        root: "MuiBox-root",
        active: "MuiBox-root-active",
        // invalid: "MuiBox-root-invalid" // todo с текущей версией либы есть проблема на определение невалидного файла в хроме с реакт 18 (проблема решена с версией react-dropzone 14.1.1)
      }}
      {...props} />
  )
};

export default Dropzone;