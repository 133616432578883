import { GridRenderCellParams } from '@mui/x-data-grid';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';

/**
 * Props for the RenderTaxCodeCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 */
interface IRenderTaxCodeCellProps {
  props: GridRenderCellParams;
}

/**
 * Renders a "Tax code" cell in a data grid.
 */
export const RenderTaxCodeCell = ({ props }: IRenderTaxCodeCellProps) => {
  const { row } = props;

  if (!row.id || !row.salesTaxCode) {
    return <></>;
  }

  const { code, category } = row.salesTaxCode;

  return (
    <Stack>
      <Typography variant="body2">{code || '—'}</Typography>
      <Typography variant="body2">{category || '—'}</Typography>
    </Stack>
  );
};
