import Carousel from 'react-material-ui-carousel';
import dayjs from 'dayjs';
import colors from 'theme/constants/colors';
import { currencyFormatter } from 'utils/currencyFormatter';
import DashLine from 'assets/img/DashLine';
import ZoomInIcon from 'assets/img/ZoomInIcon';
import AvatarItem from 'components/shared/AvatarItem/AvatarItem';
import Stack from 'components/shared/Stack/Stack';
import StatusBlock from 'components/shared/StatusBlock/StatusBlock';
import Typography from 'components/shared/Typography/Typography';

const WIDTH_ENTITY = 322;
const HEIGHT_ENTITY = 174;
const DEFAULT_MEDIA_URL = 'https://www.svgrepo.com/show/33921/sea-turtle.svg';
const DEFAULT_LOCATION = 'New York';

const EventCard = (props: any) => {
  const { title, orderedMediaList, format, startedAt, locationInfo, priceOption, price, categoryName } = props.data;
  const { userInfoDto = {} } = props.data;
  const { avatarUrl, firstName, lastName } = userInfoDto;

  const getLocation = () => locationInfo?.locationCity || locationInfo?.locationFullAddress || DEFAULT_LOCATION;

  return orderedMediaList.length > 1 ? (
    <Carousel
      height={300}
      animation="slide"
      autoPlay={false}
      swipe={false}
      className="eventCard--Carousel"
      navButtonsAlwaysInvisible={true}
      indicatorIconButtonProps={{ className: 'carouselIcon', style: { color: colors.actionDisabled } }}
      activeIndicatorIconButtonProps={{ style: { color: colors.primary } }}
      indicatorContainerProps={{ style: { gap: '12px' } }}
    >
      {orderedMediaList.map((el: any, index: number) => (
        <Stack key={el.order} gap={2} m="0 16px">
          {el.order === 0 && (
            <Typography color="text.primary" variant="caption">
              {categoryName}
            </Typography>
          )}
          <div className="carouselEntity">
            <div
              className="overlay"
              style={{
                width: el.order === 0 ? '100%' : 200,
                height: el.order === 0 ? '100%' : 300,
              }}
              onClick={() => {
                props.showMedia(
                  el.mediaUrl ? el.mediaUrl : DEFAULT_MEDIA_URL,
                  el,
                  el.mediaType === 'VIDEO' ? 'video' : 'Image',
                  props.data.orderedMediaList ?? [],
                );
              }}
            >
              <ZoomInIcon />
              VIEW MEDIA
            </div>
            <div className={el.order === 0 ? 'eventCard--container' : ''}>
              {el.order === 0 && (
                <div className="eventCard--data">
                  <div className="eventCard--status">
                    <StatusBlock status={format} irl={true} />
                  </div>
                  <div className="eventCard--name">{title}</div>
                  <div className="eventCard--avatar">
                    <AvatarItem
                      sizes="small"
                      src={avatarUrl}
                      primaryText={`${firstName} ${lastName}`}
                      subText="Creator"
                    />
                  </div>
                  <div className="eventCard--location">{`${dayjs(startedAt).format('MMM DD')} | ${getLocation()}`}</div>

                  <div className="eventCard--price">
                    Price:{' '}
                    <span>{priceOption === 'FREE' ? 'free' : `${currencyFormatter(price, 'en-US', 'USD')}`}</span>
                  </div>
                </div>
              )}
              <div className={el.order === 0 ? 'eventCard--image' : 'eventCard--background'}>
                <img
                  src={el.mediaPreviewUrl || DEFAULT_MEDIA_URL}
                  height={el.order === 0 ? HEIGHT_ENTITY : 300}
                  width={el.order === 0 ? WIDTH_ENTITY : 200}
                  alt="Event media"
                />
              </div>
              {el.order === 0 && (
                <div className="eventCard--dashLine">
                  <DashLine />
                </div>
              )}
            </div>
          </div>
        </Stack>
      ))}
    </Carousel>
  ) : (
    <Stack gap={2}>
      <Typography color="text.primary" variant="caption">
        {categoryName}
      </Typography>
      <div className="carouselEntity">
        <div
          className="overlay"
          onClick={() => {
            const mediaItem = props.data.orderedMediaList[0] || {
              mediaUrl: DEFAULT_MEDIA_URL,
              mediaType: 'Image',
            };
            props.showMedia(
              mediaItem.mediaUrl,
              mediaItem,
              mediaItem.mediaType === 'VIDEO' ? 'video' : 'Image',
              props.data.orderedMediaList,
            );
          }}
        >
          <ZoomInIcon />
          VIEW MEDIA
        </div>
        <div className="eventCard--container">
          <div className="eventCard--data">
            <div className="eventCard--status">
              <StatusBlock status={props.data.format} irl />
            </div>
            <div className="eventCard--name">{title}</div>
            <div className="eventCard--avatar">
              <AvatarItem
                sizes="small"
                src={avatarUrl}
                primaryText={`${firstName} ${lastName}`}
                subText="Creator"
                levelAura="NONE"
              />
            </div>
            <div className="eventCard--location">{`${dayjs(startedAt).format('DD MMM')} | ${getLocation()}`}</div>
            <div className="eventCard--price">
              Price: <span>{priceOption === 'FREE' ? 'free' : `${currencyFormatter(price, 'en-US', 'USD')}`}</span>
            </div>
          </div>
          <div className="eventCard--image">
            <img src={orderedMediaList[0]?.mediaPreviewUrl || DEFAULT_MEDIA_URL} alt="Event media" />
          </div>
          <div className="eventCard--dashLine">
            <DashLine />
          </div>
        </div>
      </div>
    </Stack>
  );
};

export default EventCard;
