import * as yup from 'yup';

const getValidationSchema = () => {
  return yup.object().shape({
    salesTaxCode: yup.object().shape({
      code: yup.string().required('Required field'),
      category: yup.string().required('Required field'),
    }),
  });
};

export default getValidationSchema;
