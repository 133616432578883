export enum AuthMessages {
  // setup password
  MATCH_OLD_PASSWORD = 'The new password must not match the old one.',
  BAD_CODE = 'Password setting error.',
  BAD_PASSWORD = 'Password does not match expression.',

  // sign in
  INCORRECT_CREDENTIALS = 'Incorrect email or password.',
  TRY_SIGN_IN_LATER = 'You have unsuccessfully tried to enter 5 times. Try again 10 minutes later.',
  NO_TRIES_LOGIN = 'Your account has been blocked due to multiple unsuccessful login attempts.',
  NO_TRIES_GET_OTP = 'Unable to resend one time password',

  // otp
  UNIDENTIFIED_OTP = 'Looks like something\'s wrong with your security code.', // если для кредов не бэке нет otp (в идеальном мире такого произойти не должно)
  INCORRECT_OTP = 'Wrong one time password.',
  DEACTIVATED_OTP_CODE = 'Sorry. You have made too many attempts to enter the one time password.',
  EXPIRED_OTP_CODE = 'Sorry. Your one time password has expired.',
  UNVALIDATED_OTP = 'Unvalidated otp',
}

export enum OtherMessages {
  SHOULD_ENTER_CREDENTIALS_AGAIN = 'You have to enter you credentials again to request new code.'
}

export enum ProfileMessages {
  UNVALIDATED_PASSWORD = 'Incorrect password.',
  NO_TRIES_CHANGE_PASSWORD = 'The attempts are over. To change the password, you must contact the Administrator.',
  NO_TRIES = 'Your account has been blocked due to multiple unsuccessful password changes.',
}

export enum CommonErrorMessages {
  UNEXPECTED_ERROR = 'An unexpected error occurred',
}

export enum WorldText {
  DUPLICATE_TITLE = 'Duplicate title detected',
}

export type ErrorMessages =
  | AuthMessages.MATCH_OLD_PASSWORD
  | AuthMessages.BAD_CODE
  | AuthMessages.BAD_PASSWORD
  | AuthMessages.INCORRECT_CREDENTIALS
  | AuthMessages.TRY_SIGN_IN_LATER
  | AuthMessages.NO_TRIES_LOGIN
  | AuthMessages.INCORRECT_OTP
  | AuthMessages.DEACTIVATED_OTP_CODE
  | OtherMessages.SHOULD_ENTER_CREDENTIALS_AGAIN
  | AuthMessages.UNVALIDATED_OTP
  | AuthMessages.EXPIRED_OTP_CODE
  | ProfileMessages.UNVALIDATED_PASSWORD
  | ProfileMessages.NO_TRIES_CHANGE_PASSWORD
  | ProfileMessages.NO_TRIES
  | CommonErrorMessages.UNEXPECTED_ERROR
  | WorldText.DUPLICATE_TITLE
  ;