import dayjs from 'dayjs';
import { currencyFormatter } from 'utils/currencyFormatter';
import { IFilterElement, IFilterItem } from 'types/commonTypes';

export const formatDateRange = (from: string, to: string) => {
  return `${dayjs(from).format('MM/DD/YYYY')} - ${dayjs(to.replace('T23:59:59Z', '')).format('MM/DD/YYYY')}`;
};

export const formatBetweenFilter = (isPriceFilter: boolean, from: string, to: string) => {
  if (isPriceFilter && (from || to)) {
    return `${currencyFormatter(Number(from), 'en-US', 'USD')} - ${currencyFormatter(Number(to), 'en-US', 'USD')}`;
  }
  return `${from} - ${to}`;
};

export const getEqualsFilterText = (element: IFilterElement | null) => {
  return element?.titles ? element.titles[0] : element?.value ?? '';
};

export const getSingleElementText = (element: IFilterElement) => {
  return element?.titles ? element.titles[0] : element?.value ?? '';
};

export const isSingleElementFilter = (filter: IFilterItem) => {
  return filter.elements && (filter.type === 'IN' || filter.type === 'OR') && filter.elements.length === 1;
};
