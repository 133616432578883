import { GridRenderCellParams } from '@mui/x-data-grid';
import startCase from 'lodash/startCase';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';

/**
 * Renders an entity cell in a data grid.
 */
export const RenderEntityCell = (props: GridRenderCellParams) => {
  const { row } = props;

  if (!row.id) {
    return <></>;
  }

  return (
    <Stack>
      <Typography variant="body2">{startCase(row.entityType?.toLowerCase())}</Typography>
      <Typography variant="caption" color="text.secondary">
        {startCase(row.format?.toLowerCase()) || '—'}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        {startCase(row.eventOnlineFormatType?.toLowerCase()) || '—'}
      </Typography>
    </Stack>
  );
};
