import { createSvgIcon } from '@mui/material';

const SystemIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.14999 22C6.14999 21.5306 6.53055 21.15 6.99999 21.15H17C17.4694 21.15 17.85 21.5306 17.85 22C17.85 22.4695 17.4694 22.85 17 22.85H6.99999C6.53055 22.85 6.14999 22.4695 6.14999 22Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12 22C11.5306 22 11.15 21.9024 11.15 21.7821L11.15 19.2179C11.15 19.0976 11.5306 19 12 19C12.4694 19 12.85 19.0976 12.85 19.2179L12.85 21.7821C12.85 21.9024 12.4694 22 12 22Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.4409 1.15002H13.5591C15.4376 1.15001 16.9281 1.15 18.1022 1.29749C19.3098 1.44918 20.3003 1.76859 21.1083 2.49434C21.2478 2.61961 21.3804 2.75227 21.5057 2.89173C22.2314 3.69969 22.5508 4.6902 22.7025 5.8978C22.85 7.07191 22.85 8.56238 22.85 10.4409V10.5592C22.85 12.4377 22.85 13.9281 22.7025 15.1023C22.5508 16.3099 22.2314 17.3004 21.5057 18.1083C21.3804 18.2478 21.2478 18.3804 21.1083 18.5057C20.3003 19.2315 19.3098 19.5509 18.1022 19.7026C16.9281 19.8501 15.4376 19.85 13.5591 19.85H10.4409C8.56235 19.85 7.07188 19.8501 5.89777 19.7026C4.69017 19.5509 3.69966 19.2315 2.8917 18.5057C2.75224 18.3804 2.61958 18.2478 2.49431 18.1083C1.76856 17.3004 1.44915 16.3099 1.29746 15.1023C1.14997 13.9281 1.14998 12.4377 1.14999 10.5591V10.4409C1.14998 8.56239 1.14997 7.07191 1.29746 5.8978C1.44915 4.6902 1.76856 3.69969 2.49431 2.89173C2.61958 2.75227 2.75224 2.61961 2.8917 2.49434C3.69966 1.76859 4.69017 1.44918 5.89777 1.29749C7.07188 1.15 8.56236 1.15001 10.4409 1.15002ZM6.10965 2.98423C5.07502 3.1142 4.47405 3.35812 4.02772 3.75904C3.93342 3.84375 3.84372 3.93345 3.75901 4.02775C3.35809 4.47408 3.11417 5.07505 2.9842 6.10968C2.85157 7.16551 2.84999 8.54909 2.84999 10.5C2.84999 12.451 2.85157 13.8345 2.9842 14.8904C3.11417 15.925 3.35809 16.526 3.75901 16.9723C3.84372 17.0666 3.93342 17.1563 4.02772 17.241C4.47405 17.6419 5.07502 17.8859 6.10965 18.0158C7.16548 18.1485 8.54906 18.15 10.5 18.15H13.5C15.4509 18.15 16.8345 18.1485 17.8903 18.0158C18.925 17.8859 19.5259 17.6419 19.9723 17.241C20.0666 17.1563 20.1563 17.0666 20.241 16.9723C20.6419 16.526 20.8858 15.925 21.0158 14.8904C21.1484 13.8345 21.15 12.451 21.15 10.5C21.15 8.54909 21.1484 7.16551 21.0158 6.10968C20.8858 5.07505 20.6419 4.47408 20.241 4.02775C20.1563 3.93345 20.0666 3.84375 19.9723 3.75904C19.5259 3.35812 18.925 3.1142 17.8903 2.98423C16.8345 2.8516 15.4509 2.85003 13.5 2.85003H10.5C8.54906 2.85003 7.16548 2.8516 6.10965 2.98423Z" />
    <path d="M14.2858 14.4782C14.2528 14.5121 14.2142 14.5064 14.1701 14.4613L13.343 13.4287C13.3099 13.3722 13.3099 13.3271 13.343 13.2932L16.767 10.4492L13.4588 7.70677C13.4368 7.6842 13.4423 7.63906 13.4753 7.57134L14.2858 6.53869C14.33 6.49355 14.3686 6.48791 14.4016 6.52177L18.8677 10.1614C18.9559 10.2291 19 10.3194 19 10.4323C19 10.5451 18.9559 10.6467 18.8677 10.737L14.2858 14.4782Z" />
    <path d="M9.71415 14.4782L5.13233 10.737C5.04411 10.6467 5 10.5451 5 10.4323C5 10.3194 5.04411 10.2291 5.13233 10.1614L9.59837 6.52177C9.63145 6.48791 9.67004 6.49355 9.71415 6.53869L10.5247 7.57134C10.5577 7.63906 10.5633 7.6842 10.5412 7.70677L7.23302 10.4492L10.657 13.2932C10.6901 13.3271 10.6901 13.3722 10.657 13.4287L9.82994 14.4613C9.78583 14.5064 9.74724 14.5121 9.71415 14.4782Z" />
  </svg>,
  'System',
);

export default SystemIcon;
