import { ContentType } from 'types/commonTypes';
import CardType from 'components/Moderation/types/CardType';
import ModerationTabs from 'components/Moderation/types/ModerationTabs';
import getConfirmedHiveViolations from 'components/Moderation/utils/getConfirmedHiveViolations';
import groupComplaints from 'components/Moderation/utils/groupComplaints';

const getRenderCardProps = (props: any, tabValue: ModerationTabs): any => {
  switch (tabValue) {
    case ModerationTabs.HIVE:
      const amountViolations = props.moderationJobResults?.reduce(
        (sum: number, result: any) => sum + result.metrics?.length,
        0,
      );

      return {
        entityType: props.entityInfo?.entityType?.toLowerCase(),
        entityId: props.entityInfo?.entityId,
        amountViolations: amountViolations,
        violationsFrom: `${amountViolations > 1 ? 'violations from HIVE' : 'violation from HIVE'}`,
        createdDate: props.hiveTaskCreatedDate,
        entityInfo: props.entityInfo,
        entityOwnerInfo: props.entityOwnerInfo,
        moderationJobResults: props.moderationJobResults
          ?.filter((el: any) => {
            return el.status === 'NEEDS_REVIEW';
          })
          .map((result: any) => {
            return {
              ...result,
              contentType:
                result.contentType?.toUpperCase() === ContentType.PHOTO ? 'Image' : result.contentType?.toLowerCase(),
            };
          }),
        hiveModerator: props.hiveModerator,
        confirmedHiveViolations:
          props.hiveViolationConfirmed === true ? getConfirmedHiveViolations(props.moderationJobResults) : [],
        cardType: CardType.HIVE,
        profileInfo: props.profileInfo,
        manualMode: props.manualMode,
      };
    case ModerationTabs.CONTENT_COMPLAINTS:
      return {
        entityType: props.entityInfo?.entityType?.toLowerCase(),
        entityId: props.entityInfo?.entityId,
        amountViolations: props.complaints?.length,
        confirmedComplaintsViolations: [],
        violationsFrom: props.complaints?.length > 1 ? 'complaints from users' : 'complaint from users',
        createdDate: props.complaintTaskCreatedDate,
        entityInfo: props.entityInfo,
        entityOwnerInfo: props.entityOwnerInfo,
        complaints: groupComplaints(props.complaints),
        cardType: CardType.CONTENT,
        complaintsModerator: props.complaintsModerator,
      };
    case ModerationTabs.PROFILE_COMPLAINTS:
      return {
        entityType: 'User',
        entityId: props.profileInfo?.profileId,
        amountViolations: props.complaints?.length,
        confirmedComplaintsViolations: [],
        violationsFrom: props.complaints?.length > 1 ? 'complaints from users' : 'complaint from users',
        createdDate: props.complaintTaskCreatedDate,
        profileInfo: props.profileInfo,
        complaints: groupComplaints(props.complaints),
        cardType: CardType.PROFILE,
        complaintsModerator: props.complaintsModerator,
      };
    case ModerationTabs.CONFIRMED_VIOLATIONS:
    case ModerationTabs.DECLINED_REQUESTS:
      if (props.hiveTaskCreatedDate && !props.lastComplaints) {
        let amountViolations = props.moderationJobResults?.reduce(
          (sum: number, result: any) => sum + result.metrics?.length,
          0,
        );

        return {
          entityType: props.entityInfo?.entityType?.toLowerCase(),
          entityId: props.entityInfo?.entityId,
          amountViolations: amountViolations,
          violationsFrom: `${amountViolations > 1 ? 'violations from HIVE' : 'violation from HIVE'}`,
          createdDate: props.hiveTaskCreatedDate,
          entityInfo: props.entityInfo,
          entityOwnerInfo: props.entityOwnerInfo,
          moderationJobResults: !props.manualMode
            ? props.moderationJobResults
                ?.filter((result: any) => result?.metrics?.length !== 0)
                .map((result: any) => {
                  return {
                    ...result,
                    contentType:
                      result.contentType?.toUpperCase() === ContentType.PHOTO
                        ? 'Image'
                        : result.contentType?.toLowerCase(),
                  };
                })
            : props.moderationJobResults?.map((result: any) => {
                return {
                  ...result,
                  contentType:
                    result.contentType?.toUpperCase() === ContentType.PHOTO
                      ? 'Image'
                      : result.contentType?.toLowerCase(),
                };
              }),
          confirmedHiveViolations:
            props.hiveViolationConfirmed === true ? getConfirmedHiveViolations(props.moderationJobResults) : [],
          confirmedManualModeViolations: props.confirmedManualModeViolations ?? [],
          cardType: CardType.HIVE,
          hiveModerator: props.hiveModerator,
          hiveModeratorId: props.hiveModeratorId,
          manualMode: props.manualMode,
        };
      } else if (props.sourceType === 'CONTENT') {
        return {
          entityType: props.entityInfo?.entityType?.toLowerCase(),
          entityId: props.entityInfo?.entityId,
          amountViolations: props.complaints?.length || props.lastComplaints?.length,
          confirmedComplaintsViolations: props.confirmedComplaintsViolations ?? [],
          confirmedManualModeViolations: props.confirmedManualModeViolations ?? [],
          violationsFrom:
            (props.complaints?.length ? props.complaints?.length : props.lastComplaints?.length) > 1
              ? 'complaints from users'
              : 'complaint from users',
          createdDate: props.complaintTaskCreatedDate,
          entityInfo: props.entityInfo,
          entityOwnerInfo: props.entityOwnerInfo,
          complaints: groupComplaints(props.complaints || props.lastComplaints),
          cardType: CardType.CONTENT,
          complaintsModerator: props.complaintsModerator,
          manualMode: props.manualMode,
        };
      } else {
        return {
          entityType: 'User',
          entityId: props.profileInfo?.profileId,
          amountViolations: props.complaints?.length || props.lastComplaints?.length,
          confirmedComplaintsViolations: props.confirmedComplaintsViolations ?? [],
          confirmedManualModeViolations: props.confirmedManualModeViolations ?? [],
          violationsFrom:
            (props.complaints?.length ? props.complaints?.length : props.lastComplaints?.length) > 1
              ? 'complaints from users'
              : 'complaint from users',
          createdDate: props.complaintTaskCreatedDate,
          profileInfo: props.profileInfo,
          complaints: groupComplaints(props.complaints || props.lastComplaints),
          cardType: CardType.PROFILE,
          complaintsModerator: props.complaintsModerator,
          manualMode: props.manualMode,
        };
      }
  }
};

export default getRenderCardProps;
