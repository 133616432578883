import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import dayjs from 'dayjs';
import { IFilterItem, IStatus } from 'types/commonTypes';
import { RootState } from './store';
import ContentDisplayTabs from 'components/ContentDisplay/types/ContentDisplayTabs';
import ModerationTabs from 'components/Moderation/types/ModerationTabs';

export interface FiltersState {
  filters: any[];
  sortedFilters: any[];
  presets: any[];
  activePresetId: string;
  data: any;
  currentData: any;
  possibleOptions: any;
  status: IStatus;
}

const initialState: FiltersState = {
  presets: [],
  filters: [],
  sortedFilters: [],
  activePresetId: '',
  data: {},
  currentData: {},
  possibleOptions: {},
  status: 'idle',
};

export const fetchFilterPresets = createAsyncThunk('filters/fetchPresets', async (type: string) => {
  let presetsUrl;
  switch (type) {
    case 'users':
    default: {
      presetsUrl = process.env.REACT_APP_USERS_ENDPOINT + 'timusers/presets?presetType=FILTERING';
    }
  }
  const response = await axios.get(presetsUrl, {
    headers: {
      'Member-Id': '0',
    },
  });
  return { presets: response.data.presets };
});

export const setActivePreset = createAsyncThunk(
  'filters/setActivePreset',
  async ({ type, presetId }: { type: string; presetId: string }) => {
    let presetUrl;
    switch (type) {
      case 'users':
      default: {
        presetUrl = process.env.REACT_APP_USERS_ENDPOINT + `timusers/presets/filters/${presetId}`;
      }
    }
    const response = await axios.get(presetUrl, {
      headers: {
        'Member-Id': '0',
      },
    });
    return { filters: response.data.filters, presetId: presetId };
  },
);

export const savePreset = createAsyncThunk(
  'filters/savePreset',
  async ({ type, presetName }: { type: string; presetName: string }, thunkAPI) => {
    const rootState: RootState = (await thunkAPI.getState()) as RootState;
    const filters = Object.keys(rootState.filters.data)
      ? Object.values(rootState.filters.data).filter((item: any) => item !== null)
      : [];
    let presetsUrl;
    switch (type) {
      case 'users':
      default: {
        presetsUrl = process.env.REACT_APP_USERS_ENDPOINT + 'timusers/presets?presetType=FILTERING';
      }
    }
    const response = await axios.post(
      presetsUrl,
      {
        presetName: presetName,
        presetType: 'FILTERING',
        filters: filters,
      },
      {
        headers: {
          'Member-Id': '0',
        },
      },
    );
    return { presets: response.data.presets };
  },
);

export const deletePreset = createAsyncThunk(
  'filters/deletePreset',
  async ({ type, presetId }: { type: string; presetId: string }) => {
    let presetsUrl;
    switch (type) {
      case 'users':
      default: {
        presetsUrl = process.env.REACT_APP_USERS_ENDPOINT + `timusers/presets/${presetId}`;
      }
    }
    const response = await axios.delete(presetsUrl, {
      headers: {
        'Member-Id': '0',
      },
    });
    return { presets: response.data.presets };
  },
);

export const fetchFilters = createAsyncThunk('filters/fetchFilters', async (type: string) => {
  let filtersUrl;
  switch (type) {
    case ModerationTabs.HIVE: {
      filtersUrl = process.env.REACT_APP_MODERATION_SCREEN_ENDPOINT + `moderation/screen/hive/filters`;
      break;
    }
    case ModerationTabs.CONTENT_COMPLAINTS: {
      filtersUrl = process.env.REACT_APP_MODERATION_SCREEN_ENDPOINT + `moderation/screen/content/filters`;
      break;
    }
    case ModerationTabs.PROFILE_COMPLAINTS: {
      filtersUrl = process.env.REACT_APP_MODERATION_SCREEN_ENDPOINT + `moderation/screen/profile/filters`;
      break;
    }
    case ModerationTabs.CONFIRMED_VIOLATIONS:
    case ModerationTabs.DECLINED_REQUESTS: {
      filtersUrl = process.env.REACT_APP_MODERATION_SCREEN_ENDPOINT + `moderation/screen/history/filters`;
      break;
    }
    case 'members': {
      filtersUrl = process.env.REACT_APP_MEMBERS_ENDPOINT + 'members/screen/filters';
      break;
    }
    case ContentDisplayTabs.EVENTS:
    case ContentDisplayTabs.OFFERS:
    case ContentDisplayTabs.WISHES:
    case ContentDisplayTabs.LIFESTYLES: {
      filtersUrl = process.env.REACT_APP_CONTENT_DISPLAY_ENDPOINT + `content/screen/${type}/filters`;
      break;
    }
    case 'tax-moderation': {
      filtersUrl = process.env.REACT_APP_TAXES_ENDPOINT + 'sales/tax/screen/filters';
      break;
    }
    case 'operations': {
      filtersUrl = process.env.REACT_APP_OPERATIONS_ENDPOINT + 'screen/filters?belongsToKinds=operation_find';
      break;
    }
    case 'fees': {
      filtersUrl = process.env.REACT_APP_PAYMENTS_ENDPOINT + 'screen/filters?belongsToKind=fees_find';
      break;
    }
    case 'tiers': {
      filtersUrl = process.env.REACT_APP_PAYMENTS_ENDPOINT + 'screen/filters?belongsToKind=tier_find';
      break;
    }
    case 'users':
    default: {
      filtersUrl = process.env.REACT_APP_USERS_ENDPOINT + 'timusers/presets/filters';
    }
  }
  const response = await axios.get(filtersUrl, {
    // headers: {
    //   'Member-Id': '0'
    // }
  });
  return { filters: response.data.filters, type: type };
});

export const fetchPossibleOptions = createAsyncThunk(
  'filters/fetchPossibleOptions',
  async ({
    type,
    field,
    value,
    additionalValue,
  }: {
    type: string;
    field: string;
    value?: string;
    additionalValue?: string;
  }) => {
    let optionsUrl;
    switch (type) {
      case ModerationTabs.HIVE:
      case ModerationTabs.CONTENT_COMPLAINTS:
      case ModerationTabs.PROFILE_COMPLAINTS:
      case ModerationTabs.CONFIRMED_VIOLATIONS:
      case ModerationTabs.DECLINED_REQUESTS: {
        optionsUrl = process.env.REACT_APP_MODERATION_SCREEN_ENDPOINT + `moderation/screen/filters/dropdown`;
        break;
      }
      case 'members':
        optionsUrl = process.env.REACT_APP_MEMBERS_ENDPOINT + 'members/screen/filters/dropdown';
        break;
      case 'content-display': {
        optionsUrl = process.env.REACT_APP_CONTENT_DISPLAY_ENDPOINT + 'content/screen/filters/dropdown';
        break;
      }
      case 'tax-moderation': {
        optionsUrl = process.env.REACT_APP_TAXES_ENDPOINT + 'sales/tax/screen/filters/dropdown';
        break;
      }
      case 'fees': {
        optionsUrl = process.env.REACT_APP_PAYMENTS_ENDPOINT + 'payment-adapter/tiers/screen/filters/dropdown';
        break;
      }
      case 'operations': {
        optionsUrl =
          process.env.REACT_APP_OPERATIONS_ENDPOINT + 'screen/filters/dropdown?belongsToKinds=operation_find';
        break;
      }
      case 'tiers': {
        optionsUrl = process.env.REACT_APP_PAYMENTS_ENDPOINT + 'payment-adapter/tiers/screen/filters/dropdown';
        break;
      }
      case 'users':
      default: {
        optionsUrl = process.env.REACT_APP_USERS_ENDPOINT + 'timusers/filters/dropdown';
      }
    }
    const response = await axios.get(optionsUrl, {
      params: {
        columnId: field,
        value: value,
        additionalValue: additionalValue,
      },
      headers: {
        'Profile-Id': '0',
      },
    });
    return {
      field: field,
      options: response.data.values.map((item: any) => ({ titles: item.titles, id: item.value })),
    };
  },
);

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    removeFilter: (state, action) => {
      state.data[action.payload.columnId] = null;
      state.currentData[action.payload.columnId] = null;
      state.possibleOptions[action.payload.columnId] = [];
    },
    clearFilters: (state) => {
      state.data = {};
      state.currentData = {};
      state.possibleOptions = {};
    },
    reverseFilters: (state) => {
      state.currentData = state.data;
      state.possibleOptions = {};
    },
    clearPossibleOptions: (state, action) => {
      state.possibleOptions[action.payload.columnId] = [];
    },
    setFilterValue: (state, action) => {
      const result = Array.isArray(action.payload.value)
        ? action.payload.value.map((item: any) => ({
            value: item.value ?? item.id,
            titles: item.titles,
          }))
        : action.payload.value;
      state.currentData[action.payload.columnId][action.payload.valueType] = result;
    },
    setCustomFilterValue: (state, action) => {
      state.data[action.payload.columnId] = {
        ...action.payload,
      };
      state.currentData[action.payload.columnId] = {
        ...action.payload,
      };
    },
    setActiveFilter(state, action) {
      let item = action.payload.item;
      if ((item.valueType === 'DATE' || item.displayValueType === 'DATE') && item.type === 'BETWEEN') {
        item = {
          ...item,
          from: dayjs().format('YYYY-MM-DD'),
          to: dayjs().endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]'),
        };
      }
      state.currentData[action.payload.item.columnId] = action.payload.checked ? item : null;
      if (!action.payload.checked) state.possibleOptions[action.payload.item.columnId] = [];
    },
    saveFilters: (state) => {
      state.data = {
        ...state.currentData,
      };
    },
    sortFilters: (state, action) => {
      state.sortedFilters = state.filters.filter(
        (filter: IFilterItem) => filter.belongsToKinds?.includes(action.payload as string),
      );
    },
    sortFiltersByDependsOnValue: (state, action) => {
      state.sortedFilters = state.filters.filter(
        (filter: IFilterItem) => filter.dependsOn?.value?.includes(action.payload as string),
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilters.pending, (state) => {
        state.status = 'loading';
        state.data = {};
      })
      .addCase(fetchFilters.fulfilled, (state, action) => {
        state.status = 'idle';
        state.filters = action.payload.filters;
      })
      .addCase(fetchFilters.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchFilterPresets.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFilterPresets.fulfilled, (state, action) => {
        state.status = 'idle';
        state.presets = action.payload.presets;
      })
      .addCase(fetchFilterPresets.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(setActivePreset.fulfilled, (state, action) => {
        const newData: any = {};
        action.payload.filters.forEach((item: any) => {
          newData[item.columnId] = item;
        });
        state.data = newData;
        state.activePresetId = action.payload.presetId;
      })
      .addCase(setActivePreset.rejected, () => {})
      .addCase(fetchPossibleOptions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPossibleOptions.fulfilled, (state, action) => {
        state.status = 'idle';
        const currentField = action.payload.field;
        state.possibleOptions[currentField] = action.payload.options;
      })
      .addCase(fetchPossibleOptions.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  clearFilters,
  removeFilter,
  reverseFilters,
  clearPossibleOptions,
  saveFilters,
  setActiveFilter,
  setCustomFilterValue,
  setFilterValue,
  sortFilters,
  sortFiltersByDependsOnValue,
} = filtersSlice.actions;

export const selectFiltersStatus = (state: RootState): IStatus => state.filters.status;
export const selectPossibleOptions = (state: RootState): any => state.filters.possibleOptions;
export const selectFiltersCurrentData = (state: RootState): any => state.filters.currentData;
export const selectFilters = (state: RootState): any[] => state.filters.filters;
export const selectPresets = (state: RootState): any[] => state.filters.presets;
export const selectActivePresetId = (state: RootState): string => state.filters.activePresetId;
export const selectSortedFilters = (state: RootState): any[] => state.filters.sortedFilters;
export const selectFiltersData = (state: RootState): any[] => state.filters.data;

export default filtersSlice.reducer;
