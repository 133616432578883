import { GridRenderCellParams } from '@mui/x-data-grid';
import { possibleTaxRuleCertainty } from '../constants';
import Typography from 'components/shared/Typography/Typography';
import startCase from 'lodash/startCase';

/**
 * Props for the RenderCertaintyCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 */
interface IRenderStatusSelectProps {
  props: GridRenderCellParams;
}

/**
 * Renders a certainty select cell in a data grid.
 */
export const RenderCertaintyCell = ({ props }: IRenderStatusSelectProps) => {
  const { row } = props;

  if (!row.salesTaxRuleId) {
    return <></>;
  }

  const currentCertainty = possibleTaxRuleCertainty.find(
    (el) => row.certainty?.toLowerCase() === el.certainty.toLowerCase(),
  );

  if (!currentCertainty) {
    return <Typography variant="body2">Unknown</Typography>;
  }

  return (
    <Typography variant="body2" color={{ color: currentCertainty.color }}>
      {startCase(row.certainty?.toLowerCase())}
    </Typography>
  );
};
