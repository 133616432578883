import { GridColumnHeaderParams } from '@mui/x-data-grid-pro';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import Typography from 'components/shared/Typography/Typography';

/**
 * Props for the RenderWithTooltipHeader component.
 *
 * @interface
 * @property {string} title - The title of tooltip.
 * @property {GridColumnHeaderParams} props - Parameters for rendering the cell.
 */
interface IRenderHeaderWithTooltipProps {
  title: string;
  props: GridColumnHeaderParams;
}

/**
 * Renders a header cell with tooltip in a data grid.
 */
export const RenderWithTooltipHeader = ({ title, props }: IRenderHeaderWithTooltipProps) => {
  const { colDef } = props;

  return (
    <Tooltip title={title} placement="top" arrow>
      <div>
        <Typography variant="tableHeader">{colDef.headerName}</Typography>
      </div>
    </Tooltip>
  );
};
