import colors from 'theme/constants/colors';
import { ISortingItem } from 'types/commonTypes';
import { ITierStatus, TierStatuses } from 'store/slices/Settings/interface';

export const defaultTiersSortingModel: ISortingItem = {
  columnId: 'created_date',
  title: 'Time added',
  sortingType: 'DESC',
};

export const possibleTierStatuses: ITierStatus[] = [
  {
    status: TierStatuses.ACTIVE,
    title: 'Active',
    color: colors.success,
  },
  {
    status: TierStatuses.INACTIVE,
    title: 'Inactive',
    color: colors.error,
  },
];

export const currencyTooltipTitles: { [currencyCode: string]: string } = {
  USD: 'US dollar',
  EUR: 'Euro',
  GBP: 'British Pound',
  JPY: 'Japanese Yen',
  AUD: 'Australian Dollar',
  CAD: 'Canadian Dollar',
};
