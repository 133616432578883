import { forwardRef, Ref } from 'react';
import { NumberFormatValues, NumericFormat, NumericFormatProps } from 'react-number-format';

interface IProps {
  onChange: (event: {
    target: {
      name: string;
      value: string;
    };
  }) => void;
  name: string;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  thousandSeparator?: string | boolean;
  max?: number;
  inputRef: Ref<HTMLInputElement>;
}

/**
 * NumericFormatCustom is a custom React component that wraps the NumericFormat component
 * to handle numeric input formatting and validation with additional features.
 *
 * @component
 *
 * @param {function} props.onChange - A callback function to handle the change event.
 * @param {string} props.name - The name of the input field.
 * @param {number} [props.decimalScale] - The number of decimal places to display.
 * @param {boolean} [props.fixedDecimalScale] - If set to true, it adds trailing 0s after decimalSeparator to match given decimalScale.
 * @param {string|boolean} [props.thousandSeparator] - A string or boolean specifying the thousand separator character, or `false` to disable it.
 * @param {number} [props.max] - The maximum allowed numeric value.
 * @param {Ref<HTMLInputElement>} props.inputRef - A React ref for the input element.
 *
 * @returns {JSX.Element} The NumericFormatCustom component.
 */
const NumericFormatCustom = forwardRef<NumericFormatProps, IProps>(function CurrencyFormatCustom(props, ref) {
  const { onChange, name, max, decimalScale, fixedDecimalScale, thousandSeparator, inputRef, ...other } = props;

  /**
   * Validates the input values based on the maximum limit, if provided.
   * The minimum limit must be controlled by the onBlur method.
   * For more details, refer to: [GitHub Issue #747](https://github.com/s-yadav/react-number-format/issues/747#issuecomment-1529008856)
   */
  const validation = (values: NumberFormatValues) => {
    const { floatValue } = values;
    if (!max) return false;

    return floatValue ? floatValue <= max : true;
  };

  return (
    <NumericFormat
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        });
      }}
      isAllowed={(values) => (max ? validation(values) : true)}
      allowNegative={false}
      decimalScale={decimalScale || 0}
      allowedDecimalSeparators={[',', '.']}
      fixedDecimalScale={fixedDecimalScale || false}
      thousandSeparator={thousandSeparator || ','}
      valueIsNumericString
      {...other}
    />
  );
});

export default NumericFormatCustom;
