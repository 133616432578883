import { createSvgIcon } from '@mui/material';

const ChevronUpIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M17.2679 13.7014C17.5998 14.0333 17.5998 14.5715 17.2679 14.9035C16.9359 15.2354 16.3977 15.2354 16.0658 14.9035L11.9999 10.8376L7.9342 14.9033C7.60225 15.2353 7.06406 15.2353 6.73211 14.9033C6.40017 14.5714 6.40017 14.0332 6.73211 13.7013L11.2928 9.14055C11.6834 8.75002 12.3165 8.75003 12.707 9.14055L17.2679 13.7014Z" />
  </svg>,
  'ChevronUp',
);

export default ChevronUpIcon;
