import React from "react";
import { selectProfileStatus } from "store/profileSlice";
import { useAppSelector } from "store/hooks";
import Button from "components/shared/Button/Button";
import LoadingButton from "components/shared/LoadingButton/LoadingButton";

import './index.scss';

interface Props {
  onClose(): void;
  logout(): void;
}

const GlobalLogout = ({ onClose, logout }: Props) => {
  const status = useAppSelector(selectProfileStatus);

  return (
    <div className="profileModal">
      <div className="profileModal-title">Log out from all devices</div>
      <div className="profileModal-form">
        <span>You will be logged out from all devices including current one</span>
        <div className="profileModal-buttonsGroup">
          <Button
            variant="text"
            onClick={onClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loadingPosition="start"
            color="error"
            loading={status === 'loading'}
            startIcon={<></>}
            onClick={logout}
          >
            Log out
          </LoadingButton>
        </div>
      </div>
    </div>
  )
};

export default GlobalLogout;
