const Users3Icon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12 2.6499C9.12853 2.6499 6.75 4.9057 6.75 7.7499C6.75 10.5941 9.12853 12.8499 12 12.8499C14.8715 12.8499 17.25 10.5941 17.25 7.7499C17.25 4.9057 14.8715 2.6499 12 2.6499ZM8.45 7.7499C8.45 5.89968 10.0114 4.3499 12 4.3499C13.9886 4.3499 15.55 5.89968 15.55 7.7499C15.55 9.60012 13.9886 11.1499 12 11.1499C10.0114 11.1499 8.45 9.60012 8.45 7.7499Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M11.9998 14.3374C10.2688 14.3374 8.49881 14.63 7.12881 15.2143C5.81949 15.7728 4.5498 16.7563 4.5498 18.2864C4.5498 18.9469 4.89458 19.4723 5.33829 19.8504C5.77239 20.2204 6.35086 20.4982 6.99066 20.7084C8.27661 21.1311 10.0253 21.3499 11.9998 21.3499C13.7112 21.3499 15.45 21.1284 16.7893 20.7127C17.454 20.5064 18.0726 20.2364 18.543 19.8832C19.0066 19.5351 19.4498 19.006 19.4498 18.2864C19.4498 17.5606 19.108 16.9448 18.6439 16.4644C18.1835 15.988 17.5649 15.6012 16.8843 15.2969C15.5214 14.6874 13.7502 14.3374 11.9998 14.3374ZM6.2498 18.2864C6.2498 17.8413 6.63012 17.2753 7.79579 16.778C8.9008 16.3067 10.4308 16.0374 11.9998 16.0374C13.5494 16.0374 15.0782 16.3515 16.1903 16.8488C16.7472 17.0978 17.1598 17.3751 17.4214 17.6457C17.6763 17.9096 17.7483 18.1221 17.7498 18.2812C17.7434 18.3026 17.7059 18.3859 17.5223 18.5238C17.2708 18.7126 16.8581 18.9114 16.2853 19.0891C15.1496 19.4417 13.5884 19.6499 11.9998 19.6499C10.1243 19.6499 8.573 19.439 7.52144 19.0934C6.99249 18.9196 6.64283 18.7286 6.441 18.5566C6.24977 18.3936 6.2498 18.3035 6.2498 18.2864Z" />
    <path d="M17.7537 13.5652C17.7972 13.0978 18.2114 12.7542 18.6788 12.7977C20.0027 12.9209 21.2262 13.253 22.1472 13.7674C23.0348 14.2632 23.8501 15.0596 23.8501 16.1657C23.8501 16.7411 23.6219 17.2593 23.1885 17.667C22.7801 18.0511 22.2208 18.31 21.5746 18.487C21.1218 18.611 20.6542 18.3444 20.5302 17.8917C20.4062 17.4389 20.6728 16.9713 21.1256 16.8473C21.6287 16.7096 21.8944 16.5504 22.0237 16.4287C22.1279 16.3307 22.1501 16.2555 22.1501 16.1657C22.1501 15.986 22.0089 15.6374 21.3182 15.2516C20.6611 14.8846 19.6845 14.5986 18.5213 14.4903C18.0539 14.4468 17.7102 14.0326 17.7537 13.5652Z" />
    <path d="M18.6 4.7749C18.1306 4.7749 17.75 5.15546 17.75 5.6249C17.75 6.09434 18.1306 6.4749 18.6 6.4749C19.6774 6.4749 20.5 7.31114 20.5 8.28115C20.5 9.25117 19.6774 10.0874 18.6 10.0874C18.1306 10.0874 17.75 10.468 17.75 10.9374C17.75 11.4068 18.1306 11.7874 18.6 11.7874C20.5602 11.7874 22.2 10.2452 22.2 8.28115C22.2 6.31716 20.5602 4.7749 18.6 4.7749Z" />
    <path d="M6.24628 13.5652C6.20277 13.0978 5.78858 12.7542 5.32116 12.7977C3.99726 12.9209 2.77384 13.253 1.85276 13.7674C0.965175 14.2632 0.14994 15.0596 0.14994 16.1657C0.14994 16.7411 0.378103 17.2593 0.811536 17.667C1.21989 18.0511 1.77915 18.31 2.42544 18.487C2.87822 18.611 3.34577 18.3444 3.46976 17.8917C3.59374 17.4389 3.32721 16.9713 2.87444 16.8473C2.37134 16.7096 2.1056 16.5504 1.9763 16.4287C1.87208 16.3307 1.84994 16.2555 1.84994 16.1657C1.84994 15.986 1.99114 15.6374 2.68177 15.2516C3.3389 14.8846 4.31549 14.5986 5.47872 14.4903C5.94614 14.4468 6.28979 14.0326 6.24628 13.5652Z" />
    <path d="M5.4 4.7749C5.86944 4.7749 6.25 5.15546 6.25 5.6249C6.25 6.09434 5.86944 6.4749 5.4 6.4749C4.32262 6.4749 3.5 7.31114 3.5 8.28115C3.5 9.25117 4.32262 10.0874 5.4 10.0874C5.86944 10.0874 6.25 10.468 6.25 10.9374C6.25 11.4068 5.86944 11.7874 5.4 11.7874C3.4398 11.7874 1.8 10.2452 1.8 8.28115C1.8 6.31716 3.4398 4.7749 5.4 4.7749Z" />
  </svg>
)

export default Users3Icon;