import * as yup from 'yup';

const getValidationSchema = () => {
  return yup.object().shape({
    reason: yup.string().required('Required field'),
    comment: yup.string().trim().max(5000, 'Max limit of 5000 characters is exceeded'),
  });
};

export default getValidationSchema;
