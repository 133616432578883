import React, { useState } from "react";
import Button from "components/shared/Button/Button";
import LoadingButton from "components/shared/LoadingButton/LoadingButton";
import { CohortsTableData, fetchDeleteCohorts } from "store/worldManagementSlice";
import { enqueueSnackbar, VariantType } from "notistack";
import { useAppDispatch } from "store/hooks";
import Alert from "components/shared/Alert/Alert";
import { IStatus } from "types/commonTypes";

import './index.scss';

interface IProps {
  items: CohortsTableData | CohortsTableData[];
  onClose(): void;
  status: IStatus;
}

const DeleteCohortsModal = ({ items, onClose, status }: IProps) => {
  let content;
  let setScroll;
  let title;

  const isArray = Array.isArray(items);
  const [error, setError] = useState(false);

  const dispatch = useAppDispatch();

  if (Array.isArray(items)) {
    title = 'You are going to delete following cohorts:';
    setScroll = items.length > 3;
    content = items.map((item) =>(<div className='item-content' key={item.id}>
        <div className='item-primary'>{item.title}</div>
        <div className='item-secondary'>{item.creatorName} {item.creatorLastName}</div>
      </div>
    ));
  } else {
    title = `You are going to delete “${items.title}” cohort?`;
    content = null;
  }

  const handleDelete = async () => {
    try {
      const cohortsIds = isArray ? items.map(({ id }) => id) : [items.id];
      const result = await dispatch(fetchDeleteCohorts(cohortsIds));
      if (isArray) {
        result.meta.requestStatus === 'fulfilled' && onClose();
        result.meta.requestStatus === 'fulfilled' && enqueueSnackbar(`${items.length} cohorts have been deleted`,
          { variant: 'success' as VariantType });
        result.meta.requestStatus === 'rejected' && setError(true);
        return;
      }

      result.meta.requestStatus === 'fulfilled' && enqueueSnackbar(`${items.title} cohort has been deleted`,
        { variant: 'success' as VariantType });
      result.meta.requestStatus === 'rejected' && enqueueSnackbar('Deleting error, try again later',
        { variant: 'error' as VariantType });
      onClose();
    } catch (e) {
      if (isArray) {
        setError(true);
        return;
      }
      enqueueSnackbar('Deleting error, try again later', { variant: 'error' as VariantType });
      onClose();
    }
  }

  return (
    <div className='deleteCohortsModal'>
      <div className="deleteCohortsModal-header">
        {title}
      </div>
      <div className={`deleteCohortsModal-content ${setScroll && 'deleteCohortsModal-content_scroll'}`}>
        {content}
      </div>
      <div className="deleteCohortsModal-footer">
        {error && isArray && <Alert severity="error">Deleting error, try again later</Alert>}
        <div className="deleteCohortsModal-footer-buttons">
          <Button
            onClick={onClose}
            variant="text"
            color="primary"
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="error"
            loading={status === 'loading' && isArray}
            onClick={handleDelete}>
              Delete cohort
          </LoadingButton>
        </div>
      </div>
    </div>
  )
};

export default DeleteCohortsModal;
