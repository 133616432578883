import { IUserStatus, UserStatuses } from './interface';

export const possibleUserStatuses: IUserStatus[] = [
  {
    status: UserStatuses.ACTIVE,
    title: 'Active',
    color: 'success',
  },
  {
    status: UserStatuses.BANNED,
    title: 'Banned',
    color: 'inherit',
  },
];
