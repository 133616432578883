import { ConsumptionRequestStatus } from 'store/slices/ConsumptionRequests/interface';
import { ISortingItem } from 'types/commonTypes';

export const consumptionRequestOpenSortingModel: ISortingItem = {
  columnId: 'created_at',
  title: 'Create date',
  sortingType: 'ASC',
};

export const consumptionRequestClosedSortingModel: ISortingItem = {
  columnId: 'closing_date',
  title: 'Date Of Close',
  sortingType: 'DESC',
};

export const enum ConsumptionRequestListType {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export const OPEN_FILTER = {
  type: 'EQUALS',
  valueType: 'STRING',
  customImplementation: false,
  fromArray: false,
  columnId: 'consumption_request_status',
  element: {
    value: ConsumptionRequestStatus.OPEN,
  },
  getValuesImmediately: false,
  required: false,
};

export const CLOSED_FILTER = {
  type: 'EQUALS',
  valueType: 'STRING',
  customImplementation: false,
  fromArray: false,
  columnId: 'consumption_request_status',
  element: {
    value: ConsumptionRequestStatus.CLOSED,
  },
  getValuesImmediately: false,
  required: false,
};
