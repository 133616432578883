import { createSvgIcon } from '@mui/material';

const WorldSendIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.2014 21.267C11.069 21.1829 10.9328 21.0759 10.793 20.9429C10.3475 20.5189 9.90759 19.8652 9.51967 18.9924C8.82498 17.4294 8.35046 15.2839 8.25969 12.8596H22.3632C22.8326 12.8596 23.2134 12.479 23.2134 12.0095C23.2134 5.81642 18.1929 0.795898 11.9998 0.795898C5.80665 0.795898 0.786133 5.81642 0.786133 12.0095C0.786133 17.9167 5.35371 22.757 11.1498 23.1915C11.6179 23.2265 11.9998 22.8426 11.9998 22.3732C11.9998 21.9037 11.5976 21.5187 11.2014 21.267ZM2.7874 11.1596C2.69394 11.1596 2.604 11.1747 2.51988 11.2025C2.83118 7.49646 5.26692 4.39479 8.60522 3.11936C8.3723 3.49346 8.15921 3.90174 7.96619 4.33602C7.16033 6.14922 6.64983 8.5386 6.55859 11.1596H2.7874ZM10.793 3.07596C11.2341 2.65614 11.6404 2.4959 11.9998 2.4959C12.3592 2.4959 12.7657 2.65614 13.2068 3.07596C13.6523 3.49996 14.0922 4.15366 14.4802 5.02646C15.1749 6.58956 15.6494 8.73517 15.7401 11.1596H8.25968C8.35042 8.73517 8.82496 6.58956 9.51967 5.02646C9.90759 4.15366 10.3475 3.49996 10.793 3.07596ZM21.476 11.1596C21.1495 7.47264 18.7199 4.39008 15.3947 3.1195C15.6276 3.49356 15.8406 3.90179 16.0336 4.33602C16.8395 6.14922 17.35 8.5386 17.4412 11.1596H21.476ZM8.60538 20.8998C5.26701 19.6244 2.83121 16.5227 2.51988 12.8166C2.604 12.8445 2.69394 12.8596 2.7874 12.8596H6.55859C6.64987 15.4804 7.16035 17.8697 7.96619 19.6829C8.15925 20.1172 8.3724 20.5256 8.60538 20.8998Z" />
    <path d="M19.4599 14.5051C19.1286 14.1725 18.5904 14.1716 18.2578 14.5029C17.9253 14.8343 17.9243 15.3725 18.2557 15.705L19.9531 17.4085H12.3546C11.8851 17.4085 11.5046 17.789 11.5046 18.2585C11.5046 18.7279 11.8851 19.1085 12.3546 19.1085H19.9477L18.2579 20.7979C17.926 21.1298 17.9259 21.668 18.2578 22C18.5898 22.332 19.128 22.332 19.4599 22.0001L22.8481 18.6125C23.0431 18.4175 23.0434 18.1014 22.8487 17.906L19.4599 14.5051Z" />
  </svg>, 'WorldSend'
)

export default WorldSendIcon;
