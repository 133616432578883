import { ReactNode, useCallback, useState } from 'react';
import Navbar from 'components/Navbar/Navbar';
import { Typography } from '@mui/material';
import { useErrorBoundary } from 'react-error-boundary';
import './index.scss';

interface PageData {
  status: number;
  subtitle: string;
  img: string;
  descriptionTitle: string;
  descriptionInfo: ReactNode;
  descriptionContactString: string;
}

interface ErrorStatus {
  message: string;
  status: number;
}

export const ErrorBoundaryErrors = [
  'Request failed with status code 400',
  'Request failed with status code 403',
  'Request failed with status code 404',
  'Request failed with status code 500',
  'Request failed with status code 502',
  'Request failed with status code 503',
  'Request failed with status code 504',
];

export const ErrorPage = ({ error }: { error: ErrorStatus | string }) => {
  const { resetBoundary } = useErrorBoundary();
  const [isMenuOpen, setOpen] = useState<boolean>(false);
  const customStatusCode = error && (error as ErrorStatus)?.status && (error as ErrorStatus).status < 500 ? 400 : 500;

  const handleOpen = useCallback((value: boolean) => setOpen(value), []);
  
  const errorMessage = typeof error === 'string' ? error : error?.message;

  let pageData: PageData = {
    status: customStatusCode,
    subtitle: 'Bad request',
    img: '',
    descriptionTitle: 'Your client has issued a malformed or illegal request',
    descriptionInfo: <></>,
    descriptionContactString: 'If the problem persists contact your manager',
  };

  switch ((error as ErrorStatus)?.message ? (error as ErrorStatus)?.message : error) {
    case 'Request failed with status code 400': {
      pageData = {
        status: 400,
        subtitle: 'Bad request',
        descriptionTitle: 'Your client has issued a malformed or illegal request',
        descriptionInfo: (
          <>Try to double-check the address or refresh the page. Also you can choose other module from menu bar.</>
        ),
        descriptionContactString: 'If the problem persists contact your manager',
      } as PageData;
      break;
    }
    case 'Request failed with status code 403': {
      pageData = {
        status: 403,
        subtitle: 'Forbidden',
        descriptionTitle: 'You have insufficient right to access this page',
        descriptionInfo: <>Please, choose other module from menu bar.</>,
        descriptionContactString: 'If you think it’s a mistake, contact your manager',
      } as PageData;
      break;
    }
    case 'Request failed with status code 404': {
      pageData = {
        status: 404,
        subtitle: 'Not found',
        descriptionTitle: 'Page you have requested does not exist',
        descriptionInfo: (
          <>
            The error may be caused by typo in the link or page was deleted. <br />
            Try to double-check the address or choose other module from menu bar.
          </>
        ),
        descriptionContactString: 'If you think it’s a mistake, contact your manager ',
      } as PageData;
      break;
    }
    case 'Request failed with status code 500': {
      pageData = {
        status: 500,
        subtitle: 'Internal server error',
        descriptionTitle: 'We are currently trying to fix the issue',
        descriptionInfo: <>Perhaps you are the first who has detected this error.</>,
        descriptionContactString: 'So let your manager know about the issue',
      } as PageData;
      break;
    }
    case 'Request failed with status code 502': {
      pageData = {
        status: 502,
        subtitle: 'Bad gateway',
        descriptionTitle: 'We are currently trying to fix the issue',
        descriptionInfo: <>Perhaps you are the first who has detected this error.</>,
        descriptionContactString: 'So let your manager know about the issue',
      } as PageData;
      break;
    }
    case 'Request failed with status code 503': {
      pageData = {
        status: 503,
        subtitle: 'Service is unavailable',
        descriptionTitle: 'We are currently trying to fix the issue',
        descriptionInfo: <>Perhaps you are the first who has detected this error.</>,
        descriptionContactString: 'So let your manager know about the issue',
      } as PageData;
      break;
    }
    // case 'Request failed with status code 503': { todo ** to be completed when it becomes clear how the technical error will be implemented **
    //   pageData = {
    //     status: 503,
    //     subtitle: 'Server down for maintenance',
    //     descriptionTitle: 'We are going to finish all works at 15 pm (EST) ',
    //   } as PageData;
    //   break;
    // }
    case 'Request failed with status code 504': {
      pageData = {
        status: 504,
        subtitle: 'Gateway timeout',
        descriptionTitle: 'We are currently trying to fix the issue',
        descriptionInfo: <>Perhaps you are the first who has detected this error.</>,
        descriptionContactString: 'So let your manager know about the issue',
      } as PageData;
      break;
    }
  }

  return (
    <>
      <div className={`layout-container${isMenuOpen ? ' layout-container--blurred' : ''}`}>
        {pageData && pageData?.status < 500 && (
          <Navbar isMenuOpen={isMenuOpen} handleOpen={handleOpen} resetBoundary={resetBoundary} />
        )}
        <div style={{ width: '64px' }} />
        <div className="ErrorPage">
          <header className="ErrorPage-header">
            <Typography variant="errorTitle">Error {pageData.status}</Typography>
            <Typography variant="errorSubtitle" color="text.secondary">
              {pageData.subtitle}
            </Typography>
          </header>
          <div className="ErrorPage-content">
            <div className={`ErrorPage-content-image ErrorPage-content-image-${pageData.status}`} />
            <div className="ErrorPage-content-description">
              <Typography variant="h5" className="ErrorPage-content-descriptionTitle">
                {pageData.descriptionTitle}
              </Typography>
              <Typography variant="body1" color="text.secondary" className="ErrorPage-content-descriptionInfo">
                {pageData.descriptionInfo}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {pageData.descriptionContactString}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
