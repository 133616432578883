import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from "store/store";
import axios from "axios";
// import { bannerLinkedCollections } from "__mock__/world/bannerLinkedCollections";
import { Banner } from "store/bannerDetailsSlice";
import WorldTabs from "components/WorldManagement/types/WorldTabs";
import { ILocation, IStatus } from "types/commonTypes";
import { Cohort, fetchCohort, fetchEditCohort } from "store/cohortDetailsSlice";
import { ErrorMessages } from "store/types/ErrorMessages";
import { Collection, CollectionStatus } from "store/collectionDetailsSlice";
// import { cohortsData } from "__mock__/world/cohortsData";
// import { bannersData } from "__mock__/world/bannersData";
// import { collectionsData, defaultCollectionsData } from "__mock__/world/collectionsData";

export type CohortsTableData = Pick<Cohort, 'id' | 'title' | 'status' | 'createdAt' | 'creatorName' | 'creatorLastName' | 'hasCompilation'>;

export interface CohortsLinkedCollection {
  cohort: Pick<Cohort, 'id' | 'title'>,
  compilation: Pick<Collection, 'id' | 'title'>,
}

export interface LinkedCollections {
  id: string;
  title: string;
  cohortTitle: string;
  status: CollectionStatus;
  creatorName?: string;
  creatorLastName?: string;
  totalBanners: number;
  totalHeroes: number;
  totalOffers: number;
  totalWishes: number;
  totalEvents: number;
  entityIncluded: boolean;
}

const collectionsColumns = [
  {
    columnId: 'name',
    columnName: "Name",
    flex: 2,
    sortable: false,
  },
  {
    columnId: 'status',
    columnName: "Status",
    flex: 1,
    sortable: false,
  },
  {
    columnId: 'publicationDate',
    columnName: "Publication date",
    width: 232,
    flex: 2,
    sortable: false,
  },
  {
    columnId: 'cohort',
    columnName: "Cohort",
    flex: 2,
    sortable: false,
  },
  {
    columnId: 'updatedAt',
    columnName: "Updated",
    flex: 1,
    sortable: true,
  },
  {
    columnId: 'action',
    columnName: "",
    width: 64,
    flex: 0,
    sortable: false,
  },
];

const bannersColumns = [
  {
    columnId: 'name',
    columnName: "Name",
    width: 263,
    flex: 2,
    sortable: true,
    headerClassName: 'headerWithSpace',
  },
  {
    columnId: 'status',
    columnName: "Status",
    width: 148,
    flex: 1,
    sortable: false,
  },
  {
    columnId: 'expiredDate',
    columnName: "Validity date",
    width: 232,
    flex: 1,
    sortable: false,
  },
  {
    columnId: 'updated',
    columnName: "Updated",
    width: 130,
    flex: 1,
    sortable: false,
  },
  {
    columnId: 'action',
    columnName: "",
    width: 64,
    flex: 0,
    sortable: false,
  },
];

const cohortsColumns = [
  {
    columnId: 'name',
    columnName: "Name",
    width: 416,
    flex: 2,
    sortable: true,
  },
  {
    columnId: 'status',
    columnName: "Status",
    width: 466,
    flex: 2,
    sortable: false,
  },
  {
    columnId: 'linkedCollection',
    columnName: "Linked collection",
    width: 466,
    flex: 2,
    sortable: false,
  },
  {
    columnId: 'creationDate',
    columnName: "Creation date",
    width: 232,
    flex: 1,
    sortable: false,
  },
  {
    columnId: 'action',
    columnName: "",
    width: 64,
    flex: 0,
    sortable: false,
  },
];

const linkedCollectionsColumns = [
  {
    columnId: 'name',
    flex: 1,
  },
  {
    columnId: 'cohort',
    flex: 1,
  },
  {
    columnId: 'entities',
    flex: 1,
  },
];

export interface WorldState {
  columns: {
    collections: any[];
    banners: any[];
    cohorts: any[];
    archive: any[];
  };
  data: {
    collections: Collection[] | [];
    defaultCollection: Collection | null;
    banners: Banner[] | [];
    cohorts: CohortsTableData[] | [];
    archive: any[];
  };
  allCohortsList: Cohort[] | [];
  linkedCollectionsColumns: any[];
  linkedCollections: LinkedCollections[];
  cohortsLinkedCollections: CohortsLinkedCollection[] | [];
  locations: ILocation[];
  filters: [],
  totalCount: {
    collectionElements: number,
    bannerElements: number,
    cohortElements: number,
  },
  errorStatusText: ErrorMessages | '';
  status: IStatus;
  archiveBannerStatus: IStatus;
  expireBannerStatus: IStatus;
}

const initialState: WorldState = {
  columns: {
    collections: collectionsColumns,
    banners: bannersColumns,
    cohorts: cohortsColumns,
    archive: [],
  },
  data: {
    collections: [],
    defaultCollection: null,
    banners: [],
    cohorts: [],
    archive: [],
  },
  allCohortsList: [],
  linkedCollectionsColumns: linkedCollectionsColumns,
  linkedCollections: [],
  cohortsLinkedCollections: [],
  locations: [],
  filters: [],
  totalCount: {
    collectionElements: 0,
    bannerElements: 0,
    cohortElements: 0,
  },
  errorStatusText: '',
  status: 'idle',
  archiveBannerStatus: 'idle',
  expireBannerStatus: 'idle',
};

export const fetchWorld = createAsyncThunk(
  'world-management/fetchDataWorld',
  async ({ page, pageSize, dataType }: { page: number, pageSize: number, dataType: WorldTabs }, thunkAPI) => {
    let dataUrl;

    const rootState: RootState = thunkAPI.getState() as RootState;
    const filters = Object.keys(rootState.filters.data) ? Object.values(rootState.filters.data).filter((item: any) => item !== null) : [];
    const sortingList = Object.keys(rootState.sorting.data) ? Object.values(rootState.sorting.data).filter((item: any) => {
      return item !== null && item.columnId !== null && item.sortingType !== null
    }) : [];

    switch (dataType) {
      case WorldTabs.COLLECTIONS: {
        dataUrl = 'timworld/compilations/find';
        break;
      }
      case WorldTabs.BANNERS: {
        dataUrl = 'timworld/banners/find';
        break;
      }
      case WorldTabs.COHORTS: {
        dataUrl = 'timworld/cohorts/find';
        break;
      }
      case WorldTabs.ARCHIVE: {
        dataUrl = 'timworld/archive';
        break;
      }
    }

    const response = await axios.post((process.env.REACT_APP_WORLD_MANAGEMENT_ENDPOINT as string) + dataUrl, {
      pageInfo: {
        page: page,
        size: pageSize
      },
      archived: dataType === WorldTabs.ARCHIVE,
      title: null,
      creatorId: null,
      statuses: null,
      sortingList: sortingList,
      filters: filters,
    });
    return {...response.data, dataType};
  }
);

export const fetchDefaultCollection = createAsyncThunk(
  'world-management/fetchDefaultCollection',
  async () => {
    const response = await axios.get((process.env.REACT_APP_WORLD_MANAGEMENT_ENDPOINT as string) + 'timworld/compilations/default');
    return {...response.data};
  }
);

export const fetchLinkedCollections = createAsyncThunk(
  'world-management/fetchLinkedCollection',
  async ({ entityId, entityType }: { entityId: string, entityType: 'BANNER' | 'HERO' | 'OFFER' | 'WISH' | 'EVENT'}) => {
    const response = await axios.get((process.env.REACT_APP_WORLD_MANAGEMENT_ENDPOINT as string) + 'timworld/compilations',
      {
        params: {
          entityId,
          entityType,
        },
      }
      );
    return response.data;
  }
);

export const fetchAddEntityToTimWorld = createAsyncThunk(
  'world-management/addToTimWorld',
  async ({ entityId, entityType, compilationIds }:
     { entityId: string, entityType: 'BANNER' | 'HERO' | 'OFFER' | 'WISH' | 'EVENT', compilationIds: string[] }
  ) => {
    const response = await axios.post((process.env.REACT_APP_WORLD_MANAGEMENT_ENDPOINT as string) + 'timworld/compilations/elements/add',
      {
        entityType: entityType,
        elementId: entityId,
        compilationIds: compilationIds,
      },
    );
    return { entityId, entityType, compilationIds };
  }
);

export const fetchArchiveCollections = createAsyncThunk(
  'world-management/archiveCollections',
  async (compilationIds: string[]) => {
   await axios.post((process.env.REACT_APP_WORLD_MANAGEMENT_ENDPOINT as string) + 'timworld/compilations/archive', {
     compilationIds: [...compilationIds]
   });
   return compilationIds;
  }
);

export const fetchRestoreCollections = createAsyncThunk(
  'world-management/restoreCollections',
  async (compilationIds: string[]) => {
    await axios.post((process.env.REACT_APP_WORLD_MANAGEMENT_ENDPOINT as string) + 'timworld/compilations/restore', {
      compilationIds: [...compilationIds]
    });
    return compilationIds;
  }
);

export const fetchUnpublishCollection = createAsyncThunk(
  'world-management/unpublishCollection',
  async ({ compilationId, deactivatedDateTime }: { compilationId: string, deactivatedDateTime: string }) => {
    await axios.post((process.env.REACT_APP_WORLD_MANAGEMENT_ENDPOINT as string) + 'timworld/compilations/status', {
      compilationIds: [compilationId],
      deactivatedDateTime: deactivatedDateTime,
      status: 'DEACTIVATED',
    });
    return {compilationId, deactivatedDateTime};
  }
);

export const fetchPublishCollections = createAsyncThunk(
  'world-management/publishCollection',
  async ({
           compilationIds, publishDateTime, deactivatedDateTime
  }: { compilationIds: string[], publishDateTime: string, deactivatedDateTime?: string }) => {
    await axios.post((process.env.REACT_APP_WORLD_MANAGEMENT_ENDPOINT as string) + 'timworld/compilations/status', {
      compilationIds: [...compilationIds],
      publishDateTime: publishDateTime,
      deactivatedDateTime: deactivatedDateTime ?? null,
      status: 'LIVE',
    });
    return {compilationIds, publishDateTime, deactivatedDateTime};
  }
);

export const fetchAddCollection = createAsyncThunk(
  'world-management/addCollection',
  async ({ title, cohortId }: { title: string, cohortId: string }) => {
    const response = await axios.post((process.env.REACT_APP_WORLD_MANAGEMENT_ENDPOINT as string) + 'timworld/compilations', {
      title: title,
      cohortId: cohortId,
    }).catch((error: unknown) => {
      if (axios.isAxiosError(error)) {
        throw Error(error.response?.data.techInfo.title);
      } else {
        throw Error('An unexpected error occurred');
      }}
    );

    return response.data;
  }
);

export const fetchAllCohorts = createAsyncThunk(
  'world-management/fetchAllCohorts',
  async () => {
    const response = await axios.get((process.env.REACT_APP_WORLD_MANAGEMENT_ENDPOINT as string) + 'timworld/cohorts');
    return response.data;
  }
);

export const fetchAddCohort = createAsyncThunk(
  'world-management/addCohort',
  async ({ title, minAge, maxAge, genders, locations }: Pick<Cohort, 'title' | 'minAge' | 'maxAge' | 'genders' | 'locations'>) => {
    const response = await axios.post((process.env.REACT_APP_WORLD_MANAGEMENT_ENDPOINT as string) + 'timworld/cohorts', {
      title,
      minAge,
      maxAge,
      genderIds: genders?.map((gender) => gender.id),
      locations,
    }).catch((error: unknown) => {
      if (axios.isAxiosError(error)) {
        throw Error(error.response?.data.techInfo.title);
      } else {
        throw Error('An unexpected error occurred');
      }}
    );

    return response.data;
  }
);

export const fetchDeleteCohorts = createAsyncThunk(
  'world-management/deleteCohorts',
  async (cohortIds: string[]) => {
    await axios.delete((process.env.REACT_APP_WORLD_MANAGEMENT_ENDPOINT as string) + 'timworld/cohorts', {
      data: {
        cohortIds: [...cohortIds]
      }
    });
    return cohortIds;
  }
);

export const fetchCohortsLinkedCollections = createAsyncThunk(
  'world-management/cohortsLinkedCollections',
  async () => {
    const response = await axios.get((process.env.REACT_APP_WORLD_MANAGEMENT_ENDPOINT as string) + 'timworld/cohorts/compilations/live');
    return response.data;
  }
);

export const fetchLocations = createAsyncThunk(
  'world-management/fetchLocations',
  async ({ text }: { text: string }) => {
    const response = await axios.get((process.env.REACT_APP_WORLD_MANAGEMENT_ENDPOINT as string) + 'timworld/common/location/reverse', {
      params: {
        text: text
      }
    });
    return {...response.data};
  }
);

export const fetchAddBanner = createAsyncThunk(
  'world-management/addBanner',
  async ({ cover, coverPreview, name, title, subtitle }:
           { cover: File, coverPreview: File, name: string, title?: string, subtitle?: string }
  ) => {
    const body = new FormData();
    body.append('cover', cover);
    body.append('coverPreview', coverPreview);
    body.append('request', new Blob([`{ "name": "${name}", "title": "${title}", "subtitle": "${subtitle}" }`], {type: 'application/json'}));

    const response = await axios.post((process.env.REACT_APP_WORLD_MANAGEMENT_ENDPOINT as string) + 'timworld/banners',
      body,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    return response.data;
  }
);

export const fetchArchiveBanner = createAsyncThunk(
  'world-management/archiveBanner',
  async (bannerIds: string[]) => {
    const response = await axios.post((process.env.REACT_APP_WORLD_MANAGEMENT_ENDPOINT as string) + 'timworld/banners/archive',
      {
        bannerIds: bannerIds
      });
    return response.data;
  }
);

export const fetchExpireBanner = createAsyncThunk(
  'world-management/expireBanner',
  async (bannerIds: string[]) => {
    const response = await axios.post((process.env.REACT_APP_WORLD_MANAGEMENT_ENDPOINT as string) + 'timworld/banners/expire',
      {
        bannerIds: bannerIds
      });
    return response.data;
  }
);

export const worldManagementSlice = createSlice({
  name: 'world',
  initialState,
  reducers: {
    clearErrorMessage: (state) => {
      state.errorStatusText = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorld.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWorld.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        switch (payload.dataType) {
          case WorldTabs.COLLECTIONS:
            state.data.collections = payload.content;
            state.totalCount.collectionElements = payload.totalElements;
            break;
          case WorldTabs.BANNERS:
            state.data.banners = payload.content;
            state.totalCount.bannerElements = payload.totalElements;
            break;
          case WorldTabs.COHORTS:
            state.data.cohorts = payload.content;
            state.totalCount.cohortElements = payload.totalElements;
            break;
        }
      })
      .addCase(fetchWorld.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchDefaultCollection.fulfilled, (state, { payload }) => {
        state.data = {...state.data, defaultCollection: payload};
      })
      .addCase(fetchLinkedCollections.fulfilled, (state, { payload }) => {
        state.linkedCollections = payload;
      })
      .addCase(fetchAddEntityToTimWorld.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAddEntityToTimWorld.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(fetchAddEntityToTimWorld.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchArchiveCollections.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchArchiveCollections.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.data.collections = state.data.collections.filter((item) => !payload.includes(item.id))
      })
      .addCase(fetchArchiveCollections.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchAddCollection.fulfilled, (state, { payload }) => {
        state.data.collections = [...state.data.collections, payload];
        state.errorStatusText = '';
      })
      .addCase(fetchAddCollection.rejected, (state, { error }) => {
        state.errorStatusText = (error.message ?? '') as ErrorMessages;
      })
      .addCase(fetchAddBanner.fulfilled, (state, { payload }) => {
        state.data.banners = [...state.data.banners, payload];
      })
      .addCase(fetchAddCohort.fulfilled, (state, { payload }) => {
        state.data.cohorts = [...state.data.cohorts, payload];
        state.errorStatusText = '';
      })
      .addCase(fetchCohort.fulfilled, (state) => {
        state.errorStatusText = ''
      })
      .addCase(fetchAddCohort.rejected, (state, { error }) => {
        state.errorStatusText = (error.message ?? '') as ErrorMessages;
      })
      .addCase(fetchEditCohort.fulfilled, (state, { payload }) => {
        const elementIdx = state.data.cohorts.findIndex((cohort) => cohort.id === payload.id);
        state.data.cohorts = [
          ...state.data.cohorts.slice(0, elementIdx),
          {...state.data.cohorts[elementIdx], title: payload.title},
          ...state.data.cohorts.slice(elementIdx + 1),
        ]
        state.errorStatusText = '';
      })
      .addCase(fetchEditCohort.rejected, (state, { error }) => {
        state.errorStatusText = (error.message ?? '') as ErrorMessages;
      })
      .addCase(fetchPublishCollections.fulfilled, (state, { payload }) => {
        state.data.collections = state.data.collections.map((collection) => {
          if (payload.compilationIds.includes(collection.id)) {
            return {
              ...collection,
              status: 'LIVE',
              publishDateTime: new Date(payload.publishDateTime),
              expiredDateTime: payload.deactivatedDateTime ? new Date(payload.deactivatedDateTime) : null,
            }
          }
          return collection;
        });
      })
      .addCase(fetchUnpublishCollection.fulfilled, (state, { payload }) => {
        const elementIdx = state.data.collections.findIndex((collection) => collection.id === payload.compilationId);
        state.data.collections = [
          ...state.data.collections.slice(0, elementIdx),
          {
            ...state.data.collections[elementIdx],
            expiredDateTime: new Date(payload.deactivatedDateTime),
            status: 'DEACTIVATED',
          },
          ...state.data.collections.slice(elementIdx + 1),
        ]
      })
      .addCase(fetchDeleteCohorts.fulfilled, (state, { payload }) => {
        state.data.cohorts = state.data.cohorts.filter((item) => !payload.includes(item.id))
      })
      .addCase(fetchAllCohorts.fulfilled, (state, { payload }) => {
        state.allCohortsList = payload;
      })
      .addCase(fetchCohortsLinkedCollections.fulfilled, (state, { payload }) => {
        state.cohortsLinkedCollections = payload;
      })
      .addCase(fetchLocations.fulfilled, (state, { payload }) => {
        state.locations = payload.content;
      })
      .addCase(fetchArchiveBanner.pending, (state, payload) => {
        state.archiveBannerStatus = 'loading';
      })
      .addCase(fetchArchiveBanner.fulfilled, (state, payload) => {
        state.archiveBannerStatus = 'idle';
      })
      .addCase(fetchArchiveBanner.rejected, (state, payload) => {
        state.archiveBannerStatus = 'idle';
      })
      .addCase(fetchExpireBanner.pending, (state, payload) => {
        state.expireBannerStatus = 'loading';
      })
      .addCase(fetchExpireBanner.fulfilled, (state, payload) => {
        state.expireBannerStatus = 'idle';
      })
      .addCase(fetchExpireBanner.rejected, (state, payload) => {
        state.expireBannerStatus = 'idle';
      })
  },
});

export const selectWorldStatus = (state: RootState): IStatus => state.worldManagement.status;
export const selectWorld = (state: RootState): any => state.worldManagement.data;
export const selectTotalCount = (state: RootState): any => state.worldManagement.totalCount;
export const selectWorldColumns = (state: RootState): any => state.worldManagement.columns;
export const selectLocations = (state: RootState): ILocation[] => state.worldManagement.locations;
export const selectErrorText = (state: RootState): string => state.worldManagement.errorStatusText;
export const selectAllCohorts = (state: RootState): Cohort[] => state.worldManagement.allCohortsList;
export const selectLinkedCollections = (state: RootState): LinkedCollections[] | [] => state.worldManagement.linkedCollections;
export const selectLinkedCollectionsColumns = (state: RootState): any[] => state.worldManagement.linkedCollectionsColumns;
export const selectCohortsLinkedCollections = (state: RootState): CohortsLinkedCollection[] | [] => state.worldManagement.cohortsLinkedCollections;

export const { clearErrorMessage } = worldManagementSlice.actions;
export default worldManagementSlice.reducer;
