import { ReactNode } from 'react';
import colors from 'theme/constants/colors';
import ListItem from 'components/shared/List/ListItem/ListItem';
import ListItemIcon from 'components/shared/List/ListItem/ListItemComponents/ListItemIcon/ListItemIcon';
import ListItemText from 'components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import Typography from 'components/shared/Typography/Typography';
import './index.scss';

interface IContentDisplayInfoBlockProps {
  icon: ReactNode;
  secondary: string;
  primary: string | null;
  withTooltip?: boolean;
}

const ContentDisplayInfoBlock = ({ withTooltip, icon, primary, secondary }: IContentDisplayInfoBlockProps) => (
  <ListItem disablePadding className="info-block__container">
    {icon && <ListItemIcon className="info-block__icon">{icon}</ListItemIcon>}
    <ListItemText
      className="info-block__text"
      primary={
        <Tooltip followCursor placement="top-end" title={withTooltip ? primary : ''}>
          <Typography
            component="div"
            variant="body2"
            color={primary === 'Not specified' ? colors.outlinedBorder : 'text.primary'}
            className={withTooltip ? 'info-block__text-primary--ellipsis' : 'info-block__text-primary'}
          >
            {primary}
          </Typography>
        </Tooltip>
      }
      secondary={
        <Tooltip followCursor placement="top-end" title={withTooltip ? secondary : ''}>
          <Typography
            component="div"
            variant="body2"
            color={secondary === 'Not specified' ? colors.outlinedBorder : 'text.secondary'}
            className={withTooltip ? 'info-block__text-secondary--ellipsis' : 'info-block__text-secondary'}
          >
            {secondary}
          </Typography>
        </Tooltip>
      }
    />
  </ListItem>
);

export default ContentDisplayInfoBlock;
