import { createSvgIcon } from '@mui/material';

const LocationIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.57861 9.2144C8.57861 7.32479 10.1104 5.79297 12 5.79297C13.8896 5.79297 15.4215 7.32479 15.4215 9.2144C15.4215 11.104 13.8896 12.6358 12 12.6358C10.1104 12.6358 8.57861 11.104 8.57861 9.2144ZM12 7.49297C11.0493 7.49297 10.2786 8.26368 10.2786 9.2144C10.2786 10.1651 11.0493 10.9358 12 10.9358C12.9508 10.9358 13.7215 10.1651 13.7215 9.2144C13.7215 8.26368 12.9508 7.49297 12 7.49297Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.1499 9.52399C5.1499 5.4939 7.93104 2.00732 11.9999 2.00732C16.0688 2.00732 18.8499 5.4939 18.8499 9.52399C18.8499 12.2085 17.6029 14.4842 16.1982 16.0673C15.4931 16.862 14.7306 17.503 14.0273 17.9516C13.3635 18.375 12.628 18.7073 11.9999 18.7073C11.3718 18.7073 10.6363 18.375 9.97248 17.9516C9.2692 17.503 8.50672 16.862 7.80161 16.0673C6.39688 14.4842 5.1499 12.2085 5.1499 9.52399ZM11.9999 3.70732C9.06877 3.70732 6.8499 6.22075 6.8499 9.52399C6.8499 11.6728 7.85293 13.5638 9.07319 14.939C9.68059 15.6235 10.3244 16.1596 10.8867 16.5183C11.4885 16.9022 11.878 17.0073 11.9999 17.0073C12.1218 17.0073 12.5113 16.9022 13.1131 16.5183C13.6754 16.1596 14.3192 15.6235 14.9266 14.939C16.1469 13.5638 17.1499 11.6728 17.1499 9.52399C17.1499 6.22075 14.931 3.70732 11.9999 3.70732Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M6.42819 19.7073C6.32688 19.7073 6.2469 19.723 6.18439 19.745C6.43756 19.8723 6.8255 20.0079 7.35158 20.1318C8.51277 20.4052 10.1582 20.5788 11.9996 20.5788C13.841 20.5788 15.4865 20.4052 16.6477 20.1318C17.1737 20.0079 17.5617 19.8723 17.8149 19.745C17.7524 19.723 17.6724 19.7073 17.5711 19.7073C17.1016 19.7073 16.7211 19.3268 16.7211 18.8573C16.7211 18.3879 17.1016 18.0073 17.5711 18.0073C18.21 18.0073 18.7335 18.2358 19.1034 18.568C19.4407 18.871 19.7068 19.3195 19.7068 19.7954C19.7068 20.5284 19.1184 20.9641 18.7335 21.1813C18.2847 21.4346 17.6927 21.6322 17.0373 21.7865C15.7128 22.0984 13.9297 22.2788 11.9996 22.2788C10.0696 22.2788 8.28647 22.0984 6.96195 21.7865C6.30652 21.6322 5.71457 21.4346 5.26578 21.1813C4.88083 20.9641 4.29248 20.5284 4.29248 19.7954C4.29248 19.3195 4.5585 18.871 4.89588 18.568C5.2657 18.2358 5.78928 18.0073 6.42819 18.0073C6.89764 18.0073 7.27819 18.3879 7.27819 18.8573C7.27819 19.3268 6.89764 19.7073 6.42819 19.7073ZM18.0231 19.9012C18.0231 19.9012 18.0233 19.9015 18.0231 19.9012V19.9012ZM18.0612 19.5904C18.0669 19.5826 18.0709 19.5788 18.0715 19.5789C18.072 19.579 18.0692 19.5829 18.0612 19.5904ZM5.93804 19.5904C5.93008 19.5829 5.92721 19.579 5.92776 19.5789C5.92832 19.5788 5.9323 19.5826 5.93804 19.5904Z" />
  </svg>,
  'Location',
);

export default LocationIcon;
