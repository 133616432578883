import { useCallback, useMemo } from 'react';
import { VariantType, enqueueSnackbar } from 'notistack';
import { IMediaItem, Permissions } from 'types/commonTypes';
import { checkPermissions } from 'utils/checkPermissions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchUpdateContentStatus } from 'store/contentDisplaySlice';
import { selectUserPermissions } from 'store/authSlice';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import colors from 'theme/constants/colors';
import useExpandableDescription from 'hooks/useExpandableDescription';
import CalendarIcon from 'assets/img/CalendarIcon';
import ChevronDownIcon from 'assets/img/ChevronDownIcon';
import ChevronUpIcon from 'assets/img/ChevronUpIcon';
import CloseIcon from 'assets/img/CloseIcon';
import CommentIcon from 'assets/img/CommentIcon';
import EyeClosedFilledIcon from 'assets/img/EyeClosedFilledIcon';
import EyeClosedIcon from 'assets/img/EyeClosedIcon';
import LikeIcon from 'assets/img/LikeIcon';
import LocationIcon from 'assets/img/LocationIcon';
import MoneyIcon from 'assets/img/MoneyIcon';
import StarFilledIcon from 'assets/img/StarFilledIcon';
import StarIcon from 'assets/img/StarIcon';
import Users3Icon from 'assets/img/Users3Icon';
import WorldSendIcon from 'assets/img/WorldSendIcon';
import AvatarItem from 'components/shared/AvatarItem/AvatarItem';
import Button from 'components/shared/Button/Button';
import Chip from 'components/shared/Chip/Chip';
import IconButton from 'components/shared/IconButton/IconButton';
import ContentDisplayInfoBlock from 'components/ContentDisplay/ContentDisplayInfoBlock/ContentDisplayInfoBlock';
import ToggleButton from 'components/shared/ToggleButton/ToggleButton';
import ToggleButtonGroup from 'components/shared/ToggleButtonGroup/ToggleButtonGroup';
import Typography from 'components/shared/Typography/Typography';
import EventCard from './EventCard';
import './index.scss';

const Event = (props: any) => {
  const data = props.data;
  const publishDate = new Date(data.publishedAt);
  const userInfo = data.userInfoDto ?? {};
  const dispatch = useAppDispatch();
  const userPermissions = useAppSelector(selectUserPermissions);
  const showTopSearchSelectionButton = checkPermissions(userPermissions, [
    Permissions.WORLD_MANAGEMENT_WORLD_SELECTION,
  ]);
  const showMarkHideButtonGroup = checkPermissions(userPermissions, [
    Permissions.CONTENT_DISPLAY_MARK_HIDE_PUBLICATIONS,
  ]);
  const { isExpanded, toggleExpansion, renderedDescription } = useExpandableDescription(data.description);

  const setContentStatus = useCallback(async (entityId: string, recommenderStatus: string | null, status: string) => {
    const result = await dispatch(
      fetchUpdateContentStatus({
        entityType: 'EVENT',
        entityId: entityId,
        status: recommenderStatus !== status ? status : null,
      }),
    );
    if (result.meta.requestStatus === 'fulfilled') {
      enqueueSnackbar(
        `Event #${data.entityId} has been ${recommenderStatus === status ? 'un' : ''}${status.toLowerCase()}`,
        {
          variant: 'success' as VariantType,
        },
      );
    } else if (result.meta.requestStatus === 'rejected') {
      enqueueSnackbar(
        `Event #${data.entityId} hasn't been ${
          recommenderStatus === status ? 'un' : ''
        }${status.toLowerCase()}, try again later`,
        {
          variant: 'error' as VariantType,
        },
      );
    }
  }, []);

  const locationHeader = useMemo(() => {
    if (data.locationInfo?.locationFullAddress) return data.locationInfo.locationFullAddress;
    if (data.locationInfo?.locationCountry && data.locationInfo?.locationCity)
      return `${data.locationInfo.locationCity}, ${data.locationInfo.locationCountry}`;
    if (data.locationInfo?.locationCountry) return `${data.locationInfo.locationCountry}`;
    return 'Location';
  }, [data.locationInfo]);

  const locationText = useMemo(() => {
    if (
      data.locationInfo &&
      data.locationInfo?.locationFullAddress &&
      data.locationInfo?.locationCountry &&
      data.locationInfo?.locationCity
    )
      return `${data.locationInfo.locationCity}, ${data.locationInfo.locationCountry}`;
    return 'Not specified';
  }, [data.locationInfo]);

  return (
    <div className={'event--container display--cellContainer' + (props.modalClose ? ' closeable' : '')}>
      <div className="event--containerHeader">
        <div className="event--eventName">
          <div className="event--eventId">Event #{data.entityId}</div>
          <div className="event--eventPublishDate">
            Published <span>{format(publishDate, 'MM/dd/yyyy, hh:mm a')}</span>
          </div>
        </div>
        {props.modalClose ? (
          <div className="event--eventActions">
            <IconButton sx={{ margin: '-4px -12px 0 0' }} size="small" onClick={() => props.modalClose()}>
              <CloseIcon />
            </IconButton>
          </div>
        ) : (
          <div className="event--eventActions">
            {showTopSearchSelectionButton && (
              <Button
                disabled
                size="small"
                variant="outlined"
                startIcon={<WorldSendIcon />}
                onClick={() => props.linkCollections({ entityId: data.entityId, entityType: data.entityType })}
              >
                Top Search
              </Button>
            )}
            {showMarkHideButtonGroup && (
              <ToggleButtonGroup className="event--actionButtonsGroup" exclusive value={data.recommenderStatus}>
                <ToggleButton
                  value="STARRED"
                  size="small"
                  className="event--actionButton starred"
                  onClick={(e, value) => setContentStatus(data.entityId, data.recommenderStatus, value)}
                >
                  {data.recommenderStatus === 'STARRED' ? <StarFilledIcon /> : <StarIcon />}
                </ToggleButton>
                <ToggleButton
                  value="HIDDEN"
                  size="small"
                  className="event--actionButton hidden"
                  onClick={(e, value) => setContentStatus(data.entityId, data.recommenderStatus, value)}
                >
                  {data.recommenderStatus === 'HIDDEN' ? <EyeClosedFilledIcon /> : <EyeClosedIcon />}
                </ToggleButton>
              </ToggleButtonGroup>
            )}
          </div>
        )}
      </div>
      <div className="event--content">
        <div className="eventContent--card">
          <EventCard
            data={data}
            showMedia={(
              mediaUrl: string,
              mainEntity: IMediaItem,
              mediaType?: string,
              orderedMediaList?: IMediaItem[],
            ) => {
              if (data.orderedMediaList) {
                props.showMedia(data.entityId, data.entityType, mediaUrl, mainEntity, orderedMediaList, mediaType);
              } else {
                props.showMedia(data.entityId, data.entityType, mediaUrl);
              }
            }}
          />
        </div>
        <div className="eventContent--descriptionContainer">
          <AvatarItem
            src={userInfo.avatarUrl}
            primaryText={userInfo.firstName + ' ' + userInfo.lastName}
            subText={`@${userInfo.username}`}
            onClick={() => props.openUser(userInfo.userId || '1')}
          />
          <div className="eventContent--title">{data.title}</div>
          <div className="eventContent--description">
            <Typography variant="body2" color="text.secondary" className="text" component="span">
              {renderedDescription}{' '}
            </Typography>
            {data.description && data.description.length > 250 && (
              <Typography
                component="span"
                className="trigger"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleExpansion();
                }}
              >
                <Typography component="span" variant="body2">
                  {isExpanded ? 'Collapse' : 'Expand'}
                </Typography>
                {isExpanded ? <ChevronUpIcon fontSize="small" /> : <ChevronDownIcon fontSize="small" />}
              </Typography>
            )}
          </div>
          <div className="eventContent--counters">
            <div>
              <LikeIcon /> {data.likesCounter}
            </div>
            <div>
              <CommentIcon /> {data.commentsCounter}
            </div>
          </div>
        </div>
        <div className="eventContent--passions">
          <div className="eventContent--passionsHeader">Passions</div>
          <div className="eventContent--passionsChips">
            {data.passions.length ? (
              data.passions.map((el: any, index: number) => (
                <Chip key={index} size="small" variant="filled" label={el} />
              ))
            ) : (
              <Typography variant="body2" color={colors.outlinedBorder}>
                Not specified
              </Typography>
            )}
          </div>
        </div>
        <div className="eventContent--terms">
          <ContentDisplayInfoBlock icon={<MoneyIcon />} secondary="Free" primary="$0" />
          {data.startedAt && (
            <ContentDisplayInfoBlock
              icon={<CalendarIcon />}
              secondary={data.finishedAt ? dayjs(data.finishedAt).format('MM/DD/YYYY, hh:mm A') : 'Start date'}
              primary={`${dayjs(data.startedAt).format('MM/DD/YYYY, hh:mm A')} ${data.finishedAt ? '–' : ''}`}
            />
          )}
          {data.locationInfo && (
            <ContentDisplayInfoBlock
              withTooltip
              icon={<LocationIcon />}
              secondary={locationHeader}
              primary={locationText}
            />
          )}
          {data.participantsCounter > 0 && (
            <ContentDisplayInfoBlock
              icon={<Users3Icon />}
              secondary="Participants"
              primary={data.participantsCounter}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Event;
