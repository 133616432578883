import * as yup from "yup";

const getValidationSchema = (action: 'add' | 'edit') => {
  if (action === 'add') {
    return yup.object().shape({
      title: yup.string()
        .min(3, 'The name is too short. It should be as a minimum 3 symbols')
        .max(50, '').required('')
        .matches(
          /^(?:[a-zA-Z0-9_:,\. ]*)$/,
          'Invalid name. Only letters, numbers, underscores, dots, colons and commas are allowed'
        )
        .matches(
          /^[a-zA-Z0-9]/,
          'Please, start from letter or number'
        ),
      cohortId: yup.string().required(''),
    });
  }

  return yup.object().shape({
    title: yup.string()
      .required('Collection name is obligatory')
      .min(3, 'The name is too short. It should be as a minimum 3 symbols')
      .max(50, '')
      .matches(
        /^(?:[a-zA-Z0-9_:,\. ]*)$/,
        'Invalid name. Only letters, numbers, underscores, dots, colons and commas are allowed'
      )
      .matches(
        /^[a-zA-Z0-9]/,
        'Please, start from letter or number'
      ),
    cohortId: yup.string().required(''),
  });
}

export default getValidationSchema;