import { GridRenderCellParams } from '@mui/x-data-grid';
import colors from 'theme/constants/colors';
import { IOperation, OperationTypes, TransactionTypes } from 'store/slices/Payments/interface';
import InfoIcon from 'assets/img/InfoIcon';
import { currencyFormatter } from 'utils/currencyFormatter';
import IconButton from 'components/shared/IconButton/IconButton';
import Stack from 'components/shared/Stack/Stack';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import Typography from 'components/shared/Typography/Typography';

/**
 * Props for the RenderTransactionFeeCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 * @property {IOperation} rowData - Operation data.
 */
interface IRenderTransactionFeeCellProps {
  props: GridRenderCellParams;
  rowData: IOperation;
}

/**
 * Renders a "Fee" cell in a data grid (Transactions table).
 */
export const RenderTransactionFeeCell = ({ props, rowData }: IRenderTransactionFeeCellProps) => {
  const { row } = props;

  if (!row.id) {
    return <></>;
  }

  const renderFee = (amount: number, tooltipTitle: string) => {
    return amount ? (
      <Stack alignItems="center" justifyContent="flex-end" gap={1} direction="row">
        <Typography variant="body2">{currencyFormatter(amount, 'en-US', 'USD', true)}</Typography>
        <Tooltip arrow placement="right" title={tooltipTitle}>
          <IconButton size="small" disabled disableRipple sx={{ p: 0 }}>
            <InfoIcon sx={{ color: colors.actionDisabled, fontSize: 20 }} />
          </IconButton>
        </Tooltip>
      </Stack>
    ) : (
      <div>—</div>
    );
  };

  const isCardPaymentTransaction = row.type === TransactionTypes.CARD_PAYMENTS;
  const isTransferTransaction = row.type === TransactionTypes.TRANSFER;
  const isInAppTransaction = row.type === TransactionTypes.GOOGLE_IAP || row.type === TransactionTypes.APPLE_IAP;
  const isInAppOperation =
    rowData.operationType === OperationTypes.IAP_FOLLOW ||
    rowData.operationType === OperationTypes.IAP_DONATE ||
    rowData.operationType === OperationTypes.IAP_EVENT ||
    rowData.operationType === OperationTypes.IAP_LIFESTYLE;

  return (
    <Stack alignContent="end" textAlign="right">
      {isCardPaymentTransaction &&
        !isInAppTransaction &&
        renderFee(Number(rowData.platformFeeClient), 'Platform fee client')}
      {isCardPaymentTransaction && !isInAppTransaction && renderFee(Number(rowData.paymentFeeAmount), 'Provider fee')}
      {isTransferTransaction &&
        !isInAppOperation &&
        renderFee(Number(rowData.serviceFeeCreator), 'Service fee creator')}

      {isInAppOperation &&
        isTransferTransaction &&
        renderFee(Number(rowData.platformFeeCreator), 'Platform fee creator')}
      {isTransferTransaction &&
        isInAppOperation &&
        renderFee(Number(rowData.providerFeeCreator), 'Provider fee creator')}

      {!isTransferTransaction && !isCardPaymentTransaction && '—'}
    </Stack>
  );
};
