import { useMemo } from 'react';
import { currencyFormatter } from 'utils/currencyFormatter';
import AvatarItem from 'components/shared/AvatarItem/AvatarItem';
import StatusBlock from 'components/shared/StatusBlock/StatusBlock';
import './index.scss';

const WishCard = (props: any) => {
  const userInfo = props.data.userInfoDto ?? {};

  const wishTypeClass = useMemo(() => {
    const price = props.data.price;
    const priceOption = props.data.priceOption;
    if (priceOption === 'FLEXIBLE') return 'wish-flexible';
    if (price == 0) return 'wish-0';
    if (price < 51) return 'wish-50';
    if (price < 250) return 'wish-250';
    if (price < 500) return 'wish-500';

    return 'wish-max';
  }, [props.data.price, props.data.priceOption]);

  return (
    <div className={`wishCard--container ${wishTypeClass}`}>
      <div className="wishCard--description">
        <StatusBlock status={props.data.format} irl />
        <div>
          <div className="wishCard--title">{props.data.title}</div>
          <AvatarItem
            sizes="small"
            textColor="white"
            src={userInfo.avatarUrl}
            primaryText={userInfo.firstName + ' ' + userInfo.lastName}
            subText="Creator"
          />
        </div>
      </div>
      <div className="wishCard--priceContainer">
        <div className="wishCard--priceText">You'll get{props.data.priceOption === 'FIXED' ? '' : ' up to'}:</div>
        <div className="wishCard--priceValue">
          {Number(props.data.price) === 0 ? 'Free' : `${currencyFormatter(props.data.price, 'en-US', 'USD')}`}
        </div>
      </div>
    </div>
  );
};

export default WishCard;
