import Carousel from 'react-material-ui-carousel';
import colors from 'theme/constants/colors';
import ZoomInIcon from 'assets/img/ZoomInIcon';
import AvatarItem from 'components/shared/AvatarItem/AvatarItem';
import StatusBlock from 'components/shared/StatusBlock/StatusBlock';

const OfferCard = (props: any) => {
  const userInfo = props.data.userInfoDto ?? {};
  const widthEntity = 200;
  const heightEntity = 300;

  return props.data.orderedMediaList.length > 1 ? (
    <Carousel
      height={300}
      animation="slide"
      autoPlay={false}
      swipe={false}
      className="offerCard--Carousel"
      navButtonsAlwaysInvisible={true}
      indicatorIconButtonProps={{ className: 'carouselIcon', style: { color: colors.actionDisabled } }}
      activeIndicatorIconButtonProps={{ style: { color: colors.primary } }}
      indicatorContainerProps={{ style: { gap: '12px' } }}
    >
      {props.data.orderedMediaList.map((el: any, index: number) => (
        <div key={index} className="carouselEntity">
          <div
            className="overlay overlay--shifted"
            style={{ width: widthEntity, height: heightEntity }}
            onClick={() => {
              props.showMedia(
                el.mediaUrl ? el.mediaUrl : 'https://www.svgrepo.com/show/33921/sea-turtle.svg',
                el,
                el.mediaType === 'VIDEO' ? 'video' : 'Image',
                props.data.orderedMediaList ?? [],
              );
            }}
          >
            <ZoomInIcon />
            VIEW MEDIA
          </div>
          <div className="offerCard--container">
            {index === 0 && (
              <div className="offerCard--data">
                <div>
                  <div>
                    <StatusBlock status={props.data.format} irl />
                  </div>
                </div>
                <div>
                  <div className="offerCard--title">{props.data.title}</div>
                  <AvatarItem
                    sizes="small"
                    textColor="white"
                    src={userInfo.avatarUrl}
                    primaryText={userInfo.firstName + ' ' + userInfo.lastName}
                    subText="Creator"
                  />
                </div>
              </div>
            )}
            {index === 0 && (
              <div className="offerCard--price">
                <div className="offerCard--priceText">Price</div>
                <div className="offerCard--priceValue">
                  {props.priceOption.price === '$0' ? 'Free' : props.priceOption.cardPrice}
                </div>
              </div>
            )}
            <div className="offerCard--background">
              <img
                src={
                  el.mediaPreviewUrl ||
                  'https://images.rawpixel.com/image_png_1000/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTA4L3Jhd3BpeGVsX29mZmljZV8xMF9jbG9zZXVwX2ZveF9mYWNlX21pbmltYWxfc2ltcGxlX2NsZWFuX3dhdGVyY19mMWQ5YzRmMy1jMTJkLTRlYTYtOWJhMC1jMTc0ZGM5MmM4MTMucG5n.png'
                }
                alt="Offer media"
              />
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  ) : (
    <div className="carouselEntity">
      <div
        className="overlay"
        style={{ width: widthEntity, height: heightEntity }}
        onClick={() => {
          const mediaItem = props.data.orderedMediaList[0] || {
            mediaUrl: 'https://www.svgrepo.com/show/33921/sea-turtle.svg',
            mediaType: 'Image',
          };

          props.showMedia(
            mediaItem.mediaUrl,
            mediaItem,
            mediaItem.mediaType === 'VIDEO' ? 'video' : 'Image',
            props.data.orderedMediaList,
          );
        }}
      >
        <ZoomInIcon />
        VIEW MEDIA
      </div>
      <div className="offerCard--container">
        <div className="offerCard--data">
          <div>
            <div>
              <StatusBlock status={props.data.format} irl />
            </div>
          </div>
          <div>
            <div className="offerCard--title">{props.data.title}</div>
            <AvatarItem
              sizes="small"
              textColor="white"
              src={userInfo.avatarUrl}
              primaryText={userInfo.firstName + ' ' + userInfo.lastName}
              subText="Creator"
            />
          </div>
        </div>
        <div className="offerCard--price">
          <div className="offerCard--priceText">Price</div>
          <div className="offerCard--priceValue">
            {props.priceOption.price === '$0' ? 'Free' : props.priceOption.cardPrice}
          </div>
        </div>
        <img
          className="offerCard--background"
          src={
            props.data.orderedMediaList[0]?.mediaPreviewUrl ||
            'https://images.rawpixel.com/image_png_1000/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTA4L3Jhd3BpeGVsX29mZmljZV8xMF9jbG9zZXVwX2ZveF9mYWNlX21pbmltYWxfc2ltcGxlX2NsZWFuX3dhdGVyY19mMWQ5YzRmMy1jMTJkLTRlYTYtOWJhMC1jMTc0ZGM5MmM4MTMucG5n.png'
          }
          alt="Offer media"
        />
      </div>
    </div>
  );
};

export default OfferCard;
