import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { closeSnackbar, SnackbarProvider, MaterialDesignContent } from 'notistack';
import styled from '@emotion/styled';
import CheckCircleIcon from 'assets/img/CheckCircleIcon';
import CloseIcon from 'assets/img/CloseIcon';
import ErrorIcon from 'assets/img/ErrorIcon';
import InfoIcon from 'assets/img/InfoIcon';
import WarningIcon from 'assets/img/WarningIcon';
import './index.scss';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: '#4CA478',
    fontWeight: 400,
    fontFamily: 'Roboto',
    lineHeight: 0,
    padding: '6px 16px',
    '& svg': {
      marginRight: '13px',
    },
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#B42929',
    fontWeight: 400,
    fontFamily: 'Roboto',
    lineHeight: 0,
    padding: '6px 16px',
    '& svg': {
      marginRight: '13px',
    },
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: '#0288D1',
    fontWeight: 400,
    fontFamily: 'Roboto',
    lineHeight: 0,
    padding: '6px 16px',
    '& svg': {
      marginRight: '13px',
    },
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: '#DA612B',
    fontWeight: 400,
    fontFamily: 'Roboto',
    lineHeight: 0,
    padding: '6px 16px',
    '& svg': {
      marginRight: '13px',
    },
  },
}));

const SnackbarContainer = (props: any) => {
  const location = useLocation();
  const hasSidebar = useMemo(() => {
    const noSidebarLocations = ['/ui/login', '/ui/members/password-setup'];
    return !noSidebarLocations.includes(location.pathname);
  }, [location]);

  return (
    <SnackbarProvider
      maxSnack={3}
      disableWindowBlurListener
      autoHideDuration={5000}
      classes={{ containerRoot: `snackbarContainer__positioning${hasSidebar ? ' sidebarOffset' : ''}` }}
      {...props}
      iconVariant={{
        success: <CheckCircleIcon sx={{ fontSize: 22 }} />,
        warning: <WarningIcon sx={{ fontSize: 22 }} />,
        error: <ErrorIcon sx={{ fontSize: 22 }} />,
        info: <InfoIcon sx={{ fontSize: 22 }} />,
      }}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
      }}
    />
  );
};

export const action = (snackbarId: any) => (
  <div
    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
    onClick={() => {
      closeSnackbar(snackbarId);
    }}
  >
    <CloseIcon />
  </div>
);

export default SnackbarContainer;
