export const BYTE = 1;
export const KILOBYTE = 1024 * BYTE;
export const MEGABYTE = 1024 * KILOBYTE;
export const ACCEPTED_FILES = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

export const possibleStatuses = {
  MASS: [
    {
      status: 'ACTIVE',
      title: 'Active',
    },
    {
      status: 'DEACTIVATED',
      title: 'Deactivated',
    },
    {
      status: 'NEEDS_REVIEW',
      title: 'Needs review',
    },
    {
      status: 'ARCHIVE',
      title: 'Archived',
    },
  ],
  PENDING: [
    {
      status: 'ARCHIVE',
      title: 'Archived',
    },
  ],
  TIME_OUT: [
    {
      status: 'ARCHIVE',
      title: 'Archived',
    },
  ],
  ACTIVE: [
    {
      status: 'DEACTIVATED',
      title: 'Deactivated',
    },
    {
      status: 'NEEDS_REVIEW',
      title: 'Needs review',
    },
    {
      status: 'ARCHIVE',
      title: 'Archived',
    },
  ],
  NEEDS_REVIEW: [
    {
      status: 'ACTIVE',
      title: 'Active',
    },
    {
      status: 'DEACTIVATED',
      title: 'Deactivated',
    },
    {
      status: 'ARCHIVE',
      title: 'Archived',
    },
  ],
  DEACTIVATED: [
    {
      status: 'ACTIVE',
      title: 'Active',
    },
    {
      status: 'NEEDS_REVIEW',
      title: 'Needs review',
    },
    {
      status: 'ARCHIVE',
      title: 'Archived',
    },
  ],
  ARCHIVE: [
    {
      status: 'ACTIVE',
      title: 'Active',
    },
    {
      status: 'DEACTIVATED',
      title: 'Deactivated',
    },
    {
      status: 'NEEDS_REVIEW',
      title: 'Needs review',
    },
  ],
};

export const allPossibleStatuses = [
  {
    status: 'ACTIVE',
    title: 'Active',
    color: '#4CA478',
  },
  {
    status: 'DEACTIVATED',
    title: 'Deactivated',
    color: '#70778F',
  },
  {
    status: 'NEEDS_REVIEW',
    title: 'Needs review',
    color: '#DA612B',
  },
  {
    status: 'ARCHIVE',
    title: 'Archived',
    color: '#BCC0CD',
  },
  {
    status: 'TIME_OUT',
    title: 'Timed out',
    color: '#B42929',
  },
  {
    status: 'PENDING',
    title: 'Pending',
    color: '#70778F',
  },
];
