import { GridRenderCellParams } from '@mui/x-data-grid';
import colors from 'theme/constants/colors';
import Box from 'components/shared/Box/Box';
import Divider from 'components/shared/Divider/Divider';
import Typography from 'components/shared/Typography/Typography';

/**
 * Props for the RenderCategoryCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 */
interface IRenderCategoryCellProps {
  props: GridRenderCellParams;
}

/**
 * Renders a list of event categories in a data grid.
 */
export const RenderCategoryCell = ({ props }: IRenderCategoryCellProps) => {
  const { row } = props;

  if (!row.eventCategories?.length) {
    return <span>—</span>;
  }

  if (row.eventCategories) {
    const categories: {
      id: string;
      title: string;
    }[] = row.eventCategories;

    return (
      <Box display="flex" alignItems="flex-start" flexDirection="column" width={1}>
        {categories.map((category, idx) => (
          <Box key={idx} width={1}>
            <Typography variant="body2">{category.title}</Typography>
            {idx < categories?.length - 1 && (
              <Divider flexItem orientation="horizontal">
                <Typography variant="tooltip" color={colors.outlinedBorder}>
                  OR
                </Typography>
              </Divider>
            )}
          </Box>
        ))}
      </Box>
    );
  } else {
    return <></>;
  }
};
