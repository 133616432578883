import { useCallback, useState } from 'react';
import { GridOverlay } from '@mui/x-data-grid';
import colors from 'theme/constants/colors';
import { useAppDispatch } from 'store/hooks';
import Button from 'components/shared/Button/Button';
import Dialog from 'components/shared/Dialog/Dialog';
import DialogActions from 'components/shared/Dialog/DialogActions/DialogActions';
import DialogContent from 'components/shared/Dialog/DialogContent/DialogContent';
import DialogTitle from 'components/shared/Dialog/DialogTitle/DialogTitle';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';

interface IProps {
  isFilters?: boolean;
  clearFilters?: () => void;
  fetchingError?: boolean;
  refreshData?: () => void;
}

const NoMoreContent = ({ isFilters, clearFilters, fetchingError, refreshData }: IProps) => {
  const dispatch = useAppDispatch();
  const [clearFiltersConfirmModal, setClearFiltersConfirmModal] = useState<boolean>(false);
  const cancelClearFilters = useCallback((event?: any, reason?: any) => {
    if (reason && reason === 'backdropClick') return;
    setClearFiltersConfirmModal(false);
  }, []);

  const handleClearFilters = useCallback(() => {
    setClearFiltersConfirmModal(false);
    clearFilters && clearFilters();
  }, [dispatch, clearFilters]);

  return (
    <>
      <GridOverlay>
        {fetchingError && (
          <Stack alignItems="center">
            <Typography color="text.secondary" variant="body2">
              There are some troubles with data receiving. Repeat request in a while.
            </Typography>
            <Typography fontSize="small" color="text.secondary" variant="body2">
              WARNING! In case of page reload you could lose your requested data
            </Typography>
            <Button variant="text" onClick={() => refreshData && refreshData()}>
              Repeat requests
            </Button>
          </Stack>
        )}
        {!isFilters && !fetchingError && (
          <Stack alignItems="center">
            <Typography variant="h6" color={colors.outlinedBorder}>
              No content to display
            </Typography>
          </Stack>
        )}
        {isFilters && !fetchingError && (
          <Stack alignItems="center" zIndex={100}>
            <Typography color="text.secondary" variant="body2">
              No data to display. Try to remove any of filters or change their value
            </Typography>
            <Button onClick={() => setClearFiltersConfirmModal(true)} variant="text">
              Clear filters
            </Button>
          </Stack>
        )}
      </GridOverlay>
      {clearFiltersConfirmModal && (
        <Dialog fullWidth maxWidth="xs" open={clearFiltersConfirmModal}>
          <DialogTitle variant="h6">Clear filters?</DialogTitle>
          <DialogContent>
            <Typography variant="body1">All data will be shown</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => cancelClearFilters()} variant="text" color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleClearFilters()} variant="contained" color="primary">
              Clear
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default NoMoreContent;
