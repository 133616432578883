import Alert from 'components/shared/Alert/Alert';
import Button from 'components/shared/Button/Button';
import Dialog from 'components/shared/Dialog/Dialog';
import DialogActions from 'components/shared/Dialog/DialogActions/DialogActions';
import DialogContent from 'components/shared/Dialog/DialogContent/DialogContent';
import DialogContentText from 'components/shared/Dialog/DialogContentText/DialogContentText';
import DialogTitle from 'components/shared/Dialog/DialogTitle/DialogTitle';

interface Props {
  open: boolean;
  isWarningRedirect: boolean;
  onClose: () => void;
  logout: () => void;
}

const LogoutModal = ({ open, isWarningRedirect, onClose, logout }: Props) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle variant="h6">Are you sure you want to log out? </DialogTitle>
      <DialogContent>
        <DialogContentText color="text.primary" variant="body1">
          You will be signed out and your session will be terminated
        </DialogContentText>
        {isWarningRedirect && (
          <Alert sx={{ marginTop: '16px' }} severity="info">
            After that you will need to follow the link again to set up password
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={logout}>
          Log out
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutModal;
