import { useEffect } from 'react';
import find from 'lodash/find';
import startCase from 'lodash/startCase';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  fetchConsumptionsDictionaries,
  selectConsumptionDictionaries,
} from 'store/slices/ConsumptionRequests/consumptionRequestsSlice';
import ListItem from 'components/shared/List/ListItem/ListItem';
import ListItemText from 'components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';

export const CustomDetailPanelContent = (row: any) => {
  const { row: requestData } = row;
  const dispatch = useAppDispatch();
  const consumptionDictionaries = useAppSelector(selectConsumptionDictionaries);

  useEffect(() => {
    dispatch(fetchConsumptionsDictionaries());
  }, [dispatch]);
  return (
    <Stack sx={{ height: '100%', boxSizing: 'border-box' }}>
      <Typography p="6px 16px" variant="subtitle2">
        Entity parameters
      </Typography>

      <Stack direction="row" alignItems="start">
        <Stack direction="row" alignItems="start" justifyContent="space-between" width={1} p="8px 12px">
          <ListItem sx={{ px: 0.5, py: 0 }}>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body2" color="text.secondary">
                  Platform
                </Typography>
              }
              secondary={
                <Typography variant="body2" color="text.primary">
                  {startCase(requestData?.consumptionRequestType?.toLowerCase()) || '—'}
                </Typography>
              }
            ></ListItemText>
          </ListItem>
          <ListItem sx={{ px: 0.5, py: 0 }}>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body2" color="text.secondary">
                  Sample content provided
                </Typography>
              }
              secondary={
                <Typography variant="body2" color="text.primary">
                  Yes
                </Typography>
              }
            ></ListItemText>
          </ListItem>
        </Stack>

        <ListItem>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body2" color="text.secondary">
                Account tenure
              </Typography>
            }
            secondary={
              <Typography variant="body2" color="text.primary">
                {find(consumptionDictionaries?.accountTenure, `${requestData.data?.accountTenure}`) || '—'}
              </Typography>
            }
          ></ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body2" color="text.secondary">
                Consumption status
              </Typography>
            }
            secondary={
              <Typography variant="body2" color="text.primary">
                {find(consumptionDictionaries?.consumptionStatus, `${requestData.data?.consumptionStatus}`) || '—'}
              </Typography>
            }
          ></ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body2" color="text.secondary">
                Delivery status
              </Typography>
            }
            secondary={
              <Typography variant="body2" color="text.primary">
                {find(consumptionDictionaries?.deliveryStatus, `${requestData.data?.deliveryStatus}`) || '—'}
              </Typography>
            }
          ></ListItemText>
        </ListItem>
      </Stack>
      <Stack direction="row" alignItems="start">
        <ListItem>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body2" color="text.secondary">
                lifetime dollars purchased
              </Typography>
            }
            secondary={
              <Typography variant="body2" color="text.primary">
                {find(
                  consumptionDictionaries?.lifetimeDollarsPurchased,
                  `${requestData.data?.lifetimeDollarsPurchased}`,
                ) || '—'}
              </Typography>
            }
          ></ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body2" color="text.secondary">
                lifetime dollars refunded
              </Typography>
            }
            secondary={
              <Typography variant="body2" color="text.primary">
                {find(
                  consumptionDictionaries?.lifetimeDollarsRefunded,
                  `${requestData.data?.lifetimeDollarsRefunded}`,
                ) || '—'}
              </Typography>
            }
          ></ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body2" color="text.secondary">
                Play time
              </Typography>
            }
            secondary={
              <Typography variant="body2" color="text.primary">
                {find(consumptionDictionaries?.playTime, `${requestData.data?.playTime}`) || '—'}
              </Typography>
            }
          ></ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body2" color="text.secondary">
                User status
              </Typography>
            }
            secondary={
              <Typography variant="body2" color="text.primary">
                {find(consumptionDictionaries?.userStatus, `${requestData.data?.userStatus}`) || '—'}
              </Typography>
            }
          ></ListItemText>
        </ListItem>
      </Stack>
    </Stack>
  );
};
