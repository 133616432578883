import React from "react";
import Button from "components/shared/Button/Button";

import './index.scss';

interface IProps {
  onClose(): void;
  closeModal(): void;
  action: 'add' | 'edit';
}

const RulesModalCancel = ({ onClose, action, closeModal }: IProps) => {

  return (
    <div className='rulesModal'>
      <div className="rulesModal-header">
        {action === 'add' && `Cancel adding a new rule?`}
        {action === 'edit' && `Cancel editing the rule?`}
      </div>
      <div className="rulesModal-content">
          <span className="rulesModal-content-value_delete">Changes won’t be applied</span>
      </div>
      <div className="rulesModal-footer">
        <div className="rulesModal-footer-buttons">
          <Button onClick={closeModal}>Don’t cancel</Button>
          <Button
            onClick={() => {
              closeModal();
              onClose();
            }}
            variant="contained"
            color= "primary"
          >
            Cancel {action === 'add' ? 'adding' : 'editing'}
          </Button>
        </div>
      </div>
    </div>
  )
};

export default RulesModalCancel;