import { ProtectedRoute } from 'routes/ProtectedRoute';

const UpdatedComponent = (OriginalComponent: any) => {
  function NewComponent() {
    return (
      <ProtectedRoute>
        <OriginalComponent />
      </ProtectedRoute>
    );
  }

  return NewComponent;
};
export default UpdatedComponent;
