import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  GridCallbackDetails,
  GridColDef,
  GridRenderCellParams,
  GridRowSpacingParams,
  MuiEvent,
} from '@mui/x-data-grid';
import { Tab } from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { enqueueSnackbar, VariantType } from 'notistack';
import { useDocumentTitle } from '@uidotdev/usehooks';
import isEqual from 'lodash/isEqual';
import startCase from 'lodash/startCase';
import uniqueId from 'lodash/uniqueId';
import { useErrorBoundary } from 'react-error-boundary';
import { GridRowScrollEndParams, useGridApiRef } from '@mui/x-data-grid-pro';
import ModerationTabs, { Rules } from 'components/Moderation/types/ModerationTabs';
import SubjectType from 'components/Moderation/types/SubjectType';
import { IMediaItem } from 'types/commonTypes';
import colors from 'theme/constants/colors';
import { ErrorBoundaryErrors } from 'containers/ErrorPage/ErrorPage';
import { decisions, implications, terms } from 'components/Moderation/RulesModalContent/types/rulesTypes';
import isAbortError from 'utils/isAbortError';
import { getCustomFilterValue } from 'utils/getCustomFilterValue';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  clearContentModerationData,
  fetchAssignTickets,
  fetchModerationData,
  fetchMyData,
  getEntityCardInfo,
  selectAssignStatus,
  selectColumn,
  selectConfirmedViolationsLength,
  selectConfirmedViolationsStatus,
  selectContentComplaintsLength,
  selectContentComplaintsStatus,
  selectContentComplaintsMyDataLength,
  selectDeclinedRequestsLength,
  selectDeclinedRequestsStatus,
  selectEntityCardInfoStatus,
  selectHiveLength,
  selectHiveStatus,
  selectHiveMyDataLength,
  selectModerationData,
  selectModerationStatus,
  selectProfileComplaintsLength,
  selectProfileComplaintsStatus,
  selectProfileComplaintsMyDataLength,
  setModerationStatusLoading,
} from 'store/moderationSlice';
import {
  Decision,
  fetchRules,
  HiveRule,
  ManualRule,
  ProfileImplications,
  ProfileRule,
  selectColumn as selectColumnRules,
  selectRules,
  selectRulesStatus,
  Term,
} from 'store/rulesSlice';
import { fetchSortingColumns } from 'store/sortingSlice';
import {
  fetchAllMetrics,
  fetchAvailableMetrics,
  fetchDangerousMetrics,
  selectAllMetrics,
  selectAvailableMetrics,
  selectDangerousMetrics,
} from 'store/moderationMetricsSlice';
import {
  clearFilters,
  fetchFilters,
  removeFilter,
  reverseFilters,
  selectFiltersCurrentData,
  selectFiltersData,
  setCustomFilterValue,
  sortFiltersByDependsOnValue,
} from 'store/filtersSlice';
import DeleteIcon from 'assets/img/DeleteIcon';
import EditIcon from 'assets/img/EditIcon';
import FilterIcon from 'assets/img/FilterIcon';
import RefreshIcon from 'assets/img/RefreshIcon';
import SearchIcon from 'assets/img/SearchIcon';
import SortIcon from 'assets/img/SortIcon';
import ActiveFilterList from 'components/ActiveFilterList/ActiveFilterList';
import Autocomplete from 'components/shared/Autocomplete/Autocomplete';
import Button from 'components/shared/Button/Button';
import ButtonGroup from 'components/shared/ButtonGroup/ButtonGroup';
import CircularProgress from 'components/shared/CircularProgress/CircularProgress';
import Dialog from 'components/shared/Dialog/Dialog';
import Divider from 'components/shared/Divider/Divider';
import Drawer from 'components/shared/Drawer/Drawer';
import Event from 'components/ContentDisplay/Event/Event';
import FilterDrawer from 'components/FilterDrawer/FilterDrawer';
import Grid from 'components/shared/Grid/Grid';
import InputAdornment from 'components/shared/InputAdornment/InputAdornment';
import Lifestyle from 'components/ContentDisplay/Lifestyle/Lifestyle';
import LoadingButton from 'components/shared/LoadingButton/LoadingButton';
import MediaPreviewDrawer, { IMediaProps } from 'components/Moderation/MediaPreviewDrawer/MediaPreviewDrawer';
import Modal from 'components/shared/Modal/Modal';
import ModerationCard from 'components/Moderation/ModerationCard/ModerationCard';
import ModerationGridFooter from 'components/Moderation/Footer/ModerationGridFooter';
import NoMoreTickets from 'components/Moderation/NoMoreTickets/NoMoreTickets';
import Offer from 'components/ContentDisplay/Offer/Offer';
import RulesModalContent from 'components/Moderation/RulesModalContent/RulesModalContent';
import SortingDrawer from 'components/SortingDrawer/SortingDrawer';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';
import UserProfileModal from 'components/Moderation/UserProfileModal/UserProfileModal';
import Wish from 'components/ContentDisplay/Wish/Wish';
import { CloseFilterDrawerConfirmModal } from 'components/FilterDrawer/CloseFilterDrawerConfirmModal';
import './index.scss';

export interface IStatusModal {
  openModal: boolean;
  status: boolean;
  moderator: string | null;
  violations: string[];
  onChangeStatus?: () => void;
}

export interface IRuleContent {
  isShowModal: boolean;
  action: undefined | 'add' | 'delete' | 'edit';
  rule: HiveRule | ManualRule | ProfileRule | {};
  availableMetrics?: string[];
}

const initialRowCount = 10;

function Moderation() {
  const [searchParams, setSearchParams] = useSearchParams();
  const userParamsName = searchParams.get('userName');
  const userParamsId = searchParams.get('userId');

  const apiRef = useGridApiRef();
  const fetchPromiseRef = useRef<any>(null);

  const { tabId } = useParams();

  const isCorrectPage = useMemo(
    () => tabId && Object.values(ModerationTabs).includes(tabId as ModerationTabs),
    [tabId],
  );
  const [tabValue, setTabValue] = useState<ModerationTabs>(tabId ? (tabId as ModerationTabs) : ModerationTabs.HIVE);

  useDocumentTitle(`Content moderation - ${startCase(tabValue)}`);

  const [filterDrawer, setFilterDrawer] = useState(false);
  const [filterDrawerCloseConfirmModal, setFilterDrawerCloseConfirmModal] = useState<boolean>(false);
  const [sortingDrawer, setSortingDrawer] = useState(false);
  const [mediaPreviewDrawer, setMediaPreviewDrawer] = useState<IMediaProps>();
  const [isShowEntityDetailsModal, setIsShowEntityDetailsModal] = useState(false);
  const [isShowUserProfileModal, setIsShowUserProfileModal] = useState(false);
  const [rowCount, setRowCount] = useState(initialRowCount);
  const [refresh, setRefresh] = useState(false);
  const [rulesValue, setRulesValue] = useState(Rules.HIVE);
  const [isShowRulesModal, setIsShowRulesModal] = useState<IRuleContent>({
    isShowModal: false,
    action: undefined,
    rule: {},
    availableMetrics: [],
  });
  const [searchValue, setSearchValue] = useState<string>('');
  const [subjectTypeConfirmed, setSubjectTypeConfirmed] = useState<SubjectType>(SubjectType.CONTENT);
  const [subjectTypeDeclined, setSubjectTypeDeclined] = useState<SubjectType>(SubjectType.CONTENT);
  const [ticketsGroup, setTicketsGroup] = useState<string>('All');
  const [shrink, setShrink] = useState(false);
  const [scrollEnd, setScrollEnd] = useState(false);
  const [fetchingError, setFetchingError] = useState(false);

  let { userId } = useParams();

  const { showBoundary } = useErrorBoundary();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const status = useAppSelector(selectModerationStatus);
  const assignStatus = useAppSelector(selectAssignStatus);
  const hiveStatus = useAppSelector(selectHiveStatus);
  const contentComplaintsStatus = useAppSelector(selectContentComplaintsStatus);
  const profileComplaintsStatus = useAppSelector(selectProfileComplaintsStatus);
  const confirmedViolationsStatus = useAppSelector(selectConfirmedViolationsStatus);
  const declinedRequestsStatus = useAppSelector(selectDeclinedRequestsStatus);
  const statusRules = useAppSelector(selectRulesStatus);
  const moderationData = useAppSelector(selectModerationData);
  const hiveLength = useAppSelector(selectHiveLength);
  const contentComplaintsLength = useAppSelector(selectContentComplaintsLength);
  const profileComplaintsLength = useAppSelector(selectProfileComplaintsLength);
  const confirmedViolationsLength = useAppSelector(selectConfirmedViolationsLength);
  const declinedRequestsLength = useAppSelector(selectDeclinedRequestsLength);
  const rules = useAppSelector(selectRules);
  const availableMetrics = useAppSelector(selectAvailableMetrics);
  const columns = useAppSelector(selectColumn);
  const columnsRules = useAppSelector(selectColumnRules);
  const allMetrics = useAppSelector(selectAllMetrics);
  const dangerousMetrics = useAppSelector(selectDangerousMetrics);
  const filtersData = useAppSelector(selectFiltersData);
  const filtersCurrentData = useAppSelector(selectFiltersCurrentData);
  const hiveMyDataLength = useAppSelector(selectHiveMyDataLength);
  const profileComplaintsMyDataLength = useAppSelector(selectProfileComplaintsMyDataLength);
  const contentComplaintsMyDataLength = useAppSelector(selectContentComplaintsMyDataLength);
  const entityCardInfoStatus = useAppSelector(selectEntityCardInfoStatus);
  const mounted = useRef(false);

  const onFilterSidebarClose = useCallback(
    (apply?: boolean) => {
      if (isEqual(filtersData, filtersCurrentData) || apply === true) {
        setFilterDrawerCloseConfirmModal(false);
        setFilterDrawer(false);
        dispatch(reverseFilters());
      } else {
        setFilterDrawerCloseConfirmModal(true);
      }
    },
    [filtersData, filtersCurrentData],
  );

  const cancelCloseFilterSidebar = useCallback((event?: any, reason?: any) => {
    if (reason && reason === 'backdropClick') return;
    setFilterDrawerCloseConfirmModal(false);
  }, []);

  useEffect(() => {
    if (userId) {
      setIsShowUserProfileModal(true);
    }
  }, [userId, isShowUserProfileModal]);

  useEffect(() => {
    navigate(`/ui/moderation/${tabValue}`, { replace: false });
    return () => {
      mounted.current = true;
    };
  }, [tabValue]);

  useEffect(() => {
    if (!tabId) {
      navigate(`/ui/moderation/${ModerationTabs.HIVE}`, { replace: false });
      return;
    }

    if (!isCorrectPage) {
      showBoundary(new Error('Request failed with status code 404'));
    }
  }, []);

  const filtersLength = useMemo(() => {
    return Object.values(filtersData).filter((value) => value !== null).length;
  }, [filtersData]);

  const availableMetricsTab = useMemo(() => {
    switch (rulesValue) {
      case Rules.HIVE:
        return availableMetrics.hive;
      case Rules.MANUAL:
        return availableMetrics.content;
      case Rules.PROFILE:
        return availableMetrics.profile;
    }
  }, [rulesValue, availableMetrics]);

  const handleTabChange = useCallback(async (event: ChangeEvent<{}>, value: ModerationTabs) => {
    dispatch(clearFilters());
    dispatch(clearContentModerationData());
    setRowCount(initialRowCount);
    setTicketsGroup('All');
    if (searchParams.has('tab') || searchParams.has('userName') || searchParams.has('userId')) {
      searchParams.delete('tab');
      searchParams.delete('userName');
      searchParams.delete('userId');
      setSearchParams(searchParams);
      dispatch(removeFilter({ columnId: 'owner_id' }));
    }
    setTabValue(value);
    if (value === ModerationTabs.CONFIRMED_VIOLATIONS) {
      dispatch(sortFiltersByDependsOnValue(subjectTypeConfirmed));
      setSubjectTypeConfirmed(SubjectType.CONTENT);
    }
    if (value === ModerationTabs.DECLINED_REQUESTS) {
      dispatch(sortFiltersByDependsOnValue(subjectTypeDeclined));
      setSubjectTypeDeclined(SubjectType.CONTENT);
    }
  }, []);

  const [entityModalInfo, setEntityModalInfo] = useState<any>(null);
  const [entityCardInfo, setEntityCardInfo] = useState<any>(null);

  const showEntityModal = useCallback((entityInfo?: any) => {
    if (entityInfo) {
      setIsShowEntityDetailsModal(true);
      setEntityModalInfo(entityInfo);
    } else {
      setIsShowEntityDetailsModal(false);
      setEntityModalInfo(null);
    }
  }, []);

  const loadEntityCardInfo = useCallback(async () => {
    if (entityModalInfo !== null) {
      const result = await dispatch(getEntityCardInfo(entityModalInfo));
      if (result.meta.requestStatus === 'fulfilled' && (result.payload as any)) {
        setEntityCardInfo(result.payload as any);
      } else if (result.meta.requestStatus === 'fulfilled' && !(result.payload as any)) {
        setIsShowEntityDetailsModal(false);
        enqueueSnackbar('Loading entity details error, try again later', {
          variant: 'error' as VariantType,
        });
      } else if (result.meta.requestStatus === 'rejected') {
        setIsShowEntityDetailsModal(false);
        setEntityModalInfo(null);
        enqueueSnackbar('Loading entity details error, try again later', {
          variant: 'error' as VariantType,
        });
      }
    } else {
      setEntityCardInfo(null);
    }
  }, [dispatch, entityModalInfo]);

  useEffect(() => {
    void loadEntityCardInfo();
  }, [entityModalInfo, loadEntityCardInfo]);

  const handleRefreshData = useCallback(async () => {
    setRefresh(true);
    try {
      if (fetchPromiseRef.current) {
        if (typeof fetchPromiseRef.current?.abort === 'function') {
          fetchPromiseRef.current.abort();
        }
      }

      let fetchPromise;
      if (ticketsGroup === 'All') {
        fetchPromise = dispatch(
          fetchModerationData({
            pageSize: rowCount,
            type: tabValue,
            subjectTypeDeclined: subjectTypeDeclined,
            subjectTypeConfirmed: subjectTypeConfirmed,
          }),
        );
        fetchPromiseRef.current = fetchPromise;
      } else {
        fetchPromise = dispatch(fetchMyData({ type: tabValue }));
      }

      if (fetchPromise) {
        const result = await fetchPromise.unwrap();

        if (result.meta.requestStatus === 'fulfilled') {
          enqueueSnackbar('Data has been refreshed', {
            variant: 'success' as VariantType,
          });
          setFetchingError(false);
        }
        if (result.meta.requestStatus === 'rejected') {
          enqueueSnackbar('Receiving data error', {
            variant: 'error' as VariantType,
          });
          setFetchingError(true);
        }
      }
    } catch (error) {
      !isAbortError(error) && enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType });
    }
    setRefresh(false);
  }, [tabValue, ticketsGroup, dispatch, rowCount, subjectTypeDeclined, subjectTypeConfirmed]);

  const handleRefreshAllData = useCallback(async () => {
    try {
      await dispatch(
        fetchModerationData({
          pageSize: initialRowCount,
          type: tabValue,
          subjectTypeDeclined: subjectTypeDeclined,
          subjectTypeConfirmed: subjectTypeConfirmed,
        }),
      );
    } catch (error) {
      !isAbortError(error) && enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType });
    }
  }, [dispatch, tabValue, subjectTypeDeclined, subjectTypeConfirmed]);

  const handleChangeStatus = async () => {
    if (ticketsGroup === 'All') {
      await dispatch(
        fetchModerationData({
          pageSize: rowCount,
          type: tabValue,
          subjectTypeDeclined: subjectTypeDeclined,
          subjectTypeConfirmed: subjectTypeConfirmed,
        }),
      );
    } else {
      await dispatch(fetchMyData({ type: tabValue }));
    }
  };

  const handleApplyFilter = async () => {
    try {
      onFilterSidebarClose(true);
      await dispatch(
        fetchModerationData({
          pageSize: rowCount,
          type: tabValue,
          subjectTypeConfirmed: subjectTypeConfirmed,
          subjectTypeDeclined: subjectTypeDeclined,
        }),
      ).unwrap();
      setFetchingError(false);
    } catch (error) {
      setFetchingError(true);
    }
  };

  const customCellRender: any = (props: GridRenderCellParams<any>) => {
    return (
      <ModerationCard
        subjectTypeDeclined={subjectTypeDeclined}
        subjectTypeConfirmed={subjectTypeConfirmed}
        tabValue={tabValue}
        allMetrics={allMetrics}
        dangerousMetrics={dangerousMetrics}
        renderCardProps={props.row}
        ticketsGroup={ticketsGroup}
        showPreview={(newValue: IMediaProps) => setMediaPreviewDrawer(newValue)}
        showDetails={(entityInfo: any) => showEntityModal(entityInfo)}
        showUsersProfile={() => setIsShowUserProfileModal(true)}
        onChangeStatus={handleChangeStatus}
      />
    );
  };

  const customCellRenderRules: any = {
    metric: (props: GridRenderCellParams<any>) => <div className="rulesCell">{props.row?.metric}</div>,
    threshold: (props: GridRenderCellParams<any>) => (
      <div className="rulesCell rulesCell-props">
        {props.row?.reviewMinScore} / {props.row?.decisionMinScore}
      </div>
    ),
    decision: (props: GridRenderCellParams<Decision>) => (
      <div className="rulesCell rulesCell-props">{decisions[props.row?.decision as Decision]}</div>
    ),
    profileImplication: (props: GridRenderCellParams<any>) => {
      if (props.row?.profileImplicationDto) {
        return (
          <div className="rulesCell rulesCell-props">
            {implications[props.row?.profileImplicationDto.profileDecision as ProfileImplications]}
            {props.row?.profileImplicationDto.term === 'FOR_PERIOD'
              ? `for ${props.row?.profileImplicationDto.decisionPeriodCount} days`
              : terms[props.row?.profileImplicationDto.term as Term]}
          </div>
        );
      } else if (!props.row?.profileImplicationDto && !props.row?.profileDecision) {
        return (
          <div className="rulesCell rulesCell-props">{implications['NO_IMPLICATIONS' as ProfileImplications]}</div>
        );
      } else {
        return (
          <div className="rulesCell rulesCell-props">
            {implications[props.row?.profileDecision as ProfileImplications]}
            {props.row?.term === 'PERMANENTLY'
              ? terms[props.row?.term as Term]
              : `for ${props.row?.decisionPeriodCount} days`}
          </div>
        );
      }
    },
    action: (props: GridRenderCellParams<any>) => (
      <div className="rulesCell rulesCell-props">
        <Button
          className="rulesAction"
          onClick={() =>
            setIsShowRulesModal({
              isShowModal: true,
              action: 'edit',
              rule: props.row,
            })
          }
        >
          <EditIcon />
        </Button>
        <Button
          className="rulesAction"
          onClick={() =>
            setIsShowRulesModal({
              isShowModal: true,
              action: 'delete',
              rule: props.row,
            })
          }
        >
          <DeleteIcon />
        </Button>
      </div>
    ),
  };

  const handleChangeTicketsGroup = async (value: string) => {
    await dispatch(setModerationStatusLoading());
    setTicketsGroup(value);
    setRowCount(initialRowCount);
    apiRef.current.scroll({ top: 0 });

    if (fetchPromiseRef.current) {
      if (typeof fetchPromiseRef.current?.abort === 'function') {
        fetchPromiseRef.current.abort();
      }
    }

    try {
      if (value === 'All') {
        fetchPromiseRef.current = await dispatch(
          fetchModerationData({
            pageSize: initialRowCount,
            type: tabValue,
          }),
        ).unwrap();
      } else if (value === 'My') {
        dispatch(clearFilters());
        dispatch(clearContentModerationData());
        fetchPromiseRef.current = await dispatch(fetchMyData({ type: tabValue })).unwrap();
      }
    } catch (error) {
      showBoundary(error);
    }
  };

  const handleChangeSubjectType = async (value: SubjectType) => {
    dispatch(clearFilters());
    setRowCount(initialRowCount);
    apiRef.current.scroll({ top: 0 });

    if (fetchPromiseRef.current) {
      if (typeof fetchPromiseRef.current?.abort === 'function') {
        fetchPromiseRef.current.abort();
      }
    }

    if (tabValue === ModerationTabs.CONFIRMED_VIOLATIONS) {
      setSubjectTypeConfirmed(value);
      fetchPromiseRef.current = dispatch(
        fetchModerationData({
          pageSize: initialRowCount,
          type: tabValue,
          subjectTypeConfirmed: value,
        }),
      );
      dispatch(sortFiltersByDependsOnValue(value));
    } else {
      setSubjectTypeDeclined(value);
      fetchPromiseRef.current = dispatch(
        fetchModerationData({
          pageSize: initialRowCount,
          type: tabValue,
          subjectTypeDeclined: value,
        }),
      );
      dispatch(sortFiltersByDependsOnValue(value));
    }
  };

  const getTickets = async (tab: ModerationTabs) => {
    const result = await dispatch(fetchAssignTickets({ type: tab }));
    if (result.meta.requestStatus === 'fulfilled') {
      if (result.payload.allResultsCount > 0) {
        enqueueSnackbar(
          `${result.payload.allResultsCount} ticket${result.payload.allResultsCount > 1 ? 's' : ''} assigned to you`,
          { variant: 'success' as VariantType },
        );
      } else {
        enqueueSnackbar(`No tickets in the queue, try again later`, {
          variant: 'info' as VariantType,
        });
      }
    } else if (result.meta.requestStatus === 'rejected') {
      enqueueSnackbar('Tickets assignation error, try again later', {
        variant: 'error' as VariantType,
      });
    }
  };

  const rows: any[] = useMemo(() => {
    return moderationData.map((item) => ({
      ...item,
      id: uniqueId('row_'),
    }));
  }, [moderationData]);

  const rowsWithFooter = useMemo(() => {
    if (!scrollEnd || ticketsGroup === 'My') {
      return rows;
    }

    if (moderationData.length > 1) {
      const rowsWithAddedFooter = [
        ...rows,
        {
          id: 'footer',
          isFooter: true,
        },
      ];
      return rowsWithAddedFooter;
    }

    return rows;
  }, [moderationData, rows, scrollEnd, ticketsGroup]);

  const rowsRules: any[] = useMemo(() => {
    if (rulesValue === Rules.HIVE) {
      return rules.hive;
    }
    if (rulesValue === Rules.MANUAL) {
      return rules.manual;
    }
    return rules.profile;
  }, [rules, rulesValue]);

  const cols: GridColDef[] = useMemo(() => {
    return columns.map((item) => ({
      field: item.columnId,
      headerName: item.columnName,
      flex: 1,
      renderCell: (params) => {
        if (params.row.isFooter) {
          return <ModerationGridFooter isAllLoaded={scrollEnd} setScrollEnd={setScrollEnd} />;
        }
        return customCellRender && customCellRender(params);
      },
    }));
  }, [columns, customCellRender, scrollEnd]);

  const colsRules: GridColDef[] = useMemo(() => {
    if (rulesValue === Rules.HIVE) {
      return columnsRules.hive.map((item) => ({
        field: item.columnId,
        headerName: item.columnName,
        flex: item.flex || 0,
        width: item.width,
        sortable: item.sortable,
        renderCell: customCellRenderRules[item.columnId] ?? null,
      }));
    }
    if (rulesValue === Rules.MANUAL) {
      return columnsRules.manual.map((item) => ({
        field: item.columnId,
        headerName: item.columnName,
        flex: item.flex,
        width: item.width,
        sortable: item.sortable,
        renderCell: customCellRenderRules[item.columnId] ?? null,
      }));
    }
    return columnsRules.profile.map((item) => ({
      field: item.columnId,
      headerName: item.columnName,
      flex: item.flex,
      width: item.width,
      sortable: item.sortable,
      renderCell: customCellRenderRules[item.columnId] ?? null,
    }));
  }, [columnsRules, rulesValue]);

  const initialFetchData = useCallback(async () => {
    try {
      await Promise.all([
        dispatch(fetchAllMetrics()).unwrap(),
        // dispatch(fetchDataLength()).unwrap(),
        dispatch(fetchDangerousMetrics()).unwrap(),
      ]);
    } catch (error: any) {
      showBoundary(error);
    }
  }, [dispatch, showBoundary]);

  const initialFetchAvailableMetrics = useCallback(() => {
    dispatch(fetchAvailableMetrics()).then((result: any) => {
      if (
        result.meta &&
        result.meta.requestStatus === 'rejected' &&
        ErrorBoundaryErrors.includes(result.error.message)
      ) {
        // showBoundary( new Error(result.error?.message));
      }
    });
  }, []);

  useEffect(() => {
    initialFetchData();
  }, []);

  useEffect(() => {
    if (tabValue === ModerationTabs.RULES) {
      initialFetchAvailableMetrics();
    }
  }, [tabValue]);

  const resetFilters = useCallback(async () => {
    dispatch(clearFilters());
    await dispatch(
      fetchModerationData({
        pageSize: initialRowCount,
        type: tabValue,
        subjectTypeDeclined: subjectTypeDeclined,
        subjectTypeConfirmed: subjectTypeConfirmed,
      }),
    );
  }, [dispatch, tabValue, subjectTypeDeclined, subjectTypeConfirmed]);

  useEffect(() => {
    dispatch(clearFilters());
    dispatch(clearContentModerationData());

    if (tabValue !== ModerationTabs.RULES) {
      (async () => {
        try {
          dispatch(fetchSortingColumns(tabValue));

          if (searchParams.has('userName') || searchParams.has('userId')) {
            dispatch(setCustomFilterValue(getCustomFilterValue(userParamsName, userParamsId)));
          }

          if (fetchPromiseRef.current) {
            if (typeof fetchPromiseRef.current?.abort === 'function') {
              fetchPromiseRef.current.abort();
            }
          }

          const fetchPromise = dispatch(
            fetchModerationData({
              pageSize: rowCount,
              type: tabValue,
              subjectTypeDeclined: subjectTypeDeclined,
              subjectTypeConfirmed: subjectTypeConfirmed,
            }),
          );
          fetchPromiseRef.current = fetchPromise;

          await fetchPromise.unwrap();
          await dispatch(fetchFilters(tabValue));

          if (tabValue === ModerationTabs.CONFIRMED_VIOLATIONS) {
            dispatch(sortFiltersByDependsOnValue(subjectTypeConfirmed));
          }
          if (tabValue === ModerationTabs.DECLINED_REQUESTS) {
            dispatch(sortFiltersByDependsOnValue(subjectTypeDeclined));
          }
        } catch (error) {
          !isAbortError(error) && showBoundary(error);
        }
      })();
    }

    return () => {
      if (fetchPromiseRef.current && typeof fetchPromiseRef.current?.abort === 'function') {
        fetchPromiseRef.current.abort();
      }
    };
  }, [tabValue]);

  useEffect(() => {
    dispatch(fetchRules(rulesValue)).then((result: any) => {
      if (
        result.meta &&
        result.meta.requestStatus === 'rejected' &&
        ErrorBoundaryErrors.includes(result.error.message)
      ) {
        // showBoundary( new Error(result.error?.message));
      }
    });
  }, [rulesValue]);

  const loadServerRows = useCallback(
    async (newRowLength: number): Promise<void | boolean> => {
      setRowCount(newRowLength);
      fetchPromiseRef.current = dispatch(
        fetchModerationData({
          pageSize: newRowLength,
          type: tabValue,
          subjectTypeDeclined: subjectTypeDeclined,
          subjectTypeConfirmed: subjectTypeConfirmed,
        }),
      );
    },
    [tabValue, subjectTypeConfirmed, subjectTypeDeclined],
  );

  async function handleOnRowsScrollEnd(
    params: GridRowScrollEndParams,
    event: MuiEvent<{}>,
    details: GridCallbackDetails,
    length: number,
    myDataLength?: number,
    ticketsGroup?: string,
  ) {
    if (status !== 'idle') {
      return;
    }

    if (rowCount < length && params.viewportPageSize >= 0 && ticketsGroup !== 'My') {
      await loadServerRows(rowCount + 10);
    } else if (status === 'idle' && rowCount >= length) {
      setScrollEnd(true);
    } else if (myDataLength && rowCount >= myDataLength) {
      setScrollEnd(true);
    }
  }

  const resetScrollEnd = () => {
    setScrollEnd(false);
  };

  useEffect(() => {
    resetScrollEnd();
  }, [tabValue, ticketsGroup, filtersData, refresh]);

  const openUserProfile = (userId: string) => {
    window.open(`${window.location.origin}/ui/users/user/${userId}`, '_blank');
  };

  const showMediaDrawer = (
    entityId: string,
    entityType: string,
    mediaUrl: string,
    mainEntity: IMediaItem,
    orderedMediaList: IMediaItem[],
    mediaType?: string,
  ) => {
    setMediaPreviewDrawer({
      entityId: entityId,
      entityType: entityType,
      mediaUrl: mediaUrl,
      mainEntity: mainEntity,
      orderedMediaList: orderedMediaList,
      contentType: mediaType ?? 'Image',
    });
  };

  const cardElement = () => {
    if (entityCardInfoStatus === 'loading')
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '380px' }}>
          <CircularProgress />
        </div>
      );
    if (entityCardInfo && entityModalInfo) {
      switch (entityModalInfo.entityType) {
        case 'OFFER':
          return (
            <Offer
              data={entityCardInfo}
              modalClose={() => showEntityModal()}
              showMedia={showMediaDrawer}
              openUser={(userId: string) => openUserProfile(userId)}
            />
          );
        case 'WISH':
          return (
            <Wish
              data={entityCardInfo}
              modalClose={() => showEntityModal()}
              openUser={(userId: string) => openUserProfile(userId)}
            />
          );
        case 'LIFESTYLE':
          return (
            <Lifestyle
              data={entityCardInfo}
              modalClose={() => showEntityModal()}
              showMedia={showMediaDrawer}
              openUser={(userId: string) => openUserProfile(userId)}
            />
          );
        case 'EVENT':
          return (
            <Event
              data={entityCardInfo}
              modalClose={() => showEntityModal()}
              showMedia={showMediaDrawer}
              openUser={(userId: string) => openUserProfile(userId)}
            />
          );
      }
      return;
    } else return <></>;
  };

  const handleRowClick = (params: any, event: any) => {
    event.stopPropagation();
  };

  const isEmptyData = !rows.length;
  const isDisabledFilterButton = !(!isEmptyData || !!filtersLength);

  return (
    <div className="moderation-container">
      <Typography variant="h6">Content moderation</Typography>
      <TabContext value={tabValue}>
        <TabList onChange={handleTabChange} className="moderation-tabs">
          <Tab label="Premoderation" value={ModerationTabs.HIVE} className="moderation-tab" />
          <Tab label="Content complaints " value={ModerationTabs.CONTENT_COMPLAINTS} className="moderation-tab" />
          <Tab label="Profile complaints" value={ModerationTabs.PROFILE_COMPLAINTS} className="moderation-tab" />
          <Divider orientation="vertical" flexItem className="moderation-tabs__divider" />
          <Tab label="Confirmed violations" value={ModerationTabs.CONFIRMED_VIOLATIONS} className="moderation-tab" />
          <Tab label="Declined requests" value={ModerationTabs.DECLINED_REQUESTS} className="moderation-tab" />
          <Tab disabled label="Rules" value={ModerationTabs.RULES} className="moderation-tab" />
        </TabList>
        <TabPanel value={ModerationTabs.HIVE}>
          <div className="moderation-table-container singleCard">
            <Stack direction="row" gap={2} justifyContent="space-between" mb={2}>
              <Stack gap={2} direction="row" alignItems="center" className="moderation-table-buttons">
                <ButtonGroup variant="outlined">
                  <Button
                    size="small"
                    className={ticketsGroup === 'All' ? 'Mui-selected' : ''}
                    onClick={() => handleChangeTicketsGroup('All')}
                  >
                    All Tickets
                  </Button>
                  <Button
                    size="small"
                    className={ticketsGroup === 'My' ? 'Mui-selected' : ''}
                    onClick={() => handleChangeTicketsGroup('My')}
                  >
                    My tickets
                  </Button>
                </ButtonGroup>
                {ticketsGroup === 'All' && (
                  <Button
                    disabled={isDisabledFilterButton}
                    size="small"
                    startIcon={<FilterIcon />}
                    className="moderation-table-button"
                    variant="outlined"
                    onClick={() => setFilterDrawer(true)}
                  >
                    Filter {!!filtersLength && `(${filtersLength})`}
                  </Button>
                )}
                {ticketsGroup === 'All' && (
                  <Button
                    disabled
                    size="small"
                    startIcon={<SortIcon />}
                    className="moderation-table-button"
                    variant="outlined"
                    onClick={() => setSortingDrawer(true)}
                  >
                    Sort
                  </Button>
                )}
                {ticketsGroup === 'All' && (
                  <LoadingButton
                    size="small"
                    disabled={hiveStatus === 'loading'}
                    loading={refresh}
                    startIcon={<RefreshIcon />}
                    className="moderation-table-button"
                    loadingPosition="start"
                    onClick={handleRefreshData}
                  >
                    Refresh data
                  </LoadingButton>
                )}
              </Stack>
              {ticketsGroup === 'My' && status === 'idle' ? (
                <div>
                  <LoadingButton
                    startIcon={assignStatus === 'loading' ? <CircularProgress color="inherit" size={20} /> : <></>}
                    disabled={assignStatus === 'loading' || hiveMyDataLength > 0}
                    className="moderation-table-button"
                    variant="contained"
                    onClick={() => getTickets(ModerationTabs.HIVE)}
                  >
                    Get tickets
                  </LoadingButton>
                </div>
              ) : (
                <></>
              )}
            </Stack>
            {filtersLength !== 0 && <ActiveFilterList refreshData={handleRefreshAllData} />}
            {(status === 'idle' || status === 'failed') && (
              <div className="moderation-table-tickets-counter">
                Tickets overall:
                <span>{ticketsGroup === 'My' ? hiveMyDataLength : hiveLength}</span>
              </div>
            )}
            <Grid
              apiRef={apiRef}
              rowBuffer={rows.length}
              columns={cols}
              rows={rowsWithFooter}
              filterMode="server"
              headerHeight={0}
              getRowHeight={() => 'auto'}
              getRowSpacing={(params: GridRowSpacingParams) => ({
                bottom: params.isLastVisible ? 0 : 16,
              })}
              loading={hiveStatus === 'loading'}
              components={{
                NoRowsOverlay: () =>
                  NoMoreTickets({
                    isFilters: !!filtersLength,
                    ticketsGroup: ticketsGroup,
                    fetchingError: fetchingError,
                    clearFilter: resetFilters,
                    refreshData: handleRefreshData,
                  }),
              }}
              onRowsScrollEnd={(p, e, d) => handleOnRowsScrollEnd(p, e, d, hiveLength, hiveMyDataLength, ticketsGroup)}
              scrollEndThreshold={1}
              hideFooter
              disableVirtualization
              disableSelectionOnClick
              onCellClick={handleRowClick}
              onRowClick={handleRowClick}
            />
          </div>
        </TabPanel>
        <TabPanel value={ModerationTabs.CONTENT_COMPLAINTS}>
          <div className="moderation-table-container singleCard">
            <Stack direction="row" gap={2} justifyContent="space-between" mb={2}>
              <Stack gap={2} direction="row" alignItems="center" className="moderation-table-buttons">
                <ButtonGroup variant="outlined">
                  <Button
                    size="small"
                    className={ticketsGroup === 'All' ? 'Mui-selected' : ''}
                    onClick={() => handleChangeTicketsGroup('All')}
                  >
                    All Tickets
                  </Button>
                  <Button
                    size="small"
                    className={ticketsGroup === 'My' ? 'Mui-selected' : ''}
                    onClick={() => handleChangeTicketsGroup('My')}
                  >
                    My tickets
                  </Button>
                </ButtonGroup>
                {ticketsGroup === 'All' && (
                  <Button
                    disabled={isDisabledFilterButton}
                    size="small"
                    startIcon={<FilterIcon />}
                    className="moderation-table-button"
                    variant="outlined"
                    onClick={() => setFilterDrawer(true)}
                  >
                    Filter {!!filtersLength && `(${filtersLength})`}
                  </Button>
                )}
                {ticketsGroup === 'All' && (
                  <Button
                    disabled
                    size="small"
                    startIcon={<SortIcon />}
                    className="moderation-table-button"
                    variant="outlined"
                    onClick={() => setSortingDrawer(true)}
                  >
                    Sort
                  </Button>
                )}
                {ticketsGroup === 'All' && (
                  <LoadingButton
                    size="small"
                    disabled={contentComplaintsStatus === 'loading'}
                    loading={refresh}
                    startIcon={<RefreshIcon />}
                    className="moderation-table-button"
                    loadingPosition="start"
                    onClick={handleRefreshData}
                  >
                    Refresh data
                  </LoadingButton>
                )}
              </Stack>
              {ticketsGroup === 'My' && status === 'idle' ? (
                <div>
                  <LoadingButton
                    startIcon={assignStatus === 'loading' ? <CircularProgress color="inherit" size={20} /> : <></>}
                    disabled={assignStatus === 'loading' || contentComplaintsMyDataLength > 0}
                    className="moderation-table-button"
                    variant="contained"
                    onClick={() => getTickets(ModerationTabs.CONTENT_COMPLAINTS)}
                  >
                    Get tickets
                  </LoadingButton>
                </div>
              ) : (
                <></>
              )}
            </Stack>
            {filtersLength !== 0 && <ActiveFilterList refreshData={handleRefreshAllData} />}
            {(status === 'idle' || status === 'failed') && (
              <div className="moderation-table-tickets-counter">
                Tickets overall:
                <span>{ticketsGroup === 'My' ? contentComplaintsMyDataLength : contentComplaintsLength}</span>
              </div>
            )}
            <Grid
              apiRef={apiRef}
              rowBuffer={rows.length}
              columns={cols}
              filterMode="server"
              getRowHeight={() => 'auto'}
              getRowSpacing={(params: GridRowSpacingParams) => ({
                bottom: params.isLastVisible ? 0 : 16,
              })}
              rows={rowsWithFooter}
              headerHeight={0}
              loading={contentComplaintsStatus === 'loading'}
              components={{
                NoRowsOverlay: () =>
                  NoMoreTickets({
                    isFilters: !!filtersLength,
                    ticketsGroup: ticketsGroup,
                    fetchingError: fetchingError,
                    clearFilter: resetFilters,
                    refreshData: handleRefreshData,
                  }),
              }}
              onRowsScrollEnd={(p, e, d) =>
                handleOnRowsScrollEnd(p, e, d, contentComplaintsLength, contentComplaintsMyDataLength, ticketsGroup)
              }
              scrollEndThreshold={1}
              hideFooter
              disableVirtualization
              disableSelectionOnClick
              onCellClick={handleRowClick}
              onRowClick={handleRowClick}
            />
          </div>
        </TabPanel>
        <TabPanel value={ModerationTabs.PROFILE_COMPLAINTS}>
          <div className="moderation-table-container singleCard">
            <Stack direction="row" gap={2} justifyContent="space-between" mb={2}>
              <Stack gap={2} direction="row" alignItems="center" className="moderation-table-buttons">
                <ButtonGroup variant="outlined">
                  <Button
                    size="small"
                    className={ticketsGroup === 'All' ? 'Mui-selected' : ''}
                    onClick={() => handleChangeTicketsGroup('All')}
                  >
                    All Tickets
                  </Button>
                  <Button
                    size="small"
                    className={ticketsGroup === 'My' ? 'Mui-selected' : ''}
                    onClick={() => handleChangeTicketsGroup('My')}
                  >
                    My tickets
                  </Button>
                </ButtonGroup>
                {ticketsGroup === 'All' && (
                  <Button
                    disabled={isDisabledFilterButton}
                    size="small"
                    startIcon={<FilterIcon />}
                    className="moderation-table-button"
                    variant="outlined"
                    onClick={() => setFilterDrawer(true)}
                  >
                    Filter {!!filtersLength && `(${filtersLength})`}
                  </Button>
                )}
                {ticketsGroup === 'All' && (
                  <Button
                    disabled
                    size="small"
                    startIcon={<SortIcon />}
                    className="moderation-table-button"
                    variant="outlined"
                    onClick={() => setSortingDrawer(true)}
                  >
                    Sort
                  </Button>
                )}
                {ticketsGroup === 'All' && (
                  <LoadingButton
                    size="small"
                    disabled={profileComplaintsStatus === 'loading'}
                    loading={refresh}
                    startIcon={<RefreshIcon />}
                    className="moderation-table-button"
                    loadingPosition="start"
                    onClick={handleRefreshData}
                  >
                    Refresh data
                  </LoadingButton>
                )}
              </Stack>
              {ticketsGroup === 'My' && status === 'idle' ? (
                <div>
                  <LoadingButton
                    startIcon={assignStatus === 'loading' ? <CircularProgress color="inherit" size={20} /> : <></>}
                    disabled={assignStatus === 'loading' || profileComplaintsMyDataLength > 0}
                    className="moderation-table-button"
                    variant="contained"
                    onClick={() => getTickets(ModerationTabs.PROFILE_COMPLAINTS)}
                  >
                    Get tickets
                  </LoadingButton>
                </div>
              ) : (
                <></>
              )}
            </Stack>
            {filtersLength !== 0 && <ActiveFilterList refreshData={handleRefreshAllData} />}
            {(status === 'idle' || status === 'failed') && (
              <div className="moderation-table-tickets-counter">
                Tickets overall:
                <span>{ticketsGroup === 'My' ? profileComplaintsMyDataLength : profileComplaintsLength}</span>
              </div>
            )}
            <Grid
              apiRef={apiRef}
              rowBuffer={rows.length}
              columns={cols}
              filterMode="server"
              getRowHeight={() => 'auto'}
              getRowSpacing={(params: GridRowSpacingParams) => ({
                bottom: params.isLastVisible ? 0 : 16,
              })}
              rows={rowsWithFooter}
              headerHeight={0}
              loading={profileComplaintsStatus === 'loading'}
              components={{
                NoRowsOverlay: () =>
                  NoMoreTickets({
                    isFilters: !!filtersLength,
                    ticketsGroup: ticketsGroup,
                    fetchingError: fetchingError,
                    clearFilter: resetFilters,
                    refreshData: handleRefreshData,
                  }),
              }}
              onRowsScrollEnd={(p, e, d) =>
                handleOnRowsScrollEnd(p, e, d, profileComplaintsLength, profileComplaintsMyDataLength, ticketsGroup)
              }
              scrollEndThreshold={1}
              hideFooter
              disableVirtualization
              disableSelectionOnClick
              onCellClick={handleRowClick}
              onRowClick={handleRowClick}
            />
          </div>
        </TabPanel>
        <TabPanel value={ModerationTabs.CONFIRMED_VIOLATIONS}>
          <div className="moderation-table-container singleCard">
            <Stack gap={2} direction="row" alignItems="center" className="moderation-table-buttons" mb={2}>
              <ButtonGroup variant="outlined">
                <Button
                  size="small"
                  className={subjectTypeConfirmed === SubjectType.CONTENT ? 'Mui-selected' : ''}
                  onClick={() => handleChangeSubjectType(SubjectType.CONTENT)}
                >
                  Content
                </Button>
                <Button
                  size="small"
                  className={subjectTypeConfirmed === SubjectType.PROFILE ? 'Mui-selected' : ''}
                  onClick={() => handleChangeSubjectType(SubjectType.PROFILE)}
                >
                  Profile complaints
                </Button>
              </ButtonGroup>
              <Button
                disabled={isDisabledFilterButton}
                size="small"
                startIcon={<FilterIcon />}
                className="moderation-table-button"
                variant="outlined"
                onClick={() => setFilterDrawer(true)}
              >
                Filter {!!filtersLength && `(${filtersLength})`}
              </Button>
              <Button
                disabled
                size="small"
                startIcon={<SortIcon />}
                className="moderation-table-button"
                variant="outlined"
                onClick={() => setSortingDrawer(true)}
              >
                Sort
              </Button>
              <LoadingButton
                size="small"
                loading={refresh}
                disabled={confirmedViolationsStatus === 'loading'}
                startIcon={<RefreshIcon />}
                className="moderation-table-button"
                loadingPosition="start"
                onClick={handleRefreshData}
              >
                Refresh data
              </LoadingButton>
            </Stack>
            {filtersLength !== 0 && <ActiveFilterList refreshData={handleRefreshAllData} />}
            <Grid
              apiRef={apiRef}
              rowBuffer={rows.length}
              columns={cols}
              filterMode="server"
              getRowHeight={() => 'auto'}
              getRowSpacing={() => ({
                bottom: 16,
              })}
              rows={rowsWithFooter}
              headerHeight={0}
              loading={confirmedViolationsStatus === 'loading'}
              components={{
                NoRowsOverlay: () =>
                  NoMoreTickets({
                    isFilters: !!filtersLength,
                    ticketsGroup: ticketsGroup,
                    fetchingError: fetchingError,
                    clearFilter: resetFilters,
                    refreshData: handleRefreshData,
                  }),
              }}
              onRowsScrollEnd={(p, e, d) => handleOnRowsScrollEnd(p, e, d, confirmedViolationsLength)}
              scrollEndThreshold={1}
              hideFooter
              disableSelectionOnClick
              onCellClick={handleRowClick}
              onRowClick={handleRowClick}
            />
          </div>
        </TabPanel>
        <TabPanel value={ModerationTabs.DECLINED_REQUESTS}>
          <div className="moderation-table-container singleCard">
            <Stack gap={2} direction="row" alignItems="center" className="moderation-table-buttons" mb={2}>
              <ButtonGroup variant="outlined">
                <Button
                  size="small"
                  className={subjectTypeDeclined === SubjectType.CONTENT ? 'Mui-selected' : ''}
                  onClick={() => handleChangeSubjectType(SubjectType.CONTENT)}
                >
                  Content
                </Button>
                <Button
                  size="small"
                  className={subjectTypeDeclined === SubjectType.PROFILE ? 'Mui-selected' : ''}
                  onClick={() => handleChangeSubjectType(SubjectType.PROFILE)}
                >
                  Profile complaints
                </Button>
              </ButtonGroup>
              <Button
                disabled={isDisabledFilterButton}
                size="small"
                startIcon={<FilterIcon />}
                className="moderation-table-button"
                variant="outlined"
                onClick={() => setFilterDrawer(true)}
              >
                Filter {!!filtersLength && `(${filtersLength})`}
              </Button>
              <Button
                disabled
                size="small"
                startIcon={<SortIcon />}
                className="moderation-table-button"
                variant="outlined"
                onClick={() => setSortingDrawer(true)}
              >
                Sort
              </Button>
              <LoadingButton
                size="small"
                disabled={declinedRequestsStatus === 'loading'}
                loading={refresh}
                startIcon={<RefreshIcon />}
                className="moderation-table-button"
                loadingPosition="start"
                onClick={handleRefreshData}
              >
                Refresh data
              </LoadingButton>
            </Stack>
            {filtersLength !== 0 && <ActiveFilterList refreshData={handleRefreshAllData} />}
            <Grid
              apiRef={apiRef}
              rowBuffer={rows.length}
              columns={cols}
              filterMode="server"
              getRowHeight={() => 'auto'}
              getRowSpacing={() => ({
                bottom: 16,
              })}
              rows={rowsWithFooter}
              headerHeight={0}
              loading={declinedRequestsStatus === 'loading'}
              components={{
                NoRowsOverlay: () =>
                  NoMoreTickets({
                    isFilters: !!filtersLength,
                    ticketsGroup: ticketsGroup,
                    fetchingError: fetchingError,
                    clearFilter: resetFilters,
                    refreshData: handleRefreshData,
                  }),
              }}
              onRowsScrollEnd={(p, e, d) => handleOnRowsScrollEnd(p, e, d, declinedRequestsLength)}
              scrollEndThreshold={1}
              hideFooter
              disableVirtualization
              disableSelectionOnClick
              onCellClick={handleRowClick}
              onRowClick={handleRowClick}
            />
          </div>
        </TabPanel>
        <TabPanel value={ModerationTabs.RULES}>
          <div className="moderation-table-container">
            <Stack
              gap={2}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              className="moderation-table-buttons"
            >
              <ButtonGroup variant="outlined">
                <Button
                  size="small"
                  className={rulesValue === Rules.HIVE ? 'Mui-selected' : ''}
                  onClick={() => setRulesValue(Rules.HIVE)}
                >
                  Hive rules
                </Button>
                <Button
                  size="small"
                  className={rulesValue === Rules.MANUAL ? 'Mui-selected' : ''}
                  onClick={() => setRulesValue(Rules.MANUAL)}
                >
                  Manual rules
                </Button>
                <Button
                  size="small"
                  className={rulesValue === Rules.PROFILE ? 'Mui-selected' : ''}
                  onClick={() => setRulesValue(Rules.PROFILE)}
                >
                  Profile rules
                </Button>
              </ButtonGroup>
              <Stack gap={2} direction="row" alignItems="center" className="moderation-table-buttons">
                <Button
                  variant="contained"
                  disabled={!availableMetricsTab.length}
                  onClick={() =>
                    setIsShowRulesModal({
                      isShowModal: true,
                      rule: {},
                      action: 'add',
                      availableMetrics: availableMetricsTab,
                    })
                  }
                >
                  Add rule
                </Button>
                <Autocomplete
                  size="small"
                  freeSolo
                  label="Search"
                  options={rowsRules.map(({ metric }) => metric) || []}
                  onChange={(e: SyntheticEvent, newValue: string | null) => {
                    typeof newValue === 'string' && newValue.length > 2 ? setSearchValue(newValue) : setSearchValue('');
                  }}
                  onFocus={() => setShrink(true)}
                  onBlur={(e) => {
                    !searchValue && !(e.target as HTMLInputElement).value && setShrink(false);
                  }}
                  sx={{
                    width: 276,
                    '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
                      color: colors.outlinedBorder,
                      transform: 'translate(44px, 9px)',
                    },
                  }}
                  inputlabelprops={{
                    shrink: shrink,
                  }}
                  inputprops={{
                    startAdornment: (
                      <InputAdornment sx={{ color: colors.actionDisabled, pl: '6px' }} position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>
            <Grid
              columns={colsRules}
              rowHeight={56}
              rows={rowsRules.filter(({ metric }) => {
                return metric?.includes(searchValue);
              })}
              headerHeight={56}
              hideFooter
              disableColumnResize
              disableColumnMenu
              loading={statusRules === 'loading'}
            />
          </div>
        </TabPanel>
      </TabContext>
      <CloseFilterDrawerConfirmModal
        open={filterDrawerCloseConfirmModal}
        onClose={() => onFilterSidebarClose(true)}
        closeModal={() => cancelCloseFilterSidebar()}
      />
      <Drawer anchor="right" open={filterDrawer} onClose={() => onFilterSidebarClose()}>
        <FilterDrawer
          type={tabValue}
          isHidePresets
          hasSorting={tabValue === ModerationTabs.CONFIRMED_VIOLATIONS || tabValue === ModerationTabs.DECLINED_REQUESTS}
          onClose={onFilterSidebarClose}
          onApply={handleApplyFilter}
        />
      </Drawer>
      <Drawer anchor="right" open={sortingDrawer} onClose={() => setSortingDrawer(false)}>
        {sortingDrawer && (
          <SortingDrawer
            type={tabValue}
            isHidePresets
            close={() => setSortingDrawer(false)}
            apply={() => {
              setSortingDrawer(false);
              dispatch(
                fetchModerationData({
                  pageSize: rowCount,
                  type: tabValue,
                  subjectTypeConfirmed: subjectTypeConfirmed,
                  subjectTypeDeclined: subjectTypeDeclined,
                }),
              );
            }}
          />
        )}
      </Drawer>
      <Drawer
        anchor="right"
        sx={{ zIndex: 1400 }}
        open={!!mediaPreviewDrawer}
        onClose={() => setMediaPreviewDrawer(undefined)}
      >
        {mediaPreviewDrawer && (
          <MediaPreviewDrawer mediaProps={mediaPreviewDrawer} onClose={() => setMediaPreviewDrawer(undefined)} />
        )}
      </Drawer>

      <Dialog fullWidth maxWidth="lg" open={isShowEntityDetailsModal} onClose={() => showEntityModal()}>
        <div>{cardElement()}</div>
      </Dialog>
      <UserProfileModal
        open={isShowUserProfileModal}
        onClose={() => {
          setIsShowUserProfileModal(false);
          navigate(`/ui/moderation/${tabValue}`);
        }}
      />
      <Modal
        open={isShowRulesModal.isShowModal}
        onClose={() =>
          setIsShowRulesModal({
            isShowModal: false,
            rule: {},
            action: undefined,
          })
        }
      >
        <RulesModalContent
          item={isShowRulesModal}
          contentType={rulesValue}
          onClose={() =>
            setIsShowRulesModal({
              isShowModal: false,
              rule: {},
              action: undefined,
            })
          }
        />
      </Modal>
    </div>
  );
}

export default Moderation;
