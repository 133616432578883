import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  OrderedBanner, OrderedEntity,
  OrderedHero,
} from "store/collectionDetailsSlice";
import { stringCapitalize } from "utils/stringCapitalize";
import ContextIcon from "assets/img/ContextIcon";
import DeleteIcon from "assets/img/DeleteIcon";
import DragIndicator3 from 'assets/img/DragIndicator3.png';
import ExtendIcon from "assets/img/ExtendIcon";
import WorldSendIcon from "assets/img/WorldSendIcon";
import BannerCard from "components/WorldManagement/Collections/CollectionDetails/CollectionEntity/BannerCard";
import Card from "components/shared/Card/Card";
import CardActionArea from "components/shared/Card/CardActionArea";
import CardContent from "components/shared/Card/CardContent";
import CardMedia from "components/shared/Card/CardMedia";
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Divider from "components/shared/Divider/Divider";
import FormControlLabel from 'components/shared/FormControlLabel/FormControlLabel';
import HeroCard from "components/WorldManagement/Collections/CollectionDetails/CollectionEntity/HeroCard";
import IconButton from "components/shared/IconButton/IconButton";
import ManageLinkedCollectionsModal from "components/ManageLinkedCollections/ManageLinkedCollectionsModal";
import Menu from "components/shared/Menu/Menu";
import MenuItem from "components/shared/Menu/MenuItem/MenuItem";
import UserProfileModal from "components/Moderation/UserProfileModal/UserProfileModal";
import './index.scss';

interface IProps {
  entity: OrderedEntity;
  heightEntity: number;
  widthEntity: number;
  entityType: 'BANNER' | 'HERO' | 'OFFER' | 'WISH' | 'EVENT';
  isCanDrag: boolean;
  isSelectedEntity: boolean;
  toggleSelectedEntity(entity: OrderedEntity, isCheck: boolean): void;
  setDeleteEntity(entity: string[]): void;
  isDefaultCollection: boolean;
  collectionId: string;
}

const CollectionEntity = ({
  entity,
  widthEntity,
  heightEntity,
  entityType,
  isCanDrag,
  isSelectedEntity,
  toggleSelectedEntity,
  setDeleteEntity,
  isDefaultCollection = false,
  collectionId,
}: IProps) => {
  let card;
  let { userId } = useParams();

  const [anchorElContextMenu, setAnchorElContextMenu] = useState<null | HTMLElement>(null);
  const [openLinkedCollections, setOpenLinkedCollections] = useState<OrderedEntity | null>(null);
  const [isShowUserProfileModal, setIsShowUserProfileModal] = useState(false);

  const openContextMenu = Boolean(anchorElContextMenu);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElContextMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElContextMenu(null);
  };

  const viewDetails = () => {
    switch (entityType) {
      case "BANNER":
        window.open(`/ui/world-management/banners/${entity.entityId}`,'_blank');
        break;
      case "HERO":
        navigate(`${entity.entityId}`);
        setIsShowUserProfileModal(true);
        break;
      case "WISH":
        // todo сюда добавить модалочку виша
        break;
      case "OFFER":
        // todo сюда добавить модалочку оффера
        break;
      case "EVENT":
        // todo сюда добавить модалочку эвента
        break;
    }
  }

  useEffect(() => {
    if (userId) {
      setIsShowUserProfileModal(true);
    }
  }, []);

  switch (entityType) {
    case "BANNER": {
      card = <BannerCard entity={entity as OrderedBanner} height={heightEntity} width={widthEntity} />;
      break;
    }
    case "HERO": {
      card = <HeroCard entity={entity as OrderedHero} height={heightEntity} width={widthEntity} />;
      break;
    }
    // todo сюда добавить отображение остальных карточек
    default: {
      card = (
        <Card>
          <CardActionArea>
            <CardMedia
              component="img"
              height={heightEntity}
              width={widthEntity}
              image={'cover' in entity && entity.cover?.originalLink || ''}
              alt="green iguana"
            >
            </CardMedia>
            <CardContent>

            </CardContent>
          </CardActionArea>
        </Card>
      );
    }
  }

  return (
    <div className={`collectionEntity ${entity.entityStatus === 'UNAVAILABLE' ? 'collectionEntity_unavailable' : ''} ${isSelectedEntity ? 'collectionEntity_selected' : ''}`}>
      {entity.entityStatus !== 'ACTIVE' &&
        <div className="entityStatusOverlay">
          <div className={`entityStatusOverlay-label entityStatusOverlay-label_${entity.entityStatus.toLowerCase()}`}>
            {entityType === 'BANNER' && entity.entityStatus === 'UNAVAILABLE' ? 'Expired' : stringCapitalize(entity.entityStatus)}
          </div>
        </div>
      }
      <div className="entityContainer" style={{ width: widthEntity, height: heightEntity + 38 }}>
        <div className="entityContainer-actions">
          <FormControlLabel
            label=""
            control={<Checkbox checked={isSelectedEntity} onChange={(event, checked) => toggleSelectedEntity(entity, checked)} />}
          />
          <div className="entityContextMenu">
            <IconButton
              className='entityAction'
              id="entityAction-button"
              aria-controls={openContextMenu ? 'entity-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openContextMenu ? 'true' : undefined}
              onClick={handleClick}
            >
              <ContextIcon />
            </IconButton>
            <Menu
              id="entity-menu"
              className="entityContextMenu-menu"
              aria-labelledby="entityAction-button"
              anchorEl={anchorElContextMenu}
              open={openContextMenu}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  viewDetails();
                }}
              >
                <ExtendIcon />
                View details
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setOpenLinkedCollections(entity);
                }}
              >
                <WorldSendIcon />
                Manage linked collections
              </MenuItem>
              <Divider flexItem />
              <MenuItem
                onClick={() => {
                  handleClose();
                  setDeleteEntity([entity.entityId]);
                }}
              >
                <DeleteIcon />
                Delete
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div className="overlay" style={{ width: widthEntity, height: heightEntity }}>
          {isCanDrag && <>
            <img src={DragIndicator3} alt="drag" />
            drag to change order
          </>}
        </div>
        {card}
        {isDefaultCollection && <div className="entityContainer-additionalInfo">Added {new Date(entity.createdAt).toLocaleDateString()}</div>}
      </div>
      {openLinkedCollections &&
        <ManageLinkedCollectionsModal
          open={!!openLinkedCollections}
          onClose={() => setOpenLinkedCollections(null)}
          entityId={openLinkedCollections.entityId}
          entityType={entityType}
          shouldDisabledCollection={collectionId}
        />
      }
      {isShowUserProfileModal && <UserProfileModal
        open={isShowUserProfileModal}
        onClose={() => {
          setIsShowUserProfileModal(false);
          navigate(`/ui/world-management/collections/${collectionId}`);
        }}
      />}
    </div>
  )
};

export default CollectionEntity;
