import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { enqueueSnackbar, VariantType } from "notistack";
import TextField from "components/shared/TextField/TextField";
import FormHelperText from "components/shared/FormHelperText/FormHelperText";
import Button from "components/shared/Button/Button";
import { fetchAddCollection, selectErrorText } from "store/worldManagementSlice";
import Alert from "components/shared/Alert/Alert";
import getValidationSchema from "components/WorldManagement/Collections/CollectionsModal/utils/getValidationSchema";
import Autocomplete from "components/shared/Autocomplete/Autocomplete";
import { Cohort } from "store/cohortDetailsSlice";
import { CommonErrorMessages, WorldText } from "store/types/ErrorMessages";

import './index.scss';

interface IProps {
  onClose(): void;
  cohorts: Cohort[];
}

const AddCollectionModal = ({ onClose, cohorts }: IProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [cohortValue, setCohortValue] = useState<string | null>('');
  const [showFieldError, setShowFieldError] = useState({ isShow: false, message: '' });

  const dispatch = useAppDispatch();

  const statusText = useAppSelector(selectErrorText);

  const schema = useMemo(() => getValidationSchema('add'),[]);
  const { register, handleSubmit, trigger, setValue, formState: { errors, isValid }, control } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    switch (statusText) {
      case WorldText.DUPLICATE_TITLE:
        setShowFieldError({ isShow: true, message: 'Collection with the same name already exists '});
        break;
      case CommonErrorMessages.UNEXPECTED_ERROR:
        setErrorMessage('Collection creation error, try again later');
        break;
      default:
        setShowFieldError({ isShow: false, message: ''});
    }
  }, [statusText]);

  const onSubmit = async (data: any) => {
    void trigger();

    try {
      const result = await dispatch(fetchAddCollection(data));
      if (result.meta.requestStatus === 'fulfilled') {
        onClose();
        enqueueSnackbar(`“${data.title}” collection has been created`, { variant: 'success' as VariantType });
      }
    } catch (e) {
      setErrorMessage('Collection creation error, try again later');
    }
  };

  return (
    <form
      className='addCollectionModal'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="addCollectionModal-header">
        Add new collection
      </div>
      <div className="addCollectionModal-content">
        <div>
          <TextField
            {...register('title')}
            error={!!errors.title || showFieldError.isShow}
            name="title"
            label="Title*"
            defaultValue={''}
            size="small"
            fullWidth={true}
            onBlur={() => trigger('title')}
          />
          {(errors.title || showFieldError.isShow) &&
            <FormHelperText error>
              {showFieldError.isShow ? showFieldError.message : errors.title?.message as string}
            </FormHelperText>
          }
        </div>
        <Controller
          control={control}
          name="cohortId"
          render={() => (
            <Autocomplete
              label="Cohort*"
              inputValue={cohortValue ?? ''}
              clearIcon={null}
              inputprops={{
                error: !!errors.cohortId,
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.title || ""}
              onInputChange={(event, value: string | null) => {
                setCohortValue(value);
              }}
              onChange={(event, value) => {
                setValue('cohortId',value ? value.id : null);
                setCohortValue(value ? value.title : null);
                void trigger('cohortId');
              }}
              options={[...cohorts]}
              size="small"
              onBlur={() => trigger('cohortId')}
              fullWidth={true}
            />)}
        />
      </div>
      <div className="addCollectionModal-footer">
        {errorMessage && errorMessage !== '' && !showFieldError.isShow && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
        <div className="addCollectionModal-footer-buttons">
          <Button onClick={() => onClose()}>
            Cancel
          </Button>
          <Button disabled={!isValid} type="submit" variant="contained" color="primary">
            Add collection
          </Button>
        </div>
      </div>
    </form>
  )
};

export default AddCollectionModal;
