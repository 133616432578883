import { createSvgIcon } from '@mui/material';

const RefreshIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.45193 6.74559C8.66792 5.52956 10.3172 4.84648 12.0369 4.84667C14.688 4.84686 17.0721 6.46121 18.0568 8.92262L18.2139 9.31478C18.3884 9.75057 18.8832 9.96236 19.319 9.78782C19.7548 9.61328 19.9665 9.11851 19.792 8.68272L19.6351 8.29087C18.3922 5.18412 15.3832 3.14691 12.0371 3.14667C9.86649 3.14643 7.78467 4.00863 6.24982 5.54353L3.84627 7.94709V5.99764C3.84627 5.5282 3.46571 5.14764 2.99627 5.14764C2.52683 5.14764 2.14627 5.5282 2.14627 5.99764V9.99917C2.14627 10.4686 2.52683 10.8493 2.99627 10.8493H6.99794C7.46738 10.8493 7.84794 10.4688 7.84794 9.99931C7.84794 9.52987 7.46738 9.14931 6.99794 9.14931H5.04821L7.45193 6.74559Z" />
    <path d="M20.1538 16.053V18.0027C20.1538 18.4722 20.5344 18.8527 21.0038 18.8527C21.4732 18.8527 21.8538 18.4722 21.8538 18.0027V14.0011C21.8538 13.7129 21.7104 13.4583 21.4911 13.3046C21.4614 13.2837 21.4306 13.2649 21.3989 13.2483C21.2764 13.1839 21.1414 13.1515 21.0063 13.1511C21.0054 13.1511 21.0046 13.1511 21.0038 13.1511H17.0021C16.5327 13.1511 16.1521 13.5316 16.1521 14.0011C16.1521 14.4705 16.5327 14.8511 17.0021 14.8511H18.9516L16.548 17.2546C15.332 18.4707 13.6827 19.1538 11.9629 19.1536C9.31187 19.1534 6.92782 17.539 5.94312 15.0776L5.78605 14.6854C5.61151 14.2497 5.11675 14.0379 4.68096 14.2124C4.24517 14.387 4.03338 14.8817 4.20792 15.3175L4.36498 15.7097C5.60794 18.8162 8.61677 20.8533 11.9628 20.8536C14.1334 20.8538 16.2152 19.9916 17.7501 18.4567L20.1538 16.053Z" />
  </svg>,
  'Refresh',
);

export default RefreshIcon;
