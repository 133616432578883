import { Point, Area } from "react-easy-crop/types";
import React, { useState } from "react";
import Cropper from "react-easy-crop";

import './index.scss';

interface Props {
  image: string;
  aspect: number;
  zoom?: number;
  onZoomChange?: (zoomNumber: number) => void;
  onCropComplete?: (croppedArea: Area, croppedAreaPixels: Area) => void;
  style: {
    containerStyle?: React.CSSProperties;
    mediaStyle?: React.CSSProperties;
    cropAreaStyle?: React.CSSProperties;
  };
}
const ImageCrop = ({ image, aspect, zoom, onZoomChange, style, onCropComplete, ...props }: Props) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });

  return (
    <div className="crop-container">
      <Cropper
        style={style}
        image={image}
        aspect={aspect}
        crop={crop}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        zoom={zoom}
        onZoomChange={onZoomChange}
        {...props}
      />
    </div>
  )
};

export default ImageCrop;