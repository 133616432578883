/**
 * Returns a custom filter value object based on user information.
 *
 * @param userName The username of the creator.
 * @param userId The user ID of the creator.
 * @returns A custom filter value object.
 */
export const getCustomFilterValue = (userName: string | null, userId: string | null) => {
  return {
    title: 'Creator user ID or username',
    type: 'IN',
    valueType: 'STRING',
    customImplementation: false,
    fromArray: false,
    columnId: 'owner_id',
    belongsToKinds: ['hive', 'content'],
    getValuesImmediately: false,
    required: false,
    elements: [
      {
        value: userId,
        titles: [userName, userId],
      },
    ],
  };
};
