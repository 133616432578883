import { TDataGridRowItem } from 'store/slices/Settings/interface';

const getTransformedFormData = (inputObject: TDataGridRowItem) => {
  const resultObject = {
    entityType: inputObject.entityType,
    format: inputObject.format,
    eventOnlineFormatType: inputObject.eventOnlineFormatType,
    eventCategories: inputObject.eventCategories
      ? inputObject.eventCategories.map((el) => {
          return { value: el.id, titles: [el.title] };
        })
      : [],
    passions: inputObject.passions
      ? inputObject.passions.map((passionGroup) =>
          passionGroup.map((passion) => {
            return { value: passion.id, titles: [passion.title] };
          }),
        )
      : [[]],
    certainty: inputObject.certainty,
    salesTaxCode: {
      salesTaxCodeId: inputObject.salesTaxCode.id,
      code: inputObject.salesTaxCode.code,
      category: inputObject.salesTaxCode.category,
    },
  };

  return resultObject;
};

export default getTransformedFormData;
