import colors from 'theme/constants/colors';
import { TariffGroupsStatuses } from 'store/slices/Settings/interface';

export const possibleTariffGroupsStatuses = [
  {
    status: TariffGroupsStatuses.ACTIVE,
    title: 'Active',
    color: colors.success,
  },
  {
    status: TariffGroupsStatuses.INACTIVE,
    title: 'Inactive',
    color: colors.error,
  },
];
