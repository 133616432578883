import { Dispatch, Fragment, SetStateAction } from 'react';
import dayjs from 'dayjs';
import startCase from 'lodash/startCase';
import { alpha } from '@mui/material';
import { createSearchParams, Link } from 'react-router-dom';
import colors from 'theme/constants/colors';
import { TaxModerationRoutes } from 'routes/enum';
import { IGroupedDropdownItem } from 'types/commonTypes';
import {
  ITaxModerationCard,
  ITaxModerationCardSolution,
  ITaxModerationCardStatus,
  TaxModerationSolution,
  TaxModerationStatus,
} from 'store/slices/TaxModeration/interface';
import ArrowBack from 'assets/img/ArrowBack';
import ChevronRightIcon from 'assets/img/ChevronRightIcon';
import EditIcon from 'assets/img/EditIcon';
import HistoryIcon from 'assets/img/HistoryIcon';
import Box from 'components/shared/Box/Box';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import Button from 'components/shared/Button/Button';
import Divider from 'components/shared/Divider/Divider';
import IconButton from 'components/shared/IconButton/IconButton';
import LinearProgress from 'components/shared/LinearProgress/LinearProgress';
import LinkMUI from 'components/shared/Link/Link';
import ListItem from 'components/shared/List/ListItem/ListItem';
import ListItemText from 'components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';

interface IRenderTaxModerationCardProps {
  currentSolution: ITaxModerationCardSolution | undefined;
  currentStatus: ITaxModerationCardStatus | undefined;
  handleApproveButtonClick: () => void;
  isDisabledApproveButton: boolean;
  isDisabledChangeButton: boolean;
  isLoading: boolean;
  isNewTaxCode: boolean;
  setIsOpenChangeTaxCodeModal: Dispatch<SetStateAction<boolean>>;
  taxModerationCardDetails: ITaxModerationCard | null;
}

export const RenderTaxModerationCard = ({
  currentSolution,
  currentStatus,
  handleApproveButtonClick,
  isDisabledApproveButton,
  isDisabledChangeButton,
  isLoading,
  isNewTaxCode,
  setIsOpenChangeTaxCodeModal,
  taxModerationCardDetails,
}: IRenderTaxModerationCardProps) => {
  const EntityParamsInfo = () => {
    return (
      <Stack flex="30%" p="8px 0" gap={0.5}>
        <Typography sx={{ padding: '6px 24px 6px 16px' }} variant="subtitle2" color="text.primary">
          Entity parameters
        </Typography>
        <Stack alignSelf="flex-start" alignItems="flex-start" direction="row">
          <ListItem>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body2" color="text.secondary">
                  Entity
                </Typography>
              }
              secondary={
                <Typography variant="body2" color="text.primary">
                  {startCase(taxModerationCardDetails?.entityType?.toLowerCase()) || '—'}
                </Typography>
              }
            ></ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body2" color="text.secondary">
                  Format
                </Typography>
              }
              secondary={
                <Typography variant="body2" color="text.primary">
                  {startCase(taxModerationCardDetails?.format?.toLowerCase()) || '—'}
                </Typography>
              }
            ></ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body2" color="text.secondary">
                  Type
                </Typography>
              }
              secondary={
                <Typography variant="body2" color="text.primary">
                  {startCase(taxModerationCardDetails?.eventOnlineFormatType?.toLowerCase()) || '—'}
                </Typography>
              }
            ></ListItemText>
          </ListItem>
        </Stack>
        <ListItem>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body2" color="text.secondary">
                YZZY category
              </Typography>
            }
            secondary={
              <Typography variant="body2" color="text.primary">
                {taxModerationCardDetails?.eventCategory || '—'}
              </Typography>
            }
          ></ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body2" color="text.secondary">
                Passions
              </Typography>
            }
            secondary={
              <Typography variant="body2" color="text.primary">
                {taxModerationCardDetails?.passions?.join(', ') || '—'}
              </Typography>
            }
          ></ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body2" color="text.secondary">
                Description
              </Typography>
            }
            secondary={
              <Typography variant="body2" color="text.primary">
                {taxModerationCardDetails?.entityDescription || '—'}
              </Typography>
            }
          ></ListItemText>
        </ListItem>
      </Stack>
    );
  };

  const TaxRuleInfo = () => {
    const renderPassion = (passionGroup: void | string[] | IGroupedDropdownItem[], idx: number) => (
      <Fragment key={idx}>
        <>
          {passionGroup &&
            passionGroup.map((passion, subIdx) => (
              <Typography display="inline" variant="body2" color="text.primary" key={subIdx}>
                <>
                  {passion}
                  {subIdx < passionGroup.length - 1 && (
                    <Typography component="span" variant="body2" display="inline" color={colors.outlinedBorder}>
                      {' + '}
                    </Typography>
                  )}
                </>
              </Typography>
            ))}

          <Typography variant="body2" display="inline" color={colors.outlinedBorder} key={`word-divider-${idx}`}>
            {taxModerationCardDetails?.salesTaxRule?.passions &&
              taxModerationCardDetails.salesTaxRule.passions.length > 1 &&
              idx < taxModerationCardDetails.salesTaxRule.passions.length - 1 &&
              ' or '}
          </Typography>
        </>
      </Fragment>
    );

    return (
      <Stack gap={1}>
        <Stack
          p="8px 0"
          sx={{
            borderRadius: '4px',
            border: `1px solid ${colors.outlinedBorder}`,
          }}
        >
          <Typography sx={{ padding: '6px 24px 6px 16px' }} variant="subtitle2" color="text.primary">
            Rule for sales tax
          </Typography>
          <Stack direction="row">
            <Stack flex="70%" gap={0.5}>
              <Stack alignSelf="flex-start" alignItems="flex-start" direction="row">
                <ListItem>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography variant="body2" color="text.secondary">
                        Entity
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2" color="text.primary">
                        {startCase(taxModerationCardDetails?.salesTaxRule?.entityType?.toLowerCase()) || '—'}
                      </Typography>
                    }
                  ></ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography variant="body2" color="text.secondary">
                        Format
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2" color="text.primary">
                        {startCase(taxModerationCardDetails?.salesTaxRule?.format?.toLowerCase()) || '—'}
                      </Typography>
                    }
                  ></ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography variant="body2" color="text.secondary">
                        Type
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2" color="text.primary">
                        {startCase(taxModerationCardDetails?.salesTaxRule?.eventOnlineFormatType?.toLowerCase()) || '—'}
                      </Typography>
                    }
                  ></ListItemText>
                </ListItem>
              </Stack>
              <ListItem>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="text.secondary">
                      YZZY category
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color="text.primary">
                      {taxModerationCardDetails?.salesTaxRule.eventCategories?.join(', ') || '—'}
                    </Typography>
                  }
                ></ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText disableTypography>
                  <Typography variant="body2" color="text.secondary">
                    Passions
                  </Typography>
                  {taxModerationCardDetails?.salesTaxRule?.passions?.flat().length
                    ? taxModerationCardDetails.salesTaxRule.passions.map(renderPassion)
                    : '—'}
                </ListItemText>
              </ListItem>
            </Stack>
            <Divider sx={{ borderStyle: 'dashed' }} orientation="vertical" flexItem />
            <Stack flex="30%">
              <ListItem>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="text.secondary">
                      Sales tax code
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color="text.primary">
                      {taxModerationCardDetails?.salesTaxRule?.salesTaxCode?.code}
                    </Typography>
                  }
                ></ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="text.secondary">
                      Sales tax category
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color="text.primary">
                      {taxModerationCardDetails?.salesTaxRule?.salesTaxCode?.category}
                    </Typography>
                  }
                ></ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="text.secondary">
                      Certainty
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color="text.primary">
                      {startCase(taxModerationCardDetails?.salesTaxRule?.certainty?.toLowerCase()) || '—'}
                    </Typography>
                  }
                ></ListItemText>
              </ListItem>
            </Stack>
          </Stack>
          <ListItem sx={{ pl: 1 }}>
            <Link
              to={{
                pathname: '/ui/payments-settings/taxes',
                search: `?${createSearchParams({ ruleId: taxModerationCardDetails?.salesTaxRule.id ?? '' })}`,
              }}
              target="_blank"
            >
              <Button>Go to rule</Button>
            </Link>
          </ListItem>
        </Stack>
      </Stack>
    );
  };

  const TaxCodeInfo = () => {
    const handleChangeButtonClick = () => {
      setIsOpenChangeTaxCodeModal(true);
    };

    return (
      <Stack
        sx={{
          borderRadius: '4px',
          backgroundColor: !isNewTaxCode ? alpha(colors.primary, 0.08) : alpha(colors.success, 0.12),
        }}
      >
        <Stack p="16px 24px 8px 0px" direction="row" justifyContent="space-between" alignItems="center">
          <Typography sx={{ padding: '6px 24px 6px 16px' }} variant="subtitle2" color="text.primary">
            Applied sales tax
          </Typography>
          {!isDisabledChangeButton && (
            <Button variant="contained" size="small" startIcon={<EditIcon />} onClick={handleChangeButtonClick}>
              Change
            </Button>
          )}
        </Stack>

        <Stack alignItems="flex-start" direction="row" gap={2}>
          <Stack flex="70%">
            <ListItem>
              <ListItemText disableTypography>
                <Typography variant="body2" color="text.secondary">
                  Description of the sale tax code
                </Typography>
                <Typography variant="body2" color="text.primary">
                  {taxModerationCardDetails?.salesTaxCode.description}
                </Typography>
              </ListItemText>
            </ListItem>
          </Stack>

          <Stack flex="30%">
            <ListItem>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body2" color="text.secondary">
                    Sales tax code
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" color="text.primary">
                    {taxModerationCardDetails?.salesTaxCode?.code}
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body2" color="text.secondary">
                    Sales tax category
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" color="text.primary">
                    {taxModerationCardDetails?.salesTaxCode?.category}
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
          </Stack>
        </Stack>
      </Stack>
    );
  };

  return (
    <Stack className="tax-moderation-carousel__card-wrapper">
      {isLoading && <LinearProgress className="progress" variant="query" />}

      <Box component="div" className="card-breadcrumbs">
        <Link className="unstyled-link" color="default" to={TaxModerationRoutes.TAX_MODERATION}>
          <IconButton size="small" color="default">
            <ArrowBack />
          </IconButton>
        </Link>
        <Breadcrumbs separator={<ChevronRightIcon fontSize="small" />}>
          <LinkMUI
            component={Link}
            to={TaxModerationRoutes.TAX_MODERATION}
            variant="h6"
            color="text.secondary"
            underline="hover"
          >
            Table
          </LinkMUI>
          <div>
            <Typography display="inline" variant="h6" color="text.primary" sx={{ wordBreak: 'break-word' }}>
              {`${taxModerationCardDetails?.entityName} `}
            </Typography>
            <Typography display="inline" color="text.secondary" variant="h6">
              (ID {taxModerationCardDetails?.id})
            </Typography>
          </div>
        </Breadcrumbs>
      </Box>

      <Stack
        className="card-content"
        p="0 8px 8px 8px"
        gap={2}
        direction="row"
        alignSelf="stretch"
        alignItems="flex-start"
      >
        <EntityParamsInfo />
        <Stack flex="70%" gap={1}>
          <TaxRuleInfo />
          <TaxCodeInfo />
        </Stack>
      </Stack>

      <Divider orientation="horizontal" flexItem />

      <Stack pl={1} pr={3} direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" gap={2}>
          <ListItem>
            <ListItemText
              disableTypography
              sx={{ whiteSpace: 'nowrap' }}
              primary={
                <Typography variant="body2" color="text.secondary">
                  Received
                </Typography>
              }
              secondary={
                <>
                  <Typography display="inline" variant="body2" color="text.primary">
                    {dayjs(taxModerationCardDetails?.createdAt).format('MM/DD/YYYY')}
                  </Typography>
                  <Typography ml={1} display="inline" variant="body2" color="text.primary">
                    {dayjs(taxModerationCardDetails?.createdAt).format('h:mm A')}
                  </Typography>
                </>
              }
            ></ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body2" color="text.secondary">
                  Status
                </Typography>
              }
              secondary={
                <Typography variant="body2" color={currentStatus?.color}>
                  {currentStatus && taxModerationCardDetails?.status
                    ? taxModerationCardDetails.status.replace(taxModerationCardDetails.status, currentStatus?.title)
                    : '—'}
                </Typography>
              }
            ></ListItemText>
          </ListItem>
          {currentSolution && (
            <ListItem>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body2" color="text.secondary">
                    Solution
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" color={currentSolution?.color}>
                    {taxModerationCardDetails?.solution.replace(
                      taxModerationCardDetails?.solution,
                      currentSolution.title,
                    )}
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
          )}
        </Stack>
        <Stack p="8px 0 8px 8px" direction="row" gap={2} maxHeight="42px">
          <Button disabled startIcon={<HistoryIcon />} size="small">
            History
          </Button>
          {isDisabledApproveButton && (
            <Button onClick={handleApproveButtonClick} variant="contained" color="success" size="large">
              Approve
              {currentSolution?.solution === TaxModerationSolution.MODIFIED &&
                (currentStatus?.status === TaxModerationStatus.RESOLVED ||
                  currentStatus?.status === TaxModerationStatus.CALCULATED) &&
                ' changes'}
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
