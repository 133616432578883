import Button from 'components/shared/Button/Button';
import Dialog from 'components/shared/Dialog/Dialog';
import DialogActions from 'components/shared/Dialog/DialogActions/DialogActions';
import DialogContent from 'components/shared/Dialog/DialogContent/DialogContent';
import DialogTitle from 'components/shared/Dialog/DialogTitle/DialogTitle';
import List from 'components/shared/List/List';
import ListItem from 'components/shared/List/ListItem/ListItem';
import ListItemText from 'components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText';
import Typography from 'components/shared/Typography/Typography';

interface IChangeTicketStatusModalProps {
  open: boolean;
  moderator: string | null;
  status: boolean;
  violations: string[];
  onClose: () => void;
  onChangeStatus?: () => void;
}

const ChangeTicketStatusModal = ({
  open,
  moderator,
  status,
  violations,
  onClose,
  onChangeStatus,
}: IChangeTicketStatusModalProps) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle variant="h6">You are going to change {moderator} decision</DialogTitle>
      <DialogContent sx={{ maxHeight: '184px' }}>
        {status && (
          <>
            <Typography variant="body1">Confirm that entity has violations for:</Typography>
            <List disablePadding sx={{ listStyleType: 'disc', pl: 3 }}>
              {violations.map((violation) => (
                <ListItem disablePadding sx={{ display: 'list-item' }} key={violation}>
                  <ListItemText>{violation}</ListItemText>
                </ListItem>
              ))}
            </List>
          </>
        )}
        {!status && <Typography variant="body1">Confirm that entity has no violations</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text" color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            onChangeStatus && onChangeStatus();
            onClose();
          }}
          variant="contained"
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeTicketStatusModal;
