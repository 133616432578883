import { EntityStatus, OrderedEntity } from "store/collectionDetailsSlice";

const changeOrderAndStatus = (
  entities: OrderedEntity[],
  entityType: 'BANNER' | 'HERO' | 'OFFER' | 'WISH' | 'EVENT'
): OrderedEntity[] => {
  const maxActive = entityType === 'BANNER' ? 5 : 15;

  const reorderedEntities = entities.map((entity, index) => {
    return {...entity, order: index, entityStatus: reorderStatus(entity, maxActive, index) }
  })
  return reorderedEntities;
};

export default changeOrderAndStatus;

const reorderStatus = (entity: OrderedEntity, maxActive: number, index: number): EntityStatus => {
  if (entity.entityStatus === 'UNAVAILABLE') {
    return entity.entityStatus;
  }

  return index + 1 > maxActive ? 'SPARE' : 'ACTIVE';
}
