import Button from 'components/shared/Button/Button';
import Dialog from 'components/shared/Dialog/Dialog';
import DialogActions from 'components/shared/Dialog/DialogActions/DialogActions';
import DialogContent from 'components/shared/Dialog/DialogContent/DialogContent';
import DialogTitle from 'components/shared/Dialog/DialogTitle/DialogTitle';
import Typography from 'components/shared/Typography/Typography';

interface IChangeStatusWarningModalProps {
  open: boolean;
  onClose: () => void;
}

export const ChangeStatusWarningModal = ({ open, onClose }: IChangeStatusWarningModalProps) => {
  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle variant="h6">This tax code cannot be made inactive</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          This tax code is used in the rules on the "Rules for sales tax" page. You can't make it inactive
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Got It
        </Button>
      </DialogActions>
    </Dialog>
  );
};
