import { IconButton as IconButtonMUI } from "@mui/material";
import { IconButtonProps } from "@mui/material/IconButton/IconButton";

const IconButton = (props: IconButtonProps) => {
  return (
    <IconButtonMUI {...props} />
  )
};

export default IconButton;
