import { GridRenderCellParams } from '@mui/x-data-grid';
import { possiblePaymentMethods, possibleTransactionsTypes } from '../constants';
import { TransactionTypes } from 'store/slices/Payments/interface';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';

/**
 * Props for the RenderProviderCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 */
interface IRenderProviderCellProps {
  props: GridRenderCellParams;
}

/**
 * Renders a "Provider / type" cell in a data grid.
 */
export const RenderProviderCell = ({ props }: IRenderProviderCellProps) => {
  const { row } = props;

  if (!row.id) {
    return <></>;
  }

  const currentTransactionType = possibleTransactionsTypes.find(
    (el) => el.transactionType?.toLowerCase() === row.type?.toLowerCase(),
  );

  let providerName = 'Stripe';
  if (currentTransactionType) {
    switch (currentTransactionType.transactionType) {
      case TransactionTypes.APPLE_IAP:
        providerName = 'Apple';
        break;
      case TransactionTypes.GOOGLE_IAP:
        providerName = 'Google';
        break;
    }
  }

  const paymentMethodName =
    currentTransactionType?.transactionType !== TransactionTypes.GOOGLE_IAP &&
    currentTransactionType?.transactionType !== TransactionTypes.APPLE_IAP &&
    possiblePaymentMethods.find((el) => el.paymentMethod?.toLowerCase() === row.paymentMethodName?.toLowerCase());

  return (
    <Stack>
      <Typography variant="body2">{providerName || '—'}</Typography>
      <Typography variant="caption" color="text.secondary">
        {currentTransactionType?.title || '—'}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        {paymentMethodName && paymentMethodName.title}
      </Typography>
    </Stack>
  );
};
