import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DragIndicatorIcon from 'assets/img/DragIndicatorIcon';
import uniqueId from 'lodash/uniqueId';
import {
  addColumn,
  applyColumns,
  clearColumns,
  deletePreset,
  fetchColumnsPresets,
  removeColumn,
  savePreset,
  setActivePreset,
  setNewColumnsArray,
} from 'store/columnsSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import CloseIcon from 'assets/img/CloseIcon';
import Button from 'components/shared/Button/Button';
import Chip from 'components/shared/Chip/Chip';
import Dialog from 'components/shared/Dialog/Dialog';
import DialogActions from 'components/shared/Dialog/DialogActions/DialogActions';
import DialogContent from 'components/shared/Dialog/DialogContent/DialogContent';
import DialogTitle from 'components/shared/Dialog/DialogTitle/DialogTitle';
import Divider from 'components/shared/Divider/Divider';
import IconButton from 'components/shared/IconButton/IconButton';
import Modal from 'components/shared/Modal/Modal';
import Switch from 'components/shared/Switch/Switch';
import TextField from 'components/shared/TextField/TextField';
import Typography from 'components/shared/Typography/Typography';
import colors from 'theme/constants/colors';
import isEqual from 'lodash/isEqual';
import './index.scss';

const ColumnsDrawer = (props: any) => {
  const dispatch = useAppDispatch();

  const selectedColumns = useAppSelector((state) => state.columns.currentData);
  const presets = useAppSelector((state) => state.columns.presets);
  const allColumns = useAppSelector((state) => state.columns.columns);

  const setPreset = useCallback(
    (presetId: any) => {
      dispatch(setActivePreset({ type: props.type, presetId: presetId }));
    },
    [dispatch],
  );

  const activePresetId = useAppSelector((state) => state.columns.activePresetId);

  const [savePresetModal, setSavePresetModal] = useState<boolean>(false);
  const [presetName, setPresetName] = useState<string>('');
  const [deletePresetModal, setDeletePresetModal] = useState<boolean>(false);
  const [deletedPreset, setDeletedPreset] = useState<any | null>(null);
  const [isSwitchChanged, setIsSwitchChanged] = useState(false);

  const showDeletePresetModal = useCallback((preset: any) => {
    setDeletedPreset(preset);
    setDeletePresetModal(true);
  }, []);

  const handleChangeSavePresetModalClose = useCallback(() => {
    setSavePresetModal(false);
  }, []);

  const handleChangeDeletePresetModalClose = useCallback(() => {
    setDeletePresetModal(false);
  }, []);

  const handleSavePreset = useCallback(
    async (presetName: string) => {
      await dispatch(savePreset({ type: props.type, presetName: presetName }));
      handleChangeSavePresetModalClose();
      await dispatch(fetchColumnsPresets(props.type));
    },
    [props.type],
  );

  const handleDeletePreset = useCallback(
    async (presetId: string) => {
      await dispatch(deletePreset({ type: props.type, presetId: presetId }));
      handleChangeDeletePresetModalClose();
      await dispatch(fetchColumnsPresets(props.type));
    },
    [props.type],
  );

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const newArray = reorder(selectedColumns, result.source.index, result.destination.index);
    dispatch(setNewColumnsArray(newArray));
  };

  // const inactiveColumns = useMemo(() => {
  //   console.log(allColumns, data);
  //   return allColumns.filter(
  //     (item: any) => data.findIndex((dataItem: any) => dataItem.columnName === item.columnName) === -1,
  //   );
  // }, [data]);

  const handleSwitchChange = (columnName: any, isChecked: any) => {
    if (isChecked) {
      dispatch(addColumn({ columnName }));
    } else {
      const indexToRemove = selectedColumns.findIndex((item) => item.columnName === columnName);
      if (indexToRemove !== -1) {
        dispatch(removeColumn({ index: indexToRemove }));
      }
    }
  };

  return (
    <div className="columnsDrawer--container">
      <div className="columnsDrawer--header">
        <Typography variant="h5">Columns</Typography>
        <IconButton size="small" onClick={props.close}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="columnsDrawer--content">
        {presets.length > 0 && (
          <div className="columnsDrawer--group">
            <Typography mb={1} variant="h6">
              Presets
            </Typography>
            <div style={{ marginBottom: '16px' }}>
              {presets.map((preset: any) => {
                return (
                  <Chip
                    label={preset.presetName}
                    onDelete={() => showDeletePresetModal(preset)}
                    onClick={() => setPreset(preset.presetId)}
                    active={preset.presetId === activePresetId}
                  />
                );
              })}
            </div>
            <Divider flexItem orientation="horizontal" />
          </div>
        )}
        <div className="columnsDrawer--group">
          <Typography variant="h6">Selected columns</Typography>
          <div style={{ marginBottom: '16px' }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided: any) => (
                  <div key={uniqueId()} ref={provided.innerRef} {...provided.droppableProps}>
                    {selectedColumns.map((item: any, index: any) =>
                      item.columnName === 'username' ? (
                        <div key={index} className="columnsDrawer--item" style={{ padding: '8px 0' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100%',
                            }}
                          >
                            <div className="columnsDrawer--item-title">
                              <DragIndicatorIcon sx={{ color: colors.actionDisabled, opacity: '40%' }} />
                              <div>{item.title}</div>
                            </div>
                            <div style={{ justifySelf: 'end' }}>
                              <Switch checked={true} disabled />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <Draggable
                          key={uniqueId()}
                          draggableId={index.toString()}
                          index={index}
                          isDragDisabled={item.columnName === 'username'}
                        >
                          {(providedItem, snapshot) => {
                            const iconOpacity = snapshot.isDragging ? '40%' : '100%';

                            return (
                              <div
                                ref={providedItem.innerRef}
                                {...providedItem.draggableProps}
                                {...providedItem.dragHandleProps}
                                className={`columnsDrawer--item${snapshot.isDragging ? '--dragging' : ''}`}
                              >
                                <div style={{ padding: '8px 0' }}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      width: '100%',
                                    }}
                                  >
                                    <div className="columnsDrawer--item-title">
                                      <DragIndicatorIcon sx={{ color: colors.actionDisabled, opacity: iconOpacity }} />
                                      <div>{item.title}</div>
                                    </div>
                                    <div style={{ justifySelf: 'end' }}>
                                      <Switch
                                        checked={true}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                          handleSwitchChange(item.columnName, event.target.checked)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }}
                        </Draggable>
                      ),
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <Divider flexItem orientation="horizontal" />
        </div>
        {allColumns.length > 0 && (
          <div className="columnsDrawer--group">
            <Typography variant="h6">Available columns</Typography>
            {allColumns.map((item: any) => (
              <div style={{ padding: '8px 0' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                  <div>{item.title}</div>
                  <div style={{ justifySelf: 'end' }}>
                    <Switch
                      disabled={item.columnName === 'username'}
                      checked={
                        selectedColumns.findIndex(
                          (selectedItem: any) => selectedItem.columnName === item.columnName,
                        ) !== -1
                      }
                      onChange={(event) => handleSwitchChange(item.columnName, event.target.checked)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="columnsDrawer--footer">
        <Button
          style={{ marginRight: '24px', width: '214px', height: '42px' }}
          variant="contained"
          onClick={() => {dispatch(applyColumns()); props.apply()}}
        >
          Apply
        </Button>
        <Button
          disabled
          style={{ width: '114px', height: '42px' }}
          variant="outlined"
          onClick={() => {
            setSavePresetModal(true);
          }}
        >
          Save set
        </Button>
      </div>
      <Dialog fullWidth maxWidth="xs" open={savePresetModal}>
        <DialogTitle variant="h6">Save new preset</DialogTitle>
        <DialogContent>
          <Typography mb={2} variant="subtitle1">
            Enter name for new filter preset
          </Typography>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            label="Name"
            onChange={(event: ChangeEvent<HTMLInputElement>) => setPresetName(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="primary" onClick={() => handleChangeSavePresetModalClose()}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={() => handleSavePreset(presetName)}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Modal customstyle={{ minHeight: 188 }} open={deletePresetModal} onClose={handleChangeDeletePresetModalClose}>
        <div className="deletePresetModal">
          <div>
            <div className="deletePresetModal--header">Delete preset?</div>
            <div className="deletePresetModal--text">You are going to delete preset {deletedPreset?.presetName}</div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'end', justifySelf: 'end', width: '100%' }}>
            <Button style={{ marginRight: '16px' }} onClick={() => handleChangeDeletePresetModalClose()}>
              Cancel
            </Button>
            <Button variant="contained" onClick={() => handleDeletePreset(deletedPreset?.presetId)}>
              Delete
            </Button>
          </div>
        </div>
      </Modal>

    </div>
  );
};

export default ColumnsDrawer;
