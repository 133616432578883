import { Dispatch, SetStateAction, useEffect } from 'react';
import colors from 'theme/constants/colors';
import CheckCircleIcon from 'assets/img/CheckCircleIcon';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';
import './index.scss';

interface IModerationGridFooterProps {
  isAllLoaded: boolean;
  setScrollEnd: Dispatch<SetStateAction<boolean>>;
}

const ModerationGridFooter = ({ isAllLoaded, setScrollEnd }: IModerationGridFooterProps) => {
  useEffect(() => {
    if (isAllLoaded) {
      const timeoutId = setTimeout(function () {
        setScrollEnd(false);
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [isAllLoaded, setScrollEnd]);

  return (
    <div id="grid-footer-container" className="container">
      {isAllLoaded && (
        <Stack mt={2} alignItems="center">
          <CheckCircleIcon sx={{ fontSize: 32, color: colors.outlinedBorder }} />
          <Typography variant="h6" color={colors.outlinedBorder}>
            No more tickets
          </Typography>
        </Stack>
      )}
    </div>
  );
};

export default ModerationGridFooter;
