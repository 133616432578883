import React, { MouseEvent } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import Button from 'components/shared/Button/Button';

/**
 * Props for the RenderActionCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - The properties of the RenderSolutionCell component.
 * @property {(e: MouseEvent<HTMLButtonElement>, data: any) => void} onClick - Click event handler.
 */
interface IRenderActionCellProps {
  props: GridRenderCellParams;
  onClick: (e: MouseEvent<HTMLButtonElement>, data: any) => void;
}

/**
 * Renders an action cell in a data grid.
 */
export const RenderActionCell = ({ props, onClick }: IRenderActionCellProps) => {
  const { row } = props;

  const handleClick = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    onClick(e, row);
  };

  if (!row.consumptionRequestId) {
    return <></>;
  }

  return (
    <Button size="small" variant="contained" disableElevation disableRipple onClick={(e) => handleClick(e)}>
      Resolve
    </Button>
  );
};
