import { SortEnd, SortEvent, SortEventWithTag, SortStart } from "react-sortable-hoc";
import CollectionEntity from "components/WorldManagement/Collections/CollectionDetails/CollectionEntity/CollectionEntity";
import { useCallback, useEffect, useState } from "react";
import { OrderedEntity } from "store/collectionDetailsSlice";
import { arrayMoveImmutable } from 'array-move';
import SortableList from "components/shared/SortableList/SortableList";
import SortableItem from "components/shared/SortableList/SortableItem";
import Button from "components/shared/Button/Button";
import DeleteIcon from "assets/img/DeleteIcon";
import changeOrderAndStatus from "components/WorldManagement/Collections/CollectionDetails/utils/changeOrderAndStatus";

import './index.scss';

interface IProps {
  items: OrderedEntity[] | [];
  heightEntity: number;
  widthEntity: number;
  parentsWidth?: number;
  entityType: 'BANNER' | 'HERO' | 'OFFER' | 'WISH' | 'EVENT';
  isCanDrag: boolean;
  setIsDeleteEntities(entities: string[]): void;
  isDefaultCollection: boolean;
  collectionId: string;
  onSortEntities(entities: OrderedEntity[]): void;
}

const CollectionBoard = ({
  items,
  heightEntity,
  widthEntity,
  entityType,
  parentsWidth,
  isCanDrag,
  setIsDeleteEntities,
  isDefaultCollection,
  collectionId,
  onSortEntities,
}: IProps) => {
  const [entities, setEntities] = useState<OrderedEntity[] | []>([]);
  const [maxAvailableIndex, setMaxAvailableIndex] = useState<number>();
  const [selectedEntities, setSelectedEntities] = useState<string[]>([]);
  const [maxWidth, setMaxWidth] = useState(0);

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    const newAvailableIndex = maxAvailableIndex && newIndex >= maxAvailableIndex ? (maxAvailableIndex - 1): newIndex;
    const sortedArray = arrayMoveImmutable(entities, oldIndex, newAvailableIndex);
    const ordered = changeOrderAndStatus(sortedArray, entityType);
    onSortEntities(ordered);
    setEntities(ordered);
  };

  const onSortStart = ({ helper }: SortStart) => {
    helper.setAttribute('isDragging', 'true');
  };

  const initialEntitiesOrder = useCallback(() => {
    const unavailable: OrderedEntity[] | [] = items.filter(({ entityStatus }) => entityStatus === 'UNAVAILABLE');
    const available: OrderedEntity[] | [] = items.filter(({ entityStatus }) => entityStatus !== 'UNAVAILABLE');
    setMaxAvailableIndex(available.length);
    const orderedArray = [...available].sort((item1, item2) => item1.order - item2.order);
    setEntities([...orderedArray, ...unavailable]);
  }, [items]);

  const toggleSelectedEntity = (entity: OrderedEntity, isCheck: boolean) => {
    setSelectedEntities((prevState) => {
      return isCheck ? [...prevState, entity.entityId] : prevState?.filter(( entityId ) => entityId !== entity.entityId)
    })
  }

  useEffect(() => {
    /* Gap между энтити 8 */
    const maxEntity = Math.floor((parentsWidth! + 8) / (widthEntity + 8));
    setMaxWidth(maxEntity * (widthEntity + 8));
  }, [parentsWidth, widthEntity]);

  useEffect(() => {
    initialEntitiesOrder();
    setSelectedEntities((prevState) => {
      return prevState.filter((value) => items.find(({ entityId }) => entityId === value ));
    })
  }, [items]);

  return (
    <div className="collectionBoard" style={{ width: `${maxWidth}px` }}>
      {selectedEntities.length > 0 &&
        <div className="collectionBoard-toolbar">
          <div>
            <span className="collectionBoard-toolbar-selectedAmount">
              {selectedEntities.length} selected
            </span>
            <Button
              variant="text"
              className="collectionBoard-toolbar-selectButton"
              onClick={() => setSelectedEntities(entities.map(({ entityId }) => entityId))}
            >
              Select all
            </Button>
            <Button
              variant="text"
              className="collectionBoard-toolbar-selectButton"
              onClick={() => setSelectedEntities([])}
            >
              Deselect all
            </Button>
          </div>
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => setIsDeleteEntities(selectedEntities)}
          >
            Delete
          </Button>
        </div>
      }
      {entities &&
        <SortableList
          className="collectionBoard-flex"
          onSortStart={(sort) => onSortStart(sort)}
          onSortEnd={(sort) => onSortEnd(sort)}
          axis="xy"
          shouldCancelStart={(e: SortEvent | SortEventWithTag) => {
            // @ts-ignore
            if ((e as SortEventWithTag) && ['input','button','svg','g','path'].indexOf(e.target.tagName?.toLowerCase()) !== -1) {
              return true; // Return true to cancel sorting
            }
            return false;
          }}
        >
          {entities.map((value: OrderedEntity, index) => (
            <SortableItem
              disabled={!isCanDrag || value.entityStatus === 'UNAVAILABLE'}
              key={`item-${value.compilationEntityId}`}
              index={index}
            >
              <CollectionEntity
                isSelectedEntity={selectedEntities.includes(value.entityId)}
                toggleSelectedEntity={toggleSelectedEntity}
                entity={value}
                widthEntity={widthEntity}
                heightEntity={heightEntity}
                entityType={entityType}
                isCanDrag={isCanDrag && value.entityStatus !== 'UNAVAILABLE'}
                setDeleteEntity={setIsDeleteEntities}
                isDefaultCollection={isDefaultCollection}
                collectionId={collectionId}
              />
            </SortableItem>
          ))}
        </SortableList>
      }
    </div>
  )
};

export default CollectionBoard;
