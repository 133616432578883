import * as yup from 'yup';

const getValidationSchema = () => {
  return yup.object({
    firstName: yup
      .string()
      .matches(
        /^(?:[a-zA-Z\s\-\']*)$/,
        'First name must contain only Latin letters A-Z, a-z; space and special symbols \'-',
      )
      .matches(/^(?:[a-zA-Z\s\-\.]*[a-zA-Z][a-zA-Z\s\-\']*)$/, 'First name must contain Latin letters')
      .min(2, 'First name should be at least 2 symbols'),
    lastName: yup
      .string()
      .matches(
        /^(?:[a-zA-Z\s\-\']*)$/,
        'Last name must contain only Latin letters A-Z, a-z; space and special symbols \'-',
      )
      .matches(/^(?:[a-zA-Z\s\-\.]*[a-zA-Z][a-zA-Z\s\-\']*)$/, 'Last name must contain Latin letters')
      .min(2, 'Last name should be at least 2 symbols'),
    email: yup
      .string()
      .required('Enter valid email')
      .matches(
        /^([\w][\!\#\$\%\'\*\+\-\/\=\?\^\_\`\{\|\}\~\.]?){2,192}(?<![\w][\!\#\$\%\'\*\+\-\/\=\?\^\_\`\{\|\}\~\.])(@{1})(([\w][\-]?){1,63})(?<![-])([\.]{1})(([\w][\-]?){2,})(?<![-])$/,
        'Enter valid email',
      ),
  });
};

export default getValidationSchema;
