import { GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';

/**
 * Props for the RenderReceivedCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 */
interface IRenderReceivedCellProps {
  props: GridRenderCellParams;
}

/**
 * Renders a "Received (time)" cell in a data grid.
 */
export const RenderReceivedCell = ({ props }: IRenderReceivedCellProps) => {
  const { row } = props;

  if (!row.id) {
    return <></>;
  }

  const formattedDate = dayjs(row.createdAt).format('MM/DD/YYYY');
  const formattedTime = dayjs(row.createdAt).format('h:mm A');

  return (
    <Stack>
      <Typography variant="body2">{formattedDate || '—'}</Typography>
      <Typography variant="caption" color="text.secondary">
        {formattedTime || '—'}
      </Typography>
    </Stack>
  );
};
