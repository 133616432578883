import * as yup from "yup";

const getValidationSchema = (action: 'add' | 'edit') => {
  if (action === 'add') {
    return yup.object().shape({
      name: yup.string()
        .required('Name is a required field'),
      title: yup.string().max(70, ''),
      subtitle: yup.string().max(50, ''),
      // cover: yup.mixed<File>().test(
      //   "required",
      //   "Please select a file",
      //   files => !files),
    });
  }

  return yup.object().shape({
    name: yup.string()
      .required('Name is a required field'),
    title: yup.string().max(70, ''),
    subtitle: yup.string().max(50, ''),
    expiredDate: yup.date().nullable(''),//.min(new Date(), ''),
    article: yup.object().shape({
      editorString: yup.string(),
      textString: yup.string().max(2000, ''),
    }),
  });
}

export default getValidationSchema;