import { GridToolbarContainer } from "@mui/x-data-grid";
import Button from "components/shared/Button/Button";
import ArchiveIcon from "assets/img/ArchiveIcon";
import { GridRowId, GridRowModel } from "@mui/x-data-grid/models/gridRows";
import { Banner } from "store/bannerDetailsSlice";
import { useEffect, useState } from "react";
import { Collection } from "store/collectionDetailsSlice";
import Tooltip from "components/shared/Tooltip/Tooltip";
import DeleteIcon from "assets/img/DeleteIcon";
import CheckIcon from "assets/img/CheckIcon";
import { Cohort } from "store/cohortDetailsSlice";

import './index.scss';

interface IProps {
  selectedRows: Map<GridRowId, GridRowModel>;
  setArchiveBanners?: (items: Banner[]) => void;
  setDeleteCohorts?: (items: Cohort[]) => void;
  setArchiveCollections?: (items: Collection[]) => void;
  setPublishCollections?: (items: Collection[]) => void;
}

const CustomToolbar = ({ selectedRows, setArchiveBanners, setDeleteCohorts, setArchiveCollections, setPublishCollections }: IProps) => {
  const [banners, setBanners] = useState<Banner[]>([]);
  const [cohorts, setCohorts] = useState<Cohort[]>([]);
  const [collections, setCollections] = useState<Collection[]>([]);
  const [isLive, setIsLive] = useState(false);
  const [isReadyForLive, setIsReadyForLive] = useState(true);
  const selectedCount = selectedRows.size;

  useEffect(() => {
    if (setArchiveBanners) {
      let arr: Banner[] = [];
      selectedRows.forEach((value) => {
        arr.push(value as Banner);
      });
      setBanners(arr);
      for (const element of arr) {
        if (element.status === 'LIVE') {
          setIsLive(true);
          return;
        }
      }
      setIsLive(false);
    }
    if (setDeleteCohorts) {
      let arr: Cohort[] = [];
      selectedRows.forEach((value) => {
        arr.push(value as Cohort);
      });
      setCohorts(arr);
      for (const element of arr) {
        if (element.status === 'ACTIVE' || element.hasCompilation) {
          setIsLive(true);
          return;
        }
      }
      setIsLive(false);
    }
    if (setArchiveCollections || setPublishCollections) {
      let arr: Collection[] = [];
      selectedRows.forEach((value) => {
        arr.push(value as Collection);
      });
      setCollections(arr);
      setIsReadyForLive(true);
      setIsLive(false);

      for (const element of arr) {
        if (element.status === 'LIVE') {
          setIsLive(true);
          break;
        }
      }
      for (const element of arr) {
        if (element.status !== 'READY' && element.status !== 'SCHEDULED') {
          setIsReadyForLive(false);
          break;
        }
      }

    }
  }, [selectedRows]);

  return (
    <>
      {!!selectedCount && <div className="customToolbar">
        <GridToolbarContainer>
          <span className="customToolbar-selectNumber">{selectedCount} selected</span>
          <span className="customToolbar-selectActions">
            {setArchiveBanners &&
              <Button
                variant="outlined"
                disabled={isLive}
                color="error"
                startIcon={<ArchiveIcon />}
                onClick={() => {
                  setArchiveBanners(banners as Banner[]);
                }}
              >
                Archive
              </Button>
            }
            {setDeleteCohorts &&
              <Tooltip title="You can not delete cohorts linked to collections" placement="top-start">
              <span>
                <Button
                  variant="outlined"
                  disabled={isLive}
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    setDeleteCohorts(cohorts as Cohort[]);
                  }}
                >
                  Delete
                </Button>
              </span>
              </Tooltip>
            }
            {setPublishCollections &&
              <Button
                variant="outlined"
                disabled={!isReadyForLive}
                color="primary"
                onClick={() => {
                  setPublishCollections(collections as Collection[]);
                }}
                startIcon={<CheckIcon />}
              >
                Publish
              </Button>
            }
            {setArchiveCollections &&
              <Button
                variant="outlined"
                disabled={isLive}
                color="error"
                startIcon={<ArchiveIcon />}
                onClick={() => {
                  setArchiveCollections(collections as Collection[]);
                }}
              >
                Archive
              </Button>
            }
          </span>
        </GridToolbarContainer>
      </div>}
    </>
  )
};

export default CustomToolbar;
