import axios, { isAxiosError } from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITier, ITiersState, ITierStatus } from './interface';
import { RootState } from 'store/store';
import { IStatus } from 'types/commonTypes';

const initialState: ITiersState = {
  tiers: [],
  totalCount: 0,
  providerOptions: [],
  operationTypesOptions: [],
  linkIdOptions: [],
  status: 'idle',
};

export const fetchTiers = createAsyncThunk(
  'payments/inAppTiers',
  async ({ page, pageSize }: { page: number; pageSize: number }, { rejectWithValue, getState }) => {
    try {
      const rootState: RootState = getState() as RootState;
      const filters = Object.keys(rootState.filters.data)
        ? Object.values(rootState.filters.data).filter((item: any) => item !== null)
        : [];
      const sortingList = Object.keys(rootState.sorting.data)
        ? Object.values(rootState.sorting.data).filter(
            (item: any) => item !== null && item.columnId !== null && item.sortingType !== null,
          )
        : [];
      const response = await axios.post(process.env.REACT_APP_PAYMENTS_ENDPOINT + 'payment-adapter/tiers/all', {
        pageInfo: {
          page: page + 1,
          size: pageSize,
        },
        sortingList: sortingList,
        filters: filters,
      });

      return response.data;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue({
          message: error.message || 'An unexpected error occurred',
          status: error.response?.status,
        });
      } else {
        return rejectWithValue({
          message: 'An unexpected error occurred',
        });
      }
    }
  },
);

export const fetchAddTier = createAsyncThunk(
  'payments/addInAppTier',
  async (
    { country, currency, linkId, operationType, price, productId, providerId, name }: Omit<ITier, 'status'>,
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.post((process.env.REACT_APP_PAYMENTS_ENDPOINT as string) + 'payment-adapter/tiers', {
        country,
        currency,
        linkId,
        operationType,
        price,
        productId,
        providerId,
        name,
      });

      return response.data;
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data);
    }
  },
);

export const fetchDropdownOptions = createAsyncThunk(
  'payments/tiers/providers',
  async (
    {
      fieldForDropdown,
      additionalValue,
    }: {
      fieldForDropdown: string;
      additionalValue?: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.get(
        (process.env.REACT_APP_PAYMENTS_ENDPOINT as string) + `payment-adapter/tiers/dropdown/${fieldForDropdown}`,
        {
          params: {
            additionalValue: additionalValue,
          },
        },
      );

      return response.data;
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data);
    }
  },
);

export const fetchChangeStatus = createAsyncThunk(
  'payments/tiers/changeStatus',
  async (
    {
      currentSelectedTier,
      currentSelectedStatus,
    }: {
      currentSelectedTier: ITier | null;
      currentSelectedStatus: ITierStatus | null;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.patch(
        (process.env.REACT_APP_PAYMENTS_ENDPOINT as string) + 'payment-adapter/tiers',
        {
          productId: currentSelectedTier?.productId,
          status: currentSelectedStatus?.status,
        },
      );

      return response.data;
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data);
    }
  },
);

export const tiersSlice = createSlice({
  name: 'tiers',
  initialState,
  reducers: {
    setTiersStatus: (state, action: PayloadAction<IStatus>) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTiers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTiers.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.tiers = payload.tiers;
        state.totalCount = payload.totalCount;
      })
      .addCase(fetchTiers.rejected, (state) => {
        state.status = 'failed';
        state.tiers = [];
        state.totalCount = 0;
      })
      .addCase(fetchAddTier.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAddTier.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(fetchAddTier.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchDropdownOptions.fulfilled, (state, { payload, meta }) => {
        const { fieldForDropdown } = meta.arg;

        if (fieldForDropdown === 'provider') {
          state.providerOptions = payload.values;
        } else if (fieldForDropdown === 'operation-type') {
          state.operationTypesOptions = payload.values;
        } else if (fieldForDropdown === 'link-id') {
          state.linkIdOptions = payload.values;
        }
      })
      .addCase(fetchChangeStatus.fulfilled, (state) => {
        state.status = 'idle';
      });
  },
});

export const selectTiers = (state: RootState): ITier[] => state.tiers.tiers;
export const selectTotalCount = (state: RootState): number => state.tiers.totalCount;
export const selectTiersStatus = (state: RootState): IStatus => state.tiers.status;
export const selectProviderOptions = (state: RootState) => state.tiers.providerOptions;
export const selectOperationTypesOptions = (state: RootState) => state.tiers.operationTypesOptions;
export const selectLinkIdOptions = (state: RootState) => state.tiers.linkIdOptions;

export const { setTiersStatus } = tiersSlice.actions;

export default tiersSlice.reducer;
