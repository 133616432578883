import Carousel from 'react-material-ui-carousel';
import colors from 'theme/constants/colors';
import UsdLockedIcon from 'assets/img/UsdLockedIcon';
import ZoomInIcon from 'assets/img/ZoomInIcon';
import IconButton from 'components/shared/IconButton/IconButton';

const LifestyleCard = (props: any) => {
  const widthEntity = 200;
  const heightEntity = 300;
  const isPaidLifeStyle = props.data.price && props.data.priceOption !== 'FREE';

  return props.data.orderedMediaList.length > 1 ? (
    <Carousel
      height={300}
      animation="slide"
      autoPlay={false}
      swipe={false}
      className="lifestyleCard--Carousel"
      navButtonsAlwaysInvisible={true}
      indicatorIconButtonProps={{ className: 'carouselIcon', style: { color: colors.actionDisabled } }}
      activeIndicatorIconButtonProps={{ style: { color: colors.primary } }}
      indicatorContainerProps={{ style: { display: 'flex', justifyContent: 'center', gap: '4px' } }}
    >
      {props.data.orderedMediaList.map((el: any, index: number) => (
        <div key={index} className="carouselEntity">
          <div
            className="overlay"
            style={{ width: widthEntity, height: heightEntity }}
            onClick={() => {
              props.showMedia(
                el.mediaUrl ? el.mediaUrl : 'https://www.svgrepo.com/show/33921/sea-turtle.svg',
                el,
                el.mediaType === 'VIDEO' ? 'video' : 'Image',
                props.data.orderedMediaList ?? [],
              );
            }}
          >
            <ZoomInIcon />
            VIEW MEDIA
          </div>
          <div className="lifestyleCard--background">
            <img
              src={!!el?.mediaPreviewUrl ? el.mediaPreviewUrl : 'https://www.svgrepo.com/show/33921/sea-turtle.svg'}
              height={heightEntity}
              width={widthEntity}
              alt="Lifestyle media"
            />
          </div>
          {isPaidLifeStyle && (
            <IconButton className="lifestyleCardContent--paidIcon" size="small" disableRipple disabled>
              <UsdLockedIcon sx={{ color: colors.white }} />
            </IconButton>
          )}
        </div>
      ))}
    </Carousel>
  ) : (
    <div className="carouselEntity">
      <div
        className="overlay"
        style={{ width: widthEntity, height: heightEntity }}
        onClick={() => {
          const mediaItem = props.data.orderedMediaList[0] || {
            mediaUrl: 'https://www.svgrepo.com/show/33921/sea-turtle.svg',
            mediaType: 'Image',
          };

          props.showMedia(
            mediaItem.mediaUrl,
            mediaItem,
            mediaItem.mediaType === 'VIDEO' ? 'video' : 'Image',
            props.data.orderedMediaList,
          );
        }}
      >
        <ZoomInIcon />
        VIEW MEDIA
      </div>
      <div className="lifestyleCard--background">
        <img
          src={
            !!props.data.orderedMediaList[0]?.mediaPreviewUrl
              ? props.data.orderedMediaList[0].mediaPreviewUrl
              : 'https://www.svgrepo.com/show/33921/sea-turtle.svg'
          }
          alt="Lifestyle media"
        />
      </div>
      {isPaidLifeStyle && (
        <IconButton className="lifestyleCardContent--paidIcon" size="small" disableRipple disabled>
          <UsdLockedIcon sx={{ color: colors.white }} />
        </IconButton>
      )}
    </div>
  );
};

export default LifestyleCard;
