import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Radio } from '@mui/material';
import uniqueId from 'lodash/uniqueId';
import {
  addLevel,
  changeSortingField,
  changeSortingType,
  deletePreset,
  fetchSortingPresets,
  removeSortingField,
  savePreset,
  setActivePreset,
  setNewSortingArray,
} from 'store/sortingSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectActivePresetId } from 'store/filtersSlice';
import CloseIcon from 'assets/img/CloseIcon';
import DeleteIcon from 'assets/img/DeleteIcon';
import DragIndicatorIcon from 'assets/img/DragIndicatorIcon';
import Autocomplete from 'components/shared/Autocomplete/Autocomplete';
import Button from 'components/shared/Button/Button';
import Chip from 'components/shared/Chip/Chip';
import IconButton from 'components/shared/IconButton/IconButton';
import Modal from 'components/shared/Modal/Modal';
import Stack from 'components/shared/Stack/Stack';
import TextField from 'components/shared/TextField/TextField';
import Typography from 'components/shared/Typography/Typography';
import './index.scss';

const SortingDrawer = (props: any) => {
  const dispatch = useAppDispatch();

  const allColumns = useAppSelector((state) => state.sorting.columns);
  const presets = useAppSelector((state) => state.sorting.presets);
  const data = useAppSelector((state) => state.sorting.data);

  const setPreset = useCallback(
    (presetId: any) => {
      dispatch(setActivePreset({ type: props.type, presetId: presetId }));
    },
    [dispatch],
  );

  const activePresetId = useAppSelector(selectActivePresetId);

  const [savePresetModal, setSavePresetModal] = useState<boolean>(false);
  const [presetName, setPresetName] = useState<string>('');
  const [deletePresetModal, setDeletePresetModal] = useState<boolean>(false);
  const [deletedPreset, setDeletedPreset] = useState<any | null>(null);

  const showDeletePresetModal = useCallback((preset: any) => {
    setDeletedPreset(preset);
    setDeletePresetModal(true);
  }, []);

  const handleChangeSavePresetModalClose = useCallback(() => {
    setSavePresetModal(false);
  }, []);

  const handleChangeDeletePresetModalClose = useCallback(() => {
    setDeletePresetModal(false);
  }, []);

  const handleSavePreset = useCallback(
    async (presetName: string) => {
      await dispatch(savePreset({ type: props.type, presetName: presetName }));
      handleChangeSavePresetModalClose();
      await dispatch(fetchSortingPresets(props.type));
    },
    [props.type],
  );

  const handleDeletePreset = useCallback(
    async (presetId: string) => {
      await dispatch(deletePreset({ type: props.type, presetId: presetId }));
      handleChangeDeletePresetModalClose();
      await dispatch(fetchSortingPresets(props.type));
    },
    [props.type],
  );

  const sorting = useMemo(() => {
    // console.log(filters);
    return data;
  }, [data]);

  const possibleColumns = useMemo(() => {
    const columns = allColumns.filter(
      (item: any) =>
        data.findIndex((value: any) => value?.columnName === item.columnName && value?.columnId === item.columnId) ===
        -1,
    );
    // console.log(allColumns, columns, data);
    return columns.map((item: any) => ({ label: item.title, id: item.columnName ?? item.columnId }));
  }, [data]);

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const newArray = reorder(sorting, result.source.index, result.destination.index);
    dispatch(setNewSortingArray(newArray));
  };

  return (
    <div className="sortingDrawer--container">
      <div className="sortingDrawer--header">
        <Typography variant="h5">Sort</Typography>
        <IconButton size="small" onClick={() => props.close()}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="sortingDrawer--content">
        {!props.isHidePresets && (
          <div className="sortingDrawer--group" style={{ borderBottom: '1px solid #DADCE3', marginBottom: '16px' }}>
            <div className="sortingDrawer--groupText">Presets</div>
            <div style={{ marginBottom: '16px' }}>
              {presets.map((preset: any) => {
                return (
                  <Chip
                    label={preset.presetName}
                    onDelete={() => showDeletePresetModal(preset)}
                    onClick={() => setPreset(preset.presetId)}
                    active={preset.presetId === activePresetId}
                  />
                );
              })}
            </div>
          </div>
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided: any) => (
              <div key={uniqueId()} ref={provided.innerRef} {...provided.droppableProps}>
                {sorting.map((item: any, index: number) => {
                  return (
                    <Draggable key={uniqueId()} draggableId={index.toString()} index={index}>
                      {(providedItem: any) => (
                        <div
                          ref={providedItem.innerRef}
                          {...providedItem.draggableProps}
                          {...providedItem.dragHandleProps}
                        >
                          <div className="sortingDrawer--sortElementContainer">
                            <Stack direction="row" gap={1} alignItems="center">
                              <DragIndicatorIcon />
                              <Autocomplete
                                size="small"
                                style={{ width: '272px' }}
                                options={possibleColumns}
                                value={item}
                                getOptionLabel={(option: any) => option.label ?? option.title}
                                onChange={(e: any, newValue: string | null) =>
                                  dispatch(
                                    changeSortingField({
                                      index,
                                      newValue,
                                    }),
                                  )
                                }
                              />
                              <IconButton onClick={() => dispatch(removeSortingField(index))}>
                                <DeleteIcon />
                              </IconButton>
                            </Stack>
                            <div className="sortingDrawer--sortElementOrder">
                              <div>
                                <Radio
                                  checked={item?.sortingType === 'ASC'}
                                  value={'ASC'}
                                  onChange={() => dispatch(changeSortingType({ index, sortingType: 'ASC' }))}
                                />
                                A-Z (0-9)
                              </div>
                              <div>
                                <Radio
                                  checked={item?.sortingType === 'DESC'}
                                  value={'DESC'}
                                  onChange={() => dispatch(changeSortingType({ index, sortingType: 'DESC' }))}
                                />
                                Z-A (9-0)
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Button
          disabled={possibleColumns.length === 0 || sorting.length >= allColumns.length}
          variant="text"
          onClick={() => dispatch(addLevel())}
        >
          Add level
        </Button>
      </div>
      <div className="sortingDrawer--footer">
        <Button fullWidth={true} style={{ height: '42px' }} variant="contained" onClick={() => props.apply()}>
          Apply
        </Button>
        {!props.isHidePresets && (
          <Button
            fullWidth={true}
            style={{ height: '42px' }}
            variant="outlined"
            onClick={() => {
              setSavePresetModal(true);
            }}
          >
            Save set
          </Button>
        )}
      </div>
      <Modal customstyle={{ minHeight: 256 }} open={savePresetModal} onClose={handleChangeSavePresetModalClose}>
        <div className="savePresetModal">
          <div>
            <div className="savePresetModal--header">Save new preset</div>
            <div className="savePresetModal--text">Enter name for new filter preset</div>
          </div>
          <TextField
            variant="outlined"
            placeholder="Name"
            onChange={(event: ChangeEvent<HTMLInputElement>) => setPresetName(event.target.value)}
          />
          <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
            <Button style={{ marginRight: '16px' }} onClick={() => handleChangeSavePresetModalClose()}>
              Cancel
            </Button>
            <Button variant="contained" onClick={() => handleSavePreset(presetName)}>
              Save
            </Button>
          </div>
        </div>
      </Modal>
      <Modal customstyle={{ minHeight: 188 }} open={deletePresetModal} onClose={handleChangeDeletePresetModalClose}>
        <div className="deletePresetModal">
          <div>
            <div className="deletePresetModal--header">Delete preset?</div>
            <div className="deletePresetModal--text">You are going to delete preset {deletedPreset?.presetName}</div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'end', justifySelf: 'end', width: '100%' }}>
            <Button style={{ marginRight: '16px' }} onClick={() => handleChangeDeletePresetModalClose()}>
              Cancel
            </Button>
            <Button variant="contained" onClick={() => handleDeletePreset(deletedPreset?.presetId)}>
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SortingDrawer;
