import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { selectIsAuth } from 'store/authSlice';

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();
  const isAuth = useAppSelector(selectIsAuth);

  useEffect(() => {
    if (!isAuth) {
      navigate('/ui/login');
    }
  }, [isAuth]);

  return children;
};
