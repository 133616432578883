import { createSvgIcon } from '@mui/material';

const CalendarIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.85 8.30126C7.38056 8.30126 7 8.68181 7 9.15126C7 9.6207 7.38056 10.0013 7.85 10.0013H16.15C16.6194 10.0013 17 9.6207 17 9.15126C17 8.68181 16.6194 8.30126 16.15 8.30126H7.85Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.29127 5.15374C5.25517 4.24339 6.53962 3.71064 8.1499 3.42646V3.00009C8.1499 2.53064 8.53046 2.15009 8.9999 2.15009C9.46934 2.15009 9.8499 2.53064 9.8499 3.00009V3.22177C10.5221 3.17235 11.2387 3.15087 11.9999 3.15087C12.7605 3.15087 13.4772 3.17228 14.1499 3.22236V3.00009C14.1499 2.53064 14.5305 2.15009 14.9999 2.15009C15.4693 2.15009 15.8499 2.53064 15.8499 3.00009V3.43077C17.4654 3.72215 18.7564 4.27336 19.7229 5.23012C21.3651 6.85573 21.8499 9.43193 21.8499 13.0009C21.8499 16.0967 21.3562 18.4296 19.6932 19.9246C18.0606 21.3923 15.5133 21.8509 11.9999 21.8509C8.48894 21.8509 5.92798 21.3938 4.29127 19.848C2.6385 18.287 2.1499 15.8321 2.1499 12.5009C2.1499 9.16968 2.6385 6.71469 4.29127 5.15374ZM14.1499 6.00008C14.1499 6.46953 14.5305 6.85008 14.9999 6.85008C15.4693 6.85008 15.8499 6.46953 15.8499 6.00008V5.16288C17.0682 5.41866 17.9197 5.83716 18.5269 6.43828C19.6347 7.5349 20.1499 9.45869 20.1499 13.0009C20.1499 16.0162 19.6436 17.6832 18.5566 18.6604C17.4392 19.6649 15.4865 20.1509 11.9999 20.1509C8.51087 20.1509 6.57183 19.6635 5.45853 18.6121C4.3613 17.5758 3.8499 15.7808 3.8499 12.5009C3.8499 9.22094 4.3613 7.42593 5.45853 6.38966C6.06829 5.81378 6.92575 5.40712 8.1499 5.15706V6.00008C8.1499 6.46953 8.53046 6.85008 8.9999 6.85008C9.46934 6.85008 9.8499 6.46953 9.8499 6.00008V4.92751C10.492 4.87611 11.2058 4.85087 11.9999 4.85087C12.7948 4.85087 13.5085 4.8762 14.1499 4.92829V6.00008Z" />
  </svg>,
  'Calendar',
);

export default CalendarIcon;
