import { GridRenderCellParams } from '@mui/x-data-grid';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';
import { possibleOperationTypes } from 'containers/Payments/views/Operations/constants';

/**
 * Props for the RenderOperationTypeCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 */
interface IRenderOperationTypeCellProps {
  props: GridRenderCellParams;
}

/**
 * Renders an "Operation type" cell in a data grid.
 */
const RenderOperationTypeCell = ({ props }: IRenderOperationTypeCellProps) => {
  const { row } = props;

  if (!row.id && !row.consumptionRequestId) {
    return <></>;
  }

  const currentOperationType = possibleOperationTypes.find(
    (el) => row.operationType?.toLowerCase() === el.operationType?.toLowerCase(),
  );

  return (
    <Stack>
      <Typography variant="body2">{currentOperationType?.title}</Typography>
      <Typography variant="caption" color="text.secondary">
        {currentOperationType?.subtitle}
      </Typography>
    </Stack>
  );
};

export default RenderOperationTypeCell;
