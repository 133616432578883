import { Metrics } from "store/moderationSlice";

const getConfirmedHiveViolations = (moderationResult: any[]): string[] | [] => {
  if (!moderationResult || !moderationResult.length) {
    return [];
  }

  const set = new Set<string>();
  moderationResult.forEach(({ metrics }) => metrics.forEach(({ metricName }: Metrics) => set.add(metricName)))
  return Array.from(set);
}

export default getConfirmedHiveViolations;
