import { useCallback, useMemo, useState } from 'react';
import colors from 'theme/constants/colors';
import { PRICE_IDS } from 'components/FilterDrawer/constants';
import { IFilterItem } from 'types/commonTypes';
import { clearFilters, removeFilter, selectFilters, selectFiltersData } from 'store/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import CloseCircleIcon from 'assets/img/CloseCircleIcon';
import Button from 'components/shared/Button/Button';
import Chip from 'components/shared/Chip/Chip';
import Dialog from 'components/shared/Dialog/Dialog';
import DialogActions from 'components/shared/Dialog/DialogActions/DialogActions';
import DialogContent from 'components/shared/Dialog/DialogContent/DialogContent';
import DialogTitle from 'components/shared/Dialog/DialogTitle/DialogTitle';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';
import './index.scss';
import {
  formatBetweenFilter,
  formatDateRange,
  getEqualsFilterText,
  getSingleElementText,
  isSingleElementFilter,
} from './utils';

const ActiveFilterList = (props: any) => {
  const dispatch = useAppDispatch();
  const [clearFiltersConfirmModal, setClearFiltersConfirmModal] = useState<boolean>(false);

  const cancelClearFilters = useCallback((event?: any, reason?: any) => {
    if (reason && reason === 'backdropClick') return;
    setClearFiltersConfirmModal(false);
  }, []);

  const data = useAppSelector(selectFiltersData);
  const filtersList = useAppSelector(selectFilters);

  /** Additionally, compare the filters list with the applied filters to exclude custom (search) filters from being displayed */
  const filtersData = useMemo(() => {
    const filtersColumnIds = filtersList.map((filter) => filter.columnId);

    return Object.values(data).filter(
      (element: IFilterItem) => element !== null && filtersColumnIds.includes(element.columnId),
    ) as any;
  }, [data, filtersList]);

  const handleRemoveFilter = useCallback(
    (columnId: string) => {
      dispatch(removeFilter({ columnId }));
      setClearFiltersConfirmModal(false);
      props.refreshData && props.refreshData();
    },
    [data, dispatch, props.refreshData],
  );

  const handleRemoveAllFilters = useCallback(() => {
    dispatch(clearFilters());
    setClearFiltersConfirmModal(false);
    props.refreshData && props.refreshData();
  }, [dispatch, props.refreshData]);

  const getChipText = useCallback((filter: IFilterItem) => {
    const isPriceFilter = PRICE_IDS.includes(filter.columnId.toString());

    if (filter.valueType === 'DATE') {
      return formatDateRange(filter.from ?? '', filter.to ?? '');
    }
    if (filter.type === 'BETWEEN') {
      return formatBetweenFilter(isPriceFilter, filter.from ?? '', filter.to ?? '');
    }
    if (filter.type === 'EQUALS') {
      return getEqualsFilterText(filter.element ?? null);
    }
    if (filter.elements && isSingleElementFilter(filter)) {
      const isUsername = filter.title.toLowerCase().includes('username');

      if (isUsername) {
        return `@${getSingleElementText(filter.elements[0])}`;
      }

      return getSingleElementText(filter.elements[0]);
    }

    return `${filter.elements ? filter.elements.length : 0} selected`;
  }, []);

  return (
    <Stack direction="row" gap={1.5} mb={2} className="filter-active-list">
      {filtersData.map((el: any, index: number) => {
        return (
          <Chip
            className="filter-active-list__chip"
            size="small"
            key={index}
            icon={
              <>
                <Typography color="text.secondary" variant="chip" ml={1.5}>
                  {el.title}
                </Typography>
                <Typography variant="chip" color={colors.primary} ml={0.5} mr={0.5}>
                  |
                </Typography>
              </>
            }
            deleteIcon={<CloseCircleIcon />}
            label={getChipText(el)}
            onDelete={() => handleRemoveFilter(el.columnId)}
          />
        );
      })}

      {filtersData.length > 0 ? (
        <Typography
          className="filter-active-list__clear-filters-link"
          variant="body1"
          onClick={() => setClearFiltersConfirmModal(true)}
        >
          Clear filters
        </Typography>
      ) : (
        <></>
      )}
      {clearFiltersConfirmModal && (
        <Dialog fullWidth maxWidth="xs" open={clearFiltersConfirmModal}>
          <DialogTitle variant="h6">Clear filters?</DialogTitle>
          <DialogContent>
            <Typography variant="body1">All data will be shown</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => cancelClearFilters()} variant="text" color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleRemoveAllFilters()} variant="contained" color="primary">
              Clear
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Stack>
  );
};

export default ActiveFilterList;
