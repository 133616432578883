import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  fetchResetPassword,
  fetchResetPasswordTriesLeft,
  selectProfileStatus,
  selectProfileStatusText,
  selectTriesLeft,
} from "store/profileSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import Button from "components/shared/Button/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputAdornment from 'components/shared/InputAdornment/InputAdornment';
import IconButton from "components/shared/IconButton/IconButton";
import EyeClosedIcon from "assets/img/EyeClosedIcon";
import EyeIcon from "assets/img/EyeIcon";
import TextField from "components/shared/TextField/TextField";
import { enqueueSnackbar, VariantType } from "notistack";
import * as yup from "yup";
import LoadingButton from "components/shared/LoadingButton/LoadingButton";
import { CommonErrorMessages, ProfileMessages } from "store/types/ErrorMessages";
import { logout } from "store/authSlice";
import Alert from "components/shared/Alert/Alert";

import './index.scss';

interface Props {
  onClose(): void;
}

const ChangePassword = ({ onClose }: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const ref = useRef<any>();

  const triesLeft = useAppSelector(selectTriesLeft);
  const status = useAppSelector(selectProfileStatus);
  const statusText = useAppSelector(selectProfileStatusText);

  const dispatch = useAppDispatch();

  const schema = useMemo(() => yup.object().shape({ password: yup.string().required().min(1) }), []);

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors, isValid },
    setError,
  } = useForm({
    defaultValues: {
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    void trigger();
    try {
      const result = await dispatch(fetchResetPassword({ password: data.password }));
      if (result.meta.requestStatus === 'fulfilled') {
        enqueueSnackbar('Link for setting up new password has been sent to your email', { variant: 'success' as VariantType });
        onClose();
        return;
      }
      setError('password', {});
    } catch (e) {
      setErrorMessage('An error occurred, try again later');
    }
  }

  const initState = useCallback(async () => {
    await dispatch(fetchResetPasswordTriesLeft());
  }, []);

  useEffect(() => {
    void initState();
  }, []);

  useEffect(() => {
    if (triesLeft < 3 && triesLeft > 0) {
      setErrorMessage(`You entered the wrong password earlier. You have ${triesLeft} attempt${triesLeft > 1 ? 's' : ''} left`);
    } else if (triesLeft === 0) {
      setErrorMessage(
        `You entered the wrong password earlier. You have no attempts left. Please contact your manager`,
      );
    } else {
      setErrorMessage('');
    }
  }, [triesLeft]);

  useEffect(() => {
    if (!ref.current) {
      ref.current = true;
      return;
    }

    switch (statusText) {
      case ProfileMessages.UNVALIDATED_PASSWORD:
        if (triesLeft < 3) {
          setErrorMessage(`You’ve entered the wrong password. You have ${triesLeft} attempt${triesLeft && 's'} left`);
        }
        break;
      case ProfileMessages.NO_TRIES_CHANGE_PASSWORD:
        dispatch(logout({ message: ProfileMessages.NO_TRIES }));
        break;
      case CommonErrorMessages.UNEXPECTED_ERROR:
        setErrorMessage('An error occurred, try again later');
        break;
      default:
        break;
    }
  }, [statusText, ref, triesLeft]);

  return (
    <div className="profileModal">
      <div className="profileModal-title">Change password</div>
      <form className="profileModal-form" onSubmit={handleSubmit(onSubmit)}>
        <span>Enter your current password</span>
        <TextField
          {...register('password')}
          error={!!errors.password}
          type={showPassword ? 'text' : 'password'}
          label="Password"
          name="password"
          defaultValue=""
          fullWidth={true}
          onBlur={() => {}}
          InputProps={{ endAdornment:
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((show) => !show)}
                  onMouseDown={(event) => event.preventDefault()}
                >
                  {showPassword ? <EyeClosedIcon /> : <EyeIcon />}
                </IconButton>
              </InputAdornment>
          }}
        />
        {errorMessage && errorMessage !== '' && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
        <div className="profileModal-buttonsGroup">
          <Button
            variant="text"
            onClick={onClose}
          >
            Cancel
          </Button>
          <LoadingButton
            disabled={!isValid || triesLeft === 0}
            type="submit"
            variant="contained"
            loadingPosition="start"
            startIcon={<></>}
            loading={status === 'loading'}
          >
            Change password
          </LoadingButton>
        </div>
      </form>
    </div>
  )
};

export default ChangePassword;
