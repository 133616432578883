import React, { useState } from "react";
import Button from "components/shared/Button/Button";
import LoadingButton from "components/shared/LoadingButton/LoadingButton";
import { Banner } from "store/bannerDetailsSlice";
import AvatarItem from "components/shared/AvatarItem/AvatarItem";
import Alert from "components/shared/Alert/Alert";

import './index.scss';
import { useAppSelector } from "store/hooks";

interface IProps {
  items: Banner | Banner[];
  onClose(): void;
  archive(items: Banner | Banner[]): void;
  errorMessage?: string;
}

const ArchiveBannersModal = ({ items, onClose, archive, errorMessage }: IProps) => {
  let content;
  let setScroll;
  let title;
  const archiveBannerStatus = useAppSelector(state => state.worldManagement.archiveBannerStatus);

  if (Array.isArray(items)) {
    title = 'You are going to archive following banners:';
    setScroll = items.length > 3;
    content = items.map((item) =>(
      <AvatarItem
        src={item.cover.originalLink}
        primaryText={item.name}
        subText={`by ${item.creatorName} ${item.creatorLastName}`}
      />
    ));
  } else {
    title = 'You are going to archive banner:';
    content = (
      <AvatarItem
        src={items.cover.originalLink}
        primaryText={items.name}
        subText={`by ${items.creatorName} ${items.creatorLastName}`}
      />
    );
  }

  return (
    <div className='archiveBannerModal'>
      <div className="archiveBannerModal-header">
        {title}
      </div>
      <div className={`archiveBannerModal-content ${setScroll && 'archiveBannerModal-content_scroll'}`}>
        {content}
      </div>
      <div className="archiveBannerModal-footer">
        {errorMessage && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
        <div className="archiveBannerModal-footer-buttons">
          <Button
            onClick={onClose}
            variant="text"
            color="primary"
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="error"
            loading={archiveBannerStatus === 'loading'}
            onClick={() => archive(items)}>
            Archive banner{Array.isArray(items) ? 's' : ''}
          </LoadingButton>
        </div>
      </div>
    </div>
  )
};

export default ArchiveBannersModal;
