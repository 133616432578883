import { GridOverlay } from '@mui/x-data-grid';
import colors from 'theme/constants/colors';
import { useAppSelector } from 'store/hooks';
import { selectAssignStatus } from 'store/moderationSlice';
import CheckCircleIcon from 'assets/img/CheckCircleIcon';
import Button from 'components/shared/Button/Button';
import CircularProgress from 'components/shared/CircularProgress/CircularProgress';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';
import DialogTitle from 'components/shared/Dialog/DialogTitle/DialogTitle';
import DialogContent from 'components/shared/Dialog/DialogContent/DialogContent';
import DialogActions from 'components/shared/Dialog/DialogActions/DialogActions';
import './index.scss';
import Dialog from 'components/shared/Dialog/Dialog';
import { useCallback, useState } from 'react';

interface IProps {
  isFilters?: boolean;
  ticketsGroup?: string;
  clearFilter?: () => void;
  fetchingError?: boolean;
  refreshData?: () => void;
}

const NoMoreTickets = ({ isFilters, ticketsGroup, clearFilter, fetchingError, refreshData }: IProps) => {
  const assignStatus = useAppSelector(selectAssignStatus);

  const [clearFiltersConfirmModal, setClearFiltersConfirmModal] = useState<boolean>(false);

  const cancelClearFilters = useCallback((event?: any, reason?: any) => {
    if (reason && reason === 'backdropClick') return;
    setClearFiltersConfirmModal(false);
  }, []);

  return (
    <GridOverlay className="moderation-table-container__overlay">
      {fetchingError && (
        <Stack alignItems="center">
          <Typography color="text.secondary" variant="body2">
            There are some troubles with data receiving. Repeat request in a while.
          </Typography>
          <Typography fontSize="small" color="text.secondary" variant="body2">
            WARNING! In case of page reload you could lose your requested data
          </Typography>
          <Button variant="text" onClick={() => refreshData && refreshData()}>
            Repeat requests
          </Button>
        </Stack>
      )}

      {isFilters && !fetchingError && (
        <Stack alignItems="center">
          <Typography color="text.secondary" variant="body2">
            No data to display. Try to remove any of filters or change their value
          </Typography>
          <Button onClick={() => clearFilter && setClearFiltersConfirmModal(true)} variant="text">
            Clear filters
          </Button>
        </Stack>
      )}

      {!isFilters &&
        !fetchingError &&
        (ticketsGroup === 'My' ? (
          <Stack gap={2} alignItems="center">
            {assignStatus === 'loading' && (
              <div className="loading-overlay">
                <Stack height={1} alignItems="center">
                  <CircularProgress sx={{ margin: 'auto' }} color="primary" />
                </Stack>
              </div>
            )}
            <CheckCircleIcon sx={{ fontSize: 32, color: colors.outlinedBorder }} />
            <Typography variant="h6" color={colors.outlinedBorder}>
              There are no tickets assigned to you.
            </Typography>
            <Typography variant="h6" color={colors.outlinedBorder}>
              Press GET TICKETS button if you want to request a batch of tickets
            </Typography>
          </Stack>
        ) : (
          <Stack alignItems="center">
            <Typography variant="h6" color={colors.outlinedBorder}>
              No tickets to display
            </Typography>
          </Stack>
        ))}
        {clearFiltersConfirmModal && (
        <Dialog fullWidth maxWidth="xs" open={clearFiltersConfirmModal}>
          <DialogTitle variant="h6">Clear filters?</DialogTitle>
          <DialogContent>
            <Typography variant="body1">All data will be shown</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => cancelClearFilters()} variant="text" color="primary">
              Cancel
            </Button>
            <Button onClick={() => clearFilter && clearFilter()} variant="contained" color="primary">
              Clear
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </GridOverlay>
  );
};

export default NoMoreTickets;
