import axios, { isAxiosError } from 'axios';
import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { IStatus } from 'types/commonTypes';
import { IConsumptionRequestCase, IConsumptionRequestState, IConsumptionsDictionaries } from './interface';

const initialState: IConsumptionRequestState = {
  consumptionRequestCases: [],
  allIds: [],
  consumptionRequestCaseDetails: null,
  consumptionRequestCasesCount: 0,
  consumptionsDictionaries: null,
  status: 'idle',
};

export const fetchConsumptionRequestCases = createAsyncThunk(
  'consumptionRequest/cases',
  async ({ page, pageSize }: { page: number; pageSize: number }, { rejectWithValue, getState }) => {
    try {
      const rootState: RootState = getState() as RootState;
      const filters = Object.keys(rootState.filters.data)
        ? Object.values(rootState.filters.data).filter((item: any) => item !== null)
        : [];
      const sortingList = Object.keys(rootState.sorting.data)
        ? Object.values(rootState.sorting.data).filter(
            (item: any) => item !== null && item.columnId !== null && item.sortingType !== null,
          )
        : [];
      const response = await axios.post((process.env.REACT_APP_OPERATIONS_ENDPOINT as string) + 'consumptions/find', {
        pageInfo: {
          page: page + 1,
          size: pageSize,
        },
        sortingList: sortingList,
        filters: filters,
      });

      return response.data;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue({
          message: error.message || 'An unexpected error occurred',
          status: error.response?.status,
        });
      } else {
        return rejectWithValue({
          message: 'An unexpected error occurred',
        });
      }
    }
  },
);

export const fetchConsumptionRequestSearch = createAsyncThunk(
  'consumptionRequest/cases',
  async ({ searchValue, listSize = 3 }: { searchValue: string; listSize?: number }, { rejectWithValue }) => {
    try {
      const response = await axios.post((process.env.REACT_APP_OPERATIONS_ENDPOINT as string) + 'consumptions/search', {
        value: searchValue,
        size: listSize,
      });

      return response.data;
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data);
    }
  },
);

export const fetchConsumptionsDictionaries = createAsyncThunk('consumptions/dictionaries', async () => {
  try {
    const response = await axios.get(
      (process.env.REACT_APP_OPERATIONS_ENDPOINT as string) + 'consumptions/dictionaries',
    );

    return response.data;
  } catch (error: unknown) {
    return isAxiosError(error);
  }
});

export const fetchSendConsumptionRequest = createAsyncThunk(
  'consumptions/sendRequest',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        (process.env.REACT_APP_OPERATIONS_ENDPOINT as string) + `consumptions/send`,
        data,
      );

      return response.data;
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data);
    }
  },
);

export const removeResolvedRequestId = createAction<string>('consumptions/removeResolvedRequestId');

export const ConsumptionRequestsSlice = createSlice({
  name: 'consumptionRequests',
  initialState,
  reducers: {
    setConsumptionRequestStatus: (state, action: PayloadAction<IStatus>) => {
      state.status = action.payload;
    },
    clearConsumptionRequestCases: (state) => {
      state.consumptionRequestCases = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConsumptionRequestCases.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchConsumptionRequestCases.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.consumptionRequestCases = payload.data.content;
        state.allIds = payload.allIds;
        state.consumptionRequestCasesCount = payload.data.totalElements;
      })
      .addCase(fetchConsumptionRequestCases.rejected, (state) => {
        state.status = 'failed';
        state.consumptionRequestCases = [];
        state.allIds = [];
        state.consumptionRequestCasesCount = 0;
      })
      .addCase(fetchConsumptionsDictionaries.fulfilled, (state, { payload }) => {
        state.consumptionsDictionaries = payload;
      })
      .addCase(removeResolvedRequestId, (state, action) => {
        state.allIds = state.allIds.filter((id) => id !== action.payload);
      });
  },
});

export const selectConsumptionRequestCases = (state: RootState): IConsumptionRequestCase[] =>
  state.consumptionRequests.consumptionRequestCases;
export const selectConsumptionRequestCasesIds = (state: RootState): string[] => state.consumptionRequests.allIds;
export const selectTotalCount = (state: RootState): number => state.consumptionRequests.consumptionRequestCasesCount;
export const selectConsumptionRequestCasesStatus = (state: RootState): IStatus => state.consumptionRequests.status;
export const selectConsumptionDictionaries = (state: RootState): IConsumptionsDictionaries | null =>
  state.consumptionRequests.consumptionsDictionaries;

export const { setConsumptionRequestStatus, clearConsumptionRequestCases } = ConsumptionRequestsSlice.actions;

export default ConsumptionRequestsSlice.reducer;
