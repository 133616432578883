import { MouseEvent } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { ITier } from 'store/slices/Settings/interface';
import { possibleTierStatuses } from '../constants';
import { Permissions } from 'types/commonTypes';
import { checkPermissions } from 'utils/checkPermissions';
import { useAppSelector } from 'store/hooks';
import { selectUserPermissions } from 'store/authSlice';
import ChevronDownIcon from 'assets/img/ChevronDownIcon';
import ChevronUpIcon from 'assets/img/ChevronUpIcon';
import Typography from 'components/shared/Typography/Typography';

/**
 * Props for the RenderStatusSelect component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 * @property {boolean} isMenuOpen - If `true`, the status menu is shown.
 * @property {ITier} [selectedCellData] - Row data for selected cell.
 * @property {(e: MouseEvent<HTMLDivElement>, data: any) => void} onClick - Click event handler.
 */
interface IRenderStatusSelectProps {
  props: GridRenderCellParams;
  isMenuOpen: boolean;
  selectedCellData?: ITier | null;
  onClick: (e: MouseEvent<HTMLDivElement>, data: any) => void;
}

/**
 * Renders a status select cell in a data grid.
 */
export const RenderStatusSelect = ({ props, isMenuOpen, selectedCellData, onClick }: IRenderStatusSelectProps) => {
  const { row } = props;
  const userPermissions = useAppSelector(selectUserPermissions);

  if (!row.linkId) {
    return <></>;
  }

  const hasAccess = checkPermissions(userPermissions, [Permissions.SETTINGS_CHANGE_IN_APP_TIER_STATUS]);
  const currentStatus = possibleTierStatuses.find((el) => row.status?.toLowerCase() === el.status.toLowerCase());

  const handleClick = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    if (hasAccess) {
      onClick(e, row);
    }
  };

  return (
    <div
      onClick={(e) => handleClick(e)}
      className={`tiers-table__status-select${!hasAccess ? '--no-pointer' : ''}`}
      style={{ color: currentStatus?.color }}
    >
      <Typography variant="chip" color={{ color: currentStatus?.color }}>
        {row.status?.replace(row.status, currentStatus?.title)}
      </Typography>
      {hasAccess && (selectedCellData?.linkId === row.linkId && isMenuOpen ? <ChevronUpIcon /> : <ChevronDownIcon />)}
    </div>
  );
};
