import { MouseEvent } from 'react';
import dayjs from 'dayjs';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useHover } from '@uidotdev/usehooks';
import { enqueueSnackbar, VariantType } from 'notistack';
import CopyIcon from 'assets/img/CopyIcon';
import IconButton from 'components/shared/IconButton/IconButton';
import Stack from 'components/shared/Stack/Stack';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import Typography from 'components/shared/Typography/Typography';

/**
 * Props for the RenderTransactionIDCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 */
interface IRenderTransactionIDCellProps {
  props: GridRenderCellParams;
}

/**
 * Renders a "Transaction ID" cell in a data grid.
 */
export const RenderTransactionIDCell = ({ props }: IRenderTransactionIDCellProps) => {
  const { row } = props;

  const [ref, hovering] = useHover<HTMLDivElement>();

  const formattedDate = dayjs(row.createDate);

  const copyId = (e: MouseEvent<HTMLButtonElement>, value: string) => {
    e.stopPropagation();

    if (navigator.clipboard) {
      enqueueSnackbar('Copied to clipboard', { variant: 'success' as VariantType });

      return navigator.clipboard.writeText(value);
    }
  };

  if (!row.id) {
    return <></>;
  }

  return (
    <div className="MuiDataGrid-cellContent" ref={ref}>
      <Stack>
        <Stack direction="row" alignItems="center" gap="10px" sx={{ minHeight: 28 }}>
          <Tooltip placement="top" title={row.psTransactionId}>
            <div style={{ display: 'inline-grid' }}>
              <Typography
                component="span"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                variant="body2"
              >
                {row.psTransactionId || '—'}
              </Typography>
            </div>
          </Tooltip>

          {hovering && row.psTransactionId && (
            <Tooltip placement="top" title="Copy">
              <IconButton color="default" size="small" onClick={(e) => copyId(e, row.psTransactionId)}>
                <CopyIcon sx={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
          )}
        </Stack>

        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="caption" color="text.secondary">
            {formattedDate.format('MM/DD/YYYY h:mm A') || '—'}
          </Typography>
        </Stack>
      </Stack>
    </div>
  );
};
