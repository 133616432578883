import { useState } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { IOperation, TransactionTypes } from 'store/slices/Payments/interface';
import colors from 'theme/constants/colors';
import InfoFilledIcon from 'assets/img/InfoFilledIcon';
import Button from 'components/shared/Button/Button';
import DialogActions from 'components/shared/Dialog/DialogActions/DialogActions';
import IconButton from 'components/shared/IconButton/IconButton';
import ListItem from 'components/shared/List/ListItem/ListItem';
import ListItemText from 'components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText';
import Popover from 'components/shared/Popover/Popover';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';

/**
 * Props for the RenderInfoCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 */
interface IRenderInfoCellProps {
  props: GridRenderCellParams;
}

/**
 * Renders a special cell in a transaction table.
 */
export const RenderInfoCell = ({ props }: IRenderInfoCellProps) => {
  const { row } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });

  if (!row.id) {
    return <></>;
  }

  const isRefund = row.type === TransactionTypes.REFUND;

  return (
    <>
      {isRefund && (
        <IconButton
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            setCoordinates({ x: event.clientX, y: event.clientY });
          }}
          size="small"
        >
          <InfoFilledIcon sx={{ color: !!anchorEl ? colors.standardInputLine : colors.actionDisabled, fontSize: 20 }} />
        </IconButton>
      )}
      <Popover
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        anchorReference="anchorPosition"
        anchorPosition={{
          top: coordinates.y,
          left: coordinates.x - 20,
        }}
      >
        <Stack sx={{ p: '24px 24px 0' }}>
          <ListItem>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body2" color="text.secondary">
                  Refund reason
                </Typography>
              }
              secondary={
                <Typography variant="subtitle1" color="text.primary">
                  {row.reason || '—'}
                </Typography>
              }
            ></ListItemText>
          </ListItem>

          <ListItem>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body2" color="text.secondary">
                  Comment
                </Typography>
              }
              secondary={
                <Typography
                  variant="subtitle1"
                  color="text.primary"
                  sx={{ maxHeight: '30vh', maxWidth: '30vw', overflow: 'auto' }}
                >
                  {row.comment || '—'}
                </Typography>
              }
            ></ListItemText>
          </ListItem>
        </Stack>

        <DialogActions>
          <Button onClick={() => setAnchorEl(null)}>Close</Button>
        </DialogActions>
      </Popover>
    </>
  );
};
