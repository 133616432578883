import { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchPermissions, selectIsAuth, selectUserPermissionsStatus } from 'store/authSlice';
import CircularProgress from 'components/shared/CircularProgress/CircularProgress';
import Stack from 'components/shared/Stack/Stack';

const LoaderFallback: FC = () => {
  const dispatch = useAppDispatch();
  const userPermissionsStatus = useAppSelector(selectUserPermissionsStatus);
  const isAuth = useAppSelector(selectIsAuth);

  useEffect(() => {
    if (isAuth) {
      dispatch(fetchPermissions());
    }
  }, [isAuth]);

  if (userPermissionsStatus === 'loading' && isAuth) {
    return (
      <Stack height="100vh" alignItems="center">
        <>
          <svg width={0} height={0}>
            <defs>
              <linearGradient id="logo_gradient" gradientTransform="rotate(135)">
                <stop offset="0%" stopColor="#83A4F8" />
                <stop offset="48%" stopColor="#608AF7" />
                <stop offset="100%" stopColor="#4C72D5" />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgress
            size={64}
            thickness={4}
            sx={{ 'svg circle': { stroke: 'url(#logo_gradient)' }, margin: 'auto' }}
          />
        </>
      </Stack>
    );
  }

  return <Outlet />;
};

export default LoaderFallback;
