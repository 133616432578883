import { useEffect, useRef, useState } from 'react';
import colors from 'theme/constants/colors';
import { ContentType, IMediaItem } from 'types/commonTypes';
import useVideoPlayer from 'hooks/useVideoPlayer';
import PauseFillIcon from 'assets/img/PauseFillIcon';
import PlayFillIcon from 'assets/img/PlayFillIcon';
import SpeedIcon from 'assets/img/SpeedIcon';
import VolumeIcon from 'assets/img/VolumeIcon';
import VolumeMuteIcon from 'assets/img/VolumeMuteIcon';
import Button from 'components/shared/Button/Button';
import Card from 'components/shared/Card/Card';
import CardActionArea from 'components/shared/Card/CardActionArea';
import CardActions from 'components/shared/Card/CardActions';
import CardMedia from 'components/shared/Card/CardMedia';
import CircularProgress from 'components/shared/CircularProgress/CircularProgress';
import Slider from 'components/shared/Slider/Slider';
import MediaHeader from './MediaHeader/MediaHeader';
import MediaThumbnails from './MediaThumbnails/MediaThumbnails';
import './index.scss';

export interface IMediaProps {
  entityOwnerId?: string;
  entityType: string;
  entityId: string;
  contentType: string;
  mediaUrl: string;
  mediaId?: string;
  mainEntity: IMediaItem;
  orderedMediaList: IMediaItem[];
  showDetailedHeader?: boolean;
}

interface IMediaPreviewDrawerProps {
  mediaProps: IMediaProps;
  onClose: () => void;
}

const MediaPreviewDrawer = ({ mediaProps, onClose }: IMediaPreviewDrawerProps) => {
  const {
    entityOwnerId,
    entityType,
    entityId,
    contentType,
    mediaUrl,
    mediaId,
    orderedMediaList,
    mainEntity,
    showDetailedHeader = false,
  } = mediaProps;

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const imageSrcRef = useRef(mediaUrl);
  const [playerSliderVolumeState, setPlayerSliderVolumeState] = useState(false);
  const [playerSliderSpeedState, setPlayerSliderSpeedState] = useState(false);
  const [wasPausedBeforeDragging, setWasPausedBeforeDragging] = useState(false);
  const [currentMainEntity, setCurrentMainEntity] = useState<IMediaItem>(mainEntity);
  const [error, setError] = useState(false);
  const [isLoadingMainMedia, setIsLoadingMainMedia] = useState<boolean>(true);
  const [isReloadingMainMedia, setIsReloadingMainMedia] = useState<boolean>(false);
  const [isWaitingMainMedia, setIsWaitingMainMedia] = useState<boolean>(false);
  const [mediaWidth, setMediaWidth] = useState(0);

  const {
    playerState,
    setPlayerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    toggleMute,
    handleVideoSpeed,
    handleVideoVolume,
    handleReloadVideo,
  } = useVideoPlayer(videoRef);

  const formatDuration = (value: number) => {
    const minute = Math.floor(value / 60);
    const secondLeft = Math.floor(value - minute * 60);
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  };

  const handleError = () => {
    setError(true);
    setIsLoadingMainMedia(false);
  };

  const handleMouseDown = () => {
    setWasPausedBeforeDragging(!playerState.isPlaying);
  };

  const handleMouseUp = () => {
    if (!wasPausedBeforeDragging) {
      setPlayerState({
        ...playerState,
        isPlaying: true,
      });
    }
  };

  const handleReloadImage = async () => {
    const now = new Date();
    imageSrcRef.current = `${mediaUrl}?timestamp=${now.getTime()}`;
    setCurrentMainEntity({
      ...currentMainEntity,
      mediaUrl: `${currentMainEntity.mediaUrl}?timestamp=${now.getTime()}`,
    });
  };

  const onThumbnailClick = (maniEntity: IMediaItem) => {
    setError(false);
    setIsLoadingMainMedia(true);
    setCurrentMainEntity(maniEntity);
  };

  const handleReload = () => {
    setError(false);
    setIsLoadingMainMedia(true);
    setIsReloadingMainMedia(true);
    handleReloadVideo();
    handleReloadImage();
  };

  useEffect(() => {
    const handleMouseUp = () => {
      setPlayerSliderVolumeState(false);
      setPlayerSliderSpeedState(false);
    };

    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      const width = videoRef.current.getBoundingClientRect().width;
      setMediaWidth(width);
    }
  }, [mediaUrl, isLoadingMainMedia]);

  return (
    <div className={`media ${error ? 'media-initial-width' : ''}`}>
      <MediaHeader
        entityType={entityType}
        contentType={contentType}
        entityId={entityId}
        entityOwnerId={entityOwnerId}
        mediaId={mediaId}
        onClose={onClose}
        showDetailedHeader={showDetailedHeader}
      />
      {orderedMediaList?.length > 1 ? (
        <div className="multimedia">
          <>
            <Card
              onError={handleError}
              sx={{
                backgroundColor:
                  currentMainEntity?.mediaType?.toUpperCase() === ContentType.VIDEO && !isLoadingMainMedia && !error
                    ? colors.backdropOverlay
                    : colors.white,
                boxShadow: 0,
              }}
            >
              {error && (
                <div className="media-fallback">
                  <p className="media-fallback-text">Cannot load media</p>
                  <Button className="media-fallback-button" onClick={handleReload}>
                    Reload
                  </Button>
                </div>
              )}
              {(isLoadingMainMedia || isWaitingMainMedia) && (
                <div className="media-fallback-loader">
                  <CircularProgress />
                </div>
              )}
              <div className="main-media">
                {currentMainEntity?.mediaType === ContentType.VIDEO ? (
                  <>
                    <CardActionArea disabled={isLoadingMainMedia} onClick={togglePlay}>
                      <CardMedia
                        ref={videoRef}
                        className="media-content-preview"
                        component="video"
                        sx={{
                          maxWidth: `${isLoadingMainMedia ? mediaWidth + 'px' : '100%'}`,
                        }}
                        onLoadedData={(e) => {
                          const target = e.target as HTMLVideoElement;
                          videoRef.current = target;
                          setIsLoadingMainMedia(false);
                        }}
                        onCanPlayThrough={() => {
                          setIsWaitingMainMedia(false);
                          setIsLoadingMainMedia(false);
                        }}
                        onWaiting={() => setIsWaitingMainMedia(true)}
                        onError={(e: any) => (videoRef.current = e.target)}
                        onTimeUpdate={handleOnTimeUpdate}
                        src={currentMainEntity.mediaUrl}
                      />
                    </CardActionArea>
                    {currentMainEntity?.mediaType === ContentType.VIDEO && !error && !isLoadingMainMedia && (
                      <CardActions>
                        <Button className="media-actions_play-buttons" onClick={togglePlay}>
                          {playerState.isPlaying && playerState.progress !== 0 && playerState.progress !== 100 && (
                            <PauseFillIcon />
                          )}
                          {(playerState.progress === 0 ||
                            playerState.progress === 100 ||
                            (!playerState.isPlaying && playerState.progress !== 0 && playerState.progress !== 100)) && (
                            <PlayFillIcon />
                          )}
                        </Button>
                        <div className="media-actions">
                          <div
                            className={`media-actions_volume ${playerSliderVolumeState ? 'active' : ''}`}
                            onMouseDown={() => setPlayerSliderVolumeState(true)}
                          >
                            <Button className="media-actions_volumeButton" onClick={toggleMute}>
                              {playerState.isMuted ? <VolumeMuteIcon /> : <VolumeIcon />}
                            </Button>
                            <div className="media-actions_slider media-actions_slider-vertical">
                              <Slider
                                min={0}
                                step={5}
                                valueLabelDisplay="on"
                                getAriaValueText={(value) => `${value}`}
                                valueLabelFormat={(value) => `${value}`}
                                orientation="vertical"
                                max={100}
                                value={playerState.volume}
                                onChange={(event, value) => handleVideoVolume(event, value)}
                              />
                            </div>
                          </div>
                          <div className="media-actions_slider">
                            <Slider
                              min={0}
                              step={0.1}
                              max={playerState.duration}
                              valueLabelDisplay="auto"
                              getAriaValueText={(value) => formatDuration(value)}
                              valueLabelFormat={(value) => formatDuration(value)}
                              value={playerState.currentTime}
                              onChange={handleVideoProgress}
                              onMouseDown={handleMouseDown}
                              onMouseUp={handleMouseUp}
                            />
                            <div className="media-duration">
                              <span>{formatDuration(playerState.currentTime)}</span>
                              <span>-{formatDuration(playerState.duration - playerState.currentTime)}</span>
                            </div>
                          </div>
                          <div
                            className={`media-actions_speed ${playerSliderSpeedState ? 'active' : ''}`}
                            onMouseDown={() => setPlayerSliderSpeedState(true)}
                          >
                            <Button className="media-actions_speedButton">
                              <SpeedIcon />
                            </Button>
                            <div className="media-actions_slider media-actions_slider-vertical">
                              <Slider
                                min={0.5}
                                step={0.25}
                                max={2}
                                orientation="vertical"
                                valueLabelDisplay="auto"
                                getAriaValueText={(index) => index + 'x'}
                                valueLabelFormat={(index) => index + 'x'}
                                marks
                                value={playerState.speed}
                                onChange={handleVideoSpeed}
                              />
                            </div>
                          </div>
                        </div>
                      </CardActions>
                    )}
                  </>
                ) : (
                  <div className="card-multimedia-container">
                    <CardMedia
                      className={`multimedia-content-img media-content-${!error ? '' : 'hidden'}`}
                      component="img"
                      onLoad={(e) => {
                        const target = e.target as HTMLImageElement;
                        imageSrcRef.current = target.src;
                        setIsLoadingMainMedia(false);
                      }}
                      alt=""
                      src={currentMainEntity.mediaUrl}
                    />
                  </div>
                )}
              </div>
            </Card>
          </>
          <div className="multimedia-preview">
            <MediaThumbnails
              error={error}
              isReloadingMainMedia={isReloadingMainMedia}
              setIsReloadingMainMedia={setIsReloadingMainMedia}
              orderedMediaList={orderedMediaList}
              currentMainEntity={currentMainEntity}
              onThumbnailClick={onThumbnailClick}
            />
          </div>
        </div>
      ) : (
        <div className="media-container">
          <Card
            onError={handleError}
            className="media-container__card"
            sx={{
              backgroundColor:
                contentType?.toUpperCase() === ContentType.VIDEO && !isLoadingMainMedia && !error
                  ? colors.backdropOverlay
                  : colors.white,
              boxShadow: 0,
            }}
          >
            {error && (
              <div className="media-fallback">
                <p className="media-fallback-text">Cannot load media</p>
                <Button className="media-fallback-button" onClick={handleReload}>
                  Reload
                </Button>
              </div>
            )}
            {isLoadingMainMedia && (
              <div className="media-fallback-loader">
                <CircularProgress />
              </div>
            )}
            {(contentType?.toUpperCase() === ContentType.IMAGE ||
              contentType?.toUpperCase() === ContentType.AVATAR) && (
              <div className="card-media-container">
                <CardMedia
                  className={`media-content-img media-content-${!error ? '' : 'hidden'}`}
                  component="img"
                  onLoad={(e) => {
                    const target = e.target as HTMLImageElement;
                    imageSrcRef.current = target.src;
                    setIsLoadingMainMedia(false);
                  }}
                  alt=""
                  src={imageSrcRef.current}
                />
              </div>
            )}
            {contentType?.toUpperCase() === ContentType.VIDEO && (
              <CardActionArea disabled={isLoadingMainMedia} onClick={togglePlay}>
                <CardMedia
                  ref={videoRef}
                  className="media-content-preview"
                  component="video"
                  sx={{
                    maxWidth: `${isLoadingMainMedia ? mediaWidth + 'px' : '100%'}`,
                  }}
                  onLoadedData={(e) => {
                    const target = e.target as HTMLVideoElement;
                    videoRef.current = target;
                    setIsLoadingMainMedia(false);
                  }}
                  onCanPlayThrough={() => {
                    setIsWaitingMainMedia(false);
                    setIsLoadingMainMedia(false);
                  }}
                  onWaiting={() => setIsWaitingMainMedia(true)}
                  onError={(e: any) => (videoRef.current = e.target)}
                  onTimeUpdate={handleOnTimeUpdate}
                  src={mediaUrl}
                />
              </CardActionArea>
            )}
            {contentType?.toUpperCase() === ContentType.VIDEO && !error && !isLoadingMainMedia && (
              <CardActions>
                <Button className="media-actions_play-buttons" onClick={togglePlay}>
                  {playerState.isPlaying && playerState.progress !== 0 && playerState.progress !== 100 && (
                    <PauseFillIcon />
                  )}
                  {(playerState.progress === 0 ||
                    playerState.progress === 100 ||
                    (!playerState.isPlaying && playerState.progress !== 0 && playerState.progress !== 100)) && (
                    <PlayFillIcon />
                  )}
                </Button>
                <div className="media-actions">
                  <div
                    className={`media-actions_volume ${playerSliderVolumeState ? 'active' : ''}`}
                    onMouseDown={() => setPlayerSliderVolumeState(true)}
                  >
                    <Button className="media-actions_volumeButton" onClick={toggleMute}>
                      {playerState.isMuted ? <VolumeMuteIcon /> : <VolumeIcon />}
                    </Button>
                    <div className="media-actions_slider media-actions_slider-vertical">
                      <Slider
                        min={0}
                        step={5}
                        valueLabelDisplay="on"
                        getAriaValueText={(value) => `${value}`}
                        valueLabelFormat={(value) => `${value}`}
                        orientation="vertical"
                        max={100}
                        value={playerState.volume}
                        onChange={(event, value) => handleVideoVolume(event, value)}
                      />
                    </div>
                  </div>
                  <div className="media-actions_slider">
                    <Slider
                      min={0}
                      step={0.1}
                      max={playerState.duration}
                      valueLabelDisplay="auto"
                      getAriaValueText={(value) => formatDuration(value)}
                      valueLabelFormat={(value) => formatDuration(value)}
                      value={playerState.currentTime}
                      onChange={handleVideoProgress}
                      onMouseDown={handleMouseDown}
                      onMouseUp={handleMouseUp}
                    />
                    <div className="media-duration">
                      <span>{formatDuration(playerState.currentTime)}</span>
                      <span>-{formatDuration(playerState.duration - playerState.currentTime)}</span>
                    </div>
                  </div>
                  <div
                    className={`media-actions_speed ${playerSliderSpeedState ? 'active' : ''}`}
                    onMouseDown={() => setPlayerSliderSpeedState(true)}
                  >
                    <Button className="media-actions_speedButton">
                      <SpeedIcon />
                    </Button>
                    <div className="media-actions_slider media-actions_slider-vertical">
                      <Slider
                        min={0.5}
                        step={0.25}
                        max={2}
                        orientation="vertical"
                        valueLabelDisplay="auto"
                        getAriaValueText={(index) => index + 'x'}
                        valueLabelFormat={(index) => index + 'x'}
                        marks
                        value={playerState.speed}
                        onChange={handleVideoSpeed}
                      />
                    </div>
                  </div>
                </div>
              </CardActions>
            )}
          </Card>
        </div>
      )}
    </div>
  );
};

export default MediaPreviewDrawer;
