import * as yup from 'yup';
import { SalesTaxGroup } from '../../constants';
import { ModalAction } from 'types/commonTypes';

const commonRules = {
  entityType: yup.string().required('Required field'),
  format: yup.string().required('Required field'),
  isDefaultSalesTaxRule: yup.boolean(),
  eventOnlineFormatType: yup.string(),
  eventCategories: yup.array(),
  passions: yup.array(),
  certainty: yup.string().required('Required field'),
  salesTaxCode: yup.object().shape({
    code: yup.string().required('Required field'),
    category: yup.string().required('Required field'),
  }),
};

const getValidationSchema = (action: ModalAction, contentType: SalesTaxGroup, isDefaultRule?: boolean) => {
  if (action === ModalAction.ADD && contentType === SalesTaxGroup.CODES) {
    return yup.object().shape({
      category: yup.string().required('Required field').trim().max(100, 'Max limit of 100 characters is exceeded'),
      code: yup.string().required('Required field').trim().max(25, 'Max limit of 25 characters is exceeded'),
      description: yup.string().required('Required field').trim().max(800, 'Max limit of 800 characters is exceeded'),
    });
  }

  if (action === ModalAction.ADD && contentType === SalesTaxGroup.RULES) {
    return yup.object().shape(commonRules);
  }

  if (action === ModalAction.EDIT && isDefaultRule && contentType === SalesTaxGroup.RULES) {
    return yup.object().shape({
      entityType: yup.string(),
      format: yup.string(),
      salesTaxCode: yup.object().shape({
        code: yup.string().required('Required field'),
        category: yup.string().required('Required field'),
      }),
      certainty: yup.string().required('Required field'),
    });
  } else {
    return yup.object().shape(commonRules);
  }
};

export default getValidationSchema;
