import { useState } from 'react';
import { enqueueSnackbar, VariantType } from 'notistack';
import { IStatus } from 'types/commonTypes';
import { Collection } from 'store/collectionDetailsSlice';
import { useAppDispatch } from 'store/hooks';
import { fetchArchiveCollections } from 'store/worldManagementSlice';
import Alert from 'components/shared/Alert/Alert';
import Button from 'components/shared/Button/Button';
import LoadingButton from 'components/shared/LoadingButton/LoadingButton';
import './index.scss';

interface IProps {
  items: Collection | Collection[];
  onClose(): void;
  status: IStatus;
}

const ArchiveCollectionsModal = ({ items, onClose, status }: IProps) => {
  let content;
  let setScroll;
  let title;
  const isArray = Array.isArray(items);
  const [error, setError] = useState(false);

  const dispatch = useAppDispatch();

  if (isArray) {
    title = 'You are going to archive following collections:';
    setScroll = items.length > 3;
    content = items.map((item) => (
      <div className="item-content" key={item.id}>
        <div className="item-primary">{item.title}</div>
        <div className="item-secondary">
          {item.creatorName} {item.creatorLastName}
        </div>
      </div>
    ));
  } else {
    title = 'You are going to archive collection:';
    content = (
      <div className="item-content">
        <div className="item-primary">{items.title}</div>
        <div className="item-secondary">
          {items.creatorName} {items.creatorLastName}
        </div>
      </div>
    );
  }

  const handleArchive = async () => {
    try {
      const compilationsIds = isArray ? items.map(({ id }) => id) : [items.id];
      const result = await dispatch(fetchArchiveCollections(compilationsIds));
      if (isArray) {
        result.meta.requestStatus === 'fulfilled' && onClose();
        result.meta.requestStatus === 'fulfilled' &&
          enqueueSnackbar(`${items.length} collections have been archived`, { variant: 'success' as VariantType });
        result.meta.requestStatus === 'rejected' && setError(true);
        return;
      }

      result.meta.requestStatus === 'fulfilled' &&
        enqueueSnackbar(`${items.title} collection has been archived`, { variant: 'success' as VariantType });
      result.meta.requestStatus === 'rejected' &&
        enqueueSnackbar('Archiving error, try again later', { variant: 'error' as VariantType });
      onClose();
    } catch (e) {
      if (isArray) {
        setError(true);
        return;
      }
      enqueueSnackbar('Archiving error, try again later', { variant: 'error' as VariantType });
      onClose();
    }
  };

  return (
    <div className="archiveCollectionsModal">
      <div className="archiveCollectionsModal-header">{title}</div>
      <div className={`archiveCollectionsModal-content ${setScroll && 'archiveCollectionsModal-content_scroll'}`}>
        {content}
      </div>
      <div className="archiveCollectionsModal-footer">
        {error && isArray && <Alert severity="error">Archiving error. Try later</Alert>}
        <div className="archiveCollectionsModal-footer-buttons">
          <Button onClick={onClose} variant="text" color="primary">
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="error"
            loading={status === 'loading' && isArray}
            onClick={handleArchive}
          >
            Archive collection{isArray ? 's' : ''}
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};

export default ArchiveCollectionsModal;
