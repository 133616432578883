import { useCallback, useMemo } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { VariantType, enqueueSnackbar } from 'notistack';
import { format } from 'date-fns';
import colors from 'theme/constants/colors';
import { IMediaItem, Permissions } from 'types/commonTypes';
import { checkPermissions } from 'utils/checkPermissions';
import { currencyFormatter } from 'utils/currencyFormatter';
import useExpandableDescription from 'hooks/useExpandableDescription';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchUpdateContentStatus } from 'store/contentDisplaySlice';
import { selectUserPermissions } from 'store/authSlice';
import ChevronDownIcon from 'assets/img/ChevronDownIcon';
import ChevronUpIcon from 'assets/img/ChevronUpIcon';
import CloseIcon from 'assets/img/CloseIcon';
import CommentIcon from 'assets/img/CommentIcon';
import EyeClosedFilledIcon from 'assets/img/EyeClosedFilledIcon';
import EyeClosedIcon from 'assets/img/EyeClosedIcon';
import LikeIcon from 'assets/img/LikeIcon';
import LocationIcon from 'assets/img/LocationIcon';
import MoneyIcon from 'assets/img/MoneyIcon';
import StarFilledIcon from 'assets/img/StarFilledIcon';
import StarIcon from 'assets/img/StarIcon';
import AvatarItem from 'components/shared/AvatarItem/AvatarItem';
import Chip from 'components/shared/Chip/Chip';
import IconButton from 'components/shared/IconButton/IconButton';
import ContentDisplayInfoBlock from 'components/ContentDisplay/ContentDisplayInfoBlock/ContentDisplayInfoBlock';
import Typography from 'components/shared/Typography/Typography';
import LifestyleCard from './LifestyleCard';
import './index.scss';

const Lifestyle = (props: any) => {
  const data = props.data;
  const publishDate = new Date(data.publishedAt);
  const userInfo = data.userInfoDto ?? {};
  const dispatch = useAppDispatch();
  const { isExpanded, toggleExpansion, renderedDescription } = useExpandableDescription(data.description);

  const userPermissions = useAppSelector(selectUserPermissions);
  const showMarkHideButtonGroup = checkPermissions(userPermissions, [
    Permissions.CONTENT_DISPLAY_MARK_HIDE_PUBLICATIONS,
  ]);

  const isPaidLifeStyle = data.price && data.priceOption !== 'FREE';

  const setContentStatus = useCallback(async (entityId: string, recommenderStatus: string | null, status: string) => {
    const result = await dispatch(
      fetchUpdateContentStatus({
        entityType: 'LIFESTYLE',
        entityId: entityId,
        status: recommenderStatus !== status ? status : null,
      }),
    );
    if (result.meta.requestStatus === 'fulfilled') {
      enqueueSnackbar(
        `Lifestyle #${data.entityId} has been ${recommenderStatus === status ? 'un' : ''}${status.toLowerCase()}`,
        {
          variant: 'success' as VariantType,
        },
      );
    } else if (result.meta.requestStatus === 'rejected') {
      enqueueSnackbar(
        `Lifestyle #${data.entityId} hasn't been ${
          recommenderStatus === status ? 'un' : ''
        }${status.toLowerCase()}, try again later`,
        {
          variant: 'error' as VariantType,
        },
      );
    }
  }, []);

  const locationHeader = useMemo(() => {
    if (data.locationInfo?.locationFullAddress) return data.locationInfo.locationFullAddress;
    if (data.locationInfo?.locationCountry && data.locationInfo?.locationCity)
      return `${data.locationInfo.locationCity}, ${data.locationInfo.locationCountry}`;
    if (data.locationInfo?.locationCountry) return `${data.locationInfo.locationCountry}`;
    return 'Location';
  }, [data.locationInfo]);

  const locationText = useMemo(() => {
    if (
      data.locationInfo &&
      data.locationInfo?.locationFullAddress &&
      data.locationInfo?.locationCountry &&
      data.locationInfo?.locationCity
    )
      return `${data.locationInfo.locationCity}, ${data.locationInfo.locationCountry}`;
    return 'Not specified';
  }, [data.locationInfo]);

  return (
    <div className={'lifestyleCard--container display--cellContainer' + (props.modalClose ? ' closeable' : '')}>
      <div className="lifestyleCard--containerHeader">
        <div className="lifestyleCard--eventName">
          <div className="lifestyleCard--eventId">Lifestyle #{data.entityId}</div>
          <div className="lifestyleCard--eventPublishDate">
            Published <span>{format(publishDate, 'MM/dd/yyyy, hh:mm a')}</span>
          </div>
        </div>
        {props.modalClose ? (
          <div className="lifestyleCard--eventActions">
            <IconButton sx={{ margin: '-4px -12px 0 0' }} size="small" onClick={() => props.modalClose()}>
              <CloseIcon />
            </IconButton>
          </div>
        ) : (
          <div className="lifestyleCard--eventActions">
            {showMarkHideButtonGroup && (
              <ToggleButtonGroup className="lifestyleCard--actionButtonsGroup" exclusive value={data.recommenderStatus}>
                <ToggleButton
                  value="STARRED"
                  size="small"
                  className="lifestyleCard--actionButton starred"
                  onClick={(e, value) => setContentStatus(data.entityId, data.recommenderStatus, value)}
                >
                  {data.recommenderStatus === 'STARRED' ? <StarFilledIcon /> : <StarIcon />}
                </ToggleButton>
                <ToggleButton
                  value="HIDDEN"
                  size="small"
                  className="lifestyleCard--actionButton hidden"
                  onClick={(e, value) => setContentStatus(data.entityId, data.recommenderStatus, value)}
                >
                  {data.recommenderStatus === 'HIDDEN' ? <EyeClosedFilledIcon /> : <EyeClosedIcon />}
                </ToggleButton>
              </ToggleButtonGroup>
            )}
          </div>
        )}
      </div>
      <div className="lifestyleCard--content">
        <div className="lifestyleCardContent--card">
          <LifestyleCard
            data={data}
            showMedia={(
              mediaUrl: string,
              mainEntity: IMediaItem,
              mediaType?: string,
              orderedMediaList?: IMediaItem[],
            ) => {
              if (data.orderedMediaList) {
                props.showMedia(data.entityId, data.entityType, mediaUrl, mainEntity, orderedMediaList, mediaType);
              } else {
                props.showMedia(data.entityId, data.entityType, mediaUrl);
              }
            }}
          />
        </div>
        <div className="lifestyleCardContent--descriptionContainer">
          <AvatarItem
            src={userInfo.avatarUrl}
            primaryText={userInfo.firstName + ' ' + userInfo.lastName}
            subText={`@${userInfo.username}`}
            onClick={() => props.openUser(userInfo.userId || '1')}
          />
          <div className="lifestyleCardContent--description">
            <Typography variant="body2" color="text.secondary" className="text" component="span">
              {renderedDescription}{' '}
            </Typography>
            {data.description && data.description.length > 250 && (
              <Typography
                component="span"
                className="trigger"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleExpansion();
                }}
              >
                <Typography component="span" variant="body2">
                  {isExpanded ? 'Collapse' : 'Expand'}
                </Typography>
                {isExpanded ? <ChevronUpIcon fontSize="small" /> : <ChevronDownIcon fontSize="small" />}
              </Typography>
            )}
          </div>
          <div className="lifestyleCardContent--counters">
            <div>
              <LikeIcon /> {data.likesCounter}
            </div>
            <div>
              <CommentIcon /> {data.commentsCounter}
            </div>
          </div>
        </div>
        <div className="lifestyleCardContent--passions">
          <div className="lifestyleCardContent--passionsTitle">Passions</div>
          <div className="lifestyleCardContent--passionsChips">
            {data.passions.length ? (
              data.passions.map((el: any, index: number) => (
                <Chip key={index} size="small" variant="filled" label={el} />
              ))
            ) : (
              <Typography variant="body2" color={colors.outlinedBorder}>
                Not specified
              </Typography>
            )}
          </div>
        </div>
        <div className="lifestyleCardContent--terms">
          {isPaidLifeStyle ? (
            <ContentDisplayInfoBlock
              icon={<MoneyIcon />}
              secondary="Price"
              primary={data.priceOption === 'FREE' ? 'Free' : `${currencyFormatter(data.price, 'en-US', 'USD')}`}
            />
          ) : (
            <></>
          )}
          <ContentDisplayInfoBlock
            withTooltip
            icon={<LocationIcon />}
            primary={locationText}
            secondary={locationHeader}
          />
        </div>
      </div>
    </div>
  );
};

export default Lifestyle;
