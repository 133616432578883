import { MouseEventHandler } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { TariffGroupsStatuses } from 'store/slices/Settings/interface';
import ContextIcon from 'assets/img/ContextIcon';
import IconButton from 'components/shared/IconButton/IconButton';
import Tooltip from 'components/shared/Tooltip/Tooltip';

/**
 * Props for the RenderActionsCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 * @property {MouseEventHandler<HTMLButtonElement>} onClick - Click event handler for the context button.
 */
interface IRenderActionsCellProps {
  props: GridRenderCellParams;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

/**
 * Renders actions cell in a data grid.
 */
export const RenderActionsCell = ({ props, onClick }: IRenderActionsCellProps) => {
  const { row } = props;

  if (row.tariffGroupId && row.status !== TariffGroupsStatuses.ACTIVE) {
    return (
      <Tooltip placement="top-end" followCursor title="Actions">
        <IconButton color="default" size="small" onClick={onClick}>
          <ContextIcon />
        </IconButton>
      </Tooltip>
    );
  }

  return <></>;
};
