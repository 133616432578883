const VerifyBlockedIcon = (props: any) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.3144 1.53767C11.7169 1.22197 12.2831 1.22197 12.6856 1.53767L13.7172 2.34663C13.9783 2.55136 14.3172 2.62871 14.6413 2.55753L15.9217 2.27628C16.4214 2.16652 16.9314 2.41214 17.1572 2.87125L17.7356 4.0477C17.882 4.34543 18.1538 4.56216 18.4766 4.63863L19.7523 4.94079C20.2501 5.0587 20.6031 5.50131 20.6073 6.0129L20.618 7.32381C20.6207 7.65558 20.7715 7.96877 21.0292 8.17774L22.0474 9.00346C22.4448 9.3257 22.5708 9.87762 22.3526 10.3404L21.7935 11.5261C21.652 11.8262 21.652 12.1738 21.7935 12.4739L22.3526 13.6596C22.5708 14.1224 22.4448 14.6743 22.0474 14.9965L21.0292 15.8223C20.7715 16.0312 20.6207 16.3444 20.618 16.6762L20.6073 17.9871C20.6031 18.4987 20.2501 18.9413 19.7523 19.0592L18.4766 19.3614C18.1538 19.4378 17.882 19.6546 17.7356 19.9523L17.1572 21.1287C16.9314 21.5879 16.4214 21.8335 15.9217 21.7237L14.6413 21.4425C14.3172 21.3713 13.9783 21.4486 13.7172 21.6534L12.6856 22.4623C12.2831 22.778 11.7169 22.778 11.3144 22.4623L10.2828 21.6534C10.0217 21.4486 9.68279 21.3713 9.35874 21.4425L8.0783 21.7237C7.57861 21.8335 7.06856 21.5879 6.84282 21.1287L6.26438 19.9523C6.11799 19.6546 5.84621 19.4378 5.52337 19.3614L4.24771 19.0592C3.74988 18.9413 3.39691 18.4987 3.39273 17.9871L3.38201 16.6762C3.3793 16.3444 3.22847 16.0312 2.97078 15.8223L1.95255 14.9965C1.55518 14.6743 1.42921 14.1224 1.64741 13.6596L2.20654 12.4739C2.34804 12.1738 2.34804 11.8262 2.20654 11.5261L1.64741 10.3404C1.42921 9.87762 1.55518 9.3257 1.95255 9.00346L2.97078 8.17774C3.22847 7.96877 3.3793 7.65558 3.38201 7.32381L3.39273 6.0129C3.39691 5.50131 3.74988 5.0587 4.24771 4.94079L5.52337 4.63863C5.84621 4.56216 6.11799 4.34543 6.26438 4.0477L6.84282 2.87125C7.06856 2.41214 7.57861 2.16652 8.07831 2.27628L9.35874 2.55753C9.68279 2.62871 10.0217 2.55136 10.2828 2.34663L11.3144 1.53767Z" fill="#DA612B"/>
      <path d="M9.00098 9L15.0007 14.9997" stroke="white" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.999 9L8.99966 15" stroke="white" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default VerifyBlockedIcon;