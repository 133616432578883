import * as yup from 'yup';
import { TIER_MAX_LIMIT, TIER_MIN_LIMIT } from 'yzzy-constants';

export const getValidationSchema = () => {
  return yup.object().shape({
    linkId: yup.string().when('radioValue', {
      is: (val: string) => val === 'list',
      then: (schema) => schema.required('Required field'),
    }),
    providerId: yup.string().required('Required field'),
    productId: yup.string().required('Required field').trim().max(50, 'Max limit of 50 characters is exceeded'),
    name: yup.string().required('Required field').trim().max(50, 'Max limit of 50 characters is exceeded'),
    price: yup
      .string()
      .required('Required field')
      .test('validateMinLimit', `Enter an amount greater than the minimum (${TIER_MIN_LIMIT})`, (value) =>
        value ? Number(value) >= TIER_MIN_LIMIT : false,
      )
      .test('validateMaxLimit', `Enter an amount less than the maximum (${TIER_MAX_LIMIT})`, (value) =>
        value ? Number(value) <= TIER_MAX_LIMIT : false,
      ),
    country: yup.string().required('Required field'),
    currency: yup.string().required('Required field'),
    operationType: yup.string().required('Required field'),
  });
};
