import { useEffect, useState } from 'react';
import { LocalStorageKeys } from 'types/commonTypes';
import Button from 'components/shared/Button/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Dialog from 'components/shared/Dialog/Dialog';
import DialogActions from 'components/shared/Dialog/DialogActions/DialogActions';
import DialogContent from 'components/shared/Dialog/DialogContent/DialogContent';
import DialogTitle from 'components/shared/Dialog/DialogTitle/DialogTitle';
import FormControl from 'components/shared/FormControl/FormControl';
import FormControlLabel from 'components/shared/FormControlLabel/FormControlLabel';
import Typography from 'components/shared/Typography/Typography';

interface IApprovalConfirmModalProps {
  open: boolean;
  onClose: () => void;
  onCardApproval: () => void;
}

export const ApprovalConfirmModal = ({ open, onClose, onCardApproval }: IApprovalConfirmModalProps) => {
  const localData = localStorage.getItem(LocalStorageKeys.SALES_TAX_APPROVAL_CONFIRM_SKIP);
  const salesTaxApprovalConfirmSkip = localData !== null ? !!JSON.parse(localData) : false;

  const [showApprovalConfirmModal, setShowApprovalConfirmModal] = useState<boolean>(salesTaxApprovalConfirmSkip);

  const handleCloseModal = () => {
    setShowApprovalConfirmModal(false);
    localStorage.setItem(LocalStorageKeys.SALES_TAX_APPROVAL_CONFIRM_SKIP, JSON.stringify(false));
    onClose();
  };

  useEffect(() => {
    localStorage.setItem(LocalStorageKeys.SALES_TAX_APPROVAL_CONFIRM_SKIP, JSON.stringify(showApprovalConfirmModal));
  }, [showApprovalConfirmModal]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle variant="h6">Approve sales tax?</DialogTitle>
      <DialogContent>
        <Typography mb={3} variant="body1">
          After the approval, you will still have the opportunity to change the sale tax, if necessary
        </Typography>
        <FormControl fullWidth>
          <FormControlLabel
            sx={{ pl: 1 }}
            control={
              <Checkbox
                disableRipple
                checked={showApprovalConfirmModal}
                onChange={(e) => setShowApprovalConfirmModal(e.target.checked)}
              />
            }
            label="Don't show this confirmation again. Immediately approve"
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal} variant="text" color="primary">
          Cancel
        </Button>
        <Button onClick={onCardApproval} variant="contained" color="primary">
          Approve
        </Button>
      </DialogActions>
    </Dialog>
  );
};
