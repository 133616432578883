import { enqueueSnackbar, VariantType } from 'notistack';
import { useHover } from '@uidotdev/usehooks';
import CopyIcon from 'assets/img/CopyIcon';
import IconButton from 'components/shared/IconButton/IconButton';
import ListItem from 'components/shared/List/ListItem/ListItem';
import ListItemIcon from 'components/shared/List/ListItem/ListItemComponents/ListItemIcon/ListItemIcon';
import ListItemText from 'components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import Typography from 'components/shared/Typography/Typography';

interface IUserProfileInfoBlockProps {
  icon?: any;
  primary: any;
  secondary: any;
  withCopy?: boolean;
  hasEllipsis?: boolean;
}

const UserProfileDataBlock = ({
  icon,
  primary,
  secondary,
  withCopy,
  hasEllipsis = true,
}: IUserProfileInfoBlockProps) => {
  const [ref, hovering] = useHover<HTMLDivElement>();

  const copyId = (e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    e.stopPropagation();

    if (!secondary) {
      enqueueSnackbar('There is nothing to copy', {
        variant: 'info' as VariantType,
      });
      return;
    }

    if (navigator.clipboard) {
      enqueueSnackbar('Copied to clipboard', {
        variant: 'success' as VariantType,
      });

      if (navigator.clipboard) {
        return navigator.clipboard.writeText(secondary);
      }
    }
  };

  return (
    <div className="data-block" ref={ref}>
      <ListItem
        sx={{ p: '6px 48px 6px 0' }}
        secondaryAction={
          withCopy &&
          hovering && (
            <Tooltip placement="top-end" followCursor title="Copy">
              <IconButton edge="end" color="default" size="small" onClick={(e) => copyId(e)}>
                <CopyIcon />
              </IconButton>
            </Tooltip>
          )
        }
      >
        {icon && (
          <ListItemIcon
            sx={{
              alignSelf: 'self-start',
              minWidth: 'auto',
              mr: 2,
              mt: '18px',
            }}
          >
            {icon}
          </ListItemIcon>
        )}
        <ListItemText
          disableTypography
          primary={
            <Typography variant="body2" color="text.secondary">
              {primary}
            </Typography>
          }
          secondary={
            <Typography
              sx={{
                overflow: hasEllipsis ? 'hidden' : 'visible',
                textOverflow: hasEllipsis ? 'ellipsis' : 'clip',
                whiteSpace: hasEllipsis ? 'nowrap' : 'normal',
              }}
              variant="subtitle1"
              color="text.primary"
            >
              {secondary}
            </Typography>
          }
        />
      </ListItem>
    </div>
  );
};

export default UserProfileDataBlock;
