import { ReactNode, useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Collapse } from '@mui/material';
import { Permissions } from 'types/commonTypes';
import { checkPermissions } from 'utils/checkPermissions';
import { fetchAvatar, selectAvatarPreview } from 'store/profileSlice';
import {
  fetchSignOut,
  logout,
  selectIsWarningRedirect,
  selectUserPermissions,
  setIsWarningRedirect,
} from 'store/authSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { LogoutWarningModal } from 'components/Layout/modals';
import { VariantType, enqueueSnackbar } from 'notistack';
import ContentIcon from 'assets/img/ContentIcon';
import DisputeIcon from 'assets/img/DisputeIcon';
import Logo from 'assets/img/Logo';
import LogoutIcon from 'assets/img/LogoutIcon';
import PaymentsSettingsIcon from 'assets/img/PaymentsSettingsIcon';
import ShieldIcon from 'assets/img/ShieldIcon';
import TaxModerationIcon from 'assets/img/TaxModerationIcon';
import Users2Icon from 'assets/img/Users2Icon';
import Users3Icon from 'assets/img/Users3Icon';
import WalletIcon from 'assets/img/WalletIcon';
import WorldIcon from 'assets/img/WorldIcon';
import Avatar from 'components/shared/Avatar/Avatar';
import Divider from 'components/shared/Divider/Divider';
import Typography from 'components/shared/Typography/Typography';
import LogoutModal from 'components/LogoutModal/LogoutModal';
import './index.scss';

const NavLinkWithIcon = ({
  to,
  icon,
  text,
  isDisabled,
  resetBoundary,
}: {
  to: string;
  icon: ReactNode;
  text: string;
  isDisabled?: boolean;
  resetBoundary?: () => void;
}) => (
  <NavLink
    to={to}
    onClick={resetBoundary}
    className={({ isActive }) =>
      `layout-menu-link${isActive ? ' layout-menu-link--active' : ''}${isDisabled ? ' layout-menu-link--disabled' : ''}`
    }
  >
    {icon}
    <Typography variant="inputText">{text}</Typography>
  </NavLink>
);

const Navbar = ({
  isMenuOpen,
  handleOpen,
  resetBoundary,
}: {
  isMenuOpen: boolean;
  handleOpen: (value: boolean) => void;
  resetBoundary?: () => void;
}) => {
  const dispatch = useAppDispatch();
  const memberAvatarPreview = useAppSelector(selectAvatarPreview);
  const userPermissions = useAppSelector(selectUserPermissions);
  const isWarningRedirect = useAppSelector(selectIsWarningRedirect);

  const hasPaymentsPermissions = checkPermissions(userPermissions, [
    Permissions.PAYMENTS_VIEW_OPERATIONS,
    Permissions.PAYMENTS_VIEW_PAGE_BULK_PAYOUTS,
  ]);

  const hasSalesTaxModerationPermissions = checkPermissions(userPermissions, [
    Permissions.SALES_TAX_MODERATION_VIEW_SOLVED_PAGE,
    Permissions.SALES_TAX_MODERATION_VIEW_UNRESOLVED_PAGE,
  ]);

  const hasSettingsPermissions = checkPermissions(userPermissions, [
    Permissions.SETTINGS_VIEW_PAGE_TARIFF_GROUPS,
    Permissions.SETTINGS_VIEW_IN_APP_TIERS,
    Permissions.SETTINGS_VIEW_TAXES_CODES,
    Permissions.SETTINGS_VIEW_TAXES_RULES,
    Permissions.SETTINGS_VIEW_PAYMENT_FEES,
    Permissions.SETTINGS_VIEW_TARIFFS_IN_GROUP,
    Permissions.SETTINGS_VIEW_PRICES_RESTRICTIONS_PAGE,
    Permissions.SETTINGS_VIEW_WITHDRAWAL_RESTRICTIONS_PAGE,
  ]);

  const hasConsumptionRequestPermissions = checkPermissions(userPermissions, [
    Permissions.REQUESTS_REFUNDS_DISPUTES_VIEW_OPEN_PAGE,
    Permissions.REQUESTS_REFUNDS_DISPUTES_VIEW_CLOSED_PAGE,
  ]);

  const hasContentDisplayPermissions = checkPermissions(userPermissions, [
    Permissions.CONTENT_DISPLAY_VIEW_CONTENT_DISPLAY,
  ]);

  const shouldShowDivider = hasPaymentsPermissions || hasSalesTaxModerationPermissions || hasSettingsPermissions;

  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);

  const handleWarningModalClose = () => {
    dispatch(setIsWarningRedirect(false));
    setShowWarningModal(false);
  };

  const handleLogoutModalClose = () => {
    if (isWarningRedirect) {
      dispatch(setIsWarningRedirect(false));
    }
    setShowLogoutModal(false);
  };

  const handleWarningModalLogout = () => {
    setShowWarningModal(false);
    setShowLogoutModal(true);
  };

  const handleLogout = useCallback(async () => {
    setShowLogoutModal(false);
    if (isWarningRedirect) {
      dispatch(setIsWarningRedirect(false));
    }
    const result = await dispatch(fetchSignOut());
    if (result.meta.requestStatus === 'fulfilled') {
      dispatch(logout({ message: '' }));
    }
    if (result.meta.requestStatus === 'rejected') {
      enqueueSnackbar('Logging out error, try again later', {
        variant: 'error' as VariantType,
      });
    }
  }, []);

  useEffect(() => {
    dispatch(fetchAvatar());
  }, []);

  useEffect(() => {
    if (isWarningRedirect) {
      setShowWarningModal(true);
    }
  }, [isWarningRedirect]);

  return (
    <>
      <div className="layout-menu-container">
        <Collapse in={isMenuOpen} orientation={'horizontal'} collapsedSize={64} style={{ height: '100%' }}>
          <div
            className={`layout-menu${isMenuOpen ? ' layout-menu--open' : ''}`}
            onMouseEnter={() => handleOpen(true)}
            onMouseLeave={() => handleOpen(false)}
          >
            <div className="layout-menu--navbar">
              <div className="layout-menu__logo-container">
                <Logo isMenuOpen={isMenuOpen} />
              </div>
              <Divider className="layout-menu__divider" orientation="horizontal" flexItem />

              <NavLinkWithIcon resetBoundary={resetBoundary} to="/ui/users" icon={<Users2Icon />} text="YZZY users" />
              {hasContentDisplayPermissions && (
                <NavLinkWithIcon
                  resetBoundary={resetBoundary}
                  to="/ui/content-display"
                  icon={<ContentIcon />}
                  text="Content display"
                />
              )}
              {/*<NavLinkWithIcon
                to="/ui/world-management/collections"
                icon={<WorldIcon />}
                text="TiM World management"
              />*/}
              <NavLinkWithIcon
                resetBoundary={resetBoundary}
                to="/ui/moderation"
                icon={<ShieldIcon />}
                text="Content moderation"
              />

              {shouldShowDivider && <Divider className="layout-menu__divider" orientation="horizontal" flexItem />}

              {hasPaymentsPermissions && (
                <NavLinkWithIcon
                  resetBoundary={resetBoundary}
                  to="/ui/payments"
                  icon={<WalletIcon />}
                  text="Payments"
                />
              )}
              {hasSalesTaxModerationPermissions && (
                <NavLinkWithIcon
                  resetBoundary={resetBoundary}
                  to="/ui/tax-moderation"
                  icon={<TaxModerationIcon />}
                  text="Sales tax moderation"
                />
              )}
              {hasConsumptionRequestPermissions && (
                <NavLinkWithIcon
                  resetBoundary={resetBoundary}
                  to="/ui/consumption-requests"
                  icon={<DisputeIcon />}
                  text="Requests refunds & disputes"
                />
              )}
              {hasSettingsPermissions && (
                <NavLinkWithIcon
                  resetBoundary={resetBoundary}
                  to="/ui/payments-settings"
                  icon={<PaymentsSettingsIcon />}
                  text="Payments settings"
                />
              )}

              <Divider className="layout-menu__divider" orientation="horizontal" flexItem />
              <NavLinkWithIcon resetBoundary={resetBoundary} to="/ui/members" icon={<Users3Icon />} text="Team" />
            </div>

            <div className="layout-menu__user-container">
              <div className="layout-menu-link" onClick={() => setShowLogoutModal(true)}>
                <LogoutIcon />
                <Typography variant="inputText">Log out</Typography>
              </div>

              <Divider className="layout-menu__divider" orientation="horizontal" flexItem />

              <NavLink
                onClick={() => resetBoundary?.()}
                to="/ui/profile"
                className={({ isActive }) =>
                  `layout-menu-link${isActive ? ' layout-menu-link--active' : ''} avatar-icon`
                }
              >
                <Avatar src={memberAvatarPreview.originalLink ?? ''} alt="avatar" />
                <Typography variant="inputText">Profile</Typography>
              </NavLink>
            </div>
          </div>
        </Collapse>
      </div>
      <LogoutModal
        open={showLogoutModal}
        isWarningRedirect={isWarningRedirect}
        onClose={handleLogoutModalClose}
        logout={handleLogout}
      />
      <LogoutWarningModal
        open={showWarningModal}
        onClose={handleWarningModalClose}
        onLogout={handleWarningModalLogout}
      />
    </>
  );
};

export default Navbar;
