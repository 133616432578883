import { IDropdownItem, IGroupedDropdownItem, IRefundReason, IStatus } from 'types/commonTypes';
import colors from 'theme/constants/colors';

/* ----------------------------- Tariff groups ------------------------------ */
/**
 * Represents a tariff group.
 *
 * @interface
 * @property {string} tariffGroupId - The unique identifier for the tariff group.
 * @property {string} tariffGroupTitle - The title or name of the tariff group.
 * @property {string} groupDescription - The description of the tariff group.
 * @property {TariffGroupsStatuses} status - The status of the tariff group.
 * @property {Date} createdAt - The date of group's creation.
 */
export interface ITariffGroup {
  tariffGroupId: string;
  tariffGroupTitle: string;
  groupDescription: string;
  status: TariffGroupsStatuses;
  createdAt: Date;
}

/**
 * Represents a tariff group details.
 *
 * @interface
 * @property {string} tariffId - The unique identifier for the tariff.
 * @property {string} priceMin - Minimum price.
 * @property {string} priceMax - Maximum price.
 * @property {string} platformFeePercentageClient - The percentage of platform commission for the client.
 * @property {string} platformFeeFixedClient - The platform fee, fixed client fee.
 * @property {string} platformFeePercentageCreator - The percentage of creator commissions.
 * @property {string} platformFeeFixedCreator - The creator's fixed commission.
 * @property {string} providerName - The name of provider.
 * @property {string} operationType - The type of entity to which the tariff applies.
 * @property {Date} tariffCreatedAt - The date of tariff's creation.
 */
export interface ITariffGroupDetails {
  tariffId: string;
  priceMin: string;
  priceMax: string;
  platformFeePercentageClient: string;
  platformFeeFixedClient: string;
  platformFeePercentageCreator: string;
  platformFeeFixedCreator: string;
  providerName: string;
  operationType: string;
  tariffCreatedAt: Date;
}

/**
 * Represents the state of tariff groups in store.
 *
 * @interface
 * @property {ITariffGroup[]} tariffGroups - An array of tariff groups.
 * @property {ITariffGroupDetails[]} tariffGroupDetails - An array of tariffs.
 * @property {IStatus} tariffGroupsStatus - The status object indicating the state data (tariff group).
 * @property {IStatus} tariffGroupDetailsStatus - The status object indicating the state data (tariffs).
 */
export interface ITariffGroupsState {
  tariffGroups: ITariffGroup[];
  tariffGroupDetails: ITariffGroupDetails[];
  tariffGroupsStatus: IStatus;
  tariffGroupDetailsStatus: IStatus;
}

/**
 * Enumerates the possible status values for tariff groups.
 *
 * @enum {string}
 * @readonly
 * @property {string} INACTIVE - The inactive status for a tariff group.
 * @property {string} ACTIVE - The active status for a tariff group (**it's always one entry**).
 */
export enum TariffGroupsStatuses {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
}

/**
 * Represents status object for tariff group.
 *
 * @interface
 * @property {TariffGroupsStatuses} status - Status value.
 * @property {string} title - Status title.
 * @property {colors} color - Associated color code.
 */
export interface ITariffGroupStatus {
  status: TariffGroupsStatuses;
  title: string;
  color: colors;
}

/* ------------------------------ Payment fees ------------------------------ */
/**
 * Represents a payment fee.
 *
 * @interface
 * @property {string} providerTariffId - The unique identifier for the fee.
 * @property {string} providerId - The unique identifier for the provider.
 * @property {string} paymentMethodId - The unique identifier for the payment method.
 * @property {string} paymentMethodName - The name of the payment method.
 * @property {TariffGroupsStatuses} feeStatus - The status of the fee.
 * @property {string} paymentFeePercentage - The percentage of the fee.
 * @property {string} paymentFeeFixed - The fixed fee.
 * @property {string} providerName - The name of the provider.
 * @property {string} transactionType - The type of transaction.
 * @property {Date} createdAt - The date of fee's creation.
 */
export interface IPaymentFee {
  providerTariffId: string;
  providerId: string;
  paymentMethodId: string;
  paymentMethodName: string;
  feeStatus: PaymentFeesStatuses;
  paymentFeePercentage: string;
  paymentFeeFixed: string;
  providerName: string;
  transactionType: string;
  createdAt: Date;
}

/**
 * Represents a fee condition.
 *
 * @interface
 * @property {string} id - The unique identifier for the condition.
 * @property {string} providerId - The unique identifier for the provider.
 * @property {string} transactionType - The type of transaction.
 * @property {string} paymentMethodId - The unique identifier for the payment method.
 */
export interface IFeeCondition {
  id: string;
  providerId: string;
  transactionType: string;
  paymentMethodId: string;
}

/**
 * Represents the state of payment fees in store.
 *
 * @interface
 * @property {IPaymentFee[]} fees - An array of fees.
 * @property {IFeeCondition[]} feesConditions - An array of conditions.
 * @property {number} feesCount - Total count of payment fees.
 * @property {IStatus} status - The status value for the state data.
 * @property {IStatus} addingFeeStatus - The status value for the fetchAddPaymentFee method.
 */
export interface IPaymentFeesState {
  fees: IPaymentFee[];
  feesConditions: IFeeCondition[];
  feesCount: number;
  status: IStatus;
  addingFeeStatus: IStatus;
}

/**
 * Enumerates the possible status values for payment fees.
 *
 * @enum {string}
 * @readonly
 * @property {string} INACTIVE - The inactive status for a payment fee.
 * @property {string} ACTIVE - The active status for a payment fee (**it's always one entry**).
 */
export enum PaymentFeesStatuses {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
}

/**
 * Represents status object for payment fee.
 *
 * @interface
 * @property {PaymentFeesStatuses} status - Status value.
 * @property {string} title - Status title.
 * @property {colors} color - Associated color code.
 */
export interface IPaymentFeeStatus {
  status: PaymentFeesStatuses;
  title: string;
  color: colors;
}

/* ------------------------------ In App Tiers ------------------------------ */
/**
 * Represents an in-app tier.
 *
 * @interface
 * @property {string} linkId - The unique identifier of entry.
 * @property {string} provider - The platform through which the in-app purchase is made (Apple or Google).
 * @property {string} productId - A unique tier identifier in an Apple or Google Developer account.
 * @property {string} name - The name of the tier in the Apple or Google Developer account.
 * @property {string} price - Purchase price.
 * @property {string} country - The country for which the tier is applied.
 * @property {string} currency - The currency code of purchase.
 * @property {string} operationType - The type of operation (online event, paid lifestyle, stream donation).
 * @property {TierStatuses} status - The status of the tier.
 */
export interface ITier {
  linkId: string;
  providerId: string;
  productId: string;
  name: string;
  price: string;
  country: string;
  currency: string;
  operationType: string;
  status: TierStatuses;
}

/**
 * Represents the state of in-app tiers in store.
 *
 * @interface
 * @property {ITier[]} tiers - An array of tiers.
 * @property {number} totalCount - Number of all tiers.
 * @property {{ value: string; providerName: string }[]} providerOptions - An array of providers for Autocomplete.
 * @property {{ value: string; operationTypeDescription: string }[]} operationTypesOptions - An array of operationTypes for Autocomplete.
 * @property {{ value: string; providerName: string; productId: string; name: string; price: string }[]} linkIdOptions - An array of linkId for Autocomplete.
 * @property {IStatus} status - The status value for the state data.
 */
export interface ITiersState {
  tiers: ITier[];
  totalCount: number;
  providerOptions: {
    value: string;
    providerName: string;
  }[];
  operationTypesOptions: {
    value: string;
    operationTypeDescription: string;
  }[];
  linkIdOptions: {
    value: string;
    providerName: string;
    productId: string;
    name: string;
    price: string;
  }[];
  status: IStatus;
}

/**
 * Enumerates the possible status values for tier.
 *
 * @enum {string}
 * @readonly
 * @property {string} INACTIVE - The inactive status.
 * @property {string} ACTIVE - The active status.
 */
export enum TierStatuses {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
}

/**
 * Represents status object for in-app tiers.
 *
 * @interface
 * @property {TierStatuses} status - Status value.
 * @property {string} title - Status title.
 * @property {colors} color - Associated color code.
 */
export interface ITierStatus {
  status: TierStatuses;
  title: string;
  color: colors;
}

/* ------------------------------ Taxes ------------------------------ */
/**
 * Represents a sales tax code.
 *
 * @interface
 * @property {string} id - The unique identifier of entry.
 * @property {string} salesTaxCodeId - Additional unique identifier of entry.
 * @property {string} code - The unique tax code.
 * @property {string} category - The category of tax code.
 * @property {string} description - The description of the tax code.
 * @property {TaxesStatuses} status - The status of the tax code.
 */
export interface ITaxCode {
  id: string;
  salesTaxCodeId: string;
  code: string;
  category: string;
  description: string;
  status: TaxesStatuses;
}

/**
 * Support type for mapping DataGrid data in sales tax rules.
 */
export type TDataGridRowItem = {
  salesTaxRuleId: string;
  salesTaxCode: Pick<ITaxCode, 'id' | 'salesTaxCodeId' | 'code' | 'category' | 'status'>;
  certainty: TaxRuleCertainty;
  entityType: EntityType;
  eventOnlineFormatType?: EventOnlineFormatType;
  status: TaxesStatuses;
  format?: EventFormat;
  eventCategories?: {
    id: string;
    title: string;
  }[];
  passions?: {
    id: string;
    title: string;
  }[][];
};

/**
 * Represents a rule for sales tax.
 *
 * @interface
 * @property {string} [id] - The unique identifier of the sales tax rule (optional).
 * @property {string} salesTaxRuleId - The unique identifier of the sales tax rule.
 * @property {Pick<ITaxCode, 'id' | 'salesTaxCodeId' | 'code' | 'category' | 'status'>} salesTaxCode - Information about the associated sales tax code.
 * @property {TaxRuleCertainty} certainty - The certainty level of applying the sales tax rule.
 * @property {EntityType} entityType - The type of entity to which the sales tax applies.
 * @property {EventOnlineFormatType} [eventOnlineFormatType] - The online format type (applies only if entityType is EVENT and format is ONLINE).
 * @property {TaxesStatuses} status - The status of the sales tax rule.
 * @property {boolean} isDefaultSalesTaxRule - Indicates if the sales tax rule is a default one.
 * @property {EventFormat} [format] - The format of the sales tax rule (applies only for entityType Offer/Wish/PrivateWish/Event).
 * @property {(string | void | IDropdownItem)[]} [eventCategories] - Array of event categories (applies only for entityType EVENT).
 * @property {(
 *   | string[]
 *   | void
 *   | IGroupedDropdownItem[]
 * )[]} [passions] - Array of passions (applies only for entityType Offer/Wish/Event).
 */
export interface ITaxRule {
  id?: string;
  salesTaxRuleId: string;
  salesTaxCode: Pick<ITaxCode, 'id' | 'salesTaxCodeId' | 'code' | 'category' | 'status'>;
  certainty: TaxRuleCertainty;
  entityType: EntityType;
  eventOnlineFormatType?: EventOnlineFormatType;
  status: TaxesStatuses;
  isDefaultSalesTaxRule: boolean;
  format?: EventFormat;
  eventCategories?: (string | void | IDropdownItem)[];
  passions?: (string[] | void | IGroupedDropdownItem[])[];
}

/**
 * Enumerates the possible formats for entities in app.
 *
 * @enum {string}
 * @readonly
 * @property {string} ONLINE - Online format.
 * @property {string} OFFLINE - Offline format.
 */
export enum EventFormat {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

/**
 * Enumerates the possible online format types for events in sales tax rule.
 *
 * @enum {string}
 * @readonly
 * @property {string} STREAM - Stream format type.
 * @property {string} CONFERENCE - Conference format type.
 */
export enum EventOnlineFormatType {
  STREAM = 'STREAM',
  CONFERENCE = 'CONFERENCE',
}

/**
 * Represents the state of sales tax codes in store.
 *
 * @interface
 * @property {ITaxCode[]} taxCodes - An array of sales tax codes.
 * @property {ITaxRule[]} taxRules - An array of sales tax rules.
 * @property {{ taxCodes: number; taxRules: number }} totalCount - Total count of tax codes and rules.
 * @property {IStatus} status - The status value for the state data.
 */
export interface ITaxesState {
  taxCodes: ITaxCode[];
  taxRules: ITaxRule[];
  totalCount: {
    taxCodesCount: number;
    taxRulesCount: number;
  };
  status: IStatus;
}

/**
 * Enumerates the possible status values for taxes.
 *
 * @enum {string}
 * @readonly
 * @property {string} INACTIVE - The inactive status.
 * @property {string} ACTIVE - The active status.
 * @property {string} DEFAULT - The default status.
 */
export enum TaxesStatuses {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DEFAULT = 'DEFAULT',
}

/**
 * Enumerates the possible status values for sales tax rule certainty.
 *
 * @enum {string}
 * @readonly
 * @property {string} FULL - Full certainty.
 * @property {string} UNCERTAIN - Uncertain certainty.
 */
export enum TaxRuleCertainty {
  FULL = 'FULL',
  UNCERTAIN = 'UNCERTAIN',
}

/**
 * Enumerates the possible entity types for sales tax module.
 *
 * @enum {string}
 * @readonly
 * @property {string} DONATION - Donation entity type.
 * @property {string} EVENT - Event entity type.
 * @property {string} GOAL - Goal entity type.
 * @property {string} OFFER - Offer entity type.
 * @property {string} PAID_LIFESTYLE - Paid lifestyle entity type.
 * @property {string} PRIVATE_WISH - Private wish entity type.
 * @property {string} TIP - Tip entity type.
 * @property {string} WISH - Wish entity type.
 */
export enum EntityType {
  DONATION = 'DONATION',
  EVENT = 'EVENT',
  GOAL = 'GOAL',
  OFFER = 'OFFER',
  PAID_LIFESTYLE = 'PAID_LIFESTYLE',
  PRIVATE_WISH = 'PRIVATE_WISH',
  TIP = 'TIP',
  WISH = 'WISH',
}

/**
 * Represents status object for sales tax.
 *
 * @interface
 * @property {TaxesStatuses} status - Status value.
 * @property {string} title - Status title.
 * @property {colors} color - Associated color code.
 */
export interface ITaxStatus {
  status: TaxesStatuses;
  title: string;
  color: colors;
}

/* ------------------------------ Dictionaries ------------------------------ */

/**
 * Represents the state of dictionaries in store.
 *
 * @interface
 * @property {IProvider[]} providers - An array of providers.
 * @property {IPaymentMethod[]} paymentMethods - An array of payment methods.
 * @property {ITransactionType[]} transactionTypes - An array of transaction types.
 * @property {IDropdownItem[]} entityTypes - An array of entity types.
 * @property {IDropdownItem[]} formats - An array of formats.
 * @property {IDropdownItem[]} onlineFormatTypes - An array of online format types.
 * @property {IDropdownItem[]} eventCategories - An array of event categories.
 * @property {IGroupedDropdownItem[]} passions - An array of grouped dropdown items representing passions.
 * @property {IGroupedDropdownItem[]} salesTaxCodes - An array of grouped dropdown items representing sales tax codes.
 * @property {Pick<ITaxCode, 'id' | 'code' | 'category' | 'description'>[]} salesTaxCodesList - An array of pick types representing sales tax codes.
 * @property {IDropdownItem[]} certainties - An array of certainties.
 * @property {IRefundReason[]} refundReasons - An array of refund reasons.
 * @property {IStatus} status - The status value for the state data.
 */
export interface IDictionariesState {
  providers: IProvider[];
  paymentMethods: IPaymentMethod[];
  transactionTypes: ITransactionType[];
  entityTypes: IDropdownItem[];
  formats: IDropdownItem[];
  onlineFormatTypes: IDropdownItem[];
  eventCategories: IDropdownItem[];
  passions: IGroupedDropdownItem[];
  salesTaxCodes: IGroupedDropdownItem[];
  salesTaxCodesList: Pick<ITaxCode, 'id' | 'code' | 'category' | 'description'>[];
  certainties: IDropdownItem[];
  refundReasons: IRefundReason[];
  status: IStatus;
}

/**
 * Represents provider.
 *
 * @interface
 * @property {string} providerId - The ID of the provider.
 * @property {string} providerName - The name of the provider.
 * @property {string} providerDescription - A description of the provider.
 * @property {string} status - The status of the provider.
 * @property {string} displayName - Label of element for dropdown.
 */
export interface IProvider {
  providerId: string;
  providerName: string;
  providerDescription: string;
  status: string;
  displayName: string;
}

/**
 * Represents a payment method.
 *
 * @interface
 * @property {string} paymentMethodId - The ID of the payment method.
 * @property {string} paymentMethodName - The name of the payment method.
 * @property {string} paymentMethodDescription - A description of the payment method.
 * @property {string} displayName - Label of element for dropdown.
 */
export interface IPaymentMethod {
  paymentMethodId: string;
  paymentMethodName: string;
  paymentMethodDescription: string;
  displayName: string;
}

/**
 * Represents a transaction type.
 *
 * @interface
 * @property {string} transactionTypeId - The ID of the transaction type.
 * @property {string} transactionTypeName - The name of the transaction type.
 * @property {string} displayName - Label of element for dropdown.
 */
export interface ITransactionType {
  transactionTypeId: string;
  transactionTypeName: string;
  displayName: string;
}
