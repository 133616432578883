import React, { FC, ForwardedRef, forwardRef } from "react";
import MUIRichTextEditor, { TMUIRichTextEditorProps, TMUIRichTextEditorRef, TToolbarComponentProps } from "mui-rte";
import { ToggleButton } from "@mui/material";
import { EditorState } from "draft-js";

import './index.scss';

export interface TextEditorProps extends Omit<TMUIRichTextEditorProps, 'onChange'> {
  onChange?: (editorState: EditorState) => void;
}

const TextEditor = forwardRef(({ onChange, ...props }: TextEditorProps, ref: ForwardedRef<TMUIRichTextEditorRef | null>) => {
  return (
    <MUIRichTextEditor
      {...props}
      ref={ref}
      onChange={onChange}
    />
  );
});

export default TextEditor;

export const HeaderBlockStyle = (props: any) => {
  return (
    <div className={props.readOnly ? "TextEditor-readonlyStyle" : "TextEditor-headerBlockStyle"}>
      {props.children}
    </div>
  )
};

export const HeaderToolbarButton: FC<TToolbarComponentProps> = (props) => {
  return (
    <ToggleButton
      id={props.id}
      onMouseDown={(event) => {
        props.onMouseDown(event);
      }}
      className={props.active ? 'TextEditor-button TextEditor-button_active' : 'TextEditor-button'}
      value="header"
      size="small"
      disabled={props.disabled}
    >
      Heading
    </ToggleButton>
  );
};