import { createSvgIcon } from '@mui/material';

const UserIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12.0005 2.6499C9.12902 2.6499 6.75049 4.9057 6.75049 7.7499C6.75049 10.5941 9.12902 12.8499 12.0005 12.8499C14.872 12.8499 17.2505 10.5941 17.2505 7.7499C17.2505 4.9057 14.872 2.6499 12.0005 2.6499ZM8.45049 7.7499C8.45049 5.89968 10.0118 4.3499 12.0005 4.3499C13.9891 4.3499 15.5505 5.89968 15.5505 7.7499C15.5505 9.60012 13.9891 11.1499 12.0005 11.1499C10.0118 11.1499 8.45049 9.60012 8.45049 7.7499Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.0003 14.3374C10.2693 14.3374 8.4993 14.63 7.1293 15.2143C5.81998 15.7728 4.55029 16.7563 4.55029 18.2864C4.55029 18.9469 4.89507 19.4723 5.33878 19.8504C5.77289 20.2204 6.35135 20.4982 6.99115 20.7084C8.2771 21.1311 10.0258 21.3499 12.0003 21.3499C13.7117 21.3499 15.4505 21.1284 16.7898 20.7127C17.4545 20.5064 18.0731 20.2364 18.5435 19.8832C19.0071 19.5351 19.4503 19.006 19.4503 18.2864C19.4503 17.5606 19.1085 16.9448 18.6444 16.4644C18.184 15.988 17.5654 15.6012 16.8848 15.2969C15.5218 14.6874 13.7507 14.3374 12.0003 14.3374ZM6.25029 18.2864C6.25029 17.8413 6.63061 17.2753 7.79628 16.778C8.90129 16.3067 10.4313 16.0374 12.0003 16.0374C13.5499 16.0374 15.0787 16.3515 16.1908 16.8488C16.7477 17.0978 17.1603 17.3751 17.4218 17.6457C17.6768 17.9096 17.7488 18.1221 17.7503 18.2812C17.7439 18.3026 17.7064 18.3859 17.5228 18.5238C17.2713 18.7126 16.8586 18.9114 16.2858 19.0891C15.1501 19.4417 13.5889 19.6499 12.0003 19.6499C10.1248 19.6499 8.57349 19.439 7.52193 19.0934C6.99299 18.9196 6.64332 18.7286 6.44149 18.5566C6.25026 18.3936 6.25029 18.3035 6.25029 18.2864Z" />
  </svg>,'UserIcon'
)

export default UserIcon;
