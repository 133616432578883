import colors from 'theme/constants/colors';
import { IStatus } from 'types/commonTypes';
import { EntityType, EventFormat, EventOnlineFormatType, ITaxCode, ITaxRule } from '../Settings/interface';

/* ------------------------------ Sales Tax Moderation ------------------------------ */
/**
 * Represents a card for sales tax moderation.
 *
 * @interface
 * @property {string} id - The unique identifier of the sales tax moderation card.
 * @property {string} entityName - The name of the entity associated with the sales tax moderation card.
 * @property {string} entityDescription - The description of the entity associated with the sales tax moderation card.
 * @property {EntityType} entityType - The type of entity to which the sales tax moderation card applies.
 * @property {EventFormat} [format] - The format of the sales tax moderation card.
 * @property {EventOnlineFormatType} [eventOnlineFormatType] - The online format type.
 * @property {Pick<ITaxCode, 'code' | 'category' | 'description'>} salesTaxCode - Information about the associated sales tax code.
 * @property {Date} createdAt - The date when the sales tax moderation card was created.
 * @property {TaxModerationSolution} solution - The solution status of the sales tax moderation card.
 */
export interface ITaxModerationCard {
  id: string;
  entityName: string;
  entityDescription: string;
  entityType: EntityType;
  format?: EventFormat;
  status?: TaxModerationStatus;
  eventOnlineFormatType?: EventOnlineFormatType;
  salesTaxCode: Pick<ITaxCode, 'id' | 'code' | 'category' | 'description'>;
  salesTaxRule: ITaxRule;
  createdAt: Date;
  solution: TaxModerationSolution;
  eventCategory?: string;
  passions?: string[];
}

/**
 * Enumerates the possible status values for sales tax moderation card.
 *
 * @enum {string}
 * @readonly
 * @property {string} RESOLVED - The resolved status.
 * @property {string} CALCULATED - The calculated status.
 * @property {string} NEEDS_REVIEW - The needs review status.
 * @property {string} ASSIGN_TO_SUPERVISOR - The assign to supervisor status.
 */
export enum TaxModerationStatus {
  RESOLVED = 'RESOLVED',
  CALCULATED = 'CALCULATED',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  ASSIGN_TO_SUPERVISOR = 'ASSIGN_TO_SUPERVISOR',
}

/**
 * Enumerates the possible solutions for sales tax moderation card.
 *
 * @enum {string}
 * @readonly
 * @property {string} AUTO_APPROVED - The auto approved solution.
 * @property {string} APPROVED - The approved solution.
 * @property {string} MODIFIED - The modified solution.
 * @property {string} NEEDS_REVIEW - The needs review solution.
 * @property {string} ASSIGN_TO_SUPERVISOR - The assign to supervisor solution.
 */
export enum TaxModerationSolution {
  AUTO_APPROVED = 'AUTO_APPROVED',
  APPROVED = 'APPROVED',
  MODIFIED = 'MODIFIED',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  ASSIGN_TO_SUPERVISOR = 'ASSIGN_TO_SUPERVISOR',
  RESOLVED = 'RESOLVED',
}

/**
 * Represents solution object for sales tax moderation card.
 *
 * @interface
 * @property {TaxModerationSolution} solution - Solution value.
 * @property {string} title - Solution title.
 * @property {colors} color - Associated color code.
 */
export interface ITaxModerationCardSolution {
  solution: TaxModerationSolution;
  title: string;
  color: colors;
}

/**
 * Represents status object for sales tax moderation card.
 *
 * @interface
 * @property {TaxModerationSolution} solution - Status value.
 * @property {string} title - Status title.
 * @property {colors} color - Associated color code.
 */
export interface ITaxModerationCardStatus {
  status: TaxModerationStatus;
  title: string;
  color: colors;
}

/**
 * Represents the state of sales tax moderation in the store.
 *
 * @interface
 * @property {ITaxModerationCard[]} taxModerationCards - An array of sales tax moderation cards.
 * @property {string[]} allIds - An array of unique identifiers for all sales tax moderation cards.
 * @property {ITaxModerationSearchItem[]} taxModerationSearch - An array of search results for sales tax moderation.
 * @property {string[]} taxModerationSearchAll - An array of unique identifiers for all sales tax moderation search items.
 * @property {IStatus} taxModerationSearchStatus - The status of pending search results.
 * @property {ITaxModerationCard | null} taxModerationCardDetails - Details of a specific sales tax moderation card.
 * @property {number} taxModerationCardsCount - Total quantity of sales tax moderation cards.
 * @property {IStatus} status - The status value for the state data.
 */
export interface ITaxModerationState {
  taxModerationCards: ITaxModerationCard[];
  allIds: string[];
  taxModerationSearch: ITaxModerationSearchItem[];
  taxModerationSearchAll: string[];
  taxModerationSearchStatus: IStatus;
  taxModerationCardDetails: ITaxModerationCard | null;
  taxModerationCardsCount: number;
  status: IStatus;
}

/**
 * Represents a search result item for sales tax moderation.
 *
 * @interface
 * @property {string} sameTitle - The title associated with the search item.
 * @property {string[]} ids - An array of unique identifiers for entities with the same title.
 */
export interface ITaxModerationSearchItem {
  sameTitle: string;
  ids: string[];
}
