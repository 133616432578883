import { useCallback, useMemo, useRef, useState } from 'react';
import { ArrowDropDown } from '@mui/icons-material';
import uniqueId from 'lodash/uniqueId';
import { selectProfile } from 'store/profileSlice';
import { useAppSelector } from 'store/hooks';
import Button from 'components/shared/Button/Button';
import Menu from 'components/shared/Menu/Menu';
import MenuItem from 'components/shared/Menu/MenuItem/MenuItem';
import './GridToolbar.scss';

export const GridToolbar = (props: any) => {
  const currentMember = useAppSelector(selectProfile);

  let firstAnchorEl = useRef<HTMLButtonElement | null>(null);
  const [multipleChangeStatusMenuAnchorEl, setMultipleChangeStatusMenuAnchorEl] = useState<null | HTMLElement>(null);
  const handleMultipleChangeStatusMenuOpen = useCallback((event: any) => {
    event.stopPropagation();
    firstAnchorEl.current = event.currentTarget;
    setMultipleChangeStatusMenuAnchorEl(event.currentTarget);
  }, []);
  const handleMultipleChangeStatusMenuClose = useCallback(() => {
    setMultipleChangeStatusMenuAnchorEl(null);
  }, []);

  const secondAnchorEl = useRef<HTMLButtonElement | null>(null);
  const [multipleChangeRoleMenuAnchorEl, setMultipleChangeRoleMenuAnchorEl] = useState<null | HTMLElement>(null);
  const handleMultipleChangeRoleMenuOpen = useCallback((event: any) => {
    event.stopPropagation();
    secondAnchorEl.current = event.currentTarget;
    setMultipleChangeRoleMenuAnchorEl(event.currentTarget);
  }, []);
  const handleMultipleChangeRoleMenuClose = useCallback(() => {
    setMultipleChangeRoleMenuAnchorEl(null);
  }, []);

  const archivedMembersSelected = useMemo(() => {
    return props.rows.some((row: any) => row.status === 'ARCHIVE');
  }, [props.rows]);

  const currentUserSelected = useMemo(() => {
    return props.rows.some((row: any) => row.email === currentMember.email);
  }, [props.rows, currentMember]);

  const inactiveUserSelected = useMemo(() => {
    return props.rows.some((row: any) => row.status === 'TIME_OUT' || row.status.includes('PENDING'));
  }, [props.rows]);

  return (
    <div className="membersTable--header">
      {props.selectionModel.length > 0 ? <div>{props.selectionModel.length} selected</div> : <div></div>}
      <div className="membersTableHeader--buttonContainer">
        <Button
          sx={{ width: 172 }}
          className="membersTableHeader--button"
          variant="outlined"
          ref={firstAnchorEl}
          disabled={currentUserSelected || props.selectionModel.length <= 0}
          onClick={(e: any) => handleMultipleChangeStatusMenuOpen(e)}
        >
          Change status <ArrowDropDown />
        </Button>
        {
          <Button
            variant="outlined"
            ref={secondAnchorEl}
            disabled={archivedMembersSelected || currentUserSelected || props.selectionModel.length <= 0}
            onClick={(e: any) => handleMultipleChangeRoleMenuOpen(e)}
          >
            Change role <ArrowDropDown />
          </Button>
        }
        <Menu
          id="multiple-change-status-menu"
          anchorEl={firstAnchorEl.current}
          open={Boolean(multipleChangeStatusMenuAnchorEl)}
          onClose={handleMultipleChangeStatusMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {props.possibleStatuses.map((el: any) => (
            <MenuItem
              key={uniqueId()}
              onClick={() => props.handleChangeStatusClick(el, true)}
              disabled={inactiveUserSelected && el.status !== 'ARCHIVE'}
              sx={{ width: 172 }}
            >
              {el.title}
            </MenuItem>
          ))}
        </Menu>
        <Menu
          id="multiple-change-role-menu"
          anchorEl={secondAnchorEl.current}
          open={Boolean(multipleChangeRoleMenuAnchorEl)}
          onClose={handleMultipleChangeRoleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {props.roles.map((el: any) => (
            <MenuItem key={uniqueId()} onClick={() => props.handleChangeRoleClick(el, props.rows[0])}>
              {el.name}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </div>
  );
};
