import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { GridColDef, GridEventListener } from '@mui/x-data-grid';
import { DataGridProProps } from '@mui/x-data-grid-pro/models/dataGridProProps';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, useGridApiRef } from '@mui/x-data-grid-pro';
import { GridRowId } from '@mui/x-data-grid/models/gridRows';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { enqueueSnackbar, VariantType } from 'notistack';
import { useErrorBoundary } from 'react-error-boundary';
import { defaultOperationsSortingModel } from './constants';
import { IFilterItem } from 'types/commonTypes';
import { IOperation } from 'store/slices/Payments/interface';
import { currencyFormatter } from 'utils/currencyFormatter';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  clearFilters,
  fetchFilters,
  removeFilter,
  reverseFilters,
  selectFilters,
  selectFiltersCurrentData,
  selectFiltersData,
} from 'store/filtersSlice';
import { fetchSortingColumns, setSortingData } from 'store/sortingSlice';
import {
  fetchOperations,
  selectOperations,
  selectOperationsStatus,
  selectTotalCount,
  setOperationsStatus,
} from 'store/slices/Payments/operationsSlice';
import FilterIcon from 'assets/img/FilterIcon';
import ActiveFilterList from 'components/ActiveFilterList/ActiveFilterList';
import Button from 'components/shared/Button/Button';
import CustomFooter from 'components/shared/DataGrid/CustomFooter/CustomFooter';
import CustomNoRowsOverlay from 'components/shared/DataGrid/CustomNoResultsOverlay/CustomNoRowsOverlay';
import Drawer from 'components/shared/Drawer/Drawer';
import FilterDrawer from 'components/FilterDrawer/FilterDrawer';
import Grid from 'components/shared/Grid/Grid';
import RenderCellWithCopy from 'components/shared/DataGrid/RenderCellWithCopy/RenderCellWithCopy';
import RenderDetailPanelToggle from 'components/shared/DataGrid/RenderDetailPanelToggle/RenderDetailPanelToggle';
import RenderOperationTypeCell from 'components/shared/DataGrid/RenderOperationTypeCell/RenderOperationTypeCell';
import Stack from 'components/shared/Stack/Stack';
import SortingChip from 'components/SortingChip/SortingChip';
import Typography from 'components/shared/Typography/Typography';
import { CloseFilterDrawerConfirmModal } from 'components/FilterDrawer/CloseFilterDrawerConfirmModal';
import {
  CustomDetailPanelContent,
  OperationsSearch,
  RenderCreationDateCell,
  RenderFeeCell,
  RenderHeaderWithSecondaryText,
  RenderStatusCell,
} from './components';
import './index.scss';

export const Operations: FC = () => {
  const dispatch = useAppDispatch();
  const { showBoundary } = useErrorBoundary();
  const apiRef = useGridApiRef();

  const data = useAppSelector(selectOperations);
  const totalCount = useAppSelector(selectTotalCount);
  const status = useAppSelector(selectOperationsStatus);
  const filters = useAppSelector(selectFilters);
  const filtersData = useAppSelector(selectFiltersData);
  const filtersCurrentData = useAppSelector(selectFiltersCurrentData);

  let isLoading = status === 'loading';

  const [filterDrawer, setFilterDrawer] = useState<boolean>(false);
  const [filterDrawerCloseConfirmModal, setFilterDrawerCloseConfirmModal] = useState<boolean>(false);
  const [fetchingError, setFetchingError] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<GridRowId[]>([]);

  const filtersLength = useMemo(() => {
    /** Subtraction due to search using "ID" filter. Just UI **/
    if (!isEmpty(filtersCurrentData?.id) || !isEmpty(filtersCurrentData?.operation_search)) {
      return Object.values(filtersData).filter((item: IFilterItem) => item !== null).length - 1;
    }

    return Object.values(filtersData).filter((item: IFilterItem) => item !== null).length;
  }, [filtersCurrentData, filtersData]);

  const onFilterSidebarClose = useCallback(
    (apply?: boolean) => {
      if (isEqual(filtersData, filtersCurrentData) || apply === true) {
        setFilterDrawerCloseConfirmModal(false);
        setFilterDrawer(false);
        dispatch(reverseFilters());
      } else {
        setFilterDrawerCloseConfirmModal(true);
      }
    },
    [filtersData, filtersCurrentData],
  );

  const cancelCloseFilterSidebar = useCallback((event?: any, reason?: any) => {
    if (reason && reason === 'backdropClick') return;
    setFilterDrawerCloseConfirmModal(false);
  }, []);

  const handleChangePage = (page: number) => {
    setPage(page);
    void fetchOperationsGrid(page, pageSize);
  };

  const handleChangePageSize = (pageSize: number) => {
    setPage(0);
    setPageSize(pageSize);
    void fetchOperationsGrid(0, pageSize);
  };

  const handleRowClick = useCallback<GridEventListener<'rowClick'>>(
    (params) => {
      apiRef.current.toggleDetailPanel(params.id);
    },
    [apiRef],
  );

  const handleRefreshData = () => {
    const currentState = apiRef.current.exportState();
    if (currentState.pagination) {
      dispatch(
        fetchOperations({
          page: currentState.pagination.page as number,
          pageSize: currentState.pagination.pageSize as number,
        }),
      ).then((result: any) => {
        if (result.meta && result.meta.requestStatus === 'rejected') {
          enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType });
          setFetchingError(true);
        } else if (result.meta && result.meta.requestStatus === 'fulfilled') {
          setFetchingError(false);
        }
      });
    }
  };

  const getDetailPanelHeight = useCallback(() => 'auto', []);

  const getDetailPanelContent = useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(
    ({ row }) => <CustomDetailPanelContent row={row} onDataRefresh={handleRefreshData} />,
    [],
  );

  const handleDetailPanelExpandedRowIdsChange = useCallback((newIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds);
  }, []);

  const fetchOperationsInitial = useCallback(
    async (page: number, pageSize: number) => {
      try {
        await dispatch(fetchOperations({ page, pageSize })).unwrap();
      } catch (error) {
        showBoundary(error);
      }
    },
    [showBoundary],
  );

  const fetchOperationsGrid = useCallback(
    async (page: number, pageSize: number) => {
      dispatch(fetchOperations({ page, pageSize })).then((result: any) => {
        if (result.meta && result.meta.requestStatus === 'rejected') {
          enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType });
          setFetchingError(true);
        } else if (result.meta && result.meta.requestStatus === 'fulfilled') {
          setFetchingError(false);
        }
      });
    },
    [dispatch],
  );

  const repeatFetchingRequest = useCallback(async () => {
    await fetchOperationsGrid(0, 25);
  }, []);

  const resetFilters = useCallback(async () => {
    dispatch(clearFilters());
    await dispatch(fetchOperations({ page, pageSize }));
  }, []);

  const resetIdFilter = useCallback(async () => {
    dispatch(removeFilter({ columnId: 'id' }));
  }, []);

  const initialFetchData = useCallback(async () => {
    dispatch(setOperationsStatus('loading'));
    await dispatch(fetchFilters('operations'));
    await dispatch(fetchSortingColumns('operations'));

    if (filters) {
      dispatch(setSortingData([defaultOperationsSortingModel]));
      await fetchOperationsInitial(page, pageSize);
    }
  }, [dispatch, fetchOperationsInitial, page, pageSize, filters]);

  useEffect(() => {
    dispatch(clearFilters());
    initialFetchData();
  }, []);

  const columns: GridColDef[] = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => <RenderDetailPanelToggle id={params.id} value={params.value} />,
    },
    {
      field: 'userFriendlyOperationId',
      headerName: 'User operation ID',
      flex: 0.5,
      minWidth: 170,
      sortable: false,
      renderCell: (params) => <RenderCellWithCopy isWrappedText props={params} />,
    },
    {
      field: 'id',
      headerName: 'Operation ID',
      flex: 0.5,
      minWidth: 160,
      sortable: false,
      renderCell: (params) => <RenderCellWithCopy isWrappedText props={params} />,
    },
    {
      field: 'operationType',
      headerName: 'Operation type',
      flex: 0.5,
      minWidth: 160,
      sortable: false,
      renderCell: (params) => <RenderOperationTypeCell props={params} />,
    },
    {
      field: 'createDate',
      headerName: 'Creation date',
      flex: 0.3,
      minWidth: 130,
      sortable: false,
      renderCell: (params) => <RenderCreationDateCell props={params} />,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.3,
      minWidth: 110,
      sortable: false,
      renderCell: (params) => <RenderStatusCell props={params} />,
    },
    {
      field: 'price',
      headerName: 'Amount',
      flex: 0.4,
      minWidth: 120,
      sortable: false,
      type: 'number',
      renderCell: ({ value }) => <span>{Number(value) ? currencyFormatter(value, 'en-US', 'USD', true) : '—'}</span>,
    },
    {
      field: 'platformFeeClient',
      headerName: 'Fee',
      flex: 0.4,
      minWidth: 120,
      sortable: false,
      type: 'number',
      renderCell: (params) => <RenderFeeCell props={params} />,
    },
    {
      field: 'total',
      headerName: 'Total',
      renderHeader: (params) => (
        <RenderHeaderWithSecondaryText props={params} tooltipTitle="For payer" secondaryText=" (P)" />
      ),
      flex: 0.4,
      minWidth: 120,
      sortable: false,
      type: 'number',
      renderCell: ({ value }) => <span>{Number(value) ? currencyFormatter(value, 'en-US', 'USD', true) : '—'}</span>,
    },
    {
      field: 'totalAfterAllFees',
      headerName: 'Received ',
      renderHeader: (params) => (
        <RenderHeaderWithSecondaryText props={params} tooltipTitle="For recipient" secondaryText="(R)" />
      ),
      flex: 0.4,
      minWidth: 120,
      sortable: false,
      type: 'number',
      renderCell: ({ value }) => <span>{Number(value) ? currencyFormatter(value, 'en-US', 'USD', true) : '—'}</span>,
    },
    // TODO Additional (after release) Net parameter
    // {
    //   field: 'netParam',
    //   headerName: 'Net',
    //   flex: 1,
    //   sortable: false,
    //   type: 'number',
    //   valueFormatter: ({ value }) => {
    //     return Number(value) ? currencyFormatter(value, 'en-US', 'USD', true) : '—';
    //   },
    // },
  ];

  const rows: IOperation[] | [] = useMemo(() => {
    if (data) {
      return data;
    } else return [];
  }, [data]);

  const isEmptyData = !rows.length;
  const isDisabledFilterButton = !((!isEmptyData && !fetchingError) || !!filtersLength);

  const handleSortingModelApply = async () => {
    await fetchOperationsGrid(page, pageSize);
  };

  return (
    <div className="operations-table-container">
      <div className="operations-table-container__actions">
        <Stack gap={2} direction="row" alignItems="center">
          <Button
            disabled={isDisabledFilterButton}
            size="small"
            startIcon={<FilterIcon />}
            variant="outlined"
            onClick={() => setFilterDrawer(true)}
          >
            Filter {!!filtersLength && `(${filtersLength})`}
          </Button>
          <SortingChip
            disabled={isEmptyData}
            defaultSortingModel={defaultOperationsSortingModel}
            onSortingModelApply={() => handleSortingModelApply()}
            preferableOrder="DESC"
          />
        </Stack>
        <OperationsSearch refreshData={() => repeatFetchingRequest()} clearFilter={() => resetIdFilter()} />
      </div>
      {filtersLength !== 0 && <ActiveFilterList refreshData={handleRefreshData} />}
      <Grid
        apiRef={apiRef}
        className="operations-table"
        columns={columns}
        rows={rows}
        onRowClick={handleRowClick}
        getRowId={(row) => row.id}
        loading={isLoading}
        rowHeight={68}
        page={page}
        pageSize={pageSize}
        pagination
        paginationMode="server"
        filterMode="server"
        sortingMode="server"
        rowCount={totalCount}
        rowsPerPageOptions={[10, 25, 50]} //TODO back не может переварить 100 записей (ломается на 86)
        hideFooterSelectedRowCount
        disableColumnMenu
        disableColumnReorder
        disableColumnResize
        disableSelectionOnClick
        getRowClassName={(params) => `${params.row.id === detailPanelExpandedRowIds[0] ? 'Mui-selected' : ''}`}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
        onPageChange={(newPage) => handleChangePage(newPage)}
        onPageSizeChange={(newPageSize) => handleChangePageSize(newPageSize)}
        components={{
          NoRowsOverlay: () =>
            CustomNoRowsOverlay({
              isFiltersApplied: !!filtersLength,
              fetchingError: fetchingError,
              clearFilters: () => resetFilters(),
              refreshData: () => repeatFetchingRequest(),
              renderNoDataComponent: () => (
                <Typography color="text.secondary" variant="body2">
                  No operation has been performed
                </Typography>
              ),
            }),
          Footer: CustomFooter,
        }}
        columnTypes={{
          [GRID_DETAIL_PANEL_TOGGLE_COL_DEF.type as string]: GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        }}
        hideFooter={isEmptyData && !fetchingError}
      />
      <CloseFilterDrawerConfirmModal
        open={filterDrawerCloseConfirmModal}
        onClose={() => onFilterSidebarClose(true)}
        closeModal={() => cancelCloseFilterSidebar()}
      />
      <Drawer anchor="right" open={filterDrawer} onClose={() => onFilterSidebarClose()}>
        <FilterDrawer
          type="operations"
          isHidePresets
          onClose={onFilterSidebarClose}
          onApply={() => {
            onFilterSidebarClose(true);
            setPage(0);
            void fetchOperationsGrid(0, pageSize);
          }}
        />
      </Drawer>
    </div>
  );
};
