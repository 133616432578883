import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from "axios";
import { IMedia, IStatus, LocalStorageKeys } from 'types/commonTypes';
import { RootState } from "store/store";
import { ErrorMessages, ProfileMessages } from "store/types/ErrorMessages";
import { fetchSignIn } from "store/authSlice";

export interface Member {
  id: string;
  firstName: string;
  lastName: string;
  role: {
    id: string;
    name: string;
    defaultPresetId: string;
  };
  email: string;
  status: string;
  registered: Date | null;
  latestActivity: Date | null;
}

export interface ProfileState {
  member: Member;
  avatar: IMedia;
  avatarPreview: IMedia;
  triesLeft: number;
  status: IStatus;
  errorStatusText: ErrorMessages | '';
}

const initialMember: Member = {
  id: '',
  firstName: '',
  lastName: '',
  role: {
    id: '',
    name: '',
    defaultPresetId: '',
  },
  email: '',
  status: '',
  registered: null,
  latestActivity: null,
};

const initialAvatar: IMedia = {
  id: '',
  originalLink: '',
  height: 0,
  width: 0,
  type: '',
  linkBlurredImage: '',
}

const initialState: ProfileState = {
  member: localStorage.getItem(LocalStorageKeys.MEMBER) ? JSON.parse(localStorage.getItem(LocalStorageKeys.MEMBER) || '') : initialMember,
  avatar: initialAvatar,
  avatarPreview: initialAvatar,
  triesLeft: 3,
  status: 'idle',
  errorStatusText: '',
};

export const fetchProfile = createAsyncThunk(
  'profile/fetchProfile',
  async () => {
    const response = await axios.get((process.env.REACT_APP_MEMBERS_ENDPOINT as string) + 'members');
    return response.data;
  }
);

export const fetchAvatar = createAsyncThunk(
  'profile/fetchAvatar',
  async () => {
    const response = await axios.get((process.env.REACT_APP_MEMBERS_ENDPOINT as string) + 'members/avatar');
    return response.data;
  }
);

export const fetchUpdateAvatar = createAsyncThunk(
  'profile/fetchUpdateAvatar',
  async ({ avatar, avatarPreview }: { avatar?: Blob, avatarPreview: Blob }) => {
    const body = new FormData();
    body.append('avatar', avatar ?? '');
    body.append('avatarPreview', avatarPreview ?? '');
    const response = await axios.post((
      process.env.REACT_APP_MEMBERS_ENDPOINT as string) + 'members/avatar', body);
    return response.data;
  }
);

export const fetchResetPassword = createAsyncThunk(
  'profile/resetPassword',
  async ({ password }: { password: string }) => {
    const response = await axios.post((process.env.REACT_APP_MEMBERS_ENDPOINT as string) + 'members/password-reset', {
      password: password,
    }).catch((error: unknown) => {
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        throw Error(error.response.data.title);
      } else {
        throw Error('An unexpected error occurred');
      }
    });
    return response.data;
  }
);

export const fetchResetPasswordTriesLeft = createAsyncThunk(
  'profile/resetPasswordTriesLeft',
  async () => {
    const response = await axios.get((process.env.REACT_APP_MEMBERS_ENDPOINT as string) + 'members/password-reset-tries-left');
    return response.data;
  }
);

export const fetchGlobalSignOut = createAsyncThunk(
  'profile/globalSignOut',
  async () => {
    return await axios.post((process.env.REACT_APP_MEMBERS_ENDPOINT as string) + 'members/global-sign-out');
  }
);

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProfile.fulfilled, (state, { payload }: PayloadAction<Member>) => {
        state.status = 'idle';
        state.member = payload;
        localStorage.setItem(LocalStorageKeys.MEMBER, JSON.stringify(payload));
      })
      .addCase(fetchProfile.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchAvatar.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAvatar.fulfilled,
        (state, { payload }: PayloadAction<{ avatar?: IMedia, avatarPreview?: IMedia }>
        ) => {
        state.status = 'idle';
        state.avatar = payload.avatar ?? initialAvatar;
        state.avatarPreview = payload.avatarPreview ?? initialAvatar;
      })
      .addCase(fetchAvatar.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchUpdateAvatar.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUpdateAvatar.fulfilled,
        (state, { payload }: PayloadAction<{ avatar?: IMedia, avatarPreview?: IMedia }>
        ) => {
        state.status = 'idle';
        state.avatar = payload.avatar ?? initialAvatar;
        state.avatarPreview = payload.avatarPreview ?? initialAvatar;
      })
      .addCase(fetchUpdateAvatar.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchResetPassword.pending, (state) => {
        state.status = 'loading';
        state.errorStatusText = '';
      })
      .addCase(fetchResetPassword.fulfilled, (state, action) => {
        state.status = 'idle';
        state.errorStatusText = '';
      })
      .addCase(fetchResetPassword.rejected, (state, { error }) => {
        state.status = 'failed';
        state.errorStatusText = (error.message ?? '') as ErrorMessages;
        if (error.message === ProfileMessages.UNVALIDATED_PASSWORD) {
          state.triesLeft -= 1;
        }
      })
      .addCase(fetchResetPasswordTriesLeft.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchResetPasswordTriesLeft.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.triesLeft = payload.triesLeft;
      })
      .addCase(fetchResetPasswordTriesLeft.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchGlobalSignOut.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchGlobalSignOut.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(fetchGlobalSignOut.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchSignIn.fulfilled, (state) => {
        state.errorStatusText = '';
        state.triesLeft = 3;
      })
  },
});

export default profileSlice.reducer;
export const selectProfileStatus = (state: RootState): IStatus => state.profile.status;
export const selectProfileStatusText = (state: RootState): string => state.profile.errorStatusText;
export const selectProfile = (state: RootState): Member => state.profile.member;
export const selectTriesLeft = (state: RootState): number => state.profile.triesLeft;
export const selectAvatar = (state: RootState): IMedia => state.profile.avatar;
export const selectAvatarPreview = (state: RootState): IMedia => state.profile.avatarPreview;
