import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { enqueueSnackbar, VariantType } from 'notistack';
import dayjs from 'dayjs';
import startCase from 'lodash/startCase';
import colors from 'theme/constants/colors';
import SubjectType from '../types/SubjectType';
import { EntityType, IDropdownItem } from 'types/commonTypes';
import ModerationTabs from 'components/Moderation/types/ModerationTabs';
import { IMediaProps } from 'components/Moderation/MediaPreviewDrawer/MediaPreviewDrawer';
import { IStatusModal } from 'containers/Moderation/Moderation';
import CardType from 'components/Moderation/types/CardType';
import getRenderCardProps from 'components/Moderation/utils/getRenderCardProps';
import { selectAuthMemberId } from 'store/authSlice';
import { fetchChangeStatus } from 'store/moderationSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import InfoIcon from 'assets/img/InfoIcon';
import Button from 'components/shared/Button/Button';
import Chip from 'components/shared/Chip/Chip';
import ModerationCardContent from 'components/Moderation/ModerationCard/ModerationCardContent/ModerationCardContent';
import Stack from 'components/shared/Stack/Stack';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import Typography from 'components/shared/Typography/Typography';
import ChangeTicketStatusModal from '../ChangeTicketStatusModal/ChangeTicketStatusModal';
import './index.scss';

interface IModerationCardProps {
  subjectTypeConfirmed: SubjectType;
  subjectTypeDeclined: SubjectType;
  showPreview: (newValue: IMediaProps) => void;
  onChangeStatus: () => void;
  showDetails: (entityInfo: any) => void;
  showUsersProfile: () => void;
  renderCardProps: any;
  ticketsGroup: string;
  tabValue: ModerationTabs;
  allMetrics: {
    content: string[];
    profile: string[];
  };
  dangerousMetrics: {
    hive: [];
    content: [];
    profile: [];
  };
}

const ModerationCard = ({
  subjectTypeConfirmed,
  subjectTypeDeclined,
  showPreview,
  renderCardProps,
  showDetails,
  onChangeStatus,
  tabValue,
  showUsersProfile,
  allMetrics,
  dangerousMetrics,
  ticketsGroup,
}: IModerationCardProps) => {
  const dispatch = useAppDispatch();
  const moderatorId = useAppSelector(selectAuthMemberId);

  const cardProps = useMemo(() => getRenderCardProps(renderCardProps, tabValue), [renderCardProps, tabValue]);
  const createdDate = useMemo(() => dayjs(cardProps?.createdDate).format('MM/DD/YYYY, h:mm A') || null, [cardProps]);
  const cardType: CardType = cardProps.cardType;
  const isManualMode = cardProps.manualMode;

  const complaintsCriteria = useMemo(() => {
    if ((allMetrics && cardType === CardType.CONTENT) || isManualMode) {
      return allMetrics.content.map((item) => ({ value: item, titles: [item] }) as IDropdownItem);
    }
    if (allMetrics && cardType === CardType.PROFILE) {
      return allMetrics.profile.map((item) => ({ value: item, titles: [item] }) as IDropdownItem);
    }
    return [];
  }, [cardType, allMetrics, isManualMode]);

  const mostDangerousMetrics = useMemo(() => {
    if (dangerousMetrics && cardType === CardType.HIVE) {
      return dangerousMetrics.hive;
    }
    if (dangerousMetrics && cardType === CardType.CONTENT) {
      return dangerousMetrics.content;
    }
    if (dangerousMetrics && cardType === CardType.PROFILE) {
      return dangerousMetrics.profile;
    }
    return [];
  }, [cardType, dangerousMetrics]);

  const initialStatusViolation = useMemo(() => {
    return tabValue === ModerationTabs.CONFIRMED_VIOLATIONS
      ? true
      : tabValue === ModerationTabs.DECLINED_REQUESTS
      ? false
      : undefined;
  }, [tabValue]);

  const initialViolations = useMemo(() => {
    return isManualMode
      ? cardProps.confirmedManualModeViolations || []
      : cardProps.confirmedComplaintsViolations || cardProps.confirmedHiveViolations || [];
  }, [cardProps, isManualMode]);

  const shouldDisplayViolations = (isManualMode || cardType !== CardType.HIVE) && !!initialViolations.length;

  const [isShowChangeStatusModal, setIsShowChangeStatusModal] = useState<IStatusModal>({
    openModal: false,
    status: false,
    moderator: '',
    violations: [],
    onChangeStatus: () => {},
  });

  const [statusViolations, setStatusViolations] = useState<boolean | undefined>(initialStatusViolation);
  const [selectedViolations, setSelectedViolations] = useState<IDropdownItem[]>([]);
  const [isFullHeightCard, setIsFullHeightCard] = useState(false);

  const moderatorName = useMemo(() => {
    if (cardProps?.hiveModeratorId?.toUpperCase() === 'HIVE') return 'HIVE';
    if (cardProps.hiveModerator) return `${cardProps.hiveModerator.firstName} ${cardProps.hiveModerator.lastName}`;
    if (cardProps.complaintsModerator)
      return `${cardProps.complaintsModerator.firstName} ${cardProps.complaintsModerator.lastName}`;

    return null;
  }, [cardProps.complaintsModerator, cardProps.hiveModerator, cardProps.hiveModeratorId]);

  const handleChangeStatus = useCallback(async (data: any) => {
    try {
      const result = await dispatch(fetchChangeStatus(data));
      if (result.meta.requestStatus === 'fulfilled') {
        enqueueSnackbar(
          `${getCardType(data.entityType)} ${getCardId(data.entityType)} ${
            !data.status ? 'ticket has been declined' : 'violation has been confirmed'
          } `,
          { variant: 'success' as VariantType },
        );
        onChangeStatus();
      }
      if (result.meta.requestStatus === 'rejected') {
        enqueueSnackbar('Decision has not been applied, try again later', { variant: 'error' as VariantType });
      }
    } catch (e) {
      enqueueSnackbar('Decision has not been applied, try again later', { variant: 'error' as VariantType });
    }
  }, []);

  const handleConfirmViolation = () => {
    const selectViolationsValues = selectedViolations.map(({ value }) => value);
    if (tabValue === ModerationTabs.CONFIRMED_VIOLATIONS || tabValue === ModerationTabs.DECLINED_REQUESTS) {
      setIsShowChangeStatusModal({
        openModal: true,
        status: true,
        moderator: moderatorName,
        violations: selectViolationsValues,
        onChangeStatus: () =>
          handleChangeStatus({
            entityId: cardProps.entityId,
            entityType: cardProps.entityType,
            cardType,
            status: true,
            moderatorId,
            historical: true,
            manualMode: isManualMode,
            confirmedViolations:
              cardType === CardType.HIVE ? (isManualMode ? selectViolationsValues : null) : selectViolationsValues,
          }).then(() => {
            setSelectedViolations([]);
          }),
      });

      return;
    }
    setSelectedViolations([]);
    void handleChangeStatus({
      entityId: cardProps.entityId,
      entityType: cardProps.entityType,
      cardType,
      status: true,
      moderatorId,
      historical: false,
      manualMode: isManualMode,
      confirmedViolations:
        cardType === CardType.HIVE ? (isManualMode ? selectViolationsValues : null) : selectViolationsValues,
    });
  };

  const handleNoViolation = () => {
    if (tabValue === ModerationTabs.CONFIRMED_VIOLATIONS || tabValue === ModerationTabs.DECLINED_REQUESTS) {
      setIsShowChangeStatusModal({
        openModal: true,
        status: false,
        moderator: moderatorName,
        violations: [],
        onChangeStatus: () =>
          handleChangeStatus({
            entityId: cardProps.entityId,
            entityType: cardProps.entityType,
            cardType,
            status: false,
            moderatorId,
            historical: true,
          }).then(() => {
            setSelectedViolations([]);
          }),
      });

      return;
    }
    setSelectedViolations([]);
    void handleChangeStatus({
      entityId: cardProps.entityId,
      entityType: cardProps.entityType,
      cardType,
      status: false,
      moderatorId,
      historical: false,
    });
  };

  const isAssignedToModerator = useMemo(
    () => !!cardProps.complaintsModerator || !!cardProps.hiveModerator,
    [cardProps],
  );

  const getCardType = (entityType: string) => {
    if (entityType?.toUpperCase() === EntityType.AVATAR) {
      return 'Profile pic of user';
    }
    if (entityType?.toUpperCase() === EntityType.ABOUT_ME) {
      return 'What im about of user';
    } else return startCase(entityType?.toLowerCase());
  };

  const getCardId = (entityType: string, removeHash = false) => {
    const id =
      entityType?.toUpperCase() === EntityType.ABOUT_ME || entityType?.toUpperCase() === EntityType.AVATAR
        ? cardProps?.entityOwnerInfo?.entityOwnerId
        : cardProps?.entityId;

    return removeHash ? `${id}` : `#${id}`;
  };

  useEffect(() => setSelectedViolations([]), [subjectTypeConfirmed, subjectTypeDeclined]);
  useEffect(() => setIsFullHeightCard(false), [ticketsGroup]);

  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  const isTextEllipsisActive = (element: any) => {
    return element && element.offsetWidth < element.scrollWidth;
  };

  useEffect(() => {
    const element = textRef.current;
    if (element) {
      setIsEllipsisActive(isTextEllipsisActive(element));
    }
  }, [cardProps?.entityType]);

  return (
    <div className="card" style={{ maxHeight: isFullHeightCard ? '100%' : '400px', minHeight: '400px' }}>
      <div className="card-header">
        <div className="card-header-text">
          <Tooltip
            followCursor
            placement="top-end"
            style={{ display: 'inline-flex' }}
            title={getCardId(cardProps?.entityType, true)}
            disableHoverListener={!isEllipsisActive}
          >
            <Typography variant="body1" className="card-header-name">
              <div className="card-header-name" ref={textRef}>
                {getCardType(cardProps?.entityType)} {getCardId(cardProps?.entityType)}
              </div>
            </Typography>
          </Tooltip>
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography component="span" color="text.secondary" variant="caption">
              Ticket from {createdDate}
            </Typography>
            {isManualMode && (
              <Tooltip placement="top" followCursor title="Manual mode ticket. It wasn't checked by Hive">
                <InfoIcon sx={{ fontSize: 20, color: colors.primary }} />
              </Tooltip>
            )}
          </Stack>
        </div>
        {ticketsGroup === 'My' ||
        tabValue === ModerationTabs.CONFIRMED_VIOLATIONS ||
        tabValue === ModerationTabs.DECLINED_REQUESTS ? (
          <div className="card-buttons">
            {statusViolations !== undefined && (
              <div className="card-buttons-moderator">
                <Typography color="text.primary" variant="subtitle2" component="span" sx={{ width: 'max-content' }}>
                  {moderatorName}&nbsp;
                </Typography>
                <Typography color="text.primary" variant="body2" component="span">
                  {statusViolations ? 'confirmed violation' : "didn't confirm a violation"}
                </Typography>
                {statusViolations && shouldDisplayViolations && (
                  <>
                    &nbsp;for&nbsp;
                    <Typography color="text.primary" variant="subtitle2" component="span">
                      {initialViolations[0]}
                    </Typography>
                    {initialViolations.length > 1 && (
                      <Tooltip
                        arrow
                        placement="top"
                        title={initialViolations.map((violation: string) => (
                          <div key={violation}>{violation}</div>
                        ))}
                      >
                        <Typography color="text.primary" variant="subtitle2" component="span">
                          and {initialViolations.length - 1} others categories
                        </Typography>
                      </Tooltip>
                    )}
                  </>
                )}
              </div>
            )}
            {(isManualMode || cardType !== CardType.HIVE) && !statusViolations && (
              <Autocomplete
                size="small"
                clearIcon={null}
                multiple
                disableCloseOnSelect
                value={selectedViolations}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={({ inputProps, ...rest }) => (
                  <TextField
                    {...rest}
                    inputProps={{ ...inputProps, readOnly: true }}
                    label="Violation category"
                    sx={{ input: { cursor: 'pointer', flex: 'none', width: 0 } }}
                  />
                )}
                getOptionLabel={({ titles }) => titles[0]}
                options={complaintsCriteria}
                onChange={(_, value) => setSelectedViolations(value)}
                renderTags={(tagValue, getTagProps) => {
                  const numTags = tagValue.length;

                  return tagValue.slice(0, 1).map((option, index) => {
                    return (
                      <Fragment key={index}>
                        <Chip
                          size="small"
                          variant="filled"
                          label={option.titles[0]}
                          {...getTagProps({ index })}
                          sx={{ maxWidth: 'calc(100% - 24px)' }}
                        />
                        {numTags > 1 && ` +${numTags - 1}`}
                      </Fragment>
                    );
                  });
                }}
                sx={{
                  minWidth: 245,
                  minHeight: 40,
                  maxHeight: 40,
                  ml: 2,
                  '.MuiAutocomplete-tag': { maxWidth: 'calc(100% - 24px)' },
                  '.MuiAutocomplete-inputRoot': { flexWrap: 'nowrap' },
                }}
              />
            )}
            {!statusViolations ? (
              <Button
                sx={{ minHeight: 40, maxHeight: 40, ml: 1, w: 1 }}
                variant={
                  statusViolations === undefined || (cardType !== CardType.HIVE && !selectedViolations.length)
                    ? 'contained'
                    : 'outlined'
                }
                color="error"
                disabled={
                  !isManualMode ? cardType !== CardType.HIVE && !selectedViolations.length : !selectedViolations.length
                }
                onClick={handleConfirmViolation}
              >
                Confirm violation
              </Button>
            ) : null}
            {statusViolations === undefined || statusViolations ? (
              <Button
                sx={{ minHeight: 40, maxHeight: 40, ml: 2 }}
                variant={statusViolations === undefined ? 'contained' : 'outlined'}
                color="success"
                onClick={handleNoViolation}
              >
                No violation
              </Button>
            ) : null}
          </div>
        ) : (
          <></>
        )}
        {ticketsGroup === 'All' &&
        tabValue !== ModerationTabs.CONFIRMED_VIOLATIONS &&
        tabValue !== ModerationTabs.DECLINED_REQUESTS &&
        moderatorName ? (
          <div className="card-assign-info">
            {isAssignedToModerator ? (
              <Typography variant="body2" color="text.secondary">
                Assigned to{' '}
                <Typography variant="subtitle2" color="text.primary" component="span">
                  {moderatorName}
                </Typography>
              </Typography>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
      <ModerationCardContent
        mostDangerousMetrics={mostDangerousMetrics}
        violationsFrom={cardProps.violationsFrom}
        amountViolations={cardProps.amountViolations}
        entityInfo={cardProps.entityInfo}
        profileInfo={cardProps.profileInfo}
        moderationResults={cardProps.moderationJobResults}
        complaints={cardProps.complaints}
        entityOwnerInfo={cardProps.entityOwnerInfo}
        showPreview={showPreview}
        showDetails={showDetails}
        showUsersProfile={showUsersProfile}
        isManualMode={cardProps.manualMode}
        cardType={cardProps.cardType}
        isFullHeightCard={isFullHeightCard}
        tabValue={tabValue}
        setIsFullHeightCard={setIsFullHeightCard}
      />
      <ChangeTicketStatusModal
        open={isShowChangeStatusModal.openModal}
        onClose={() =>
          setIsShowChangeStatusModal({
            openModal: false,
            status: false,
            moderator: '',
            violations: [],
          })
        }
        status={isShowChangeStatusModal.status}
        moderator={isShowChangeStatusModal.moderator}
        violations={isShowChangeStatusModal.violations}
        onChangeStatus={isShowChangeStatusModal.onChangeStatus}
      />
    </div>
  );
};

export default ModerationCard;
