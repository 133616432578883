import { ChangeEvent, FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, useGridApiRef } from '@mui/x-data-grid-pro';
import { GridColDef, GridEventListener, GridRowClassNameParams } from '@mui/x-data-grid';
import { GridRowId } from '@mui/x-data-grid/models/gridRows';
import { DataGridProProps } from '@mui/x-data-grid-pro/models/dataGridProProps';
import { motion } from 'framer-motion';
import { enqueueSnackbar, VariantType } from 'notistack';
import { createSearchParams, Outlet, useNavigate, useParams } from 'react-router-dom';
import { useDocumentTitle } from '@uidotdev/usehooks';
import { useErrorBoundary } from 'react-error-boundary';
import startCase from 'lodash/startCase';
import { ErrorBoundaryErrors } from 'containers/ErrorPage/ErrorPage';
import {
  CLOSED_FILTER,
  consumptionRequestClosedSortingModel,
  ConsumptionRequestListType,
  consumptionRequestOpenSortingModel,
  OPEN_FILTER,
} from './constants';
import { IConsumptionRequestCase } from 'store/slices/ConsumptionRequests/interface';
import { ConsumptionRequestsRoutes } from 'routes/enum';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  clearConsumptionRequestCases,
  fetchConsumptionRequestCases,
  selectConsumptionRequestCases,
  selectConsumptionRequestCasesStatus,
  selectTotalCount,
  setConsumptionRequestStatus,
} from 'store/slices/ConsumptionRequests/consumptionRequestsSlice';
import { clearSortingData, fetchSortingColumns, setSortingData } from 'store/sortingSlice';
import { clearFilters, setCustomFilterValue } from 'store/filtersSlice';
import CustomFooter from 'components/shared/DataGrid/CustomFooter/CustomFooter';
import CustomNoRowsOverlay from 'components/shared/DataGrid/CustomNoResultsOverlay/CustomNoRowsOverlay';
import FormControl from 'components/shared/FormControl/FormControl';
import FormControlLabel from 'components/shared/FormControlLabel/FormControlLabel';
import Grid from 'components/shared/Grid/Grid';
import Radio from 'components/shared/Radio/Radio';
import RadioGroup from 'components/shared/RadioGroup/RadioGroup';
import RenderCellWithCopy from 'components/shared/DataGrid/RenderCellWithCopy/RenderCellWithCopy';
import RenderDetailPanelToggle from 'components/shared/DataGrid/RenderDetailPanelToggle/RenderDetailPanelToggle';
import RenderExpandableCell from 'components/shared/DataGrid/RenderExpandableCell/RenderExpandableCell';
import RenderOperationTypeCell from 'components/shared/DataGrid/RenderOperationTypeCell/RenderOperationTypeCell';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';
import { CustomDetailPanelContent, RenderActionCell, RenderTimeLeftCell, RequestsSearch } from './components';
import './index.scss';

const ConsumptionRequests: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const apiRef = useGridApiRef();
  const { showBoundary } = useErrorBoundary();
  const { id: requestId } = useParams();

  const consumptionRequestCases = useAppSelector(selectConsumptionRequestCases);
  const totalCount = useAppSelector(selectTotalCount);
  const status = useAppSelector(selectConsumptionRequestCasesStatus);

  let isLoading = status === 'loading';

  const [listType, setListType] = useState(ConsumptionRequestListType.OPEN);
  useDocumentTitle(`Requests refunds & disputes - ${startCase(listType.toLowerCase())}`);

  const [fetchingError, setFetchingError] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<GridRowId[]>([]);
  const isClosedListType = listType === ConsumptionRequestListType.CLOSED;

  const handleChangePage = (page: number) => {
    setPage(page);
    void fetchConsumptionRequestGrid(page, pageSize);
  };

  const handleChangePageSize = (pageSize: number) => {
    setPage(0);
    setPageSize(pageSize);
    void fetchConsumptionRequestGrid(0, pageSize);
  };

  const handleChangeListType = async (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    dispatch(clearFilters());
    dispatch(clearSortingData());
    dispatch(setConsumptionRequestStatus('loading'));
    dispatch(clearConsumptionRequestCases());

    if (requestId) {
      navigate(ConsumptionRequestsRoutes.CONSUMPTION_REQUESTS);
    }
    setListType(event.target.value as ConsumptionRequestListType);
    if (event.target.value?.toUpperCase() === ConsumptionRequestListType.CLOSED) {
      dispatch(setCustomFilterValue(CLOSED_FILTER));
      dispatch(setSortingData([consumptionRequestClosedSortingModel]));

      await fetchConsumptionRequestGrid(0, pageSize);
    } else {
      dispatch(setCustomFilterValue(OPEN_FILTER));
      dispatch(setSortingData([consumptionRequestOpenSortingModel]));

      await fetchConsumptionRequestGrid(0, pageSize);
    }
  };

  const handleResolve = (event: MouseEvent<HTMLButtonElement>, data: IConsumptionRequestCase) => {
    const options = {
      pathname: `details/${data.consumptionRequestId}`,
      search: `?${createSearchParams({
        operationId: data.operationId,
        platform: data.consumptionRequestType,
        creationDate: data.createdAt,
      } as any)}`,
    };
    navigate(options);
    event.stopPropagation();
  };

  const handleRowClick = useCallback<GridEventListener<'rowClick'>>(
    (params) => {
      if (isClosedListType) {
        return;
      }
      apiRef.current.toggleDetailPanel(params.id);
    },
    [apiRef],
  );

  const getDetailPanelHeight = useCallback(() => 'auto', []);

  const getDetailPanelContent = useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(
    ({ row }) => <CustomDetailPanelContent row={row} />,
    [],
  );

  const handleDetailPanelExpandedRowIdsChange = useCallback((newIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds);
  }, []);

  const getRowClassName = (params: GridRowClassNameParams) => {
    if (isClosedListType && params.row.consumptionRequestId === detailPanelExpandedRowIds[0]) {
      return 'Mui-selected';
    } else if (!isClosedListType) {
      return 'Mui-empty';
    } else {
      return '';
    }
  };

  const columns: GridColDef[] | [] = useMemo(() => {
    if (listType === ConsumptionRequestListType.OPEN) {
      return [
        {
          field: 'consumptionRequestId',
          headerName: 'Case ID',
          flex: 0.4,
          minWidth: 140,
          sortable: false,
          renderCell: (params) => <RenderCellWithCopy props={params} />,
        },
        {
          field: 'operationType',
          headerName: 'Operation type',
          flex: 0.5,
          minWidth: 140,
          sortable: false,
          renderCell: (params) => <RenderOperationTypeCell props={params} />,
        },
        {
          field: 'operationId',
          headerName: 'Operation ID',
          flex: 0.6,
          minWidth: 160,
          sortable: false,
          renderCell: (params) => <RenderCellWithCopy props={params} />,
        },
        {
          field: 'profileId',
          headerName: 'Profile ID',
          flex: 0.6,
          minWidth: 160,
          sortable: false,
          renderCell: (params) => <RenderCellWithCopy props={params} />,
        },
        {
          field: 'createdAt',
          headerName: 'Time left',
          flex: 0.3,
          minWidth: 120,
          sortable: false,
          renderCell: (params) => <RenderTimeLeftCell props={params} />,
        },
        {
          field: 'hidden',
          headerName: '',
          flex: 0.3,
          minWidth: 100,
          sortable: false,
          renderCell: (params) => <RenderActionCell props={params} onClick={(e, data) => handleResolve(e, data)} />,
        },
      ];
    } else
      return [
        {
          ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
          renderCell: (params) => <RenderDetailPanelToggle id={params.id} value={params.value} />,
        },
        {
          field: 'consumptionRequestId',
          headerName: 'Case ID',
          flex: 0.4,
          minWidth: 140,
          sortable: false,
          renderCell: (params) => <RenderCellWithCopy props={params} />,
        },
        {
          field: 'operationType',
          headerName: 'Operation type',
          flex: 0.4,
          minWidth: 140,
          sortable: false,
          renderCell: (params) => <RenderOperationTypeCell props={params} />,
        },
        {
          field: 'operationId',
          headerName: 'Operation ID',
          flex: 0.4,
          minWidth: 140,
          sortable: false,
          renderCell: (params) => <RenderCellWithCopy props={params} />,
        },

        {
          field: 'profileId',
          headerName: 'Profile ID',
          flex: 0.4,
          minWidth: 140,
          sortable: false,
          renderCell: (params) => <RenderCellWithCopy props={params} />,
        },
        {
          field: 'reason',
          headerName: 'Reason',
          flex: 0.6,
          minWidth: 160,
          sortable: false,
          valueFormatter: (params) => {
            return params.value ? params.value : '—';
          },
        },
        {
          field: 'comment',
          headerName: 'Comment (for YZZY only)',
          flex: 0.6,
          minWidth: 200,
          sortable: false,
          renderCell: (params) => <RenderExpandableCell {...params} />,
        },
        {
          field: 'memberInfo',
          headerName: 'Resolved by',
          flex: 0.6,
          minWidth: 160,
          sortable: false,
          valueFormatter: (params) => {
            const fullName = `${params?.value?.firstName} ${params?.value?.lastName}`;
            return params.value ? `${fullName.length > 45 ? `${fullName.substring(0, 45)}...` : fullName}` : '—';
          },
        },
      ];
  }, [listType]);

  const rows: IConsumptionRequestCase[] | [] = useMemo(() => {
    if (consumptionRequestCases) {
      return consumptionRequestCases;
    } else return [];
  }, [consumptionRequestCases]);

  const isEmptyData = !rows.length;

  const fetchConsumptionRequestInitial = useCallback(async (page: number, pageSize: number) => {
    try {
      await dispatch(fetchConsumptionRequestCases({ page, pageSize })).unwrap();
    } catch (error) {
      showBoundary(error);
    }
  }, []);

  const fetchConsumptionRequestGrid = useCallback(
    async (page: number, pageSize: number) => {
      dispatch(fetchConsumptionRequestCases({ page, pageSize })).then((result: any) => {
        if (result.meta && result.meta.requestStatus === 'rejected') {
          enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType });
          setFetchingError(true);
        } else if (result.meta && result.meta.requestStatus === 'fulfilled') {
          setFetchingError(false);
        }
      });
    },
    [dispatch],
  );

  const repeatFetchingRequest = useCallback(async () => {
    fetchConsumptionRequestGrid(page, pageSize);
  }, [dispatch, page, pageSize]);

  const initialFetchData = useCallback(async () => {
    dispatch(fetchSortingColumns('consumption-requests'));
    dispatch(setCustomFilterValue(OPEN_FILTER));
    dispatch(setSortingData([consumptionRequestOpenSortingModel]));
    await fetchConsumptionRequestInitial(page, pageSize);
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    dispatch(clearFilters());
    dispatch(clearSortingData());
    initialFetchData();
  }, []);

  return (
    <div className="consumption-requests-container">
      <Typography variant="h6">Requests refunds & disputes</Typography>
      <div className="consumption-requests-content">
        <div className="consumption-requests-content__actions">
          <Stack pl={2} direction="row" gap={1} alignItems="center">
            <FormControl>
              <RadioGroup row value={listType} onChange={handleChangeListType}>
                <FormControlLabel value={ConsumptionRequestListType.OPEN} control={<Radio />} label="Open" />
                <FormControlLabel value={ConsumptionRequestListType.CLOSED} control={<Radio />} label="Closed" />
              </RadioGroup>
            </FormControl>
          </Stack>

          {!isEmptyData && <RequestsSearch />}
        </div>
        <Outlet context={{ page, pageSize }} />
        {!requestId && (
          <motion.div
            className="consumption-requests-table"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Grid
              apiRef={apiRef}
              columns={columns}
              rows={rows}
              getRowId={(row) => row.consumptionRequestId as GridRowId}
              loading={isLoading}
              onRowClick={isClosedListType ? handleRowClick : () => {}}
              page={page}
              pageSize={pageSize}
              pagination
              paginationMode="server"
              rowCount={totalCount}
              rowsPerPageOptions={[10, 25, 50, 100]}
              getRowHeight={() => 'auto'}
              hideFooterSelectedRowCount
              disableColumnMenu
              disableColumnReorder
              disableColumnResize
              disableSelectionOnClick
              columnVisibilityModel={{
                __detail_panel_toggle__: isClosedListType,
              }}
              getRowClassName={getRowClassName}
              getDetailPanelContent={getDetailPanelContent}
              getDetailPanelHeight={getDetailPanelHeight}
              detailPanelExpandedRowIds={detailPanelExpandedRowIds}
              onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
              onPageChange={(newPage) => handleChangePage(newPage)}
              onPageSizeChange={(newPageSize) => handleChangePageSize(newPageSize)}
              components={{
                NoRowsOverlay: () =>
                  CustomNoRowsOverlay({
                    isFiltersApplied: false,
                    fetchingError: fetchingError,
                    refreshData: () => repeatFetchingRequest(),
                    renderNoDataComponent: () => (
                      <Typography color="text.secondary" variant="body2">
                        No {listType === ConsumptionRequestListType.OPEN ? 'open' : 'closed'} requests
                      </Typography>
                    ),
                  }),
                Footer: CustomFooter,
              }}
              columnTypes={{
                [GRID_DETAIL_PANEL_TOGGLE_COL_DEF.type as string]: GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
              }}
              hideFooter={isEmptyData && !fetchingError}
            />
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default ConsumptionRequests;
