import { SortableElement, SortableElementProps } from "react-sortable-hoc";
import { ComponentClass, ReactNode } from "react";

interface ISortableItem extends SortableElementProps {
  children: ReactNode
}

const SortableItem: ComponentClass<ISortableItem, any> = SortableElement(
  ({ children }: { children: ReactNode }) => <>{children}</>);

export default SortableItem;
