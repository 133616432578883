import { Input as InputBase } from "@mui/material";
import { InputProps } from "@mui/material/Input/Input";

interface IInputProps extends InputProps {
  primary?: boolean;
  label?: string;
}

const Input = (props: IInputProps) => {
  return (
    <InputBase {...props} />
  )
};

export default Input;