import { AuraLevel } from 'types/commonTypes';

/**
 * Returns the color scheme associated with a given aura level.
 *
 * @param {string | undefined} levelAura - The aura level as a string/
 * @returns {Object} An object containing the following properties:
 *                   - background: A CSS linear-gradient string for the background.
 *                   - gradientBackgroundColors: An array of gradient color strings.
 *                   - chipBackground: A CSS linear-gradient string for the chip background.
 *                   - color: A string representing the text color.
 *                   - border: A string representing the border style.
 */
export const getAuraColor = (levelAura: string | undefined) => {
  switch (levelAura?.toUpperCase()) {
    case AuraLevel.BABY:
      return {
        background: 'linear-gradient(90deg, #16A085 0%, #9AD26E 100%)',
        gradientBackgroundColors: ['#16A085', '#9AD26E'],
        chipBackground: 'linear-gradient(90deg, rgba(22, 160, 133, 0.1) 0%, rgba(154, 210, 110, 0.1) 100%)',
        color: 'rgba(68, 135, 98, 1)',
        border: '2px solid',
      };
    case AuraLevel.MAKING:
      return {
        background: 'linear-gradient(90deg, #6F86D6 0%, #48C6EF 100%)',
        gradientBackgroundColors: ['#6F86D6', '#48C6EF'],
        chipBackground: 'linear-gradient(90deg, rgba(111, 134, 214, 0.1) 0%, rgba(72, 198, 239, 0.1) 100%)',
        color: 'rgba(52, 87, 180, 1)',
        border: '2px solid',
      };
    case AuraLevel.HUSTLING:
      return {
        background: 'linear-gradient(90deg, #F83600 0%, #F9D423 100%)',
        gradientBackgroundColors: ['#F83600', '#F9D423'],

        chipBackground: 'linear-gradient(90deg, rgba(248, 54, 0, 0.1) 0%, rgba(249, 212, 35, 0.1) 100%)',
        color: 'rgba(218, 97, 43, 1)',
        border: '2px solid',
      };
    case AuraLevel.BALLING:
      return {
        background: 'linear-gradient(90deg, #A445B2 0%, #D41872 51.56%, #FF0066 100%)',
        gradientBackgroundColors: ['#A445B2', '#D41872', '#FF0066'],
        chipBackground:
          'linear-gradient(90deg, rgba(164, 69, 178, 0.1) 0%, rgba(212, 24, 114, 0.1) 51.56%, rgba(255, 0, 102, 0.1) 100%)',
        color: 'rgba(180, 41, 41, 1)',
        border: '2px solid',
      };
    default:
      return {
        background: 'transparent',
        gradientBackgroundColors: ['#BFC6E2', '#BFC6E2'],
        chipBackground: 'transparent',
        color: '#23252F',
        border: '2px solid',
      };
  }
};
