import { createSvgIcon } from '@mui/material';

const CheckCircleFilledIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.15009 12C1.15009 6.02168 6.02174 1.15002 12.0001 1.15002C17.9784 1.15002 22.8501 6.02168 22.8501 12C22.8501 17.9784 17.9784 22.85 12.0001 22.85C6.02174 22.85 1.15009 17.9784 1.15009 12ZM18.0519 7.84973C18.3882 8.17727 18.3953 8.71541 18.0678 9.0517L11.1689 16.135C11.0068 16.3015 10.7836 16.3943 10.5513 16.3919C10.319 16.3896 10.0978 16.2922 9.93914 16.1226L6.92138 12.8957C6.60073 12.5528 6.61874 12.015 6.96161 11.6943C7.30448 11.3737 7.84237 11.3917 8.16302 11.7345L10.5724 14.3109L16.85 7.86558C17.1775 7.52929 17.7156 7.52219 18.0519 7.84973Z"
    />
  </svg>,
  'CheckCircleFilled',
);

export default CheckCircleFilledIcon;
