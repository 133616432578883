import axios, { isAxiosError } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IDictionariesState, IPaymentMethod, IProvider, ITaxCode, ITransactionType } from './interface';
import { IDropdownItem, IGroupedDropdownItem, IRefundReason, IStatus } from 'types/commonTypes';
import { RootState } from 'store/store';

const initialState: IDictionariesState = {
  providers: [],
  paymentMethods: [],
  transactionTypes: [],
  entityTypes: [],
  formats: [],
  onlineFormatTypes: [],
  eventCategories: [],
  passions: [],
  salesTaxCodes: [],
  salesTaxCodesList: [],
  certainties: [],
  refundReasons: [],
  status: 'idle',
};

export const fetchProviders = createAsyncThunk('dictionaries/providers', async () => {
  try {
    const response = await axios.get(
      (process.env.REACT_APP_PAYMENTS_ENDPOINT as string) + 'payment-adapter/dictionary/providers',
    );

    return response.data;
  } catch (error: unknown) {
    return isAxiosError(error);
  }
});

export const fetchTransactionTypes = createAsyncThunk('dictionaries/transactionTypes', async () => {
  try {
    const response = await axios.get(
      (process.env.REACT_APP_PAYMENTS_ENDPOINT as string) + 'payment-adapter/dictionary/transaction-types',
    );

    return response.data;
  } catch (error: unknown) {
    return isAxiosError(error);
  }
});

export const fetchPaymentsMethods = createAsyncThunk('dictionaries/paymentsMethods', async () => {
  try {
    const response = await axios.get(
      (process.env.REACT_APP_PAYMENTS_ENDPOINT as string) + 'payment-adapter/dictionary/methods',
    );

    return response.data;
  } catch (error: unknown) {
    return isAxiosError(error);
  }
});

export const fetchEntityTypes = createAsyncThunk('dictionaries/entityTypes', async () => {
  try {
    const response = await axios.get(
      (process.env.REACT_APP_TAXES_ENDPOINT as string) + 'sales/tax/screen/filters/dropdown?columnId=entity_type',
    );

    return response.data.values;
  } catch (error: unknown) {
    return isAxiosError(error);
  }
});

export const fetchFormats = createAsyncThunk('dictionaries/formats', async () => {
  try {
    const response = await axios.get(
      (process.env.REACT_APP_TAXES_ENDPOINT as string) + 'sales/tax/screen/filters/dropdown?columnId=format',
    );

    return response.data.values;
  } catch (error: unknown) {
    return isAxiosError(error);
  }
});

export const fetchOnlineFormatTypes = createAsyncThunk('dictionaries/onlineFormatTypes', async () => {
  try {
    const response = await axios.get(
      (process.env.REACT_APP_TAXES_ENDPOINT as string) +
        'sales/tax/screen/filters/dropdown?columnId=event_online_format_type',
    );

    return response.data.values;
  } catch (error: unknown) {
    return isAxiosError(error);
  }
});

export const fetchEventCategories = createAsyncThunk('dictionaries/eventCategories', async () => {
  try {
    const response = await axios.get(
      (process.env.REACT_APP_TAXES_ENDPOINT as string) +
        'sales/tax/screen/filters/dropdown?columnId=sales_tax_moderation_event_category_id',
    );

    return response.data.values;
  } catch (error: unknown) {
    return isAxiosError(error);
  }
});

export const fetchPassions = createAsyncThunk('dictionaries/passions', async () => {
  try {
    const response = await axios.get(
      (process.env.REACT_APP_TAXES_ENDPOINT as string) +
        'sales/tax/screen/filters/dropdown?columnId=sales_tax_moderation_passions',
    );

    return response.data.values;
  } catch (error: unknown) {
    return isAxiosError(error);
  }
});

export const fetchCertainties = createAsyncThunk('dictionaries/certainties', async () => {
  try {
    const response = await axios.get(
      (process.env.REACT_APP_TAXES_ENDPOINT as string) + 'sales/tax/screen/filters/dropdown?columnId=certainty',
    );

    return response.data.values;
  } catch (error: unknown) {
    return isAxiosError(error);
  }
});

export const fetchSalesTaxCodes = createAsyncThunk('dictionaries/salesTaxCodes', async () => {
  try {
    const response = await axios.get(
      (process.env.REACT_APP_TAXES_ENDPOINT as string) +
        'sales/tax/screen/filters/dropdown?columnId=sales_tax_code_entity',
    );

    return response.data.values;
  } catch (error: unknown) {
    return isAxiosError(error);
  }
});

export const fetchSalesTaxCodesList = createAsyncThunk('dictionaries/salesTaxCodesList', async () => {
  const response = await axios.get((process.env.REACT_APP_TAXES_ENDPOINT as string) + 'sales/tax/code');
  return response.data;
});

export const fetchRefundReasons = createAsyncThunk('dictionaries/refundReasons', async () => {
  try {
    const response = await axios.get(
      (process.env.REACT_APP_OPERATIONS_ENDPOINT as string) + `operations/refund-reasons`,
    );

    return response.data;
  } catch (error: unknown) {
    return isAxiosError(error);
  }
});

export const dictionariesSlice = createSlice({
  name: 'dictionaries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProviders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProviders.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.providers = payload;
      })
      .addCase(fetchProviders.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchPaymentsMethods.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPaymentsMethods.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.paymentMethods = payload;
      })
      .addCase(fetchPaymentsMethods.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchTransactionTypes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTransactionTypes.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.transactionTypes = payload;
      })
      .addCase(fetchTransactionTypes.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchEntityTypes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEntityTypes.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.entityTypes = payload;
      })
      .addCase(fetchEntityTypes.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchFormats.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFormats.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.formats = payload;
      })
      .addCase(fetchFormats.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchOnlineFormatTypes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOnlineFormatTypes.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.onlineFormatTypes = payload;
      })
      .addCase(fetchOnlineFormatTypes.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchEventCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEventCategories.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.eventCategories = payload;
      })
      .addCase(fetchEventCategories.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchPassions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPassions.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.passions = payload;
      })
      .addCase(fetchPassions.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchCertainties.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCertainties.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.certainties = payload;
      })
      .addCase(fetchCertainties.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchSalesTaxCodes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSalesTaxCodes.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.salesTaxCodes = payload;
      })
      .addCase(fetchSalesTaxCodes.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchSalesTaxCodesList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSalesTaxCodesList.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.salesTaxCodesList = payload;
      })
      .addCase(fetchSalesTaxCodesList.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchRefundReasons.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRefundReasons.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.refundReasons = payload;
      })
      .addCase(fetchRefundReasons.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectProviders = (state: RootState): IProvider[] => state.dictionaries.providers;
export const selectPaymentMethods = (state: RootState): IPaymentMethod[] => state.dictionaries.paymentMethods;
export const selectTransactionTypes = (state: RootState): ITransactionType[] => state.dictionaries.transactionTypes;
export const selectEntityTypes = (state: RootState): IDropdownItem[] => state.dictionaries.entityTypes;
export const selectFormats = (state: RootState): IDropdownItem[] => state.dictionaries.formats;
export const selectOnlineFormatTypes = (state: RootState): IDropdownItem[] => state.dictionaries.onlineFormatTypes;
export const selectEventCategories = (state: RootState): IDropdownItem[] => state.dictionaries.eventCategories;
export const selectPassions = (state: RootState): IGroupedDropdownItem[] => state.dictionaries.passions;
export const selectSalesTaxCodes = (state: RootState): IGroupedDropdownItem[] => state.dictionaries.salesTaxCodes;
export const selectSalesTaxCodesList = (
  state: RootState,
): Pick<ITaxCode, 'id' | 'code' | 'category' | 'description'>[] => state.dictionaries.salesTaxCodesList;
export const selectCertainties = (state: RootState): IDropdownItem[] => state.dictionaries.certainties;
export const selectRefundReasons = (state: RootState): IRefundReason[] => state.dictionaries.refundReasons;
export const selectDictionariesStatus = (state: RootState): IStatus => state.dictionaries.status;

export default dictionariesSlice.reducer;
