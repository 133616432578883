import Alert from 'components/shared/Alert/Alert';
import Button from 'components/shared/Button/Button';
import Dialog from 'components/shared/Dialog/Dialog';
import DialogActions from 'components/shared/Dialog/DialogActions/DialogActions';
import DialogContent from 'components/shared/Dialog/DialogContent/DialogContent';
import DialogTitle from 'components/shared/Dialog/DialogTitle/DialogTitle';

interface ILogoutWarningModalProps {
  open: boolean;
  onClose: () => void;
  onLogout: () => void;
}

export const LogoutWarningModal = ({ open, onClose, onLogout }: ILogoutWarningModalProps) => {
  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle variant="h6">Unable to open the page due to active session in this browser</DialogTitle>
      <DialogContent>
        <Alert severity="warning">You need to change your browser or terminate current session</Alert>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="error" onClick={onLogout}>
          Log out
        </Button>
        <Button onClick={onClose} variant="contained" color="primary">
          Stay logged
        </Button>
      </DialogActions>
    </Dialog>
  );
};
