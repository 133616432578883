import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import filter from 'lodash/filter';
import some from 'lodash/some';
import { enqueueSnackbar, VariantType } from 'notistack';
import { STRIPE_MAX_LIMIT } from 'yzzy-constants';
import getValidationSchema from './utils/getValidationSchema';
import { IPaymentFee, IPaymentMethod, IProvider, ITransactionType } from 'store/slices/Settings/interface';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  fetchAddPaymentFee,
  fetchFeesConditions,
  selectAddingFeeStatus,
  selectFeesConditions,
} from 'store/slices/Settings/paymentFeesSlice';
import {
  fetchPaymentsMethods,
  fetchProviders,
  fetchTransactionTypes,
  selectPaymentMethods,
  selectProviders,
  selectTransactionTypes,
} from 'store/slices/Settings/dictionariesSlice';
import Alert from 'components/shared/Alert/Alert';
import Autocomplete, { renderOptions } from 'components/shared/Autocomplete/Autocomplete';
import Button from 'components/shared/Button/Button';
import Dialog from 'components/shared/Dialog/Dialog';
import DialogActions from 'components/shared/Dialog/DialogActions/DialogActions';
import DialogContent from 'components/shared/Dialog/DialogContent/DialogContent';
import DialogTitle from 'components/shared/Dialog/DialogTitle/DialogTitle';
import FormControl from 'components/shared/FormControl/FormControl';
import FormHelperText from 'components/shared/FormHelperText/FormHelperText';
import InputAdornment from 'components/shared/InputAdornment/InputAdornment';
import LoadingButton from 'components/shared/LoadingButton/LoadingButton';
import NumericFormatCustom from 'components/shared/CurrencyFormatCustom/NumericFormatCustom';
import SimpleGrid from 'components/shared/SimpleGrid/SimpleGrid';
import TextField from 'components/shared/TextField/TextField';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import Typography from 'components/shared/Typography/Typography';
import { ConfirmCancelModal } from './ConfirmCancelModal';

interface IAddFeeModalProps {
  open: boolean;
  onClose: (isAddedNewFee?: boolean) => void;
}

export const AddFeeModal = ({ open, onClose }: IAddFeeModalProps) => {
  const dispatch = useAppDispatch();
  const schema = useMemo(() => getValidationSchema(), []);

  const providers = useAppSelector(selectProviders);
  const paymentMethods = useAppSelector(selectPaymentMethods);
  const transactionTypes = useAppSelector(selectTransactionTypes);
  const feeConditions = useAppSelector(selectFeesConditions);
  const addingFeeStatus = useAppSelector(selectAddingFeeStatus);

  const [selectedProvider, setSelectedProvider] = useState<IProvider | null>(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<IPaymentMethod | null>(null);
  const [selectedTransactionType, setSelectedTransactionType] = useState<ITransactionType | null>(null);
  const [isSelectedFeeFixedField, setIsSelectedFeeFixedField] = useState(false);
  const [isSelectedFeePercentageField, setIsSelectedFeePercentageField] = useState(false);

  const filteredTransactionTypes = useMemo(() => {
    return filter(transactionTypes, (type) => {
      return some(feeConditions, (condition) => {
        return (
          condition.providerId === selectedProvider?.providerId && condition.transactionType === type.transactionTypeId
        );
      });
    });
  }, [transactionTypes, feeConditions, selectedProvider]);

  const filteredPaymentMethods = useMemo(() => {
    return filter(paymentMethods, (method) => {
      return some(feeConditions, (condition) => {
        return (
          condition.providerId === selectedProvider?.providerId &&
          condition.transactionType === selectedTransactionType?.transactionTypeId &&
          condition.paymentMethodId === method.paymentMethodId
        );
      });
    });
  }, [paymentMethods, feeConditions, selectedProvider, selectedTransactionType]);

  const {
    watch,
    resetField,
    reset,
    control,
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      providerId: '',
      paymentMethodId: '',
      transactionType: '',
      paymentFeeFixed: '',
      paymentFeePercentage: '',
    },
  });

  const [isOpenConfirmCancelModal, setIsOpenConfirmCancelModal] = useState(false);

  const handleClose = (isAddedNewFee?: boolean) => {
    reset();
    setSelectedProvider(null);
    setSelectedPaymentMethod(null);
    setSelectedTransactionType(null);
    onClose(isAddedNewFee);
  };

  const onSubmit = async (
    data: Pick<
      IPaymentFee,
      'providerId' | 'paymentMethodId' | 'transactionType' | 'paymentFeeFixed' | 'paymentFeePercentage'
    >,
  ) => {
    void trigger();

    try {
      const result = await dispatch(fetchAddPaymentFee(data));

      if (result.meta.requestStatus === 'fulfilled') {
        handleClose(true);
        enqueueSnackbar('Payment fee has been added', {
          variant: 'success' as VariantType,
        });
      } else if (result.meta.requestStatus === 'rejected') {
        enqueueSnackbar('An error occurred while adding the fee, try again again later', { variant: 'error' as VariantType });
      }
    } catch (e) {
      enqueueSnackbar('An error occurred while adding the fee, try again again later', { variant: 'error' as VariantType });
    }
  };

  useEffect(() => {
    dispatch(fetchFeesConditions());
    dispatch(fetchProviders());
    dispatch(fetchPaymentsMethods());
    dispatch(fetchTransactionTypes());
  }, [dispatch]);

  return (
    <>
      <Dialog disableScrollLock fullWidth maxWidth="sm" open={open}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle variant="h6">Add new fee</DialogTitle>
          <DialogContent>
            <Alert sx={{ mb: 2 }} severity="info">
              All fields are required. Changing one of the parameters resets values in other fields.{' '}
            </Alert>
            <SimpleGrid container flexDirection="column" minHeight="100%" alignContent="stretch" spacing={2}>
              <SimpleGrid item xs={12}>
                <Controller
                  control={control}
                  name="providerId"
                  render={() => (
                    <FormControl fullWidth>
                      <Autocomplete
                        size="small"
                        label="Provider"
                        clearIcon={null}
                        value={selectedProvider}
                        isOptionEqualToValue={(option, value) => option.providerId === value.providerId}
                        renderOption={(params, option, { inputValue }) =>
                          renderOptions(params, option.displayName, inputValue)
                        }
                        getOptionLabel={({ displayName }) => displayName}
                        options={providers || []}
                        onChange={(_, value) => {
                          setValue('providerId', value ? value.providerId : null, { shouldDirty: true });
                          setSelectedProvider(value);
                          void trigger('providerId');
                          resetField('transactionType');
                          setSelectedTransactionType(null);
                          resetField('paymentMethodId');
                          setSelectedPaymentMethod(null);
                        }}
                        inputprops={{
                          error: !!errors.providerId,
                        }}
                        onBlur={() => trigger('providerId')}
                      />
                      <FormHelperText error={!!errors.providerId}>
                        {errors.providerId?.message as string}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </SimpleGrid>
              <SimpleGrid item xs={12}>
                <Controller
                  control={control}
                  name="transactionType"
                  render={() => (
                    <FormControl fullWidth>
                      <Tooltip
                        followCursor
                        placement="top-end"
                        title={!selectedProvider && 'You need to choose a Provider first'}
                      >
                        <Autocomplete
                          size="small"
                          label="Transaction type"
                          clearIcon={null}
                          value={selectedTransactionType}
                          disabled={!selectedProvider}
                          isOptionEqualToValue={(option, value) => option.transactionTypeId === value.transactionTypeId}
                          renderOption={(params, option, { inputValue }) =>
                            renderOptions(params, option.displayName, inputValue)
                          }
                          getOptionLabel={({ displayName }) => displayName}
                          options={filteredTransactionTypes || []}
                          onChange={(_, value) => {
                            setValue('transactionType', value ? value.transactionTypeId : null, { shouldDirty: true });
                            setSelectedTransactionType(value);
                            void trigger('transactionType');
                            resetField('paymentMethodId');
                            setSelectedPaymentMethod(null);
                          }}
                          inputprops={{
                            error: !!errors.transactionType,
                          }}
                          onBlur={() => trigger('transactionType')}
                        />
                      </Tooltip>
                      <FormHelperText error={!!errors.transactionType}>
                        {errors.transactionType?.message as string}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </SimpleGrid>
              <SimpleGrid item xs={12}>
                <Controller
                  control={control}
                  name="paymentMethodId"
                  render={() => (
                    <FormControl fullWidth>
                      <Tooltip
                        followCursor
                        placement="top-end"
                        title={!selectedTransactionType && 'You need to choose Transaction type first'}
                      >
                        <Autocomplete
                          size="small"
                          label="Payment method"
                          clearIcon={null}
                          value={selectedPaymentMethod}
                          disabled={!selectedTransactionType}
                          isOptionEqualToValue={(option, value) => option.paymentMethodId === value.paymentMethodId}
                          renderOption={(params, option, { inputValue }) =>
                            renderOptions(params, option.displayName, inputValue)
                          }
                          getOptionLabel={({ displayName }) => displayName}
                          options={filteredPaymentMethods || []}
                          onChange={(_, value) => {
                            setValue('paymentMethodId', value ? value.paymentMethodId : null, { shouldDirty: true });
                            setSelectedPaymentMethod(value);
                            void trigger('paymentMethodId');
                          }}
                          inputprops={{
                            error: !!errors.paymentMethodId,
                          }}
                          onBlur={() => trigger('paymentMethodId')}
                        />
                      </Tooltip>
                      <FormHelperText error={!!errors.paymentMethodId}>
                        {errors.paymentMethodId?.message as string}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </SimpleGrid>
              <SimpleGrid item xs={12}>
                <TextField
                  {...register('paymentFeePercentage')}
                  size="small"
                  name="paymentFeePercentage"
                  label="Payment fee percentage"
                  fullWidth
                  placeholder={isSelectedFeePercentageField ? '0' : undefined}
                  error={!!errors.paymentFeePercentage}
                  helperText={errors.paymentFeePercentage && (errors.paymentFeePercentage?.message as string)}
                  onFocus={() => setIsSelectedFeePercentageField(true)}
                  onBlur={() => {
                    setIsSelectedFeePercentageField(false);
                    trigger('paymentFeePercentage');
                  }}
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                    inputProps: {
                      decimalScale: 1,
                      max: 100,
                    },
                    startAdornment:
                      watch('paymentFeePercentage') || isSelectedFeePercentageField ? (
                        <InputAdornment position="start">
                          <Typography variant="body1" color="action.disabled">
                            %
                          </Typography>
                        </InputAdornment>
                      ) : undefined,
                  }}
                />
              </SimpleGrid>
              <SimpleGrid item xs={12}>
                <TextField
                  {...register('paymentFeeFixed')}
                  size="small"
                  name="paymentFeeFixed"
                  label="Payment fee fixed"
                  fullWidth
                  placeholder={isSelectedFeeFixedField ? '0.00' : undefined}
                  error={!!errors.paymentFeeFixed}
                  helperText={errors.paymentFeeFixed && (errors.paymentFeeFixed?.message as string)}
                  onFocus={() => setIsSelectedFeeFixedField(true)}
                  onBlur={() => {
                    setIsSelectedFeeFixedField(false);
                    trigger('paymentFeeFixed');
                  }}
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                    inputProps: {
                      decimalScale: 2,
                      fixedDecimalScale: true,
                      max: STRIPE_MAX_LIMIT,
                    },
                    startAdornment:
                      watch('paymentFeeFixed') || isSelectedFeeFixedField ? (
                        <InputAdornment color="text.primary" position="start">
                          <Typography variant="body1" color="action.disabled">
                            $
                          </Typography>
                        </InputAdornment>
                      ) : undefined,
                  }}
                />
              </SimpleGrid>
            </SimpleGrid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                isDirty ? setIsOpenConfirmCancelModal(true) : handleClose();
              }}
              variant="text"
              color="primary"
            >
              Cancel
            </Button>
            <LoadingButton
              loading={addingFeeStatus === 'loading'}
              // disabled={!isValid || !isDirty}
              variant="contained"
              color="primary"
              type="submit"
            >
              Add fee
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
      <ConfirmCancelModal
        closeModal={() => setIsOpenConfirmCancelModal(false)}
        open={isOpenConfirmCancelModal}
        onClose={handleClose}
      />
    </>
  );
};
