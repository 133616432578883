import { GridRenderCellParams } from '@mui/x-data-grid';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import Typography from 'components/shared/Typography/Typography';

/**
 * Props for the RenderCellWithTooltip component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 * @property {string} title - Tooltip title.
 */
interface IRenderCellWithTooltipProps {
  props: GridRenderCellParams;
  title: string;
}

/**
 * Renders a cell with Tooltip in a data grid.
 */
const RenderCellWithTooltip = ({ props, title }: IRenderCellWithTooltipProps) => {
  const { row, value } = props;

  if (row) {
    return (
      <Tooltip style={{ display: 'inline-grid' }} placement="top-end" followCursor title={title}>
        <Typography
          component="span"
          variant="body2"
          color="text.primary"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'wrap',
          }}
        >
          {value}
        </Typography>
      </Tooltip>
    );
  } else {
    return <></>;
  }
};

export default RenderCellWithTooltip;
