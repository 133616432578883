import { GridRenderCellParams } from '@mui/x-data-grid';
import { OperationTypes } from 'store/slices/Payments/interface';
import { currencyFormatter } from 'utils/currencyFormatter';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';
import Tooltip from 'components/shared/Tooltip/Tooltip';

/**
 * Props for the RenderFeeCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 */
interface IRenderFeeCellProps {
  props: GridRenderCellParams;
}

/**
 * Renders a "Fee" cell in a data grid.
 */
export const RenderFeeCell = ({ props }: IRenderFeeCellProps) => {
  const { row } = props;

  if (!row.id) {
    return <></>;
  }

  const renderFee = (amount: number, type: string, tooltipTitle: string) => {
    return amount ? (
      <Typography component="span" variant="body2">
        <Tooltip placement="top" followCursor title={tooltipTitle}>
          <>
            {currencyFormatter(amount, 'en-US', 'USD', true)}
            <Typography component="span" display="inline" variant="body2" color="text.secondary">
              {type ? ` (${type})` : ''}
            </Typography>
          </>
        </Tooltip>
      </Typography>
    ) : (
      <div>—</div>
    );
  };

  const isPayoutOperation = row.operationType === OperationTypes.PAY_OUT;

  return (
    <Stack alignContent="end" textAlign="right">
      {!isPayoutOperation && renderFee(Number(row.serviceFeeClient), 'P', 'For payer')}
      {!isPayoutOperation && renderFee(Number(row.serviceFeeCreator), 'R', 'For recipient')}
      {isPayoutOperation && renderFee(Number(row.paymentFeeAmount), '', 'Payment provider commission')}
    </Stack>
  );
};
