import React, { useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from 'components/shared/Button/Button';
import { Rules } from 'components/Moderation/types/ModerationTabs';
import Select, { ISelectOptions } from 'components/shared/Select/Select';
import Divider from 'components/shared/Divider/Divider';
import TextField from 'components/shared/TextField/TextField';
import getValidationSchema from 'components/Moderation/RulesModalContent/utils/getValidationSchema';
import { fetchAddRule, fetchDeleteRule, fetchUpdateRule, selectRulesStatus } from 'store/rulesSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import Modal from 'components/shared/Modal/Modal';
import RulesModalCancel from 'components/Moderation/RulesModalContent/RulesModalCancel';
import FormHelperText from 'components/shared/FormHelperText/FormHelperText';
import { enqueueSnackbar, VariantType } from 'notistack';
import Alert from 'components/shared/Alert/Alert';

import './index.scss';

interface IProps {
  onClose(): void;

  contentType: Rules;
  item: any;
}

const RulesModalContent = ({ onClose, contentType, item }: IProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const dispatch = useAppDispatch();

  const status = useAppSelector(selectRulesStatus);
  const schema = useMemo(() => getValidationSchema(contentType, item.action), [item.action]);
  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues:
      item.action === 'delete'
        ? { id: item.rule.id, metric: item.rule.metric }
        : {
            id: item.rule.id,
            metric: item.rule.metric,
            decision: item.rule.decision,
            type: item.rule.type || 'VISUAL',
            restrictions: item.rule.restrictions,
            reviewMinScore: item.rule.reviewMinScore,
            decisionMinScore: item.rule.decisionMinScore,
            countForPeriod: item.rule.countForPeriod,
            periodUnit: item.rule.periodUnit,
            periodCount: item.rule.periodCount,
            profileDecision:
              item.rule.profileImplicationDto?.profileDecision ||
              item.rule.profileDecision ||
              contentType === Rules.PROFILE ||
              item.action === 'add'
                ? item.rule.profileImplicationDto?.profileDecision || item.rule.profileDecision
                : 'NO_IMPLICATIONS',
            term: item.rule.term,
            decisionPeriodUnit: item.rule.decisionPeriodUnit,
            decisionPeriodCount: item.rule.decisionPeriodCount,
          },
  });

  const onSubmit = async (data: any) => {
    void trigger();

    if (item.action === 'delete') {
      try {
        const result = await dispatch(fetchDeleteRule({ type: contentType, data }));
        result.meta.requestStatus === 'fulfilled' && onClose();
        result.meta.requestStatus === 'fulfilled' &&
          enqueueSnackbar(`${data.metric} rule deleted`, { variant: 'success' as VariantType });
        result.meta.requestStatus === 'rejected' &&
          enqueueSnackbar('Deleting error, try again later', { variant: 'error' as VariantType });
      } catch (e) {
        enqueueSnackbar('Deleting error, try again later', { variant: 'error' as VariantType });
      }
      return;
    }

    let newData;
    if (contentType === Rules.PROFILE) {
      newData = {
        ...data,
        profileDecision: data.profileDecision === 'NO_IMPLICATIONS' ? null : data.profileDecision,
      };
    } else {
      newData = {
        id: data.id,
        metric: data.metric,
        decision: data.decision,
        type: data.type,
        reviewMinScore: data.reviewMinScore,
        decisionMinScore: data.decisionMinScore,
        restrictions: data.decision !== 'LIMIT' ? null : data.restrictions,
        profileImplicationDto:
          data.profileDecision === 'NO_IMPLICATIONS'
            ? null
            : {
                countForPeriod: data.profileDecision === 'NO_IMPLICATIONS' ? null : data.countForPeriod,
                periodUnit: data.profileDecision === 'NO_IMPLICATIONS' ? null : data.periodUnit,
                periodCount: data.profileDecision === 'NO_IMPLICATIONS' ? null : data.periodCount,
                profileDecision: data.profileDecision === 'NO_IMPLICATIONS' ? undefined : data.profileDecision,
                term: data.profileDecision === 'NO_IMPLICATIONS' ? null : data.term,
                decisionPeriodUnit: data.profileDecision === 'NO_IMPLICATIONS' ? null : data.decisionPeriodUnit,
                decisionPeriodCount: data.profileDecision === 'NO_IMPLICATIONS' ? null : data.decisionPeriodCount,
              },
      };
    }
    if (item.action === 'add') {
      try {
        const result = await dispatch(fetchAddRule({ type: contentType, data: newData }));
        result.meta.requestStatus === 'fulfilled' && onClose();
        result.meta.requestStatus === 'fulfilled' &&
          enqueueSnackbar(`${newData.metric} rule has been added`, { variant: 'success' as VariantType });
        result.meta.requestStatus === 'rejected' &&
          enqueueSnackbar('Creation error, try again later', { variant: 'error' as VariantType });
        setIsShowError(status === 'failed');
      } catch (e) {
        setIsShowError(true);
        enqueueSnackbar('Creation error, try again later', { variant: 'error' as VariantType });
      }
      return;
    }

    try {
      const result = await dispatch(fetchUpdateRule({ type: contentType, data: newData }));
      result.meta.requestStatus === 'fulfilled' && onClose();
      result.meta.requestStatus === 'fulfilled' &&
        enqueueSnackbar(`Changes of ${newData.metric} rule has been saved`, { variant: 'success' as VariantType });
      result.meta.requestStatus === 'rejected' &&
        enqueueSnackbar('Saving error, try again later', { variant: 'error' as VariantType });
      setIsShowError(status === 'failed');
    } catch (e) {
      setIsShowError(true);
      enqueueSnackbar('Saving error, try again later', { variant: 'error' as VariantType });
    }
  };

  return (
    <form
      className={item.action === 'delete' ? 'rulesModal rulesModal_delete' : 'rulesModal'}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="rulesModal-header">
        {item.action === 'add' && `New ${contentType} rule`}
        {item.action === 'edit' && `Edit ${item.rule.metric} rule`}
        {item.action === 'delete' && `You are going to delete ${item.rule.metric} rule`}
      </div>
      <div className="rulesModal-content">
        {item.action === 'delete' && <span className="rulesModal-content-value_delete">It’s irreversible action</span>}
        {item.action === 'add' && (
          <>
            <div className="rulesModal-content-name">Category</div>
            <Select
              {...register('metric')}
              error={!!errors.metric}
              name="metric"
              className="rulesModal-content-value"
              variant="outlined"
              size="small"
              options={item.availableMetrics.map(
                (metric: string) =>
                  ({
                    value: metric,
                    text: metric,
                  }) as ISelectOptions,
              )}
              defaultValue={item.availableMetrics[0] || ''}
              sx={{ width: '330px' }}
            />
            <Divider orientation="horizontal" />
          </>
        )}
        {(item.action === 'add' || item.action === 'edit') && (
          <>
            <div className="rulesModal-content-group rulesModal-content-group_sb">
              {contentType === Rules.HIVE && (
                <div>
                  <div className="rulesModal-content-name">Review score</div>
                  <TextField
                    {...register('reviewMinScore')}
                    error={!!errors.reviewMinScore}
                    label="From"
                    name="reviewMinScore"
                    className="rulesModal-content-value"
                    defaultValue={item.rule.reviewMinScore || 0.6}
                    size="small"
                    sx={{ width: '64px' }}
                  />
                </div>
              )}
              {contentType === Rules.HIVE && (
                <div>
                  <div className="rulesModal-content-name">Confirmation score</div>
                  <TextField
                    {...register('decisionMinScore')}
                    error={!!errors.decisionMinScore}
                    label="From"
                    name="decisionMinScore"
                    className="rulesModal-content-value"
                    defaultValue={item.rule.decisionMinScore || 0.9}
                    size="small"
                    sx={{ width: '64px' }}
                  />
                </div>
              )}
              {contentType !== Rules.PROFILE && (
                <div>
                  <div className="rulesModal-content-name">Decision</div>
                  <Select
                    {...register('decision')}
                    error={!!errors.decision}
                    name="decision"
                    className="rulesModal-content-value"
                    variant="outlined"
                    size="small"
                    options={[
                      { value: 'DELETE', text: 'Delete entity' },
                      { value: 'LIMIT', text: 'Limit entity' },
                    ]}
                    onChange={(event) => {
                      setValue('decision', event.target.value);
                      void trigger();
                    }}
                    defaultValue={item.rule.decision || 'DELETE'}
                    sx={{ width: '176px' }}
                  />
                </div>
              )}
            </div>
            {errors.reviewMinScore && <FormHelperText error>{errors.reviewMinScore?.message as string}</FormHelperText>}
            {errors.decisionMinScore && (
              <FormHelperText error>{errors.decisionMinScore?.message as string}</FormHelperText>
            )}
            {contentType !== Rules.PROFILE && <Divider orientation="horizontal" />}

            {getValues('decision') === 'LIMIT' && (
              <>
                <div className="rulesModal-content-group rulesModal-content-group_fs">
                  <div>
                    <div className="rulesModal-content-name">Restrictions</div>
                    <Select
                      className="rulesModal-content-value"
                      variant="outlined"
                      size="small"
                      options={[{ value: 'age', text: 'Age restrict' }]}
                      value={'age'}
                      sx={{ width: '200px' }}
                    />
                  </div>
                  <Select
                    disabled={true}
                    placeholder="Minimal age"
                    className="rulesModal-content-value"
                    variant="outlined"
                    size="small"
                    options={[{ value: '18', text: '18' }]}
                    value={'18'}
                    sx={{ width: '120px' }}
                  />
                </div>
                <Divider orientation="horizontal" />
              </>
            )}
            <div className="rulesModal-content-group rulesModal-content-group_fs">
              <div>
                <div className="rulesModal-content-name">Profile implications</div>
                <Controller
                  render={({ field: { value } }) => (
                    <Select
                      {...register('profileDecision')}
                      error={!!errors?.profileDecision}
                      placeholder="Implication"
                      className=""
                      variant="outlined"
                      size="small"
                      options={
                        contentType !== Rules.PROFILE
                          ? [
                              { value: 'BLOCK_PUBLICATION', text: 'Prohibit content creation' },
                              { value: 'BAN_PROFILE', text: 'Ban profile' },
                              { value: 'NO_IMPLICATIONS', text: 'No implications' },
                            ]
                          : [
                              { value: 'BLOCK_PUBLICATION', text: 'Prohibit content creation' },
                              { value: 'BAN_PROFILE', text: 'Ban profile' },
                            ]
                      }
                      onChange={(event) => {
                        setValue('profileDecision', event.target.value);
                        void trigger();
                      }}
                      value={value}
                      sx={{ width: '240px' }}
                    />
                  )}
                  name="profileDecision"
                  control={control}
                  defaultValue={
                    item.rule.profileImplicationDto?.profileDecision || item.rule.profileDecision || 'BLOCK_PUBLICATION'
                  }
                />
              </div>
              {getValues('profileDecision') !== 'NO_IMPLICATIONS' && (
                <>
                  <Select
                    {...register('term')}
                    error={!!errors.term}
                    name="term"
                    placeholder="Term"
                    className=""
                    variant="outlined"
                    size="small"
                    onChange={(event) => {
                      setValue('term', event.target.value);
                      void trigger();
                    }}
                    options={[
                      { value: 'FOR_PERIOD', text: 'for period' },
                      { value: 'PERMANENTLY', text: 'permanently' },
                    ]}
                    defaultValue={item.rule.profileImplicationDto?.term || item.rule.term || 'FOR_PERIOD'}
                    sx={{ width: '152px' }}
                  />
                  {getValues('term') !== 'PERMANENTLY' && (
                    <>
                      <span className="rulesModal-content-value_text">of</span>
                      <TextField
                        {...register('decisionPeriodCount')}
                        error={!!errors.decisionPeriodCount}
                        label="Days"
                        name="decisionPeriodCount"
                        className=""
                        defaultValue={
                          item.rule.profileImplicationDto?.decisionPeriodCount || item.rule.decisionPeriodCount || 14
                        }
                        size="small"
                        sx={{ width: '64px' }}
                      />
                    </>
                  )}
                </>
              )}
            </div>
            {getValues('profileDecision') !== 'NO_IMPLICATIONS' && errors.decisionPeriodCount && (
              <FormHelperText error>{errors.decisionPeriodCount.message as string}</FormHelperText>
            )}
            {getValues('profileDecision') !== 'NO_IMPLICATIONS' && (
              <>
                <div className="rulesModal-content-name rulesModal-content-name_grey">when number of violations</div>
                <div className="rulesModal-content-group rulesModal-content-group_fs">
                  <TextField
                    {...register('countForPeriod')}
                    error={!!errors.countForPeriod}
                    name="countForPeriod"
                    className="rulesModal-content-value"
                    defaultValue={item.rule.profileImplicationDto?.countForPeriod || item.rule.countForPeriod || 2}
                    size="small"
                    sx={{ width: '64px' }}
                  />
                  <span className="rulesModal-content-value rulesModal-content-value_text">within</span>
                  <TextField
                    {...register('periodCount')}
                    error={!!errors.periodCount}
                    name="periodCount"
                    className="rulesModal-content-value"
                    defaultValue={item.rule.profileImplicationDto?.periodCount || item.rule.periodCount || 1}
                    size="small"
                    sx={{ width: '64px' }}
                  />
                  <Select
                    {...register('periodUnit')}
                    error={!!errors.periodUnit}
                    name="periodUnit"
                    placeholder="Period"
                    className="rulesModal-content-value"
                    variant="outlined"
                    size="small"
                    onChange={(event) => {
                      setValue('periodUnit', event.target.value);
                      void trigger();
                    }}
                    options={[
                      { value: 'DAYS', text: 'days' },
                      { value: 'MONTH', text: 'month' },
                    ]}
                    defaultValue={item.rule.profileImplicationDto?.periodUnit || item.rule.periodUnit || 'DAYS'}
                    sx={{ width: '112px' }}
                  />
                </div>
                {errors.countForPeriod && (
                  <FormHelperText error>{errors.countForPeriod.message as string}</FormHelperText>
                )}
                {errors.periodCount && <FormHelperText error>{errors.periodCount.message as string}</FormHelperText>}
              </>
            )}
          </>
        )}
      </div>
      <div className="rulesModal-footer">
        {isShowError && (item.action === 'add' || item.action === 'edit') && (
          <Alert severity="error">{item.action === 'add' ? 'Creation' : 'Saving'} error, try again later</Alert>
        )}
        <div className="rulesModal-footer-buttons">
          <Button
            onClick={() => {
              if (item.action === 'delete') {
                onClose();
                return;
              }
              setIsOpenModal(true);
            }}
          >
            Cancel
          </Button>
          <Button type="submit" variant="contained" color={item.action === 'delete' ? 'error' : 'primary'}>
            {item.action === 'edit' ? 'Save' : item.action}
          </Button>
        </div>
      </div>
      <Modal customstyle={{ minHeight: 188 }} open={isOpenModal}>
        <RulesModalCancel
          closeModal={() => setIsOpenModal(false)}
          onClose={onClose}
          action={item.action}
        ></RulesModalCancel>
      </Modal>
    </form>
  );
};

export default RulesModalContent;
