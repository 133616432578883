/**
 * Returns a pluralized form of a noun based on the provided count.
 *
 * @param {number} count - The count of the items.
 * @param {string} noun - The noun to be pluralized.
 * @param {string} [suffix='s'] - The suffix to append for pluralization (default is 's').
 * @returns {string} A string that includes the count and the noun, pluralized if necessary.
 */
export const pluralize = (count: number, noun: string, suffix: string = 's'): string =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;
