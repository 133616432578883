import { createSvgIcon } from '@mui/material';

const CloseIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.8067 11.9913L5.78624 6.97082C5.45429 6.63887 5.45429 6.10068 5.78624 5.76873C6.11818 5.43679 6.65637 5.43679 6.98832 5.76873L12.0088 10.7892L17.0113 5.78666C17.3433 5.45472 17.8814 5.45472 18.2134 5.78666C18.5453 6.11861 18.5453 6.6568 18.2134 6.98875L13.2109 11.9913L18.2313 17.0117C18.5633 17.3437 18.5633 17.8819 18.2313 18.2138C17.8994 18.5458 17.3612 18.5458 17.0292 18.2138L12.0088 13.1934L6.97039 18.2317C6.63844 18.5637 6.10025 18.5637 5.76831 18.2317C5.43636 17.8998 5.43636 17.3616 5.76831 17.0297L10.8067 11.9913Z" />
  </svg>,
  'Close',
);

export default CloseIcon;
