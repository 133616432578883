/**
 * Capitalizes the first letter of a given string and converts the rest to lowercase.
 *
 * @param {string} value - The string to be capitalized.
 * @returns {string} The input string with the first letter capitalized and the rest in lowercase.
 */
export const stringCapitalize = (value: string): string => {
  const capitalizeFirstLetter = value.charAt(0).toUpperCase();
  const restString = value.slice(1).toLowerCase();
  return capitalizeFirstLetter + restString;
};
