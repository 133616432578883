import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import colors from 'theme/constants/colors';
import { IMediaItem } from 'types/commonTypes';
import ErrorIcon from 'assets/img/ErrorIcon';
import CircularProgress from 'components/shared/CircularProgress/CircularProgress';
import './index.scss';

interface IMediaThumbNailProps {
  mainMediaError: boolean;
  isReloadingMainMedia: boolean;
  setIsReloadingMainMedia: Dispatch<SetStateAction<boolean>>;
  mediaItem: IMediaItem;
  currentMainEntity: IMediaItem;
  onThumbnailClick: (maniEntity: IMediaItem) => void;
  isActive: boolean;
}

const MediaThumbnail = ({
  mainMediaError,
  isReloadingMainMedia,
  setIsReloadingMainMedia,
  mediaItem,
  currentMainEntity,
  onThumbnailClick,
  isActive,
}: IMediaThumbNailProps) => {
  const { mediaPreviewUrl, mediaId } = mediaItem;
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const imageSrcRef = useRef(mediaPreviewUrl);

  const handleReloadMedia = useCallback(async () => {
    const now = new Date();
    imageSrcRef.current = `${mediaPreviewUrl}?timestamp=${now.getTime()}`;
  }, [mediaPreviewUrl]);

  useEffect(() => {
    if (mainMediaError) {
      setIsLoading(false);
    }
  }, [mainMediaError]);

  useEffect(() => {
    if (isReloadingMainMedia) {
      setIsLoading(true);
      handleReloadMedia().then(() => setIsReloadingMainMedia(false));
    }
  }, [handleReloadMedia, isReloadingMainMedia]);

  const loadingClass = isLoading ? 'multimedia-preview-item__loading--show' : '';
  const errorClass =
    ((mainMediaError && mediaId === currentMainEntity.mediaId) || error) && !isLoading
      ? 'multimedia-preview-item__error--show'
      : '';
  const itemActiveClass = isActive ? 'multimedia-preview-item--active' : '';
  const imgActiveClass = isActive ? 'multimedia-preview-item__img--active' : '';

  return (
    <div
      className={`multimedia-preview-item ${itemActiveClass}`}
      onClick={() => {
        if (error) {
          setIsReloadingMainMedia(true);
        }
        onThumbnailClick(mediaItem);
      }}
    >
      <div>
        <div className={`multimedia-preview-item__loading ${loadingClass}`}>
          <CircularProgress />
        </div>
        <div className={`multimedia-preview-item__error ${errorClass}`}>
          <ErrorIcon sx={{ color: colors.lightError }} />
        </div>
        <img
          className={`multimedia-preview-item__img ${imgActiveClass}`}
          onLoad={() => {
            setError(false);
            setIsLoading(false);
          }}
          onError={() => setError(true)}
          src={imageSrcRef.current}
          alt=""
        />
      </div>
    </div>
  );
};

export default MediaThumbnail;
