enum ModerationTabs {
  HIVE = 'hive',
  CONTENT_COMPLAINTS = 'content-complaints',
  PROFILE_COMPLAINTS = 'profile-complaints',
  CONFIRMED_VIOLATIONS = 'confirmed-violations',
  DECLINED_REQUESTS = 'declined-requests',
  RULES = 'rules',
}

export enum Rules {
  HIVE = 'Hive',
  MANUAL = 'Manual',
  PROFILE = 'Profile',
}

export default ModerationTabs;
