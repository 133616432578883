import React, { MouseEvent } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { taxModerationCardSolutions } from '../constants';
import { ITaxModerationCard } from 'store/slices/TaxModeration/interface';
import ArrowDropDownIcon from 'assets/img/ArrowDropDownIcon';
import ArrowDropUpIcon from 'assets/img/ArrowDropUpIcon';
import EditIcon from 'assets/img/EditIcon';
import Button from 'components/shared/Button/Button';
import IconButton from 'components/shared/IconButton/IconButton';
import Stack from 'components/shared/Stack/Stack';
import Tooltip from 'components/shared/Tooltip/Tooltip';

/**
 * Props for the RenderSolutionCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - The properties of the RenderSolutionCell component.
 * @property {boolean} isMenuOpen - Flag for element anchor.
 * @property {ITaxModerationCard | null} currentSelectedSalesTax - Selected sales tax element.
 * @property {(e: MouseEvent<HTMLButtonElement>, data: any) => void} onClick - Click event handler.
 * @property {(e: MouseEvent<HTMLButtonElement>, data: any) => void} onEdit - IconButton click event handler.
 */
interface IRenderSolutionCellProps {
  props: GridRenderCellParams;
  isMenuOpen: boolean;
  currentSelectedSalesTax: ITaxModerationCard | null;
  onClick: (e: MouseEvent<HTMLButtonElement>, data: any) => void;
  onEdit: (e: MouseEvent<HTMLButtonElement>, data: any) => void;
}

/**
 * Renders a solution cell in a data grid.
 */
export const RenderSolutionCell = ({
  props,
  isMenuOpen,
  currentSelectedSalesTax,
  onClick,
  onEdit,
}: IRenderSolutionCellProps) => {
  const { row } = props;

  const handleClick = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    onClick(e, row);
  };

  const handleIconButtonClick = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    onEdit(e, row);
  };

  if (!row.id) {
    return <></>;
  }

  if (row.solution) {
    const currentSolutionType = taxModerationCardSolutions.find(
      (el) => row.solution?.toLowerCase() === el.solution?.toLowerCase(),
    );

    if (currentSolutionType) {
      return (
        <Stack direction="row" alignItems="center" sx={{ color: currentSolutionType.color }}>
          {row.solution.replace(row.solution, currentSolutionType.title)}
          <Tooltip followCursor placement="top-end" title="Change sales tax code">
            <IconButton sx={{ ml: 0.5 }} color="default" onClick={(e) => handleIconButtonClick(e)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      );
    }
  } else {
    return (
      <Button
        size="small"
        endIcon={currentSelectedSalesTax?.id === row.id && isMenuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        variant="contained"
        onClick={(e) => handleClick(e)}
      >
        Resolve
      </Button>
    );
  }

  return <></>;
};
