import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar, VariantType } from 'notistack';
import isEmpty from 'lodash/isEmpty';
import colors from 'theme/constants/colors';
import {
  IOperation,
  OperationActions,
  OperationStatuses,
  OperationTypes,
  TransactionStatuses,
  TransactionTypes,
} from 'store/slices/Payments/interface';
import { Permissions } from 'types/commonTypes';
import { checkPermissions } from 'utils/checkPermissions';
import { selectUserPermissions } from 'store/authSlice';
import { fetchRefundReasons, selectRefundReasons } from 'store/slices/Settings/dictionariesSlice';
import {
  fetchOperationDetails,
  selectCurrentOperationAction,
  selectOperationActionStatus,
  selectOperationDetails,
  selectOperationDetailsStatus,
} from 'store/slices/Payments/operationsSlice';
import { fetchUserProfile, selectUserProfile } from 'store/userProfileSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import ChevronDownIcon from 'assets/img/ChevronDownIcon';
import CopyIcon from 'assets/img/CopyIcon';
import Avatar from 'components/shared/Avatar/Avatar';
import Box from 'components/shared/Box/Box';
import IconButton from 'components/shared/IconButton/IconButton';
import LinearProgress from 'components/shared/LinearProgress/LinearProgress';
import ListItem from 'components/shared/List/ListItem/ListItem';
import ListItemText from 'components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText';
import LoadingButton from 'components/shared/LoadingButton/LoadingButton';
import Stack from 'components/shared/Stack/Stack';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import TransactionsTable from './TransactionsTable/TransactionsTable';
import Typography from 'components/shared/Typography/Typography';
import { MemberProfilePopover, ProfilePopover, RefundModal, SuspensionConfirmModal } from '../modals';

export const CustomDetailPanelContent = ({
  row: operationData,
  onDataRefresh,
}: {
  row: IOperation;
  onDataRefresh: () => void;
}) => {
  const { id: requestId } = useParams();
  const dispatch = useAppDispatch();
  const profileInfo = useAppSelector(selectUserProfile);
  const operationDetails = useAppSelector(selectOperationDetails);
  const operationDetailsStatus = useAppSelector(selectOperationDetailsStatus);
  const operationActionStatus = useAppSelector(selectOperationActionStatus);
  const currentOperationAction = useAppSelector(selectCurrentOperationAction);
  const userPermissions = useAppSelector(selectUserPermissions);
  const refundReasons = useAppSelector(selectRefundReasons);

  let isOperationDetailsLoading = operationDetailsStatus === 'loading';
  let isOperationActionLoading = operationActionStatus === 'loading';

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [memberInfoAnchorEl, setMemberInfoAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [controlledOperationAction, setControlledOperationAction] = useState<OperationActions | null>(null);

  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const [currentProfileId, setCurrentProfileId] = useState('');
  const [isEntityInfoExpanded, setIsEntityInfoExpanded] = useState<boolean>(false);
  const [isPaymentInfoExpanded, setIsPaymentInfoExpanded] = useState<boolean>(false);
  const [buttonLoadingState, setButtonLoadingState] = useState<{
    [profileId: string]: boolean;
  }>({});
  const [showRefundModal, setShowRefundModal] = useState<boolean>(false);
  const [showSuspensionModal, setShowSuspensionModal] = useState<boolean>(false);

  const isInAppOperation =
    operationData.operationType === OperationTypes.IAP_FOLLOW ||
    operationData.operationType === OperationTypes.IAP_DONATE ||
    operationData.operationType === OperationTypes.IAP_EVENT ||
    operationData.operationType === OperationTypes.IAP_LIFESTYLE;
  const isBoostOperation = operationData.operationType === OperationTypes.BOOST;
  const isChargeOperation = operationData.operationType === OperationTypes.CHARGE;
  const isPayoutOperation = operationData.operationType === OperationTypes.PAY_OUT;
  const isOperationFailed = operationData.status === OperationStatuses.FAILED;
  const isOperationInTransit = operationData.status === OperationStatuses.IN_TRANSIT;
  const isOperationPending = operationData.status === OperationStatuses.PENDING;
  const isOperationRefunded = operationData.status === OperationStatuses.REFUNDED;
  const isOperationRequested = operationData.status === OperationStatuses.REQUESTED;
  const isOperationStarted = operationData.status === OperationStatuses.START;
  const isOperationSuspended = operationData.isTransferSuspended;

  let isRefundTransactionSuccess = operationDetails?.transactions.some((transaction) => {
    return (
      transaction.status === TransactionStatuses.CANCELED ||
      (transaction.type === TransactionTypes.REFUND && transaction.status === TransactionStatuses.SUCCESS)
    );
  });
  let isRefundTransactionProceed = operationDetails?.transactions.some((transaction) => {
    return transaction.status === TransactionStatuses.CANCELED || transaction.type === TransactionTypes.REFUND;
  });
  let isValidOperationDetails =
    operationDetails?.operation.id === operationData.id || operationDetailsStatus !== 'failed';
  let isContainsAppleTransaction = operationDetails?.transactions.some((transaction) => {
    return transaction.type === TransactionTypes.APPLE_IAP;
  });

  const isContainsValidTransferTransaction = operationDetails?.transactions.some((transaction) => {
    const invalidStatuses = [TransactionStatuses.CANCELED, TransactionStatuses.FAILED, TransactionStatuses.REFUNDED];

    return transaction.type === TransactionTypes.TRANSFER && !invalidStatuses.includes(transaction.status);
  });

  const isAllowedRefundOrSuspend = !isPayoutOperation && !isBoostOperation && !isChargeOperation;
  const idDisabledSuspendButton = !!requestId;
  const idDisabledRefundButton = !!requestId;
  const isShowRefundButton = !(
    isOperationFailed ||
    isOperationStarted ||
    isOperationPending ||
    isOperationInTransit ||
    isOperationRequested ||
    isContainsAppleTransaction
  );

  const isShowSuspendButton = !isOperationSuspended && !isOperationRefunded && isContainsValidTransferTransaction;
  const isShowUnblockButton = isOperationSuspended && !isOperationRefunded && isContainsValidTransferTransaction;

  const isEmptyOperationHistory = isEmpty(operationDetails?.lastAdminOperationHistory);

  const handleLoadProfileInfo = async (profileId: string) => {
    setButtonLoadingState((prevLoadingStates) => ({
      ...prevLoadingStates,
      [profileId]: true,
    }));
    setCurrentProfileId(profileId);

    const result = await dispatch(fetchUserProfile(profileId));

    if (result.meta.requestStatus === 'rejected') {
      enqueueSnackbar('Failed to load the profile, try again later', {
        variant: 'error' as VariantType,
      });
    }

    setButtonLoadingState((prevLoadingStates) => ({
      ...prevLoadingStates,
      [profileId]: false,
    }));
  };

  const copyId = (e: MouseEvent<HTMLButtonElement>, value: string) => {
    e.stopPropagation();

    if (!value) {
      enqueueSnackbar('There is nothing to copy', {
        variant: 'info' as VariantType,
      });
      return;
    }

    if (navigator.clipboard) {
      enqueueSnackbar('Copied to clipboard', {
        variant: 'success' as VariantType,
      });

      return navigator.clipboard.writeText(value);
    }
  };

  const initialFetchData = useCallback(async () => {
    const result = await dispatch(fetchOperationDetails(operationData.id));
    if (result.meta.requestStatus === 'rejected') {
      enqueueSnackbar('Failed to load operation details, try again later', {
        variant: 'error' as VariantType,
      });
    }
  }, [dispatch, operationData.id]);

  useEffect(() => {
    initialFetchData();
  }, [operationData.id]);

  useEffect(() => {
    dispatch(fetchUserProfile(operationData.profileId!));
  }, [dispatch, operationData.profileId]);

  useEffect(() => {
    dispatch(fetchRefundReasons());
  }, [dispatch]);

  return (
    <Stack alignContent="center" direction="column" gap={1}>
      {isOperationDetailsLoading && (
        <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, right: 0 }} variant="query" />
      )}
      <Stack sx={{ borderRadius: 2, border: `1px solid ${colors.divider}` }} direction="row" width={1}>
        <Box component="div" pl={1} pt={1}>
          <IconButton
            size="small"
            aria-label={isEntityInfoExpanded ? 'Close' : 'Open'}
            onClick={() => setIsEntityInfoExpanded(!isEntityInfoExpanded)}
          >
            <ChevronDownIcon
              sx={{
                fontSize: 32,
                transform: `rotateZ(${isEntityInfoExpanded ? 180 : 0}deg)`,
                transition: (theme) =>
                  theme.transitions.create('transform', {
                    duration: theme.transitions.duration.shortest,
                  }),
              }}
            />
          </IconButton>
        </Box>

        <Stack direction="column" width={1} sx={{ pr: '50px' }}>
          <Stack alignItems="start" direction="row">
            <ListItem sx={{ maxWidth: '50%' }}>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body2" color="text.secondary">
                    Subject of payment
                  </Typography>
                }
                secondary={
                  <Typography variant="subtitle1" color="text.primary">
                    {operationData.entityTitle || '—'}
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
            <Stack direction="row" alignItems="start" width={1} sx={{ maxWidth: '50%', pl: 2 }}>
              <ListItem sx={{ px: 0 }}>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="text.secondary">
                      ID
                    </Typography>
                  }
                  secondary={
                    <Typography variant="subtitle1" color="text.primary">
                      {operationData.entityId || '—'}
                    </Typography>
                  }
                ></ListItemText>
              </ListItem>
              <ListItem sx={{ px: 0, ml: 4, width: '20%' }}>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="text.secondary">
                      Version
                    </Typography>
                  }
                  secondary={
                    <Typography variant="subtitle1" color="text.primary">
                      {operationData.entityVersion || '—'}
                    </Typography>
                  }
                ></ListItemText>
              </ListItem>
            </Stack>
          </Stack>
          {isEntityInfoExpanded && (
            <Stack alignItems="start" direction="row" width={1}>
              <ListItem sx={{ maxWidth: '50%' }}>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="text.secondary">
                      Description
                    </Typography>
                  }
                  secondary={
                    <Typography variant="subtitle1" color="text.primary">
                      {operationData.entityDescription || '—'}
                    </Typography>
                  }
                ></ListItemText>
              </ListItem>
              <ListItem sx={{ maxWidth: '50%' }}>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="text.secondary">
                      Entity execution ID
                    </Typography>
                  }
                  secondary={
                    <Typography
                      sx={{
                        wordBreak: 'break-word',
                      }}
                      variant="subtitle1"
                      color="text.primary"
                    >
                      {operationData.entityExecutionId || '—'}
                    </Typography>
                  }
                ></ListItemText>
              </ListItem>
            </Stack>
          )}
        </Stack>
      </Stack>
      <Stack
        sx={{
          borderRadius: 2,
          border: `1px solid ${colors.divider}`,
          position: 'relative',
        }}
      >
        <Stack direction="row">
          <Box component="div" pl={1} pt={1}>
            <IconButton
              size="small"
              aria-label={isPaymentInfoExpanded ? 'Close' : 'Open'}
              onClick={() => setIsPaymentInfoExpanded(!isPaymentInfoExpanded)}
            >
              <ChevronDownIcon
                sx={{
                  fontSize: 32,
                  transform: `rotateZ(${isPaymentInfoExpanded ? 180 : 0}deg)`,
                  transition: (theme) =>
                    theme.transitions.create('transform', {
                      duration: theme.transitions.duration.shortest,
                    }),
                }}
              />
            </IconButton>
          </Box>

          <ProfilePopover
            profileInfo={profileInfo}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            coordinates={coordinates}
            copyId={copyId}
            profileId={currentProfileId}
          />

          {isValidOperationDetails && (
            <MemberProfilePopover
              lastAdminOperationHistory={operationDetails?.lastAdminOperationHistory}
              action={controlledOperationAction}
              anchorEl={memberInfoAnchorEl}
              coordinates={coordinates}
              onClose={() => setMemberInfoAnchorEl(null)}
            />
          )}

          <Stack flex={1} direction="column">
            <Stack alignItems="start" gap={2} direction="row">
              <Stack>
                <Stack gap={1} flex="auto" alignItems="center" direction="row">
                  <ListItem>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography variant="body2" color="text.secondary">
                          Payer profile ID
                        </Typography>
                      }
                      secondary={
                        <>
                          <Tooltip placement="top-end" followCursor title={operationData.profileId}>
                            <Typography variant="subtitle1" color="text.primary">
                              {operationData.profileId || '—'}
                            </Typography>
                          </Tooltip>
                          <LoadingButton
                            onClick={(event) => {
                              void handleLoadProfileInfo(operationData.profileId);
                              setAnchorEl(event.currentTarget);
                              setCoordinates({
                                x: event.clientX,
                                y: event.clientY,
                              });
                            }}
                            sx={{ minWidth: 'unset', p: '4px 0' }}
                            variant="text"
                            size="small"
                            loading={buttonLoadingState[operationData.profileId] || false}
                          >
                            More
                          </LoadingButton>
                        </>
                      }
                    ></ListItemText>
                  </ListItem>
                  <Tooltip placement="top" title="Copy">
                    <IconButton color="default" size="small" onClick={(e) => copyId(e, operationData.profileId ?? '')}>
                      <CopyIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
                {isPaymentInfoExpanded && (
                  <>
                    <Stack gap={1} alignItems="center" direction="row">
                      <ListItem>
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography variant="body2" color="text.secondary">
                              External payer ID
                            </Typography>
                          }
                          secondary={
                            <Tooltip placement="top-end" followCursor title={operationData.externalPayerId}>
                              <Typography variant="subtitle1" color="text.primary">
                                {operationData.externalPayerId || '—'}
                              </Typography>
                            </Tooltip>
                          }
                        ></ListItemText>
                      </ListItem>
                      <Tooltip placement="top" title="Copy">
                        <IconButton
                          color="default"
                          size="small"
                          onClick={(e) => copyId(e, operationData.externalPayerId ?? '')}
                        >
                          <CopyIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                    <Stack gap={1} flex="auto" alignItems="center" direction="row">
                      <ListItem>
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography variant="body2" color="text.secondary">
                              External tax payer ID
                            </Typography>
                          }
                          secondary={
                            <Tooltip placement="top-end" followCursor title={operationData.externalTaxPayerId}>
                              <Typography variant="subtitle1" color="text.primary">
                                {operationData.externalTaxPayerId || '—'}
                              </Typography>
                            </Tooltip>
                          }
                        ></ListItemText>
                      </ListItem>
                      <Tooltip placement="top" title="Copy">
                        <IconButton
                          color="default"
                          size="small"
                          onClick={(e) => copyId(e, operationData.externalTaxPayerId ?? '')}
                        >
                          <CopyIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </>
                )}
              </Stack>

              <Box pt={5}>
                <svg width="33" height="8" viewBox="0 0 33 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M32.3536 4.35355C32.5488 4.15829 32.5488 3.84171 32.3536 3.64645L29.1716 0.464466C28.9763 0.269204 28.6597 0.269204 28.4645 0.464466C28.2692 0.659728 28.2692 0.976311 28.4645 1.17157L31.2929 4L28.4645 6.82843C28.2692 7.02369 28.2692 7.34027 28.4645 7.53553C28.6597 7.7308 28.9763 7.7308 29.1716 7.53553L32.3536 4.35355ZM0 4.5H32V3.5H0V4.5Z"
                    fill="#BCC0CD"
                  />
                </svg>
              </Box>

              <Stack>
                <Stack gap={1} flex="auto" alignItems="center" direction="row">
                  <ListItem>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography variant="body2" color="text.secondary">
                          Recipient profile ID
                        </Typography>
                      }
                      secondary={
                        <>
                          <Tooltip placement="top-end" followCursor title={operationData.recipientProfileId}>
                            <Typography variant="subtitle1" color="text.primary">
                              {operationData.recipientProfileId || '—'}
                            </Typography>
                          </Tooltip>
                          <LoadingButton
                            onClick={(event) => {
                              void handleLoadProfileInfo(operationData.recipientProfileId);
                              setAnchorEl(event.currentTarget);
                              setCoordinates({
                                x: event.clientX,
                                y: event.clientY,
                              });
                            }}
                            sx={{ minWidth: 'min-content', p: '4px 0' }}
                            variant="text"
                            size="small"
                            loading={buttonLoadingState[operationData.recipientProfileId] || false}
                          >
                            More
                          </LoadingButton>
                        </>
                      }
                    ></ListItemText>
                  </ListItem>
                  <Tooltip placement="top" title="Copy">
                    <IconButton
                      color="default"
                      size="small"
                      onClick={(e) => copyId(e, operationData.recipientProfileId ?? '')}
                    >
                      <CopyIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
                {isPaymentInfoExpanded && (
                  <Stack gap={1} flex="auto" alignItems="center" direction="row">
                    <ListItem>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography variant="body2" color="text.secondary">
                            External recipient ID
                          </Typography>
                        }
                        secondary={
                          <Tooltip placement="top-end" followCursor title={operationData.externalReceiverId}>
                            <Typography variant="subtitle1" color="text.primary">
                              {operationData.externalReceiverId || '—'}
                            </Typography>
                          </Tooltip>
                        }
                      ></ListItemText>
                    </ListItem>
                    <Tooltip placement="top" title="Copy">
                      <IconButton
                        color="default"
                        size="small"
                        onClick={(e) => copyId(e, operationData.externalReceiverId ?? '')}
                      >
                        <CopyIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
          {isAllowedRefundOrSuspend && (
            <Stack
              p={1}
              sx={{ opacity: isOperationDetailsLoading ? 0 : 1, transition: 'opacity 0.2s' }}
              gap={isOperationSuspended || isRefundTransactionProceed || isRefundTransactionSuccess ? 1 : 2}
              alignItems="flex-end"
            >
              {isShowRefundButton && (
                <>
                  {isValidOperationDetails && isRefundTransactionProceed ? (
                    <Stack
                      alignItems="center"
                      direction="row"
                      p={1}
                      gap={1}
                      borderRadius={2}
                      border={`1px solid ${colors.divider}`}
                    >
                      <IconButton
                        sx={{ maxHeight: 24, p: 0 }}
                        disableRipple
                        size="small"
                        disabled={isEmptyOperationHistory}
                        onClick={(event) => {
                          setControlledOperationAction(OperationActions.REFUND);
                          setMemberInfoAnchorEl(event.currentTarget);
                          setCoordinates({ x: event.clientX, y: event.clientY });
                        }}
                      >
                        <Avatar
                          style={{
                            backgroundColor: !!memberInfoAnchorEl ? colors.standardInputLine : colors.actionDisabled,
                          }}
                          sx={{
                            width: 24,
                            height: 24,
                          }}
                          iconSize="16px"
                        />
                      </IconButton>
                      <Typography fontSize="small" color="text.secondary" variant="buttonSmall">
                        {isRefundTransactionSuccess ? 'Refunded' : 'Refund initiated'}
                      </Typography>
                    </Stack>
                  ) : (
                    <LoadingButton
                      loading={currentOperationAction === OperationActions.REFUND && isOperationActionLoading}
                      disabled={idDisabledRefundButton}
                      variant="outlined"
                      size="small"
                      onClick={() => setShowRefundModal(true)}
                    >
                      Refund
                    </LoadingButton>
                  )}
                </>
              )}
              {isShowSuspendButton && (
                <>
                  {checkPermissions(userPermissions, [Permissions.PAYMENTS_REFUND]) && (
                    <LoadingButton
                      loading={currentOperationAction === OperationActions.SUSPEND && isOperationActionLoading}
                      disabled={idDisabledSuspendButton}
                      variant="outlined"
                      size="small"
                      onClick={() => setShowSuspensionModal(true)}
                    >
                      Suspend
                    </LoadingButton>
                  )}
                </>
              )}
              {isShowUnblockButton && (
                <>
                  {checkPermissions(userPermissions, [Permissions.PAYMENTS_SUSPEND_UNBLOCK]) && (
                    <Stack
                      alignItems="center"
                      direction="row"
                      p={1}
                      gap={1}
                      borderRadius={2}
                      border={`1px solid ${colors.divider}`}
                    >
                      <IconButton
                        sx={{ maxHeight: 24, p: 0 }}
                        disableRipple
                        size="small"
                        disabled={isEmptyOperationHistory}
                        onClick={(event) => {
                          setControlledOperationAction(OperationActions.SUSPEND);
                          setMemberInfoAnchorEl(event.currentTarget);
                          setCoordinates({ x: event.clientX, y: event.clientY });
                        }}
                      >
                        <Avatar
                          style={{
                            backgroundColor: !!memberInfoAnchorEl ? colors.standardInputLine : colors.actionDisabled,
                          }}
                          sx={{
                            width: 24,
                            height: 24,
                          }}
                          iconSize="16px"
                        />
                      </IconButton>
                      <LoadingButton
                        loading={currentOperationAction === OperationActions.UNSUSPEND && isOperationActionLoading}
                        disabled={idDisabledSuspendButton}
                        variant="outlined"
                        size="small"
                        onClick={() => setShowSuspensionModal(true)}
                      >
                        Unblock
                      </LoadingButton>
                    </Stack>
                  )}
                </>
              )}
            </Stack>
          )}
        </Stack>
        <TransactionsTable row={operationData} />
      </Stack>
      <SuspensionConfirmModal
        open={showSuspensionModal}
        operationId={operationData.id as string}
        isOperationSuspended={isOperationSuspended}
        onDataRefresh={() => {
          dispatch(fetchOperationDetails(operationData.id));
          onDataRefresh();
        }}
        onClose={() => setShowSuspensionModal(false)}
      />

      <RefundModal
        open={showRefundModal}
        total={operationData.total}
        isInAppOperation={isInAppOperation}
        currency={operationData.currency}
        operationId={operationData.id as string}
        refundReasons={refundReasons}
        onDataRefresh={() => {
          dispatch(fetchOperationDetails(operationData.id));
          onDataRefresh();
        }}
        onClose={() => setShowRefundModal(false)}
      />
    </Stack>
  );
};
