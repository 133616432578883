import { ChangeEvent, FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { Menu, MenuItem } from '@mui/material';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { enqueueSnackbar, VariantType } from 'notistack';
import { useErrorBoundary } from 'react-error-boundary';
import uniqueId from 'lodash/uniqueId';
import { possibleTariffGroupsStatuses } from './constants';
import { ErrorBoundaryErrors } from 'containers/ErrorPage/ErrorPage';
import { Permissions } from 'types/commonTypes';
import { ITariffGroup, ITariffGroupStatus } from 'store/slices/Settings/interface';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchTariffGroups, selectTariffGroups, selectTariffGroupsStatus } from 'store/slices/Settings/tariffsSlice';
import { checkPermissions } from 'utils/checkPermissions';
import { selectUserPermissions } from 'store/authSlice';
import DeleteIcon from 'assets/img/DeleteIcon';
import Button from 'components/shared/Button/Button';
import CustomNoRowsOverlay from 'components/shared/DataGrid/CustomNoResultsOverlay/CustomNoRowsOverlay';
import Grid from 'components/shared/Grid/Grid';
import ListItemIcon from 'components/shared/List/ListItem/ListItemComponents/ListItemIcon/ListItemIcon';
import ListItemText from 'components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText';
import RenderCellWithCopy from 'components/shared/DataGrid/RenderCellWithCopy/RenderCellWithCopy';
import RenderExpandableCell from 'components/shared/DataGrid/RenderExpandableCell/RenderExpandableCell';
import SearchField from 'components/shared/SearchField/SearchField';
import Typography from 'components/shared/Typography/Typography';
import { RenderActionsCell, RenderStatusSelect } from './components';
import './index.scss';

export const TariffGroups: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userPermissions = useAppSelector(selectUserPermissions);
  const data = useAppSelector(selectTariffGroups);
  const status = useAppSelector(selectTariffGroupsStatus);
  let isLoading = status === 'loading';

  const { showBoundary } = useErrorBoundary();
  const [searchValue, setSearchValue] = useState<string>('');
  const [fetchingError, setFetchingError] = useState<boolean>(false);

  const [tableMenuAnchorEl, setTableMenuAnchorEl] = useState<null | HTMLElement>(null);
  const tableMenu = Boolean(tableMenuAnchorEl);

  const [statusMenuAnchorEl, setStatusMenuAnchorEl] = useState<null | HTMLElement>(null);
  const statusMenuOpen = Boolean(statusMenuAnchorEl);

  const [currentSelectedStatus, setCurrentSelectedStatus] = useState<ITariffGroupStatus | null>(null);
  const [currentSelectedTariffGroup, setCurrentSelectedTariffGroup] = useState<ITariffGroup | null>(null);

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleStatusMenuClick = (event: MouseEvent<HTMLDivElement>, data: ITariffGroup) => {
    event.stopPropagation();
    setStatusMenuAnchorEl(event.currentTarget);
    setCurrentSelectedTariffGroup(data);
  };

  const handleTableMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setTableMenuAnchorEl(event.currentTarget);
  };

  const handleTableMenuClose = () => {
    setTableMenuAnchorEl(null);
  };

  const handleStatusMenuClose = () => {
    setStatusMenuAnchorEl(null);
  };

  const handleChangeStatusClick = (elem: ITariffGroupStatus) => {
    setCurrentSelectedStatus(elem);
    handleStatusMenuClose();
  };

  const handleRowClick = (params: GridRowParams<any>) => {
    if (!checkPermissions(userPermissions, [Permissions.SETTINGS_VIEW_TARIFFS_IN_GROUP])) {
      return;
    }

    const currentRow = params.row;
    const options = {
      pathname: 'details',
      search: `?${createSearchParams(currentRow)}`,
    };
    navigate(options, { replace: true });
  };

  const fetchTariffGroupsInitial = useCallback(async () => {
    try {
      await dispatch(fetchTariffGroups()).unwrap();
    } catch (error) {
      showBoundary(error);
    }
  }, [dispatch]);

  const fetchTariffGroupsGrid = useCallback(async () => {
    dispatch(fetchTariffGroups()).then((result: any) => {
      if (result.meta && result.meta.requestStatus === 'rejected') {
        enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType });
        setFetchingError(true);
      } else if (result.meta && result.meta.requestStatus === 'fulfilled') {
        setFetchingError(false);
      }
    });
  }, [dispatch]);

  const repeatFetchingRequest = useCallback(async () => {
    fetchTariffGroupsGrid();
  }, [dispatch]);

  const initialFetchData = useCallback(async () => {
    await fetchTariffGroupsInitial();
  }, []);

  useEffect(() => {
    initialFetchData();
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'tariffGroupId',
      headerName: 'ID',
      flex: 0.6,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => <RenderCellWithCopy isWrappedText props={params} />,
    },
    {
      field: 'tariffGroupTitle',
      headerName: 'Title',
      flex: 0.6,
      minWidth: 140,
      sortable: false,
      renderCell: ({ value }) => <span>{value ? value : '—'}</span>,
    },
    {
      field: 'groupDescription',
      headerName: 'Description',
      flex: 3,
      minWidth: 160,
      sortable: false,
      renderCell: (params) => <RenderExpandableCell {...params} />,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.4,
      minWidth: 100,
      sortable: false,
      renderCell: (params) => (
        <RenderStatusSelect
          props={params}
          selectedCellData={currentSelectedTariffGroup}
          isMenuOpen={statusMenuOpen}
          onClick={(e, data) => handleStatusMenuClick(e, data)}
        />
      ),
    },
    {
      field: 'hidden',
      headerName: '',
      sortable: false,
      flex: 0.3,
      minWidth: 70,
      renderCell: (params) => <RenderActionsCell props={params} onClick={(e) => handleTableMenuClick(e)} />,
    },
  ];

  const rows: ITariffGroup[] | [] = useMemo(() => {
    if (data) {
      return data;
    } else return [];
  }, [data]);

  const isEmptyData = !rows.length;

  return (
    <div className="tariff-groups-table-container">
      <div className="tariff-groups-table-container__actions">
        {checkPermissions(userPermissions, [Permissions.SETTINGS_ADD_TARIFF_GROUP]) && (
          <Button disabled variant="contained">
            Add tariff group
          </Button>
        )}

        {!isEmptyData && (
          <SearchField
            searchValue={searchValue}
            onChange={handleChangeSearch}
            clearEvent={() => setSearchValue('')}
            label="Search by ID"
            maxlength={50}
            disabled
          />
        )}
      </div>
      <Typography variant="h6">Tariff groups</Typography>
      <Grid
        className="tariff-groups-table"
        columns={columns}
        rows={rows}
        getRowId={(row) => row.tariffGroupId}
        onRowClick={(params) => handleRowClick(params)}
        loading={isLoading}
        getRowHeight={() => 'auto'}
        getRowSpacing={() => ({
          bottom: 16,
        })}
        hideFooter
        disableColumnMenu
        disableColumnReorder
        disableColumnResize
        disableSelectionOnClick
        columnVisibilityModel={{
          hidden: checkPermissions(userPermissions, [Permissions.SETTINGS_DELETE_TARIFF_GROUP]),
        }}
        components={{
          NoRowsOverlay: () =>
            CustomNoRowsOverlay({
              isFiltersApplied: false,
              fetchingError: fetchingError,
              refreshData: () => repeatFetchingRequest(),
              renderNoDataComponent: () => (
                <>
                  <Typography color="text.secondary" variant="body2">
                    No tariff group has been created yet
                  </Typography>
                  {checkPermissions(userPermissions, [Permissions.SETTINGS_ADD_TARIFF_GROUP]) && (
                    <Button variant="text" color="primary">
                      <Typography variant="buttonMedium">Create tariff group</Typography>
                    </Button>
                  )}
                </>
              ),
            }),
        }}
      />
      <Menu
        id="table-menu"
        anchorEl={tableMenuAnchorEl}
        open={tableMenu}
        onClose={handleTableMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'table-button',
        }}
      >
        <MenuItem>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <Menu
        id="status-menu"
        anchorEl={statusMenuAnchorEl}
        open={statusMenuOpen}
        onClose={handleStatusMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {possibleTariffGroupsStatuses.map((status: any) => (
          <MenuItem
            key={uniqueId()}
            selected={status.status === currentSelectedStatus?.status}
            onClick={() => handleChangeStatusClick(status)}
          >
            {status.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
