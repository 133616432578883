import axios from "axios";
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import { logout, refresh, updateSnackbarKeys } from "store/authSlice";
import { enqueueSnackbar, VariantType } from "notistack";
import { LocalStorageKeys } from 'types/commonTypes';
import { action } from "containers/SnackbarContainer";

let store: ToolkitStore;

export const injectStore = (_store: ToolkitStore) => {
  store = _store;
}

axios.interceptors.request.use(
  (config) => {
    if (
      (config.url === (process.env.REACT_APP_AUTH_MEMBERS_ENDPOINT as string) + 'members/sign-in'
        || config.url === (process.env.REACT_APP_AUTH_MEMBERS_ENDPOINT as string) + 'members/password-setup'
        || config.url === (process.env.REACT_APP_AUTH_MEMBERS_ENDPOINT as string) + 'members/verify-otp'
      )
    ) {
      return config;
    }
    const accessToken: string = localStorage.getItem('accessToken') ?? '';
    const memberId: string = store.getState().auth.memberId;
    config.headers.set('Member-Id', memberId);
    config.headers.set('Access-Token', accessToken);
    return config;
  },
  (error) => {
    return error;
  }
);

let failedQueue: any[] = [];
let isRefreshing = false;

const processQueue = (error: unknown, data: { memberId: string, accessToken: string } | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(data);
    }
  })

  failedQueue = [];
};

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    const originalConfig = error.config;

    if (
      (originalConfig.url !== (process.env.REACT_APP_AUTH_MEMBERS_ENDPOINT as string) + 'members/sign-in'
        || originalConfig.url !== (process.env.REACT_APP_AUTH_MEMBERS_ENDPOINT as string) + 'members/password-setup'
        || originalConfig.url !== (process.env.REACT_APP_AUTH_MEMBERS_ENDPOINT as string) + 'members/verify-otp'
      )
      && error.response
    ) {
      // Access Token was expired
      if (error.response.status === 401
        && store.getState().auth.refreshToken
        && store.getState().auth.isAuth
      ) {
        if (isRefreshing) {
          return new Promise(function(resolve, reject) {
            failedQueue.push({resolve, reject})
          }).then((data) => {
            const { memberId, accessToken } = data as { memberId: string, accessToken: string };
            originalConfig.headers.set('Member-Id', memberId);
            originalConfig.headers.set('Access-Token', accessToken);
            return axios(originalConfig);
          }).catch(err => {
            return Promise.reject(err);
          })
        }

        isRefreshing = true;
        let memberId = '';
        let accessToken = '';

        try {
          const refreshData = await axios.post((process.env.REACT_APP_AUTH_MEMBERS_ENDPOINT as string) + 'members/refresh-token', {
            refreshToken: store.getState().auth.refreshToken,
            memberId: store.getState().auth.memberId,
          });

          accessToken = refreshData.data.accessToken;
          memberId = refreshData.data.memberId;
          localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, accessToken);
          localStorage.setItem(LocalStorageKeys.MEMBER_ID, memberId);

          originalConfig.headers.set('Member-Id', memberId);
          originalConfig.headers.set('Access-Token', accessToken);
          store.dispatch(refresh({ memberId, accessToken}));
        } catch (_error) {
          store.dispatch(logout({ message: '' }));
          processQueue(_error, null);
          const key = enqueueSnackbar('Session has been ended, you have to relogin', { action, autoHideDuration: null, variant: 'warning' as VariantType });
          const snackbarKeys = store.getState().auth.snackbarKeys;
          store.dispatch(updateSnackbarKeys([...snackbarKeys, key]));
          return Promise.reject(_error);
        }
        finally {
          isRefreshing = false;
        }
        processQueue(null, { memberId, accessToken });
        return await axios(originalConfig);
      }
    }
    return Promise.reject(error);
  }
);
