import { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { enqueueSnackbar, VariantType } from 'notistack';
import colors from 'theme/constants/colors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchUserProfile, selectUserProfile, selectUserProfileStatus } from 'store/userProfileSlice';
import { ErrorBoundaryErrors } from 'containers/ErrorPage/ErrorPage';
import CloseIcon from 'assets/img/CloseIcon';
import LinkIcon from 'assets/img/LinkIcon';
import Button from 'components/shared/Button/Button';
import CircularProgress from 'components/shared/CircularProgress/CircularProgress';
import Dialog from 'components/shared/Dialog/Dialog';
import DialogContent from 'components/shared/Dialog/DialogContent/DialogContent';
import DialogTitle from 'components/shared/Dialog/DialogTitle/DialogTitle';
import IconButton from 'components/shared/IconButton/IconButton';
import Typography from 'components/shared/Typography/Typography';
import UserProfile from 'components/UserProfile/UserProfile';
import './index.scss';

interface IProps {
  open: boolean;
  currentUserId?: string;
  onClose: () => void;
}

const UserProfileModal = ({ open, onClose, currentUserId }: IProps) => {
  const dispatch = useAppDispatch();

  const { userId } = useParams();
  const usedUserId = currentUserId ?? userId;

  const userProfileData = useAppSelector(selectUserProfile);
  const [isScrollVisible, setIsScrollVisible] = useState<boolean>(false);

  useEffect(() => {
    if (usedUserId) {
      dispatch(fetchUserProfile(usedUserId)).then((result: any) => {
        if (result.meta?.requestStatus === 'rejected' && ErrorBoundaryErrors.includes(result.error.message)) {
          onClose();
          enqueueSnackbar('Loading user’s card error, try again later', { variant: 'error' as VariantType });
        }
      });
    }
  }, [usedUserId]);

  useLayoutEffect(() => {
    const item = document.querySelector('.MuiDialogContent-root');
    setIsScrollVisible(item?.clientHeight !== item?.scrollHeight);
  });

  return (
    <Dialog
      className="user-profile-modal"
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={onClose}
      sx={{
        '& ::-webkit-scrollbar-track': {
          background: 'transparent',
        },

        '& ::-webkit-scrollbar-thumb': {
          border: `8px solid ${colors.backgroundModal}`,
        },
      }}
    >
      <DialogTitle className="user-profile-modal__header">
        <Typography variant="h6" color="text.primary">
          User #{usedUserId}
        </Typography>
        {/* <Link to={`/ui/users/user/${usedUserId}`} target="_blank">
            <Button size="small" startIcon={<LinkIcon />}>
              Show in YZZY users
            </Button>
          </Link> */}
        <IconButton className="user-profile-modal__header-close" size="medium" onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={isScrollVisible ? 'scrollable' : ''} sx={{ backgroundColor: colors.backgroundModal }}>
        {userProfileData.main?.userId !== usedUserId ? (
          <div className="user-profile-modal__overlay">
            <CircularProgress color="primary" />
          </div>
        ) : (
          <div className="user-profile-modal__content">
            <div className="user-profile-modal__container">
              <UserProfile />
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UserProfileModal;
