import colors from 'theme/constants/colors';
import {
  IOperationStatus,
  IOperationType,
  IPaymentMethod,
  ITransactionType,
  OperationStatuses,
  OperationTypes,
  PaymentMethods,
  TransactionTypes,
} from 'store/slices/Payments/interface';
import { ISortingItem } from 'types/commonTypes';

export const defaultOperationsSortingModel: ISortingItem = {
  columnId: 'create_date',
  title: 'Creation date',
  sortingType: 'DESC',
};

export const possibleOperationStatuses: IOperationStatus[] = [
  {
    status: OperationStatuses.SUCCESS,
    title: 'Success',
    color: colors.success,
  },
  {
    status: OperationStatuses.START,
    title: 'Start',
    color: colors.textSecondary,
  },
  {
    status: OperationStatuses.COMPLETED,
    title: 'Completed',
    color: colors.success,
  },
  {
    status: OperationStatuses.REQUESTED,
    title: 'Requested',
    color: colors.textSecondary,
  },
  {
    status: OperationStatuses.REFUNDED_REFUNDED,
    title: 'Refunded (Refunded)',
    color: colors.success,
  },
  {
    status: OperationStatuses.REFUNDED,
    title: 'Refunded',
    color: colors.success,
  },
  {
    status: OperationStatuses.HOLD,
    title: 'Hold',
    color: colors.primary,
  },
  {
    status: OperationStatuses.FROZEN,
    title: 'Frozen',
    color: colors.primary,
  },
  {
    status: OperationStatuses.REFUNDED_HOLD_CHARGE,
    title: 'Refunded (Hold charge)',
    color: colors.primary,
  },
  {
    status: OperationStatuses.PARTIALLY_REFUNDED,
    title: 'Partially refunded',
    color: colors.primary,
  },
  {
    status: OperationStatuses.HOLD_CHARGE,
    title: 'Hold charge',
    color: colors.primary,
  },
  {
    status: OperationStatuses.PENDING,
    title: 'Pending',
    color: colors.textSecondary,
  },
  {
    status: OperationStatuses.IN_TRANSIT,
    title: 'In transit',
    color: colors.textSecondary,
  },
  {
    status: OperationStatuses.REFUNDED_PENDING,
    title: 'Refunded (Pending)',
    color: colors.textSecondary,
  },
  {
    status: OperationStatuses.REFUNDED_PENDING_CHARGE,
    title: 'Refunded (Pending charge)',
    color: colors.textSecondary,
  },
  {
    status: OperationStatuses.PENDING_REFUND,
    title: 'Pending refund',
    color: colors.textSecondary,
  },
  {
    status: OperationStatuses.CANCELED,
    title: 'Canceled',
    color: colors.error,
  },
  {
    status: OperationStatuses.REFUNDED_CHARGED,
    title: 'Refunded (Charged)',
    color: colors.error,
  },
  {
    status: OperationStatuses.REFUNDED_FROZEN,
    title: 'Refunded (Frozen)',
    color: colors.error,
  },
  {
    status: OperationStatuses.REFUNDED_CANCELED,
    title: 'Refunded (Canceled)',
    color: colors.error,
  },
  {
    status: OperationStatuses.CHARGED,
    title: 'Charged',
    color: colors.error,
  },
  {
    status: OperationStatuses.FAILED,
    title: 'Failed',
    color: colors.error,
  },
];

export const possibleOperationTypes: IOperationType[] = [
  {
    operationType: OperationTypes.IAP_FOLLOW,
    title: 'In-App Purchase',
    subtitle: 'Connect (Follow)',
    tierTitle: 'Only iap_follow',
  },
  {
    operationType: OperationTypes.DONATE,
    title: 'Purchase',
    subtitle: 'Donation',
  },
  {
    operationType: OperationTypes.IAP_DONATE,
    title: 'In-App Purchase',
    subtitle: 'Donation',
  },
  {
    operationType: OperationTypes.IAP_DONATION,
    title: 'In-App Purchase',
    subtitle: 'Donation',
    tierTitle: 'Only iap_donation',
  },
  {
    operationType: OperationTypes.RM_GOAL,
    title: 'Purchase',
    subtitle: 'Goal (Raising money)',
  },
  {
    operationType: OperationTypes.IAP_LIFESTYLE,
    title: 'In-App Purchase',
    subtitle: 'Paid Lifestyle',
    tierTitle: 'Only iap_lifestyle',
  },
  {
    operationType: OperationTypes.EVENT,
    title: 'Purchase',
    subtitle: 'Event',
  },
  {
    operationType: OperationTypes.IAP_EVENT,
    title: 'In-App Purchase',
    subtitle: 'Event',
    tierTitle: 'Only iap_event',
  },
  {
    operationType: OperationTypes.OFFER,
    title: 'Purchase',
    subtitle: 'Offer',
  },
  {
    operationType: OperationTypes.WISH,
    title: 'Purchase',
    subtitle: 'Wish',
  },
  {
    operationType: OperationTypes.PRIVATE_WISH,
    title: 'Purchase',
    subtitle: 'Private Wish',
  },
  {
    operationType: OperationTypes.TIPS_EVENT,
    title: 'Purchase',
    subtitle: 'Tips Event',
  },
  {
    operationType: OperationTypes.TIPS_OFFER,
    title: 'Purchase',
    subtitle: 'Tips Offer',
  },
  {
    operationType: OperationTypes.BOOST,
    title: 'Executed boost wish',
    subtitle: '',
  },
  {
    operationType: OperationTypes.PAY_OUT,
    title: 'Payout',
    subtitle: 'Standard',
  },
  {
    operationType: OperationTypes.CHARGE,
    title: 'Charge',
    subtitle: 'Penalty',
  },
];

export const possibleTransactionsTypes: ITransactionType[] = [
  {
    transactionType: TransactionTypes.APPLE_IAP,
    title: 'Apple iap',
  },
  {
    transactionType: TransactionTypes.REVERSAL_TRANSFER,
    title: 'Reversal transfer',
  },
  {
    transactionType: TransactionTypes.CHARGE,
    title: 'Charge',
  },
  {
    transactionType: TransactionTypes.PAY_OUT,
    title: 'Payout',
  },
  {
    transactionType: TransactionTypes.REFUND,
    title: 'Refund',
  },
  {
    transactionType: TransactionTypes.GOOGLE_IAP,
    title: 'Google iap',
  },
  {
    transactionType: TransactionTypes.CARD_PAYMENTS,
    title: 'Card payment',
  },
  {
    transactionType: TransactionTypes.TRANSFER,
    title: 'Transfer',
  },
];

export const possiblePaymentMethods: IPaymentMethod[] = [
  {
    paymentMethod: PaymentMethods.APPLE_IAP,
    title: 'Apple iap',
  },
  {
    paymentMethod: PaymentMethods.GOOGLE_IAP,
    title: 'Google iap',
  },
  {
    paymentMethod: PaymentMethods.CARD,
    title: 'Card',
  },
];
