import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from "store/store";
import axios from "axios";
import { IGender, ILocation } from "types/commonTypes";

export type CohortsStatus = 'ACTIVE' | 'WAITING';

export interface Cohort {
  id: string,
  title: string,
  status: CohortsStatus,
  creatorName: string,
  creatorLastName: string,
  createdAt: Date,
  minAge: number | null,
  maxAge: number | null,
  genders: IGender[] | null,
  locations: ILocation[] | null,
  hasCompilation?: boolean;
}

export type CohortDetails = Omit<Cohort, 'status' | 'creatorName' | 'creatorLastName' | 'createdAt' | 'hasCompilation'>;

export interface CohortState {
  cohort: CohortDetails | null;

}

const initialState: CohortState = {
  cohort: null,
};

export const fetchCohort = createAsyncThunk(
  'cohortDetails/fetchCohort',
  async (id: string) => {
    const result = await axios.get((process.env.REACT_APP_WORLD_MANAGEMENT_ENDPOINT as string) + `timworld/cohorts/${id}`);
    return {...result.data};
  }
);


export const fetchEditCohort = createAsyncThunk(
  'cohortDetails/editCohort',
  async ({
           id, title, minAge, maxAge, genders, locations
         }: Pick<Cohort, 'id' | 'title' | 'minAge' | 'maxAge' | 'genders' | 'locations'>
  ) => {
    await axios.patch(
      (process.env.REACT_APP_WORLD_MANAGEMENT_ENDPOINT as string) + `timworld/cohorts/${id}`,
      {
        title,
        minAge,
        maxAge,
        genderIds: genders?.map((gender) => gender.id),
        locations,
      }).catch((error: unknown) => {
      if (axios.isAxiosError(error)) {
        throw Error(error.response?.data.techInfo.title);
      } else {
        throw Error('An unexpected error occurred');
      }}
    );

    return {id, title, minAge, maxAge, genders, locations};
  }
);

export const cohortDetailsSlice = createSlice({
  name: 'cohortDetails',
  initialState,
  reducers: {
    clearDetails: (state) => {
      state.cohort = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCohort.fulfilled, (state, { payload }) => {
        state.cohort = payload;
      })
      .addCase(fetchCohort.rejected, (state) => {
        state.cohort = null;
      })
      .addCase(fetchEditCohort.fulfilled, (state, { payload }) => {
        state.cohort = payload;
      })
  },
});

export const selectCohortDetails = (state: RootState): CohortDetails | null => state.cohortDetails.cohort;

export const { clearDetails } = cohortDetailsSlice.actions;
export default cohortDetailsSlice.reducer;