import { GridRenderCellParams } from '@mui/x-data-grid';
import duration from 'dayjs/plugin/duration';
import dayjs from 'dayjs';
import useRemainingTimeColor from 'hooks/useRemainingTimeColor';
import Typography from 'components/shared/Typography/Typography';

dayjs.extend(duration);

/**
 * Props for the RenderTimeLeftCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 */
interface IRenderTimeLeftCellProps {
  props: GridRenderCellParams;
}

/**
 * Renders a "Time left" cell in a data grid.
 */
export const RenderTimeLeftCell = ({ props }: IRenderTimeLeftCellProps) => {
  const { row } = props;

  const { color, remainingTime } = useRemainingTimeColor(row.createdAt);

  if (!row.consumptionRequestId) {
    return <></>;
  }

  return (
    <Typography color={{ color: color }} variant="body2">
      {remainingTime ? remainingTime.formatted : '—'}
    </Typography>
  );
};
