import axios, { isAxiosError } from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFeeCondition, IPaymentFee, IPaymentFeesState } from './interface';
import { RootState } from 'store/store';
import { IStatus } from 'types/commonTypes';

const initialState: IPaymentFeesState = {
  fees: [],
  feesConditions: [],
  feesCount: 0,
  status: 'idle',
  addingFeeStatus: 'idle',
};

export const fetchAddPaymentFee = createAsyncThunk(
  'payments/addPaymentFee',
  async ({
    providerId,
    paymentMethodId,
    transactionType,
    paymentFeeFixed,
    paymentFeePercentage,
  }: Pick<
    IPaymentFee,
    'providerId' | 'paymentMethodId' | 'transactionType' | 'paymentFeeFixed' | 'paymentFeePercentage'
  >) => {
    const response = await axios.post((process.env.REACT_APP_PAYMENTS_ENDPOINT as string) + 'payment-adapter/fees', {
      providerId,
      paymentMethodId,
      transactionType,
      paymentFeeFixed,
      paymentFeePercentage,
    });

    return response.data;
  },
);

export const fetchPaymentFeeList = createAsyncThunk('payments/feeList', async () => {
  const response = await axios.get((process.env.REACT_APP_PAYMENTS_ENDPOINT as string) + 'payment-adapter/fees');
  return response.data;
});

export const fetchPaymentFees = createAsyncThunk(
  'payments/fees',
  async ({ page, pageSize }: { page: number; pageSize: number }, { rejectWithValue, getState }) => {
    try {
      const rootState: RootState = getState() as RootState;
      const filters = Object.keys(rootState.filters.data)
        ? Object.values(rootState.filters.data).filter((item: any) => item !== null)
        : [];

      const response = await axios.post(
        (process.env.REACT_APP_PAYMENTS_ENDPOINT as string) + 'payment-adapter/fees/find',
        {
          pageInfo: {
            page: page + 1,
            size: pageSize,
          },
          filters: filters,
        },
      );

      return response.data;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue({
          message: error.message || 'An unexpected error occurred',
          status: error.response?.status,
        });
      } else {
        return rejectWithValue({
          message: 'An unexpected error occurred',
        });
      }
    }
  },
);

export const fetchFeesConditions = createAsyncThunk('payments/fees/conditions', async () => {
  try {
    const response = await axios.get(
      (process.env.REACT_APP_PAYMENTS_ENDPOINT as string) + 'payment-adapter/fees/conditions',
    );

    return response.data;
  } catch (error: unknown) {
    return isAxiosError(error);
  }
});

export const paymentFeesSlice = createSlice({
  name: 'paymentFees',
  initialState,
  reducers: {
    setPaymentFeesStatus: (state, action: PayloadAction<IStatus>) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentFees.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPaymentFees.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.fees = payload.content;
        state.feesCount = payload.totalElements;
      })
      .addCase(fetchPaymentFees.rejected, (state) => {
        state.status = 'failed';
        state.fees = [];
        state.feesCount = 0;
      })
      .addCase(fetchAddPaymentFee.pending, (state) => {
        state.addingFeeStatus = 'loading';
      })
      .addCase(fetchAddPaymentFee.fulfilled, (state) => {
        state.addingFeeStatus = 'idle';
      })
      .addCase(fetchAddPaymentFee.rejected, (state) => {
        state.addingFeeStatus = 'failed';
      })
      .addCase(fetchFeesConditions.fulfilled, (state, { payload }) => {
        state.feesConditions = payload;
      });
  },
});

export const selectPaymentFees = (state: RootState): IPaymentFee[] => state.paymentFees.fees;
export const selectFeesConditions = (state: RootState): IFeeCondition[] => state.paymentFees.feesConditions;
export const selectTotalCount = (state: RootState): number => state.paymentFees.feesCount;
export const selectPaymentFeesStatus = (state: RootState): IStatus => state.paymentFees.status;
export const selectAddingFeeStatus = (state: RootState): IStatus => state.paymentFees.addingFeeStatus;

export const { setPaymentFeesStatus } = paymentFeesSlice.actions;

export default paymentFeesSlice.reducer;
