import { FC } from 'react';
import { ILastAdminOperationHistory, OperationActions } from 'store/slices/Payments/interface';
import Avatar from 'components/shared/Avatar/Avatar';
import Button from 'components/shared/Button/Button';
import DialogActions from 'components/shared/Dialog/DialogActions/DialogActions';
import Popover from 'components/shared/Popover/Popover';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';

interface MemberProfilePopoverProps {
  anchorEl: HTMLButtonElement | null;
  action: OperationActions | null;
  lastAdminOperationHistory?: ILastAdminOperationHistory;
  coordinates: { x: number; y: number };
  onClose: () => void;
}

export const MemberProfilePopover: FC<MemberProfilePopoverProps> = ({
  anchorEl,
  action,
  lastAdminOperationHistory,
  onClose,
  coordinates,
}) => {
  const isOpen = !!anchorEl;
  const initiatorInfo = lastAdminOperationHistory?.memberInfo;

  return (
    <Popover
      open={isOpen}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      anchorReference="anchorPosition"
      anchorPosition={{ top: coordinates.y + 20, left: coordinates.x }}
    >
      <Stack gap={2} sx={{ p: '24px 24px 0', minWidth: 248 }}>
        <Typography variant="h6">
          {action === OperationActions.REFUND ? 'Return Initiator' : 'Initiator of the suspension procedure'}
        </Typography>

        <Stack gap={1} direction="row" flex="auto">
          <Avatar
            alt={`${initiatorInfo?.firstName} ${initiatorInfo?.lastName}`}
            src={lastAdminOperationHistory?.avatarPreview?.originalLink}
          />
          <Stack flex="auto">
            <Typography variant="body2">{`${initiatorInfo?.firstName} ${initiatorInfo?.lastName}`}</Typography>
            <Typography variant="caption" color="text.secondary">
              {initiatorInfo?.role?.name}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Popover>
  );
};
