import { createSvgIcon } from '@mui/material';

const PlusIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <g id="Plus">
      <path id="Vector" d="M11.15 12.85V21.15C11.15 21.6194 11.5306 22 12 22C12.4695 22 12.85 21.6194 12.85 21.15V12.85H21.15C21.6194 12.85 22 12.4694 22 12C22 11.5306 21.6194 11.15 21.15 11.15H12.85V2.85C12.85 2.38056 12.4695 2 12 2C11.5306 2 11.15 2.38056 11.15 2.85V11.15H2.85C2.38056 11.15 2 11.5306 2 12C2 12.4694 2.38056 12.85 2.85 12.85H11.15Z" />
    </g>
  </svg>,
  'PlusIcon',
);

export default PlusIcon;
