import Button from 'components/shared/Button/Button';
import Dialog from 'components/shared/Dialog/Dialog';
import DialogActions from 'components/shared/Dialog/DialogActions/DialogActions';
import DialogContent from 'components/shared/Dialog/DialogContent/DialogContent';
import DialogTitle from 'components/shared/Dialog/DialogTitle/DialogTitle';
import Typography from 'components/shared/Typography/Typography';

interface IConfirmCancelModalProps {
  open: boolean;
  onClose: () => void;
  closeModal: () => void;
}

export const ConfirmCancelModal = ({ open, onClose, closeModal }: IConfirmCancelModalProps) => {
  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle variant="h6">Cancel adding a new tier?</DialogTitle>
      <DialogContent>
        <Typography variant="body1">Changes won’t be applied </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} variant="text" color="primary">
          Don’t cancel
        </Button>
        <Button
          onClick={() => {
            closeModal();
            onClose();
          }}
          variant="contained"
          color="primary"
        >
          Cancel adding
        </Button>
      </DialogActions>
    </Dialog>
  );
};
