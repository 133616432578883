/**
 * Represents the possible status values for a resource state.
 *
 * @type {('idle' | 'loading' | 'failed')}
 */
export type IStatus = 'idle' | 'loading' | 'failed';

/**
 * Represents permission model.
 *
 * @interface
 * @property {string} id - The permissions unique identification.
 * @property {string} name - The name of the permission.
 */
export interface IPermission {
  id: string;
  name: string;
}

/**
 * Represents the possible actions for modals.
 */
export enum ModalAction {
  ADD = 'add',
  EDIT = 'edit',
}

/**
 * Represents media information.
 *
 * @interface
 * @property {string} id - The content ID.
 * @property {string} originalLink - The URL of the original photo or video.
 * @property {number} height - The height of the media.
 * @property {number} width - The width of the media.
 * @property {string} type - The type of media (PHOTO or VIDEO).
 * @property {string} linkBlurredImage - The URL of the blurred photo or video.
 */
export interface IMedia {
  id: string;
  originalLink: string;
  height: number;
  width: number;
  type: string;
  linkBlurredImage: string;
}

/**
 * Represents location information.
 *
 * @interface
 * @property {string} id - The location ID.
 * @property {string} title - The name of the venue.
 * @property {string} subtitle - The abbreviated address of the venue.
 * @property {string} fullAddress - The full address of the venue.
 * @property {number} latitude - The latitude coordinate of the venue.
 * @property {number} longitude - The longitude coordinate of the venue.
 */
export interface ILocation {
  id: string;
  title: string;
  subtitle: string;
  fullAddress: string;
  latitude: number;
  longitude: number;
}

/**
 * Represents a media item with its associated metadata.
 *
 * @interface
 * @property {string} mediaId - A unique identifier for the media item.
 * @property {string} mediaPreviewId - A unique identifier for the media preview.
 * @property {string} mediaPreviewType - The type of media preview (e.g., 'image', 'video').
 * @property {string} mediaPreviewUrl - The URL for accessing the media preview.
 * @property {string} mediaType - The type of media (e.g., 'image', 'video', 'audio').
 * @property {string} mediaUrl - The URL for accessing the full media item.
 * @property {number} order - The order of the media item in a collection or sequence.
 * @property {boolean} uploadError - Indicates whether there was an error during the upload of the media item.
 */
export interface IMediaItem {
  mediaId: string;
  mediaPreviewId: string;
  mediaPreviewType: string;
  mediaPreviewUrl: string;
  mediaType: string;
  mediaUrl: string;
  order: number;
  uploadError: boolean;
}

/**
 * Represents gender information.
 *
 * @interface
 * @property {string} id - The record ID.
 * @property {string} name - The name of the gender.
 */
export interface IGender {
  id: string;
  name: string;
}

/**
 * Represents a filter item for filtering drawer.
 *
 * @interface
 * @property {string} title - The title or label for the filter item.
 * @property {('IN' | 'BETWEEN' | 'EQUALS' | 'OR')} type - The type of filter operation.
 * @property {string} category - The main category for the filter.
 * @property {string} subcategory - The subcategory for the filter.
 * @property {string} columnId - The ID of the column associated with the filter.
 * @property {boolean} getValuesImmediately - Indicates whether to fetch values immediately.
 * @property {string} [dataType] - The data type of the filter, if applicable.
 * @property {string} [value] - The single value for the filter (for 'EQUALS' type).
 * @property {string} [values] - The comma-separated values for the filter (for 'IN' type).
 * @property {string} [from] - The starting value for the filter range (for 'BETWEEN' type).
 * @property {boolean} [customImplementation] - If true, filter has custom implementation on backend.
 * @property {string} [to] - The ending value for the filter range (for 'BETWEEN' type).
 * @property {string[]} [belongsToKinds] - Pages or Tabs to which the filter belongs.
 * @property {boolean} [required] - Indicates whether the filter is required.
 * @property {boolean} [dropdownDisabled] - If true, possible filter options wouldn't be fetched.
 * @property {{ columnId: string; value: string }} [dependsOn] - Information for the frontend that says to display the filter if the value of another filter with *columnId* is equal to the value of *value*.
 * @property {IFilterElement[]} [elements] - The array of elements for the filter.
 * @property {IFilterElement} [elements] - The element for the filter.
 */
export interface IFilterItem {
  title: string;
  type: 'IN' | 'BETWEEN' | 'EQUALS' | 'OR';
  category: string;
  subcategory: string;
  columnId: string;
  getValuesImmediately: boolean;
  dataType?: string;
  valueType?: string;
  value?: string;
  values?: string;
  from?: string;
  customImplementation?: boolean;
  to?: string;
  belongsToKinds?: string[];
  required?: boolean;
  dropdownDisabled?: boolean;
  dependsOn?: {
    columnId: string;
    value: string;
  };
  elements?: IFilterElement[];
  element?: IFilterElement;
}

/**
 * Represents an element of a filter.
 *
 * @interface
 * @property {string} value - The value of the filter element.
 * @property {string[]} titles - The titles or labels for the filter element.
 */
export interface IFilterElement {
  value: string;
  titles: string[];
}

/**
 * Represents a sorting item for sorting functionality.
 *
 * @interface
 * * @property {string} [id] - An optional unique identifier for the sorting item.
 *  * @property {string | null} columnId - The unique identifier of the column. Can be null.
 *  * @property {string} [columnName] - An optional name for the column.
 *  * @property {string} [label] - An optional label for the sorting item.
 *  * @property {string} title - The title or label for the sorting item.
 *  * @property {('ASC' | 'DESC')} sortingType - The type of sorting direction, either 'ASC' for ascending or 'DESC' for descending.
 *  * @property {string[]} [belongsToKinds] - An optional array of kinds to which this sorting item belongs.
 *  */
export interface ISortingItem {
  id?: string;
  columnId: string | null;
  columnName?: string;
  label?: string;
  title: string;
  sortingType: 'ASC' | 'DESC';
  belongsToKinds?: string[];
}

/**
 * Represents an item in a dropdown.
 *
 * @interface
 * @property {string} [id] - The unique identifier for the dropdown item (to make filters work).
 * @property {string} value - Another unique identifier for the dropdown item.
 * @property {string[]} titles - An array of titles associated with the dropdown item.
 */
export interface IDropdownItem {
  id?: string;
  value: string;
  titles: string[];
}

/**
 * Represents a grouped item in a dropdown.
 *
 * @interface
 * @property {string} value - The unique identifier for the dropdown item.
 * @property {string} group - The group to which the dropdown item belongs.
 * @property {string[]} titles - An array of titles associated with the dropdown item.
 */
export interface IGroupedDropdownItem {
  value: string;
  group: string;
  titles: string[];
}

/**
 * Represents a refund reason in a dropdown.
 *
 * @interface
 * @property {string} reason - The unique identifier for the reason.
 * @property {string} name - The name to be displayed.
 */
export interface IRefundReason {
  reason: string;
  name: string;
}

/**
 * Represents the possible keys of LocalStorage.
 */
export enum LocalStorageKeys {
  ACCESS_TOKEN = 'accessToken',
  AVATAR = 'avatar',
  AVATAR_PREVIEW = 'avatarPreview',
  IS_AUTH = 'isAuth',
  MEMBER = 'member',
  MEMBER_ID = 'memberId',
  REFRESH_TOKEN = 'refreshToken',
  SALES_TAX_APPROVAL_CONFIRM_SKIP = 'salesTaxApprovalConfirmSkip',
  CONSUMPTION_REQUEST_SENDING_CONFIRM_SKIP = 'consumptionRequestSendingConfirmSkip',
  SPEED = 'speed',
  VOLUME = 'volume',
  MUTED_VOLUME = 'mutedVolume',
}

/**
 * Represents system's permissions.
 */
export enum Permissions {
  MEMBERS_VIEW_MEMBERS = 'MEMBERS-view_members',
  MEMBERS_CREATE_EDIT_DELETE_MEMBERS = 'MEMBERS-create_edit_delete_members',
  MEMBERS_RESET_MEMBERS_PASSWORD = 'MEMBERS-reset_members_password',
  MEMBERS_VIEW_ROLES_DETAILS = 'MEMBERS-view_roles_details',
  MEMBERS_CREATE_EDIT_DELETE_ROLES = 'MEMBERS-create_edit_delete_roles',
  USERS_VIEW_USERS_LIST = 'USERS-view_users_list',
  USERS_VIEW_DELETED_USERS = 'USERS-view_deleted_users',
  USERS_VIEW_BANNED_USERS = 'USERS-view_banned_users',
  USERS_DELETE_OR_RESTORE_USER = 'USERS-delete_or_restore_user',
  USERS_EDIT_USERS_STATUS = 'USERS-edit_users_status',
  USERS_CARD_VIEW_USERS_CARDS = 'USERS_CARD-view_users_cards',
  USERS_CARD_LATEST_ACTIVITY_AND_CONTACTS = 'USERS_CARD-latest_activity_and_contacts',
  CONTENT_MODERATION_VIEW_CONTENT_MODERATION = 'CONTENT_MODERATION-view_content_moderation',
  CONTENT_MODERATION_HIVE_MODERATION = 'CONTENT_MODERATION-hive_moderation',
  CONTENT_MODERATION_HIVE_MODERATION_SEE_ALL = 'CONTENT_MODERATION-hive_moderation_see_all',
  CONTENT_MODERATION_CONTENT_COMPLAINS_MODERATION = 'CONTENT_MODERATION-content_complains_moderation',
  CONTENT_MODERATION_CONTENT_COMPLAINS_MODERATION_SEE_ALL = 'CONTENT_MODERATION-content_complains_moderation_see_all',
  CONTENT_MODERATION_PROFILE_COMPLAINS_MODERATION = 'CONTENT_MODERATION-profile_complains_moderation',
  CONTENT_MODERATION_PROFILE_COMPLAINS_MODERATION_SEE_ALL = 'CONTENT_MODERATION-profile_complains_moderation_see_all',
  CONTENT_MODERATION_HIVE_MODERATION_RULES_MANAGEMENT = 'CONTENT_MODERATION-hive_moderation_rules_management',
  CONTENT_MODERATION_CONTENT_COMPLAINTS_MODERATION_RULES_MANAGEMENT = 'CONTENT_MODERATION-content_complaints_moderation_rules_management',
  CONTENT_MODERATION_PROFILE_COMPLAINTS_MODERATION_RULES_MANAGEMENT = 'CONTENT_MODERATION-profile_complaints_moderation_rules_management',
  WORLD_MANAGEMENT_VIEW_WORLD_MANAGEMENT = 'WORLD_MANAGEMENT-view_world_management',
  WORLD_MANAGEMENT_VIEW_COLLECTIONS = 'WORLD_MANAGEMENT-view_collections',
  WORLD_MANAGEMENT_CREATE_EDIT_DELETE_COLLECTIONS = 'WORLD_MANAGEMENT-create_edit_delete_collections',
  WORLD_MANAGEMENT_WORLD_SELECTION = 'WORLD_MANAGEMENT-world_management_selection',
  WORLD_MANAGEMENT_EDIT_COLLECTIONS_WITH_LIVE_STATUS = 'WORLD_MANAGEMENT-edit_collections_with_live_status',
  WORLD_MANAGEMENT_VIEW_BANNERS = 'WORLD_MANAGEMENT-view_banners',
  WORLD_MANAGEMENT_CREATE_BANNERS = 'WORLD_MANAGEMENT-create_banners',
  WORLD_MANAGEMENT_EDIT_BANNERS = 'WORLD_MANAGEMENT-edit_banners',
  WORLD_MANAGEMENT_DELETE_BANNERS = 'WORLD_MANAGEMENT-delete_banners',
  WORLD_MANAGEMENT_VIEW_SEGMENTS = 'WORLD_MANAGEMENT-view_segments',
  WORLD_MANAGEMENT_CREATE_EDIT_DELETE_SEGMENTS = 'WORLD_MANAGEMENT-create_edit_delete_segments',
  WORLD_MANAGEMENT_VIEW_ARCHIVE = 'WORLD_MANAGEMENT-view_archive',
  CONTENT_DISPLAY_VIEW_CONTENT_DISPLAY = 'CONTENT_DISPLAY-view_content_display',
  CONTENT_DISPLAY_MARK_HIDE_PUBLICATIONS = 'CONTENT_DISPLAY-mark_hide_publications',
  PAYMENTS_VIEW_OPERATIONS = 'PAYMENTS-view_operations',
  PAYMENTS_REFUND = 'PAYMENTS-refund',
  PAYMENTS_SUSPEND_UNBLOCK = 'PAYMENTS-suspend_unblock',
  PAYMENTS_VIEW_PAGE_BULK_PAYOUTS = 'PAYMENTS-view_page_bulk_payouts',
  PAYMENTS_MAKE_A_PAYOUT = 'PAYMENTS-make_a_payout',
  PAYMENTS_MAKE_A_PAYOUT_FOR_A_DRAFT = 'PAYMENTS-make_a_payout_for_a_draft',
  PAYMENTS_DELETE_FAILED_PAYOUT = 'PAYMENTS-delete_failed_payout',
  SETTINGS_VIEW_PAGE_TARIFF_GROUPS = 'SETTINGS-view_page_tariff_groups',
  SETTINGS_ADD_TARIFF_GROUP = 'SETTINGS-add_tariff_group',
  SETTINGS_DELETE_TARIFF_GROUP = 'SETTINGS-delete_tariff_group',
  SETTINGS_VIEW_TARIFFS_IN_GROUP = 'SETTINGS-view_tariffs_in_group',
  SETTINGS_ADD_TARIFF_IN_GROUP = 'SETTINGS-add_tariff_in_group',
  SETTINGS_EDIT_TARIFF_IN_GROUP = 'SETTINGS-edit_tariff_in_group',
  SETTINGS_DELETE_TARIFF_IN_GROUP = 'SETTINGS-delete_tariff_in_group',
  SETTINGS_CHANGE_STATUS_FOR_A_TARIFF_GROUP = 'SETTINGS-change_status_for_a_tariff_group',
  SETTINGS_VIEW_PAYMENT_FEES = 'SETTINGS-view_payment_fees',
  SETTINGS_ADD_PAYMENT_FEE = 'SETTINGS-add_payment_fee',
  SETTINGS_EDIT_PAYMENT_FEE = 'SETTINGS-edit_payment_fee',
  SETTINGS_DELETE_PAYMENT_FEE = 'SETTINGS-delete_payment_fee',
  SETTINGS_CHANGE_PAYMENT_FEE_STATUS = 'SETTINGS-change_payment_fee_status',
  SETTINGS_VIEW_TAXES_RULES = 'SETTINGS-view_taxes_rules',
  SETTINGS_ADD_TAXES_RULE = 'SETTINGS-add_taxes_rule',
  SETTINGS_EDIT_SALES_TAX = 'SETTINGS-edit_sales_tax',
  SETTINGS_CHANGE_TAXES_RULE_STATUS = 'SETTINGS-change_taxes_rule_status',
  SETTINGS_VIEW_TAXES_CODES = 'SETTINGS-view_taxes_codes',
  SETTINGS_ADD_TAX_CODE = 'SETTINGS-add_tax_code',
  SETTINGS_CHANGE_TAX_CODE_STATUS = 'SETTINGS-change_tax_code_status',
  SETTINGS_VIEW_IN_APP_TIERS = 'SETTINGS-view_in_app_tiers',
  SETTINGS_ADD_IN_APP_TIER = 'SETTINGS-add_in_app_tier',
  SETTINGS_CHANGE_IN_APP_TIER_STATUS = 'SETTINGS-change_in_app_tier_status',
  SETTINGS_VIEW_PRICES_RESTRICTIONS_PAGE = 'SETTINGS-view_prices_restrictions_page',
  SETTINGS_EDIT_PRICE_RESTRICTION = 'SETTINGS-edit_price_restriction',
  SETTINGS_VIEW_WITHDRAWAL_RESTRICTIONS_PAGE = 'SETTINGS-view_withdrawal_restrictions_page',
  SETTINGS_EDIT_WITHDRAWAL_RESTRICTION = 'SETTINGS-edit_withdrawal_restriction',
  SALES_TAX_MODERATION_VIEW_UNRESOLVED_PAGE = 'SALES_TAX_MODERATION-view_unresolved_page',
  SALES_TAX_MODERATION_UNRESOLVED_APPROVE = 'SALES_TAX_MODERATION-unresolved_approve',
  SALES_TAX_MODERATION_UNRESOLVED_TAX_CHANGE_CODE = 'SALES_TAX_MODERATION-unresolved_tax_change_code',
  SALES_TAX_MODERATION_UNRESOLVED_ASSIGN_TO_SUPERVISOR = 'SALES_TAX_MODERATION-unresolved_assign_to_supervisor',
  SALES_TAX_MODERATION_VIEW_SOLVED_PAGE = 'SALES_TAX_MODERATION-view_solved_page',
  SALES_TAX_MODERATION_EDIT_SOLVED_DECISION = 'SALES_TAX_MODERATION-edit_solved_decision',
  REQUESTS_REFUNDS_DISPUTES_VIEW_OPEN_PAGE = 'REQUESTS_REFUNDS_DISPUTES-view_open_page',
  REQUESTS_REFUNDS_DISPUTES_RESOLVE_OPEN_REQUEST = 'REQUESTS_REFUNDS_DISPUTES-resolve_open_request',
  REQUESTS_REFUNDS_DISPUTES_VIEW_CLOSED_PAGE = 'REQUESTS_REFUNDS_DISPUTES-view_closed_page',
  USERS_VIEW_CASH_MOVEMENT_ON_A_WALLET = 'USERS-view_cash_movement_on_a_wallet',
  USERS_VIEW_CASH_MOVEMENT_ON_A_WALLET_BY_OTP_REQUEST = 'USERS-view_cash_movement_on_a_wallet_by_otp_request',
  USERS_VIEW_USER_PAID_BLOCK = 'USERS-view_user_paid_block',
  USERS_VIEW_USER_RECIPIENT_BLOCK = 'USERS-view_user_recipient_block',
  USERS_BLOCK_WALLET = 'USERS-block_wallet',
}

/**
 * Enumerates types for entity.
 *
 * @enum {string}
 * @readonly
 */
export enum EntityType {
  ABOUT_ME = 'ABOUT_ME',
  AVATAR = 'AVATAR',
  COMMENT = 'COMMENT',
  MOOD = 'MOOD',
}

/**
 * Enumerates types for content.
 *
 * @enum {string}
 * @readonly
 */
export enum ContentType {
  ABOUT_ME = 'ABOUT_ME',
  AVATAR = 'AVATAR',
  BENEFITS = 'BENEFITS',
  DESCRIPTION = 'DESCRIPTION',
  IMAGE = 'IMAGE',
  PHOTO = 'PHOTO',
  TITLE = 'TITLE',
  VIDEO = 'VIDEO',
}

export interface StatusError {
  message: string;
  status?: number;
}

/**
 * Enumerates aura levels for user.
 *
 * @enum {string}
 * @readonly
 */
export enum AuraLevel {
  BABY = 'BABY',
  MAKING = 'MAKING',
  HUSTLING = 'HUSTLING',
  BALLING = 'BALLING',
}
