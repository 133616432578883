import React, { ReactNode } from 'react';

import './index.scss';

interface IProps {
  children: ReactNode;
}

const AuthLayout = ({ children }: IProps) => {
  return (
    <div className="authLayout">
      {children}
    </div>
  );
}

export default AuthLayout;
