import React, { useCallback, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { enqueueSnackbar, VariantType } from "notistack";
import Divider from "components/shared/Divider/Divider";
import TextField from "components/shared/TextField/TextField";
import FormHelperText from "components/shared/FormHelperText/FormHelperText";
import Button from "components/shared/Button/Button";
import Modal from "components/shared/Modal/Modal";
import getValidationSchema from "components/WorldManagement/Banners/BannersModal/utils/getValidationSchema";
import { fetchAddBanner, selectWorldStatus } from "store/worldManagementSlice";
import IconButton from "components/shared/IconButton/IconButton";
import DeleteIcon from "assets/img/DeleteIcon";
import ImageCrop from "components/shared/ImageCrop/ImageCrop";
import ZoomOuttaIcon from "assets/img/ZoomOuttaIcon";
import Slider from "components/shared/Slider/Slider";
import ZoomInIcon from "assets/img/ZoomInIcon";
import Dropzone from "components/shared/Dropzone/Dropzone";
import { Area } from "react-easy-crop/types";
import { getCroppedImg } from "utils/canvasUtils";
import { blobToFile } from "utils/blobToFile";
import AddBannerModalCancel from "components/WorldManagement/Banners/BannersModal/AddBanner/AddBannerModalCancel";
import Alert from "components/shared/Alert/Alert";

import './index.scss';

interface IProps {
  onClose(): void;
}

interface Image {
  type?: string;
  src: string;
  file: File;
}

const BYTE = 1;
const KILOBYTE = 1024 * BYTE;
const MEGABYTE = 1024 * KILOBYTE;
const ACCEPTED_FILES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
];

const AddBannerModal = ({ onClose }: IProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [image, setImage] = useState<Image | null>(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [zoom, setZoom] = useState(1);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const dispatch = useAppDispatch();

  const status = useAppSelector(selectWorldStatus);
  const schema = useMemo(() => getValidationSchema('add'),[]);
  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<{ cover: File | null, coverPreview: File | null, name: string, title?: string, subtitle?: string }>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      title: '',
      subtitle: '',
      cover: null,
      coverPreview: null,
    }
  });

  const onSubmit = async (data: any) => {
    try {
      const croppedImage = await getCroppedImg(
        image?.src ?? '',
        image?.type ?? '',
        croppedAreaPixels ?? {
          height: 244,
          width: 366,
          y: 0,
          x: 0,
        },
        0,
      )
      if (croppedImage) {
        const fileCroppedImage = blobToFile(croppedImage, image?.file.name + 'preview');
        void trigger();

        const result = await dispatch(fetchAddBanner( {
          cover: data.cover!,
          coverPreview: fileCroppedImage,
          title: data.title,
          subtitle: data.subtitle,
          name: data.name
        }));
        result.meta.requestStatus === 'fulfilled' && onClose();
        result.meta.requestStatus === 'fulfilled' && enqueueSnackbar(`“${data.name}” banner has been created`, { variant: 'success' as VariantType });
        if (result.meta.requestStatus === 'rejected') {
          setErrorMessage('Banner creation error, try again later');
        }
      }
    } catch (e) {
      setErrorMessage('Banner creation error, try again later');
      return;
    }
  };

  const onLoadImage = (files: File[]) => {
    if (files && files[0]) {
      const img = new Image();
      const blobUrl: string = URL.createObjectURL(files[0]);
      img.onload = () => {
        if (img.width > 5000 || img.height > 5000) {
          setErrorMessage('Files larger than 30 Mb and bigger than 5000px are not allowed');
        } else {
          setImage({
            file: files[0],
            src: blobUrl,
            type: files[0].type
          });
          setValue('cover', files[0]);
          void trigger('cover');
        }
      };
      img.src = blobUrl;
    }
  };

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  return (
    <form
      className='addBannerModal'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="addBannerModal-header">
        Add new banner
      </div>
      <div
        className={`addBannerModal-content ${(errorMessage) ? 'addBannerModal-content_overflow': ''}`}>
        <div>
          <TextField
            {...register('name')}
            error={!!errors.name}
            name="name"
            label="Name*"
            defaultValue={''}
            size="small"
            fullWidth={true}
            onBlur={() => trigger('name')}
          />
          {errors.name && <FormHelperText error>{errors.name?.message as string}</FormHelperText>}
        </div>

        <Divider orientation="horizontal" />

        <TextField
          {...register('title')}
          name="title"
          label="Title"
          defaultValue={''}
          size="small"
          fullWidth={true}
        />
        <TextField
          {...register('subtitle')}
          name="subtitle"
          label="Subtitle"
          defaultValue={''}
          size="small"
          fullWidth={true}
        />

        <div className="addBannerModal-content-name">Cover*</div>
        <div className="addBannerModal-cover">
          <div className="addBannerModal-cover-uploadArea">
            {image !== null ? (
              <div className="addBannerModal-cover-uploadArea_containerCropper">
                <IconButton
                  className="addBannerModal-cover-uploadArea_deleteButton"
                  onClick={() => {
                    setImage(null);
                    setValue('cover', null);
                    setValue('coverPreview', null);
                    void trigger('cover');
                  }}
                >
                  <DeleteIcon />
                </IconButton>
                <div className="addBannerModal-cover-uploadArea_crop">
                  <ImageCrop
                    image={image.src}
                    aspect={4/3}
                    zoom={zoom}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                    style={{
                      containerStyle: { borderRadius: '8px' },
                    }}
                  />
                </div>
                <div className="addBannerModal-cover-uploadArea_controls">
                  <IconButton
                    onClick={() => setZoom((prevState) => prevState <= 1 ? prevState : Number(prevState - 0.1))}
                  >
                    <ZoomOuttaIcon />
                  </IconButton>
                  <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    size="small"
                    aria-labelledby="Zoom"
                    onChange={(e, zoom) => setZoom(Number(zoom))}
                    classes={{ root: "slider" }}
                  />
                  <IconButton
                    onClick={() => setZoom((prevState) => prevState >= 3 ? prevState : Number(prevState + 0.1))}
                  >
                    <ZoomInIcon />
                  </IconButton>
                </div>
              </div>
            ) : (
              <div className="addBannerModal-cover-uploadArea_containerDropzone">
              <span className="addBannerModal-cover-uploadArea_title">
                One .jpg, .jpeg or .png file only
              </span>
                <Dropzone
                  initialFiles={undefined}
                  dropzoneClass="addBannerModal-cover-uploadArea_dropzone"
                  showAlerts={false}
                  showPreviews={false}
                  showPreviewsInDropzone={false}
                  onDrop={onLoadImage}
                  onDropRejected={(rejectedFiles) => {
                    setErrorMessage('');
                    if (rejectedFiles.length > 1) {
                      setErrorMessage('Only one file allowed');
                      return;
                    }
                    if (!ACCEPTED_FILES.includes(rejectedFiles[0].type)) {
                      setErrorMessage('Unsupported file extension');
                      return;
                    }
                    if (rejectedFiles[0].size > MEGABYTE * 30) {
                      setErrorMessage('Files larger than 30 Mb and bigger than 5000px are not allowed');
                    }
                  }}
                  getFileAddedMessage={() => {
                    setErrorMessage('');
                    return '';
                  }}
                  dropzoneText={'Drag and drop a file here or click'}
                  acceptedFiles={ACCEPTED_FILES}
                  filesLimit={1}
                  maxFileSize={30 * MEGABYTE}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="addBannerModal-footer">
        {errorMessage && errorMessage !== '' && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
        <div className="addBannerModal-footer-buttons">
          <Button
            onClick={() => {
              const values = getValues();
              if (values.name || values.title || values.subtitle || image) {
                setIsOpenModal(true);
                return;
              }
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!isValid || !image}
            type="submit"
            variant="contained"
            color="primary"
          >
            Add Banner
          </Button>
        </div>
      </div>
      <Modal customstyle={{ minHeight: 188 }} open={isOpenModal}>
        <AddBannerModalCancel closeModal={() => setIsOpenModal(false)} onClose={onClose}></AddBannerModalCancel>
      </Modal>
    </form>
  )
};

export default AddBannerModal;
