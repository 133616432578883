import React, { useMemo } from "react";
import { Member } from "store/profileSlice";
import MemberAvatar from "components/Profile/MemberProfile/MemberAvatar/MemberAvatar";
import Button from "components/shared/Button/Button";
import List from "components/shared/List/List";
import ListItem from "components/shared/List/ListItem/ListItem";
import ListItemText from "components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText";
import { AvatarAction } from "containers/Profile/Profile";
import { IMedia } from "types/commonTypes";

import './index.scss';

interface Props {
  memberProfile: Member;
  avatarPreview: IMedia;
  setShowChangePasswordModal(): void;
  setShowGlobalLogoutModal(): void;
  setShowSetAvatar(action: AvatarAction): void;
}

const MemberProfile = ({
  memberProfile,
  avatarPreview,
  setShowChangePasswordModal,
  setShowGlobalLogoutModal,
  setShowSetAvatar,
}: Props) => {
  const registered = useMemo(() => {
    return new Date(memberProfile.registered ?? '').toLocaleString('en-AU', {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
  }, []);

  return (
    <div className="profileCard">
      <div className="profileCard-generalInfo">
        <MemberAvatar avatarPreview={avatarPreview} setShowSetAvatar={setShowSetAvatar} />
        <div className="profileCard-generalInfo-info">
          <div className="profileCard-title">Info</div>
          <div className="profileCard-generalInfo-info-data">
            <List>
              <ListItem>
                <ListItemText
                  primary="Name"
                  secondary={memberProfile.firstName}
                />
                <ListItemText
                  primary="Surname"
                  secondary={memberProfile.lastName}
                />
                <ListItemText
                  primary="Role"
                  secondary={memberProfile.role.name}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Email"
                  secondary={memberProfile.email}
                />
                <ListItemText
                  primary="Registration date"
                  secondary={registered.toLowerCase()}
                />
              </ListItem>
            </List>
          </div>
        </div>
      </div>
      <div className="profileCard-controls">
        <div className="profileCard-title">Controls</div>
        <div className="profileCard-controls-buttonGroup">
          <Button
            variant="outlined"
            onClick={() => setShowChangePasswordModal()}
          >
            Change password
          </Button>
          <Button
            variant="text"
            onClick={() => setShowGlobalLogoutModal()}
          >
            Log out from ALL DEVICES
          </Button>
        </div>
      </div>
    </div>
  )
};

export default MemberProfile;
