const VolumeMuteIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.68 2.23C20.98 1.93 21.47 1.93 21.77 2.23C22.08 2.53 22.08 3.01 21.77 3.31L3.31 21.77C3.16 21.92 2.97 22 2.77 22C2.57 22 2.38 21.92 2.22 21.78C1.92 21.48 1.92 20.99 2.22 20.69L5.16 17.75H5C2.58 17.75 1.25 16.42 1.25 14V10C1.25 7.58 2.58 6.25 5 6.25H6.43C6.66 6.25 6.89 6.18 7.09 6.06L10 4.24C11.46 3.33 12.89 3.17 14.01 3.79C15.07 4.38 15.67 5.58 15.73 7.18L20.68 2.23Z" />
      <path d="M19.38 19.1C19.52 19.2 19.67 19.25 19.83 19.25C20.06 19.25 20.29 19.15 20.43 18.95C22.89 15.67 23.43 11.29 21.84 7.51002C21.68 7.13002 21.24 6.95002 20.86 7.11002C20.48 7.27002 20.3 7.71002 20.46 8.09002C21.84 11.38 21.37 15.2 19.23 18.05C18.98 18.38 19.05 18.85 19.38 19.1Z" />
      <path d="M17.55 16.6C17.69 16.7 17.84 16.75 18 16.75C18.23 16.75 18.45 16.65 18.6 16.46C20.17 14.36 20.52 11.55 19.5 9.13001C19.34 8.75001 18.9 8.57001 18.52 8.73001C18.14 8.89001 17.96 9.33001 18.12 9.71001C18.93 11.64 18.66 13.87 17.4 15.55C17.15 15.88 17.22 16.35 17.55 16.6Z" />
      <path d="M14.04 12.96C14.67 12.33 15.75 12.78 15.75 13.67V16.6C15.75 18.32 15.13 19.61 14.02 20.23C13.57 20.48 13.07 20.6 12.55 20.6C11.75 20.6 10.89 20.33 10.01 19.78L9.37001 19.38C8.83001 19.04 8.74001 18.28 9.19001 17.83L14.04 12.96Z" />
    </svg>
  )
}

export default VolumeMuteIcon;