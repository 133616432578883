import { MouseEvent } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { ITaxCode, TaxesStatuses } from 'store/slices/Settings/interface';
import { possibleTaxStatuses } from '../constants';
import { checkPermissions } from 'utils/checkPermissions';
import { selectUserPermissions } from 'store/authSlice';
import { useAppSelector } from 'store/hooks';
import ChevronDownIcon from 'assets/img/ChevronDownIcon';
import ChevronUpIcon from 'assets/img/ChevronUpIcon';
import Typography from 'components/shared/Typography/Typography';

/**
 * Props for the RenderStatusSelect component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 * @property {string[] } disablePermission - If `true`, it's not allowed to change status.
 * @property {boolean} isMenuOpen - If `true`, the status menu is shown.
 * @property {ITaxCode | null} [selectedCellData] - Row data for selected cell.
 * @property {(e: MouseEvent<HTMLDivElement>, data: any) => void} onClick - Click event handler.
 */
interface IRenderStatusSelectProps {
  props: GridRenderCellParams;
  disablePermission: string[];
  isMenuOpen: boolean;
  selectedCellData?: ITaxCode | null;
  onClick: (e: MouseEvent<HTMLDivElement>, data: any) => void;
}

/**
 * Renders a status select cell in a data grid.
 */
export const RenderStatusSelect = ({
  props,
  disablePermission,
  isMenuOpen,
  selectedCellData,
  onClick,
}: IRenderStatusSelectProps) => {
  const { row } = props;
  const userPermissions = useAppSelector(selectUserPermissions);

  if (!row.id && !row.salesTaxRuleId) {
    return <></>;
  }

  const hasAccess = checkPermissions(userPermissions, disablePermission);
  const currentStatus = possibleTaxStatuses.find((el) => row.status?.toLowerCase() === el.status.toLowerCase());

  const handleClick = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    onClick(e, row);
  };

  const isDefaultStatus = row.status === TaxesStatuses.DEFAULT;
  const isDisabledChange = hasAccess && !isDefaultStatus;

  return (
    <div
      onClick={(e) => isDisabledChange && handleClick(e)}
      className={isDisabledChange ? 'taxes-table__status-select' : ''}
      style={{ color: currentStatus?.color }}
    >
      <Typography variant="chip" color={{ color: currentStatus?.color }}>
        {row.status?.replace(row.status, currentStatus?.title)}
      </Typography>
      {isDisabledChange && (selectedCellData?.id === row.id && isMenuOpen ? <ChevronUpIcon /> : <ChevronDownIcon />)}
    </div>
  );
};
