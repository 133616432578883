import React, { useState } from "react";
import IconButton from "components/shared/IconButton/IconButton";
import ContextIcon from "assets/img/ContextIcon";
import Menu from "components/shared/Menu/Menu";
import MenuItem from "components/shared/Menu/MenuItem/MenuItem";
import Divider from "components/shared/Divider/Divider";
import WorldSendIcon from "assets/img/WorldSendIcon";
import CalendarExpiredIcon from "assets/img/CalendarExpiredIcon";
import ArchiveIcon from "assets/img/ArchiveIcon";
import { Banner } from "store/bannerDetailsSlice";
import { Collection } from "store/collectionDetailsSlice";
import EditIcon from "assets/img/EditIcon";
import DeleteIcon from "assets/img/DeleteIcon";
import Tooltip from "components/shared/Tooltip/Tooltip";
import CheckIcon from "assets/img/CheckIcon";
import { Cohort } from "store/cohortDetailsSlice";

import './index.scss';

interface IProps {
  item: Collection | Banner | Cohort;
  setArchiveCollections?: (item: Collection) => void;
  setPublishCollections?: (item: Collection) => void;
  setUnpublishCollections?: (item: Collection) => void;
  setArchiveBanners?: (item: Banner) => void;
  setExpiredBanners?: (item: Banner) => void;
  setLinkedCollections?: (item: Banner) => void;
  setEditCohorts?: (itemId: string) => void;
  setDeleteCohorts?: (item: Cohort) => void;
}

const ContextMenu = ({
  item,
  setArchiveCollections,
  setPublishCollections,
  setUnpublishCollections,
  setArchiveBanners,
  setExpiredBanners,
  setLinkedCollections,
  setEditCohorts,
  setDeleteCohorts,
}: IProps) => {
  const [anchorElContextMenu, setAnchorElContextMenu] = useState<null | HTMLElement>(null);
  const openContextMenu = Boolean(anchorElContextMenu);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElContextMenu(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = () => {
    setAnchorElContextMenu(null);
  };

  return (
    <div className="worldContextMenu">
      <IconButton
        className='worldAction'
        id="basic-button"
        aria-controls={openContextMenu ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openContextMenu ? 'true' : undefined}
        onClick={handleClick}
      >
        <ContextIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        className="worldContextMenu-menu"
        aria-labelledby="basic-button"
        anchorEl={anchorElContextMenu}
        open={openContextMenu}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {setPublishCollections && item.status === 'INCOMPLETE' &&
          <Tooltip title="You can not publish incomplete collection" placement="top-start">
            <span>
              <MenuItem
                disabled={true}
                onClick={() => {
                  handleClose();
                  setPublishCollections(item as Collection);
                }}
              >
                <CheckIcon />
                Publish now
              </MenuItem>
            </span>
          </Tooltip>
        }
        {setPublishCollections && (item.status === 'READY' || item.status === 'SCHEDULED') &&
          <MenuItem
            disabled={false}
            onClick={() => {
              handleClose();
              setPublishCollections(item as Collection);
            }}
          >
            <CheckIcon />
            Publish now
          </MenuItem>
        }
        {setUnpublishCollections && item.status === 'LIVE' &&
          <MenuItem
            onClick={() => {
              handleClose();
              setUnpublishCollections(item as Collection);
            }}
          >
            <CheckIcon />
            Unpublish now
          </MenuItem>
        }
        {setLinkedCollections &&
          <MenuItem
            disabled={item.status === 'INCOMPLETE'}
            onClick={() => {
              handleClose();
              setLinkedCollections(item as Banner);
            }}
          >
            <WorldSendIcon />
            Manage linked collections
          </MenuItem>
        }
        {setEditCohorts && item.status === 'ACTIVE' &&
          <Tooltip title="You can not edit cohorts in an active status" placement="top-start">
            <span>
              <MenuItem
                disabled={true}
                onClick={() => {
                  handleClose();
                  setEditCohorts((item as Cohort).id);
                }}
              >
                <EditIcon />
                Edit
              </MenuItem>
            </span>
          </Tooltip>
        }
        {setEditCohorts && item.status !== 'ACTIVE' &&
          <MenuItem
            disabled={false}
            onClick={() => {
              handleClose();
              setEditCohorts((item as Cohort).id);
            }}
          >
            <EditIcon />
            Edit
          </MenuItem>
        }
        {item.status !== 'DEACTIVATED' && <Divider flexItem />}
        {setArchiveCollections && item.status !== 'LIVE' &&
          <MenuItem
            disabled={false}
            onClick={() => {
              handleClose();
              setArchiveCollections(item as Collection);
            }}
          >
            <ArchiveIcon />
            Archive
          </MenuItem>
        }
        {setArchiveCollections && item.status === 'LIVE' &&
          <Tooltip title="You can not archive live collection" placement="top-start">
            <span>
              <MenuItem
                disabled={true}
                onClick={() => {
                  handleClose();
                  setArchiveCollections(item as Collection);
                }}
              >
                <ArchiveIcon />
                Archive
              </MenuItem>
            </span>
          </Tooltip>
        }
        {setExpiredBanners &&
          <MenuItem
            disabled={item.status === 'EXPIRED'}
            onClick={() => {
              handleClose();
              setExpiredBanners(item as Banner);
            }}
          >
            <CalendarExpiredIcon />
            Set as expired
          </MenuItem>
        }
        {setArchiveBanners &&
          <MenuItem
            disabled={item.status === 'LIVE'}
            onClick={() => {
              handleClose();
              setArchiveBanners(item as Banner);
            }}
          >
            <ArchiveIcon />
            Archive
          </MenuItem>
        }
        {setDeleteCohorts && (item as Cohort).hasCompilation &&
          <Tooltip title="You can not delete cohorts linked to collections" placement="top-start">
            <span>
              <MenuItem
                disabled={true}
                onClick={() => {
                  handleClose();
                  setDeleteCohorts(item as Cohort);
                }}
              >
                <DeleteIcon />
                Delete
              </MenuItem>
            </span>
          </Tooltip>
        }
        {setDeleteCohorts && !(item as Cohort).hasCompilation &&
          <MenuItem
            disabled={false}
            onClick={() => {
              handleClose();
              setDeleteCohorts(item as Cohort);
            }}
          >
            <DeleteIcon />
            Delete
          </MenuItem>
        }
      </Menu>
    </div>
  )
};

export default ContextMenu;
