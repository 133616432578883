import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from "store/store";
import axios from "axios";
import {ILocation, IMedia, IStatus} from "types/commonTypes";
import { Banner } from "store/bannerDetailsSlice";

export type CollectionStatus = 'LIVE' | 'INCOMPLETE' | 'READY' | 'DEACTIVATED' | 'SCHEDULED' | 'ARCHIEVED';

export interface Collection {
  id: string;
  title: string;
  cohortId: string;
  cohortTitle: string;
  status: CollectionStatus;
  creatorName: string;
  creatorLastName: string;
  updatedAt: Date;
  publishDateTime: Date | null;
  expiredDateTime: Date | null;
}

export type EntityStatus = 'ACTIVE' | 'SPARE' | 'UNAVAILABLE';
export type PriceOption = 'FIXED' | 'FLEXIBLE' | 'FREE';

/* Структура БД на бэке вот такая - compilation_entity: id, entity_id, entity_type
* У всех сущностей уникальный id для коллекции = на фронт передается compilationEntityId
* entity_id - внутренний айдишник энтити
* */

export interface OrderedItem {
  order: number;
  entityStatus: EntityStatus;
  compilationEntityId: string;
  entityId: string;
  createdAt: Date;
}

export type OrderedBanner = Omit<Banner, 'id'> & OrderedItem;

export interface OrderedHero extends OrderedItem {
  firstName: string;
  lastName: string;
  avatar: IMedia;
  occupation: string;
}

export interface OrderedOffer extends OrderedItem {
  title: string;
  format: 'OFFLINE' | 'ONLINE';
  cover: IMedia;
  price: string;
  priceOption: PriceOption;
  offerCreator: {
    profileId: string;
    firstName: string;
    lastName: string;
    avatar: IMedia;
  }
}

export interface OrderedWish extends OrderedItem {
  title: string;
  format: 'OFFLINE' | 'ONLINE';
  cover: IMedia;
  price: string;
  priceOption: PriceOption;
  wishCreator: {
    profileId: string;
    firstName: string;
    lastName: string;
    avatar: IMedia;
  }
}

export interface OrderedEvent extends OrderedItem {
  title: string;
  format: 'OFFLINE' | 'ONLINE';
  cover: IMedia;
  price: string;
  priceOption: PriceOption;
  eventCreator: {
    profileId: string;
    firstName: string;
    lastName: string;
    avatar: IMedia;
  }
  startedAt: Date;
  location: ILocation;
}

export interface ICollectionDetails extends Collection {
  banners: OrderedBanner[];
  heroes: OrderedHero[];
  offers: OrderedOffer[];
  wishes: OrderedWish[];
  events: OrderedEvent[];
}

export type OrderedEntity = OrderedBanner | OrderedHero | OrderedOffer | OrderedWish | OrderedEvent;

interface CollectionDetailsState {
  collection: ICollectionDetails | null;
  status: IStatus;
}

const initialState: CollectionDetailsState = {
  collection: null,
  status: 'idle',
};

export const fetchCollection = createAsyncThunk(
  'collectionDetails/collectionDetails',
  async (collectionId: string) => {
    const response = await axios.get(
      (process.env.REACT_APP_WORLD_MANAGEMENT_ENDPOINT as string) + `timworld/compilations/${collectionId}`,
      {
        params: {
          additionalInfo: 'BANNER,HERO,OFFER,WISH,EVENT',
        }
      });
    return {...response.data};
  }
);

export const fetchEditCollection = createAsyncThunk(
  'collectionDetails/editCollection',
  async ({ id, cohortId, title, banners, heroes, offers, wishes, events, ...rest }: ICollectionDetails) => {
    const response = await axios.patch(
      (process.env.REACT_APP_WORLD_MANAGEMENT_ENDPOINT as string) + `timworld/compilations/${id}`,
      {
        cohortId,
        title,
        banners: banners.map((banner: OrderedBanner) => {
          return {
            id: banner.compilationEntityId,
            order: banner.order,
            entityStatus: banner.entityStatus,
          }
        }),
        heroes: heroes.map((hero: OrderedHero) => {
          return {
            id: hero.compilationEntityId,
            order: hero.order,
            entityStatus: hero.entityStatus,
          }
        }),
        offers: offers.map((offer: OrderedOffer) => {
          return {
            id: offer.compilationEntityId,
            order: offer.order,
            entityStatus: offer.entityStatus,
          }
        }),
        wishes: wishes.map((wish: OrderedWish) => {
          return {
            id: wish.compilationEntityId,
            order: wish.order,
            entityStatus: wish.entityStatus,
          }
        }),
        events: events.map((event: OrderedEvent) => {
          return {
            id: event.compilationEntityId,
            order: event.order,
            entityStatus: event.entityStatus,
          }
        }),
      });
    return { id, cohortId, title, banners, heroes, offers, wishes, events, ...rest };
  }
);

// сразу удаляться сущность не будет, а только при сохранении коллекции.
// export const fetchDeleteEntities = createAsyncThunk(
//   'collectionDetails/deleteEntities',
//   async ({ collectionId, entitiesIds, entityType }: { collectionId: string, entitiesIds: string[], entityType: 'BANNER' | 'HERO' | 'OFFER' | 'WISH' | 'EVENT' }) => {
//     const response = await axios.delete(
//       (process.env.REACT_APP_WORLD_MANAGEMENT_ENDPOINT as string) + `timworld/compilations/elements`,
//       {
//         data: {
//           compilationId: collectionId,
//           compilationEntityIds: entitiesIds,
//         }
//       });
//     return { entitiesIds, entityType };
//   }
// );

export const collectionDetailsSlice = createSlice({
  name: 'collectionDetails',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCollection.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCollection.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.collection = payload;
      })
      .addCase(fetchCollection.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchEditCollection.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.collection = payload;
      })
      // .addCase(fetchDeleteEntities.fulfilled, (state, { payload }) => {
        // if (state.collection) {
        //   switch (payload.entityType) {
        //     case "BANNER":
        //       state.collection.banners = state.collection?.banners.filter(
        //         ({compilationEntityId}) => !payload.entitiesIds.includes(compilationEntityId)
        //       );
        //       break;
        //     case "HERO":
        //       state.collection.heroes = state.collection?.heroes.filter(
        //         ({compilationEntityId}) => !payload.entitiesIds.includes(compilationEntityId)
        //       );
        //       break;
        //     case "OFFER":
        //       state.collection.offers = state.collection?.offers.filter(
        //         ({compilationEntityId}) => !payload.entitiesIds.includes(compilationEntityId)
        //       );
        //       break;
        //     case "WISH":
        //       state.collection.wishes = state.collection?.wishes.filter(
        //         ({compilationEntityId}) => !payload.entitiesIds.includes(compilationEntityId)
        //       );
        //       break;
        //     case "EVENT":
        //       state.collection.events = state.collection?.events.filter(
        //         ({compilationEntityId}) => !payload.entitiesIds.includes(compilationEntityId)
        //       );
        //       break;
        //   }
        // }
      // })
  },
});

export const selectCollectionDetails = (state: RootState): ICollectionDetails | null => state.collectionDetails.collection;
export const selectCollectionStatus = (state: RootState): IStatus => state.collectionDetails.status;

export default collectionDetailsSlice.reducer;
