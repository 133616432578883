import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { GridColDef, GridEventListener } from '@mui/x-data-grid';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, useGridApiRef } from '@mui/x-data-grid-pro';
import { useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import startCase from 'lodash/startCase';
import { IOperation } from 'store/slices/Payments/interface';
import { GridRowId } from '@mui/x-data-grid/models/gridRows';
import { DataGridProProps } from '@mui/x-data-grid-pro/models/dataGridProProps';
import { LocalStorageKeys } from 'types/commonTypes';
import { ConsumptionRequestsRoutes } from 'routes/enum';
import { currencyFormatter } from 'utils/currencyFormatter';
import useRemainingTimeColor from 'hooks/useRemainingTimeColor';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchRefundReasons, selectRefundReasons } from 'store/slices/Settings/dictionariesSlice';
import {
  fetchConsumptionRequestCases,
  fetchConsumptionsDictionaries,
  fetchSendConsumptionRequest,
  selectConsumptionDictionaries,
} from 'store/slices/ConsumptionRequests/consumptionRequestsSlice';
import { selectOperationDetails } from 'store/slices/Payments/operationsSlice';
import Button from 'components/shared/Button/Button';
import Divider from 'components/shared/Divider/Divider';
import FormHelperText from 'components/shared/FormHelperText/FormHelperText';
import Grid from 'components/shared/Grid/Grid';
import ListItem from 'components/shared/List/ListItem/ListItem';
import ListItemText from 'components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText';
import RenderCellWithCopy from 'components/shared/DataGrid/RenderCellWithCopy/RenderCellWithCopy';
import RenderDetailPanelToggle from 'components/shared/DataGrid/RenderDetailPanelToggle/RenderDetailPanelToggle';
import RenderOperationTypeCell from 'components/shared/DataGrid/RenderOperationTypeCell/RenderOperationTypeCell';
import Select, { ISelectOptions } from 'components/shared/Select/Select';
import SimpleGrid from 'components/shared/SimpleGrid/SimpleGrid';
import Stack from 'components/shared/Stack/Stack';
import TextField from 'components/shared/TextField/TextField';
import Typography from 'components/shared/Typography/Typography';
import { ApprovalConfirmModal, ConfirmCancelModal } from '../modals';
import { sentenceCase } from 'utils/sentenceCase';
import {
  CustomDetailPanelContent,
  RenderCreationDateCell,
  RenderFeeCell,
  RenderHeaderWithSecondaryText,
  RenderStatusCell,
} from '../../Payments/views/Operations/components';

export interface FormValues {
  reason: string;
  comment: string;
  consumptionRequestType: string;
  appleConsumptionRequestData: {
    accountTenure: string;
    consumptionStatus: string;
    deliveryStatus: string;
    lifetimeDollarsPurchased: string;
    lifetimeDollarsRefunded: string;
    playTime: string;
    userStatus: string;
  };
}

interface IProps {
  onNavigateToNextRequest: () => void;
  useFormMethods: UseFormReturn<FormValues>;
}

export const RenderConsumptionRequest = ({ onNavigateToNextRequest, useFormMethods }: IProps) => {
  const {
    getValues,
    setValue,
    setError,
    reset,
    register,
    trigger,
    formState: { errors, isValid, isDirty },
  } = useFormMethods;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id: requestId } = useParams();
  const apiRef = useGridApiRef();

  const [searchParams] = useSearchParams();
  const creationDate = searchParams.get('creationDate');
  const platform = searchParams.get('platform');

  const { page, pageSize }: { page: number; pageSize: number } = useOutletContext();

  const refundReasons = useAppSelector(selectRefundReasons);
  const consumptionDictionaries = useAppSelector(selectConsumptionDictionaries);
  const operationDetails = useAppSelector(selectOperationDetails);

  type DynamicFieldName = keyof typeof consumptionDictionaries;

  const localData = localStorage.getItem(LocalStorageKeys.CONSUMPTION_REQUEST_SENDING_CONFIRM_SKIP);
  const responseSendingConfirmSkip = localData !== null ? !!JSON.parse(localData) : false;

  const [isOpenApprovalModal, setIsOpenApprovalModal] = useState<boolean>(false);
  const [isOpenConfirmCancelModal, setIsOpenConfirmCancelModal] = useState(false);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<GridRowId[]>([]);

  const { color, remainingTime } = useRemainingTimeColor(creationDate ?? '');

  const handleCancelSolving = () => {
    isDirty ? setIsOpenConfirmCancelModal(true) : navigate(ConsumptionRequestsRoutes.CONSUMPTION_REQUESTS);
  };

  const handleCloseConfirmModal = () => {
    reset();
    navigate(ConsumptionRequestsRoutes.CONSUMPTION_REQUESTS);
  };

  const checkRequestSending = async () => {
    await trigger();

    if (!responseSendingConfirmSkip && isValid && isDirty) {
      setIsOpenApprovalModal(true);
    } else if (isValid && isDirty) {
      handleRequestSending();
    }
  };

  const handleRefreshData = useCallback(async () => {
    dispatch(
      fetchConsumptionRequestCases({
        page: page,
        pageSize: pageSize,
      }),
    );
  }, [dispatch, page, pageSize]);

  const handleRequestSending = async () => {
    setIsOpenApprovalModal(false);

    const data = getValues();
    const formattedData = {
      ...data,
      operationId: operationDetails?.operation.id ?? null,
      comment: !!data.comment ? data.comment.trim() : null,
    };

    try {
      const result = await dispatch(fetchSendConsumptionRequest(formattedData));
      if (result.meta.requestStatus === 'fulfilled') {
        enqueueSnackbar(`Sent. Request closed`, {
          autoHideDuration: 1000,
          variant: 'success',
        });

        if (requestId) {
          setTimeout(() => {
            // dispatch(removeResolvedRequestId(requestId));
            navigate(ConsumptionRequestsRoutes.CONSUMPTION_REQUESTS);
            handleRefreshData();
            // onNavigateToNextRequest();
          }, 1000);
        }
      } else if (result.payload) {
        const { title, techInfo } = result.payload;
        const techInfoKeys = Object.keys(techInfo) as any;

        if (techInfoKeys.length) {
          techInfoKeys.forEach((key: string) => setError(key as any, { message: techInfo[key] ?? title }));
        } else {
          enqueueSnackbar(`An error occurred during request resolution: ${title}`, {
            variant: 'error',
          });
        }
      } else {
        enqueueSnackbar('An error occurred during request resolution', {
          variant: 'error',
        });
      }
    } catch (error) {
      enqueueSnackbar('An error occurred during request resolution', {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    dispatch(fetchConsumptionsDictionaries());
    dispatch(fetchRefundReasons());
  }, [dispatch]);

  const columns: GridColDef[] = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => <RenderDetailPanelToggle id={params.id} value={params.value} />,
    },
    {
      field: 'id',
      headerName: 'Operation ID',
      flex: 0.5,
      sortable: false,
      renderCell: (params) => <RenderCellWithCopy isWrappedText props={params} />,
    },
    {
      field: 'operationType',
      headerName: 'Operation type',
      flex: 0.5,
      sortable: false,
      renderCell: (params) => <RenderOperationTypeCell props={params} />,
    },
    {
      field: 'createDate',
      headerName: 'Creation date',
      flex: 0.3,
      sortable: false,
      renderCell: (params) => <RenderCreationDateCell props={params} />,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.3,
      sortable: false,
      renderCell: (params) => <RenderStatusCell props={params} />,
    },
    {
      field: 'price',
      headerName: 'Amount',
      flex: 0.4,
      sortable: false,
      type: 'number',
      valueFormatter: ({ value }) => {
        return Number(value) ? currencyFormatter(value, 'en-US', 'USD', true) : '—';
      },
    },
    {
      field: 'platformFeeClient',
      headerName: 'Fee',
      flex: 0.4,
      sortable: false,
      type: 'number',
      renderCell: (params) => <RenderFeeCell props={params} />,
    },
    {
      field: 'total',
      headerName: 'Total',
      renderHeader: (params) => (
        <RenderHeaderWithSecondaryText props={params} tooltipTitle="For payer" secondaryText=" (P)" />
      ),
      flex: 0.4,
      sortable: false,
      type: 'number',
      valueFormatter: ({ value }) => {
        return Number(value) ? currencyFormatter(value, 'en-US', 'USD', true) : '—';
      },
    },
    {
      field: 'totalAfterAllFees',
      headerName: 'Received ',
      renderHeader: (params) => (
        <RenderHeaderWithSecondaryText props={params} tooltipTitle="For recipient" secondaryText="(R)" />
      ),
      flex: 0.4,
      sortable: false,
      type: 'number',
      valueFormatter: ({ value }) => {
        return Number(value) ? currencyFormatter(value, 'en-US', 'USD', true) : '—';
      },
    },
    // TODO Additional (after release) Net parameter
    // {
    //   field: 'netParam',
    //   headerName: 'Net',
    //   flex: 1,
    //   sortable: false,
    //   type: 'number',
    //   valueFormatter: ({ value }) => {
    //     return Number(value) ? currencyFormatter(value, 'en-US', 'USD', true) : '—';
    //   },
    // },
  ];

  const rows: IOperation[] | [] = useMemo(() => {
    if (operationDetails) {
      return [operationDetails.operation];
    } else return [];
  }, [operationDetails]);

  const onRowClick = useCallback<GridEventListener<'rowClick'>>(
    (params) => {
      apiRef.current.toggleDetailPanel(params.id);
    },
    [apiRef],
  );

  const getDetailPanelHeight = useCallback(() => 'auto', []);

  const getDetailPanelContent = useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(
    ({ row }) => <CustomDetailPanelContent row={row} onDataRefresh={() => {}} />,
    [],
  );

  const handleDetailPanelExpandedRowIdsChange = useCallback((newIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds);
  }, []);

  return (
    <Stack className="consumption-requests-carousel__card-wrapper">
      <Stack p="16px 8px">
        <Typography m="0 0 0 24px" variant="subtitle2">
          Operation
        </Typography>
        <Grid
          autoHeight
          apiRef={apiRef}
          className="operations-table--brief"
          columns={columns}
          rows={rows}
          onRowClick={onRowClick}
          getRowId={(row) => row.id}
          rowHeight={68}
          hideFooter
          hideFooterSelectedRowCount
          disableColumnMenu
          disableColumnReorder
          disableColumnResize
          disableSelectionOnClick
          getRowClassName={(params) => `${params.row.id === detailPanelExpandedRowIds[0] ? 'Mui-selected' : ''}`}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
          detailPanelExpandedRowIds={detailPanelExpandedRowIds}
          onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
          components={{
            NoRowsOverlay: () => (
              <Typography sx={{ position: 'absolute', right: '50%', top: '50%' }} variant="body2">
                No data to display
              </Typography>
            ),
          }}
          columnTypes={{
            [GRID_DETAIL_PANEL_TOGGLE_COL_DEF.type as string]: GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
          }}
        />
      </Stack>

      <Divider orientation="horizontal" flexItem />

      <Stack p={3} direction="row" alignItems="center" justifyContent="space-between">
        <Stack flex={'45%'} maxWidth={'45%'} alignSelf="self-start">
          <Typography variant="subtitle2">Entity parameters</Typography>
          <Stack direction="row" gap={4} justifyContent="flex-start">
            <ListItem sx={{ p: 0 }}>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body2" color="text.secondary">
                    Platform
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" color="text.primary">
                    {startCase(platform?.toLowerCase()) || '—'}
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
            <ListItem sx={{ p: 0 }}>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body2" color="text.secondary">
                    Sample content provided
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" color="text.primary">
                    Yes
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
          </Stack>

          <SimpleGrid
            sx={{ paddingTop: 2 }}
            container
            flexDirection="column"
            minHeight="100%"
            alignContent="stretch"
            spacing={2}
          >
            {consumptionDictionaries &&
              Object.entries(consumptionDictionaries)
                .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                .map(([key, value]) => (
                  <SimpleGrid item xs={12} key={key}>
                    <Select
                      {...register(`appleConsumptionRequestData.${key}` as DynamicFieldName)}
                      placeholder={sentenceCase(key)}
                      name={key}
                      variant="outlined"
                      error={!!errors.appleConsumptionRequestData?.[key as DynamicFieldName]}
                      fullWidth
                      size="small"
                      options={Object.entries(value).map(([optionValue, optionText]) => {
                        return {
                          value: optionValue,
                          text: optionText,
                        } as ISelectOptions;
                      })}
                      onChange={(event) => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        setValue(`appleConsumptionRequestData.${key}` as DynamicFieldName, event.target.value, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });
                        trigger(`appleConsumptionRequestData.${key}` as DynamicFieldName);
                      }}
                      defaultValue=""
                      onBlur={() => trigger(`appleConsumptionRequestData.${key}` as DynamicFieldName)}
                    />
                    {errors.appleConsumptionRequestData?.[key as DynamicFieldName] && (
                      <FormHelperText error={!!errors.appleConsumptionRequestData?.[key as DynamicFieldName]}>
                        {errors.appleConsumptionRequestData[key as DynamicFieldName]['message']}
                      </FormHelperText>
                    )}
                  </SimpleGrid>
                ))}
          </SimpleGrid>
        </Stack>

        <Divider sx={{ margin: '0 48px' }} orientation="vertical" flexItem />

        <Stack flex={'45%'} maxWidth={'45%'} alignSelf="self-start">
          <Typography variant="subtitle2">YZZY solution</Typography>
          <SimpleGrid
            sx={{ paddingTop: 2 }}
            container
            flexDirection="column"
            minHeight="100%"
            alignContent="stretch"
            spacing={2}
          >
            <SimpleGrid pt={2} item xs={12}>
              <Select
                {...register('reason')}
                placeholder="Reason"
                name="reason"
                variant="outlined"
                error={!!errors.reason}
                fullWidth
                size="small"
                options={
                  refundReasons.length
                    ? refundReasons.map(
                        ({ name }) =>
                          ({
                            value: name,
                            text: name,
                          }) as ISelectOptions,
                      )
                    : []
                }
                defaultValue=""
                onBlur={() => trigger('reason')}
              />
              {errors.reason?.message && (
                <FormHelperText error={!!errors.reason}>{errors.reason.message}</FormHelperText>
              )}
            </SimpleGrid>
            <SimpleGrid item xs={12}>
              <TextField
                {...register('comment')}
                size="small"
                name="comment"
                label="Comment"
                type="text"
                fullWidth
                multiline
                maxRows={16}
                error={!!errors.comment}
                helperText={errors.comment && (errors.comment?.message as string)}
                onBlur={() => trigger('comment')}
              />
            </SimpleGrid>
          </SimpleGrid>
        </Stack>
      </Stack>

      <Divider orientation="horizontal" flexItem />

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <ListItem>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body2" color="text.secondary">
                Time left
              </Typography>
            }
            secondary={
              <Typography color={{ color: color }} variant="body2">
                {remainingTime ? remainingTime.formatted : '—'}
              </Typography>
            }
          ></ListItemText>
        </ListItem>

        <Stack direction="row" gap={2} mr={3}>
          <Button variant="text" onClick={handleCancelSolving}>
            Cancel
          </Button>
          <Button variant="contained" size="large" onClick={() => checkRequestSending()}>
            Send
          </Button>
        </Stack>
      </Stack>
      <ConfirmCancelModal
        closeModal={() => setIsOpenConfirmCancelModal(false)}
        open={isOpenConfirmCancelModal}
        onClose={handleCloseConfirmModal}
      />
      <ApprovalConfirmModal
        onRequestSend={() => handleRequestSending()}
        open={isOpenApprovalModal}
        onClose={() => setIsOpenApprovalModal(false)}
      />
    </Stack>
  );
};
