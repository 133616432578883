import React from "react";
import Button from "components/shared/Button/Button";
import Alert from "components/shared/Alert/Alert";

import './index.scss';

interface IProps {
  deleteEntities(entities: string[], entityType: 'BANNER' | 'HERO' | 'OFFER' | 'WISH' | 'EVENT'): void;
  items: string[];
  willUnpublishWarning?: boolean;
  onClose(): void;
  entityType: 'BANNER' | 'HERO' | 'OFFER' | 'WISH' | 'EVENT';
}

const DeleteEntities = ({ deleteEntities, items, onClose, willUnpublishWarning = false, entityType }: IProps) => {
  let content;
  let setScroll;
  let title;

  const isProfile = entityType === 'HERO';
  const isMultiple = items.length > 1;

  if (isMultiple) {
    title = `You are going to delete ${items.length} ${isProfile ? 'profiles' : 'entities'} from collection`;
  } else {
    title = `You are going to delete ${isProfile ? 'profile' : 'entity'} from collection`;
    content = null;
  }

  const handleDelete = async () => {
    deleteEntities(items, entityType );
    onClose();
  }

  return (
    <div className='deleteEntities'>
      <div className="deleteEntities-header">
        {title}
      </div>
      <div className="deleteEntities-footer">
        {willUnpublishWarning && <Alert severity="warning">After deleting will be not enough entities for publishing</Alert>}
        <div className="deleteEntities-footer-buttons">
          <Button
            onClick={onClose}
            variant="text"
            color="primary"
          >
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={handleDelete}>
            Delete {isMultiple ? `${isProfile ? 'profiles' : 'entities'}` : `${isProfile ? 'profile' : 'entity'}`}
          </Button>
        </div>
      </div>
    </div>
  )
};

export default DeleteEntities;