import { useState } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import trancate from 'lodash/truncate';
import ChevronDownIcon from 'assets/img/ChevronDownIcon';
import ChevronUpIcon from 'assets/img/ChevronUpIcon';
import Typography from 'components/shared/Typography/Typography';
import './index.scss';

/**
 * Renders a cell with expand/collapse functionality for long texts.
 */
const RenderExpandableCell = (props: GridRenderCellParams) => {
  const { value } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  if (!value) {
    return <>—</>;
  }

  return (
    <div className="expandable-cell">
      <Typography className="text" variant="body2" component="span">
        {isExpanded
          ? value
          : trancate(value, {
              length: 205,
            })}{' '}
      </Typography>
      {value?.length > 205 && (
        <Typography
          component="span"
          className="trigger"
          onClick={(e) => {
            e.stopPropagation();
            setIsExpanded(!isExpanded);
          }}
        >
          <Typography component="span" variant="body2">
            {isExpanded ? 'Collapse' : 'Expand'}
          </Typography>
          {isExpanded ? <ChevronUpIcon fontSize="small" /> : <ChevronDownIcon fontSize="small" />}
        </Typography>
      )}
    </div>
  );
};

export default RenderExpandableCell;
