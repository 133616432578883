import { GridRenderCellParams } from '@mui/x-data-grid';
import { currencyFormatter } from 'utils/currencyFormatter';
import { IOperation, TransactionTypes } from 'store/slices/Payments/interface';
import Typography from 'components/shared/Typography/Typography';

/**
 * Props for the RenderAmountCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 * @property {IOperation} rowData - Operation data.
 */
interface IRenderAmountCellProps {
  props: GridRenderCellParams;
  rowData: IOperation;
}

/**
 * Renders an "Amount" cell in a data grid (Transactions table).
 */
export const RenderAmountCell = ({ props, rowData }: IRenderAmountCellProps) => {
  const { row } = props;

  if (!row.id) {
    return <></>;
  }

  const isRefundOrTransfer = row.type === TransactionTypes.REFUND || row.type === TransactionTypes.REVERSAL_TRANSFER;

  return (
    <Typography variant="body2">
      {isRefundOrTransfer ? '—' : currencyFormatter(Number(rowData.price), 'en-US', 'USD', true)}
    </Typography>
  );
};
