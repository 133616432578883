import React, { useState } from "react";
import { IMedia } from "types/commonTypes";
import 'components/Profile/MemberProfile/MemberAvatar/index.scss';
import Button from "components/shared/Button/Button";
import ImageIcon from "assets/img/ImageIcon";
import IconButton from "components/shared/IconButton/IconButton";
import EditIcon from "assets/img/EditIcon";
import RefreshIcon from "assets/img/RefreshIcon";
import Tooltip from "components/shared/Tooltip/Tooltip";

import { AvatarAction } from "containers/Profile/Profile";
import UserBroken from 'assets/img/UserBroken.png';

interface Props {
  avatarPreview: IMedia;
  setShowSetAvatar(action: AvatarAction): void;
}

const MemberAvatar = ({ avatarPreview, setShowSetAvatar }: Props) => {
  const [isError, setIsError] = useState(false);

  return (
    <div className="memberAvatar">
      {avatarPreview.originalLink && avatarPreview.originalLink !== '' ?
        (
          <div className="memberAvatar-imageContainer">
            <div className="memberAvatar-imageContainer-buttons">
              <Tooltip title="Edit photo" placement="top-start">
                <span className={isError ? 'memberAvatar-imageContainer-buttons_disabled' : ''}>
                  <IconButton disabled={isError} onClick={() => setShowSetAvatar('edit')}><EditIcon /></IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Replace photo" placement="top-start">
                <span>
                  <IconButton onClick={() => setShowSetAvatar('replace')}><RefreshIcon /></IconButton>
                </span>
              </Tooltip>
            </div>
            <img
              src={avatarPreview.originalLink}
              alt="avatar"
              onError={(event) => {
                event.currentTarget.src = UserBroken;
                setIsError(true);
              }}
            />
          </div>
        ) :
        (
          <div className="memberAvatar-imageContainer memberAvatar-imageContainer_noImage">
            <Button
              startIcon={<ImageIcon />}
              variant="outlined"
              onClick={() => setShowSetAvatar('add')}
            >
              Add Photo
            </Button>
          </div>
        )
      }
    </div>
  )
};

export default MemberAvatar;