/**
 * Converts a Blob object into a File object by adding file properties.
 *
 * @param {Blob} theBlob - The Blob object to be converted.
 * @param {string} fileName - The name to assign to the File object.
 * @returns {File} The Blob object cast as a File with additional properties.
 */
export const blobToFile = (theBlob: Blob, fileName: string): File => {
  const b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;

  //Cast to a File() type
  return theBlob as File;
};
