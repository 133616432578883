import { Skeleton as SkeletonMUI } from "@mui/material";
import { SkeletonProps } from "@mui/material/Skeleton/Skeleton";

import './index.scss';

const Skeleton = (props: SkeletonProps) => {
  return (
    <SkeletonMUI {...props} />
  )
};

export default Skeleton;