import { GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import Tooltip from 'components/shared/Tooltip/Tooltip';

/**
 * Check if there is no value in a cell and return formatted value.
 */
const CheckIfEmptyCell = (params: GridRenderCellParams<any, any, any>) => {
  return !params.value ? (
    <Tooltip title="Not needed for this provider" placement="top">
      <span>—</span>
    </Tooltip>
  ) : (
    params.colDef.valueFormatter && params.colDef.valueFormatter(params as GridValueFormatterParams)
  );
};

export default CheckIfEmptyCell;
