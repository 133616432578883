import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { enqueueSnackbar, VariantType } from 'notistack';
import { OperationActions } from 'store/slices/Payments/interface';
import { IRefundReason } from 'types/commonTypes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchOperationAction, selectOperationActionStatus } from 'store/slices/Payments/operationsSlice';
import getValidationSchema from '../utils/getValidationSchema';
import { currencyFormatter } from 'utils/currencyFormatter';
import Button from 'components/shared/Button/Button';
import Dialog from 'components/shared/Dialog/Dialog';
import DialogActions from 'components/shared/Dialog/DialogActions/DialogActions';
import DialogContent from 'components/shared/Dialog/DialogContent/DialogContent';
import DialogTitle from 'components/shared/Dialog/DialogTitle/DialogTitle';
import FormHelperText from 'components/shared/FormHelperText/FormHelperText';
import ListItem from 'components/shared/List/ListItem/ListItem';
import ListItemText from 'components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText';
import LoadingButton from 'components/shared/LoadingButton/LoadingButton';
import Select, { ISelectOptions } from 'components/shared/Select/Select';
import SimpleGrid from 'components/shared/SimpleGrid/SimpleGrid';
import Stack from 'components/shared/Stack/Stack';
import TextField from 'components/shared/TextField/TextField';
import Typography from 'components/shared/Typography/Typography';
import { ConfirmCancelModal } from './ConfirmCancelModal';

interface IRefundModalProps {
  open: boolean;
  operationId: string;
  isInAppOperation: boolean;
  total: number;
  currency: string;
  refundReasons: IRefundReason[];
  onClose: () => void;
  onDataRefresh: () => void;
}

export const RefundModal = ({
  open,
  total,
  currency,
  operationId,
  isInAppOperation,
  onClose,
  onDataRefresh,
  refundReasons,
}: IRefundModalProps) => {
  const dispatch = useAppDispatch();
  const schema = useMemo(() => getValidationSchema(), []);
  const operationActionStatus = useAppSelector(selectOperationActionStatus);
  let isOperationActionLoading = operationActionStatus === 'loading';

  const [isOpenConfirmCancelModal, setIsOpenConfirmCancelModal] = useState(false);

  const {
    reset,
    handleSubmit,
    register,
    trigger,
    formState: { errors, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      reason: '',
      comment: '',
    },
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = async (data: { comment?: string; reason: string }) => {
    void trigger();

    const formattedData = {
      operationId: operationId,
      action: OperationActions.REFUND,
      reason: data.reason,
      comment: data.comment?.trim(),
      inApp: isInAppOperation ? isInAppOperation : null,
    };

    try {
      const result = await dispatch(fetchOperationAction(formattedData));

      if (result.meta.requestStatus === 'fulfilled') {
        handleClose();
        enqueueSnackbar('Refund has been completed', {
          variant: 'success' as VariantType,
        });
        onClose();

        onDataRefresh();
      } else if (result.payload) {
        const { title } = result.payload;
        enqueueSnackbar(`An error occurred during the refund process: ${title}`, {
          variant: 'error' as VariantType,
        });
      } else {
        enqueueSnackbar('An error occurred during the refund process', {
          variant: 'error' as VariantType,
        });
      }
    } catch (error) {
      enqueueSnackbar('An error occurred during the refund process', {
        variant: 'error' as VariantType,
      });
    }
  };

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={open}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle variant="h6">Refund payment</DialogTitle>
          <DialogContent>
            <Stack direction="row">
              <ListItem>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="text.secondary">
                      Refund amount
                    </Typography>
                  }
                  secondary={
                    <Typography variant="subtitle1" color="text.primary">
                      {total ? currencyFormatter(total, 'en-US', 'USD', true) : '—'}
                    </Typography>
                  }
                ></ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="text.secondary">
                      Currency
                    </Typography>
                  }
                  secondary={
                    <Typography variant="subtitle1" color="text.primary">
                      {currency || 'USD'}
                    </Typography>
                  }
                ></ListItemText>
              </ListItem>
            </Stack>
            <SimpleGrid
              sx={{ paddingTop: 2 }}
              container
              flexDirection="column"
              minHeight="100%"
              alignContent="stretch"
              spacing={2}
            >
              <SimpleGrid pt={2} item xs={12}>
                <Select
                  {...register('reason')}
                  placeholder="Reason"
                  name="reason"
                  variant="outlined"
                  error={!!errors.reason}
                  fullWidth
                  size="small"
                  options={
                    refundReasons.length
                      ? refundReasons.map(
                          ({ name }) =>
                            ({
                              value: name,
                              text: name,
                            }) as ISelectOptions,
                        )
                      : []
                  }
                  onBlur={() => trigger('reason')}
                />
                {errors.reason?.message && (
                  <FormHelperText error={!!errors.reason}>{errors.reason.message}</FormHelperText>
                )}
              </SimpleGrid>
              <SimpleGrid item xs={12}>
                <TextField
                  {...register('comment')}
                  size="small"
                  name="comment"
                  label="Comment"
                  type="text"
                  fullWidth
                  multiline
                  maxRows={4}
                  error={!!errors.comment}
                  helperText={errors.comment && (errors.comment?.message as string)}
                  onBlur={() => trigger('comment')}
                />
              </SimpleGrid>
            </SimpleGrid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                isDirty ? setIsOpenConfirmCancelModal(true) : handleClose();
              }}
              variant="text"
              color="primary"
            >
              Cancel
            </Button>
            <LoadingButton
              loading={isOperationActionLoading}
              // disabled={!isValid || !isDirty}
              variant="contained"
              color="primary"
              type="submit"
            >
              Refund
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
      <ConfirmCancelModal
        closeModal={() => setIsOpenConfirmCancelModal(false)}
        open={isOpenConfirmCancelModal}
        onClose={handleClose}
      />
    </>
  );
};
