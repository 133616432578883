import * as yup from 'yup';

const getValidationSchema = (action: 'login' | 'setPassword' | 'otp') => {
  if (action === 'login') {
    return yup.object().shape({
      email: yup
        .string()
        .required('Enter valid email')
        .matches(
          /^([\w][\!\#\$\%\'\*\+\-\/\=\?\^\_\`\{\|\}\~\.]?){2,192}(?<![\w][\!\#\$\%\'\*\+\-\/\=\?\^\_\`\{\|\}\~\.])(@{1})(([\w][\-]?){1,63})(?<![-])([\.]{1})(([\w][\-]?){2,})(?<![-])$/,
        'Enter valid email',
        ),
      password: yup.string().required('Password is a required field').min(1),
    });
  }
  if (action === 'otp') {
    return yup.object().shape({
      email: yup.string(),
      otp: yup.string().required('Enter code').length(6),
    });
  }
  return yup.object().shape({
    password: yup
      .string()
      .required('Password does not meet the above-mentioned conditions')
      .matches(
        /^(?:[0-9a-zA-Z!@#&()\-\[{}\]:;',?\/*~$^+=<>]*)$/,
        `Password must contain only Latin letters A-Z, a-z; numbers \n0-9 and special symbols &$@[({<>})]!?:;',~^*#+-=/`,
      )
      .max(20, 'Max limit of 20 characters is exceeded')
      .matches(
        /(?=.*[0-9])(?=.*[!@#&()\-\[{}\]:;',?\/*~$^+=<>])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#&()\-\[{}\]:;',?\/*~$^+=<>]{8,}/,
        'Password does not meet the above-mentioned conditions',
      )
      .min(8, 'Password does not meet the above-mentioned conditions'),
    repeatPassword: yup.string().oneOf([yup.ref('password')], 'Passwords don’t match'),
  });
};

export default getValidationSchema;
