import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from "store/store";
import axios from "axios";
import { bannersData } from "__mock__/world/bannersData";
import { IMedia, IStatus } from "types/commonTypes";

export type BannerStatus = 'INCOMPLETE' | 'READY' | 'LIVE' | 'EXPIRED' | 'ARCHIVED';

export interface Banner {
  id: string;
  name: string;
  title: string;
  subtitle: string;
  cover: IMedia;
  status: BannerStatus;
  expiredDate: Date | null;
  creatorName: string;
  creatorLastName: string;
  updatedAt: Date;
  article: {
    editorString: string;
    textString: string;
  } | null;
  order?: number;
}

interface BannerDetailsState {
  banner: Banner | null;
  status: IStatus;
  savingStatus: IStatus;
}

const initialState: BannerDetailsState = {
  banner: null,
  status: 'idle',
  savingStatus: 'idle'
};

export const fetchBanner = createAsyncThunk(
  'bannerDetails/bannerDetails',
  async (bannerId: string) => {
    const response = await axios.get((process.env.REACT_APP_WORLD_MANAGEMENT_ENDPOINT as string) + `timworld/banners/${bannerId}`);
    return response.data;
  }
);

export const fetchEditBanner = createAsyncThunk(
  'bannerDetails/editBanner',
  async ({bannerId, data}: {bannerId: string, data: any}) => {
    const response = await axios.patch((process.env.REACT_APP_WORLD_MANAGEMENT_ENDPOINT as string) + `timworld/banners/${bannerId}`, data);
    return response.data;
  }
)

export const fetchUpdateCover = createAsyncThunk(
  'bannerDetails/updateCover',
  async ({ cover, coverPreview, bannerId }: { cover?: Blob, coverPreview: Blob, bannerId: string }) => {
    const body = new FormData();
    body.append('cover', cover ?? '');
    body.append('coverPreview', coverPreview ?? '');
    const response = await axios.post((
      process.env.REACT_APP_WORLD_MANAGEMENT_ENDPOINT as string) + `timworld/banners/${bannerId}/cover`,
      body,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    return response.data;
  }
);

export const bannerDetailsSlice = createSlice({
  name: 'bannerDetails',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBanner.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBanner.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.banner = payload;
      })
      .addCase(fetchBanner.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchEditBanner.pending, (state) => {
        state.savingStatus = 'loading';
      })
      .addCase(fetchEditBanner.fulfilled, (state, { payload }) => {
        state.savingStatus = 'idle';
      })
      .addCase(fetchEditBanner.rejected, (state) => {
        state.savingStatus = 'failed';
      })
  },
});

export const selectBanner = (state: RootState): Banner | null => state.bannerDetails.banner;
export const selectBannerStatus = (state: RootState): IStatus => state.bannerDetails.status;
export const savingBannerStatus = (state: RootState): IStatus => state.bannerDetails.savingStatus;

export default bannerDetailsSlice.reducer;