import React, { useCallback, useEffect, useState } from "react";
import { fetchUpdateAvatar, selectProfileStatus } from "store/profileSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import Button from "components/shared/Button/Button";
import LoadingButton from "components/shared/LoadingButton/LoadingButton";
import Dropzone from "components/shared/Dropzone/Dropzone";
import ImageCrop from "components/shared/ImageCrop/ImageCrop";
import Slider from "components/shared/Slider/Slider";
import DeleteIcon from "assets/img/DeleteIcon";
import IconButton from "components/shared/IconButton/IconButton";
import ZoomInIcon from "assets/img/ZoomInIcon";
import ZoomOuttaIcon from "assets/img/ZoomOuttaIcon";
import { getCroppedImg } from "utils/canvasUtils";
import { Area } from "react-easy-crop/types";
import { blobToFile } from "utils/blobToFile";
import { enqueueSnackbar, VariantType } from "notistack";
import { AvatarAction } from "containers/Profile/Profile";
import Modal from "components/shared/Modal/Modal";
import { IMedia } from "types/commonTypes";
import Alert from "components/shared/Alert/Alert";

import './index.scss';

interface Props {
  onClose(): void;
  avatar: IMedia | null;
  avatarAction: AvatarAction;
}

const BYTE = 1;
const KILOBYTE = 1024 * BYTE;
const MEGABYTE = 1024 * KILOBYTE;
const ACCEPTED_FILES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
];

interface Image {
  type?: string;
  src: string;
  file: File;
}

const SetAvatar = ({ onClose, avatar, avatarAction }: Props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [image, setImage] = useState<Image | null>(null);
  const [_, setPreviewImage] = useState<Image | null>(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [zoom, setZoom] = useState(1);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const status = useAppSelector(selectProfileStatus);

  const dispatch = useAppDispatch();

  const initialAvatar = useCallback(async () => {
    if (avatar) {
      const res = await fetch(avatar.originalLink, {
        method: 'GET',
        headers: {
          "Access-Control-Allow-Headers": "Authorization, X-Requested-With, Accept, Content-Type, Origin, Cache-Control, X-File-Name",
          "Access-Control-Allow-Origin": "*"
        },
        mode: 'no-cors',
      })
      const data = await res?.blob();
      const metadata = { type: data.type };
      const filename = avatar.originalLink.replace(/\?.+/, "").split("/").pop();

      setImage({
        file: new File([data], filename!, metadata),
        src: avatar.originalLink,
        type: avatar.type
      });
    }
  }, []);

  useEffect(() => {
    initialAvatar();
    return () => setImage(null);
  }, []);

  const onLoadImage = (files: File[]) => {
    if (files && files[0]) {
      const img = new Image();
      const blobUrl: string = URL.createObjectURL(files[0]);
      img.onload = () => {
        if (img.width > 5000 || img.height > 5000) {
          setErrorMessage('Files larger than 30 Mb and bigger than 5000px are not allowed');
        } else {
          setImage({
            file: files[0],
            src: blobUrl,
            type: files[0].type
          })
        }
      };
      img.src = blobUrl;
    }
  };

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleUploadPhoto = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image?.src ?? '',
        image?.type ?? '',
        croppedAreaPixels ?? {
          height: 244,
          width: 244,
          y: 0,
          x: 0,
        },
        0,
      )
      if (croppedImage) {
        const fileCroppedImage = blobToFile(croppedImage, image?.file.name + 'preview');
        setPreviewImage({
          src: URL.createObjectURL(croppedImage),
          file: fileCroppedImage,
        });

        const result = await dispatch(fetchUpdateAvatar({
          avatar: avatar?.originalLink !== image?.src ? image?.file : undefined,
          avatarPreview: fileCroppedImage,
        }));
        if (result.meta.requestStatus === 'fulfilled') {
          const snackMessage = 'Photo has been uploaded';
          enqueueSnackbar(snackMessage, { variant: 'success' as VariantType });
          onClose();
        }
        if (result.meta.requestStatus === 'rejected') {
          setErrorMessage('Uploading error, try again later');
        }
      }
    } catch (e) {
      setErrorMessage('Uploading photo error');
    }
  }, [image, croppedAreaPixels]);

  return (
    <div className="profileModal">
      <div className="profileModal-title">
        {avatarAction === 'edit' && 'Edit photo'}
        {avatarAction === 'replace' && 'Replace photo'}
        {avatarAction === 'add' && 'Add photo'}
      </div>
      <div className="profileModal-form profileModal-form_p0">
        <div className="profileModal-form-uploadArea">
          {image !== null ? (
            <div className="profileModal-form-uploadArea_containerCropper">
              {avatarAction !== 'edit' &&
                <IconButton
                  disabled={status === 'loading'}
                  className="profileModal-form-uploadArea_deleteButton"
                  onClick={() => setImage(null)}
                >
                  <DeleteIcon />
                </IconButton>
              }
              <div className="profileModal-form-uploadArea_crop">
                <ImageCrop
                  image={image.src}
                  aspect={1}
                  zoom={zoom}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                  style={{
                    containerStyle: { borderRadius: '8px' },
                  }}
                />
              </div>
              <div className="profileModal-form-uploadArea_controls">
                <IconButton
                  onClick={() => setZoom((prevState) => prevState <= 1 ? prevState : Number(prevState - 0.1))}
                >
                  <ZoomOuttaIcon />
                </IconButton>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  size="small"
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(Number(zoom))}
                  classes={{ root: "slider" }}
                />
                <IconButton
                  onClick={() => setZoom((prevState) => prevState >= 3 ? prevState : Number(prevState + 0.1))}
                >
                  <ZoomInIcon />
                </IconButton>
              </div>
            </div>
          ) : (
            <div className="profileModal-form-uploadArea_containerDropzone">
              <span className="profileModal-form-uploadArea_title">
                One .jpg, .jpeg or .png file only
              </span>
              <Dropzone
                initialFiles={avatarAction === 'edit' && image ? [image as File] : undefined}
                dropzoneClass="profileModal-form-uploadArea_dropzone"
                showAlerts={false}
                showPreviews={false}
                showPreviewsInDropzone={false}
                onDrop={onLoadImage}
                onDropRejected={(rejectedFiles) => {
                  setErrorMessage('');
                  if (rejectedFiles.length > 1) {
                    setErrorMessage('Only one file allowed');
                    return;
                  }
                  if (!ACCEPTED_FILES.includes(rejectedFiles[0].type)) {
                    setErrorMessage('Unsupported file extension');
                    return;
                  }
                  if (rejectedFiles[0].size > MEGABYTE * 30) {
                    setErrorMessage('Files larger than 30 Mb and bigger than 5000px are not allowed');
                  }
                }}
                getFileAddedMessage={() => {
                  setErrorMessage('');
                  return '';
                }}
                dropzoneText={'Drag and drop a file here or click'}
                acceptedFiles={ACCEPTED_FILES}
                filesLimit={1}
                maxFileSize={30 * MEGABYTE}
              />
            </div>
          )}
        </div>
        {errorMessage && errorMessage !== '' && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
        <div className="profileModal-buttonsGroup">
          <Button
            disabled={status === 'loading'}
            variant="text"
            onClick={() => image ? setIsOpenModal(true) : onClose()}
          >
            Cancel
          </Button>
          <LoadingButton
            disabled={image === null}
            type="submit"
            variant="contained"
            loadingPosition="start"
            startIcon={<></>}
            loading={status === 'loading'}
            onClick={handleUploadPhoto}
          >
            {avatarAction === 'edit' ? 'Save changes' : 'Upload photo'}
          </LoadingButton>
        </div>
      </div>
      <Modal customstyle={{ minHeight: 188 }} open={isOpenModal}>
        <div className="cancelModal">
          <div className="cancelModal-header">
            {avatarAction === 'add' && `Cancel adding photo?`}
            {avatarAction === 'edit' && `Cancel editing photo`}
            {avatarAction === 'replace' && `Cancel replacing photo`}
          </div>
          <div className="cancelModal-content">
            <span className="cancelModal-content-value">Changes won’t be applied</span>
          </div>
          <div className="cancelModal-footer">
            <div className="cancelModal-footer-buttons">
              <Button onClick={() => setIsOpenModal(false)}>Don’t cancel</Button>
              <Button
                onClick={() => {
                  setIsOpenModal(false);
                  onClose();
                }}
                variant="contained"
                color= "primary"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
};

export default SetAvatar;
