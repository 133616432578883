import React, { useRef, useState } from "react";
import {Box, InputLabel, useTheme} from "@mui/material";
import TextEditor, {
  HeaderBlockStyle,
  HeaderToolbarButton,
  TextEditorProps,
} from "components/shared/TextEditor/TextEditor";

import './index.scss';
import { TMUIRichTextEditorRef } from "mui-rte";

interface IProps extends Omit<TextEditorProps, 'toolbar' | 'onChange' | 'onSave'> {
  onChange(value: string): void;
  onSave(value: string): void;
  placeholder?: string;
}

const TextEditorField = (props: IProps) => {
  const [active, setActive] = useState(false);
  const ref = useRef<TMUIRichTextEditorRef>(null);

  const theme = useTheme();

  Object.assign(theme, {
    overrides: {
      MUIRichTextEditor: {
        root: {
          height: '100%',
        },
        container: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        },
        editor: {
          order: -1,
          height: '100%',
          color: props.readOnly ? '#BCC0CD' : '#70778f',
        },
        editorContainer: {
          height: '100%',
          width: '100%',
        },
        placeHolder: {
          order: -1,
          paddingTop: '5px',
          color: '#BCC0CD',
        },
        toolbar: {
          marginTop: '24px',
          '& .TextEditor-button': {
            color: '#70778f',
          },
          '& .MuiToggleButton-root': {
            padding: '4px 6px'
          },
          '& .MuiToggleButton-root:hover': {
            backgroundColor: '#d9e3fd',
            border: '1px solid #DADCE3',
          },
          '& .TextEditor-button_active': {
            color: '#4E79E7',
            backgroundColor: '#d9e3fd',
            border: '1px solid #88a8f9',
          },
        },
        '.DraftEditor-root': {
          height: '100%',
        },
      }
    }
  })

  return (
    <Box
      id="box-editor"
      className={active ? 'TextEditor-box TextEditor-box_active'
        : props.readOnly ? 'TextEditor-box TextEditor-box_disabled' : 'TextEditor-box '}
    >
      <InputLabel
        htmlFor="box-editor"
        className={active ?  'TextEditor-label TextEditor-label_active'
          : props.readOnly ? 'TextEditor-label TextEditor-label_disabled' : 'TextEditor-label'}
      >
        {props.label}
      </InputLabel>
      <TextEditor
        {...props}
        ref={ref}
        maxLength={props.maxLength}
        onSave={(data) => !props.readOnly && props.onSave(data)}
        onFocus={() => !props.readOnly && setActive(true)}
        onBlur={() => !props.readOnly && setActive(false)}
        onChange={(editorState) => {
          if (!props.readOnly) {
            props.onChange(editorState.getCurrentContent().getPlainText());
            ref.current?.save();
          }
        }}
        defaultValue={props.defaultValue}
        label={props.placeholder}
        controls={["header"]}
        toolbar={active || props.readOnly}
        draftEditorProps={{
          stripPastedStyles: true,
        }}
        customControls={[
          {
            name: "header",
            component: HeaderToolbarButton,
            type: "block",
            blockWrapper: <HeaderBlockStyle readOnly={props.readOnly} />,
            id: "header",
          },
        ]}
      />
    </Box>
  );
};

export default TextEditorField;