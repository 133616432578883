import React from "react";
import Button from "components/shared/Button/Button";

import './index.scss';

interface IProps {
  onClose(): void;
  closeModal(): void;
}

const BannerDetailsCloseModal = ({ onClose, closeModal }: IProps) => {
  return (
    <div className='closeBannerDetailsModal'>
      <div className="closeBannerDetailsModal-header">
        Exit without saving changes?
      </div>
      <div className="closeBannerDetailsModal-content">
        All unsaved data will be lost
      </div>
      <div className="closeBannerDetailsModal-footer">
        <div className="closeBannerDetailsModal-footer-buttons">
          <Button onClick={closeModal}>Cancel</Button>
          <Button
            onClick={() => {
              closeModal();
              onClose();
            }}
            variant="contained"
            color="warning"
          >
            Exit without saving
          </Button>
        </div>
      </div>
    </div>
  )
};

export default BannerDetailsCloseModal;