import { Chip as ChipBase, useTheme } from '@mui/material';
import { ChipProps } from '@mui/material/Chip/Chip';

interface IChipProps extends ChipProps {
  primary?: boolean;
  label?: string;
  active?: boolean;
}

const Chip = (props: IChipProps) => {
  const theme = useTheme();
  return (
    <ChipBase
      {...props}
      variant={props.variant ?? props.active ? 'filled' : 'outlined'}

      sx={{
        color: theme.palette.common.black,
        ...props.sx,
      }}
    />
  );
};

export default Chip;
