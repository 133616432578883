import colors from 'theme/constants/colors';
import { PaymentFeesStatuses } from 'store/slices/Settings/interface';

export const possiblePaymentFeesStatuses = [
  {
    status: PaymentFeesStatuses.ACTIVE,
    title: 'Active',
    color: colors.success,
  },
  {
    status: PaymentFeesStatuses.INACTIVE,
    title: 'Inactive',
    color: colors.error,
  },
];
