export const getEntityDescription = (entityType: 'BANNER' | 'HERO' | 'OFFER' | 'WISH' | 'EVENT') => {
  switch (entityType) {
    case "BANNER": {
      return {
        title: 'Banner',
        alertEntityName: 'Banner',
        singularEntityName: 'banner',
        pluralEntityName: 'banners',
        entityCountProp: 'totalBanners',
        minAllowedNumberOfEntities: 5,
      }
    }
    case "HERO": {
      return {
        title: 'User',
        alertEntityName: 'profile',
        singularEntityName: 'profile',
        pluralEntityName: 'profiles',
        entityCountProp: 'totalHeroes',
        minAllowedNumberOfEntities: 15,
      }
    }
    case "OFFER": {
      return {
        title: 'Offer',
        alertEntityName: 'entity',
        singularEntityName: 'offer',
        pluralEntityName: 'offers',
        entityCountProp: 'totalOffers',
        minAllowedNumberOfEntities: 15,
      }
    }
    case "WISH": {
      return {
        title: 'Wish',
        alertEntityName: 'entity',
        singularEntityName: 'wish',
        pluralEntityName: 'wishes',
        entityCountProp: 'totalWishes',
        minAllowedNumberOfEntities: 15,
      }
    }
    case "EVENT": {
      return {
        title: 'Event',
        alertEntityName: 'entity',
        singularEntityName: 'event',
        pluralEntityName: 'events',
        entityCountProp: 'totalEvents',
        minAllowedNumberOfEntities: 15,
      }
    }
  }
}