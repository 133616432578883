import { DataGridPro } from '@mui/x-data-grid-pro';
import { DataGridProProps } from '@mui/x-data-grid-pro/models/dataGridProProps';

interface IGridProps extends DataGridProProps {
  primary?: boolean;
  label?: string;
}

const Grid = (props: IGridProps) => {
  return (
    <DataGridPro
      {...props}
    />
  );
};

export default Grid;
