enum colors {
  /*---light--- */
  text = '#23252F',
  textSecondary = '#70778f',

  primary = '#4E79E7',
  secondary = '#7360CD',
  success = '#4CA478',
  warning = '#DA612B',
  error = '#B42929',
  info = '#0288D1',

  actionDisabled = '#BFC6E2',
  actionSelected = '#BFC6E23D',
  actionDisabledBackground = '#EEF0F6',

  /*---other--- */
  backdropOverlay = '#4F5775',
  divider = '#DADCE3',
  outlinedBorder = '#BCC0CD',
  snackbar = '#101828',
  standardInputLine = '#8990A7',
  lightError = '#e57373',

  /*---background--- */
  backgroundPaper = '#FFFFFF',
  background = '#23252f',
  backgroundModal = '#F5F6FA',

  /*---common--- */
  white = '#FFFFFF',
  black = '#000000',
}

export default colors;
