import * as yup from 'yup';

const getValidationSchema = () => {
  return yup.object().shape({
    providerId: yup.string().required('Required field'),
    paymentMethodId: yup.string().required('Required field'),
    transactionType: yup.string().required('Required field'),
    paymentFeeFixed: yup.string().required('Required field'),
    paymentFeePercentage: yup.string().required('Required field'),
  });
};

export default getValidationSchema;
