import upperFirst from 'lodash/upperFirst';
import lowerCase from 'lodash/lowerCase';

/**
 * Converts a string to "sentence case" format.
 * @param {string} str - The input string to convert.
 * @returns {string} The string in "sentence case" format.
 */
export const sentenceCase = (str: string): string => {
  return upperFirst(lowerCase(str));
};
