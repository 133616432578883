import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from './store';
import { IStatus } from 'types/commonTypes';

/**
 * Represents the main data of a user profile.
 *
 * @interface
 * @property {string} userId - The unique identifier of the user.
 * @property {string} username - The username of the user.
 * @property {string} avatarUrl - The URL of the user's avatar.
 * @property {string} levelAura - The level aura of the user.
 * @property {string} firstName - The first name of the user.
 * @property {string} lastName - The last name of the user.
 * @property {string} status - The status of the user.
 * @property {number} profileViolationsCount - The count of profile violations for the user.
 * @property {number} contentViolationsCount - The count of content violations for the user.
 */
export interface UserProfileMainData {
  userId: string;
  username: string;
  avatarUrl: string;
  levelAura: string;
  firstName: string;
  lastName: string;
  status: string;
  profileViolationsCount: number;
  contentViolationsCount: number;
  activePaidContent?: UserActivePaidContent;
}

export interface UserActivePaidContent {
  offersCount: number;
  lifestylesCount: number;
  wishesCount: number;
  eventsCount: number;
}

/**
 * Represents the personal data of a user profile.
 *
 * @interface
 * @property {string} firstName - The first name of the user.
 * @property {string} lastName - The last name of the user.
 * @property {string} username - The username of the user.
 * @property {string} birthDate - The birthdate of the user.
 * @property {string} gender - The gender of the user.
 * @property {string} occupation - The occupation of the user.
 * @property {string} location - The location of the user.
 * @property {string} aboutMe - Information about the user.
 * @property {number} followersCount - The count of followers for the user.
 * @property {number} subscriptionsCount - The count of subscriptions for the user.
 * @property {string} phoneNumber - The phone number of the user.
 * @property {string} email - The email address of the user.
 * @property {string[]} passions - The list of passions or interests of the user.
 */
export interface UserProfilePersonalData {
  firstName: string;
  lastName: string;
  username: string;
  birthDate: string;
  gender: string;
  occupation: string;
  location: string;
  aboutMe: string;
  followersCount: number;
  subscriptionsCount: number;
  phoneNumber: string;
  email: string;
  passions: string[];
}

/**
 * Represents the payment information of a user profile.
 *
 * @interface
 * @property {number} balance - The user's balance.
 * @property {number} balancePendingEarned - The pending earned balance.
 * @property {number} balancePendingWithdrawal - The pending withdrawal balance.
 * @property {number} balanceAvailableWithdrawal - The available withdrawal balance.
 * @property {string} walletStatusCode - The status code of the wallet.
 */
export interface UserProfilePaymentInfo {
  balance: number;
  balancePendingEarned: number;
  balancePendingWithdrawal: number;
  balanceAvailableWithdrawal: number;
  walletStatusCode: string;
}

/**
 * Represents the state of the user profile.
 *
 * @interface
 * @property {{ main: UserProfileMainData; personal: UserProfilePersonalData; paymentInfo: UserProfilePaymentInfo }} data - The user profile data.
 * @property {IStatus} status - The status of the overall user profile state.
 * @property {IStatus} deleteStatus - The status of the delete operation for the user profile.
 * @property {IStatus} restoreStatus - The status of the restore operation for the user profile.
 * @property {IStatus} updateCommentStatus - The status of the update comment operation for the user profile.
 */
export interface UserProfileState {
  data: UserProfileData;
  status: IStatus;
  deleteStatus: IStatus;
  restoreStatus: IStatus;
  updateCommentStatus: IStatus;
}

/**
 * Represents user profile data.
 *
 * @interface
 * @property {UserProfileMainData} main - The main data of the user profile.
 * @property {UserProfilePersonalData} personal - The personal data of the user profile.
 * @property {UserProfilePaymentInfo} paymentInfo - The payment information of the user profile.
 */
export interface UserProfileData {
  main: UserProfileMainData;
  personal: UserProfilePersonalData;
  paymentInfo: UserProfilePaymentInfo;
}

const initialState: UserProfileState = {
  data: {
    main: {
      userId: '',
      username: '',
      avatarUrl: '',
      levelAura: '',
      firstName: '',
      lastName: '',
      status: '',
      profileViolationsCount: 0,
      contentViolationsCount: 0,
      activePaidContent: {
        offersCount: 0,
        wishesCount: 0,
        eventsCount: 0,
        lifestylesCount: 0,
      },
    },
    personal: {
      firstName: '',
      lastName: '',
      username: '',
      birthDate: '',
      gender: '',
      occupation: '',
      location: '',
      aboutMe: '',
      followersCount: 0,
      subscriptionsCount: 0,
      phoneNumber: '',
      email: '',
      passions: [],
    },
    paymentInfo: {
      balance: 0,
      balancePendingEarned: 0,
      balancePendingWithdrawal: 0,
      balanceAvailableWithdrawal: 0,
      walletStatusCode: '',
    },
  },
  status: 'idle',
  deleteStatus: 'idle',
  restoreStatus: 'idle',
  updateCommentStatus: 'idle',
};

export const fetchUserProfile = createAsyncThunk('userProfile/fetchUserProfile', async (profileId: string, thunkAPI) => {
  try {
    const backupProfileId = profileId ?? '4da07f22-f77c-494d-b86c-0766e070a17b';
    const response = await axios.get(process.env.REACT_APP_USERS_ENDPOINT + `timusers/users/${backupProfileId}`);
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      return thunkAPI.rejectWithValue({
        message: error.message || 'An unexpected error occurred',
        status: error.response?.status,
      });
    } else {
      return thunkAPI.rejectWithValue({
        message: 'An unexpected error occurred',
      });
    }
  }
});

export const fetchDeleteUser = createAsyncThunk(
  'userProfile/fetchDeleteUser',
  async ({ profileId, comment }: { profileId: string; comment: string }) => {
    const response = await axios.post(process.env.REACT_APP_USERS_ENDPOINT + `timusers/users/delete`, {
      profileId: profileId,
      comment: comment,
    });
    return response.data;
  },
);

export const fetchBanUser = createAsyncThunk(
  'userProfile/fetchBanUser',
  async ({ profileId, comment }: { profileId: string; comment: string }) => {
    const response = await axios.post(process.env.REACT_APP_USERS_ENDPOINT + `timusers/users/ban`, {
      profileId: profileId,
      comment: comment,
    });
    return response.data;
  },
);

export const fetchRestoreUser = createAsyncThunk(
  'userProfile/fetchRestoreUser',
  async ({ profileId }: { profileId: string }) => {
    const response = await axios.post(process.env.REACT_APP_USERS_ENDPOINT + `timusers/users/restore`, {
      profileId: profileId,
    });
    return response.data;
  },
);

export const fetchUpdateComment = createAsyncThunk(
  'userProfile/fetchUpdateComment',
  async ({ profileId, comment }: { profileId: string; comment: string }) => {
    const response = await axios.patch(process.env.REACT_APP_USERS_ENDPOINT + `timusers/users/deactivated/update`, {
      profileId: profileId,
      comment: comment,
    });
    return response.data;
  },
);

export const userProfileSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload;
      })
      .addCase(fetchUserProfile.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchDeleteUser.pending, (state) => {
        state.deleteStatus = 'loading';
      })
      .addCase(fetchDeleteUser.fulfilled, (state) => {
        state.deleteStatus = 'idle';
      })
      .addCase(fetchDeleteUser.rejected, (state) => {
        state.deleteStatus = 'idle';
      })
      .addCase(fetchRestoreUser.pending, (state) => {
        state.restoreStatus = 'loading';
      })
      .addCase(fetchRestoreUser.fulfilled, (state) => {
        state.restoreStatus = 'idle';
      })
      .addCase(fetchRestoreUser.rejected, (state) => {
        state.restoreStatus = 'idle';
      })
      .addCase(fetchUpdateComment.pending, (state) => {
        state.updateCommentStatus = 'loading';
      })
      .addCase(fetchUpdateComment.fulfilled, (state) => {
        state.updateCommentStatus = 'idle';
      })
      .addCase(fetchUpdateComment.rejected, (state) => {
        state.updateCommentStatus = 'idle';
      });
  },
});

export const selectUserProfile = (state: RootState): UserProfileData => state.userProfile.data;
export const selectUserProfileStatus = (state: RootState): IStatus => state.userProfile.status;
export const selectUpdateCommentStatus = (state: RootState): IStatus => state.userProfile.updateCommentStatus;

export default userProfileSlice.reducer;
