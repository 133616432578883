import { GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';

/**
 * Props for the RenderCreationDateCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 */
interface IRenderCreationDateCellProps {
  props: GridRenderCellParams;
}

/**
 * Renders a "Creation Date" cell in a data grid.
 */
export const RenderCreationDateCell = ({ props }: IRenderCreationDateCellProps) => {
  const { row } = props;

  if (!row.id) {
    return <></>;
  }

  const formattedDate = dayjs(row.createDate);

  return (
    <Stack>
      <Typography variant="body2">{formattedDate.format('MM/DD/YYYY')}</Typography>
      <Typography variant="caption" color="text.secondary">
        {formattedDate.format('h:mm A')}
      </Typography>
    </Stack>
  );
};

