import { createSvgIcon } from '@mui/material';

const RestoreIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.41504 10.2319L2.07295 8.77692C1.75466 8.43186 1.21691 8.41015 0.871842 8.72843C0.526777 9.04672 0.505067 9.58447 0.823352 9.92953L3.53538 12.8698C3.85367 13.2148 4.39142 13.2365 4.73649 12.9182L7.6767 10.2062C8.02177 9.88793 8.04348 9.35018 7.72519 9.00511C7.40691 8.66005 6.86916 8.63834 6.52409 8.95662L5.14566 10.2281C5.47995 8.62232 6.30051 7.10298 7.59796 5.90623C10.9065 2.85443 16.0626 3.06259 19.1144 6.37116C22.1662 9.67973 21.9581 14.8358 18.6495 17.8876C15.3409 20.9394 10.1848 20.7313 7.13302 17.4227C6.81474 17.0776 6.27698 17.0559 5.93192 17.3742C5.58685 17.6925 5.56514 18.2302 5.88343 18.5753C9.57179 22.574 15.8034 22.8256 19.8021 19.1372C23.8008 15.4489 24.0524 9.21725 20.364 5.21855C16.6756 1.21985 10.444 0.96827 6.44534 4.65664C4.78327 6.18972 3.76845 8.16333 3.41504 10.2319Z" />
  </svg>,
  'Restore',
);

export default RestoreIcon;
