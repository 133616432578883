import { Typography as TypographyMUI } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography/Typography';

type ExtraProps = {
  component?: React.ElementType;
};

const Typography = (props: TypographyProps & ExtraProps) => {
  return <TypographyMUI {...props}>{props.children}</TypographyMUI>;
};

export default Typography;
