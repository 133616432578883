import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from "store/store";
import axios from "axios";
import {IGender, ILocation} from "types/commonTypes";

export interface CommonState {
  genders: IGender[];
}

const initialState: CommonState = {
  genders: [],
};

export const fetchGenders = createAsyncThunk(
  'common/fetchGenders',
  async () => {
    const response = await axios.get((process.env.REACT_APP_COMMON_CLASSIFIER as string) + 'genders');
    return {...response.data};
  }
);

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGenders.fulfilled, (state, { payload }) => {
        state.genders = payload.genders;
      })
  },
});

export const selectGenders = (state: RootState): IGender[] => state.common.genders;

export default commonSlice.reducer;