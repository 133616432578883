import colors from 'theme/constants/colors';
import { ISortingItem } from 'types/commonTypes';
import {
  ITaxModerationCardSolution,
  ITaxModerationCardStatus,
  TaxModerationSolution,
  TaxModerationStatus,
} from 'store/slices/TaxModeration/interface';

export const defaultTaxModerationSortingModel: ISortingItem = {
  columnId: 'sales_tax_moderation_created_at',
  title: 'Received',
  sortingType: 'DESC',
};

export const taxModerationCardSolutions: ITaxModerationCardSolution[] = [
  {
    solution: TaxModerationSolution.APPROVED,
    title: 'Approved',
    color: colors.success,
  },
  {
    solution: TaxModerationSolution.MODIFIED,
    title: 'Modified',
    color: colors.warning,
  },
  {
    solution: TaxModerationSolution.ASSIGN_TO_SUPERVISOR,
    title: 'Assigned to Supervisor',
    color: colors.warning,
  },
  {
    solution: TaxModerationSolution.AUTO_APPROVED,
    title: 'Approved',
    color: colors.success,
  },
  {
    solution: TaxModerationSolution.NEEDS_REVIEW,
    title: 'Unresolved',
    color: colors.warning,
  },
];

export const taxModerationCardStatuses: ITaxModerationCardStatus[] = [
  {
    status: TaxModerationStatus.NEEDS_REVIEW,
    title: 'Unresolved',
    color: colors.warning,
  },
  {
    status: TaxModerationStatus.CALCULATED,
    title: 'Solved',
    color: colors.success,
  },
  {
    status: TaxModerationStatus.ASSIGN_TO_SUPERVISOR,
    title: 'Assigned to Supervisor',
    color: colors.success,
  },
  {
    status: TaxModerationStatus.RESOLVED,
    title: 'Solved',
    color: colors.success,
  },
];

export const TaxModerationCardSolutionItems: Omit<ITaxModerationCardSolution, 'color'>[] = [
  {
    solution: TaxModerationSolution.APPROVED,
    title: 'Approve',
  },
  {
    solution: TaxModerationSolution.MODIFIED,
    title: 'Modify',
  },
];

export const enum TaxModerationListType {
  SOLVED = 'SOLVED',
  UNRESOLVED = 'UNRESOLVED',
}

export const UNRESOLVED_FILTERS_KIND = 'unresolved_sales_tax_moderation';
export const SOLVED_FILTER_KIND = 'solved_sales_tax_moderation';
