import { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { TaxModerationListType } from '../constants';
import { TaxModerationRoutes } from 'routes/enum';
import { useAppSelector } from 'store/hooks';
import { selectTaxModerationCardIds } from 'store/slices/TaxModeration/taxModerationSlice';
import GoodJob from 'assets/img/GoodJob';
import Button from 'components/shared/Button/Button';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';

export const CustomNoCardsOverlay = () => {
  const allIds = useAppSelector(selectTaxModerationCardIds);
  const navigate = useNavigate();

  useEffect(() => {
    if (allIds.length !== 0) {
      navigate(TaxModerationRoutes.TAX_MODERATION, { state: { listType: TaxModerationListType.UNRESOLVED } });
    }
  }, [allIds.length]);

  return (
    <motion.div
      initial={{ opacity: 0, margin: 'auto' }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Stack mt={2} alignItems="center">
        <Typography variant="errorTitle">The end</Typography>
        <Typography variant="errorSubtitle" color="text.secondary">
          No unresolved request cards
        </Typography>
        <GoodJob />
        <Button
          color="primary"
          variant="contained"
          onClick={() =>
            navigate(TaxModerationRoutes.TAX_MODERATION, { state: { listType: TaxModerationListType.SOLVED } })
          }
        >
          Go to solved cards
        </Button>
      </Stack>
    </motion.div>
  );
};
