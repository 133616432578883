import { createSvgIcon } from '@mui/material';

const ZoomInIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.2471 11.0585C7.2471 10.5891 7.62766 10.2085 8.0971 10.2085H10.2085V8.09707C10.2085 7.62763 10.5891 7.24707 11.0585 7.24707C11.528 7.24707 11.9085 7.62763 11.9085 8.09707V10.2085H14.02C14.4894 10.2085 14.87 10.5891 14.87 11.0585C14.87 11.5279 14.4894 11.9085 14.02 11.9085H11.9085V14.0199C11.9085 14.4894 11.528 14.8699 11.0585 14.8699C10.5891 14.8699 10.2085 14.4894 10.2085 14.0199V11.9085H8.0971C7.62766 11.9085 7.2471 11.5279 7.2471 11.0585Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.0198 17.2219C14.6628 18.3156 12.9371 18.9704 11.0585 18.9704C6.68881 18.9704 3.14651 15.4281 3.14651 11.0584C3.14651 6.68878 6.68881 3.14648 11.0585 3.14648C15.4281 3.14648 18.9704 6.68878 18.9704 11.0584C18.9704 12.9371 18.3156 14.6628 17.2219 16.0198L20.6044 19.4023C20.9363 19.7342 20.9363 20.2724 20.6044 20.6043C20.2724 20.9363 19.7342 20.9363 19.4023 20.6043L16.0198 17.2219ZM4.84651 11.0584C4.84651 7.62767 7.6277 4.84648 11.0585 4.84648C14.4892 4.84648 17.2704 7.62767 17.2704 11.0584C17.2704 12.7645 16.5826 14.3099 15.4692 15.4326C15.463 15.4385 15.4568 15.4445 15.4507 15.4506C15.4445 15.4567 15.4385 15.4629 15.4326 15.4692C14.31 16.5826 12.7645 17.2704 11.0585 17.2704C7.6277 17.2704 4.84651 14.4892 4.84651 11.0584Z" />
  </svg>,
  'ZoomIn',
);

export default ZoomInIcon;
