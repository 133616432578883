import React, { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import isEqual from 'lodash/isEqual';
import uniqueId from 'lodash/uniqueId';
import { useErrorBoundary } from 'react-error-boundary';
import { enqueueSnackbar, VariantType } from 'notistack';
import { currencyTooltipTitles, defaultTiersSortingModel, possibleTierStatuses } from './constants';
import { possibleOperationTypes } from 'containers/Payments/views/Operations/constants';
import { Permissions } from 'types/commonTypes';
import { ITier, ITierStatus } from 'store/slices/Settings/interface';
import { checkPermissions } from 'utils/checkPermissions';
import { currencyFormatter } from 'utils/currencyFormatter';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  fetchTiers,
  selectTiers,
  selectTiersStatus,
  fetchChangeStatus,
  selectTotalCount,
  setTiersStatus,
} from 'store/slices/Settings/tiersSlice';
import {
  clearFilters,
  fetchFilters,
  reverseFilters,
  selectFilters,
  selectFiltersCurrentData,
  selectFiltersData,
} from 'store/filtersSlice';
import { fetchSortingColumns, selectSortingColumns, setSortingData } from 'store/sortingSlice';
import { selectUserPermissions } from 'store/authSlice';
import DeleteIcon from 'assets/img/DeleteIcon';
import EditIcon from 'assets/img/EditIcon';
import FilterIcon from 'assets/img/FilterIcon';
import ActiveFilterList from 'components/ActiveFilterList/ActiveFilterList';
import Button from 'components/shared/Button/Button';
import CustomFooter from 'components/shared/DataGrid/CustomFooter/CustomFooter';
import CustomNoRowsOverlay from 'components/shared/DataGrid/CustomNoResultsOverlay/CustomNoRowsOverlay';
import Drawer from 'components/shared/Drawer/Drawer';
import FilterDrawer from 'components/FilterDrawer/FilterDrawer';
import Grid from 'components/shared/Grid/Grid';
import ListItemIcon from 'components/shared/List/ListItem/ListItemComponents/ListItemIcon/ListItemIcon';
import ListItemText from 'components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText';
import Menu from 'components/shared/Menu/Menu';
import MenuItem from 'components/shared/Menu/MenuItem/MenuItem';
import RenderCellWithCopy from 'components/shared/DataGrid/RenderCellWithCopy/RenderCellWithCopy';
import RenderCellWithTooltip from 'components/shared/DataGrid/RenderCellWithTooltip/RenderCellWithTooltip';
import SortingChip from 'components/SortingChip/SortingChip';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';
import { CloseFilterDrawerConfirmModal } from 'components/FilterDrawer/CloseFilterDrawerConfirmModal';
import { RenderStatusSelect } from './components';
import { AddTierModal } from './modals';
import './index.scss';

export const InAppTiers: FC = () => {
  const dispatch = useAppDispatch();

  const userPermissions = useAppSelector(selectUserPermissions);
  const data = useAppSelector(selectTiers);
  const totalCount = useAppSelector(selectTotalCount);
  const status = useAppSelector(selectTiersStatus);
  const filters = useAppSelector(selectFilters);
  const filtersData = useAppSelector(selectFiltersData);
  const filtersCurrentData = useAppSelector(selectFiltersCurrentData);
  const sortingColumns = useAppSelector(selectSortingColumns);
  let isLoading = status === 'loading';

  const filtersLength = useMemo(() => {
    return Object.values(filtersData).filter((item: any) => item !== null).length;
  }, [filtersData]);

  const { showBoundary } = useErrorBoundary();
  const [isAddingTier, setIsAddingTier] = useState(false);

  const [filterDrawer, setFilterDrawer] = useState<boolean>(false);
  const [filterDrawerCloseConfirmModal, setFilterDrawerCloseConfirmModal] = useState<boolean>(false);
  const [fetchingError, setFetchingError] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const [tableMenuAnchorEl, setTableMenuAnchorEl] = useState<null | HTMLElement>(null);
  const tableMenu = Boolean(tableMenuAnchorEl);

  const [statusMenuAnchorEl, setStatusMenuAnchorEl] = useState<null | HTMLElement>(null);
  const statusMenuOpen = Boolean(statusMenuAnchorEl);

  const [currentSelectedStatus, setCurrentSelectedStatus] = useState<ITierStatus | null>(null);
  const [currentSelectedTier, setCurrentSelectedTier] = useState<ITier | null>(null);
  const [highlightedTier, setHighlightedTier] = useState<{ linkId: string; referenceName: string } | null>(null);

  const onFilterSidebarClose = useCallback(
    (apply?: boolean) => {
      if (isEqual(filtersData, filtersCurrentData) || apply === true) {
        setFilterDrawerCloseConfirmModal(false);
        setFilterDrawer(false);
        dispatch(reverseFilters());
      } else {
        setFilterDrawerCloseConfirmModal(true);
      }
    },
    [filtersData, filtersCurrentData],
  );

  const cancelCloseFilterSidebar = useCallback((event?: any, reason?: any) => {
    if (reason && reason === 'backdropClick') return;
    setFilterDrawerCloseConfirmModal(false);
  }, []);

  const handleStatusMenuClick = (event: MouseEvent<HTMLDivElement>, data: ITier) => {
    event.stopPropagation();
    setStatusMenuAnchorEl(event.currentTarget);
    setCurrentSelectedStatus(null);
    setCurrentSelectedTier(data);
  };

  const handleTableMenuClose = () => {
    setTableMenuAnchorEl(null);
  };

  const handleStatusMenuClose = () => {
    setStatusMenuAnchorEl(null);
  };

  const handleChangeStatusClick = (data: ITierStatus) => {
    setCurrentSelectedStatus(data);
    handleStatusMenuClose();
  };

  const handleChangePage = (page: number) => {
    setPage(page);
    void fetchTiersGrid(page, pageSize);
  };

  const handleChangePageSize = (pageSize: number) => {
    setPage(0);
    setPageSize(pageSize);
    void fetchTiersGrid(0, pageSize);
  };

  const fetchTiersInitial = useCallback(async (page: number, pageSize: number) => {
    try {
      await dispatch(fetchTiers({ page, pageSize })).unwrap();
    } catch (error) {
      showBoundary(error);
    }
  }, []);

  const fetchTiersGrid = useCallback(async (page: number, pageSize: number) => {
    try {
      await dispatch(fetchTiers({ page, pageSize })).unwrap();
      setFetchingError(false);
    } catch (error) {
      enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType });
      setFetchingError(true);
    }
  }, []);

  const repeatFetchingRequest = useCallback(async () => {
    fetchTiersGrid(page, pageSize);
  }, [page, pageSize]);

  const resetFilters = useCallback(async () => {
    dispatch(clearFilters());
    await dispatch(fetchTiers({ page, pageSize }));
  }, []);

  const initialFetchData = useCallback(async () => {
    dispatch(setTiersStatus('loading'));
    await dispatch(fetchFilters('tiers'));
    await dispatch(fetchSortingColumns('tiers'));
    if (filters && sortingColumns) {
      dispatch(setSortingData([defaultTiersSortingModel]));
      await fetchTiersInitial(page, pageSize);
    }
  }, [filters, sortingColumns, page, pageSize]);

  const handleChangeStatus = useCallback(async () => {
    const result = await dispatch(
      fetchChangeStatus({
        currentSelectedTier: currentSelectedTier,
        currentSelectedStatus: currentSelectedStatus,
      }),
    );

    if (result.meta.requestStatus === 'fulfilled') {
      void fetchTiersGrid(0, pageSize);
    } else if (result.meta.requestStatus === 'rejected') {
      enqueueSnackbar(result.payload.title ?? `An error occurred, try again later`, {
        variant: 'error' as VariantType,
      });
    }
  }, [currentSelectedStatus, currentSelectedTier]);

  const handleSortingModelApply = async () => {
    await fetchTiersGrid(page, pageSize);
  };

  const handleAddTierModalClose = (isAddedNewTier?: boolean, newTier?: ITier) => {
    setIsAddingTier(false);
    if (isAddedNewTier && newTier) {
      dispatch(fetchTiers({ page: page, pageSize: pageSize })).then(() => {
        if (!isLoading) {
          setHighlightedTier({
            linkId: newTier?.linkId,
            referenceName: newTier?.name,
          });
          setTimeout(() => {
            setHighlightedTier(null);
          }, 2000);
        }
      });
    }
  };

  useEffect(() => {
    dispatch(clearFilters());
    initialFetchData();
  }, []);

  useEffect(() => {
    currentSelectedStatus?.status !== currentSelectedTier?.status && currentSelectedStatus && handleChangeStatus();
  }, [currentSelectedStatus, currentSelectedTier, handleChangeStatus]);

  const columns: GridColDef[] = [
    {
      field: 'linkId',
      headerName: 'Link ID',
      flex: 0.5,
      minWidth: 140,
      sortable: false,
      renderCell: (params) => <RenderCellWithCopy isWrappedText props={params} />,
    },
    {
      field: 'provider',
      headerName: 'Provider',
      flex: 0.3,
      minWidth: 100,
      sortable: false,
      renderCell: ({ value }) => <span>{value ? value : '—'}</span>,
    },
    {
      field: 'productId',
      headerName: 'Product ID',
      flex: 0.5,
      minWidth: 140,
      sortable: false,
      renderCell: ({ value }) => <span>{value ? value : '—'}</span>,
    },
    {
      field: 'referenceName',
      headerName: 'Reference name',
      flex: 0.5,
      minWidth: 140,
      sortable: false,
      renderCell: ({ value }) => <span>{value ? value : '—'}</span>,
    },
    {
      field: 'price',
      headerName: 'Price',
      flex: 0.3,
      minWidth: 100,
      sortable: false,
      type: 'number',
      renderCell: ({ value }) => <span>{currencyFormatter(value, 'en-US', 'USD')}</span>,
    },
    {
      field: 'country',
      headerName: 'Country',
      flex: 0.3,
      minWidth: 100,
      sortable: false,
      renderCell: ({ value }) => <span>{value ? value : '—'}</span>,
    },
    {
      field: 'currency',
      headerName: 'Currency',
      flex: 0.3,
      minWidth: 100,
      sortable: false,
      renderCell: (params) => (
        <RenderCellWithTooltip props={params} title={currencyTooltipTitles && currencyTooltipTitles[params.value]} />
      ),
    },
    {
      field: 'tierOperationType',
      headerName: 'Operation type',
      flex: 0.3,
      minWidth: 130,
      sortable: false,
      renderCell: ({ value }) => (
        <span>
          {value
            ? possibleOperationTypes.find((el) => value?.toLowerCase() === el.operationType?.toLowerCase())?.tierTitle
            : '—'}
        </span>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.4,
      minWidth: 100,
      sortable: false,
      renderCell: (params) => (
        <RenderStatusSelect
          props={params}
          selectedCellData={currentSelectedTier}
          isMenuOpen={statusMenuOpen}
          onClick={(e, data) => handleStatusMenuClick(e, data)}
        />
      ),
    },
  ];

  const rows: ITier[] | [] = useMemo(() => {
    if (data) {
      return data;
    } else return [];
  }, [data]);

  const isEmptyData = !rows.length;
  const isDisabledFilterButton = !((!isEmptyData && !fetchingError) || !!filtersLength);

  return (
    <div className="tiers-table-container">
      <div className="tiers-table-container__actions">
        <Stack gap={2} direction="row" alignItems="center">
          <Button
            disabled={isDisabledFilterButton}
            size="small"
            startIcon={<FilterIcon />}
            variant="outlined"
            onClick={() => setFilterDrawer(true)}
          >
            Filter {!!filtersLength && `(${filtersLength})`}
          </Button>
          <SortingChip
            disabled={isEmptyData}
            defaultSortingModel={defaultTiersSortingModel}
            onSortingModelApply={() => handleSortingModelApply()}
          />
        </Stack>
        {checkPermissions(userPermissions, [Permissions.SETTINGS_ADD_IN_APP_TIER]) && (
          <Button variant="contained" onClick={() => setIsAddingTier(true)}>
            <Typography variant="buttonMedium">Add tier</Typography>
          </Button>
        )}
      </div>
      {filtersLength !== 0 && <ActiveFilterList refreshData={() => fetchTiersGrid(page, pageSize)} />}
      <Grid
        className="tiers-table"
        columns={columns}
        rows={rows}
        getRowId={(row) => row.productId + uniqueId()}
        loading={isLoading}
        getRowHeight={() => 'auto'}
        getRowSpacing={() => ({
          bottom: 16,
        })}
        getRowClassName={(params) =>
          `${
            params.row.linkId === highlightedTier?.linkId && params.row.referenceName === highlightedTier?.referenceName
              ? 'tiers-table__highlighted-row'
              : ''
          }`
        }
        page={page}
        pageSize={pageSize}
        pagination
        paginationMode="server"
        filterMode="server"
        sortingMode="server"
        rowCount={totalCount}
        rowsPerPageOptions={[10, 25, 50, 100]}
        hideFooterSelectedRowCount
        disableColumnMenu
        disableColumnReorder
        disableColumnResize
        disableSelectionOnClick
        onPageChange={(newPage) => handleChangePage(newPage)}
        onPageSizeChange={(newPageSize) => handleChangePageSize(newPageSize)}
        components={{
          NoRowsOverlay: () =>
            CustomNoRowsOverlay({
              isFiltersApplied: !!filtersLength,
              fetchingError: fetchingError,
              clearFilters: () => resetFilters(),
              refreshData: () => repeatFetchingRequest(),
              renderNoDataComponent: () => (
                <>
                  <Typography color="text.secondary" variant="body2">
                    No tiers has been added yet
                  </Typography>
                  {checkPermissions(userPermissions, [Permissions.SETTINGS_ADD_IN_APP_TIER]) && (
                    <Button onClick={() => setIsAddingTier(true)} variant="text" color="primary">
                      <Typography variant="buttonMedium">Add tier</Typography>
                    </Button>
                  )}
                </>
              ),
            }),
          Footer: CustomFooter,
        }}
        hideFooter={isEmptyData && !fetchingError}
      />
      <Menu
        id="table-menu"
        anchorEl={tableMenuAnchorEl}
        open={tableMenu}
        onClose={handleTableMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'table-button',
        }}
      >
        <MenuItem>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <Menu
        id="status-menu"
        anchorEl={statusMenuAnchorEl}
        open={statusMenuOpen}
        onClose={handleStatusMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {possibleTierStatuses.map((status: ITierStatus) => (
          <MenuItem
            key={uniqueId()}
            selected={status.status === currentSelectedTier?.status}
            onClick={() => handleChangeStatusClick(status)}
          >
            {status.title}
          </MenuItem>
        ))}
      </Menu>
      <AddTierModal
        open={isAddingTier}
        onClose={(isAddedNewTier, newTier) => handleAddTierModalClose(isAddedNewTier, newTier)}
      />
      <CloseFilterDrawerConfirmModal
        open={filterDrawerCloseConfirmModal}
        onClose={() => onFilterSidebarClose(true)}
        closeModal={() => cancelCloseFilterSidebar()}
      />
      <Drawer anchor="right" open={filterDrawer} onClose={() => onFilterSidebarClose()}>
        <FilterDrawer
          type="tiers"
          isHidePresets
          onClose={onFilterSidebarClose}
          onApply={() => {
            onFilterSidebarClose(true);
            setPage(0);
            void fetchTiersGrid(0, pageSize);
          }}
        />
      </Drawer>
    </div>
  );
};
