import { MouseEvent } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { IPaymentFee, PaymentFeesStatuses } from 'store/slices/Settings/interface';
import { possiblePaymentFeesStatuses } from '../constants';
import { Permissions } from 'types/commonTypes';
import { checkPermissions } from 'utils/checkPermissions';
import { useAppSelector } from 'store/hooks';
import { selectUserPermissions } from 'store/authSlice';
import ChevronDownIcon from 'assets/img/ChevronDownIcon';
import ChevronUpIcon from 'assets/img/ChevronUpIcon';
import Typography from 'components/shared/Typography/Typography';

/**
 * Props for the RenderStatusSelect component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 * @property {boolean} isMenuOpen - If `true`, the status menu is shown.
 * @property {IPaymentFee|null} [selectedCellData] - Row data for selected cell.
 * @property {(e: MouseEvent<HTMLDivElement>, data: any) => void} onClick - Click event handler.
 */
interface IRenderStatusSelectProps {
  props: GridRenderCellParams;
  isMenuOpen: boolean;
  selectedCellData?: IPaymentFee | null;
  onClick: (e: MouseEvent<HTMLDivElement>, data: any) => void;
}

/**
 * Renders a status select cell in a data grid.
 */
export const RenderStatusSelect = ({ props, isMenuOpen, selectedCellData, onClick }: IRenderStatusSelectProps) => {
  const { row } = props;
  const userPermissions = useAppSelector(selectUserPermissions);

  if (!row.providerTariffId) {
    return <></>;
  }
  const isInactive = row.feeStatus?.toUpperCase() === PaymentFeesStatuses.INACTIVE;
  const hasAccess = checkPermissions(userPermissions, [Permissions.SETTINGS_CHANGE_PAYMENT_FEE_STATUS]);
  const currentStatus = possiblePaymentFeesStatuses.find(
    (el) => row.feeStatus?.toLowerCase() === el.status.toLowerCase(),
  );

  const handleClick = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    if (isInactive && hasAccess) {
      onClick(e, row);
    }
  };

  return (
    <div
      onClick={(e) => handleClick(e)}
      className={`fees-table__status-select${!(isInactive && hasAccess) ? '--no-pointer' : ''}`}
      style={{ color: currentStatus?.color }}
    >
      <Typography variant="chip" color={{ color: currentStatus?.color }}>
        {row.feeStatus?.replace(row.feeStatus, currentStatus?.title)}
      </Typography>
      {isInactive &&
        hasAccess &&
        (selectedCellData?.providerTariffId === row.providerTariffId && isMenuOpen ? (
          <ChevronUpIcon />
        ) : (
          <ChevronDownIcon />
        ))}
    </div>
  );
};
