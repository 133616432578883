import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { IStatus } from 'types/commonTypes';
import { RootState } from './store';
import ContentDisplayTabs from 'components/ContentDisplay/types/ContentDisplayTabs';

export interface UsersState {
  data: any[];
  totalElements: number;
  status: IStatus;
  eventsStatus: IStatus;
  offersStatus: IStatus;
  wishesStatus: IStatus;
  lifestyleStatus: IStatus;
  refreshStatus: IStatus;
  currentTabFetching: string;
}

const initialState: UsersState = {
  data: [],
  totalElements: 0,
  status: 'idle',
  eventsStatus: 'idle',
  offersStatus: 'idle',
  wishesStatus: 'idle',
  lifestyleStatus: 'idle',
  refreshStatus: 'idle',
  currentTabFetching: '',
};

export const fetchContent = createAsyncThunk(
  'contentDisplay/fetchContent',
  async ({ searchValue, tab, pageSize }: { searchValue: string; tab: string; pageSize: number }, thunkAPI) => {
    try {
      const rootState: RootState = (await thunkAPI.getState()) as RootState;
      const filters = Object.keys(rootState.filters.data)
        ? Object.values(rootState.filters.data).filter((item: any) => item !== null)
        : [];
      const sortingList = Object.keys(rootState.sorting.data)
        ? Object.values(rootState.sorting.data).filter(
            (item: any) => item !== null && item.columnId !== null && item.sortingType !== null,
          )
        : [];
      const response = await axios.post(
        process.env.REACT_APP_CONTENT_DISPLAY_ENDPOINT + `content/screen/${tab}`,
        {
          pageInfo: {
            page: 1,
            size: pageSize,
          },
          filters: filters,
          sortingList: sortingList,
          searchValue: searchValue,
        },
        { signal: thunkAPI.signal },
      );

      return { data: response.data, entityType: tab };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue({
          message: error.message || 'An unexpected error occurred',
          status: error.response?.status,
        });
      } else {
        return thunkAPI.rejectWithValue({
          message: 'An unexpected error occurred',
        });
      }
    }
  },
);

export const fetchUpdateContentStatus = createAsyncThunk(
  'contentDisplay/fetchUpdateContentStatus',
  async ({ entityType, entityId, status }: { entityType: string; entityId: string; status: string | null }) => {
    const response = await axios.post(process.env.REACT_APP_CONTENT_DISPLAY_ENDPOINT + 'content/recommender-status', {
      entityType: entityType,
      entityId: entityId,
      status: status,
    });

    return { data: response.data, entityId: entityId, status: status };
  },
);

export const contentDisplaySlice = createSlice({
  name: 'contentDisplay',
  initialState,
  reducers: {
    clearContentDisplayData: (state) => {
      state.data = [];
    },
    setRefreshContentStatus: (state, action) => {
      state.refreshStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContent.pending, (state, action) => {
        state.status = 'loading';
        state.currentTabFetching = action.meta.arg.tab;
        switch (action.meta.arg.tab) {
          case ContentDisplayTabs.WISHES:
            state.wishesStatus = 'loading';
            break;
          case ContentDisplayTabs.EVENTS:
            state.eventsStatus = 'loading';
            break;
          case ContentDisplayTabs.OFFERS:
            state.offersStatus = 'loading';
            break;
          case ContentDisplayTabs.LIFESTYLES:
            state.lifestyleStatus = 'loading';
            break;
        }
      })
      .addCase(fetchContent.fulfilled, (state, action) => {
        state.status = 'idle';
        state.refreshStatus = 'idle';
        if (state.currentTabFetching === action.payload.entityType) {
          state.data = action.payload.data.content;
          state.totalElements = action.payload.data.totalElements;
        }
        switch (action.payload.entityType) {
          case ContentDisplayTabs.WISHES:
            state.wishesStatus = 'idle';
            break;
          case ContentDisplayTabs.EVENTS:
            state.eventsStatus = 'idle';
            break;
          case ContentDisplayTabs.OFFERS:
            state.offersStatus = 'idle';
            break;
          case ContentDisplayTabs.LIFESTYLES:
            state.lifestyleStatus = 'idle';
            break;
        }
      })
      .addCase(fetchContent.rejected, (state) => {
        state.status = 'failed';
        state.refreshStatus = 'idle';
      })
      .addCase(fetchUpdateContentStatus.fulfilled, (state, action) => {
        const elemIndex = state.data.findIndex((elem: any) => elem.entityId === action.payload.entityId);
        state.data[elemIndex].recommenderStatus =
          state.data[elemIndex].recommenderStatus === action.payload.status ? null : action.payload.status;
      });
  },
});

export const selectContentDisplayTotalElements = (state: RootState): number => state.contentDisplay.totalElements;
export const selectContentDisplayStatus = (state: RootState): IStatus => state.contentDisplay.status;
export const selectEventsStatus = (state: RootState): IStatus => state.contentDisplay.eventsStatus;
export const selectWishesStatus = (state: RootState): IStatus => state.contentDisplay.wishesStatus;
export const selectOffersStatus = (state: RootState): IStatus => state.contentDisplay.offersStatus;
export const selectLifestyleStatus = (state: RootState): IStatus => state.contentDisplay.lifestyleStatus;
export const selectContentDisplayRefreshStatus = (state: RootState): IStatus => state.contentDisplay.refreshStatus;
export const selectContentDisplayData = (state: RootState): any[] => state.contentDisplay.data;

export const { clearContentDisplayData, setRefreshContentStatus } = contentDisplaySlice.actions;

export default contentDisplaySlice.reducer;
