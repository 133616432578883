import { useEffect, useState } from 'react';
import { LocalStorageKeys } from 'types/commonTypes';
import Button from 'components/shared/Button/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Dialog from 'components/shared/Dialog/Dialog';
import DialogActions from 'components/shared/Dialog/DialogActions/DialogActions';
import DialogContent from 'components/shared/Dialog/DialogContent/DialogContent';
import DialogTitle from 'components/shared/Dialog/DialogTitle/DialogTitle';
import FormControl from 'components/shared/FormControl/FormControl';
import FormControlLabel from 'components/shared/FormControlLabel/FormControlLabel';
import Typography from 'components/shared/Typography/Typography';

interface IApprovalConfirmModalProps {
  open: boolean;
  onClose: () => void;
  onRequestSend: () => void;
}

export const ApprovalConfirmModal = ({ open, onClose, onRequestSend }: IApprovalConfirmModalProps) => {
  const localData = localStorage.getItem(LocalStorageKeys.CONSUMPTION_REQUEST_SENDING_CONFIRM_SKIP);
  const consumptionRequestSendingConfirmSkip = localData !== null ? !!JSON.parse(localData) : false;

  const [showApprovalConfirmModal, setShowApprovalConfirmModal] = useState<boolean>(
    consumptionRequestSendingConfirmSkip,
  );

  const handleCloseModal = () => {
    setShowApprovalConfirmModal(false);
    localStorage.setItem(LocalStorageKeys.CONSUMPTION_REQUEST_SENDING_CONFIRM_SKIP, JSON.stringify(false));
    onClose();
  };

  useEffect(() => {
    localStorage.setItem(
      LocalStorageKeys.CONSUMPTION_REQUEST_SENDING_CONFIRM_SKIP,
      JSON.stringify(showApprovalConfirmModal),
    );
  }, [showApprovalConfirmModal]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle variant="h6">Send a response to the provider?</DialogTitle>
      <DialogContent>
        <Typography mb={3} variant="body1">
          You will not be able to change your choice after submitting
        </Typography>
        <FormControl fullWidth>
          <FormControlLabel
            sx={{ pl: 1 }}
            control={
              <Checkbox
                disableRipple
                checked={showApprovalConfirmModal}
                onChange={(e) => setShowApprovalConfirmModal(e.target.checked)}
              />
            }
            label="Don't show this confirmation again. Instantly send"
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal} variant="text" color="primary">
          Cancel
        </Button>
        <Button onClick={onRequestSend} variant="contained" color="primary">
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};
