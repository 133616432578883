import { createSvgIcon } from '@mui/material';

const SortIcon = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.86016 3.4999C9.86016 3.03046 9.4796 2.6499 9.01016 2.6499C8.54071 2.6499 8.16016 3.03046 8.16016 3.4999V18.451L4.59058 14.8885C4.2583 14.5569 3.72011 14.5574 3.3885 14.8897C3.05688 15.222 3.05742 15.7602 3.3897 16.0918L8.4097 21.1018C8.74197 21.4334 9.28016 21.4329 9.61178 21.1006C9.71197 21.0002 9.78185 20.881 9.82141 20.7544C9.84659 20.674 9.86016 20.5886 9.86016 20.4999V3.4999Z"/>
    <path d="M14.5798 2.71424C14.6799 2.75562 14.7738 2.81695 14.8552 2.89826L19.8752 7.90826C20.2075 8.23988 20.208 8.77807 19.8764 9.11034C19.5448 9.44262 19.0066 9.44316 18.6743 9.11154L15.1048 5.54909V20.4999C15.1048 20.9693 14.7242 21.3499 14.2548 21.3499C13.7853 21.3499 13.4048 20.9693 13.4048 20.4999V3.4999C13.4048 3.50001 13.4048 3.4998 13.4048 3.4999C13.4048 3.38492 13.4277 3.27495 13.4691 3.17493C13.5099 3.07632 13.57 2.98383 13.6494 2.90324C13.6514 2.90122 13.6534 2.89922 13.6554 2.89722C13.8091 2.74437 14.0209 2.6499 14.2548 2.6499"/>
    <path d="M14.2548 2.6499C14.3698 2.64992 14.4797 2.67279 14.5798 2.71424Z" />
    </svg>, 'Sort'
  )

export default SortIcon;

