import { createSvgIcon } from '@mui/material';

const MoneyIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.8497 6.16698C12.8497 5.69754 12.4691 5.31698 11.9997 5.31698C11.5303 5.31698 11.1497 5.69754 11.1497 6.16698V6.55505C9.54101 6.86342 8.23303 8.14414 8.23303 9.8791H9.08303C8.23303 9.8791 8.23303 9.88027 8.23303 9.88027L8.23303 9.88149L8.23304 9.88409L8.23309 9.88992L8.23333 9.90403C8.23356 9.91452 8.23396 9.92717 8.23463 9.94184C8.23597 9.97115 8.23841 10.0087 8.24279 10.0533C8.25153 10.1423 8.26819 10.2612 8.30008 10.4004C8.36343 10.6769 8.49032 11.0477 8.74718 11.4213C9.29338 12.2157 10.3048 12.8503 11.9997 12.8503C13.2214 12.8503 13.6684 13.2763 13.8513 13.5425C13.9591 13.6992 14.0145 13.8587 14.0423 13.98C14.0559 14.0397 14.0621 14.0864 14.0647 14.1135C14.0656 14.1223 14.0661 14.1288 14.0663 14.1327C14.0597 15.0138 13.2706 15.817 12.0969 15.817C11.2767 15.817 10.5953 15.4234 10.2862 14.9378C10.0341 14.5418 9.50869 14.4251 9.11268 14.6772C8.71667 14.9293 8.6 15.4547 8.85209 15.8507C9.35343 16.6383 10.1977 17.1874 11.1497 17.4085V17.8336C11.1497 18.3031 11.5303 18.6836 11.9997 18.6836C12.4691 18.6836 12.8497 18.3031 12.8497 17.8336V17.4456C14.4584 17.1372 15.7664 15.8565 15.7664 14.1215H14.9309C15.7664 14.1215 15.7664 14.1204 15.7664 14.1204L15.7664 14.1191L15.7663 14.1165L15.7663 14.1107L15.7661 14.0966C15.7658 14.0861 15.7654 14.0735 15.7648 14.0588C15.7634 14.0295 15.761 13.9919 15.7566 13.9473C15.7479 13.8583 15.7312 13.7394 15.6993 13.6002C15.636 13.3238 15.5091 12.953 15.2522 12.5794C14.706 11.7849 13.6946 11.1503 11.9997 11.1503C10.778 11.1503 10.331 10.7243 10.148 10.4582C10.0403 10.3015 9.98492 10.142 9.95712 10.0207C9.94344 9.96097 9.93731 9.9142 9.93465 9.88717C9.93379 9.87836 9.93332 9.87187 9.93307 9.86796C9.93967 8.98681 10.7288 8.18365 11.9025 8.18365C12.7226 8.18365 13.4041 8.57722 13.7132 9.06283C13.9653 9.45884 14.4907 9.57551 14.8867 9.32342C15.2827 9.07133 15.3994 8.54594 15.1473 8.14992C14.646 7.36236 13.8017 6.8132 12.8497 6.59214V6.16698Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12 23.35C18.2685 23.35 23.35 18.2684 23.35 12C23.35 5.73156 18.2684 0.649994 12 0.649994C5.73159 0.649994 0.650024 5.73156 0.650024 12C0.650024 18.2684 5.73159 23.35 12 23.35ZM21.65 12C21.65 17.3295 17.3296 21.65 12 21.65C6.67048 21.65 2.35002 17.3295 2.35002 12C2.35002 6.67045 6.67047 2.35 12 2.35C17.3296 2.35 21.65 6.67045 21.65 12Z" />
  </svg>,
  'Money',
);

export default MoneyIcon;
