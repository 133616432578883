import { enqueueSnackbar, VariantType } from 'notistack';
import CopyIcon from 'assets/img/CopyIcon';
import IconButton from 'components/shared/IconButton/IconButton';
import withAuth from 'components/Authorization/withAuth';
import Typography from 'components/shared/Typography/Typography';

const adminEmail = process.env.REACT_APP_ADMIN_EMAIL as string;

const NotFound = () => {
  const copyEmail = async () => {
    if (navigator.clipboard) {
      enqueueSnackbar('Email address has been copied to clipboard', { variant: 'success' as VariantType });
      return navigator.clipboard.writeText(adminEmail);
    }
  };

  return (
    <div className="ErrorPage">
      <header className="ErrorPage-header">
        <Typography variant="errorTitle">Error 404</Typography>
        <Typography variant="errorSubtitle" color="text.secondary">
          Not found
        </Typography>
      </header>
      <div className="ErrorPage-content">
        <div className={`ErrorPage-content-image ErrorPage-content-image-404`} />
        <div className="ErrorPage-content-description">
          <Typography variant="h5" className="ErrorPage-content-descriptionTitle">
            Page you have requested does not exist
          </Typography>
          <Typography variant="body1" color="text.secondary" className="ErrorPage-content-descriptionInfo">
            The error may be caused by typo in the link or page was deleted. <br />
            Try to double-check the address or choose other module from menu bar.
          </Typography>
          <Typography variant="body1" color="text.secondary">
            If you think it’s a mistake, contact to admin:
            <Typography variant="alertTitle">{adminEmail} </Typography>
            <IconButton
              className="ErrorPage-content-descriptionInfo-button"
              size="small"
              onClick={() => void copyEmail()}
            >
              <CopyIcon color="disabled" fontSize="small" />
            </IconButton>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default withAuth(NotFound);
