import { Button as ButtonBase } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';

interface IButtonProps extends ButtonProps {
  primary?: boolean;
  label?: string;
}

const Button = (props: IButtonProps) => {
  return <ButtonBase {...props} />;
};

export default Button;
