import { createSvgIcon } from '@mui/material';

const DeleteIcon = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.38212 5.54207C7.56368 4.84787 7.83534 4.01074 8.47501 3.34739C9.23096 2.56344 10.3592 2.1499 11.9998 2.1499C13.6404 2.1499 14.7687 2.56344 15.5246 3.34739C16.1643 4.01072 16.4359 4.84781 16.6175 5.542C17.5451 5.70871 18.3398 5.91172 19.0013 6.11648C19.7896 6.36047 20.3877 6.60659 20.7955 6.79591C20.9993 6.89056 21.1556 6.97099 21.2642 7.02985C21.3186 7.05927 21.361 7.08331 21.3915 7.10103C21.3981 7.10482 21.4041 7.10833 21.4095 7.11154C21.4168 7.11581 21.4231 7.11956 21.4284 7.12275L21.4401 7.12985L21.4443 7.13242L21.446 7.13345C21.4464 7.13366 21.4474 7.13432 21 7.85704L21.4474 7.13432C21.8466 7.38141 21.9698 7.9053 21.7227 8.30445C21.4759 8.70312 20.953 8.82657 20.5541 8.58066L20.5382 8.57135C20.5219 8.56186 20.494 8.546 20.4545 8.52464C20.3757 8.48194 20.2507 8.41728 20.0796 8.33782C19.7373 8.17892 19.2104 7.96076 18.4987 7.74047C17.0763 7.30019 14.9109 6.8499 12 6.8499C9.08914 6.8499 6.92378 7.30019 5.50135 7.74047C4.78963 7.96076 4.26271 8.17892 3.92046 8.33782C3.74932 8.41728 3.62433 8.48194 3.54548 8.52464C3.50606 8.546 3.47817 8.56186 3.46183 8.57135L3.44597 8.58068L3.00002 7.85704C2.55261 7.13432 2.55294 7.13412 2.55328 7.13391L2.5557 7.13242L2.5599 7.12985L2.57164 7.12275C2.58096 7.11717 2.59325 7.10988 2.60852 7.10103C2.63904 7.08331 2.68147 7.05927 2.7358 7.02985C2.84445 6.97099 3.00071 6.89056 3.20457 6.79591C3.61233 6.60659 4.2104 6.36047 4.99868 6.11648C5.66014 5.91175 6.45468 5.70877 7.38212 5.54207ZM9.24301 5.28381C9.36129 4.97919 9.5025 4.73093 9.69874 4.52742C10.0276 4.18637 10.6404 3.8499 11.9998 3.8499C13.3592 3.8499 13.972 4.18637 14.3009 4.52742C14.4971 4.73092 14.6383 4.97917 14.7566 5.28377C13.9165 5.19978 12.9976 5.1499 12 5.1499C11.0023 5.1499 10.0832 5.1998 9.24301 5.28381Z" />
      <path d="M3.44472 8.58143L3.44597 8.58068L3.00002 7.85704L2.55328 7.13391C2.15413 7.381 2.0302 7.9053 2.27729 8.30445C2.52383 8.7027 3.04591 8.82631 3.44472 8.58143Z" />
      <path d="M4.90708 9.15615C5.37365 9.10431 5.79391 9.44052 5.84575 9.90709C5.97164 11.0401 6.06821 12.0621 6.1539 12.9785L6.16813 13.1307C6.24819 13.9874 6.31887 14.7437 6.3967 15.4193C6.56413 16.8725 6.75499 17.8397 7.07304 18.5128C7.36112 19.1225 7.75628 19.4976 8.44625 19.7493C9.19756 20.0233 10.3029 20.151 12.001 20.151C13.699 20.151 14.8043 20.0233 15.5557 19.7493C16.2456 19.4976 16.6408 19.1225 16.9289 18.5128C17.2469 17.8397 17.4378 16.8725 17.6052 15.4193C17.683 14.7436 17.7537 13.9873 17.8338 13.1306L17.848 12.9785C17.9337 12.0621 18.0303 11.0401 18.1562 9.90709C18.208 9.44052 18.6283 9.10431 19.0948 9.15615C19.5614 9.20799 19.8976 9.62825 19.8458 10.0948C19.7216 11.2118 19.6262 12.221 19.5406 13.1367L19.5257 13.2961C19.4463 14.1456 19.374 14.9193 19.294 15.6139C19.1266 17.0669 18.9157 18.2872 18.4659 19.2391C17.9861 20.2545 17.2474 20.9418 16.1382 21.3464C15.0904 21.7286 13.7315 21.851 12.001 21.851C10.2704 21.851 8.91149 21.7286 7.8637 21.3464C6.75455 20.9418 6.01578 20.2545 5.536 19.2391C5.0862 18.2872 4.87528 17.0669 4.70788 15.6139C4.62786 14.9193 4.55557 14.1457 4.47619 13.2962L4.46128 13.1367C4.37566 12.221 4.28026 11.2118 4.15615 10.0948C4.10431 9.62825 4.44051 9.20799 4.90708 9.15615Z" />
    </svg>, 'Delete'
  )

export default DeleteIcon;
